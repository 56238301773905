import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import dayjs from 'dayjs';
import {
  GoalCategoryModel,
  GoalModel,
  PortfolioProfitabilityModel,
} from 'projects/bp-core/src/lib/proto/common-message.pb';
import { BehaviorSubject, Observable } from 'rxjs';
import { IChart } from '../../models/portal/chart.model';
import { IDepositForm } from '../../models/portal/goal/deposit-form.model';
import { IGoalTransaction } from '../../models/portal/goal/goal-transaction.model';
import { IGoal } from '../../models/portal/goal/goal.model';
import { IIrrResponse } from '../../models/portal/goal/irr.model';
import { IPortfolioProfitability } from '../../models/portal/goal/portfolio.model';
import { IProfitavilityValue } from '../../models/portal/goal/profitability-value.model';
import {
  ISimulateForecastResult,
  ISimulateForecastValue,
} from '../../models/portal/goal/simulate-forescast-result';
import { IStarredForm } from '../../models/portal/goal/starred-form.model';
import { IWhereMyMoney } from '../../models/portal/goal/whereMyMoney.model';
import { Menu, NavService } from './nav.service';

@Injectable({
  providedIn: 'root',
})
export class GoalService {
  constructor(private navService: NavService, private http: HttpClient) {}
  goals$ = new BehaviorSubject<GoalModel[]>([]);

  public set Goals(goals: GoalModel[]) {
    this.goals$.next(goals);
    const newMenu: Menu[] = [
      ...this.navService.menuItemsAbove,
      ...goals.map(goal => ({
        path: '/goals/' + goal.id,
        title: goal.title,
        icon: goal.goalCategory ? goal?.goalCategory?.icon?.code : '',
        type: 'link',
      })),
      ...this.navService.menuItemsDown,
    ];
    this.navService.items.next(newMenu);
  }

  /**
   * funcion para agregar goal
   *
   * @param {IGoal} goal goal a agregar
   * @returns {Observable<IGoal>} resultado
   */
  add(goal: IGoal): Observable<IGoal> {
    const href = environment.urlApi + 'Goals/';
    return this.http.post<IGoal>(href, goal);
  }

  /**
   * funcion para obtener composicion portfolio en una meta
   *
   * @param {number} goalId id de la meta
   * @returns {Observable<IWhereMyMoney[]>} resultado
   */
  wheremymoney(goalId: number): Observable<IWhereMyMoney[]> {
    const href = environment.urlApi + 'goals/' + goalId + '/where-my-money';

    // const requestUrl = `${href}`;
    return this.http.get<IWhereMyMoney[]>(href);
  }
  /**
   * funcion para obtener un goal
   *
   * @param {number} id id del goal a obtener
   * @returns {Observable<IGoal>} resultado
   */
  get(id: number): Observable<IGoal> {
    const href = environment.urlApi + 'Goals/' + id;
    const query = {
      include: [
        // {
        //   relation: 'transactions'
        // },
        {
          relation: 'riskLevel',
        },
        {
          relation: 'goalCategory',
          scope: {
            include: {
              relation: 'goalCategoryConfigCurrencies',
            },
          },
        },
        {
          relation: 'financialEntity',
        },
        {
          relation: 'portfolio',
        },
        {
          relation: 'currency',
        },
        {
          relation: 'investmentStrategy',
        },
        {
          relation: 'displayCurrency',
        },
        //   scope: {
        //     include: [
        //       {
        //         relation: 'funds',
        //         scope: {
        //           include: {
        //             relation: 'funding',
        //             scope: {
        //               include: [
        //                 { relation: 'financialEntity' },
        //                 {
        //                   relation: 'compositions',
        //                   scope: {
        //                     include: {
        //                       relation: 'subcategory',
        //                       scope: {
        //                         include: {
        //                           relation: 'category'
        //                         }
        //                       }
        //                     }
        //                   }
        //                 }
        //               ]
        //             }
        //           }
        //         }
        //       },
        //       {
        //         relation: 'financialEntity'
        //       },
        //       {
        //         relation: 'compositions',
        //         scope: {
        //           include: {
        //             relation: 'subcategory',
        //             scope: {
        //               include: {
        //                 relation: 'category'
        //               }
        //             }
        //           }
        //         }
        //       }
        //     ]
        //   }
        // }
      ],
    };

    const requestUrl = `${href}?filter=${JSON.stringify(query, null, 2)}`;
    // const requestUrl = `${href}`;
    return this.http.get<IGoal>(requestUrl);
  }
  /**
   * funcion para depositar dinero a una meta
   *
   * @param {number} goalId id de la meta
   * @param {IDepositForm} form valor a depositar
   * @returns {Observable<IDepositForm>} resultado de la operacion
   */
  deposit(goalId: number, form: IDepositForm): Observable<IDepositForm> {
    const href = `${environment.urlApi}Goals/${goalId}/deposit`;

    return this.http.post<IDepositForm>(href, form);
  }

  /**
   * funcion para indicar como favorita una meta
   *
   * @param {number} goalId id de la meta
   * @param {IStarredForm} form informacion de favorita
   * @returns object
   */
  starred(goalId: number, form: IStarredForm): Observable<IGoal> {
    const href = `${environment.urlApi}Goals/${goalId}/starred`;

    return this.http.post<IGoal>(href, form);

    // return {status:'nok', message:'No fue posible actualizar meta favorita'};
  }

  /**
   * funcion para depositar dinero a una meta
   *
   * @param {number} goalId id de la meta
   * @param {Object} form valor a depositar
   * @returns
   */
  checkCurrentStarredGoal(financialEntityId: number): Observable<IGoal> {
    const href = `${environment.urlApi}Goals/${financialEntityId}/check-starred`;
    return this.http.get<IGoal>(href);
  }

  /**
   * funcion para retirar dinero a una meta
   *
   * @param {number} goalId id de la meta
   * @param {IDepositForm} form valor a retirar
   * @returns {Observable<IDepositForm>} resultado de la operacion
   */
  withdraw(goalId: number, form: IDepositForm): Observable<IDepositForm> {
    const href = `${environment.urlApi}Goals/${goalId}/withdraw`;

    return this.http.post<IDepositForm>(href, form);
  }

  getProfitabilityValues(
    goalId: number | null | undefined,
    where = {},
    limit?: number,
    order?: string,
    include?: any[],
  ): Observable<IProfitavilityValue[]> {
    let href = environment.urlApi + 'goalprofitabilityValues/';
    if (goalId) {
      href = environment.urlApi + 'Goals/' + goalId + '/profitabilityValues/';
    }
    // console.log(where);
    const query: any = {
      include: [
        {
          relation: 'funding',
          scope: {
            fields: {
              title: true,
            },
          },
        },
        {
          relation: 'goal',
          scope: {
            include: {
              relation: 'goalCategory',
              scope: {
                fields: {
                  title: true,
                },
              },
            },
            fields: {
              title: true,
            },
          },
        },
      ],
      order: 'date ASC',
      where,
      fields: {
        value: true,
        date: true,
        type: true,
        funding: true,
        goal: true,
        goalId: true,
        fundingId: true,
      },
    };
    if (limit) {
      query.limit = limit;
    }
    if (order) {
      query.order = order;
    }
    if (include) {
      query.include = include;
    }
    const requestUrl = `${href}?filter=${JSON.stringify(query, null, 2)}`;

    return this.http.get<IProfitavilityValue[]>(requestUrl);
  }
  getTransactionByGoalId(
    goalId?: number,
    fromDate?: Date,
    toDate?: Date,
    order?: string,
  ): Observable<IGoalTransaction[]> {
    const query: any = {
      order: 'date ASC',
    };
    let href = environment.urlApi + 'goaltransactions/';
    if (goalId) {
      href = `${environment.urlApi}Goals/${goalId}/transactions`;
    }

    if (fromDate && toDate) {
      query.where = {
        and: [
          { date: { gte: dayjs(fromDate).format('YYYY-MM-DD') } },
          { date: { lte: dayjs(toDate).format('YYYY-MM-DD') } },
          { state: 'Processed' },
        ],
      };
    } else if (fromDate) {
      query.where = {
        and: [{ date: { gte: dayjs(fromDate).format('YYYY-MM-DD') } }, { state: 'Processed' }],
      };
    } else if (toDate) {
      query.where = {
        and: [{ date: { lte: dayjs(toDate).format('YYYY-MM-DD') } }, { state: 'Processed' }],
      };
    } else {
      query.where = { state: 'Processed' };
    }

    if (order) {
      query.order = order;
    }
    query.include = 'currency';
    // console.log(query);
    const requestUrl = `${href}?filter=${JSON.stringify(query, null, 2)}`;

    return this.http.get<IGoalTransaction[]>(requestUrl);
  }

  /**
   * remover un goal
   *
   * @param {number} id id del foal
   * @returns {Observable<boolean>} resultado de la eliminacion
   */
  remove(id: number): Observable<boolean> {
    const href = environment.urlApi + 'goals/' + id;
    return this.http.delete<boolean>(href);
  }
  /**
   * update un goal
   *
   * @param {IGoal} goal a actualizar
   * @returns {Observable<IGoal>} resultado de la actualizacion
   */
  update(goal: IGoal): Observable<IGoal> {
    const href = environment.urlApi + 'goals/' + goal.id;
    return this.http.patch<IGoal>(href, goal);
  }

  getEstimatedMonthlyContribution(
    goal: GoalModel | undefined,
    profitabilitys: PortfolioProfitabilityModel[],
    goalCategory: GoalCategoryModel | undefined,
    current = false,
  ): number {
    const targetAmount: number = goal ? goal.targetAmount : goalCategory?.targetAmount ?? 0;

    let initialInvestment = goal ? goal.initialInvestment : goalCategory?.initialInvestment ?? 0;

    const years = goal ? goal.years : goalCategory?.year ?? 0;

    let timeMonths = 0;

    if (current) {
      //Si meta ya en curso, determinar meses restantes
      initialInvestment = goal?.currentContribution! + goal?.amountsTransactionsPending!;
      const startDate = dayjs();
      const createdGoal = dayjs(goal?.dateOfCompletion);
      const remainMonths = createdGoal.diff(startDate, 'month', false);
      timeMonths = remainMonths - 1;
    } else {
      //Si meta nueva, considerar meses restantes los años de la meta
      timeMonths = years * 12 - 1;
    }

    const monthlyProfitability2 = (profitabilitys[0].expected / 100 + 1) ** (1 / 12) - 1;
    //=(1+C12)^(1/12)-1

    let contributionSum2 = 0;
    for (let i = 1; i <= timeMonths; i++) {
      contributionSum2 += (1 + monthlyProfitability2) ** (timeMonths - i);
    }

    let monthlyContr2: number =
      (targetAmount - initialInvestment * (1 + monthlyProfitability2) ** (timeMonths + 1)) /
      contributionSum2;

    if (monthlyContr2 < 0) {
      monthlyContr2 = 0;
    }

    return Math.round(monthlyContr2);
  }
  calculateMonthlyValueSimulatorLegacy(
    currentValues: ITransactionSimulator[],
    transactionValueToAdd: number,
    profitability: IPortfolioProfitability[],
  ) {
    currentValues.push({
      currentMonth: 0,
      currentYear: 0,
      initialValue: Number(transactionValueToAdd),
      currentValueMaximum: Number(transactionValueToAdd),
      currentValueMinimum: Number(transactionValueToAdd),
      currentValueNormal: Number(transactionValueToAdd),
      profit: 0,
      secuentialMonth: 0,
    });
    const result: ICalculateMonthlyValueSimulator = {
      transactions: currentValues,
      value: {
        contribution: 0,
        maximum: 0,
        minimum: 0,
        normal: 0,
      },
    };
    if (!profitability || profitability.length === 0) {
      return result;
    }

    for (let index = 0; index < currentValues.length; index++) {
      const currentValue = currentValues[index];
      currentValue.currentMonth++;
      if (currentValue.currentMonth > 12) {
        currentValue.currentMonth = 1;
        currentValue.currentYear++;
      }
      let maximumProfitabilityPercentage = profitability[currentValue.currentYear].maximum / 100;

      const minimumProfitabilityPercentageYear =
        profitability[currentValue.currentYear].minimum / 100;

      let normalProfitabilityPercentage = profitability[currentValue.currentYear].expected / 100;

      maximumProfitabilityPercentage = Math.pow(1 + maximumProfitabilityPercentage, 1 / 12);

      const minimumProfitabilityPercentage = Math.pow(
        1 + minimumProfitabilityPercentageYear,
        1 / 12,
      );

      normalProfitabilityPercentage = Math.pow(1 + normalProfitabilityPercentage, 1 / 12);

      currentValue.currentValueMaximum =
        currentValue.currentValueMaximum * maximumProfitabilityPercentage;

      currentValue.currentValueMinimum =
        currentValue.currentValueMinimum * minimumProfitabilityPercentage;

      currentValue.currentValueNormal =
        currentValue.currentValueNormal * normalProfitabilityPercentage;

      result.value.contribution =
        Number(result.value.contribution) + Number(currentValue.initialValue);

      result.value.maximum = result.value.maximum + currentValue.currentValueMaximum;
      result.value.minimum = result.value.minimum + currentValue.currentValueMinimum;
      result.value.normal = result.value.normal + currentValue.currentValueNormal;
    }

    return result;
  }
  simulateForecastLegacy(
    goal: GoalModel,
    current = false,
    noCalculateInitialInvestment = false,
    simNewDeposit = 0,
    yearsFromToday = false,
  ): ISimulateForecastResult {
    // console.time('calculo grafico');
    let initialDate = dayjs();
    let endDate = initialDate.add(goal.years, 'year');
    let totalMonths = goal.years * 12;

    if (goal?.goalCategory?.code == 'ahorros-generales') {
      endDate = dayjs().add(10, 'year');
      totalMonths = 10 * 12;
    }

    let initialInvestment = goal.initialInvestment;

    if (goal.haveDeposited) {
      //meta existente
      initialInvestment =
        //goal.currentContribution + goal.amountsTransactionsPending + simNewDeposit;
        goal.currentCapital + goal.amountsTransactionsPending + simNewDeposit;
      if (!yearsFromToday) {
        endDate = dayjs(goal.created?.toDate() ?? '').add(goal.years, 'year');

        const remainYears = endDate.diff(initialDate, 'month', false);
        totalMonths = remainYears; // meses restantes*/
      }

      //se va considerar desde la fecha de consulta del simulador, tomando como aporte inicial el capital actual
      /*const createdGoal = dayjs(goal.created);
      endDate = endDate.add(createdGoal.diff(initialDate), 'millisecond');
      //meses restantes de la meta
      const startDate = dayjs();
      initialDate = startDate;
      const createdGoalEnd = dayjs(goal?.dateOfCompletion);
      const remainYears = createdGoalEnd.diff(startDate, 'month', false);
      totalMonths = remainYears; // meses restantes*/
    }

    let transactionsSimulation: ITransactionSimulator[] = [];

    const data: ISimulateForecastValue[] = [];
    const monthContributionValue = goal.monthlyContribution;
    let yearCount = 0;
    let monthCount = 0;

    //AporteInicial o currentCapital de meta
    const result = this.calculateMonthlyValueSimulatorLegacy(
      transactionsSimulation,
      initialInvestment,
      goal?.portfolio?.profitability ?? [],
    );

    transactionsSimulation = result.transactions;
    data.push({
      date: initialDate.toDate(),
      contribution: result.value.contribution,
      minimum: result.value.minimum,
      maximum: result.value.maximum,
      normal: result.value.normal,
      yearCount,
      monthCount,
    });

    initialDate = initialDate.add(1, 'month');
    endDate = initialDate.add(totalMonths - 1, 'month');
    monthCount = 1;

    for (let currentYear = initialDate.year(); endDate.year() >= currentYear; currentYear++) {
      let startMonth = 0;
      let endMonth = 11;
      if (currentYear === initialDate.year()) {
        startMonth = initialDate.month();
      }
      if (currentYear === endDate.year()) {
        endMonth = endDate.month() - (current ? 0 : 1);
      }

      for (let month = startMonth; endMonth >= month; month++) {
        if (!goal?.portfolio?.profitability![yearCount]) {
          continue;
        }

        const result = this.calculateMonthlyValueSimulatorLegacy(
          transactionsSimulation,
          monthContributionValue,
          goal?.portfolio?.profitability ?? [],
        );
        transactionsSimulation = result.transactions;
        data.push({
          yearCount,
          monthCount,
          date: dayjs(
            initialDate
              .clone()
              .year(currentYear)
              .month(month),
          ).toDate(),
          contribution: result.value.contribution,
          minimum: result.value.minimum,
          maximum: result.value.maximum,
          normal: result.value.normal,
        });

        monthCount++;
        if (monthCount > 12) {
          monthCount = 1;
          yearCount++;
        }
      }
    }

    const worstCaseCapital = data.length > 0 ? Number(data[data.length - 1].minimum) : 0;
    const bestCaseCapital = data.length > 0 ? Number(data[data.length - 1].maximum) : 0;
    const expectedCapital = data.length > 0 ? Number(data[data.length - 1].normal) : 0;
    const contributionCapital = data.length > 0 ? Number(data[data.length - 1].contribution) : 0;

    let max = 0;
    if (data.length > 0) {
      max =
        goal.targetAmount > data[data.length - 1].maximum
          ? goal.targetAmount
          : data[data.length - 1].maximum;
    }

    return {
      values: data,
      expectedCapital,
      maxChart: max * 1.03,
      contributionCapital,
      worstCaseCapital,
      bestCaseCapital,
    };
  }
  simulateForecast(
    goal: GoalModel,
    current = false,
    noCalculateInitialInvestment = false,
    simNewDeposit = 0,
    yearsFromToday = false,
  ): ISimulateForecastResult {
    // console.time('calculo grafico');
    let initialDate = dayjs();
    let endDate = initialDate.add(goal.years, 'year');
    let totalMonths = goal.years * 12;

    if (goal?.goalCategory?.code == 'ahorros-generales') {
      endDate = dayjs().add(10, 'year');
      totalMonths = 10 * 12;
    }

    let initialInvestment = goal.initialInvestment;

    if (goal.haveDeposited) {
      //meta existente
      initialInvestment =
        //goal.currentContribution + goal.amountsTransactionsPending + simNewDeposit;
        goal.currentCapital + goal.amountsTransactionsPending + simNewDeposit;
      if (!yearsFromToday) {
        endDate = dayjs(goal.created?.toDate() ?? '').add(goal.years, 'year');

        const remainYears = endDate.diff(initialDate, 'month', false);
        totalMonths = remainYears; // meses restantes*/
      }

      //se va considerar desde la fecha de consulta del simulador, tomando como aporte inicial el capital actual
      /*const createdGoal = dayjs(goal.created);
      endDate = endDate.add(createdGoal.diff(initialDate), 'millisecond');
      //meses restantes de la meta
      const startDate = dayjs();
      initialDate = startDate;
      const createdGoalEnd = dayjs(goal?.dateOfCompletion);
      const remainYears = createdGoalEnd.diff(startDate, 'month', false);
      totalMonths = remainYears; // meses restantes*/
    }

    let transactionsSimulation: ITransactionSimulator[] = [];

    const data: ISimulateForecastValue[] = [];
    const monthContributionValue = goal.monthlyContribution;
    let yearCount = 0;
    let monthCount = 0;

    //AporteInicial o currentCapital de meta
    const result = this.calculateMonthlyValueSimulatorLegacy(
      transactionsSimulation,
      initialInvestment,
      goal?.portfolio?.profitability ?? [],
    );

    transactionsSimulation = result.transactions;
    data.push({
      date: initialDate.toDate(),
      contribution: result.value.contribution,
      minimum: result.value.minimum,
      maximum: result.value.maximum,
      normal: result.value.normal,
      yearCount,
      monthCount,
    });

    initialDate = initialDate.add(1, 'month');
    endDate = initialDate.add(totalMonths - 1, 'month');
    monthCount = 1;

    for (let currentYear = initialDate.year(); endDate.year() >= currentYear; currentYear++) {
      let startMonth = 0;
      let endMonth = 11;
      if (currentYear === initialDate.year()) {
        startMonth = initialDate.month();
      }
      if (currentYear === endDate.year()) {
        endMonth = endDate.month() - (current ? 0 : 1);
      }

      for (let month = startMonth; endMonth >= month; month++) {
        if (!goal?.portfolio?.profitability![yearCount]) {
          continue;
        }

        const result = this.calculateMonthlyValueSimulatorLegacy(
          transactionsSimulation,
          monthContributionValue,
          goal?.portfolio?.profitability ?? [],
        );
        transactionsSimulation = result.transactions;
        data.push({
          yearCount,
          monthCount,
          date: dayjs(
            initialDate
              .clone()
              .year(currentYear)
              .month(month),
          ).toDate(),
          contribution: result.value.contribution,
          minimum: result.value.minimum,
          maximum: result.value.maximum,
          normal: result.value.normal,
        });

        monthCount++;
        if (monthCount > 12) {
          monthCount = 1;
          yearCount++;
        }
      }
    }

    const worstCaseCapital = data.length > 0 ? Number(data[data.length - 1].minimum) : 0;
    const bestCaseCapital = data.length > 0 ? Number(data[data.length - 1].maximum) : 0;
    const expectedCapital = data.length > 0 ? Number(data[data.length - 1].normal) : 0;
    const contributionCapital = data.length > 0 ? Number(data[data.length - 1].contribution) : 0;

    let max = 0;
    if (data.length > 0) {
      max =
        goal.targetAmount > data[data.length - 1].maximum
          ? goal.targetAmount
          : data[data.length - 1].maximum;
    }

    return {
      values: data,
      expectedCapital,
      maxChart: max * 1.03,
      contributionCapital,
      worstCaseCapital,
      bestCaseCapital,
    };
  }

  calculateMonthlyValueSimulator(
    currentValues: ITransactionSimulator[],
    transactionValueToAdd: number,
    profitability: PortfolioProfitabilityModel[],
  ) {
    currentValues.push({
      currentMonth: 0,
      currentYear: 0,
      initialValue: Number(transactionValueToAdd),
      currentValueMaximum: Number(transactionValueToAdd),
      currentValueMinimum: Number(transactionValueToAdd),
      currentValueNormal: Number(transactionValueToAdd),
      profit: 0,
      secuentialMonth: 0,
    });
    const result: ICalculateMonthlyValueSimulator = {
      transactions: currentValues,
      value: {
        contribution: 0,
        maximum: 0,
        minimum: 0,
        normal: 0,
      },
    };
    if (!profitability || profitability.length === 0) {
      return result;
    }

    for (let index = 0; index < currentValues.length; index++) {
      const currentValue = currentValues[index];
      currentValue.currentMonth++;
      if (currentValue.currentMonth > 12) {
        currentValue.currentMonth = 1;
        currentValue.currentYear++;
      }
      let maximumProfitabilityPercentage =
        (profitability[currentValue.currentYear].maximum ?? 1) / 100;

      const minimumProfitabilityPercentageYear =
        (profitability[currentValue.currentYear].minimum ?? 1) / 100;

      let normalProfitabilityPercentage =
        (profitability[currentValue.currentYear].expected ?? 1) / 100;

      maximumProfitabilityPercentage = Math.pow(1 + maximumProfitabilityPercentage, 1 / 12);

      const minimumProfitabilityPercentage = Math.pow(
        1 + minimumProfitabilityPercentageYear,
        1 / 12,
      );

      normalProfitabilityPercentage = Math.pow(1 + normalProfitabilityPercentage, 1 / 12);

      currentValue.currentValueMaximum =
        currentValue.currentValueMaximum * maximumProfitabilityPercentage;

      currentValue.currentValueMinimum =
        currentValue.currentValueMinimum * minimumProfitabilityPercentage;

      currentValue.currentValueNormal =
        currentValue.currentValueNormal * normalProfitabilityPercentage;

      result.value.contribution =
        Number(result.value.contribution) + Number(currentValue.initialValue);

      result.value.maximum = result.value.maximum + currentValue.currentValueMaximum;
      result.value.minimum = result.value.minimum + currentValue.currentValueMinimum;
      result.value.normal = result.value.normal + currentValue.currentValueNormal;
    }

    return result;
  }

  probabilities(query?: {
    fromDate?: Date;
    toDate?: Date;
    goalId?: number;
    funds?: number[];
    currencyCode?: string;
    fundingCurrencyCode?: string;
  }): Observable<IChart> {
    let href = environment.urlApi + 'Goals/profitabilities/?';
    if (!query) {
      query = {};
    }

    if (query.fromDate) {
      href += 'fromDate=' + dayjs(query.fromDate).format('YYYY-MM-DD') + '&';
    }
    if (query.toDate) {
      href += 'toDate=' + dayjs(query.toDate).format('YYYY-MM-DD') + '&';
    }
    if (query.goalId) {
      href += 'goalId=' + query.goalId + '&';
    }
    if (query.currencyCode) {
      href += 'currencyCode=' + query.currencyCode + '&';
    }
    if (query.fundingCurrencyCode) {
      href += 'fundingCurrencyCode=' + query.fundingCurrencyCode + '&';
    }

    if (query.funds) {
      href += 'funds=' + JSON.stringify(query.funds);
    }

    return this.http.get<IChart>(href);
  }

  irr(query?: { fromDate?: Date; toDate?: Date; goalId?: number }): Observable<IIrrResponse> {
    if (!query) {
      query = {};
    }
    let href = environment.urlApi + 'Goals/irr/?';
    if (query.fromDate) {
      href += 'fromDate=' + dayjs(query.fromDate).format('YYYY-MM-DD') + '&';
    }
    if (query.toDate) {
      href += 'toDate=' + dayjs(query.toDate).format('YYYY-MM-DD') + '&';
    }
    if (query.goalId) {
      href += 'goalId=' + query.goalId;
    }
    return this.http.get<IIrrResponse>(href);
  }
}

interface ITransactionSimulator {
  secuentialMonth: number;
  initialValue: number;
  currentValueMaximum: number;
  currentValueMinimum: number;
  currentValueNormal: number;
  currentYear: number;
  currentMonth: number;
  profit?: number;
}
interface ICalculateMonthlyValueSimulator {
  value: ISimulateForecastValue;
  transactions: ITransactionSimulator[];
}
