/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum OriginType {
  Normal = 0,
  Referral = 1,
  External = 2,
  FINTOC = 3,
  AMH = 4,
  CHECK = 5
}
