/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as file_message007 from './file-message.pb';
import * as common_message008 from './common-message.pb';
/**
 * Message implementation for user.GetMeRequest
 */
export class GetMeRequest implements GrpcMessage {
  static id = 'user.GetMeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeRequest();
    GetMeRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeRequest) {
    _instance.onlyManager = _instance.onlyManager || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.onlyManager = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.onlyManager) {
      _writer.writeBool(1, _instance.onlyManager);
    }
  }

  private _onlyManager: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeRequest.AsObject>) {
    _value = _value || {};
    this.onlyManager = _value.onlyManager;
    GetMeRequest.refineValues(this);
  }
  get onlyManager(): boolean {
    return this._onlyManager;
  }
  set onlyManager(value: boolean) {
    this._onlyManager = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeRequest.AsObject {
    return {
      onlyManager: this.onlyManager
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeRequest.AsProtobufJSON {
    return {
      onlyManager: this.onlyManager
    };
  }
}
export module GetMeRequest {
  /**
   * Standard JavaScript object representation for GetMeRequest
   */
  export interface AsObject {
    onlyManager: boolean;
  }

  /**
   * Protobuf JSON representation for GetMeRequest
   */
  export interface AsProtobufJSON {
    onlyManager: boolean;
  }
}

/**
 * Message implementation for user.GetMeManagersRequest
 */
export class GetMeManagersRequest implements GrpcMessage {
  static id = 'user.GetMeManagersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeManagersRequest();
    GetMeManagersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeManagersRequest) {
    _instance.userId = _instance.userId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeManagersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeManagersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeManagersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
  }

  private _userId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeManagersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeManagersRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    GetMeManagersRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeManagersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeManagersRequest.AsObject {
    return {
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeManagersRequest.AsProtobufJSON {
    return {
      userId: this.userId
    };
  }
}
export module GetMeManagersRequest {
  /**
   * Standard JavaScript object representation for GetMeManagersRequest
   */
  export interface AsObject {
    userId: number;
  }

  /**
   * Protobuf JSON representation for GetMeManagersRequest
   */
  export interface AsProtobufJSON {
    userId: number;
  }
}

/**
 * Message implementation for user.GetMeManagersResponse
 */
export class GetMeManagersResponse implements GrpcMessage {
  static id = 'user.GetMeManagersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeManagersResponse();
    GetMeManagersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeManagersResponse) {
    _instance.managers = _instance.managers || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeManagersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new ManagerModel();
          _reader.readMessage(
            messageInitializer1,
            ManagerModel.deserializeBinaryFromReader
          );
          (_instance.managers = _instance.managers || []).push(
            messageInitializer1
          );
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeManagersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeManagersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.managers && _instance.managers.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.managers as any,
        ManagerModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _managers?: ManagerModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeManagersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeManagersResponse.AsObject>) {
    _value = _value || {};
    this.managers = (_value.managers || []).map(m => new ManagerModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    GetMeManagersResponse.refineValues(this);
  }
  get managers(): ManagerModel[] | undefined {
    return this._managers;
  }
  set managers(value: ManagerModel[] | undefined) {
    this._managers = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeManagersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeManagersResponse.AsObject {
    return {
      managers: (this.managers || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeManagersResponse.AsProtobufJSON {
    return {
      managers: (this.managers || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module GetMeManagersResponse {
  /**
   * Standard JavaScript object representation for GetMeManagersResponse
   */
  export interface AsObject {
    managers?: ManagerModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for GetMeManagersResponse
   */
  export interface AsProtobufJSON {
    managers: ManagerModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for user.ManagerModel
 */
export class ManagerModel implements GrpcMessage {
  static id = 'user.ManagerModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ManagerModel();
    ManagerModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ManagerModel) {
    _instance.id = _instance.id || 0;
    _instance.userId = _instance.userId || 0;
    _instance.rut = _instance.rut || '';
    _instance.name = _instance.name || '';
    _instance.isEnabled = _instance.isEnabled || false;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ManagerModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.userId = _reader.readInt32();
          break;
        case 3:
          _instance.rut = _reader.readString();
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.isEnabled = _reader.readBool();
          break;
        case 6:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ManagerModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ManagerModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.userId) {
      _writer.writeInt32(2, _instance.userId);
    }
    if (_instance.rut) {
      _writer.writeString(3, _instance.rut);
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.isEnabled) {
      _writer.writeBool(5, _instance.isEnabled);
    }
    if (_instance.created) {
      _writer.writeMessage(
        6,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        7,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _userId: number;
  private _rut: string;
  private _name: string;
  private _isEnabled: boolean;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ManagerModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ManagerModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.userId = _value.userId;
    this.rut = _value.rut;
    this.name = _value.name;
    this.isEnabled = _value.isEnabled;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    ManagerModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get rut(): string {
    return this._rut;
  }
  set rut(value: string) {
    this._rut = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get isEnabled(): boolean {
    return this._isEnabled;
  }
  set isEnabled(value: boolean) {
    this._isEnabled = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ManagerModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ManagerModel.AsObject {
    return {
      id: this.id,
      userId: this.userId,
      rut: this.rut,
      name: this.name,
      isEnabled: this.isEnabled,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ManagerModel.AsProtobufJSON {
    return {
      id: this.id,
      userId: this.userId,
      rut: this.rut,
      name: this.name,
      isEnabled: this.isEnabled,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module ManagerModel {
  /**
   * Standard JavaScript object representation for ManagerModel
   */
  export interface AsObject {
    id: number;
    userId: number;
    rut: string;
    name: string;
    isEnabled: boolean;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for ManagerModel
   */
  export interface AsProtobufJSON {
    id: number;
    userId: number;
    rut: string;
    name: string;
    isEnabled: boolean;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.ListScheduleEventResponse
 */
export class ListScheduleEventResponse implements GrpcMessage {
  static id = 'user.ListScheduleEventResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListScheduleEventResponse();
    ListScheduleEventResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListScheduleEventResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListScheduleEventResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.ScheduleEventModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.ScheduleEventModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListScheduleEventResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListScheduleEventResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.ScheduleEventModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message008.ScheduleEventModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListScheduleEventResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListScheduleEventResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.ScheduleEventModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListScheduleEventResponse.refineValues(this);
  }
  get values(): common_message008.ScheduleEventModel[] | undefined {
    return this._values;
  }
  set values(value: common_message008.ScheduleEventModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListScheduleEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListScheduleEventResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListScheduleEventResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListScheduleEventResponse {
  /**
   * Standard JavaScript object representation for ListScheduleEventResponse
   */
  export interface AsObject {
    values?: common_message008.ScheduleEventModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListScheduleEventResponse
   */
  export interface AsProtobufJSON {
    values: common_message008.ScheduleEventModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for user.GetMeTransactionsResponse
 */
export class GetMeTransactionsResponse implements GrpcMessage {
  static id = 'user.GetMeTransactionsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetMeTransactionsResponse();
    GetMeTransactionsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetMeTransactionsResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetMeTransactionsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.GoalTransactionModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.GoalTransactionModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetMeTransactionsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetMeTransactionsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.GoalTransactionModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message008.GoalTransactionModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetMeTransactionsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetMeTransactionsResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.GoalTransactionModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    GetMeTransactionsResponse.refineValues(this);
  }
  get values(): common_message008.GoalTransactionModel[] | undefined {
    return this._values;
  }
  set values(value: common_message008.GoalTransactionModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetMeTransactionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetMeTransactionsResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetMeTransactionsResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module GetMeTransactionsResponse {
  /**
   * Standard JavaScript object representation for GetMeTransactionsResponse
   */
  export interface AsObject {
    values?: common_message008.GoalTransactionModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for GetMeTransactionsResponse
   */
  export interface AsProtobufJSON {
    values: common_message008.GoalTransactionModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for user.GetLastFundingsValuesRequest
 */
export class GetLastFundingsValuesRequest implements GrpcMessage {
  static id = 'user.GetLastFundingsValuesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastFundingsValuesRequest();
    GetLastFundingsValuesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastFundingsValuesRequest) {
    _instance.userId = _instance.userId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastFundingsValuesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastFundingsValuesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastFundingsValuesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
  }

  private _userId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastFundingsValuesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetLastFundingsValuesRequest.AsObject>
  ) {
    _value = _value || {};
    this.userId = _value.userId;
    GetLastFundingsValuesRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastFundingsValuesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastFundingsValuesRequest.AsObject {
    return {
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastFundingsValuesRequest.AsProtobufJSON {
    return {
      userId: this.userId
    };
  }
}
export module GetLastFundingsValuesRequest {
  /**
   * Standard JavaScript object representation for GetLastFundingsValuesRequest
   */
  export interface AsObject {
    userId: number;
  }

  /**
   * Protobuf JSON representation for GetLastFundingsValuesRequest
   */
  export interface AsProtobufJSON {
    userId: number;
  }
}

/**
 * Message implementation for user.GetLastFundingsValuesResponse
 */
export class GetLastFundingsValuesResponse implements GrpcMessage {
  static id = 'user.GetLastFundingsValuesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastFundingsValuesResponse();
    GetLastFundingsValuesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastFundingsValuesResponse) {
    _instance.values = _instance.values || [];
    _instance.date = _instance.date || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastFundingsValuesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.FundingProfitabilityValueModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.FundingProfitabilityValueModel
              .deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastFundingsValuesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastFundingsValuesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.FundingProfitabilityValueModel.serializeBinaryToWriter
      );
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _values?: common_message008.FundingProfitabilityValueModel[];
  private _date?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastFundingsValuesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetLastFundingsValuesResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.FundingProfitabilityValueModel(m)
    );
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    GetLastFundingsValuesResponse.refineValues(this);
  }
  get values(): common_message008.FundingProfitabilityValueModel[] | undefined {
    return this._values;
  }
  set values(
    value: common_message008.FundingProfitabilityValueModel[] | undefined
  ) {
    this._values = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastFundingsValuesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastFundingsValuesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      date: this.date ? this.date.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastFundingsValuesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      date: this.date ? this.date.toProtobufJSON(options) : null
    };
  }
}
export module GetLastFundingsValuesResponse {
  /**
   * Standard JavaScript object representation for GetLastFundingsValuesResponse
   */
  export interface AsObject {
    values?: common_message008.FundingProfitabilityValueModel.AsObject[];
    date?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetLastFundingsValuesResponse
   */
  export interface AsProtobufJSON {
    values:
      | common_message008.FundingProfitabilityValueModel.AsProtobufJSON[]
      | null;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.GetFundingsValuesByUserRequest
 */
export class GetFundingsValuesByUserRequest implements GrpcMessage {
  static id = 'user.GetFundingsValuesByUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFundingsValuesByUserRequest();
    GetFundingsValuesByUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFundingsValuesByUserRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.date = _instance.date || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFundingsValuesByUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetFundingsValuesByUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFundingsValuesByUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _userId: number;
  private _date?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFundingsValuesByUserRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetFundingsValuesByUserRequest.AsObject>
  ) {
    _value = _value || {};
    this.userId = _value.userId;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    GetFundingsValuesByUserRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFundingsValuesByUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFundingsValuesByUserRequest.AsObject {
    return {
      userId: this.userId,
      date: this.date ? this.date.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFundingsValuesByUserRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      date: this.date ? this.date.toProtobufJSON(options) : null
    };
  }
}
export module GetFundingsValuesByUserRequest {
  /**
   * Standard JavaScript object representation for GetFundingsValuesByUserRequest
   */
  export interface AsObject {
    userId: number;
    date?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetFundingsValuesByUserRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.GetFundingsValuesByUserResponse
 */
export class GetFundingsValuesByUserResponse implements GrpcMessage {
  static id = 'user.GetFundingsValuesByUserResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetFundingsValuesByUserResponse();
    GetFundingsValuesByUserResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetFundingsValuesByUserResponse) {
    _instance.values = _instance.values || [];
    _instance.date = _instance.date || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetFundingsValuesByUserResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.FundingProfitabilityValueModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.FundingProfitabilityValueModel
              .deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetFundingsValuesByUserResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetFundingsValuesByUserResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.FundingProfitabilityValueModel.serializeBinaryToWriter
      );
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _values?: common_message008.FundingProfitabilityValueModel[];
  private _date?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetFundingsValuesByUserResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetFundingsValuesByUserResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.FundingProfitabilityValueModel(m)
    );
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    GetFundingsValuesByUserResponse.refineValues(this);
  }
  get values(): common_message008.FundingProfitabilityValueModel[] | undefined {
    return this._values;
  }
  set values(
    value: common_message008.FundingProfitabilityValueModel[] | undefined
  ) {
    this._values = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetFundingsValuesByUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetFundingsValuesByUserResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      date: this.date ? this.date.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetFundingsValuesByUserResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      date: this.date ? this.date.toProtobufJSON(options) : null
    };
  }
}
export module GetFundingsValuesByUserResponse {
  /**
   * Standard JavaScript object representation for GetFundingsValuesByUserResponse
   */
  export interface AsObject {
    values?: common_message008.FundingProfitabilityValueModel.AsObject[];
    date?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetFundingsValuesByUserResponse
   */
  export interface AsProtobufJSON {
    values:
      | common_message008.FundingProfitabilityValueModel.AsProtobufJSON[]
      | null;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.ReferralTransactionsSummaryResponse
 */
export class ReferralTransactionsSummaryResponse implements GrpcMessage {
  static id = 'user.ReferralTransactionsSummaryResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReferralTransactionsSummaryResponse();
    ReferralTransactionsSummaryResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReferralTransactionsSummaryResponse) {
    _instance.totalAmount = _instance.totalAmount || 0;
    _instance.availableAmount = _instance.availableAmount || 0;
    _instance.investedAmount = _instance.investedAmount || 0;
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
    _instance.lastTransactionDate = _instance.lastTransactionDate || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReferralTransactionsSummaryResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.totalAmount = _reader.readDouble();
          break;
        case 2:
          _instance.availableAmount = _reader.readDouble();
          break;
        case 3:
          _instance.investedAmount = _reader.readDouble();
          break;
        case 4:
          _instance.status = _reader.readInt32();
          break;
        case 5:
          _instance.message = _reader.readString();
          break;
        case 6:
          _instance.lastTransactionDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastTransactionDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ReferralTransactionsSummaryResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReferralTransactionsSummaryResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.totalAmount) {
      _writer.writeDouble(1, _instance.totalAmount);
    }
    if (_instance.availableAmount) {
      _writer.writeDouble(2, _instance.availableAmount);
    }
    if (_instance.investedAmount) {
      _writer.writeDouble(3, _instance.investedAmount);
    }
    if (_instance.status) {
      _writer.writeInt32(4, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(5, _instance.message);
    }
    if (_instance.lastTransactionDate) {
      _writer.writeMessage(
        6,
        _instance.lastTransactionDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _totalAmount: number;
  private _availableAmount: number;
  private _investedAmount: number;
  private _status: number;
  private _message: string;
  private _lastTransactionDate?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReferralTransactionsSummaryResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ReferralTransactionsSummaryResponse.AsObject>
  ) {
    _value = _value || {};
    this.totalAmount = _value.totalAmount;
    this.availableAmount = _value.availableAmount;
    this.investedAmount = _value.investedAmount;
    this.status = _value.status;
    this.message = _value.message;
    this.lastTransactionDate = _value.lastTransactionDate
      ? new googleProtobuf001.Timestamp(_value.lastTransactionDate)
      : undefined;
    ReferralTransactionsSummaryResponse.refineValues(this);
  }
  get totalAmount(): number {
    return this._totalAmount;
  }
  set totalAmount(value: number) {
    this._totalAmount = value;
  }
  get availableAmount(): number {
    return this._availableAmount;
  }
  set availableAmount(value: number) {
    this._availableAmount = value;
  }
  get investedAmount(): number {
    return this._investedAmount;
  }
  set investedAmount(value: number) {
    this._investedAmount = value;
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }
  get lastTransactionDate(): googleProtobuf001.Timestamp | undefined {
    return this._lastTransactionDate;
  }
  set lastTransactionDate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastTransactionDate = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReferralTransactionsSummaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReferralTransactionsSummaryResponse.AsObject {
    return {
      totalAmount: this.totalAmount,
      availableAmount: this.availableAmount,
      investedAmount: this.investedAmount,
      status: this.status,
      message: this.message,
      lastTransactionDate: this.lastTransactionDate
        ? this.lastTransactionDate.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReferralTransactionsSummaryResponse.AsProtobufJSON {
    return {
      totalAmount: this.totalAmount,
      availableAmount: this.availableAmount,
      investedAmount: this.investedAmount,
      status: this.status,
      message: this.message,
      lastTransactionDate: this.lastTransactionDate
        ? this.lastTransactionDate.toProtobufJSON(options)
        : null
    };
  }
}
export module ReferralTransactionsSummaryResponse {
  /**
   * Standard JavaScript object representation for ReferralTransactionsSummaryResponse
   */
  export interface AsObject {
    totalAmount: number;
    availableAmount: number;
    investedAmount: number;
    status: number;
    message: string;
    lastTransactionDate?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for ReferralTransactionsSummaryResponse
   */
  export interface AsProtobufJSON {
    totalAmount: number;
    availableAmount: number;
    investedAmount: number;
    status: number;
    message: string;
    lastTransactionDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.CreateReferralTransactionRequest
 */
export class CreateReferralTransactionRequest implements GrpcMessage {
  static id = 'user.CreateReferralTransactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateReferralTransactionRequest();
    CreateReferralTransactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateReferralTransactionRequest) {
    _instance.data = _instance.data || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateReferralTransactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.data = new common_message008.UserReferralTransactionModel();
          _reader.readMessage(
            _instance.data,
            common_message008.UserReferralTransactionModel
              .deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateReferralTransactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateReferralTransactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.data) {
      _writer.writeMessage(
        1,
        _instance.data as any,
        common_message008.UserReferralTransactionModel.serializeBinaryToWriter
      );
    }
  }

  private _data?: common_message008.UserReferralTransactionModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateReferralTransactionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CreateReferralTransactionRequest.AsObject>
  ) {
    _value = _value || {};
    this.data = _value.data
      ? new common_message008.UserReferralTransactionModel(_value.data)
      : undefined;
    CreateReferralTransactionRequest.refineValues(this);
  }
  get data(): common_message008.UserReferralTransactionModel | undefined {
    return this._data;
  }
  set data(value: common_message008.UserReferralTransactionModel | undefined) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateReferralTransactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateReferralTransactionRequest.AsObject {
    return {
      data: this.data ? this.data.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateReferralTransactionRequest.AsProtobufJSON {
    return {
      data: this.data ? this.data.toProtobufJSON(options) : null
    };
  }
}
export module CreateReferralTransactionRequest {
  /**
   * Standard JavaScript object representation for CreateReferralTransactionRequest
   */
  export interface AsObject {
    data?: common_message008.UserReferralTransactionModel.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateReferralTransactionRequest
   */
  export interface AsProtobufJSON {
    data: common_message008.UserReferralTransactionModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.GetSummaryRequest
 */
export class GetSummaryRequest implements GrpcMessage {
  static id = 'user.GetSummaryRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSummaryRequest();
    GetSummaryRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSummaryRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.includeCurrency = _instance.includeCurrency || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSummaryRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.includeCurrency = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetSummaryRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSummaryRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.includeCurrency) {
      _writer.writeBool(2, _instance.includeCurrency);
    }
  }

  private _userId: number;
  private _includeCurrency: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSummaryRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSummaryRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    this.includeCurrency = _value.includeCurrency;
    GetSummaryRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get includeCurrency(): boolean {
    return this._includeCurrency;
  }
  set includeCurrency(value: boolean) {
    this._includeCurrency = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSummaryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSummaryRequest.AsObject {
    return {
      userId: this.userId,
      includeCurrency: this.includeCurrency
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSummaryRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      includeCurrency: this.includeCurrency
    };
  }
}
export module GetSummaryRequest {
  /**
   * Standard JavaScript object representation for GetSummaryRequest
   */
  export interface AsObject {
    userId: number;
    includeCurrency: boolean;
  }

  /**
   * Protobuf JSON representation for GetSummaryRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    includeCurrency: boolean;
  }
}

/**
 * Message implementation for user.GetSummaryResponse
 */
export class GetSummaryResponse implements GrpcMessage {
  static id = 'user.GetSummaryResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSummaryResponse();
    GetSummaryResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSummaryResponse) {
    _instance.riskProfile = _instance.riskProfile || '';
    _instance.balance = _instance.balance || 0;
    _instance.netDeposit = _instance.netDeposit || 0;
    _instance.earning = _instance.earning || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.bankRescueAmount = _instance.bankRescueAmount || [];
    _instance.bankDepositsAmount = _instance.bankDepositsAmount || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSummaryResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.riskProfile = _reader.readString();
          break;
        case 2:
          _instance.balance = _reader.readDouble();
          break;
        case 3:
          _instance.netDeposit = _reader.readDouble();
          break;
        case 4:
          _instance.earning = _reader.readDouble();
          break;
        case 5:
          _instance.currency = new common_message008.CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            common_message008.CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 6:
          const messageInitializer6 = new BankTransactionAmount();
          _reader.readMessage(
            messageInitializer6,
            BankTransactionAmount.deserializeBinaryFromReader
          );
          (_instance.bankRescueAmount = _instance.bankRescueAmount || []).push(
            messageInitializer6
          );
          break;
        case 7:
          const messageInitializer7 = new BankTransactionAmount();
          _reader.readMessage(
            messageInitializer7,
            BankTransactionAmount.deserializeBinaryFromReader
          );
          (_instance.bankDepositsAmount =
            _instance.bankDepositsAmount || []).push(messageInitializer7);
          break;
        default:
          _reader.skipField();
      }
    }

    GetSummaryResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSummaryResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.riskProfile) {
      _writer.writeString(1, _instance.riskProfile);
    }
    if (_instance.balance) {
      _writer.writeDouble(2, _instance.balance);
    }
    if (_instance.netDeposit) {
      _writer.writeDouble(3, _instance.netDeposit);
    }
    if (_instance.earning) {
      _writer.writeDouble(4, _instance.earning);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        5,
        _instance.currency as any,
        common_message008.CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.bankRescueAmount && _instance.bankRescueAmount.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.bankRescueAmount as any,
        BankTransactionAmount.serializeBinaryToWriter
      );
    }
    if (_instance.bankDepositsAmount && _instance.bankDepositsAmount.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.bankDepositsAmount as any,
        BankTransactionAmount.serializeBinaryToWriter
      );
    }
  }

  private _riskProfile: string;
  private _balance: number;
  private _netDeposit: number;
  private _earning: number;
  private _currency?: common_message008.CurrencyModel;
  private _bankRescueAmount?: BankTransactionAmount[];
  private _bankDepositsAmount?: BankTransactionAmount[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSummaryResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSummaryResponse.AsObject>) {
    _value = _value || {};
    this.riskProfile = _value.riskProfile;
    this.balance = _value.balance;
    this.netDeposit = _value.netDeposit;
    this.earning = _value.earning;
    this.currency = _value.currency
      ? new common_message008.CurrencyModel(_value.currency)
      : undefined;
    this.bankRescueAmount = (_value.bankRescueAmount || []).map(
      m => new BankTransactionAmount(m)
    );
    this.bankDepositsAmount = (_value.bankDepositsAmount || []).map(
      m => new BankTransactionAmount(m)
    );
    GetSummaryResponse.refineValues(this);
  }
  get riskProfile(): string {
    return this._riskProfile;
  }
  set riskProfile(value: string) {
    this._riskProfile = value;
  }
  get balance(): number {
    return this._balance;
  }
  set balance(value: number) {
    this._balance = value;
  }
  get netDeposit(): number {
    return this._netDeposit;
  }
  set netDeposit(value: number) {
    this._netDeposit = value;
  }
  get earning(): number {
    return this._earning;
  }
  set earning(value: number) {
    this._earning = value;
  }
  get currency(): common_message008.CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: common_message008.CurrencyModel | undefined) {
    this._currency = value;
  }
  get bankRescueAmount(): BankTransactionAmount[] | undefined {
    return this._bankRescueAmount;
  }
  set bankRescueAmount(value: BankTransactionAmount[] | undefined) {
    this._bankRescueAmount = value;
  }
  get bankDepositsAmount(): BankTransactionAmount[] | undefined {
    return this._bankDepositsAmount;
  }
  set bankDepositsAmount(value: BankTransactionAmount[] | undefined) {
    this._bankDepositsAmount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSummaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSummaryResponse.AsObject {
    return {
      riskProfile: this.riskProfile,
      balance: this.balance,
      netDeposit: this.netDeposit,
      earning: this.earning,
      currency: this.currency ? this.currency.toObject() : undefined,
      bankRescueAmount: (this.bankRescueAmount || []).map(m => m.toObject()),
      bankDepositsAmount: (this.bankDepositsAmount || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSummaryResponse.AsProtobufJSON {
    return {
      riskProfile: this.riskProfile,
      balance: this.balance,
      netDeposit: this.netDeposit,
      earning: this.earning,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      bankRescueAmount: (this.bankRescueAmount || []).map(m =>
        m.toProtobufJSON(options)
      ),
      bankDepositsAmount: (this.bankDepositsAmount || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetSummaryResponse {
  /**
   * Standard JavaScript object representation for GetSummaryResponse
   */
  export interface AsObject {
    riskProfile: string;
    balance: number;
    netDeposit: number;
    earning: number;
    currency?: common_message008.CurrencyModel.AsObject;
    bankRescueAmount?: BankTransactionAmount.AsObject[];
    bankDepositsAmount?: BankTransactionAmount.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetSummaryResponse
   */
  export interface AsProtobufJSON {
    riskProfile: string;
    balance: number;
    netDeposit: number;
    earning: number;
    currency: common_message008.CurrencyModel.AsProtobufJSON | null;
    bankRescueAmount: BankTransactionAmount.AsProtobufJSON[] | null;
    bankDepositsAmount: BankTransactionAmount.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for user.BankTransactionAmount
 */
export class BankTransactionAmount implements GrpcMessage {
  static id = 'user.BankTransactionAmount';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BankTransactionAmount();
    BankTransactionAmount.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BankTransactionAmount) {
    _instance.amount = _instance.amount || 0;
    _instance.transactionsNumber = _instance.transactionsNumber || 0;
    _instance.currency = _instance.currency || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BankTransactionAmount,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readDouble();
          break;
        case 2:
          _instance.transactionsNumber = _reader.readDouble();
          break;
        case 3:
          _instance.currency = new common_message008.CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            common_message008.CurrencyModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BankTransactionAmount.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BankTransactionAmount,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeDouble(1, _instance.amount);
    }
    if (_instance.transactionsNumber) {
      _writer.writeDouble(2, _instance.transactionsNumber);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        3,
        _instance.currency as any,
        common_message008.CurrencyModel.serializeBinaryToWriter
      );
    }
  }

  private _amount: number;
  private _transactionsNumber: number;
  private _currency?: common_message008.CurrencyModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BankTransactionAmount to deeply clone from
   */
  constructor(_value?: RecursivePartial<BankTransactionAmount.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.transactionsNumber = _value.transactionsNumber;
    this.currency = _value.currency
      ? new common_message008.CurrencyModel(_value.currency)
      : undefined;
    BankTransactionAmount.refineValues(this);
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get transactionsNumber(): number {
    return this._transactionsNumber;
  }
  set transactionsNumber(value: number) {
    this._transactionsNumber = value;
  }
  get currency(): common_message008.CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: common_message008.CurrencyModel | undefined) {
    this._currency = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BankTransactionAmount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BankTransactionAmount.AsObject {
    return {
      amount: this.amount,
      transactionsNumber: this.transactionsNumber,
      currency: this.currency ? this.currency.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BankTransactionAmount.AsProtobufJSON {
    return {
      amount: this.amount,
      transactionsNumber: this.transactionsNumber,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null
    };
  }
}
export module BankTransactionAmount {
  /**
   * Standard JavaScript object representation for BankTransactionAmount
   */
  export interface AsObject {
    amount: number;
    transactionsNumber: number;
    currency?: common_message008.CurrencyModel.AsObject;
  }

  /**
   * Protobuf JSON representation for BankTransactionAmount
   */
  export interface AsProtobufJSON {
    amount: number;
    transactionsNumber: number;
    currency: common_message008.CurrencyModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.RemoveBankAccountRequest
 */
export class RemoveBankAccountRequest implements GrpcMessage {
  static id = 'user.RemoveBankAccountRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemoveBankAccountRequest();
    RemoveBankAccountRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemoveBankAccountRequest) {
    _instance.userBankAccountId = _instance.userBankAccountId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemoveBankAccountRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userBankAccountId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    RemoveBankAccountRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemoveBankAccountRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userBankAccountId) {
      _writer.writeInt32(1, _instance.userBankAccountId);
    }
  }

  private _userBankAccountId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemoveBankAccountRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RemoveBankAccountRequest.AsObject>) {
    _value = _value || {};
    this.userBankAccountId = _value.userBankAccountId;
    RemoveBankAccountRequest.refineValues(this);
  }
  get userBankAccountId(): number {
    return this._userBankAccountId;
  }
  set userBankAccountId(value: number) {
    this._userBankAccountId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemoveBankAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemoveBankAccountRequest.AsObject {
    return {
      userBankAccountId: this.userBankAccountId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemoveBankAccountRequest.AsProtobufJSON {
    return {
      userBankAccountId: this.userBankAccountId
    };
  }
}
export module RemoveBankAccountRequest {
  /**
   * Standard JavaScript object representation for RemoveBankAccountRequest
   */
  export interface AsObject {
    userBankAccountId: number;
  }

  /**
   * Protobuf JSON representation for RemoveBankAccountRequest
   */
  export interface AsProtobufJSON {
    userBankAccountId: number;
  }
}

/**
 * Message implementation for user.BankAccountsResponse
 */
export class BankAccountsResponse implements GrpcMessage {
  static id = 'user.BankAccountsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BankAccountsResponse();
    BankAccountsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BankAccountsResponse) {
    _instance.userBankAccounts = _instance.userBankAccounts || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BankAccountsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.UserBankAccountModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.UserBankAccountModel.deserializeBinaryFromReader
          );
          (_instance.userBankAccounts = _instance.userBankAccounts || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BankAccountsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BankAccountsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.userBankAccounts && _instance.userBankAccounts.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.userBankAccounts as any,
        common_message008.UserBankAccountModel.serializeBinaryToWriter
      );
    }
  }

  private _userBankAccounts?: common_message008.UserBankAccountModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BankAccountsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<BankAccountsResponse.AsObject>) {
    _value = _value || {};
    this.userBankAccounts = (_value.userBankAccounts || []).map(
      m => new common_message008.UserBankAccountModel(m)
    );
    BankAccountsResponse.refineValues(this);
  }
  get userBankAccounts(): common_message008.UserBankAccountModel[] | undefined {
    return this._userBankAccounts;
  }
  set userBankAccounts(
    value: common_message008.UserBankAccountModel[] | undefined
  ) {
    this._userBankAccounts = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BankAccountsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BankAccountsResponse.AsObject {
    return {
      userBankAccounts: (this.userBankAccounts || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BankAccountsResponse.AsProtobufJSON {
    return {
      userBankAccounts: (this.userBankAccounts || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module BankAccountsResponse {
  /**
   * Standard JavaScript object representation for BankAccountsResponse
   */
  export interface AsObject {
    userBankAccounts?: common_message008.UserBankAccountModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for BankAccountsResponse
   */
  export interface AsProtobufJSON {
    userBankAccounts:
      | common_message008.UserBankAccountModel.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for user.BankAccountResponse
 */
export class BankAccountResponse implements GrpcMessage {
  static id = 'user.BankAccountResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BankAccountResponse();
    BankAccountResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BankAccountResponse) {
    _instance.userBankAccount = _instance.userBankAccount || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BankAccountResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userBankAccount = new common_message008.UserBankAccountModel();
          _reader.readMessage(
            _instance.userBankAccount,
            common_message008.UserBankAccountModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BankAccountResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BankAccountResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.userBankAccount) {
      _writer.writeMessage(
        1,
        _instance.userBankAccount as any,
        common_message008.UserBankAccountModel.serializeBinaryToWriter
      );
    }
  }

  private _userBankAccount?: common_message008.UserBankAccountModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BankAccountResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<BankAccountResponse.AsObject>) {
    _value = _value || {};
    this.userBankAccount = _value.userBankAccount
      ? new common_message008.UserBankAccountModel(_value.userBankAccount)
      : undefined;
    BankAccountResponse.refineValues(this);
  }
  get userBankAccount(): common_message008.UserBankAccountModel | undefined {
    return this._userBankAccount;
  }
  set userBankAccount(
    value: common_message008.UserBankAccountModel | undefined
  ) {
    this._userBankAccount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BankAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BankAccountResponse.AsObject {
    return {
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BankAccountResponse.AsProtobufJSON {
    return {
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toProtobufJSON(options)
        : null
    };
  }
}
export module BankAccountResponse {
  /**
   * Standard JavaScript object representation for BankAccountResponse
   */
  export interface AsObject {
    userBankAccount?: common_message008.UserBankAccountModel.AsObject;
  }

  /**
   * Protobuf JSON representation for BankAccountResponse
   */
  export interface AsProtobufJSON {
    userBankAccount: common_message008.UserBankAccountModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.GetBankAccountsRequest
 */
export class GetBankAccountsRequest implements GrpcMessage {
  static id = 'user.GetBankAccountsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBankAccountsRequest();
    GetBankAccountsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBankAccountsRequest) {
    _instance.finantialEntityId = _instance.finantialEntityId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBankAccountsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.finantialEntityId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.finantialEntityId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetBankAccountsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBankAccountsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.finantialEntityId) {
      _writer.writeMessage(
        1,
        _instance.finantialEntityId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _finantialEntityId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBankAccountsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetBankAccountsRequest.AsObject>) {
    _value = _value || {};
    this.finantialEntityId = _value.finantialEntityId
      ? new googleProtobuf000.Int32Value(_value.finantialEntityId)
      : undefined;
    GetBankAccountsRequest.refineValues(this);
  }
  get finantialEntityId(): googleProtobuf000.Int32Value | undefined {
    return this._finantialEntityId;
  }
  set finantialEntityId(value: googleProtobuf000.Int32Value | undefined) {
    this._finantialEntityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBankAccountsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBankAccountsRequest.AsObject {
    return {
      finantialEntityId: this.finantialEntityId
        ? this.finantialEntityId.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBankAccountsRequest.AsProtobufJSON {
    return {
      finantialEntityId: this.finantialEntityId
        ? this.finantialEntityId.toProtobufJSON(options)
        : null
    };
  }
}
export module GetBankAccountsRequest {
  /**
   * Standard JavaScript object representation for GetBankAccountsRequest
   */
  export interface AsObject {
    finantialEntityId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for GetBankAccountsRequest
   */
  export interface AsProtobufJSON {
    finantialEntityId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.SetBankAccountRequest
 */
export class SetBankAccountRequest implements GrpcMessage {
  static id = 'user.SetBankAccountRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetBankAccountRequest();
    SetBankAccountRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetBankAccountRequest) {
    _instance.finantialEntityId = _instance.finantialEntityId || 0;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.bank = _instance.bank || '';
    _instance.accountNumber = _instance.accountNumber || '';
    _instance.accountType = _instance.accountType || '';
    _instance.state = _instance.state || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetBankAccountRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.finantialEntityId = _reader.readInt32();
          break;
        case 2:
          _instance.currencyId = _reader.readInt32();
          break;
        case 3:
          _instance.bank = _reader.readString();
          break;
        case 4:
          _instance.accountNumber = _reader.readString();
          break;
        case 5:
          _instance.accountType = _reader.readString();
          break;
        case 6:
          _instance.state = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SetBankAccountRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetBankAccountRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.finantialEntityId) {
      _writer.writeInt32(1, _instance.finantialEntityId);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(2, _instance.currencyId);
    }
    if (_instance.bank) {
      _writer.writeString(3, _instance.bank);
    }
    if (_instance.accountNumber) {
      _writer.writeString(4, _instance.accountNumber);
    }
    if (_instance.accountType) {
      _writer.writeString(5, _instance.accountType);
    }
    if (_instance.state) {
      _writer.writeString(6, _instance.state);
    }
  }

  private _finantialEntityId: number;
  private _currencyId: number;
  private _bank: string;
  private _accountNumber: string;
  private _accountType: string;
  private _state: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetBankAccountRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetBankAccountRequest.AsObject>) {
    _value = _value || {};
    this.finantialEntityId = _value.finantialEntityId;
    this.currencyId = _value.currencyId;
    this.bank = _value.bank;
    this.accountNumber = _value.accountNumber;
    this.accountType = _value.accountType;
    this.state = _value.state;
    SetBankAccountRequest.refineValues(this);
  }
  get finantialEntityId(): number {
    return this._finantialEntityId;
  }
  set finantialEntityId(value: number) {
    this._finantialEntityId = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get bank(): string {
    return this._bank;
  }
  set bank(value: string) {
    this._bank = value;
  }
  get accountNumber(): string {
    return this._accountNumber;
  }
  set accountNumber(value: string) {
    this._accountNumber = value;
  }
  get accountType(): string {
    return this._accountType;
  }
  set accountType(value: string) {
    this._accountType = value;
  }
  get state(): string {
    return this._state;
  }
  set state(value: string) {
    this._state = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetBankAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetBankAccountRequest.AsObject {
    return {
      finantialEntityId: this.finantialEntityId,
      currencyId: this.currencyId,
      bank: this.bank,
      accountNumber: this.accountNumber,
      accountType: this.accountType,
      state: this.state
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetBankAccountRequest.AsProtobufJSON {
    return {
      finantialEntityId: this.finantialEntityId,
      currencyId: this.currencyId,
      bank: this.bank,
      accountNumber: this.accountNumber,
      accountType: this.accountType,
      state: this.state
    };
  }
}
export module SetBankAccountRequest {
  /**
   * Standard JavaScript object representation for SetBankAccountRequest
   */
  export interface AsObject {
    finantialEntityId: number;
    currencyId: number;
    bank: string;
    accountNumber: string;
    accountType: string;
    state: string;
  }

  /**
   * Protobuf JSON representation for SetBankAccountRequest
   */
  export interface AsProtobufJSON {
    finantialEntityId: number;
    currencyId: number;
    bank: string;
    accountNumber: string;
    accountType: string;
    state: string;
  }
}

/**
 * Message implementation for user.CreateReferralRelationRequest
 */
export class CreateReferralRelationRequest implements GrpcMessage {
  static id = 'user.CreateReferralRelationRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateReferralRelationRequest();
    CreateReferralRelationRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateReferralRelationRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.referralCode = _instance.referralCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateReferralRelationRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.referralCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateReferralRelationRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateReferralRelationRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.referralCode) {
      _writer.writeString(2, _instance.referralCode);
    }
  }

  private _userId: number;
  private _referralCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateReferralRelationRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CreateReferralRelationRequest.AsObject>
  ) {
    _value = _value || {};
    this.userId = _value.userId;
    this.referralCode = _value.referralCode;
    CreateReferralRelationRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get referralCode(): string {
    return this._referralCode;
  }
  set referralCode(value: string) {
    this._referralCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateReferralRelationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateReferralRelationRequest.AsObject {
    return {
      userId: this.userId,
      referralCode: this.referralCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateReferralRelationRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      referralCode: this.referralCode
    };
  }
}
export module CreateReferralRelationRequest {
  /**
   * Standard JavaScript object representation for CreateReferralRelationRequest
   */
  export interface AsObject {
    userId: number;
    referralCode: string;
  }

  /**
   * Protobuf JSON representation for CreateReferralRelationRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    referralCode: string;
  }
}

/**
 * Message implementation for user.CreateUserCodeRequest
 */
export class CreateUserCodeRequest implements GrpcMessage {
  static id = 'user.CreateUserCodeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateUserCodeRequest();
    CreateUserCodeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateUserCodeRequest) {
    _instance.id = _instance.id || undefined;
    _instance.userId = _instance.userId || 0;
    _instance.type = _instance.type || 0;
    _instance.code = _instance.code || '';
    _instance.status = _instance.status || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateUserCodeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.id,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.userId = _reader.readInt32();
          break;
        case 3:
          _instance.type = _reader.readEnum();
          break;
        case 4:
          _instance.code = _reader.readString();
          break;
        case 5:
          _instance.status = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateUserCodeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateUserCodeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeMessage(
        1,
        _instance.id as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.userId) {
      _writer.writeInt32(2, _instance.userId);
    }
    if (_instance.type) {
      _writer.writeEnum(3, _instance.type);
    }
    if (_instance.code) {
      _writer.writeString(4, _instance.code);
    }
    if (_instance.status) {
      _writer.writeString(5, _instance.status);
    }
  }

  private _id?: googleProtobuf000.Int32Value;
  private _userId: number;
  private _type: common_message008.UserCodeTypeEnum;
  private _code: string;
  private _status: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateUserCodeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateUserCodeRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id
      ? new googleProtobuf000.Int32Value(_value.id)
      : undefined;
    this.userId = _value.userId;
    this.type = _value.type;
    this.code = _value.code;
    this.status = _value.status;
    CreateUserCodeRequest.refineValues(this);
  }
  get id(): googleProtobuf000.Int32Value | undefined {
    return this._id;
  }
  set id(value: googleProtobuf000.Int32Value | undefined) {
    this._id = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get type(): common_message008.UserCodeTypeEnum {
    return this._type;
  }
  set type(value: common_message008.UserCodeTypeEnum) {
    this._type = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateUserCodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateUserCodeRequest.AsObject {
    return {
      id: this.id ? this.id.toObject() : undefined,
      userId: this.userId,
      type: this.type,
      code: this.code,
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateUserCodeRequest.AsProtobufJSON {
    return {
      id: this.id ? this.id.toProtobufJSON(options) : null,
      userId: this.userId,
      type:
        common_message008.UserCodeTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      code: this.code,
      status: this.status
    };
  }
}
export module CreateUserCodeRequest {
  /**
   * Standard JavaScript object representation for CreateUserCodeRequest
   */
  export interface AsObject {
    id?: googleProtobuf000.Int32Value.AsObject;
    userId: number;
    type: common_message008.UserCodeTypeEnum;
    code: string;
    status: string;
  }

  /**
   * Protobuf JSON representation for CreateUserCodeRequest
   */
  export interface AsProtobufJSON {
    id: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    userId: number;
    type: string;
    code: string;
    status: string;
  }
}

/**
 * Message implementation for user.GetActiveCodesRequest
 */
export class GetActiveCodesRequest implements GrpcMessage {
  static id = 'user.GetActiveCodesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetActiveCodesRequest();
    GetActiveCodesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetActiveCodesRequest) {
    _instance.type = _instance.type || 0;
    _instance.userId = _instance.userId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetActiveCodesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.userId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetActiveCodesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetActiveCodesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.userId) {
      _writer.writeMessage(
        2,
        _instance.userId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _type: common_message008.UserCodeTypeEnum;
  private _userId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetActiveCodesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetActiveCodesRequest.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.userId = _value.userId
      ? new googleProtobuf000.Int32Value(_value.userId)
      : undefined;
    GetActiveCodesRequest.refineValues(this);
  }
  get type(): common_message008.UserCodeTypeEnum {
    return this._type;
  }
  set type(value: common_message008.UserCodeTypeEnum) {
    this._type = value;
  }
  get userId(): googleProtobuf000.Int32Value | undefined {
    return this._userId;
  }
  set userId(value: googleProtobuf000.Int32Value | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetActiveCodesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetActiveCodesRequest.AsObject {
    return {
      type: this.type,
      userId: this.userId ? this.userId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetActiveCodesRequest.AsProtobufJSON {
    return {
      type:
        common_message008.UserCodeTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      userId: this.userId ? this.userId.toProtobufJSON(options) : null
    };
  }
}
export module GetActiveCodesRequest {
  /**
   * Standard JavaScript object representation for GetActiveCodesRequest
   */
  export interface AsObject {
    type: common_message008.UserCodeTypeEnum;
    userId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for GetActiveCodesRequest
   */
  export interface AsProtobufJSON {
    type: string;
    userId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.UserCodeReferralsResponse
 */
export class UserCodeReferralsResponse implements GrpcMessage {
  static id = 'user.UserCodeReferralsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserCodeReferralsResponse();
    UserCodeReferralsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserCodeReferralsResponse) {
    _instance.referrals = _instance.referrals || [];
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserCodeReferralsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.UserReferralInfoModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.UserReferralInfoModel.deserializeBinaryFromReader
          );
          (_instance.referrals = _instance.referrals || []).push(
            messageInitializer1
          );
          break;
        case 2:
          _instance.status = _reader.readInt32();
          break;
        case 3:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserCodeReferralsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserCodeReferralsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.referrals && _instance.referrals.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.referrals as any,
        common_message008.UserReferralInfoModel.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeInt32(2, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(3, _instance.message);
    }
  }

  private _referrals?: common_message008.UserReferralInfoModel[];
  private _status: number;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserCodeReferralsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserCodeReferralsResponse.AsObject>) {
    _value = _value || {};
    this.referrals = (_value.referrals || []).map(
      m => new common_message008.UserReferralInfoModel(m)
    );
    this.status = _value.status;
    this.message = _value.message;
    UserCodeReferralsResponse.refineValues(this);
  }
  get referrals(): common_message008.UserReferralInfoModel[] | undefined {
    return this._referrals;
  }
  set referrals(value: common_message008.UserReferralInfoModel[] | undefined) {
    this._referrals = value;
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserCodeReferralsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserCodeReferralsResponse.AsObject {
    return {
      referrals: (this.referrals || []).map(m => m.toObject()),
      status: this.status,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserCodeReferralsResponse.AsProtobufJSON {
    return {
      referrals: (this.referrals || []).map(m => m.toProtobufJSON(options)),
      status: this.status,
      message: this.message
    };
  }
}
export module UserCodeReferralsResponse {
  /**
   * Standard JavaScript object representation for UserCodeReferralsResponse
   */
  export interface AsObject {
    referrals?: common_message008.UserReferralInfoModel.AsObject[];
    status: number;
    message: string;
  }

  /**
   * Protobuf JSON representation for UserCodeReferralsResponse
   */
  export interface AsProtobufJSON {
    referrals: common_message008.UserReferralInfoModel.AsProtobufJSON[] | null;
    status: number;
    message: string;
  }
}

/**
 * Message implementation for user.ListUserCodeResponse
 */
export class ListUserCodeResponse implements GrpcMessage {
  static id = 'user.ListUserCodeResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListUserCodeResponse();
    ListUserCodeResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListUserCodeResponse) {
    _instance.values = _instance.values || [];
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListUserCodeResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.UserCodeModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.UserCodeModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.status = _reader.readInt32();
          break;
        case 3:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ListUserCodeResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListUserCodeResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.UserCodeModel.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeInt32(2, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(3, _instance.message);
    }
  }

  private _values?: common_message008.UserCodeModel[];
  private _status: number;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListUserCodeResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListUserCodeResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.UserCodeModel(m)
    );
    this.status = _value.status;
    this.message = _value.message;
    ListUserCodeResponse.refineValues(this);
  }
  get values(): common_message008.UserCodeModel[] | undefined {
    return this._values;
  }
  set values(value: common_message008.UserCodeModel[] | undefined) {
    this._values = value;
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListUserCodeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListUserCodeResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      status: this.status,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListUserCodeResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      status: this.status,
      message: this.message
    };
  }
}
export module ListUserCodeResponse {
  /**
   * Standard JavaScript object representation for ListUserCodeResponse
   */
  export interface AsObject {
    values?: common_message008.UserCodeModel.AsObject[];
    status: number;
    message: string;
  }

  /**
   * Protobuf JSON representation for ListUserCodeResponse
   */
  export interface AsProtobufJSON {
    values: common_message008.UserCodeModel.AsProtobufJSON[] | null;
    status: number;
    message: string;
  }
}

/**
 * Message implementation for user.ListUserResponse
 */
export class ListUserResponse implements GrpcMessage {
  static id = 'user.ListUserResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListUserResponse();
    ListUserResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListUserResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListUserResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.UserModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.UserModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListUserResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListUserResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message008.UserModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListUserResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListUserResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.UserModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListUserResponse.refineValues(this);
  }
  get values(): common_message008.UserModel[] | undefined {
    return this._values;
  }
  set values(value: common_message008.UserModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListUserResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListUserResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListUserResponse {
  /**
   * Standard JavaScript object representation for ListUserResponse
   */
  export interface AsObject {
    values?: common_message008.UserModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListUserResponse
   */
  export interface AsProtobufJSON {
    values: common_message008.UserModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for user.AddOrUpdateDeviceInstallationRequest
 */
export class AddOrUpdateDeviceInstallationRequest implements GrpcMessage {
  static id = 'user.AddOrUpdateDeviceInstallationRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddOrUpdateDeviceInstallationRequest();
    AddOrUpdateDeviceInstallationRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddOrUpdateDeviceInstallationRequest) {
    _instance.installationId = _instance.installationId || '';
    _instance.platform = _instance.platform || '';
    _instance.token = _instance.token || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddOrUpdateDeviceInstallationRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.installationId = _reader.readString();
          break;
        case 2:
          _instance.platform = _reader.readString();
          break;
        case 3:
          _instance.token = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddOrUpdateDeviceInstallationRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddOrUpdateDeviceInstallationRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.installationId) {
      _writer.writeString(1, _instance.installationId);
    }
    if (_instance.platform) {
      _writer.writeString(2, _instance.platform);
    }
    if (_instance.token) {
      _writer.writeString(3, _instance.token);
    }
  }

  private _installationId: string;
  private _platform: string;
  private _token: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddOrUpdateDeviceInstallationRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddOrUpdateDeviceInstallationRequest.AsObject>
  ) {
    _value = _value || {};
    this.installationId = _value.installationId;
    this.platform = _value.platform;
    this.token = _value.token;
    AddOrUpdateDeviceInstallationRequest.refineValues(this);
  }
  get installationId(): string {
    return this._installationId;
  }
  set installationId(value: string) {
    this._installationId = value;
  }
  get platform(): string {
    return this._platform;
  }
  set platform(value: string) {
    this._platform = value;
  }
  get token(): string {
    return this._token;
  }
  set token(value: string) {
    this._token = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddOrUpdateDeviceInstallationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddOrUpdateDeviceInstallationRequest.AsObject {
    return {
      installationId: this.installationId,
      platform: this.platform,
      token: this.token
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddOrUpdateDeviceInstallationRequest.AsProtobufJSON {
    return {
      installationId: this.installationId,
      platform: this.platform,
      token: this.token
    };
  }
}
export module AddOrUpdateDeviceInstallationRequest {
  /**
   * Standard JavaScript object representation for AddOrUpdateDeviceInstallationRequest
   */
  export interface AsObject {
    installationId: string;
    platform: string;
    token: string;
  }

  /**
   * Protobuf JSON representation for AddOrUpdateDeviceInstallationRequest
   */
  export interface AsProtobufJSON {
    installationId: string;
    platform: string;
    token: string;
  }
}

/**
 * Message implementation for user.RemoveDeviceInstallationRequest
 */
export class RemoveDeviceInstallationRequest implements GrpcMessage {
  static id = 'user.RemoveDeviceInstallationRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemoveDeviceInstallationRequest();
    RemoveDeviceInstallationRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemoveDeviceInstallationRequest) {
    _instance.installationId = _instance.installationId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemoveDeviceInstallationRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.installationId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RemoveDeviceInstallationRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemoveDeviceInstallationRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.installationId) {
      _writer.writeString(1, _instance.installationId);
    }
  }

  private _installationId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemoveDeviceInstallationRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<RemoveDeviceInstallationRequest.AsObject>
  ) {
    _value = _value || {};
    this.installationId = _value.installationId;
    RemoveDeviceInstallationRequest.refineValues(this);
  }
  get installationId(): string {
    return this._installationId;
  }
  set installationId(value: string) {
    this._installationId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemoveDeviceInstallationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemoveDeviceInstallationRequest.AsObject {
    return {
      installationId: this.installationId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemoveDeviceInstallationRequest.AsProtobufJSON {
    return {
      installationId: this.installationId
    };
  }
}
export module RemoveDeviceInstallationRequest {
  /**
   * Standard JavaScript object representation for RemoveDeviceInstallationRequest
   */
  export interface AsObject {
    installationId: string;
  }

  /**
   * Protobuf JSON representation for RemoveDeviceInstallationRequest
   */
  export interface AsProtobufJSON {
    installationId: string;
  }
}

/**
 * Message implementation for user.SendUserFeedbackRequest
 */
export class SendUserFeedbackRequest implements GrpcMessage {
  static id = 'user.SendUserFeedbackRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendUserFeedbackRequest();
    SendUserFeedbackRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendUserFeedbackRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.userFeedbackMessage = _instance.userFeedbackMessage || '';
    _instance.userFeedbackType = _instance.userFeedbackType || '';
    _instance.userFeedbackExtra = _instance.userFeedbackExtra || '';
    _instance.transactionRequestId = _instance.transactionRequestId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendUserFeedbackRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.userFeedbackMessage = _reader.readString();
          break;
        case 3:
          _instance.userFeedbackType = _reader.readString();
          break;
        case 4:
          _instance.userFeedbackExtra = _reader.readString();
          break;
        case 5:
          _instance.transactionRequestId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    SendUserFeedbackRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendUserFeedbackRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.userFeedbackMessage) {
      _writer.writeString(2, _instance.userFeedbackMessage);
    }
    if (_instance.userFeedbackType) {
      _writer.writeString(3, _instance.userFeedbackType);
    }
    if (_instance.userFeedbackExtra) {
      _writer.writeString(4, _instance.userFeedbackExtra);
    }
    if (_instance.transactionRequestId) {
      _writer.writeInt32(5, _instance.transactionRequestId);
    }
  }

  private _userId: number;
  private _userFeedbackMessage: string;
  private _userFeedbackType: string;
  private _userFeedbackExtra: string;
  private _transactionRequestId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendUserFeedbackRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendUserFeedbackRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    this.userFeedbackMessage = _value.userFeedbackMessage;
    this.userFeedbackType = _value.userFeedbackType;
    this.userFeedbackExtra = _value.userFeedbackExtra;
    this.transactionRequestId = _value.transactionRequestId;
    SendUserFeedbackRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get userFeedbackMessage(): string {
    return this._userFeedbackMessage;
  }
  set userFeedbackMessage(value: string) {
    this._userFeedbackMessage = value;
  }
  get userFeedbackType(): string {
    return this._userFeedbackType;
  }
  set userFeedbackType(value: string) {
    this._userFeedbackType = value;
  }
  get userFeedbackExtra(): string {
    return this._userFeedbackExtra;
  }
  set userFeedbackExtra(value: string) {
    this._userFeedbackExtra = value;
  }
  get transactionRequestId(): number {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: number) {
    this._transactionRequestId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendUserFeedbackRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendUserFeedbackRequest.AsObject {
    return {
      userId: this.userId,
      userFeedbackMessage: this.userFeedbackMessage,
      userFeedbackType: this.userFeedbackType,
      userFeedbackExtra: this.userFeedbackExtra,
      transactionRequestId: this.transactionRequestId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendUserFeedbackRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      userFeedbackMessage: this.userFeedbackMessage,
      userFeedbackType: this.userFeedbackType,
      userFeedbackExtra: this.userFeedbackExtra,
      transactionRequestId: this.transactionRequestId
    };
  }
}
export module SendUserFeedbackRequest {
  /**
   * Standard JavaScript object representation for SendUserFeedbackRequest
   */
  export interface AsObject {
    userId: number;
    userFeedbackMessage: string;
    userFeedbackType: string;
    userFeedbackExtra: string;
    transactionRequestId: number;
  }

  /**
   * Protobuf JSON representation for SendUserFeedbackRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    userFeedbackMessage: string;
    userFeedbackType: string;
    userFeedbackExtra: string;
    transactionRequestId: number;
  }
}

/**
 * Message implementation for user.SendReferralsEmailRequest
 */
export class SendReferralsEmailRequest implements GrpcMessage {
  static id = 'user.SendReferralsEmailRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendReferralsEmailRequest();
    SendReferralsEmailRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendReferralsEmailRequest) {
    _instance.referralEmail = _instance.referralEmail || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendReferralsEmailRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.referralEmail = _instance.referralEmail || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SendReferralsEmailRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendReferralsEmailRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.referralEmail && _instance.referralEmail.length) {
      _writer.writeRepeatedString(1, _instance.referralEmail);
    }
  }

  private _referralEmail: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendReferralsEmailRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendReferralsEmailRequest.AsObject>) {
    _value = _value || {};
    this.referralEmail = (_value.referralEmail || []).slice();
    SendReferralsEmailRequest.refineValues(this);
  }
  get referralEmail(): string[] {
    return this._referralEmail;
  }
  set referralEmail(value: string[]) {
    this._referralEmail = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendReferralsEmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendReferralsEmailRequest.AsObject {
    return {
      referralEmail: (this.referralEmail || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendReferralsEmailRequest.AsProtobufJSON {
    return {
      referralEmail: (this.referralEmail || []).slice()
    };
  }
}
export module SendReferralsEmailRequest {
  /**
   * Standard JavaScript object representation for SendReferralsEmailRequest
   */
  export interface AsObject {
    referralEmail: string[];
  }

  /**
   * Protobuf JSON representation for SendReferralsEmailRequest
   */
  export interface AsProtobufJSON {
    referralEmail: string[];
  }
}

/**
 * Message implementation for user.SendGoalMilestoneEmailRequest
 */
export class SendGoalMilestoneEmailRequest implements GrpcMessage {
  static id = 'user.SendGoalMilestoneEmailRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendGoalMilestoneEmailRequest();
    SendGoalMilestoneEmailRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendGoalMilestoneEmailRequest) {
    _instance.userName = _instance.userName || '';
    _instance.userEmail = _instance.userEmail || '';
    _instance.goalName = _instance.goalName || '';
    _instance.goalMilestoneMessage = _instance.goalMilestoneMessage || '';
    _instance.goalMilestoneType = _instance.goalMilestoneType || '';
    _instance.fundingTransaction = _instance.fundingTransaction || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendGoalMilestoneEmailRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userName = _reader.readString();
          break;
        case 2:
          _instance.userEmail = _reader.readString();
          break;
        case 3:
          _instance.goalName = _reader.readString();
          break;
        case 4:
          _instance.goalMilestoneMessage = _reader.readString();
          break;
        case 5:
          _instance.goalMilestoneType = _reader.readString();
          break;
        case 6:
          const messageInitializer6 = new SendGoalMilestoneEmailTrasnsactionFunding();
          _reader.readMessage(
            messageInitializer6,
            SendGoalMilestoneEmailTrasnsactionFunding.deserializeBinaryFromReader
          );
          (_instance.fundingTransaction =
            _instance.fundingTransaction || []).push(messageInitializer6);
          break;
        default:
          _reader.skipField();
      }
    }

    SendGoalMilestoneEmailRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendGoalMilestoneEmailRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userName) {
      _writer.writeString(1, _instance.userName);
    }
    if (_instance.userEmail) {
      _writer.writeString(2, _instance.userEmail);
    }
    if (_instance.goalName) {
      _writer.writeString(3, _instance.goalName);
    }
    if (_instance.goalMilestoneMessage) {
      _writer.writeString(4, _instance.goalMilestoneMessage);
    }
    if (_instance.goalMilestoneType) {
      _writer.writeString(5, _instance.goalMilestoneType);
    }
    if (_instance.fundingTransaction && _instance.fundingTransaction.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.fundingTransaction as any,
        SendGoalMilestoneEmailTrasnsactionFunding.serializeBinaryToWriter
      );
    }
  }

  private _userName: string;
  private _userEmail: string;
  private _goalName: string;
  private _goalMilestoneMessage: string;
  private _goalMilestoneType: string;
  private _fundingTransaction?: SendGoalMilestoneEmailTrasnsactionFunding[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendGoalMilestoneEmailRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SendGoalMilestoneEmailRequest.AsObject>
  ) {
    _value = _value || {};
    this.userName = _value.userName;
    this.userEmail = _value.userEmail;
    this.goalName = _value.goalName;
    this.goalMilestoneMessage = _value.goalMilestoneMessage;
    this.goalMilestoneType = _value.goalMilestoneType;
    this.fundingTransaction = (_value.fundingTransaction || []).map(
      m => new SendGoalMilestoneEmailTrasnsactionFunding(m)
    );
    SendGoalMilestoneEmailRequest.refineValues(this);
  }
  get userName(): string {
    return this._userName;
  }
  set userName(value: string) {
    this._userName = value;
  }
  get userEmail(): string {
    return this._userEmail;
  }
  set userEmail(value: string) {
    this._userEmail = value;
  }
  get goalName(): string {
    return this._goalName;
  }
  set goalName(value: string) {
    this._goalName = value;
  }
  get goalMilestoneMessage(): string {
    return this._goalMilestoneMessage;
  }
  set goalMilestoneMessage(value: string) {
    this._goalMilestoneMessage = value;
  }
  get goalMilestoneType(): string {
    return this._goalMilestoneType;
  }
  set goalMilestoneType(value: string) {
    this._goalMilestoneType = value;
  }
  get fundingTransaction():
    | SendGoalMilestoneEmailTrasnsactionFunding[]
    | undefined {
    return this._fundingTransaction;
  }
  set fundingTransaction(
    value: SendGoalMilestoneEmailTrasnsactionFunding[] | undefined
  ) {
    this._fundingTransaction = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendGoalMilestoneEmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendGoalMilestoneEmailRequest.AsObject {
    return {
      userName: this.userName,
      userEmail: this.userEmail,
      goalName: this.goalName,
      goalMilestoneMessage: this.goalMilestoneMessage,
      goalMilestoneType: this.goalMilestoneType,
      fundingTransaction: (this.fundingTransaction || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendGoalMilestoneEmailRequest.AsProtobufJSON {
    return {
      userName: this.userName,
      userEmail: this.userEmail,
      goalName: this.goalName,
      goalMilestoneMessage: this.goalMilestoneMessage,
      goalMilestoneType: this.goalMilestoneType,
      fundingTransaction: (this.fundingTransaction || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module SendGoalMilestoneEmailRequest {
  /**
   * Standard JavaScript object representation for SendGoalMilestoneEmailRequest
   */
  export interface AsObject {
    userName: string;
    userEmail: string;
    goalName: string;
    goalMilestoneMessage: string;
    goalMilestoneType: string;
    fundingTransaction?: SendGoalMilestoneEmailTrasnsactionFunding.AsObject[];
  }

  /**
   * Protobuf JSON representation for SendGoalMilestoneEmailRequest
   */
  export interface AsProtobufJSON {
    userName: string;
    userEmail: string;
    goalName: string;
    goalMilestoneMessage: string;
    goalMilestoneType: string;
    fundingTransaction:
      | SendGoalMilestoneEmailTrasnsactionFunding.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for user.SendGoalMilestoneEmailTrasnsactionFunding
 */
export class SendGoalMilestoneEmailTrasnsactionFunding implements GrpcMessage {
  static id = 'user.SendGoalMilestoneEmailTrasnsactionFunding';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendGoalMilestoneEmailTrasnsactionFunding();
    SendGoalMilestoneEmailTrasnsactionFunding.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendGoalMilestoneEmailTrasnsactionFunding) {
    _instance.fundingName = _instance.fundingName || '';
    _instance.fundingAmount = _instance.fundingAmount || 0;
    _instance.fundingQuotas = _instance.fundingQuotas || 0;
    _instance.fundingIsBox = _instance.fundingIsBox || false;
    _instance.fundingAmountFormat = _instance.fundingAmountFormat || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SendGoalMilestoneEmailTrasnsactionFunding,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fundingName = _reader.readString();
          break;
        case 2:
          _instance.fundingAmount = _reader.readDouble();
          break;
        case 3:
          _instance.fundingQuotas = _reader.readDouble();
          break;
        case 4:
          _instance.fundingIsBox = _reader.readBool();
          break;
        case 5:
          _instance.fundingAmountFormat = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SendGoalMilestoneEmailTrasnsactionFunding.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SendGoalMilestoneEmailTrasnsactionFunding,
    _writer: BinaryWriter
  ) {
    if (_instance.fundingName) {
      _writer.writeString(1, _instance.fundingName);
    }
    if (_instance.fundingAmount) {
      _writer.writeDouble(2, _instance.fundingAmount);
    }
    if (_instance.fundingQuotas) {
      _writer.writeDouble(3, _instance.fundingQuotas);
    }
    if (_instance.fundingIsBox) {
      _writer.writeBool(4, _instance.fundingIsBox);
    }
    if (_instance.fundingAmountFormat) {
      _writer.writeString(5, _instance.fundingAmountFormat);
    }
  }

  private _fundingName: string;
  private _fundingAmount: number;
  private _fundingQuotas: number;
  private _fundingIsBox: boolean;
  private _fundingAmountFormat: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendGoalMilestoneEmailTrasnsactionFunding to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      SendGoalMilestoneEmailTrasnsactionFunding.AsObject
    >
  ) {
    _value = _value || {};
    this.fundingName = _value.fundingName;
    this.fundingAmount = _value.fundingAmount;
    this.fundingQuotas = _value.fundingQuotas;
    this.fundingIsBox = _value.fundingIsBox;
    this.fundingAmountFormat = _value.fundingAmountFormat;
    SendGoalMilestoneEmailTrasnsactionFunding.refineValues(this);
  }
  get fundingName(): string {
    return this._fundingName;
  }
  set fundingName(value: string) {
    this._fundingName = value;
  }
  get fundingAmount(): number {
    return this._fundingAmount;
  }
  set fundingAmount(value: number) {
    this._fundingAmount = value;
  }
  get fundingQuotas(): number {
    return this._fundingQuotas;
  }
  set fundingQuotas(value: number) {
    this._fundingQuotas = value;
  }
  get fundingIsBox(): boolean {
    return this._fundingIsBox;
  }
  set fundingIsBox(value: boolean) {
    this._fundingIsBox = value;
  }
  get fundingAmountFormat(): string {
    return this._fundingAmountFormat;
  }
  set fundingAmountFormat(value: string) {
    this._fundingAmountFormat = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendGoalMilestoneEmailTrasnsactionFunding.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendGoalMilestoneEmailTrasnsactionFunding.AsObject {
    return {
      fundingName: this.fundingName,
      fundingAmount: this.fundingAmount,
      fundingQuotas: this.fundingQuotas,
      fundingIsBox: this.fundingIsBox,
      fundingAmountFormat: this.fundingAmountFormat
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SendGoalMilestoneEmailTrasnsactionFunding.AsProtobufJSON {
    return {
      fundingName: this.fundingName,
      fundingAmount: this.fundingAmount,
      fundingQuotas: this.fundingQuotas,
      fundingIsBox: this.fundingIsBox,
      fundingAmountFormat: this.fundingAmountFormat
    };
  }
}
export module SendGoalMilestoneEmailTrasnsactionFunding {
  /**
   * Standard JavaScript object representation for SendGoalMilestoneEmailTrasnsactionFunding
   */
  export interface AsObject {
    fundingName: string;
    fundingAmount: number;
    fundingQuotas: number;
    fundingIsBox: boolean;
    fundingAmountFormat: string;
  }

  /**
   * Protobuf JSON representation for SendGoalMilestoneEmailTrasnsactionFunding
   */
  export interface AsProtobufJSON {
    fundingName: string;
    fundingAmount: number;
    fundingQuotas: number;
    fundingIsBox: boolean;
    fundingAmountFormat: string;
  }
}

/**
 * Message implementation for user.GetEntityByRutRequest
 */
export class GetEntityByRutRequest implements GrpcMessage {
  static id = 'user.GetEntityByRutRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetEntityByRutRequest();
    GetEntityByRutRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetEntityByRutRequest) {
    _instance.rut = _instance.rut || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetEntityByRutRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rut = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetEntityByRutRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetEntityByRutRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.rut) {
      _writer.writeString(1, _instance.rut);
    }
  }

  private _rut: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetEntityByRutRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetEntityByRutRequest.AsObject>) {
    _value = _value || {};
    this.rut = _value.rut;
    GetEntityByRutRequest.refineValues(this);
  }
  get rut(): string {
    return this._rut;
  }
  set rut(value: string) {
    this._rut = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetEntityByRutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetEntityByRutRequest.AsObject {
    return {
      rut: this.rut
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetEntityByRutRequest.AsProtobufJSON {
    return {
      rut: this.rut
    };
  }
}
export module GetEntityByRutRequest {
  /**
   * Standard JavaScript object representation for GetEntityByRutRequest
   */
  export interface AsObject {
    rut: string;
  }

  /**
   * Protobuf JSON representation for GetEntityByRutRequest
   */
  export interface AsProtobufJSON {
    rut: string;
  }
}

/**
 * Message implementation for user.AddDocumentRequest
 */
export class AddDocumentRequest implements GrpcMessage {
  static id = 'user.AddDocumentRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddDocumentRequest();
    AddDocumentRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddDocumentRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.financialEntityId = _instance.financialEntityId || undefined;
    _instance.fileName = _instance.fileName || '';
    _instance.file = _instance.file || undefined;
    _instance.signFile = _instance.signFile || undefined;
    _instance.documentType = _instance.documentType || '';
    _instance.route = _instance.route || undefined;
    _instance.listedAsDocument = _instance.listedAsDocument || undefined;
    _instance.acl = _instance.acl || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddDocumentRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.financialEntityId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.financialEntityId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.fileName = _reader.readString();
          break;
        case 4:
          _instance.file = new file_message007.FileModel();
          _reader.readMessage(
            _instance.file,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.signFile = new file_message007.FileModel();
          _reader.readMessage(
            _instance.signFile,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.documentType = _reader.readString();
          break;
        case 7:
          _instance.route = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.route,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.listedAsDocument = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.listedAsDocument,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.acl = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.acl,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddDocumentRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddDocumentRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.financialEntityId) {
      _writer.writeMessage(
        2,
        _instance.financialEntityId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.fileName) {
      _writer.writeString(3, _instance.fileName);
    }
    if (_instance.file) {
      _writer.writeMessage(
        4,
        _instance.file as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.signFile) {
      _writer.writeMessage(
        5,
        _instance.signFile as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.documentType) {
      _writer.writeString(6, _instance.documentType);
    }
    if (_instance.route) {
      _writer.writeMessage(
        7,
        _instance.route as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.listedAsDocument) {
      _writer.writeMessage(
        8,
        _instance.listedAsDocument as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.acl) {
      _writer.writeMessage(
        9,
        _instance.acl as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _userId: number;
  private _financialEntityId?: googleProtobuf000.Int32Value;
  private _fileName: string;
  private _file?: file_message007.FileModel;
  private _signFile?: file_message007.FileModel;
  private _documentType: string;
  private _route?: googleProtobuf000.StringValue;
  private _listedAsDocument?: googleProtobuf000.BoolValue;
  private _acl?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddDocumentRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddDocumentRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    this.financialEntityId = _value.financialEntityId
      ? new googleProtobuf000.Int32Value(_value.financialEntityId)
      : undefined;
    this.fileName = _value.fileName;
    this.file = _value.file
      ? new file_message007.FileModel(_value.file)
      : undefined;
    this.signFile = _value.signFile
      ? new file_message007.FileModel(_value.signFile)
      : undefined;
    this.documentType = _value.documentType;
    this.route = _value.route
      ? new googleProtobuf000.StringValue(_value.route)
      : undefined;
    this.listedAsDocument = _value.listedAsDocument
      ? new googleProtobuf000.BoolValue(_value.listedAsDocument)
      : undefined;
    this.acl = _value.acl
      ? new googleProtobuf000.StringValue(_value.acl)
      : undefined;
    AddDocumentRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get financialEntityId(): googleProtobuf000.Int32Value | undefined {
    return this._financialEntityId;
  }
  set financialEntityId(value: googleProtobuf000.Int32Value | undefined) {
    this._financialEntityId = value;
  }
  get fileName(): string {
    return this._fileName;
  }
  set fileName(value: string) {
    this._fileName = value;
  }
  get file(): file_message007.FileModel | undefined {
    return this._file;
  }
  set file(value: file_message007.FileModel | undefined) {
    this._file = value;
  }
  get signFile(): file_message007.FileModel | undefined {
    return this._signFile;
  }
  set signFile(value: file_message007.FileModel | undefined) {
    this._signFile = value;
  }
  get documentType(): string {
    return this._documentType;
  }
  set documentType(value: string) {
    this._documentType = value;
  }
  get route(): googleProtobuf000.StringValue | undefined {
    return this._route;
  }
  set route(value: googleProtobuf000.StringValue | undefined) {
    this._route = value;
  }
  get listedAsDocument(): googleProtobuf000.BoolValue | undefined {
    return this._listedAsDocument;
  }
  set listedAsDocument(value: googleProtobuf000.BoolValue | undefined) {
    this._listedAsDocument = value;
  }
  get acl(): googleProtobuf000.StringValue | undefined {
    return this._acl;
  }
  set acl(value: googleProtobuf000.StringValue | undefined) {
    this._acl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddDocumentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddDocumentRequest.AsObject {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toObject()
        : undefined,
      fileName: this.fileName,
      file: this.file ? this.file.toObject() : undefined,
      signFile: this.signFile ? this.signFile.toObject() : undefined,
      documentType: this.documentType,
      route: this.route ? this.route.toObject() : undefined,
      listedAsDocument: this.listedAsDocument
        ? this.listedAsDocument.toObject()
        : undefined,
      acl: this.acl ? this.acl.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddDocumentRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toProtobufJSON(options)
        : null,
      fileName: this.fileName,
      file: this.file ? this.file.toProtobufJSON(options) : null,
      signFile: this.signFile ? this.signFile.toProtobufJSON(options) : null,
      documentType: this.documentType,
      route: this.route ? this.route.toProtobufJSON(options) : null,
      listedAsDocument: this.listedAsDocument
        ? this.listedAsDocument.toProtobufJSON(options)
        : null,
      acl: this.acl ? this.acl.toProtobufJSON(options) : null
    };
  }
}
export module AddDocumentRequest {
  /**
   * Standard JavaScript object representation for AddDocumentRequest
   */
  export interface AsObject {
    userId: number;
    financialEntityId?: googleProtobuf000.Int32Value.AsObject;
    fileName: string;
    file?: file_message007.FileModel.AsObject;
    signFile?: file_message007.FileModel.AsObject;
    documentType: string;
    route?: googleProtobuf000.StringValue.AsObject;
    listedAsDocument?: googleProtobuf000.BoolValue.AsObject;
    acl?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for AddDocumentRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    financialEntityId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    fileName: string;
    file: file_message007.FileModel.AsProtobufJSON | null;
    signFile: file_message007.FileModel.AsProtobufJSON | null;
    documentType: string;
    route: googleProtobuf000.StringValue.AsProtobufJSON | null;
    listedAsDocument: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    acl: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.AddContractFilesRequest
 */
export class AddContractFilesRequest implements GrpcMessage {
  static id = 'user.AddContractFilesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddContractFilesRequest();
    AddContractFilesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddContractFilesRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.files = _instance.files || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddContractFilesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 3:
          const messageInitializer3 = new AddContractFile();
          _reader.readMessage(
            messageInitializer3,
            AddContractFile.deserializeBinaryFromReader
          );
          (_instance.files = _instance.files || []).push(messageInitializer3);
          break;
        default:
          _reader.skipField();
      }
    }

    AddContractFilesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddContractFilesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(2, _instance.financialEntityId);
    }
    if (_instance.files && _instance.files.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.files as any,
        AddContractFile.serializeBinaryToWriter
      );
    }
  }

  private _userId: number;
  private _financialEntityId: number;
  private _files?: AddContractFile[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddContractFilesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddContractFilesRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    this.financialEntityId = _value.financialEntityId;
    this.files = (_value.files || []).map(m => new AddContractFile(m));
    AddContractFilesRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get files(): AddContractFile[] | undefined {
    return this._files;
  }
  set files(value: AddContractFile[] | undefined) {
    this._files = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddContractFilesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddContractFilesRequest.AsObject {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      files: (this.files || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddContractFilesRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      files: (this.files || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module AddContractFilesRequest {
  /**
   * Standard JavaScript object representation for AddContractFilesRequest
   */
  export interface AsObject {
    userId: number;
    financialEntityId: number;
    files?: AddContractFile.AsObject[];
  }

  /**
   * Protobuf JSON representation for AddContractFilesRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    financialEntityId: number;
    files: AddContractFile.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for user.AddContractFile
 */
export class AddContractFile implements GrpcMessage {
  static id = 'user.AddContractFile';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddContractFile();
    AddContractFile.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddContractFile) {
    _instance.fileName = _instance.fileName || '';
    _instance.file = _instance.file || undefined;
    _instance.signFile = _instance.signFile || undefined;
    _instance.documentType = _instance.documentType || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddContractFile,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fileName = _reader.readString();
          break;
        case 2:
          _instance.file = new file_message007.FileModel();
          _reader.readMessage(
            _instance.file,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.signFile = new file_message007.FileModel();
          _reader.readMessage(
            _instance.signFile,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.documentType = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddContractFile.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddContractFile,
    _writer: BinaryWriter
  ) {
    if (_instance.fileName) {
      _writer.writeString(1, _instance.fileName);
    }
    if (_instance.file) {
      _writer.writeMessage(
        2,
        _instance.file as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.signFile) {
      _writer.writeMessage(
        3,
        _instance.signFile as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.documentType) {
      _writer.writeString(4, _instance.documentType);
    }
  }

  private _fileName: string;
  private _file?: file_message007.FileModel;
  private _signFile?: file_message007.FileModel;
  private _documentType: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddContractFile to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddContractFile.AsObject>) {
    _value = _value || {};
    this.fileName = _value.fileName;
    this.file = _value.file
      ? new file_message007.FileModel(_value.file)
      : undefined;
    this.signFile = _value.signFile
      ? new file_message007.FileModel(_value.signFile)
      : undefined;
    this.documentType = _value.documentType;
    AddContractFile.refineValues(this);
  }
  get fileName(): string {
    return this._fileName;
  }
  set fileName(value: string) {
    this._fileName = value;
  }
  get file(): file_message007.FileModel | undefined {
    return this._file;
  }
  set file(value: file_message007.FileModel | undefined) {
    this._file = value;
  }
  get signFile(): file_message007.FileModel | undefined {
    return this._signFile;
  }
  set signFile(value: file_message007.FileModel | undefined) {
    this._signFile = value;
  }
  get documentType(): string {
    return this._documentType;
  }
  set documentType(value: string) {
    this._documentType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddContractFile.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddContractFile.AsObject {
    return {
      fileName: this.fileName,
      file: this.file ? this.file.toObject() : undefined,
      signFile: this.signFile ? this.signFile.toObject() : undefined,
      documentType: this.documentType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddContractFile.AsProtobufJSON {
    return {
      fileName: this.fileName,
      file: this.file ? this.file.toProtobufJSON(options) : null,
      signFile: this.signFile ? this.signFile.toProtobufJSON(options) : null,
      documentType: this.documentType
    };
  }
}
export module AddContractFile {
  /**
   * Standard JavaScript object representation for AddContractFile
   */
  export interface AsObject {
    fileName: string;
    file?: file_message007.FileModel.AsObject;
    signFile?: file_message007.FileModel.AsObject;
    documentType: string;
  }

  /**
   * Protobuf JSON representation for AddContractFile
   */
  export interface AsProtobufJSON {
    fileName: string;
    file: file_message007.FileModel.AsProtobufJSON | null;
    signFile: file_message007.FileModel.AsProtobufJSON | null;
    documentType: string;
  }
}

/**
 * Message implementation for user.BaseResponse
 */
export class BaseResponse implements GrpcMessage {
  static id = 'user.BaseResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BaseResponse();
    BaseResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BaseResponse) {
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BaseResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BaseResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BaseResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeInt32(1, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _status: number;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BaseResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<BaseResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    this.message = _value.message;
    BaseResponse.refineValues(this);
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BaseResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BaseResponse.AsObject {
    return {
      status: this.status,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BaseResponse.AsProtobufJSON {
    return {
      status: this.status,
      message: this.message
    };
  }
}
export module BaseResponse {
  /**
   * Standard JavaScript object representation for BaseResponse
   */
  export interface AsObject {
    status: number;
    message: string;
  }

  /**
   * Protobuf JSON representation for BaseResponse
   */
  export interface AsProtobufJSON {
    status: number;
    message: string;
  }
}

/**
 * Message implementation for user.ManageEntityRelationRequest
 */
export class ManageEntityRelationRequest implements GrpcMessage {
  static id = 'user.ManageEntityRelationRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ManageEntityRelationRequest();
    ManageEntityRelationRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ManageEntityRelationRequest) {
    _instance.relationId = _instance.relationId || 0;
    _instance.relationIsEnabled = _instance.relationIsEnabled || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ManageEntityRelationRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.relationId = _reader.readInt32();
          break;
        case 2:
          _instance.relationIsEnabled = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ManageEntityRelationRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ManageEntityRelationRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.relationId) {
      _writer.writeInt32(1, _instance.relationId);
    }
    if (_instance.relationIsEnabled) {
      _writer.writeBool(2, _instance.relationIsEnabled);
    }
  }

  private _relationId: number;
  private _relationIsEnabled: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ManageEntityRelationRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ManageEntityRelationRequest.AsObject>) {
    _value = _value || {};
    this.relationId = _value.relationId;
    this.relationIsEnabled = _value.relationIsEnabled;
    ManageEntityRelationRequest.refineValues(this);
  }
  get relationId(): number {
    return this._relationId;
  }
  set relationId(value: number) {
    this._relationId = value;
  }
  get relationIsEnabled(): boolean {
    return this._relationIsEnabled;
  }
  set relationIsEnabled(value: boolean) {
    this._relationIsEnabled = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ManageEntityRelationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ManageEntityRelationRequest.AsObject {
    return {
      relationId: this.relationId,
      relationIsEnabled: this.relationIsEnabled
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ManageEntityRelationRequest.AsProtobufJSON {
    return {
      relationId: this.relationId,
      relationIsEnabled: this.relationIsEnabled
    };
  }
}
export module ManageEntityRelationRequest {
  /**
   * Standard JavaScript object representation for ManageEntityRelationRequest
   */
  export interface AsObject {
    relationId: number;
    relationIsEnabled: boolean;
  }

  /**
   * Protobuf JSON representation for ManageEntityRelationRequest
   */
  export interface AsProtobufJSON {
    relationId: number;
    relationIsEnabled: boolean;
  }
}

/**
 * Message implementation for user.ManageEntityRelationResponse
 */
export class ManageEntityRelationResponse implements GrpcMessage {
  static id = 'user.ManageEntityRelationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ManageEntityRelationResponse();
    ManageEntityRelationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ManageEntityRelationResponse) {
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ManageEntityRelationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ManageEntityRelationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ManageEntityRelationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeInt32(1, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _status: number;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ManageEntityRelationResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ManageEntityRelationResponse.AsObject>
  ) {
    _value = _value || {};
    this.status = _value.status;
    this.message = _value.message;
    ManageEntityRelationResponse.refineValues(this);
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ManageEntityRelationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ManageEntityRelationResponse.AsObject {
    return {
      status: this.status,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ManageEntityRelationResponse.AsProtobufJSON {
    return {
      status: this.status,
      message: this.message
    };
  }
}
export module ManageEntityRelationResponse {
  /**
   * Standard JavaScript object representation for ManageEntityRelationResponse
   */
  export interface AsObject {
    status: number;
    message: string;
  }

  /**
   * Protobuf JSON representation for ManageEntityRelationResponse
   */
  export interface AsProtobufJSON {
    status: number;
    message: string;
  }
}

/**
 * Message implementation for user.AddNewEntityRelationRequest
 */
export class AddNewEntityRelationRequest implements GrpcMessage {
  static id = 'user.AddNewEntityRelationRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddNewEntityRelationRequest();
    AddNewEntityRelationRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddNewEntityRelationRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.entityName = _instance.entityName || '';
    _instance.entityRut = _instance.entityRut || '';
    _instance.entityBusiness = _instance.entityBusiness || '';
    _instance.entityAddress = _instance.entityAddress || '';
    _instance.entityCity = _instance.entityCity || '';
    _instance.entityRegion = _instance.entityRegion || '';
    _instance.entityType = _instance.entityType || '';
    _instance.entityAccountNumber = _instance.entityAccountNumber || '';
    _instance.entityAccountType = _instance.entityAccountType || '';
    _instance.entityAccountBank = _instance.entityAccountBank || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddNewEntityRelationRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.entityName = _reader.readString();
          break;
        case 3:
          _instance.entityRut = _reader.readString();
          break;
        case 4:
          _instance.entityBusiness = _reader.readString();
          break;
        case 5:
          _instance.entityAddress = _reader.readString();
          break;
        case 6:
          _instance.entityCity = _reader.readString();
          break;
        case 7:
          _instance.entityRegion = _reader.readString();
          break;
        case 8:
          _instance.entityType = _reader.readString();
          break;
        case 9:
          _instance.entityAccountNumber = _reader.readString();
          break;
        case 10:
          _instance.entityAccountType = _reader.readString();
          break;
        case 11:
          _instance.entityAccountBank = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddNewEntityRelationRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddNewEntityRelationRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.entityName) {
      _writer.writeString(2, _instance.entityName);
    }
    if (_instance.entityRut) {
      _writer.writeString(3, _instance.entityRut);
    }
    if (_instance.entityBusiness) {
      _writer.writeString(4, _instance.entityBusiness);
    }
    if (_instance.entityAddress) {
      _writer.writeString(5, _instance.entityAddress);
    }
    if (_instance.entityCity) {
      _writer.writeString(6, _instance.entityCity);
    }
    if (_instance.entityRegion) {
      _writer.writeString(7, _instance.entityRegion);
    }
    if (_instance.entityType) {
      _writer.writeString(8, _instance.entityType);
    }
    if (_instance.entityAccountNumber) {
      _writer.writeString(9, _instance.entityAccountNumber);
    }
    if (_instance.entityAccountType) {
      _writer.writeString(10, _instance.entityAccountType);
    }
    if (_instance.entityAccountBank) {
      _writer.writeString(11, _instance.entityAccountBank);
    }
  }

  private _userId: number;
  private _entityName: string;
  private _entityRut: string;
  private _entityBusiness: string;
  private _entityAddress: string;
  private _entityCity: string;
  private _entityRegion: string;
  private _entityType: string;
  private _entityAccountNumber: string;
  private _entityAccountType: string;
  private _entityAccountBank: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddNewEntityRelationRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddNewEntityRelationRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    this.entityName = _value.entityName;
    this.entityRut = _value.entityRut;
    this.entityBusiness = _value.entityBusiness;
    this.entityAddress = _value.entityAddress;
    this.entityCity = _value.entityCity;
    this.entityRegion = _value.entityRegion;
    this.entityType = _value.entityType;
    this.entityAccountNumber = _value.entityAccountNumber;
    this.entityAccountType = _value.entityAccountType;
    this.entityAccountBank = _value.entityAccountBank;
    AddNewEntityRelationRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get entityName(): string {
    return this._entityName;
  }
  set entityName(value: string) {
    this._entityName = value;
  }
  get entityRut(): string {
    return this._entityRut;
  }
  set entityRut(value: string) {
    this._entityRut = value;
  }
  get entityBusiness(): string {
    return this._entityBusiness;
  }
  set entityBusiness(value: string) {
    this._entityBusiness = value;
  }
  get entityAddress(): string {
    return this._entityAddress;
  }
  set entityAddress(value: string) {
    this._entityAddress = value;
  }
  get entityCity(): string {
    return this._entityCity;
  }
  set entityCity(value: string) {
    this._entityCity = value;
  }
  get entityRegion(): string {
    return this._entityRegion;
  }
  set entityRegion(value: string) {
    this._entityRegion = value;
  }
  get entityType(): string {
    return this._entityType;
  }
  set entityType(value: string) {
    this._entityType = value;
  }
  get entityAccountNumber(): string {
    return this._entityAccountNumber;
  }
  set entityAccountNumber(value: string) {
    this._entityAccountNumber = value;
  }
  get entityAccountType(): string {
    return this._entityAccountType;
  }
  set entityAccountType(value: string) {
    this._entityAccountType = value;
  }
  get entityAccountBank(): string {
    return this._entityAccountBank;
  }
  set entityAccountBank(value: string) {
    this._entityAccountBank = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddNewEntityRelationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddNewEntityRelationRequest.AsObject {
    return {
      userId: this.userId,
      entityName: this.entityName,
      entityRut: this.entityRut,
      entityBusiness: this.entityBusiness,
      entityAddress: this.entityAddress,
      entityCity: this.entityCity,
      entityRegion: this.entityRegion,
      entityType: this.entityType,
      entityAccountNumber: this.entityAccountNumber,
      entityAccountType: this.entityAccountType,
      entityAccountBank: this.entityAccountBank
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddNewEntityRelationRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      entityName: this.entityName,
      entityRut: this.entityRut,
      entityBusiness: this.entityBusiness,
      entityAddress: this.entityAddress,
      entityCity: this.entityCity,
      entityRegion: this.entityRegion,
      entityType: this.entityType,
      entityAccountNumber: this.entityAccountNumber,
      entityAccountType: this.entityAccountType,
      entityAccountBank: this.entityAccountBank
    };
  }
}
export module AddNewEntityRelationRequest {
  /**
   * Standard JavaScript object representation for AddNewEntityRelationRequest
   */
  export interface AsObject {
    userId: number;
    entityName: string;
    entityRut: string;
    entityBusiness: string;
    entityAddress: string;
    entityCity: string;
    entityRegion: string;
    entityType: string;
    entityAccountNumber: string;
    entityAccountType: string;
    entityAccountBank: string;
  }

  /**
   * Protobuf JSON representation for AddNewEntityRelationRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    entityName: string;
    entityRut: string;
    entityBusiness: string;
    entityAddress: string;
    entityCity: string;
    entityRegion: string;
    entityType: string;
    entityAccountNumber: string;
    entityAccountType: string;
    entityAccountBank: string;
  }
}

/**
 * Message implementation for user.AddNewEntityRelationResponse
 */
export class AddNewEntityRelationResponse implements GrpcMessage {
  static id = 'user.AddNewEntityRelationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddNewEntityRelationResponse();
    AddNewEntityRelationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddNewEntityRelationResponse) {
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddNewEntityRelationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddNewEntityRelationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddNewEntityRelationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeInt32(1, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _status: number;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddNewEntityRelationResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddNewEntityRelationResponse.AsObject>
  ) {
    _value = _value || {};
    this.status = _value.status;
    this.message = _value.message;
    AddNewEntityRelationResponse.refineValues(this);
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddNewEntityRelationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddNewEntityRelationResponse.AsObject {
    return {
      status: this.status,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddNewEntityRelationResponse.AsProtobufJSON {
    return {
      status: this.status,
      message: this.message
    };
  }
}
export module AddNewEntityRelationResponse {
  /**
   * Standard JavaScript object representation for AddNewEntityRelationResponse
   */
  export interface AsObject {
    status: number;
    message: string;
  }

  /**
   * Protobuf JSON representation for AddNewEntityRelationResponse
   */
  export interface AsProtobufJSON {
    status: number;
    message: string;
  }
}

/**
 * Message implementation for user.EditBoxTransactionsByUserRequest
 */
export class EditBoxTransactionsByUserRequest implements GrpcMessage {
  static id = 'user.EditBoxTransactionsByUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EditBoxTransactionsByUserRequest();
    EditBoxTransactionsByUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EditBoxTransactionsByUserRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.transactionId = _instance.transactionId || 0;
    _instance.transactionAmount = _instance.transactionAmount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EditBoxTransactionsByUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 3:
          _instance.transactionId = _reader.readInt32();
          break;
        case 4:
          _instance.transactionAmount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    EditBoxTransactionsByUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EditBoxTransactionsByUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(2, _instance.financialEntityId);
    }
    if (_instance.transactionId) {
      _writer.writeInt32(3, _instance.transactionId);
    }
    if (_instance.transactionAmount) {
      _writer.writeInt32(4, _instance.transactionAmount);
    }
  }

  private _userId: number;
  private _financialEntityId: number;
  private _transactionId: number;
  private _transactionAmount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EditBoxTransactionsByUserRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<EditBoxTransactionsByUserRequest.AsObject>
  ) {
    _value = _value || {};
    this.userId = _value.userId;
    this.financialEntityId = _value.financialEntityId;
    this.transactionId = _value.transactionId;
    this.transactionAmount = _value.transactionAmount;
    EditBoxTransactionsByUserRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get transactionId(): number {
    return this._transactionId;
  }
  set transactionId(value: number) {
    this._transactionId = value;
  }
  get transactionAmount(): number {
    return this._transactionAmount;
  }
  set transactionAmount(value: number) {
    this._transactionAmount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EditBoxTransactionsByUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EditBoxTransactionsByUserRequest.AsObject {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      transactionId: this.transactionId,
      transactionAmount: this.transactionAmount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EditBoxTransactionsByUserRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      transactionId: this.transactionId,
      transactionAmount: this.transactionAmount
    };
  }
}
export module EditBoxTransactionsByUserRequest {
  /**
   * Standard JavaScript object representation for EditBoxTransactionsByUserRequest
   */
  export interface AsObject {
    userId: number;
    financialEntityId: number;
    transactionId: number;
    transactionAmount: number;
  }

  /**
   * Protobuf JSON representation for EditBoxTransactionsByUserRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    financialEntityId: number;
    transactionId: number;
    transactionAmount: number;
  }
}

/**
 * Message implementation for user.EditBoxTransactionsResponse
 */
export class EditBoxTransactionsResponse implements GrpcMessage {
  static id = 'user.EditBoxTransactionsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EditBoxTransactionsResponse();
    EditBoxTransactionsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EditBoxTransactionsResponse) {
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EditBoxTransactionsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    EditBoxTransactionsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EditBoxTransactionsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeInt32(1, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _status: number;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EditBoxTransactionsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<EditBoxTransactionsResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    this.message = _value.message;
    EditBoxTransactionsResponse.refineValues(this);
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EditBoxTransactionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EditBoxTransactionsResponse.AsObject {
    return {
      status: this.status,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EditBoxTransactionsResponse.AsProtobufJSON {
    return {
      status: this.status,
      message: this.message
    };
  }
}
export module EditBoxTransactionsResponse {
  /**
   * Standard JavaScript object representation for EditBoxTransactionsResponse
   */
  export interface AsObject {
    status: number;
    message: string;
  }

  /**
   * Protobuf JSON representation for EditBoxTransactionsResponse
   */
  export interface AsProtobufJSON {
    status: number;
    message: string;
  }
}

/**
 * Message implementation for user.GetBoxTransactionsByUserRequest
 */
export class GetBoxTransactionsByUserRequest implements GrpcMessage {
  static id = 'user.GetBoxTransactionsByUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBoxTransactionsByUserRequest();
    GetBoxTransactionsByUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBoxTransactionsByUserRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBoxTransactionsByUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.financialEntityId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetBoxTransactionsByUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBoxTransactionsByUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(2, _instance.financialEntityId);
    }
  }

  private _userId: number;
  private _financialEntityId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBoxTransactionsByUserRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetBoxTransactionsByUserRequest.AsObject>
  ) {
    _value = _value || {};
    this.userId = _value.userId;
    this.financialEntityId = _value.financialEntityId;
    GetBoxTransactionsByUserRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBoxTransactionsByUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBoxTransactionsByUserRequest.AsObject {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBoxTransactionsByUserRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId
    };
  }
}
export module GetBoxTransactionsByUserRequest {
  /**
   * Standard JavaScript object representation for GetBoxTransactionsByUserRequest
   */
  export interface AsObject {
    userId: number;
    financialEntityId: number;
  }

  /**
   * Protobuf JSON representation for GetBoxTransactionsByUserRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    financialEntityId: number;
  }
}

/**
 * Message implementation for user.GetBoxTransactionsResponse
 */
export class GetBoxTransactionsResponse implements GrpcMessage {
  static id = 'user.GetBoxTransactionsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBoxTransactionsResponse();
    GetBoxTransactionsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBoxTransactionsResponse) {
    _instance.items = _instance.items || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBoxTransactionsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.GoalTransactionFundingModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.GoalTransactionFundingModel
              .deserializeBinaryFromReader
          );
          (_instance.items = _instance.items || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetBoxTransactionsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBoxTransactionsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.items && _instance.items.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.items as any,
        common_message008.GoalTransactionFundingModel.serializeBinaryToWriter
      );
    }
  }

  private _items?: common_message008.GoalTransactionFundingModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBoxTransactionsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetBoxTransactionsResponse.AsObject>) {
    _value = _value || {};
    this.items = (_value.items || []).map(
      m => new common_message008.GoalTransactionFundingModel(m)
    );
    GetBoxTransactionsResponse.refineValues(this);
  }
  get items(): common_message008.GoalTransactionFundingModel[] | undefined {
    return this._items;
  }
  set items(
    value: common_message008.GoalTransactionFundingModel[] | undefined
  ) {
    this._items = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBoxTransactionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBoxTransactionsResponse.AsObject {
    return {
      items: (this.items || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBoxTransactionsResponse.AsProtobufJSON {
    return {
      items: (this.items || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetBoxTransactionsResponse {
  /**
   * Standard JavaScript object representation for GetBoxTransactionsResponse
   */
  export interface AsObject {
    items?: common_message008.GoalTransactionFundingModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetBoxTransactionsResponse
   */
  export interface AsProtobufJSON {
    items:
      | common_message008.GoalTransactionFundingModel.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for user.GetGoalFavoriteByUserRequest
 */
export class GetGoalFavoriteByUserRequest implements GrpcMessage {
  static id = 'user.GetGoalFavoriteByUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetGoalFavoriteByUserRequest();
    GetGoalFavoriteByUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetGoalFavoriteByUserRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;

    _instance.currencyId = _instance.currencyId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetGoalFavoriteByUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 3:
          _instance.fundingId = _reader.readInt32();
          break;
        case 4:
          _instance.currencyId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetGoalFavoriteByUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetGoalFavoriteByUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(2, _instance.financialEntityId);
    }
    if (_instance.fundingId || _instance.fundingId === 0) {
      _writer.writeInt32(3, _instance.fundingId);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(4, _instance.currencyId);
    }
  }

  private _userId: number;
  private _financialEntityId: number;
  private _fundingId: number;
  private _currencyId: number;

  private _optional: GetGoalFavoriteByUserRequest.OptionalCase =
    GetGoalFavoriteByUserRequest.OptionalCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetGoalFavoriteByUserRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetGoalFavoriteByUserRequest.AsObject>
  ) {
    _value = _value || {};
    this.userId = _value.userId;
    this.financialEntityId = _value.financialEntityId;
    this.fundingId = _value.fundingId;
    this.currencyId = _value.currencyId;
    GetGoalFavoriteByUserRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    if (value !== undefined && value !== null) {
      this._optional = GetGoalFavoriteByUserRequest.OptionalCase.fundingId;
    }
    this._fundingId = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get optional() {
    return this._optional;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetGoalFavoriteByUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetGoalFavoriteByUserRequest.AsObject {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      fundingId: this.fundingId,
      currencyId: this.currencyId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetGoalFavoriteByUserRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      fundingId:
        this.fundingId === null || this.fundingId === undefined
          ? null
          : this.fundingId,
      currencyId: this.currencyId
    };
  }
}
export module GetGoalFavoriteByUserRequest {
  /**
   * Standard JavaScript object representation for GetGoalFavoriteByUserRequest
   */
  export interface AsObject {
    userId: number;
    financialEntityId: number;
    fundingId: number;
    currencyId: number;
  }

  /**
   * Protobuf JSON representation for GetGoalFavoriteByUserRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    financialEntityId: number;
    fundingId: number | null;
    currencyId: number;
  }
  export enum OptionalCase {
    none = 0,
    fundingId = 1
  }
}

/**
 * Message implementation for user.AddFavoriteGoalTransactionRequest
 */
export class AddFavoriteGoalTransactionRequest implements GrpcMessage {
  static id = 'user.AddFavoriteGoalTransactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddFavoriteGoalTransactionRequest();
    AddFavoriteGoalTransactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddFavoriteGoalTransactionRequest) {
    _instance.userId = _instance.userId || 0;
    _instance.amount = _instance.amount || 0;
    _instance.voucher = _instance.voucher || undefined;
    _instance.paymentMethod = _instance.paymentMethod || 0;

    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.currencyId = _instance.currencyId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddFavoriteGoalTransactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.amount = _reader.readInt32();
          break;
        case 3:
          _instance.voucher = new file_message007.FileModel();
          _reader.readMessage(
            _instance.voucher,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.paymentMethod = _reader.readEnum();
          break;
        case 4:
          _instance.fundingId = _reader.readInt32();
          break;
        case 6:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 7:
          _instance.currencyId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AddFavoriteGoalTransactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddFavoriteGoalTransactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.amount) {
      _writer.writeInt32(2, _instance.amount);
    }
    if (_instance.voucher) {
      _writer.writeMessage(
        3,
        _instance.voucher as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.paymentMethod) {
      _writer.writeEnum(5, _instance.paymentMethod);
    }
    if (_instance.fundingId || _instance.fundingId === 0) {
      _writer.writeInt32(4, _instance.fundingId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(6, _instance.financialEntityId);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(7, _instance.currencyId);
    }
  }

  private _userId: number;
  private _amount: number;
  private _voucher?: file_message007.FileModel;
  private _paymentMethod: goal_transaction_payment_enum004.GoalTransactionPaymentMethod;
  private _fundingId: number;
  private _financialEntityId: number;
  private _currencyId: number;

  private _optional: AddFavoriteGoalTransactionRequest.OptionalCase =
    AddFavoriteGoalTransactionRequest.OptionalCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddFavoriteGoalTransactionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddFavoriteGoalTransactionRequest.AsObject>
  ) {
    _value = _value || {};
    this.userId = _value.userId;
    this.amount = _value.amount;
    this.voucher = _value.voucher
      ? new file_message007.FileModel(_value.voucher)
      : undefined;
    this.paymentMethod = _value.paymentMethod;
    this.fundingId = _value.fundingId;
    this.financialEntityId = _value.financialEntityId;
    this.currencyId = _value.currencyId;
    AddFavoriteGoalTransactionRequest.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get voucher(): file_message007.FileModel | undefined {
    return this._voucher;
  }
  set voucher(value: file_message007.FileModel | undefined) {
    this._voucher = value;
  }
  get paymentMethod(): goal_transaction_payment_enum004.GoalTransactionPaymentMethod {
    return this._paymentMethod;
  }
  set paymentMethod(
    value: goal_transaction_payment_enum004.GoalTransactionPaymentMethod
  ) {
    this._paymentMethod = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    if (value !== undefined && value !== null) {
      this._optional = AddFavoriteGoalTransactionRequest.OptionalCase.fundingId;
    }
    this._fundingId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get optional() {
    return this._optional;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddFavoriteGoalTransactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddFavoriteGoalTransactionRequest.AsObject {
    return {
      userId: this.userId,
      amount: this.amount,
      voucher: this.voucher ? this.voucher.toObject() : undefined,
      paymentMethod: this.paymentMethod,
      fundingId: this.fundingId,
      financialEntityId: this.financialEntityId,
      currencyId: this.currencyId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddFavoriteGoalTransactionRequest.AsProtobufJSON {
    return {
      userId: this.userId,
      amount: this.amount,
      voucher: this.voucher ? this.voucher.toProtobufJSON(options) : null,
      paymentMethod:
        goal_transaction_payment_enum004.GoalTransactionPaymentMethod[
          this.paymentMethod === null || this.paymentMethod === undefined
            ? 0
            : this.paymentMethod
        ],
      fundingId:
        this.fundingId === null || this.fundingId === undefined
          ? null
          : this.fundingId,
      financialEntityId: this.financialEntityId,
      currencyId: this.currencyId
    };
  }
}
export module AddFavoriteGoalTransactionRequest {
  /**
   * Standard JavaScript object representation for AddFavoriteGoalTransactionRequest
   */
  export interface AsObject {
    userId: number;
    amount: number;
    voucher?: file_message007.FileModel.AsObject;
    paymentMethod: goal_transaction_payment_enum004.GoalTransactionPaymentMethod;
    fundingId: number;
    financialEntityId: number;
    currencyId: number;
  }

  /**
   * Protobuf JSON representation for AddFavoriteGoalTransactionRequest
   */
  export interface AsProtobufJSON {
    userId: number;
    amount: number;
    voucher: file_message007.FileModel.AsProtobufJSON | null;
    paymentMethod: string;
    fundingId: number | null;
    financialEntityId: number;
    currencyId: number;
  }
  export enum OptionalCase {
    none = 0,
    fundingId = 1
  }
}

/**
 * Message implementation for user.AddFavoriteGoalTransactionResponse
 */
export class AddFavoriteGoalTransactionResponse implements GrpcMessage {
  static id = 'user.AddFavoriteGoalTransactionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddFavoriteGoalTransactionResponse();
    AddFavoriteGoalTransactionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddFavoriteGoalTransactionResponse) {
    _instance.transactionsId = _instance.transactionsId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddFavoriteGoalTransactionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.transactionsId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AddFavoriteGoalTransactionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddFavoriteGoalTransactionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.transactionsId) {
      _writer.writeInt32(1, _instance.transactionsId);
    }
  }

  private _transactionsId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddFavoriteGoalTransactionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddFavoriteGoalTransactionResponse.AsObject>
  ) {
    _value = _value || {};
    this.transactionsId = _value.transactionsId;
    AddFavoriteGoalTransactionResponse.refineValues(this);
  }
  get transactionsId(): number {
    return this._transactionsId;
  }
  set transactionsId(value: number) {
    this._transactionsId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddFavoriteGoalTransactionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddFavoriteGoalTransactionResponse.AsObject {
    return {
      transactionsId: this.transactionsId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddFavoriteGoalTransactionResponse.AsProtobufJSON {
    return {
      transactionsId: this.transactionsId
    };
  }
}
export module AddFavoriteGoalTransactionResponse {
  /**
   * Standard JavaScript object representation for AddFavoriteGoalTransactionResponse
   */
  export interface AsObject {
    transactionsId: number;
  }

  /**
   * Protobuf JSON representation for AddFavoriteGoalTransactionResponse
   */
  export interface AsProtobufJSON {
    transactionsId: number;
  }
}

/**
 * Message implementation for user.GetAllUsersResponse
 */
export class GetAllUsersResponse implements GrpcMessage {
  static id = 'user.GetAllUsersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllUsersResponse();
    GetAllUsersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllUsersResponse) {
    _instance.items = _instance.items || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllUsersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.UserModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.UserModel.deserializeBinaryFromReader
          );
          (_instance.items = _instance.items || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllUsersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllUsersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.items && _instance.items.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.items as any,
        common_message008.UserModel.serializeBinaryToWriter
      );
    }
  }

  private _items?: common_message008.UserModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllUsersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetAllUsersResponse.AsObject>) {
    _value = _value || {};
    this.items = (_value.items || []).map(
      m => new common_message008.UserModel(m)
    );
    GetAllUsersResponse.refineValues(this);
  }
  get items(): common_message008.UserModel[] | undefined {
    return this._items;
  }
  set items(value: common_message008.UserModel[] | undefined) {
    this._items = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllUsersResponse.AsObject {
    return {
      items: (this.items || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllUsersResponse.AsProtobufJSON {
    return {
      items: (this.items || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetAllUsersResponse {
  /**
   * Standard JavaScript object representation for GetAllUsersResponse
   */
  export interface AsObject {
    items?: common_message008.UserModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetAllUsersResponse
   */
  export interface AsProtobufJSON {
    items: common_message008.UserModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for user.GetLastBoxAmountResponse
 */
export class GetLastBoxAmountResponse implements GrpcMessage {
  static id = 'user.GetLastBoxAmountResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastBoxAmountResponse();
    GetLastBoxAmountResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastBoxAmountResponse) {
    _instance.boxAmount = _instance.boxAmount || 0;
    _instance.boxDate = _instance.boxDate || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastBoxAmountResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.boxAmount = _reader.readDouble();
          break;
        case 2:
          _instance.boxDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.boxDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastBoxAmountResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastBoxAmountResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.boxAmount) {
      _writer.writeDouble(1, _instance.boxAmount);
    }
    if (_instance.boxDate) {
      _writer.writeMessage(
        2,
        _instance.boxDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _boxAmount: number;
  private _boxDate?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastBoxAmountResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLastBoxAmountResponse.AsObject>) {
    _value = _value || {};
    this.boxAmount = _value.boxAmount;
    this.boxDate = _value.boxDate
      ? new googleProtobuf001.Timestamp(_value.boxDate)
      : undefined;
    GetLastBoxAmountResponse.refineValues(this);
  }
  get boxAmount(): number {
    return this._boxAmount;
  }
  set boxAmount(value: number) {
    this._boxAmount = value;
  }
  get boxDate(): googleProtobuf001.Timestamp | undefined {
    return this._boxDate;
  }
  set boxDate(value: googleProtobuf001.Timestamp | undefined) {
    this._boxDate = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastBoxAmountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastBoxAmountResponse.AsObject {
    return {
      boxAmount: this.boxAmount,
      boxDate: this.boxDate ? this.boxDate.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastBoxAmountResponse.AsProtobufJSON {
    return {
      boxAmount: this.boxAmount,
      boxDate: this.boxDate ? this.boxDate.toProtobufJSON(options) : null
    };
  }
}
export module GetLastBoxAmountResponse {
  /**
   * Standard JavaScript object representation for GetLastBoxAmountResponse
   */
  export interface AsObject {
    boxAmount: number;
    boxDate?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetLastBoxAmountResponse
   */
  export interface AsProtobufJSON {
    boxAmount: number;
    boxDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for user.GetBalanceNetDepositGraphRequest
 */
export class GetBalanceNetDepositGraphRequest implements GrpcMessage {
  static id = 'user.GetBalanceNetDepositGraphRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBalanceNetDepositGraphRequest();
    GetBalanceNetDepositGraphRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBalanceNetDepositGraphRequest) {
    _instance.from = _instance.from || undefined;
    _instance.to = _instance.to || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBalanceNetDepositGraphRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.from = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.from,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.to = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.to,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetBalanceNetDepositGraphRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBalanceNetDepositGraphRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.from) {
      _writer.writeMessage(
        1,
        _instance.from as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.to) {
      _writer.writeMessage(
        2,
        _instance.to as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _from?: googleProtobuf001.Timestamp;
  private _to?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBalanceNetDepositGraphRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetBalanceNetDepositGraphRequest.AsObject>
  ) {
    _value = _value || {};
    this.from = _value.from
      ? new googleProtobuf001.Timestamp(_value.from)
      : undefined;
    this.to = _value.to
      ? new googleProtobuf001.Timestamp(_value.to)
      : undefined;
    GetBalanceNetDepositGraphRequest.refineValues(this);
  }
  get from(): googleProtobuf001.Timestamp | undefined {
    return this._from;
  }
  set from(value: googleProtobuf001.Timestamp | undefined) {
    this._from = value;
  }
  get to(): googleProtobuf001.Timestamp | undefined {
    return this._to;
  }
  set to(value: googleProtobuf001.Timestamp | undefined) {
    this._to = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBalanceNetDepositGraphRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBalanceNetDepositGraphRequest.AsObject {
    return {
      from: this.from ? this.from.toObject() : undefined,
      to: this.to ? this.to.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBalanceNetDepositGraphRequest.AsProtobufJSON {
    return {
      from: this.from ? this.from.toProtobufJSON(options) : null,
      to: this.to ? this.to.toProtobufJSON(options) : null
    };
  }
}
export module GetBalanceNetDepositGraphRequest {
  /**
   * Standard JavaScript object representation for GetBalanceNetDepositGraphRequest
   */
  export interface AsObject {
    from?: googleProtobuf001.Timestamp.AsObject;
    to?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetBalanceNetDepositGraphRequest
   */
  export interface AsProtobufJSON {
    from: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    to: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}
