/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for file_message.FileModel
 */
export class FileModel implements GrpcMessage {
  static id = 'file_message.FileModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FileModel();
    FileModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FileModel) {
    _instance.chunk = _instance.chunk || new Uint8Array();
    _instance.mimeType = _instance.mimeType || '';
    _instance.extension = _instance.extension || '';
    _instance.fileName = _instance.fileName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FileModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.chunk = _reader.readBytes();
          break;
        case 2:
          _instance.mimeType = _reader.readString();
          break;
        case 3:
          _instance.extension = _reader.readString();
          break;
        case 4:
          _instance.fileName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    FileModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: FileModel, _writer: BinaryWriter) {
    if (_instance.chunk && _instance.chunk.length) {
      _writer.writeBytes(1, _instance.chunk);
    }
    if (_instance.mimeType) {
      _writer.writeString(2, _instance.mimeType);
    }
    if (_instance.extension) {
      _writer.writeString(3, _instance.extension);
    }
    if (_instance.fileName) {
      _writer.writeString(4, _instance.fileName);
    }
  }

  private _chunk: Uint8Array;
  private _mimeType: string;
  private _extension: string;
  private _fileName: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FileModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FileModel.AsObject>) {
    _value = _value || {};
    this.chunk = _value.chunk;
    this.mimeType = _value.mimeType;
    this.extension = _value.extension;
    this.fileName = _value.fileName;
    FileModel.refineValues(this);
  }
  get chunk(): Uint8Array {
    return this._chunk;
  }
  set chunk(value: Uint8Array) {
    this._chunk = value;
  }
  get mimeType(): string {
    return this._mimeType;
  }
  set mimeType(value: string) {
    this._mimeType = value;
  }
  get extension(): string {
    return this._extension;
  }
  set extension(value: string) {
    this._extension = value;
  }
  get fileName(): string {
    return this._fileName;
  }
  set fileName(value: string) {
    this._fileName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FileModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FileModel.AsObject {
    return {
      chunk: this.chunk ? this.chunk.subarray(0) : new Uint8Array(),
      mimeType: this.mimeType,
      extension: this.extension,
      fileName: this.fileName
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FileModel.AsProtobufJSON {
    return {
      chunk: this.chunk ? uint8ArrayToBase64(this.chunk) : '',
      mimeType: this.mimeType,
      extension: this.extension,
      fileName: this.fileName
    };
  }
}
export module FileModel {
  /**
   * Standard JavaScript object representation for FileModel
   */
  export interface AsObject {
    chunk: Uint8Array;
    mimeType: string;
    extension: string;
    fileName: string;
  }

  /**
   * Protobuf JSON representation for FileModel
   */
  export interface AsProtobufJSON {
    chunk: string;
    mimeType: string;
    extension: string;
    fileName: string;
  }
}
