import { Injectable, NgZone } from '@angular/core';
import {
  IInvokeCSharpActionData,
  InvokeCSharpActionDataTypeEnum
} from '../models/IInvokeCsharpActionData';

@Injectable({
  providedIn: 'root'
})
export class WVCommunicationService {
  constructor(private ngZone: NgZone) {}
  isLogin(value: boolean): void {
    this.invokeCSharpAction({
      type: InvokeCSharpActionDataTypeEnum.IsLogin,
      value
    });
  }
  setAccessToken(value: string): void {
    this.invokeCSharpAction({
      type: InvokeCSharpActionDataTypeEnum.AccessToken,
      value
    });
  }
  setNavigationEnd(origin: string, pathName: string): void {
    this.invokeCSharpAction({
      type: InvokeCSharpActionDataTypeEnum.NavigationEnd,
      value: {
        state: true,
        pathName,
        origin
      }
    });
  }
  setNavigationStart(origin: string, pathName: string): void {
    this.invokeCSharpAction({
      type: InvokeCSharpActionDataTypeEnum.NavigationStart,
      value: {
        state: true,
        pathName,
        origin
      }
    });
  }
  showDebugPage(): void {
    this.invokeCSharpAction({
      type: InvokeCSharpActionDataTypeEnum.ShowDebugPage,
      value: true
    });
  }
  showCiValidatePage(rut: string): void {
    this.invokeCSharpAction({
      type: InvokeCSharpActionDataTypeEnum.ShowCiValidatePage,
      value: rut
    });
  }
  private invokeCSharpAction(data: IInvokeCSharpActionData): void {
    const isMovil = localStorage.getItem('bp_movil');
    const dataString = JSON.stringify(data);

    if (isMovil && isMovil.toLowerCase() === 'true') {
      console.log('invokeCSharpAction', isMovil, dataString);
      this.ngZone.runOutsideAngular(() => {
        try {
          console.log('Sending Data:' + dataString);
          // @ts-ignore
          invokeCSharpAction(dataString);
        } catch (err) {
          console.error(err);
        }
      });
    }
  }
}
