/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as common_message007 from './common-message.pb';
/**
 * Message implementation for amend_risk_profile.AnswerAmendRiskProfileRequest
 */
export class AnswerAmendRiskProfileRequest implements GrpcMessage {
  static id = 'amend_risk_profile.AnswerAmendRiskProfileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AnswerAmendRiskProfileRequest();
    AnswerAmendRiskProfileRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AnswerAmendRiskProfileRequest) {
    _instance.uuid = _instance.uuid || '';
    _instance.userConfirmRiskProfile =
      _instance.userConfirmRiskProfile || false;
    _instance.reasonRejection = _instance.reasonRejection || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AnswerAmendRiskProfileRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.uuid = _reader.readString();
          break;
        case 2:
          _instance.userConfirmRiskProfile = _reader.readBool();
          break;
        case 3:
          _instance.reasonRejection = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AnswerAmendRiskProfileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AnswerAmendRiskProfileRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.uuid) {
      _writer.writeString(1, _instance.uuid);
    }
    if (_instance.userConfirmRiskProfile) {
      _writer.writeBool(2, _instance.userConfirmRiskProfile);
    }
    if (_instance.reasonRejection) {
      _writer.writeString(3, _instance.reasonRejection);
    }
  }

  private _uuid: string;
  private _userConfirmRiskProfile: boolean;
  private _reasonRejection: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AnswerAmendRiskProfileRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AnswerAmendRiskProfileRequest.AsObject>
  ) {
    _value = _value || {};
    this.uuid = _value.uuid;
    this.userConfirmRiskProfile = _value.userConfirmRiskProfile;
    this.reasonRejection = _value.reasonRejection;
    AnswerAmendRiskProfileRequest.refineValues(this);
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get userConfirmRiskProfile(): boolean {
    return this._userConfirmRiskProfile;
  }
  set userConfirmRiskProfile(value: boolean) {
    this._userConfirmRiskProfile = value;
  }
  get reasonRejection(): string {
    return this._reasonRejection;
  }
  set reasonRejection(value: string) {
    this._reasonRejection = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AnswerAmendRiskProfileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AnswerAmendRiskProfileRequest.AsObject {
    return {
      uuid: this.uuid,
      userConfirmRiskProfile: this.userConfirmRiskProfile,
      reasonRejection: this.reasonRejection
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AnswerAmendRiskProfileRequest.AsProtobufJSON {
    return {
      uuid: this.uuid,
      userConfirmRiskProfile: this.userConfirmRiskProfile,
      reasonRejection: this.reasonRejection
    };
  }
}
export module AnswerAmendRiskProfileRequest {
  /**
   * Standard JavaScript object representation for AnswerAmendRiskProfileRequest
   */
  export interface AsObject {
    uuid: string;
    userConfirmRiskProfile: boolean;
    reasonRejection: string;
  }

  /**
   * Protobuf JSON representation for AnswerAmendRiskProfileRequest
   */
  export interface AsProtobufJSON {
    uuid: string;
    userConfirmRiskProfile: boolean;
    reasonRejection: string;
  }
}

/**
 * Message implementation for amend_risk_profile.AnswerAmendRiskProfileResponse
 */
export class AnswerAmendRiskProfileResponse implements GrpcMessage {
  static id = 'amend_risk_profile.AnswerAmendRiskProfileResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AnswerAmendRiskProfileResponse();
    AnswerAmendRiskProfileResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AnswerAmendRiskProfileResponse) {
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
    _instance.userName = _instance.userName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AnswerAmendRiskProfileResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.userName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AnswerAmendRiskProfileResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AnswerAmendRiskProfileResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeInt32(1, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.userName) {
      _writer.writeString(3, _instance.userName);
    }
  }

  private _status: number;
  private _message: string;
  private _userName: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AnswerAmendRiskProfileResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AnswerAmendRiskProfileResponse.AsObject>
  ) {
    _value = _value || {};
    this.status = _value.status;
    this.message = _value.message;
    this.userName = _value.userName;
    AnswerAmendRiskProfileResponse.refineValues(this);
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }
  get userName(): string {
    return this._userName;
  }
  set userName(value: string) {
    this._userName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AnswerAmendRiskProfileResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AnswerAmendRiskProfileResponse.AsObject {
    return {
      status: this.status,
      message: this.message,
      userName: this.userName
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AnswerAmendRiskProfileResponse.AsProtobufJSON {
    return {
      status: this.status,
      message: this.message,
      userName: this.userName
    };
  }
}
export module AnswerAmendRiskProfileResponse {
  /**
   * Standard JavaScript object representation for AnswerAmendRiskProfileResponse
   */
  export interface AsObject {
    status: number;
    message: string;
    userName: string;
  }

  /**
   * Protobuf JSON representation for AnswerAmendRiskProfileResponse
   */
  export interface AsProtobufJSON {
    status: number;
    message: string;
    userName: string;
  }
}

/**
 * Message implementation for amend_risk_profile.AmendRiskProfileGetRequest
 */
export class AmendRiskProfileGetRequest implements GrpcMessage {
  static id = 'amend_risk_profile.AmendRiskProfileGetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AmendRiskProfileGetRequest();
    AmendRiskProfileGetRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AmendRiskProfileGetRequest) {
    _instance.where = _instance.where || '';
    _instance.page = _instance.page || 0;
    _instance.size = _instance.size || 0;
    _instance.order = _instance.order || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AmendRiskProfileGetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.where = _reader.readString();
          break;
        case 2:
          _instance.page = _reader.readInt32();
          break;
        case 3:
          _instance.size = _reader.readInt32();
          break;
        case 4:
          _instance.order = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AmendRiskProfileGetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AmendRiskProfileGetRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.where) {
      _writer.writeString(1, _instance.where);
    }
    if (_instance.page) {
      _writer.writeInt32(2, _instance.page);
    }
    if (_instance.size) {
      _writer.writeInt32(3, _instance.size);
    }
    if (_instance.order) {
      _writer.writeString(4, _instance.order);
    }
  }

  private _where: string;
  private _page: number;
  private _size: number;
  private _order: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AmendRiskProfileGetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AmendRiskProfileGetRequest.AsObject>) {
    _value = _value || {};
    this.where = _value.where;
    this.page = _value.page;
    this.size = _value.size;
    this.order = _value.order;
    AmendRiskProfileGetRequest.refineValues(this);
  }
  get where(): string {
    return this._where;
  }
  set where(value: string) {
    this._where = value;
  }
  get page(): number {
    return this._page;
  }
  set page(value: number) {
    this._page = value;
  }
  get size(): number {
    return this._size;
  }
  set size(value: number) {
    this._size = value;
  }
  get order(): string {
    return this._order;
  }
  set order(value: string) {
    this._order = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AmendRiskProfileGetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AmendRiskProfileGetRequest.AsObject {
    return {
      where: this.where,
      page: this.page,
      size: this.size,
      order: this.order
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AmendRiskProfileGetRequest.AsProtobufJSON {
    return {
      where: this.where,
      page: this.page,
      size: this.size,
      order: this.order
    };
  }
}
export module AmendRiskProfileGetRequest {
  /**
   * Standard JavaScript object representation for AmendRiskProfileGetRequest
   */
  export interface AsObject {
    where: string;
    page: number;
    size: number;
    order: string;
  }

  /**
   * Protobuf JSON representation for AmendRiskProfileGetRequest
   */
  export interface AsProtobufJSON {
    where: string;
    page: number;
    size: number;
    order: string;
  }
}

/**
 * Message implementation for amend_risk_profile.AmendRiskProfileGetResponse
 */
export class AmendRiskProfileGetResponse implements GrpcMessage {
  static id = 'amend_risk_profile.AmendRiskProfileGetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AmendRiskProfileGetResponse();
    AmendRiskProfileGetResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AmendRiskProfileGetResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AmendRiskProfileGetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new AmendRiskProfileModel();
          _reader.readMessage(
            messageInitializer1,
            AmendRiskProfileModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AmendRiskProfileGetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AmendRiskProfileGetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        AmendRiskProfileModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: AmendRiskProfileModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AmendRiskProfileGetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AmendRiskProfileGetResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new AmendRiskProfileModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    AmendRiskProfileGetResponse.refineValues(this);
  }
  get values(): AmendRiskProfileModel[] | undefined {
    return this._values;
  }
  set values(value: AmendRiskProfileModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AmendRiskProfileGetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AmendRiskProfileGetResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AmendRiskProfileGetResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module AmendRiskProfileGetResponse {
  /**
   * Standard JavaScript object representation for AmendRiskProfileGetResponse
   */
  export interface AsObject {
    values?: AmendRiskProfileModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for AmendRiskProfileGetResponse
   */
  export interface AsProtobufJSON {
    values: AmendRiskProfileModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for amend_risk_profile.AmendRiskProfileModel
 */
export class AmendRiskProfileModel implements GrpcMessage {
  static id = 'amend_risk_profile.AmendRiskProfileModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AmendRiskProfileModel();
    AmendRiskProfileModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AmendRiskProfileModel) {
    _instance.id = _instance.id || 0;
    _instance.hasEmailSended = _instance.hasEmailSended || false;
    _instance.userConfirmRiskProfile =
      _instance.userConfirmRiskProfile || false;
    _instance.user = _instance.user || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.answeredAt = _instance.answeredAt || undefined;
    _instance.riskProfile = _instance.riskProfile || undefined;
    _instance.uuid = _instance.uuid || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AmendRiskProfileModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.hasEmailSended = _reader.readBool();
          break;
        case 3:
          _instance.userConfirmRiskProfile = _reader.readBool();
          break;
        case 4:
          _instance.user = new common_message007.UserModel();
          _reader.readMessage(
            _instance.user,
            common_message007.UserModel.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.answeredAt = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.answeredAt,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.riskProfile = new common_message007.RiskProfileModel();
          _reader.readMessage(
            _instance.riskProfile,
            common_message007.RiskProfileModel.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.uuid = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AmendRiskProfileModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AmendRiskProfileModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.hasEmailSended) {
      _writer.writeBool(2, _instance.hasEmailSended);
    }
    if (_instance.userConfirmRiskProfile) {
      _writer.writeBool(3, _instance.userConfirmRiskProfile);
    }
    if (_instance.user) {
      _writer.writeMessage(
        4,
        _instance.user as any,
        common_message007.UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        5,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        6,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.answeredAt) {
      _writer.writeMessage(
        7,
        _instance.answeredAt as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.riskProfile) {
      _writer.writeMessage(
        8,
        _instance.riskProfile as any,
        common_message007.RiskProfileModel.serializeBinaryToWriter
      );
    }
    if (_instance.uuid) {
      _writer.writeString(9, _instance.uuid);
    }
  }

  private _id: number;
  private _hasEmailSended: boolean;
  private _userConfirmRiskProfile: boolean;
  private _user?: common_message007.UserModel;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _answeredAt?: googleProtobuf001.Timestamp;
  private _riskProfile?: common_message007.RiskProfileModel;
  private _uuid: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AmendRiskProfileModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<AmendRiskProfileModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.hasEmailSended = _value.hasEmailSended;
    this.userConfirmRiskProfile = _value.userConfirmRiskProfile;
    this.user = _value.user
      ? new common_message007.UserModel(_value.user)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.answeredAt = _value.answeredAt
      ? new googleProtobuf001.Timestamp(_value.answeredAt)
      : undefined;
    this.riskProfile = _value.riskProfile
      ? new common_message007.RiskProfileModel(_value.riskProfile)
      : undefined;
    this.uuid = _value.uuid;
    AmendRiskProfileModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get hasEmailSended(): boolean {
    return this._hasEmailSended;
  }
  set hasEmailSended(value: boolean) {
    this._hasEmailSended = value;
  }
  get userConfirmRiskProfile(): boolean {
    return this._userConfirmRiskProfile;
  }
  set userConfirmRiskProfile(value: boolean) {
    this._userConfirmRiskProfile = value;
  }
  get user(): common_message007.UserModel | undefined {
    return this._user;
  }
  set user(value: common_message007.UserModel | undefined) {
    this._user = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get answeredAt(): googleProtobuf001.Timestamp | undefined {
    return this._answeredAt;
  }
  set answeredAt(value: googleProtobuf001.Timestamp | undefined) {
    this._answeredAt = value;
  }
  get riskProfile(): common_message007.RiskProfileModel | undefined {
    return this._riskProfile;
  }
  set riskProfile(value: common_message007.RiskProfileModel | undefined) {
    this._riskProfile = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AmendRiskProfileModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AmendRiskProfileModel.AsObject {
    return {
      id: this.id,
      hasEmailSended: this.hasEmailSended,
      userConfirmRiskProfile: this.userConfirmRiskProfile,
      user: this.user ? this.user.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      answeredAt: this.answeredAt ? this.answeredAt.toObject() : undefined,
      riskProfile: this.riskProfile ? this.riskProfile.toObject() : undefined,
      uuid: this.uuid
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AmendRiskProfileModel.AsProtobufJSON {
    return {
      id: this.id,
      hasEmailSended: this.hasEmailSended,
      userConfirmRiskProfile: this.userConfirmRiskProfile,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      answeredAt: this.answeredAt
        ? this.answeredAt.toProtobufJSON(options)
        : null,
      riskProfile: this.riskProfile
        ? this.riskProfile.toProtobufJSON(options)
        : null,
      uuid: this.uuid
    };
  }
}
export module AmendRiskProfileModel {
  /**
   * Standard JavaScript object representation for AmendRiskProfileModel
   */
  export interface AsObject {
    id: number;
    hasEmailSended: boolean;
    userConfirmRiskProfile: boolean;
    user?: common_message007.UserModel.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    answeredAt?: googleProtobuf001.Timestamp.AsObject;
    riskProfile?: common_message007.RiskProfileModel.AsObject;
    uuid: string;
  }

  /**
   * Protobuf JSON representation for AmendRiskProfileModel
   */
  export interface AsProtobufJSON {
    id: number;
    hasEmailSended: boolean;
    userConfirmRiskProfile: boolean;
    user: common_message007.UserModel.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    answeredAt: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    riskProfile: common_message007.RiskProfileModel.AsProtobufJSON | null;
    uuid: string;
  }
}
