/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum TransactionRequestState {
  PENDING = 0,
  ASSIGNED = 1,
  OBSOLETE = 2
}
export enum TransactionRequestType {
  Buy = 0,
  Sale = 1,
  ChangePortfolio = 2,
  TransferGoal = 3,
  Comission = 4,
  Dividend = 5
}
export enum TransactionPaymentMethod {
  TRANSFER = 0,
  CHECK = 1,
  NONE = 2,
  REFERRAL = 3,
  REBALANCE = 4
}
export enum TransactionGoalRequestType {
  NA = 0,
  FROM = 1,
  TO = 2,
  DEPOSIT = 3,
  FROM_CURRENCY = 4,
  TO_CURRENCY = 5,
  FROM_INSTRUMENT = 6,
  TO_INSTRUMENT = 7,
  REINVEST = 8
}
export enum GoalTransactionState {
  CHECKING_RESCUE = 0,
  CHECKING_DEPOSIT = 1,
  WAITING_TO_BE_SEND = 2,
  CHECKING_BUY = 3,
  CHECKING_SALE = 4,
  PROCESSED = 5,
  WAITING_SHARE_VALUE = 6,
  CONFLICT = 7,
  ERROR_TO_PROCESING = 8,
  ERROR_TO_SEND = 9,
  NOT_MATCH_DATE = 10,
  NOT_MATCH_FUNDING = 11,
  READY_TO_PROCESS = 12,
  WAITING_SELECT_GOAL = 13,
  CONFIRMED_GOAL = 14,
  ROBOT_CHECKED_DEPOSIT = 15,
  ROBOT_CONFIRM_GOAL = 16,
  WAITING_SEND_USER = 17,
  ROBOT_CREATED_DEPOSIT = 18,
  WAITING_TO_BE_SEND_TRANSACTION = 19,
  WAITING_TO_BE_SEND_OPERATION = 20,
  WAITING_TO_BE_SEND_SALE_TO_USER = 21,
  NO_OVERRIDE_STATE = 22,
  CHECKING_FINTOC = 23,
  CANCELED = 24,
  CANCELED_FINTOC = 25,
  ROBOT_CONFIRMED_GOAL = 26,
  WAITING_ENTITY_RESPONSE = 27,
  PRE_PROCESSED = 28
}
