/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum GoalTransactionType {
  BUY = 0,
  SALE = 1,
  CHANGE_PORTFOLIO_BUY = 2,
  CHANGE_PORTFOLIO_SALE = 3,
  TRANSFER_GOAL_BUY = 4,
  TRANSFER_GOAL_SALE = 5,
  CURRENCY_EXCHANGE_BUY = 6,
  CURRENCY_EXCHANGE_SALE = 7,
  INSTRUMENT_BUY = 8,
  INSTRUMENT_SALE = 9,
  DEPOSIT = 10,
  RESCUE = 11,
  DIVIDEND = 12,
  CASH_IN = 13,
  CASH_OUT = 14
}
