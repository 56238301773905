import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }
  public screenWidth: any;
  public collapseSidebar = true;

  public menuItemsAbove: Menu[] = [
    {
      path: '/dashboard',
      title: 'Dashboard',
      icon: 'feather-layout',
      type: 'link',
    },
  ];

  public menuItemsDown: Menu[] = [
    {
      path: '/goals/new',
      title: 'Nueva Meta',
      icon: 'feather-plus-circle',
      type: 'link',
    },
    {
      path: '/advisor',
      title: 'Conoce Tu Asesor',
      icon: 'feather-user',
      type: 'link',
    },
    {
      path: '/documents',
      title: 'Documentos',
      icon: 'feather-paperclip',
      type: 'link',
    },
    {
      path: '/profile',
      title: 'Mi Perfil',
      icon: 'feather-clipboard',
      type: 'link',
    },
  ];
  public items = new BehaviorSubject<Menu[]>(this.menuItemsAbove);

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.screenWidth = window.innerWidth;
  }
}
