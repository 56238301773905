/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './user.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as file_message007 from './file-message.pb';
import * as common_message008 from './common-message.pb';
import { GRPC_USER_GRPC_SERVICE_CLIENT_SETTINGS } from './user.pbconf';
/**
 * Service client implementation for user.UserGrpcService
 */
@Injectable({ providedIn: 'any' })
export class UserGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /user.UserGrpcService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserModel>>
     */
    get: (
      requestData: common_message008.BaseGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/Get',
        requestData,
        requestMetadata,
        requestClass: common_message008.BaseGetRequest,
        responseClass: common_message008.UserModel
      });
    },
    /**
     * Unary call: /user.UserGrpcService/List
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListUserResponse>>
     */
    list: (
      requestData: common_message008.BaseListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListUserResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/List',
        requestData,
        requestMetadata,
        requestClass: common_message008.BaseListRequest,
        responseClass: thisProto.ListUserResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetAllUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetAllUsersResponse>>
     */
    getAllUsers: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetAllUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetAllUsers',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.GetAllUsersResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetEntityByRut
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetAllUsersResponse>>
     */
    getEntityByRut: (
      requestData: thisProto.GetEntityByRutRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetAllUsersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetEntityByRut',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetEntityByRutRequest,
        responseClass: thisProto.GetAllUsersResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/AddFavoriteGoalTransaction
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddFavoriteGoalTransactionResponse>>
     */
    addFavoriteGoalTransaction: (
      requestData: thisProto.AddFavoriteGoalTransactionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddFavoriteGoalTransactionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/AddFavoriteGoalTransaction',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddFavoriteGoalTransactionRequest,
        responseClass: thisProto.AddFavoriteGoalTransactionResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetGoalFavorite
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.GoalModel>>
     */
    getGoalFavorite: (
      requestData: thisProto.GetGoalFavoriteByUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.GoalModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetGoalFavorite',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetGoalFavoriteByUserRequest,
        responseClass: common_message008.GoalModel
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetBoxTransactions
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetBoxTransactionsResponse>>
     */
    getBoxTransactions: (
      requestData: thisProto.GetBoxTransactionsByUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetBoxTransactionsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetBoxTransactions',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBoxTransactionsByUserRequest,
        responseClass: thisProto.GetBoxTransactionsResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/EditBoxTransactions
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.EditBoxTransactionsResponse>>
     */
    editBoxTransactions: (
      requestData: thisProto.EditBoxTransactionsByUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.EditBoxTransactionsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/EditBoxTransactions',
        requestData,
        requestMetadata,
        requestClass: thisProto.EditBoxTransactionsByUserRequest,
        responseClass: thisProto.EditBoxTransactionsResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/AddNewEntityRelation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddNewEntityRelationResponse>>
     */
    addNewEntityRelation: (
      requestData: thisProto.AddNewEntityRelationRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddNewEntityRelationResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/AddNewEntityRelation',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddNewEntityRelationRequest,
        responseClass: thisProto.AddNewEntityRelationResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/ManageEntityRelation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ManageEntityRelationResponse>>
     */
    manageEntityRelation: (
      requestData: thisProto.ManageEntityRelationRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ManageEntityRelationResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/ManageEntityRelation',
        requestData,
        requestMetadata,
        requestClass: thisProto.ManageEntityRelationRequest,
        responseClass: thisProto.ManageEntityRelationResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/AddDocument
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    addDocument: (
      requestData: thisProto.AddDocumentRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/AddDocument',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddDocumentRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/AddContractFiles
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    addContractFiles: (
      requestData: thisProto.AddContractFilesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/AddContractFiles',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddContractFilesRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/SendGoalMilestoneEmail
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    sendGoalMilestoneEmail: (
      requestData: thisProto.SendGoalMilestoneEmailRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/SendGoalMilestoneEmail',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendGoalMilestoneEmailRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/SendUserFeedback
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    sendUserFeedback: (
      requestData: thisProto.SendUserFeedbackRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/SendUserFeedback',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendUserFeedbackRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetLastBoxAmount
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetLastBoxAmountResponse>>
     */
    getLastBoxAmount: (
      requestData: thisProto.GetBoxTransactionsByUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetLastBoxAmountResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetLastBoxAmount',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBoxTransactionsByUserRequest,
        responseClass: thisProto.GetLastBoxAmountResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetBalanceNetDepositGraph
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.BalanceNetDepositGraphDetailModel>>
     */
    getBalanceNetDepositGraph: (
      requestData: thisProto.GetBalanceNetDepositGraphRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<common_message008.BalanceNetDepositGraphDetailModel>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetBalanceNetDepositGraph',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBalanceNetDepositGraphRequest,
        responseClass: common_message008.BalanceNetDepositGraphDetailModel
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetMeSummary
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetSummaryResponse>>
     */
    getMeSummary: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetSummaryResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetMeSummary',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.GetSummaryResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetSummary
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetSummaryResponse>>
     */
    getSummary: (
      requestData: thisProto.GetSummaryRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetSummaryResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetSummary',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetSummaryRequest,
        responseClass: thisProto.GetSummaryResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetMe
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserModel>>
     */
    getMe: (
      requestData: thisProto.GetMeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetMe',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeRequest,
        responseClass: common_message008.UserModel
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetMeAdvisor
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserModel>>
     */
    getMeAdvisor: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetMeAdvisor',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: common_message008.UserModel
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetMeInformation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserInformationModel>>
     */
    getMeInformation: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserInformationModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetMeInformation',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: common_message008.UserInformationModel
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetMeTransactions
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeTransactionsResponse>>
     */
    getMeTransactions: (
      requestData: common_message008.BaseListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeTransactionsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetMeTransactions',
        requestData,
        requestMetadata,
        requestClass: common_message008.BaseListRequest,
        responseClass: thisProto.GetMeTransactionsResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetLastFundingsValues
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetLastFundingsValuesResponse>>
     */
    getLastFundingsValues: (
      requestData: thisProto.GetLastFundingsValuesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetLastFundingsValuesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetLastFundingsValues',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetLastFundingsValuesRequest,
        responseClass: thisProto.GetLastFundingsValuesResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetFundingsValuesByUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetFundingsValuesByUserResponse>>
     */
    getFundingsValuesByUser: (
      requestData: thisProto.GetFundingsValuesByUserRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetFundingsValuesByUserResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetFundingsValuesByUser',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetFundingsValuesByUserRequest,
        responseClass: thisProto.GetFundingsValuesByUserResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/SkipeAdvisor
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    skipeAdvisor: (
      requestData: common_message008.UserModel,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/SkipeAdvisor',
        requestData,
        requestMetadata,
        requestClass: common_message008.UserModel,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/ConfirmScheduledAdvisor
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    confirmScheduledAdvisor: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/ConfirmScheduledAdvisor',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/AddOrUpdateDeviceInstallation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    addOrUpdateDeviceInstallation: (
      requestData: thisProto.AddOrUpdateDeviceInstallationRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/AddOrUpdateDeviceInstallation',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddOrUpdateDeviceInstallationRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/RemoveDeviceInstallation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    removeDeviceInstallation: (
      requestData: thisProto.RemoveDeviceInstallationRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/RemoveDeviceInstallation',
        requestData,
        requestMetadata,
        requestClass: thisProto.RemoveDeviceInstallationRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetActiveCodes
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListUserCodeResponse>>
     */
    getActiveCodes: (
      requestData: thisProto.GetActiveCodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListUserCodeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetActiveCodes',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetActiveCodesRequest,
        responseClass: thisProto.ListUserCodeResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetActiveCodesAdmin
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListUserCodeResponse>>
     */
    getActiveCodesAdmin: (
      requestData: thisProto.GetActiveCodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListUserCodeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetActiveCodesAdmin',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetActiveCodesRequest,
        responseClass: thisProto.ListUserCodeResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/CreateUserCode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    createUserCode: (
      requestData: thisProto.CreateUserCodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/CreateUserCode',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateUserCodeRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/EditUserCode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    editUserCode: (
      requestData: thisProto.CreateUserCodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/EditUserCode',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateUserCodeRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/RemoveUserCode
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    removeUserCode: (
      requestData: thisProto.CreateUserCodeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/RemoveUserCode',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateUserCodeRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetActiveCodesUsers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UserCodeReferralsResponse>>
     */
    getActiveCodesUsers: (
      requestData: thisProto.GetActiveCodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UserCodeReferralsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetActiveCodesUsers',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetActiveCodesRequest,
        responseClass: thisProto.UserCodeReferralsResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetActiveCodesUsersAdmin
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UserCodeReferralsResponse>>
     */
    getActiveCodesUsersAdmin: (
      requestData: thisProto.GetActiveCodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UserCodeReferralsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetActiveCodesUsersAdmin',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetActiveCodesRequest,
        responseClass: thisProto.UserCodeReferralsResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetBankAccounts
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BankAccountsResponse>>
     */
    getBankAccounts: (
      requestData: thisProto.GetBankAccountsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BankAccountsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetBankAccounts',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBankAccountsRequest,
        responseClass: thisProto.BankAccountsResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/SetBankAccount
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BankAccountResponse>>
     */
    setBankAccount: (
      requestData: thisProto.SetBankAccountRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BankAccountResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/SetBankAccount',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetBankAccountRequest,
        responseClass: thisProto.BankAccountResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/RemoveBankAccount
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BankAccountResponse>>
     */
    removeBankAccount: (
      requestData: thisProto.RemoveBankAccountRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BankAccountResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/RemoveBankAccount',
        requestData,
        requestMetadata,
        requestClass: thisProto.RemoveBankAccountRequest,
        responseClass: thisProto.BankAccountResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/SendReferralsEmail
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    sendReferralsEmail: (
      requestData: thisProto.SendReferralsEmailRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/SendReferralsEmail',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendReferralsEmailRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/CreateReferralRelation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    createReferralRelation: (
      requestData: thisProto.CreateReferralRelationRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/CreateReferralRelation',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateReferralRelationRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/CheckReferralRelation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    checkReferralRelation: (
      requestData: thisProto.GetActiveCodesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/CheckReferralRelation',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetActiveCodesRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetAllReferralTransactions
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.ListUserReferralTransactionResponse>>
     */
    getAllReferralTransactions: (
      requestData: common_message008.BaseListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<common_message008.ListUserReferralTransactionResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetAllReferralTransactions',
        requestData,
        requestMetadata,
        requestClass: common_message008.BaseListRequest,
        responseClass: common_message008.ListUserReferralTransactionResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/CreateReferralTransaction
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    createReferralTransaction: (
      requestData: thisProto.CreateReferralTransactionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/CreateReferralTransaction',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateReferralTransactionRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/RemoveReferralTransaction
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BaseResponse>>
     */
    removeReferralTransaction: (
      requestData: thisProto.CreateReferralTransactionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/RemoveReferralTransaction',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateReferralTransactionRequest,
        responseClass: thisProto.BaseResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetReferralTransactionsSummary
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ReferralTransactionsSummaryResponse>>
     */
    getReferralTransactionsSummary: (
      requestData: common_message008.BaseGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ReferralTransactionsSummaryResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetReferralTransactionsSummary',
        requestData,
        requestMetadata,
        requestClass: common_message008.BaseGetRequest,
        responseClass: thisProto.ReferralTransactionsSummaryResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetScheduleEvents
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListScheduleEventResponse>>
     */
    getScheduleEvents: (
      requestData: common_message008.BaseListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListScheduleEventResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetScheduleEvents',
        requestData,
        requestMetadata,
        requestClass: common_message008.BaseListRequest,
        responseClass: thisProto.ListScheduleEventResponse
      });
    },
    /**
     * Unary call: /user.UserGrpcService/GetMeManagers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeManagersResponse>>
     */
    getMeManagers: (
      requestData: thisProto.GetMeManagersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeManagersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/user.UserGrpcService/GetMeManagers',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeManagersRequest,
        responseClass: thisProto.GetMeManagersResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_USER_GRPC_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('user.UserGrpcService', settings);
  }

  /**
   * Unary call @/user.UserGrpcService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserModel>
   */
  get(
    requestData: common_message008.BaseGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserModel> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/List
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListUserResponse>
   */
  list(
    requestData: common_message008.BaseListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListUserResponse> {
    return this.$raw
      .list(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetAllUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetAllUsersResponse>
   */
  getAllUsers(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetAllUsersResponse> {
    return this.$raw
      .getAllUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetEntityByRut
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetAllUsersResponse>
   */
  getEntityByRut(
    requestData: thisProto.GetEntityByRutRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetAllUsersResponse> {
    return this.$raw
      .getEntityByRut(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/AddFavoriteGoalTransaction
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddFavoriteGoalTransactionResponse>
   */
  addFavoriteGoalTransaction(
    requestData: thisProto.AddFavoriteGoalTransactionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddFavoriteGoalTransactionResponse> {
    return this.$raw
      .addFavoriteGoalTransaction(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetGoalFavorite
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.GoalModel>
   */
  getGoalFavorite(
    requestData: thisProto.GetGoalFavoriteByUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.GoalModel> {
    return this.$raw
      .getGoalFavorite(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetBoxTransactions
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetBoxTransactionsResponse>
   */
  getBoxTransactions(
    requestData: thisProto.GetBoxTransactionsByUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetBoxTransactionsResponse> {
    return this.$raw
      .getBoxTransactions(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/EditBoxTransactions
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.EditBoxTransactionsResponse>
   */
  editBoxTransactions(
    requestData: thisProto.EditBoxTransactionsByUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.EditBoxTransactionsResponse> {
    return this.$raw
      .editBoxTransactions(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/AddNewEntityRelation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddNewEntityRelationResponse>
   */
  addNewEntityRelation(
    requestData: thisProto.AddNewEntityRelationRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddNewEntityRelationResponse> {
    return this.$raw
      .addNewEntityRelation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/ManageEntityRelation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ManageEntityRelationResponse>
   */
  manageEntityRelation(
    requestData: thisProto.ManageEntityRelationRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ManageEntityRelationResponse> {
    return this.$raw
      .manageEntityRelation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/AddDocument
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  addDocument(
    requestData: thisProto.AddDocumentRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .addDocument(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/AddContractFiles
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  addContractFiles(
    requestData: thisProto.AddContractFilesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .addContractFiles(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/SendGoalMilestoneEmail
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  sendGoalMilestoneEmail(
    requestData: thisProto.SendGoalMilestoneEmailRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .sendGoalMilestoneEmail(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/SendUserFeedback
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  sendUserFeedback(
    requestData: thisProto.SendUserFeedbackRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .sendUserFeedback(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetLastBoxAmount
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetLastBoxAmountResponse>
   */
  getLastBoxAmount(
    requestData: thisProto.GetBoxTransactionsByUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetLastBoxAmountResponse> {
    return this.$raw
      .getLastBoxAmount(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetBalanceNetDepositGraph
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.BalanceNetDepositGraphDetailModel>
   */
  getBalanceNetDepositGraph(
    requestData: thisProto.GetBalanceNetDepositGraphRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.BalanceNetDepositGraphDetailModel> {
    return this.$raw
      .getBalanceNetDepositGraph(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetMeSummary
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetSummaryResponse>
   */
  getMeSummary(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetSummaryResponse> {
    return this.$raw
      .getMeSummary(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetSummary
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetSummaryResponse>
   */
  getSummary(
    requestData: thisProto.GetSummaryRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetSummaryResponse> {
    return this.$raw
      .getSummary(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetMe
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserModel>
   */
  getMe(
    requestData: thisProto.GetMeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserModel> {
    return this.$raw
      .getMe(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetMeAdvisor
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserModel>
   */
  getMeAdvisor(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserModel> {
    return this.$raw
      .getMeAdvisor(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetMeInformation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserInformationModel>
   */
  getMeInformation(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserInformationModel> {
    return this.$raw
      .getMeInformation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetMeTransactions
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeTransactionsResponse>
   */
  getMeTransactions(
    requestData: common_message008.BaseListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeTransactionsResponse> {
    return this.$raw
      .getMeTransactions(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetLastFundingsValues
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetLastFundingsValuesResponse>
   */
  getLastFundingsValues(
    requestData: thisProto.GetLastFundingsValuesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetLastFundingsValuesResponse> {
    return this.$raw
      .getLastFundingsValues(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetFundingsValuesByUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetFundingsValuesByUserResponse>
   */
  getFundingsValuesByUser(
    requestData: thisProto.GetFundingsValuesByUserRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetFundingsValuesByUserResponse> {
    return this.$raw
      .getFundingsValuesByUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/SkipeAdvisor
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  skipeAdvisor(
    requestData: common_message008.UserModel,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .skipeAdvisor(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/ConfirmScheduledAdvisor
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  confirmScheduledAdvisor(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .confirmScheduledAdvisor(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/AddOrUpdateDeviceInstallation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  addOrUpdateDeviceInstallation(
    requestData: thisProto.AddOrUpdateDeviceInstallationRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .addOrUpdateDeviceInstallation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/RemoveDeviceInstallation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  removeDeviceInstallation(
    requestData: thisProto.RemoveDeviceInstallationRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .removeDeviceInstallation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetActiveCodes
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListUserCodeResponse>
   */
  getActiveCodes(
    requestData: thisProto.GetActiveCodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListUserCodeResponse> {
    return this.$raw
      .getActiveCodes(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetActiveCodesAdmin
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListUserCodeResponse>
   */
  getActiveCodesAdmin(
    requestData: thisProto.GetActiveCodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListUserCodeResponse> {
    return this.$raw
      .getActiveCodesAdmin(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/CreateUserCode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  createUserCode(
    requestData: thisProto.CreateUserCodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .createUserCode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/EditUserCode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  editUserCode(
    requestData: thisProto.CreateUserCodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .editUserCode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/RemoveUserCode
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  removeUserCode(
    requestData: thisProto.CreateUserCodeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .removeUserCode(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetActiveCodesUsers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UserCodeReferralsResponse>
   */
  getActiveCodesUsers(
    requestData: thisProto.GetActiveCodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UserCodeReferralsResponse> {
    return this.$raw
      .getActiveCodesUsers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetActiveCodesUsersAdmin
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UserCodeReferralsResponse>
   */
  getActiveCodesUsersAdmin(
    requestData: thisProto.GetActiveCodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UserCodeReferralsResponse> {
    return this.$raw
      .getActiveCodesUsersAdmin(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetBankAccounts
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BankAccountsResponse>
   */
  getBankAccounts(
    requestData: thisProto.GetBankAccountsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BankAccountsResponse> {
    return this.$raw
      .getBankAccounts(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/SetBankAccount
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BankAccountResponse>
   */
  setBankAccount(
    requestData: thisProto.SetBankAccountRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BankAccountResponse> {
    return this.$raw
      .setBankAccount(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/RemoveBankAccount
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BankAccountResponse>
   */
  removeBankAccount(
    requestData: thisProto.RemoveBankAccountRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BankAccountResponse> {
    return this.$raw
      .removeBankAccount(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/SendReferralsEmail
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  sendReferralsEmail(
    requestData: thisProto.SendReferralsEmailRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .sendReferralsEmail(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/CreateReferralRelation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  createReferralRelation(
    requestData: thisProto.CreateReferralRelationRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .createReferralRelation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/CheckReferralRelation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  checkReferralRelation(
    requestData: thisProto.GetActiveCodesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .checkReferralRelation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetAllReferralTransactions
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.ListUserReferralTransactionResponse>
   */
  getAllReferralTransactions(
    requestData: common_message008.BaseListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.ListUserReferralTransactionResponse> {
    return this.$raw
      .getAllReferralTransactions(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/CreateReferralTransaction
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  createReferralTransaction(
    requestData: thisProto.CreateReferralTransactionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .createReferralTransaction(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/RemoveReferralTransaction
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BaseResponse>
   */
  removeReferralTransaction(
    requestData: thisProto.CreateReferralTransactionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BaseResponse> {
    return this.$raw
      .removeReferralTransaction(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetReferralTransactionsSummary
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ReferralTransactionsSummaryResponse>
   */
  getReferralTransactionsSummary(
    requestData: common_message008.BaseGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ReferralTransactionsSummaryResponse> {
    return this.$raw
      .getReferralTransactionsSummary(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetScheduleEvents
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListScheduleEventResponse>
   */
  getScheduleEvents(
    requestData: common_message008.BaseListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListScheduleEventResponse> {
    return this.$raw
      .getScheduleEvents(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/user.UserGrpcService/GetMeManagers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeManagersResponse>
   */
  getMeManagers(
    requestData: thisProto.GetMeManagersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeManagersResponse> {
    return this.$raw
      .getMeManagers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
