export interface IInvokeCSharpActionData {
  type: InvokeCSharpActionDataTypeEnum;
  value: any;
}
export enum InvokeCSharpActionDataTypeEnum {
  IsLogin = 'IS_LOGIN',
  AccessToken = 'ACCESS_TOKEN',
  NavigationEnd = 'NAVIGATION_END',
  NavigationStart = 'NAVIGATION_START',
  ShowDebugPage = 'SHOW_DEBUG_PAGE',
  ShowCiValidatePage = 'SHOW_CI_VALIDATE_PAGE'
}
