/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './amend-risk-profile.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as common_message007 from './common-message.pb';
import { GRPC_AMEND_RISK_PROFILE_GRPC_SERVICE_CLIENT_SETTINGS } from './amend-risk-profile.pbconf';
/**
 * Service client implementation for amend_risk_profile.AmendRiskProfileGrpcService
 */
@Injectable({ providedIn: 'any' })
export class AmendRiskProfileGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /amend_risk_profile.AmendRiskProfileGrpcService/AnswerRiskProfile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AnswerAmendRiskProfileResponse>>
     */
    answerRiskProfile: (
      requestData: thisProto.AnswerAmendRiskProfileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AnswerAmendRiskProfileResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/amend_risk_profile.AmendRiskProfileGrpcService/AnswerRiskProfile',
        requestData,
        requestMetadata,
        requestClass: thisProto.AnswerAmendRiskProfileRequest,
        responseClass: thisProto.AnswerAmendRiskProfileResponse
      });
    },
    /**
     * Unary call: /amend_risk_profile.AmendRiskProfileGrpcService/CheckRiskProfile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AmendRiskProfileModel>>
     */
    checkRiskProfile: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AmendRiskProfileModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/amend_risk_profile.AmendRiskProfileGrpcService/CheckRiskProfile',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.AmendRiskProfileModel
      });
    },
    /**
     * Unary call: /amend_risk_profile.AmendRiskProfileGrpcService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AmendRiskProfileModel>>
     */
    get: (
      requestData: common_message007.BaseGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AmendRiskProfileModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/amend_risk_profile.AmendRiskProfileGrpcService/Get',
        requestData,
        requestMetadata,
        requestClass: common_message007.BaseGetRequest,
        responseClass: thisProto.AmendRiskProfileModel
      });
    },
    /**
     * Unary call: /amend_risk_profile.AmendRiskProfileGrpcService/GetAll
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AmendRiskProfileGetResponse>>
     */
    getAll: (
      requestData: thisProto.AmendRiskProfileGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AmendRiskProfileGetResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/amend_risk_profile.AmendRiskProfileGrpcService/GetAll',
        requestData,
        requestMetadata,
        requestClass: thisProto.AmendRiskProfileGetRequest,
        responseClass: thisProto.AmendRiskProfileGetResponse
      });
    }
  };

  constructor(
    @Optional()
    @Inject(GRPC_AMEND_RISK_PROFILE_GRPC_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'amend_risk_profile.AmendRiskProfileGrpcService',
      settings
    );
  }

  /**
   * Unary call @/amend_risk_profile.AmendRiskProfileGrpcService/AnswerRiskProfile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AnswerAmendRiskProfileResponse>
   */
  answerRiskProfile(
    requestData: thisProto.AnswerAmendRiskProfileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AnswerAmendRiskProfileResponse> {
    return this.$raw
      .answerRiskProfile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/amend_risk_profile.AmendRiskProfileGrpcService/CheckRiskProfile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AmendRiskProfileModel>
   */
  checkRiskProfile(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AmendRiskProfileModel> {
    return this.$raw
      .checkRiskProfile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/amend_risk_profile.AmendRiskProfileGrpcService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AmendRiskProfileModel>
   */
  get(
    requestData: common_message007.BaseGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AmendRiskProfileModel> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/amend_risk_profile.AmendRiskProfileGrpcService/GetAll
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AmendRiskProfileGetResponse>
   */
  getAll(
    requestData: thisProto.AmendRiskProfileGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AmendRiskProfileGetResponse> {
    return this.$raw
      .getAll(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
