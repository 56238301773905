/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
export enum BankNameEnum {
  BANCO_ESTADO = 0,
  COOPEUCH = 1,
  BANCO_BBVA = 2,
  BANCO_CONSORCIO = 3,
  BANCO_RIPLEY = 4,
  BANCO_FALABELLA = 5,
  BANCO_SECURITY = 6,
  THE_BANK_OF_TOKYO_MITSUBISHI_UFJ = 7,
  BANCO_ITAU = 8,
  BANCO_SANTANDER = 9,
  HSBC_BANK_CHILE = 10,
  BICE = 11,
  CORP_BANCA = 12,
  BANCO_DE_CREDITO_E_INVERSIONES = 13,
  SCOTIABANK_DESARROLLO = 14,
  BANCO_INTERNACIONAL = 15,
  BANCO_DE_CHILE = 16
}
export enum AccountTypeBankEnum {
  CUENTA_CORRIENTE = 0,
  CUENTA_VISTA = 1,
  CUENTA_AHORRO = 2
}
export enum ArticleCompareOperationEnum {
  Same = 0,
  GreaterTo = 1,
  GreaterEqualTo = 2,
  LessEqualTo = 3,
  LessThan = 4,
  Distinct = 5
}
export enum ArticleUnionOperationEnum {
  And = 0,
  Or = 1,
  NA = 2
}
export enum ArticleResponseTypeEnum {
  Text = 0,
  Number = 1,
  Select = 2,
  Calendar = 3
}
export enum UserCodeTypeEnum {
  REFERRAL = 0,
  BP_US_INVITATION = 1
}
export enum GenderEnum {
  Female = 0,
  Male = 1
}
export enum UserInscriptionTypeEnum {
  US = 0
}
export enum UserInscriptionStatusEnum {
  Initiated = 0,
  InProgress = 1,
  Cancelled = 2,
  Processed = 3
}
export enum InternalNotificationEventConfigurationType {
  NEW_VECTOR_TRANSFER = 0,
  NEW_SIGNING_CONTRACT_VECTOR = 1,
  NEW_BETTERPLANUS_ACTIVATION = 2,
  NEW_LEGAL_PERSON_REQUEST = 3,
  NEW_ADVICE_IS_SCHEDULED = 4,
  NEW_GENERAL_FEEDBACK = 5,
  NEW_RESCUE_FEEDBACK = 6,
  NEW_CHANGE_PORTFOLIO_FEEDBACK = 7,
  NEW_TRANSFER_GOAL_FEEDBACK = 8,
  ERROR_TRANSACTION = 9,
  NEW_RESCUE = 10,
  ERROR_CI_VALIDATION = 11,
  ERROR_GENERATE_CONTRACT = 12,
  NEW_FIRST_TRANSFER = 13,
  ERROR_INJECTION_WS_VECTOR = 14,
  GOAL_TRANSACTION_VECTOR_OPERATIONS_STATUS = 15,
  GENERIC_ERROR = 16,
  NEW_GET_STARTED_RESPONSES = 17,
  SCHEDULE_ADVISOR_NOT_FOUND = 18,
  ERROR_INJECTION_VOLTECH_API = 19,
  ERROR_UPDATING_CURRENCY_INDICATOR = 20,
  NEW_TX_SEMI_LIQUID = 21,
  NEW_TRANSFER_GOAL_SEMI_LIQUID = 22,
  REPORT_GOAL_TRANSACTION_OLD_STATE = 23,
  BAD_DIVIDEND_OBSERVATION_FORMAT = 24
}
export enum TemplateType {
  CERTIFICATE_SPANISH = 0,
  CERTIFICATE_ENGLISH = 1
}
export enum LangEnum {
  SPANISH = 0,
  ENGLISH = 1
}
export enum FeedbackTypeEnum {
  CHANGEPORTFOLIO = 0,
  RESCUETOGOAL = 1,
  GENERAL = 2,
  RESCUE = 3
}
export enum FinancialEntityEmailType {
  OPERATIONS = 0,
  CONTRACTS = 1
}
/**
 * Message implementation for common_message.WhereGetRequest
 */
export class WhereGetRequest implements GrpcMessage {
  static id = 'common_message.WhereGetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WhereGetRequest();
    WhereGetRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WhereGetRequest) {
    _instance.predicate = _instance.predicate || undefined;
    _instance.order = _instance.order || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WhereGetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.predicate = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.predicate,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.order = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.order,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WhereGetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WhereGetRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.predicate) {
      _writer.writeMessage(
        1,
        _instance.predicate as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.order) {
      _writer.writeMessage(
        2,
        _instance.order as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _predicate?: googleProtobuf000.StringValue;
  private _order?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WhereGetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WhereGetRequest.AsObject>) {
    _value = _value || {};
    this.predicate = _value.predicate
      ? new googleProtobuf000.StringValue(_value.predicate)
      : undefined;
    this.order = _value.order
      ? new googleProtobuf000.StringValue(_value.order)
      : undefined;
    WhereGetRequest.refineValues(this);
  }
  get predicate(): googleProtobuf000.StringValue | undefined {
    return this._predicate;
  }
  set predicate(value: googleProtobuf000.StringValue | undefined) {
    this._predicate = value;
  }
  get order(): googleProtobuf000.StringValue | undefined {
    return this._order;
  }
  set order(value: googleProtobuf000.StringValue | undefined) {
    this._order = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WhereGetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WhereGetRequest.AsObject {
    return {
      predicate: this.predicate ? this.predicate.toObject() : undefined,
      order: this.order ? this.order.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WhereGetRequest.AsProtobufJSON {
    return {
      predicate: this.predicate ? this.predicate.toProtobufJSON(options) : null,
      order: this.order ? this.order.toProtobufJSON(options) : null
    };
  }
}
export module WhereGetRequest {
  /**
   * Standard JavaScript object representation for WhereGetRequest
   */
  export interface AsObject {
    predicate?: googleProtobuf000.StringValue.AsObject;
    order?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for WhereGetRequest
   */
  export interface AsProtobufJSON {
    predicate: googleProtobuf000.StringValue.AsProtobufJSON | null;
    order: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.WhereListRequest
 */
export class WhereListRequest implements GrpcMessage {
  static id = 'common_message.WhereListRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WhereListRequest();
    WhereListRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WhereListRequest) {
    _instance.predicate = _instance.predicate || undefined;
    _instance.page = _instance.page || undefined;
    _instance.size = _instance.size || undefined;
    _instance.order = _instance.order || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WhereListRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.predicate = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.predicate,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.page = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.page,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.size = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.size,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.order = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.order,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WhereListRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WhereListRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.predicate) {
      _writer.writeMessage(
        1,
        _instance.predicate as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.page) {
      _writer.writeMessage(
        2,
        _instance.page as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.size) {
      _writer.writeMessage(
        3,
        _instance.size as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.order) {
      _writer.writeMessage(
        4,
        _instance.order as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _predicate?: googleProtobuf000.StringValue;
  private _page?: googleProtobuf000.Int32Value;
  private _size?: googleProtobuf000.Int32Value;
  private _order?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WhereListRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WhereListRequest.AsObject>) {
    _value = _value || {};
    this.predicate = _value.predicate
      ? new googleProtobuf000.StringValue(_value.predicate)
      : undefined;
    this.page = _value.page
      ? new googleProtobuf000.Int32Value(_value.page)
      : undefined;
    this.size = _value.size
      ? new googleProtobuf000.Int32Value(_value.size)
      : undefined;
    this.order = _value.order
      ? new googleProtobuf000.StringValue(_value.order)
      : undefined;
    WhereListRequest.refineValues(this);
  }
  get predicate(): googleProtobuf000.StringValue | undefined {
    return this._predicate;
  }
  set predicate(value: googleProtobuf000.StringValue | undefined) {
    this._predicate = value;
  }
  get page(): googleProtobuf000.Int32Value | undefined {
    return this._page;
  }
  set page(value: googleProtobuf000.Int32Value | undefined) {
    this._page = value;
  }
  get size(): googleProtobuf000.Int32Value | undefined {
    return this._size;
  }
  set size(value: googleProtobuf000.Int32Value | undefined) {
    this._size = value;
  }
  get order(): googleProtobuf000.StringValue | undefined {
    return this._order;
  }
  set order(value: googleProtobuf000.StringValue | undefined) {
    this._order = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WhereListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WhereListRequest.AsObject {
    return {
      predicate: this.predicate ? this.predicate.toObject() : undefined,
      page: this.page ? this.page.toObject() : undefined,
      size: this.size ? this.size.toObject() : undefined,
      order: this.order ? this.order.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WhereListRequest.AsProtobufJSON {
    return {
      predicate: this.predicate ? this.predicate.toProtobufJSON(options) : null,
      page: this.page ? this.page.toProtobufJSON(options) : null,
      size: this.size ? this.size.toProtobufJSON(options) : null,
      order: this.order ? this.order.toProtobufJSON(options) : null
    };
  }
}
export module WhereListRequest {
  /**
   * Standard JavaScript object representation for WhereListRequest
   */
  export interface AsObject {
    predicate?: googleProtobuf000.StringValue.AsObject;
    page?: googleProtobuf000.Int32Value.AsObject;
    size?: googleProtobuf000.Int32Value.AsObject;
    order?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for WhereListRequest
   */
  export interface AsProtobufJSON {
    predicate: googleProtobuf000.StringValue.AsProtobufJSON | null;
    page: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    size: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    order: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.BaseResponse
 */
export class BaseResponse implements GrpcMessage {
  static id = 'common_message.BaseResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BaseResponse();
    BaseResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BaseResponse) {
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BaseResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BaseResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BaseResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeInt32(1, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _status: number;
  private _message: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BaseResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<BaseResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    this.message = _value.message;
    BaseResponse.refineValues(this);
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BaseResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BaseResponse.AsObject {
    return {
      status: this.status,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BaseResponse.AsProtobufJSON {
    return {
      status: this.status,
      message: this.message
    };
  }
}
export module BaseResponse {
  /**
   * Standard JavaScript object representation for BaseResponse
   */
  export interface AsObject {
    status: number;
    message: string;
  }

  /**
   * Protobuf JSON representation for BaseResponse
   */
  export interface AsProtobufJSON {
    status: number;
    message: string;
  }
}

/**
 * Message implementation for common_message.BaseGetRequest
 */
export class BaseGetRequest implements GrpcMessage {
  static id = 'common_message.BaseGetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BaseGetRequest();
    BaseGetRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BaseGetRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BaseGetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.where = new WhereGetRequest();
          _reader.readMessage(
            _instance.where,
            WhereGetRequest.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.include = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BaseGetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BaseGetRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.where) {
      _writer.writeMessage(
        1,
        _instance.where as any,
        WhereGetRequest.serializeBinaryToWriter
      );
    }
    if (_instance.include || _instance.include === '') {
      _writer.writeString(2, _instance.include);
    }
  }

  private _where?: WhereGetRequest;
  private _include: string;

  private _whereOf: BaseGetRequest.WhereOfCase =
    BaseGetRequest.WhereOfCase.none;
  private _includeOf: BaseGetRequest.IncludeOfCase =
    BaseGetRequest.IncludeOfCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BaseGetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<BaseGetRequest.AsObject>) {
    _value = _value || {};
    this.where = _value.where ? new WhereGetRequest(_value.where) : undefined;
    this.include = _value.include;
    BaseGetRequest.refineValues(this);
  }
  get where(): WhereGetRequest | undefined {
    return this._where;
  }
  set where(value: WhereGetRequest | undefined) {
    if (value !== undefined && value !== null) {
      this._whereOf = BaseGetRequest.WhereOfCase.where;
    }
    this._where = value;
  }
  get include(): string {
    return this._include;
  }
  set include(value: string) {
    if (value !== undefined && value !== null) {
      this._includeOf = BaseGetRequest.IncludeOfCase.include;
    }
    this._include = value;
  }
  get whereOf() {
    return this._whereOf;
  }
  get includeOf() {
    return this._includeOf;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BaseGetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BaseGetRequest.AsObject {
    return {
      where: this.where ? this.where.toObject() : undefined,
      include: this.include
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BaseGetRequest.AsProtobufJSON {
    return {
      where: this.where ? this.where.toProtobufJSON(options) : null,
      include:
        this.include === null || this.include === undefined
          ? null
          : this.include
    };
  }
}
export module BaseGetRequest {
  /**
   * Standard JavaScript object representation for BaseGetRequest
   */
  export interface AsObject {
    where?: WhereGetRequest.AsObject;
    include: string;
  }

  /**
   * Protobuf JSON representation for BaseGetRequest
   */
  export interface AsProtobufJSON {
    where: WhereGetRequest.AsProtobufJSON | null;
    include: string | null;
  }
  export enum WhereOfCase {
    none = 0,
    where = 1
  }
  export enum IncludeOfCase {
    none = 0,
    include = 1
  }
}

/**
 * Message implementation for common_message.BaseListRequest
 */
export class BaseListRequest implements GrpcMessage {
  static id = 'common_message.BaseListRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BaseListRequest();
    BaseListRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BaseListRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BaseListRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.where = new WhereListRequest();
          _reader.readMessage(
            _instance.where,
            WhereListRequest.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.include = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BaseListRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BaseListRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.where) {
      _writer.writeMessage(
        1,
        _instance.where as any,
        WhereListRequest.serializeBinaryToWriter
      );
    }
    if (_instance.include || _instance.include === '') {
      _writer.writeString(2, _instance.include);
    }
  }

  private _where?: WhereListRequest;
  private _include: string;

  private _whereOf: BaseListRequest.WhereOfCase =
    BaseListRequest.WhereOfCase.none;
  private _includeOf: BaseListRequest.IncludeOfCase =
    BaseListRequest.IncludeOfCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BaseListRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<BaseListRequest.AsObject>) {
    _value = _value || {};
    this.where = _value.where ? new WhereListRequest(_value.where) : undefined;
    this.include = _value.include;
    BaseListRequest.refineValues(this);
  }
  get where(): WhereListRequest | undefined {
    return this._where;
  }
  set where(value: WhereListRequest | undefined) {
    if (value !== undefined && value !== null) {
      this._whereOf = BaseListRequest.WhereOfCase.where;
    }
    this._where = value;
  }
  get include(): string {
    return this._include;
  }
  set include(value: string) {
    if (value !== undefined && value !== null) {
      this._includeOf = BaseListRequest.IncludeOfCase.include;
    }
    this._include = value;
  }
  get whereOf() {
    return this._whereOf;
  }
  get includeOf() {
    return this._includeOf;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BaseListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BaseListRequest.AsObject {
    return {
      where: this.where ? this.where.toObject() : undefined,
      include: this.include
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BaseListRequest.AsProtobufJSON {
    return {
      where: this.where ? this.where.toProtobufJSON(options) : null,
      include:
        this.include === null || this.include === undefined
          ? null
          : this.include
    };
  }
}
export module BaseListRequest {
  /**
   * Standard JavaScript object representation for BaseListRequest
   */
  export interface AsObject {
    where?: WhereListRequest.AsObject;
    include: string;
  }

  /**
   * Protobuf JSON representation for BaseListRequest
   */
  export interface AsProtobufJSON {
    where: WhereListRequest.AsProtobufJSON | null;
    include: string | null;
  }
  export enum WhereOfCase {
    none = 0,
    where = 1
  }
  export enum IncludeOfCase {
    none = 0,
    include = 1
  }
}

/**
 * Message implementation for common_message.BaseRemoveByIdRequest
 */
export class BaseRemoveByIdRequest implements GrpcMessage {
  static id = 'common_message.BaseRemoveByIdRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BaseRemoveByIdRequest();
    BaseRemoveByIdRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BaseRemoveByIdRequest) {
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BaseRemoveByIdRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    BaseRemoveByIdRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BaseRemoveByIdRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BaseRemoveByIdRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<BaseRemoveByIdRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    BaseRemoveByIdRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BaseRemoveByIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BaseRemoveByIdRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BaseRemoveByIdRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module BaseRemoveByIdRequest {
  /**
   * Standard JavaScript object representation for BaseRemoveByIdRequest
   */
  export interface AsObject {
    id: number;
  }

  /**
   * Protobuf JSON representation for BaseRemoveByIdRequest
   */
  export interface AsProtobufJSON {
    id: number;
  }
}

/**
 * Message implementation for common_message.ScheduleEventModel
 */
export class ScheduleEventModel implements GrpcMessage {
  static id = 'common_message.ScheduleEventModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ScheduleEventModel();
    ScheduleEventModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ScheduleEventModel) {
    _instance.id = _instance.id || undefined;
    _instance.uuid = _instance.uuid || '';
    _instance.date = _instance.date || undefined;
    _instance.location = _instance.location || '';
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.ownerId = _instance.ownerId || undefined;
    _instance.inviteeId = _instance.inviteeId || undefined;
    _instance.owner = _instance.owner || undefined;
    _instance.canceled = _instance.canceled || false;
    _instance.cancelEventUrl = _instance.cancelEventUrl || '';
    _instance.reprogramEventUrl = _instance.reprogramEventUrl || '';
    _instance.logReadAiId = _instance.logReadAiId || undefined;
    _instance.logReadAi = _instance.logReadAi || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ScheduleEventModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.id,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.uuid = _reader.readString();
          break;
        case 3:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.location = _reader.readString();
          break;
        case 5:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.ownerId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.ownerId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.inviteeId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.inviteeId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.owner = new UserModel();
          _reader.readMessage(
            _instance.owner,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.canceled = _reader.readBool();
          break;
        case 11:
          _instance.cancelEventUrl = _reader.readString();
          break;
        case 12:
          _instance.reprogramEventUrl = _reader.readString();
          break;
        case 13:
          _instance.logReadAiId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.logReadAiId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.logReadAi = new LogReadAiModel();
          _reader.readMessage(
            _instance.logReadAi,
            LogReadAiModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ScheduleEventModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ScheduleEventModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeMessage(
        1,
        _instance.id as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.uuid) {
      _writer.writeString(2, _instance.uuid);
    }
    if (_instance.date) {
      _writer.writeMessage(
        3,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.location) {
      _writer.writeString(4, _instance.location);
    }
    if (_instance.created) {
      _writer.writeMessage(
        5,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        6,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.ownerId) {
      _writer.writeMessage(
        7,
        _instance.ownerId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.inviteeId) {
      _writer.writeMessage(
        8,
        _instance.inviteeId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.owner) {
      _writer.writeMessage(
        9,
        _instance.owner as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.canceled) {
      _writer.writeBool(10, _instance.canceled);
    }
    if (_instance.cancelEventUrl) {
      _writer.writeString(11, _instance.cancelEventUrl);
    }
    if (_instance.reprogramEventUrl) {
      _writer.writeString(12, _instance.reprogramEventUrl);
    }
    if (_instance.logReadAiId) {
      _writer.writeMessage(
        13,
        _instance.logReadAiId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.logReadAi) {
      _writer.writeMessage(
        14,
        _instance.logReadAi as any,
        LogReadAiModel.serializeBinaryToWriter
      );
    }
  }

  private _id?: googleProtobuf000.Int32Value;
  private _uuid: string;
  private _date?: googleProtobuf001.Timestamp;
  private _location: string;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _ownerId?: googleProtobuf000.Int32Value;
  private _inviteeId?: googleProtobuf000.Int32Value;
  private _owner?: UserModel;
  private _canceled: boolean;
  private _cancelEventUrl: string;
  private _reprogramEventUrl: string;
  private _logReadAiId?: googleProtobuf000.Int32Value;
  private _logReadAi?: LogReadAiModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ScheduleEventModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ScheduleEventModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id
      ? new googleProtobuf000.Int32Value(_value.id)
      : undefined;
    this.uuid = _value.uuid;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.location = _value.location;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.ownerId = _value.ownerId
      ? new googleProtobuf000.Int32Value(_value.ownerId)
      : undefined;
    this.inviteeId = _value.inviteeId
      ? new googleProtobuf000.Int32Value(_value.inviteeId)
      : undefined;
    this.owner = _value.owner ? new UserModel(_value.owner) : undefined;
    this.canceled = _value.canceled;
    this.cancelEventUrl = _value.cancelEventUrl;
    this.reprogramEventUrl = _value.reprogramEventUrl;
    this.logReadAiId = _value.logReadAiId
      ? new googleProtobuf000.Int32Value(_value.logReadAiId)
      : undefined;
    this.logReadAi = _value.logReadAi
      ? new LogReadAiModel(_value.logReadAi)
      : undefined;
    ScheduleEventModel.refineValues(this);
  }
  get id(): googleProtobuf000.Int32Value | undefined {
    return this._id;
  }
  set id(value: googleProtobuf000.Int32Value | undefined) {
    this._id = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get location(): string {
    return this._location;
  }
  set location(value: string) {
    this._location = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get ownerId(): googleProtobuf000.Int32Value | undefined {
    return this._ownerId;
  }
  set ownerId(value: googleProtobuf000.Int32Value | undefined) {
    this._ownerId = value;
  }
  get inviteeId(): googleProtobuf000.Int32Value | undefined {
    return this._inviteeId;
  }
  set inviteeId(value: googleProtobuf000.Int32Value | undefined) {
    this._inviteeId = value;
  }
  get owner(): UserModel | undefined {
    return this._owner;
  }
  set owner(value: UserModel | undefined) {
    this._owner = value;
  }
  get canceled(): boolean {
    return this._canceled;
  }
  set canceled(value: boolean) {
    this._canceled = value;
  }
  get cancelEventUrl(): string {
    return this._cancelEventUrl;
  }
  set cancelEventUrl(value: string) {
    this._cancelEventUrl = value;
  }
  get reprogramEventUrl(): string {
    return this._reprogramEventUrl;
  }
  set reprogramEventUrl(value: string) {
    this._reprogramEventUrl = value;
  }
  get logReadAiId(): googleProtobuf000.Int32Value | undefined {
    return this._logReadAiId;
  }
  set logReadAiId(value: googleProtobuf000.Int32Value | undefined) {
    this._logReadAiId = value;
  }
  get logReadAi(): LogReadAiModel | undefined {
    return this._logReadAi;
  }
  set logReadAi(value: LogReadAiModel | undefined) {
    this._logReadAi = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ScheduleEventModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ScheduleEventModel.AsObject {
    return {
      id: this.id ? this.id.toObject() : undefined,
      uuid: this.uuid,
      date: this.date ? this.date.toObject() : undefined,
      location: this.location,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      ownerId: this.ownerId ? this.ownerId.toObject() : undefined,
      inviteeId: this.inviteeId ? this.inviteeId.toObject() : undefined,
      owner: this.owner ? this.owner.toObject() : undefined,
      canceled: this.canceled,
      cancelEventUrl: this.cancelEventUrl,
      reprogramEventUrl: this.reprogramEventUrl,
      logReadAiId: this.logReadAiId ? this.logReadAiId.toObject() : undefined,
      logReadAi: this.logReadAi ? this.logReadAi.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ScheduleEventModel.AsProtobufJSON {
    return {
      id: this.id ? this.id.toProtobufJSON(options) : null,
      uuid: this.uuid,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      location: this.location,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      ownerId: this.ownerId ? this.ownerId.toProtobufJSON(options) : null,
      inviteeId: this.inviteeId ? this.inviteeId.toProtobufJSON(options) : null,
      owner: this.owner ? this.owner.toProtobufJSON(options) : null,
      canceled: this.canceled,
      cancelEventUrl: this.cancelEventUrl,
      reprogramEventUrl: this.reprogramEventUrl,
      logReadAiId: this.logReadAiId
        ? this.logReadAiId.toProtobufJSON(options)
        : null,
      logReadAi: this.logReadAi ? this.logReadAi.toProtobufJSON(options) : null
    };
  }
}
export module ScheduleEventModel {
  /**
   * Standard JavaScript object representation for ScheduleEventModel
   */
  export interface AsObject {
    id?: googleProtobuf000.Int32Value.AsObject;
    uuid: string;
    date?: googleProtobuf001.Timestamp.AsObject;
    location: string;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    ownerId?: googleProtobuf000.Int32Value.AsObject;
    inviteeId?: googleProtobuf000.Int32Value.AsObject;
    owner?: UserModel.AsObject;
    canceled: boolean;
    cancelEventUrl: string;
    reprogramEventUrl: string;
    logReadAiId?: googleProtobuf000.Int32Value.AsObject;
    logReadAi?: LogReadAiModel.AsObject;
  }

  /**
   * Protobuf JSON representation for ScheduleEventModel
   */
  export interface AsProtobufJSON {
    id: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    uuid: string;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    location: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    ownerId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    inviteeId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    owner: UserModel.AsProtobufJSON | null;
    canceled: boolean;
    cancelEventUrl: string;
    reprogramEventUrl: string;
    logReadAiId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    logReadAi: LogReadAiModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.LogReadAiModel
 */
export class LogReadAiModel implements GrpcMessage {
  static id = 'common_message.LogReadAiModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogReadAiModel();
    LogReadAiModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogReadAiModel) {
    _instance.id = _instance.id || 0;
    _instance.sessionId = _instance.sessionId || '';
    _instance.trigger = _instance.trigger || '';
    _instance.title = _instance.title || '';
    _instance.reportUrl = _instance.reportUrl || '';
    _instance.summary = _instance.summary || '';
    _instance.startTime = _instance.startTime || undefined;
    _instance.topics = _instance.topics || [];
    _instance.keyQuestions = _instance.keyQuestions || [];
    _instance.actions = _instance.actions || [];
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogReadAiModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.sessionId = _reader.readString();
          break;
        case 3:
          _instance.trigger = _reader.readString();
          break;
        case 4:
          _instance.title = _reader.readString();
          break;
        case 5:
          _instance.reportUrl = _reader.readString();
          break;
        case 6:
          _instance.summary = _reader.readString();
          break;
        case 7:
          _instance.startTime = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          const messageInitializer8 = new LogReadAiTextsModel();
          _reader.readMessage(
            messageInitializer8,
            LogReadAiTextsModel.deserializeBinaryFromReader
          );
          (_instance.topics = _instance.topics || []).push(messageInitializer8);
          break;
        case 9:
          const messageInitializer9 = new LogReadAiTextsModel();
          _reader.readMessage(
            messageInitializer9,
            LogReadAiTextsModel.deserializeBinaryFromReader
          );
          (_instance.keyQuestions = _instance.keyQuestions || []).push(
            messageInitializer9
          );
          break;
        case 10:
          const messageInitializer10 = new LogReadAiTextsModel();
          _reader.readMessage(
            messageInitializer10,
            LogReadAiTextsModel.deserializeBinaryFromReader
          );
          (_instance.actions = _instance.actions || []).push(
            messageInitializer10
          );
          break;
        case 11:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    LogReadAiModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogReadAiModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.sessionId) {
      _writer.writeString(2, _instance.sessionId);
    }
    if (_instance.trigger) {
      _writer.writeString(3, _instance.trigger);
    }
    if (_instance.title) {
      _writer.writeString(4, _instance.title);
    }
    if (_instance.reportUrl) {
      _writer.writeString(5, _instance.reportUrl);
    }
    if (_instance.summary) {
      _writer.writeString(6, _instance.summary);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        7,
        _instance.startTime as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.topics && _instance.topics.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.topics as any,
        LogReadAiTextsModel.serializeBinaryToWriter
      );
    }
    if (_instance.keyQuestions && _instance.keyQuestions.length) {
      _writer.writeRepeatedMessage(
        9,
        _instance.keyQuestions as any,
        LogReadAiTextsModel.serializeBinaryToWriter
      );
    }
    if (_instance.actions && _instance.actions.length) {
      _writer.writeRepeatedMessage(
        10,
        _instance.actions as any,
        LogReadAiTextsModel.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        11,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        12,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _sessionId: string;
  private _trigger: string;
  private _title: string;
  private _reportUrl: string;
  private _summary: string;
  private _startTime?: googleProtobuf001.Timestamp;
  private _topics?: LogReadAiTextsModel[];
  private _keyQuestions?: LogReadAiTextsModel[];
  private _actions?: LogReadAiTextsModel[];
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogReadAiModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogReadAiModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.sessionId = _value.sessionId;
    this.trigger = _value.trigger;
    this.title = _value.title;
    this.reportUrl = _value.reportUrl;
    this.summary = _value.summary;
    this.startTime = _value.startTime
      ? new googleProtobuf001.Timestamp(_value.startTime)
      : undefined;
    this.topics = (_value.topics || []).map(m => new LogReadAiTextsModel(m));
    this.keyQuestions = (_value.keyQuestions || []).map(
      m => new LogReadAiTextsModel(m)
    );
    this.actions = (_value.actions || []).map(m => new LogReadAiTextsModel(m));
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    LogReadAiModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get sessionId(): string {
    return this._sessionId;
  }
  set sessionId(value: string) {
    this._sessionId = value;
  }
  get trigger(): string {
    return this._trigger;
  }
  set trigger(value: string) {
    this._trigger = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get reportUrl(): string {
    return this._reportUrl;
  }
  set reportUrl(value: string) {
    this._reportUrl = value;
  }
  get summary(): string {
    return this._summary;
  }
  set summary(value: string) {
    this._summary = value;
  }
  get startTime(): googleProtobuf001.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf001.Timestamp | undefined) {
    this._startTime = value;
  }
  get topics(): LogReadAiTextsModel[] | undefined {
    return this._topics;
  }
  set topics(value: LogReadAiTextsModel[] | undefined) {
    this._topics = value;
  }
  get keyQuestions(): LogReadAiTextsModel[] | undefined {
    return this._keyQuestions;
  }
  set keyQuestions(value: LogReadAiTextsModel[] | undefined) {
    this._keyQuestions = value;
  }
  get actions(): LogReadAiTextsModel[] | undefined {
    return this._actions;
  }
  set actions(value: LogReadAiTextsModel[] | undefined) {
    this._actions = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogReadAiModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogReadAiModel.AsObject {
    return {
      id: this.id,
      sessionId: this.sessionId,
      trigger: this.trigger,
      title: this.title,
      reportUrl: this.reportUrl,
      summary: this.summary,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      topics: (this.topics || []).map(m => m.toObject()),
      keyQuestions: (this.keyQuestions || []).map(m => m.toObject()),
      actions: (this.actions || []).map(m => m.toObject()),
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogReadAiModel.AsProtobufJSON {
    return {
      id: this.id,
      sessionId: this.sessionId,
      trigger: this.trigger,
      title: this.title,
      reportUrl: this.reportUrl,
      summary: this.summary,
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      topics: (this.topics || []).map(m => m.toProtobufJSON(options)),
      keyQuestions: (this.keyQuestions || []).map(m =>
        m.toProtobufJSON(options)
      ),
      actions: (this.actions || []).map(m => m.toProtobufJSON(options)),
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module LogReadAiModel {
  /**
   * Standard JavaScript object representation for LogReadAiModel
   */
  export interface AsObject {
    id: number;
    sessionId: string;
    trigger: string;
    title: string;
    reportUrl: string;
    summary: string;
    startTime?: googleProtobuf001.Timestamp.AsObject;
    topics?: LogReadAiTextsModel.AsObject[];
    keyQuestions?: LogReadAiTextsModel.AsObject[];
    actions?: LogReadAiTextsModel.AsObject[];
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for LogReadAiModel
   */
  export interface AsProtobufJSON {
    id: number;
    sessionId: string;
    trigger: string;
    title: string;
    reportUrl: string;
    summary: string;
    startTime: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    topics: LogReadAiTextsModel.AsProtobufJSON[] | null;
    keyQuestions: LogReadAiTextsModel.AsProtobufJSON[] | null;
    actions: LogReadAiTextsModel.AsProtobufJSON[] | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.LogReadAiTextsModel
 */
export class LogReadAiTextsModel implements GrpcMessage {
  static id = 'common_message.LogReadAiTextsModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogReadAiTextsModel();
    LogReadAiTextsModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogReadAiTextsModel) {
    _instance.text = _instance.text || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogReadAiTextsModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.text = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    LogReadAiTextsModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogReadAiTextsModel,
    _writer: BinaryWriter
  ) {
    if (_instance.text) {
      _writer.writeString(1, _instance.text);
    }
  }

  private _text: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogReadAiTextsModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogReadAiTextsModel.AsObject>) {
    _value = _value || {};
    this.text = _value.text;
    LogReadAiTextsModel.refineValues(this);
  }
  get text(): string {
    return this._text;
  }
  set text(value: string) {
    this._text = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogReadAiTextsModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogReadAiTextsModel.AsObject {
    return {
      text: this.text
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogReadAiTextsModel.AsProtobufJSON {
    return {
      text: this.text
    };
  }
}
export module LogReadAiTextsModel {
  /**
   * Standard JavaScript object representation for LogReadAiTextsModel
   */
  export interface AsObject {
    text: string;
  }

  /**
   * Protobuf JSON representation for LogReadAiTextsModel
   */
  export interface AsProtobufJSON {
    text: string;
  }
}

/**
 * Message implementation for common_message.UserReferralTransactionModel
 */
export class UserReferralTransactionModel implements GrpcMessage {
  static id = 'common_message.UserReferralTransactionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserReferralTransactionModel();
    UserReferralTransactionModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserReferralTransactionModel) {
    _instance.id = _instance.id || undefined;
    _instance.rut = _instance.rut || '';
    _instance.date = _instance.date || undefined;
    _instance.amount = _instance.amount || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.user = _instance.user || undefined;
    _instance.description = _instance.description || '';
    _instance.amountCurrencyId = _instance.amountCurrencyId || 0;
    _instance.state = _instance.state || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserReferralTransactionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.id,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.rut = _reader.readString();
          break;
        case 3:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.amount = _reader.readDouble();
          break;
        case 5:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.user = new UserModel();
          _reader.readMessage(
            _instance.user,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.description = _reader.readString();
          break;
        case 9:
          _instance.amountCurrencyId = _reader.readInt32();
          break;
        case 10:
          _instance.state = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    UserReferralTransactionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserReferralTransactionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeMessage(
        1,
        _instance.id as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.rut) {
      _writer.writeString(2, _instance.rut);
    }
    if (_instance.date) {
      _writer.writeMessage(
        3,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.amount) {
      _writer.writeDouble(4, _instance.amount);
    }
    if (_instance.created) {
      _writer.writeMessage(
        5,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        6,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.user) {
      _writer.writeMessage(
        7,
        _instance.user as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(8, _instance.description);
    }
    if (_instance.amountCurrencyId) {
      _writer.writeInt32(9, _instance.amountCurrencyId);
    }
    if (_instance.state) {
      _writer.writeEnum(10, _instance.state);
    }
  }

  private _id?: googleProtobuf000.Int32Value;
  private _rut: string;
  private _date?: googleProtobuf001.Timestamp;
  private _amount: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _user?: UserModel;
  private _description: string;
  private _amountCurrencyId: number;
  private _state: goal_transaction_enums002.GoalTransactionState;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserReferralTransactionModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UserReferralTransactionModel.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id
      ? new googleProtobuf000.Int32Value(_value.id)
      : undefined;
    this.rut = _value.rut;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.amount = _value.amount;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.user = _value.user ? new UserModel(_value.user) : undefined;
    this.description = _value.description;
    this.amountCurrencyId = _value.amountCurrencyId;
    this.state = _value.state;
    UserReferralTransactionModel.refineValues(this);
  }
  get id(): googleProtobuf000.Int32Value | undefined {
    return this._id;
  }
  set id(value: googleProtobuf000.Int32Value | undefined) {
    this._id = value;
  }
  get rut(): string {
    return this._rut;
  }
  set rut(value: string) {
    this._rut = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get user(): UserModel | undefined {
    return this._user;
  }
  set user(value: UserModel | undefined) {
    this._user = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get amountCurrencyId(): number {
    return this._amountCurrencyId;
  }
  set amountCurrencyId(value: number) {
    this._amountCurrencyId = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserReferralTransactionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserReferralTransactionModel.AsObject {
    return {
      id: this.id ? this.id.toObject() : undefined,
      rut: this.rut,
      date: this.date ? this.date.toObject() : undefined,
      amount: this.amount,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      user: this.user ? this.user.toObject() : undefined,
      description: this.description,
      amountCurrencyId: this.amountCurrencyId,
      state: this.state
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserReferralTransactionModel.AsProtobufJSON {
    return {
      id: this.id ? this.id.toProtobufJSON(options) : null,
      rut: this.rut,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      amount: this.amount,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      description: this.description,
      amountCurrencyId: this.amountCurrencyId,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ]
    };
  }
}
export module UserReferralTransactionModel {
  /**
   * Standard JavaScript object representation for UserReferralTransactionModel
   */
  export interface AsObject {
    id?: googleProtobuf000.Int32Value.AsObject;
    rut: string;
    date?: googleProtobuf001.Timestamp.AsObject;
    amount: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    user?: UserModel.AsObject;
    description: string;
    amountCurrencyId: number;
    state: goal_transaction_enums002.GoalTransactionState;
  }

  /**
   * Protobuf JSON representation for UserReferralTransactionModel
   */
  export interface AsProtobufJSON {
    id: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    rut: string;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    amount: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    user: UserModel.AsProtobufJSON | null;
    description: string;
    amountCurrencyId: number;
    state: string;
  }
}

/**
 * Message implementation for common_message.ListUserReferralTransactionResponse
 */
export class ListUserReferralTransactionResponse implements GrpcMessage {
  static id = 'common_message.ListUserReferralTransactionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListUserReferralTransactionResponse();
    ListUserReferralTransactionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListUserReferralTransactionResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListUserReferralTransactionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new UserReferralTransactionModel();
          _reader.readMessage(
            messageInitializer1,
            UserReferralTransactionModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListUserReferralTransactionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListUserReferralTransactionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        UserReferralTransactionModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: UserReferralTransactionModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListUserReferralTransactionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListUserReferralTransactionResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new UserReferralTransactionModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListUserReferralTransactionResponse.refineValues(this);
  }
  get values(): UserReferralTransactionModel[] | undefined {
    return this._values;
  }
  set values(value: UserReferralTransactionModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListUserReferralTransactionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListUserReferralTransactionResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListUserReferralTransactionResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListUserReferralTransactionResponse {
  /**
   * Standard JavaScript object representation for ListUserReferralTransactionResponse
   */
  export interface AsObject {
    values?: UserReferralTransactionModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListUserReferralTransactionResponse
   */
  export interface AsProtobufJSON {
    values: UserReferralTransactionModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for common_message.GoalTransactionModel
 */
export class GoalTransactionModel implements GrpcMessage {
  static id = 'common_message.GoalTransactionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalTransactionModel();
    GoalTransactionModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalTransactionModel) {
    _instance.type = _instance.type || 0;
    _instance.amount = _instance.amount || undefined;
    _instance.amountRequested = _instance.amountRequested || 0;
    _instance.amountToProcess = _instance.amountToProcess || undefined;
    _instance.date = _instance.date || undefined;
    _instance.all = _instance.all || false;
    _instance.state = _instance.state || 0;
    _instance.paymentMethod = _instance.paymentMethod || 0;
    _instance.id = _instance.id || undefined;
    _instance.goalId = _instance.goalId || undefined;
    _instance.ownerId = _instance.ownerId || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.requestFileId = _instance.requestFileId || undefined;
    _instance.voucherFileId = _instance.voucherFileId || undefined;
    _instance.isProcessed = _instance.isProcessed || false;
    _instance.goalTransactionFundings = _instance.goalTransactionFundings || [];
    _instance.goal = _instance.goal || undefined;
    _instance.identifier = _instance.identifier || '';
    _instance.financialEntityId = _instance.financialEntityId || undefined;
    _instance.goalTo = _instance.goalTo || undefined;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.userBankAccountId = _instance.userBankAccountId || undefined;
    _instance.owner = _instance.owner || undefined;
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.cost = _instance.cost || 0;
    _instance.transactionRequestId =
      _instance.transactionRequestId || undefined;
    _instance.transactionRequest = _instance.transactionRequest || undefined;
    _instance.goalFrom = _instance.goalFrom || undefined;
    _instance.transactionGoalRequests = _instance.transactionGoalRequests || [];
    _instance.userBankAccount = _instance.userBankAccount || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalTransactionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.amount = new googleProtobuf000.DoubleValue();
          _reader.readMessage(
            _instance.amount,
            googleProtobuf000.DoubleValue.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.amountRequested = _reader.readDouble();
          break;
        case 4:
          _instance.amountToProcess = new googleProtobuf000.DoubleValue();
          _reader.readMessage(
            _instance.amountToProcess,
            googleProtobuf000.DoubleValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.all = _reader.readBool();
          break;
        case 7:
          _instance.state = _reader.readEnum();
          break;
        case 8:
          _instance.paymentMethod = _reader.readEnum();
          break;
        case 9:
          _instance.id = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.id,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.goalId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.ownerId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.ownerId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.requestFileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.requestFileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.voucherFileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.voucherFileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.isProcessed = _reader.readBool();
          break;
        case 17:
          const messageInitializer17 = new GoalTransactionFundingModel();
          _reader.readMessage(
            messageInitializer17,
            GoalTransactionFundingModel.deserializeBinaryFromReader
          );
          (_instance.goalTransactionFundings =
            _instance.goalTransactionFundings || []).push(messageInitializer17);
          break;
        case 18:
          _instance.goal = new GoalModel();
          _reader.readMessage(
            _instance.goal,
            GoalModel.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.identifier = _reader.readString();
          break;
        case 20:
          _instance.financialEntityId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.financialEntityId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.goalTo = new GoalModel();
          _reader.readMessage(
            _instance.goalTo,
            GoalModel.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.currencyId = _reader.readInt32();
          break;
        case 23:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 24:
          _instance.userBankAccountId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userBankAccountId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 25:
          _instance.owner = new UserModel();
          _reader.readMessage(
            _instance.owner,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 27:
          _instance.cost = _reader.readDouble();
          break;
        case 28:
          _instance.transactionRequestId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.transactionRequestId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 29:
          _instance.transactionRequest = new TransactionRequestModel();
          _reader.readMessage(
            _instance.transactionRequest,
            TransactionRequestModel.deserializeBinaryFromReader
          );
          break;
        case 30:
          _instance.goalFrom = new GoalModel();
          _reader.readMessage(
            _instance.goalFrom,
            GoalModel.deserializeBinaryFromReader
          );
          break;
        case 31:
          const messageInitializer31 = new TransactionGoalRequestModel();
          _reader.readMessage(
            messageInitializer31,
            TransactionGoalRequestModel.deserializeBinaryFromReader
          );
          (_instance.transactionGoalRequests =
            _instance.transactionGoalRequests || []).push(messageInitializer31);
          break;
        case 32:
          _instance.userBankAccount = new UserBankAccountModel();
          _reader.readMessage(
            _instance.userBankAccount,
            UserBankAccountModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GoalTransactionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalTransactionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.amount) {
      _writer.writeMessage(
        2,
        _instance.amount as any,
        googleProtobuf000.DoubleValue.serializeBinaryToWriter
      );
    }
    if (_instance.amountRequested) {
      _writer.writeDouble(3, _instance.amountRequested);
    }
    if (_instance.amountToProcess) {
      _writer.writeMessage(
        4,
        _instance.amountToProcess as any,
        googleProtobuf000.DoubleValue.serializeBinaryToWriter
      );
    }
    if (_instance.date) {
      _writer.writeMessage(
        5,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.all) {
      _writer.writeBool(6, _instance.all);
    }
    if (_instance.state) {
      _writer.writeEnum(7, _instance.state);
    }
    if (_instance.paymentMethod) {
      _writer.writeEnum(8, _instance.paymentMethod);
    }
    if (_instance.id) {
      _writer.writeMessage(
        9,
        _instance.id as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.goalId) {
      _writer.writeMessage(
        10,
        _instance.goalId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.ownerId) {
      _writer.writeMessage(
        11,
        _instance.ownerId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        12,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        13,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.requestFileId) {
      _writer.writeMessage(
        14,
        _instance.requestFileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.voucherFileId) {
      _writer.writeMessage(
        15,
        _instance.voucherFileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.isProcessed) {
      _writer.writeBool(16, _instance.isProcessed);
    }
    if (
      _instance.goalTransactionFundings &&
      _instance.goalTransactionFundings.length
    ) {
      _writer.writeRepeatedMessage(
        17,
        _instance.goalTransactionFundings as any,
        GoalTransactionFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.goal) {
      _writer.writeMessage(
        18,
        _instance.goal as any,
        GoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.identifier) {
      _writer.writeString(19, _instance.identifier);
    }
    if (_instance.financialEntityId) {
      _writer.writeMessage(
        20,
        _instance.financialEntityId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.goalTo) {
      _writer.writeMessage(
        21,
        _instance.goalTo as any,
        GoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.currencyId) {
      _writer.writeInt32(22, _instance.currencyId);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        23,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.userBankAccountId) {
      _writer.writeMessage(
        24,
        _instance.userBankAccountId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.owner) {
      _writer.writeMessage(
        25,
        _instance.owner as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        26,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.cost) {
      _writer.writeDouble(27, _instance.cost);
    }
    if (_instance.transactionRequestId) {
      _writer.writeMessage(
        28,
        _instance.transactionRequestId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.transactionRequest) {
      _writer.writeMessage(
        29,
        _instance.transactionRequest as any,
        TransactionRequestModel.serializeBinaryToWriter
      );
    }
    if (_instance.goalFrom) {
      _writer.writeMessage(
        30,
        _instance.goalFrom as any,
        GoalModel.serializeBinaryToWriter
      );
    }
    if (
      _instance.transactionGoalRequests &&
      _instance.transactionGoalRequests.length
    ) {
      _writer.writeRepeatedMessage(
        31,
        _instance.transactionGoalRequests as any,
        TransactionGoalRequestModel.serializeBinaryToWriter
      );
    }
    if (_instance.userBankAccount) {
      _writer.writeMessage(
        32,
        _instance.userBankAccount as any,
        UserBankAccountModel.serializeBinaryToWriter
      );
    }
  }

  private _type: goal_transaction_type_enums003.GoalTransactionType;
  private _amount?: googleProtobuf000.DoubleValue;
  private _amountRequested: number;
  private _amountToProcess?: googleProtobuf000.DoubleValue;
  private _date?: googleProtobuf001.Timestamp;
  private _all: boolean;
  private _state: goal_transaction_enums002.GoalTransactionState;
  private _paymentMethod: goal_transaction_payment_enum004.GoalTransactionPaymentMethod;
  private _id?: googleProtobuf000.Int32Value;
  private _goalId?: googleProtobuf000.Int32Value;
  private _ownerId?: googleProtobuf000.Int32Value;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _requestFileId?: googleProtobuf000.Int32Value;
  private _voucherFileId?: googleProtobuf000.Int32Value;
  private _isProcessed: boolean;
  private _goalTransactionFundings?: GoalTransactionFundingModel[];
  private _goal?: GoalModel;
  private _identifier: string;
  private _financialEntityId?: googleProtobuf000.Int32Value;
  private _goalTo?: GoalModel;
  private _currencyId: number;
  private _currency?: CurrencyModel;
  private _userBankAccountId?: googleProtobuf000.Int32Value;
  private _owner?: UserModel;
  private _financialEntity?: FinancialEntityModel;
  private _cost: number;
  private _transactionRequestId?: googleProtobuf000.Int32Value;
  private _transactionRequest?: TransactionRequestModel;
  private _goalFrom?: GoalModel;
  private _transactionGoalRequests?: TransactionGoalRequestModel[];
  private _userBankAccount?: UserBankAccountModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalTransactionModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalTransactionModel.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.amount = _value.amount
      ? new googleProtobuf000.DoubleValue(_value.amount)
      : undefined;
    this.amountRequested = _value.amountRequested;
    this.amountToProcess = _value.amountToProcess
      ? new googleProtobuf000.DoubleValue(_value.amountToProcess)
      : undefined;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.all = _value.all;
    this.state = _value.state;
    this.paymentMethod = _value.paymentMethod;
    this.id = _value.id
      ? new googleProtobuf000.Int32Value(_value.id)
      : undefined;
    this.goalId = _value.goalId
      ? new googleProtobuf000.Int32Value(_value.goalId)
      : undefined;
    this.ownerId = _value.ownerId
      ? new googleProtobuf000.Int32Value(_value.ownerId)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.requestFileId = _value.requestFileId
      ? new googleProtobuf000.Int32Value(_value.requestFileId)
      : undefined;
    this.voucherFileId = _value.voucherFileId
      ? new googleProtobuf000.Int32Value(_value.voucherFileId)
      : undefined;
    this.isProcessed = _value.isProcessed;
    this.goalTransactionFundings = (_value.goalTransactionFundings || []).map(
      m => new GoalTransactionFundingModel(m)
    );
    this.goal = _value.goal ? new GoalModel(_value.goal) : undefined;
    this.identifier = _value.identifier;
    this.financialEntityId = _value.financialEntityId
      ? new googleProtobuf000.Int32Value(_value.financialEntityId)
      : undefined;
    this.goalTo = _value.goalTo ? new GoalModel(_value.goalTo) : undefined;
    this.currencyId = _value.currencyId;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.userBankAccountId = _value.userBankAccountId
      ? new googleProtobuf000.Int32Value(_value.userBankAccountId)
      : undefined;
    this.owner = _value.owner ? new UserModel(_value.owner) : undefined;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.cost = _value.cost;
    this.transactionRequestId = _value.transactionRequestId
      ? new googleProtobuf000.Int32Value(_value.transactionRequestId)
      : undefined;
    this.transactionRequest = _value.transactionRequest
      ? new TransactionRequestModel(_value.transactionRequest)
      : undefined;
    this.goalFrom = _value.goalFrom
      ? new GoalModel(_value.goalFrom)
      : undefined;
    this.transactionGoalRequests = (_value.transactionGoalRequests || []).map(
      m => new TransactionGoalRequestModel(m)
    );
    this.userBankAccount = _value.userBankAccount
      ? new UserBankAccountModel(_value.userBankAccount)
      : undefined;
    GoalTransactionModel.refineValues(this);
  }
  get type(): goal_transaction_type_enums003.GoalTransactionType {
    return this._type;
  }
  set type(value: goal_transaction_type_enums003.GoalTransactionType) {
    this._type = value;
  }
  get amount(): googleProtobuf000.DoubleValue | undefined {
    return this._amount;
  }
  set amount(value: googleProtobuf000.DoubleValue | undefined) {
    this._amount = value;
  }
  get amountRequested(): number {
    return this._amountRequested;
  }
  set amountRequested(value: number) {
    this._amountRequested = value;
  }
  get amountToProcess(): googleProtobuf000.DoubleValue | undefined {
    return this._amountToProcess;
  }
  set amountToProcess(value: googleProtobuf000.DoubleValue | undefined) {
    this._amountToProcess = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get all(): boolean {
    return this._all;
  }
  set all(value: boolean) {
    this._all = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }
  get paymentMethod(): goal_transaction_payment_enum004.GoalTransactionPaymentMethod {
    return this._paymentMethod;
  }
  set paymentMethod(
    value: goal_transaction_payment_enum004.GoalTransactionPaymentMethod
  ) {
    this._paymentMethod = value;
  }
  get id(): googleProtobuf000.Int32Value | undefined {
    return this._id;
  }
  set id(value: googleProtobuf000.Int32Value | undefined) {
    this._id = value;
  }
  get goalId(): googleProtobuf000.Int32Value | undefined {
    return this._goalId;
  }
  set goalId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalId = value;
  }
  get ownerId(): googleProtobuf000.Int32Value | undefined {
    return this._ownerId;
  }
  set ownerId(value: googleProtobuf000.Int32Value | undefined) {
    this._ownerId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get requestFileId(): googleProtobuf000.Int32Value | undefined {
    return this._requestFileId;
  }
  set requestFileId(value: googleProtobuf000.Int32Value | undefined) {
    this._requestFileId = value;
  }
  get voucherFileId(): googleProtobuf000.Int32Value | undefined {
    return this._voucherFileId;
  }
  set voucherFileId(value: googleProtobuf000.Int32Value | undefined) {
    this._voucherFileId = value;
  }
  get isProcessed(): boolean {
    return this._isProcessed;
  }
  set isProcessed(value: boolean) {
    this._isProcessed = value;
  }
  get goalTransactionFundings(): GoalTransactionFundingModel[] | undefined {
    return this._goalTransactionFundings;
  }
  set goalTransactionFundings(
    value: GoalTransactionFundingModel[] | undefined
  ) {
    this._goalTransactionFundings = value;
  }
  get goal(): GoalModel | undefined {
    return this._goal;
  }
  set goal(value: GoalModel | undefined) {
    this._goal = value;
  }
  get identifier(): string {
    return this._identifier;
  }
  set identifier(value: string) {
    this._identifier = value;
  }
  get financialEntityId(): googleProtobuf000.Int32Value | undefined {
    return this._financialEntityId;
  }
  set financialEntityId(value: googleProtobuf000.Int32Value | undefined) {
    this._financialEntityId = value;
  }
  get goalTo(): GoalModel | undefined {
    return this._goalTo;
  }
  set goalTo(value: GoalModel | undefined) {
    this._goalTo = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get userBankAccountId(): googleProtobuf000.Int32Value | undefined {
    return this._userBankAccountId;
  }
  set userBankAccountId(value: googleProtobuf000.Int32Value | undefined) {
    this._userBankAccountId = value;
  }
  get owner(): UserModel | undefined {
    return this._owner;
  }
  set owner(value: UserModel | undefined) {
    this._owner = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }
  get cost(): number {
    return this._cost;
  }
  set cost(value: number) {
    this._cost = value;
  }
  get transactionRequestId(): googleProtobuf000.Int32Value | undefined {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: googleProtobuf000.Int32Value | undefined) {
    this._transactionRequestId = value;
  }
  get transactionRequest(): TransactionRequestModel | undefined {
    return this._transactionRequest;
  }
  set transactionRequest(value: TransactionRequestModel | undefined) {
    this._transactionRequest = value;
  }
  get goalFrom(): GoalModel | undefined {
    return this._goalFrom;
  }
  set goalFrom(value: GoalModel | undefined) {
    this._goalFrom = value;
  }
  get transactionGoalRequests(): TransactionGoalRequestModel[] | undefined {
    return this._transactionGoalRequests;
  }
  set transactionGoalRequests(
    value: TransactionGoalRequestModel[] | undefined
  ) {
    this._transactionGoalRequests = value;
  }
  get userBankAccount(): UserBankAccountModel | undefined {
    return this._userBankAccount;
  }
  set userBankAccount(value: UserBankAccountModel | undefined) {
    this._userBankAccount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalTransactionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalTransactionModel.AsObject {
    return {
      type: this.type,
      amount: this.amount ? this.amount.toObject() : undefined,
      amountRequested: this.amountRequested,
      amountToProcess: this.amountToProcess
        ? this.amountToProcess.toObject()
        : undefined,
      date: this.date ? this.date.toObject() : undefined,
      all: this.all,
      state: this.state,
      paymentMethod: this.paymentMethod,
      id: this.id ? this.id.toObject() : undefined,
      goalId: this.goalId ? this.goalId.toObject() : undefined,
      ownerId: this.ownerId ? this.ownerId.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      requestFileId: this.requestFileId
        ? this.requestFileId.toObject()
        : undefined,
      voucherFileId: this.voucherFileId
        ? this.voucherFileId.toObject()
        : undefined,
      isProcessed: this.isProcessed,
      goalTransactionFundings: (this.goalTransactionFundings || []).map(m =>
        m.toObject()
      ),
      goal: this.goal ? this.goal.toObject() : undefined,
      identifier: this.identifier,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toObject()
        : undefined,
      goalTo: this.goalTo ? this.goalTo.toObject() : undefined,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toObject() : undefined,
      userBankAccountId: this.userBankAccountId
        ? this.userBankAccountId.toObject()
        : undefined,
      owner: this.owner ? this.owner.toObject() : undefined,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      cost: this.cost,
      transactionRequestId: this.transactionRequestId
        ? this.transactionRequestId.toObject()
        : undefined,
      transactionRequest: this.transactionRequest
        ? this.transactionRequest.toObject()
        : undefined,
      goalFrom: this.goalFrom ? this.goalFrom.toObject() : undefined,
      transactionGoalRequests: (this.transactionGoalRequests || []).map(m =>
        m.toObject()
      ),
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalTransactionModel.AsProtobufJSON {
    return {
      type:
        goal_transaction_type_enums003.GoalTransactionType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      amount: this.amount ? this.amount.toProtobufJSON(options) : null,
      amountRequested: this.amountRequested,
      amountToProcess: this.amountToProcess
        ? this.amountToProcess.toProtobufJSON(options)
        : null,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      all: this.all,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      paymentMethod:
        goal_transaction_payment_enum004.GoalTransactionPaymentMethod[
          this.paymentMethod === null || this.paymentMethod === undefined
            ? 0
            : this.paymentMethod
        ],
      id: this.id ? this.id.toProtobufJSON(options) : null,
      goalId: this.goalId ? this.goalId.toProtobufJSON(options) : null,
      ownerId: this.ownerId ? this.ownerId.toProtobufJSON(options) : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      requestFileId: this.requestFileId
        ? this.requestFileId.toProtobufJSON(options)
        : null,
      voucherFileId: this.voucherFileId
        ? this.voucherFileId.toProtobufJSON(options)
        : null,
      isProcessed: this.isProcessed,
      goalTransactionFundings: (this.goalTransactionFundings || []).map(m =>
        m.toProtobufJSON(options)
      ),
      goal: this.goal ? this.goal.toProtobufJSON(options) : null,
      identifier: this.identifier,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toProtobufJSON(options)
        : null,
      goalTo: this.goalTo ? this.goalTo.toProtobufJSON(options) : null,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      userBankAccountId: this.userBankAccountId
        ? this.userBankAccountId.toProtobufJSON(options)
        : null,
      owner: this.owner ? this.owner.toProtobufJSON(options) : null,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      cost: this.cost,
      transactionRequestId: this.transactionRequestId
        ? this.transactionRequestId.toProtobufJSON(options)
        : null,
      transactionRequest: this.transactionRequest
        ? this.transactionRequest.toProtobufJSON(options)
        : null,
      goalFrom: this.goalFrom ? this.goalFrom.toProtobufJSON(options) : null,
      transactionGoalRequests: (this.transactionGoalRequests || []).map(m =>
        m.toProtobufJSON(options)
      ),
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toProtobufJSON(options)
        : null
    };
  }
}
export module GoalTransactionModel {
  /**
   * Standard JavaScript object representation for GoalTransactionModel
   */
  export interface AsObject {
    type: goal_transaction_type_enums003.GoalTransactionType;
    amount?: googleProtobuf000.DoubleValue.AsObject;
    amountRequested: number;
    amountToProcess?: googleProtobuf000.DoubleValue.AsObject;
    date?: googleProtobuf001.Timestamp.AsObject;
    all: boolean;
    state: goal_transaction_enums002.GoalTransactionState;
    paymentMethod: goal_transaction_payment_enum004.GoalTransactionPaymentMethod;
    id?: googleProtobuf000.Int32Value.AsObject;
    goalId?: googleProtobuf000.Int32Value.AsObject;
    ownerId?: googleProtobuf000.Int32Value.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    requestFileId?: googleProtobuf000.Int32Value.AsObject;
    voucherFileId?: googleProtobuf000.Int32Value.AsObject;
    isProcessed: boolean;
    goalTransactionFundings?: GoalTransactionFundingModel.AsObject[];
    goal?: GoalModel.AsObject;
    identifier: string;
    financialEntityId?: googleProtobuf000.Int32Value.AsObject;
    goalTo?: GoalModel.AsObject;
    currencyId: number;
    currency?: CurrencyModel.AsObject;
    userBankAccountId?: googleProtobuf000.Int32Value.AsObject;
    owner?: UserModel.AsObject;
    financialEntity?: FinancialEntityModel.AsObject;
    cost: number;
    transactionRequestId?: googleProtobuf000.Int32Value.AsObject;
    transactionRequest?: TransactionRequestModel.AsObject;
    goalFrom?: GoalModel.AsObject;
    transactionGoalRequests?: TransactionGoalRequestModel.AsObject[];
    userBankAccount?: UserBankAccountModel.AsObject;
  }

  /**
   * Protobuf JSON representation for GoalTransactionModel
   */
  export interface AsProtobufJSON {
    type: string;
    amount: googleProtobuf000.DoubleValue.AsProtobufJSON | null;
    amountRequested: number;
    amountToProcess: googleProtobuf000.DoubleValue.AsProtobufJSON | null;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    all: boolean;
    state: string;
    paymentMethod: string;
    id: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    goalId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    ownerId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    requestFileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    voucherFileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    isProcessed: boolean;
    goalTransactionFundings:
      | GoalTransactionFundingModel.AsProtobufJSON[]
      | null;
    goal: GoalModel.AsProtobufJSON | null;
    identifier: string;
    financialEntityId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    goalTo: GoalModel.AsProtobufJSON | null;
    currencyId: number;
    currency: CurrencyModel.AsProtobufJSON | null;
    userBankAccountId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    owner: UserModel.AsProtobufJSON | null;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
    cost: number;
    transactionRequestId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    transactionRequest: TransactionRequestModel.AsProtobufJSON | null;
    goalFrom: GoalModel.AsProtobufJSON | null;
    transactionGoalRequests:
      | TransactionGoalRequestModel.AsProtobufJSON[]
      | null;
    userBankAccount: UserBankAccountModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.PortfolioModel
 */
export class PortfolioModel implements GrpcMessage {
  static id = 'common_message.PortfolioModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PortfolioModel();
    PortfolioModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PortfolioModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.maxRangeYear = _instance.maxRangeYear || 0;
    _instance.minRangeYear = _instance.minRangeYear || 0;
    _instance.uuid = _instance.uuid || '';
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.riskLevelId = _instance.riskLevelId || 0;
    _instance.isDefault = _instance.isDefault || false;
    _instance.profitability = _instance.profitability || [];
    _instance.investmentStrategyId = _instance.investmentStrategyId || 0;
    _instance.version = _instance.version || '';
    _instance.extraProfitabilityCurrencyCode =
      _instance.extraProfitabilityCurrencyCode || '';
    _instance.estimatedProfitability = _instance.estimatedProfitability || '';
    _instance.portfolioFunding = _instance.portfolioFunding || [];
    _instance.userId = _instance.userId || undefined;
    _instance.portfolioCurrencyConfig = _instance.portfolioCurrencyConfig || [];
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.investmentStrategy = _instance.investmentStrategy || undefined;
    _instance.riskLevel = _instance.riskLevel || undefined;
    _instance.portfolioComposition = _instance.portfolioComposition || [];
    _instance.bpComission = _instance.bpComission || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PortfolioModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.maxRangeYear = _reader.readDouble();
          break;
        case 5:
          _instance.minRangeYear = _reader.readDouble();
          break;
        case 6:
          _instance.uuid = _reader.readString();
          break;
        case 7:
          _instance.title = _reader.readString();
          break;
        case 8:
          _instance.description = _reader.readString();
          break;
        case 9:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 10:
          _instance.riskLevelId = _reader.readInt32();
          break;
        case 11:
          _instance.isDefault = _reader.readBool();
          break;
        case 12:
          const messageInitializer12 = new PortfolioProfitabilityModel();
          _reader.readMessage(
            messageInitializer12,
            PortfolioProfitabilityModel.deserializeBinaryFromReader
          );
          (_instance.profitability = _instance.profitability || []).push(
            messageInitializer12
          );
          break;
        case 13:
          _instance.investmentStrategyId = _reader.readInt32();
          break;
        case 14:
          _instance.version = _reader.readString();
          break;
        case 15:
          _instance.extraProfitabilityCurrencyCode = _reader.readString();
          break;
        case 16:
          _instance.estimatedProfitability = _reader.readString();
          break;
        case 17:
          const messageInitializer17 = new PortfolioFundingModel();
          _reader.readMessage(
            messageInitializer17,
            PortfolioFundingModel.deserializeBinaryFromReader
          );
          (_instance.portfolioFunding = _instance.portfolioFunding || []).push(
            messageInitializer17
          );
          break;
        case 18:
          _instance.userId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 19:
          const messageInitializer19 = new PortfolioCurrencyConfigModel();
          _reader.readMessage(
            messageInitializer19,
            PortfolioCurrencyConfigModel.deserializeBinaryFromReader
          );
          (_instance.portfolioCurrencyConfig =
            _instance.portfolioCurrencyConfig || []).push(messageInitializer19);
          break;
        case 20:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.investmentStrategy = new InvestmentStrategyModel();
          _reader.readMessage(
            _instance.investmentStrategy,
            InvestmentStrategyModel.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.riskLevel = new RiskLevelModel();
          _reader.readMessage(
            _instance.riskLevel,
            RiskLevelModel.deserializeBinaryFromReader
          );
          break;
        case 23:
          const messageInitializer23 = new PortfolioCompositionModel();
          _reader.readMessage(
            messageInitializer23,
            PortfolioCompositionModel.deserializeBinaryFromReader
          );
          (_instance.portfolioComposition =
            _instance.portfolioComposition || []).push(messageInitializer23);
          break;
        case 24:
          _instance.bpComission = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    PortfolioModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PortfolioModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.maxRangeYear) {
      _writer.writeDouble(4, _instance.maxRangeYear);
    }
    if (_instance.minRangeYear) {
      _writer.writeDouble(5, _instance.minRangeYear);
    }
    if (_instance.uuid) {
      _writer.writeString(6, _instance.uuid);
    }
    if (_instance.title) {
      _writer.writeString(7, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(8, _instance.description);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(9, _instance.financialEntityId);
    }
    if (_instance.riskLevelId) {
      _writer.writeInt32(10, _instance.riskLevelId);
    }
    if (_instance.isDefault) {
      _writer.writeBool(11, _instance.isDefault);
    }
    if (_instance.profitability && _instance.profitability.length) {
      _writer.writeRepeatedMessage(
        12,
        _instance.profitability as any,
        PortfolioProfitabilityModel.serializeBinaryToWriter
      );
    }
    if (_instance.investmentStrategyId) {
      _writer.writeInt32(13, _instance.investmentStrategyId);
    }
    if (_instance.version) {
      _writer.writeString(14, _instance.version);
    }
    if (_instance.extraProfitabilityCurrencyCode) {
      _writer.writeString(15, _instance.extraProfitabilityCurrencyCode);
    }
    if (_instance.estimatedProfitability) {
      _writer.writeString(16, _instance.estimatedProfitability);
    }
    if (_instance.portfolioFunding && _instance.portfolioFunding.length) {
      _writer.writeRepeatedMessage(
        17,
        _instance.portfolioFunding as any,
        PortfolioFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.userId) {
      _writer.writeMessage(
        18,
        _instance.userId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (
      _instance.portfolioCurrencyConfig &&
      _instance.portfolioCurrencyConfig.length
    ) {
      _writer.writeRepeatedMessage(
        19,
        _instance.portfolioCurrencyConfig as any,
        PortfolioCurrencyConfigModel.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        20,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.investmentStrategy) {
      _writer.writeMessage(
        21,
        _instance.investmentStrategy as any,
        InvestmentStrategyModel.serializeBinaryToWriter
      );
    }
    if (_instance.riskLevel) {
      _writer.writeMessage(
        22,
        _instance.riskLevel as any,
        RiskLevelModel.serializeBinaryToWriter
      );
    }
    if (
      _instance.portfolioComposition &&
      _instance.portfolioComposition.length
    ) {
      _writer.writeRepeatedMessage(
        23,
        _instance.portfolioComposition as any,
        PortfolioCompositionModel.serializeBinaryToWriter
      );
    }
    if (_instance.bpComission) {
      _writer.writeDouble(24, _instance.bpComission);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _maxRangeYear: number;
  private _minRangeYear: number;
  private _uuid: string;
  private _title: string;
  private _description: string;
  private _financialEntityId: number;
  private _riskLevelId: number;
  private _isDefault: boolean;
  private _profitability?: PortfolioProfitabilityModel[];
  private _investmentStrategyId: number;
  private _version: string;
  private _extraProfitabilityCurrencyCode: string;
  private _estimatedProfitability: string;
  private _portfolioFunding?: PortfolioFundingModel[];
  private _userId?: googleProtobuf000.Int32Value;
  private _portfolioCurrencyConfig?: PortfolioCurrencyConfigModel[];
  private _financialEntity?: FinancialEntityModel;
  private _investmentStrategy?: InvestmentStrategyModel;
  private _riskLevel?: RiskLevelModel;
  private _portfolioComposition?: PortfolioCompositionModel[];
  private _bpComission: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PortfolioModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<PortfolioModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.maxRangeYear = _value.maxRangeYear;
    this.minRangeYear = _value.minRangeYear;
    this.uuid = _value.uuid;
    this.title = _value.title;
    this.description = _value.description;
    this.financialEntityId = _value.financialEntityId;
    this.riskLevelId = _value.riskLevelId;
    this.isDefault = _value.isDefault;
    this.profitability = (_value.profitability || []).map(
      m => new PortfolioProfitabilityModel(m)
    );
    this.investmentStrategyId = _value.investmentStrategyId;
    this.version = _value.version;
    this.extraProfitabilityCurrencyCode = _value.extraProfitabilityCurrencyCode;
    this.estimatedProfitability = _value.estimatedProfitability;
    this.portfolioFunding = (_value.portfolioFunding || []).map(
      m => new PortfolioFundingModel(m)
    );
    this.userId = _value.userId
      ? new googleProtobuf000.Int32Value(_value.userId)
      : undefined;
    this.portfolioCurrencyConfig = (_value.portfolioCurrencyConfig || []).map(
      m => new PortfolioCurrencyConfigModel(m)
    );
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.investmentStrategy = _value.investmentStrategy
      ? new InvestmentStrategyModel(_value.investmentStrategy)
      : undefined;
    this.riskLevel = _value.riskLevel
      ? new RiskLevelModel(_value.riskLevel)
      : undefined;
    this.portfolioComposition = (_value.portfolioComposition || []).map(
      m => new PortfolioCompositionModel(m)
    );
    this.bpComission = _value.bpComission;
    PortfolioModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get maxRangeYear(): number {
    return this._maxRangeYear;
  }
  set maxRangeYear(value: number) {
    this._maxRangeYear = value;
  }
  get minRangeYear(): number {
    return this._minRangeYear;
  }
  set minRangeYear(value: number) {
    this._minRangeYear = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get riskLevelId(): number {
    return this._riskLevelId;
  }
  set riskLevelId(value: number) {
    this._riskLevelId = value;
  }
  get isDefault(): boolean {
    return this._isDefault;
  }
  set isDefault(value: boolean) {
    this._isDefault = value;
  }
  get profitability(): PortfolioProfitabilityModel[] | undefined {
    return this._profitability;
  }
  set profitability(value: PortfolioProfitabilityModel[] | undefined) {
    this._profitability = value;
  }
  get investmentStrategyId(): number {
    return this._investmentStrategyId;
  }
  set investmentStrategyId(value: number) {
    this._investmentStrategyId = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get extraProfitabilityCurrencyCode(): string {
    return this._extraProfitabilityCurrencyCode;
  }
  set extraProfitabilityCurrencyCode(value: string) {
    this._extraProfitabilityCurrencyCode = value;
  }
  get estimatedProfitability(): string {
    return this._estimatedProfitability;
  }
  set estimatedProfitability(value: string) {
    this._estimatedProfitability = value;
  }
  get portfolioFunding(): PortfolioFundingModel[] | undefined {
    return this._portfolioFunding;
  }
  set portfolioFunding(value: PortfolioFundingModel[] | undefined) {
    this._portfolioFunding = value;
  }
  get userId(): googleProtobuf000.Int32Value | undefined {
    return this._userId;
  }
  set userId(value: googleProtobuf000.Int32Value | undefined) {
    this._userId = value;
  }
  get portfolioCurrencyConfig(): PortfolioCurrencyConfigModel[] | undefined {
    return this._portfolioCurrencyConfig;
  }
  set portfolioCurrencyConfig(
    value: PortfolioCurrencyConfigModel[] | undefined
  ) {
    this._portfolioCurrencyConfig = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }
  get investmentStrategy(): InvestmentStrategyModel | undefined {
    return this._investmentStrategy;
  }
  set investmentStrategy(value: InvestmentStrategyModel | undefined) {
    this._investmentStrategy = value;
  }
  get riskLevel(): RiskLevelModel | undefined {
    return this._riskLevel;
  }
  set riskLevel(value: RiskLevelModel | undefined) {
    this._riskLevel = value;
  }
  get portfolioComposition(): PortfolioCompositionModel[] | undefined {
    return this._portfolioComposition;
  }
  set portfolioComposition(value: PortfolioCompositionModel[] | undefined) {
    this._portfolioComposition = value;
  }
  get bpComission(): number {
    return this._bpComission;
  }
  set bpComission(value: number) {
    this._bpComission = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PortfolioModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PortfolioModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      maxRangeYear: this.maxRangeYear,
      minRangeYear: this.minRangeYear,
      uuid: this.uuid,
      title: this.title,
      description: this.description,
      financialEntityId: this.financialEntityId,
      riskLevelId: this.riskLevelId,
      isDefault: this.isDefault,
      profitability: (this.profitability || []).map(m => m.toObject()),
      investmentStrategyId: this.investmentStrategyId,
      version: this.version,
      extraProfitabilityCurrencyCode: this.extraProfitabilityCurrencyCode,
      estimatedProfitability: this.estimatedProfitability,
      portfolioFunding: (this.portfolioFunding || []).map(m => m.toObject()),
      userId: this.userId ? this.userId.toObject() : undefined,
      portfolioCurrencyConfig: (this.portfolioCurrencyConfig || []).map(m =>
        m.toObject()
      ),
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      investmentStrategy: this.investmentStrategy
        ? this.investmentStrategy.toObject()
        : undefined,
      riskLevel: this.riskLevel ? this.riskLevel.toObject() : undefined,
      portfolioComposition: (this.portfolioComposition || []).map(m =>
        m.toObject()
      ),
      bpComission: this.bpComission
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PortfolioModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      maxRangeYear: this.maxRangeYear,
      minRangeYear: this.minRangeYear,
      uuid: this.uuid,
      title: this.title,
      description: this.description,
      financialEntityId: this.financialEntityId,
      riskLevelId: this.riskLevelId,
      isDefault: this.isDefault,
      profitability: (this.profitability || []).map(m =>
        m.toProtobufJSON(options)
      ),
      investmentStrategyId: this.investmentStrategyId,
      version: this.version,
      extraProfitabilityCurrencyCode: this.extraProfitabilityCurrencyCode,
      estimatedProfitability: this.estimatedProfitability,
      portfolioFunding: (this.portfolioFunding || []).map(m =>
        m.toProtobufJSON(options)
      ),
      userId: this.userId ? this.userId.toProtobufJSON(options) : null,
      portfolioCurrencyConfig: (this.portfolioCurrencyConfig || []).map(m =>
        m.toProtobufJSON(options)
      ),
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      investmentStrategy: this.investmentStrategy
        ? this.investmentStrategy.toProtobufJSON(options)
        : null,
      riskLevel: this.riskLevel ? this.riskLevel.toProtobufJSON(options) : null,
      portfolioComposition: (this.portfolioComposition || []).map(m =>
        m.toProtobufJSON(options)
      ),
      bpComission: this.bpComission
    };
  }
}
export module PortfolioModel {
  /**
   * Standard JavaScript object representation for PortfolioModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    maxRangeYear: number;
    minRangeYear: number;
    uuid: string;
    title: string;
    description: string;
    financialEntityId: number;
    riskLevelId: number;
    isDefault: boolean;
    profitability?: PortfolioProfitabilityModel.AsObject[];
    investmentStrategyId: number;
    version: string;
    extraProfitabilityCurrencyCode: string;
    estimatedProfitability: string;
    portfolioFunding?: PortfolioFundingModel.AsObject[];
    userId?: googleProtobuf000.Int32Value.AsObject;
    portfolioCurrencyConfig?: PortfolioCurrencyConfigModel.AsObject[];
    financialEntity?: FinancialEntityModel.AsObject;
    investmentStrategy?: InvestmentStrategyModel.AsObject;
    riskLevel?: RiskLevelModel.AsObject;
    portfolioComposition?: PortfolioCompositionModel.AsObject[];
    bpComission: number;
  }

  /**
   * Protobuf JSON representation for PortfolioModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    maxRangeYear: number;
    minRangeYear: number;
    uuid: string;
    title: string;
    description: string;
    financialEntityId: number;
    riskLevelId: number;
    isDefault: boolean;
    profitability: PortfolioProfitabilityModel.AsProtobufJSON[] | null;
    investmentStrategyId: number;
    version: string;
    extraProfitabilityCurrencyCode: string;
    estimatedProfitability: string;
    portfolioFunding: PortfolioFundingModel.AsProtobufJSON[] | null;
    userId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    portfolioCurrencyConfig:
      | PortfolioCurrencyConfigModel.AsProtobufJSON[]
      | null;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
    investmentStrategy: InvestmentStrategyModel.AsProtobufJSON | null;
    riskLevel: RiskLevelModel.AsProtobufJSON | null;
    portfolioComposition: PortfolioCompositionModel.AsProtobufJSON[] | null;
    bpComission: number;
  }
}

/**
 * Message implementation for common_message.PortfolioCompositionModel
 */
export class PortfolioCompositionModel implements GrpcMessage {
  static id = 'common_message.PortfolioCompositionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PortfolioCompositionModel();
    PortfolioCompositionModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PortfolioCompositionModel) {
    _instance.color = _instance.color || '';
    _instance.fundingId = _instance.fundingId || 0;
    _instance.id = _instance.id || 0;
    _instance.isPrimarySubcategory = _instance.isPrimarySubcategory || false;
    _instance.percentage = _instance.percentage || 0;
    _instance.subCategoryName = _instance.subCategoryName || '';
    _instance.subcategory = _instance.subcategory || undefined;
    _instance.subCategoryId = _instance.subCategoryId || 0;
    _instance.isBox = _instance.isBox || false;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.portfolio = _instance.portfolio || undefined;
    _instance.portfolioId = _instance.portfolioId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PortfolioCompositionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.color = _reader.readString();
          break;
        case 2:
          _instance.fundingId = _reader.readInt32();
          break;
        case 3:
          _instance.id = _reader.readInt32();
          break;
        case 4:
          _instance.isPrimarySubcategory = _reader.readBool();
          break;
        case 5:
          _instance.percentage = _reader.readDouble();
          break;
        case 6:
          _instance.subCategoryName = _reader.readString();
          break;
        case 7:
          _instance.subcategory = new CompositionSubcategoryModel();
          _reader.readMessage(
            _instance.subcategory,
            CompositionSubcategoryModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.subCategoryId = _reader.readInt32();
          break;
        case 9:
          _instance.isBox = _reader.readBool();
          break;
        case 10:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.portfolio = new PortfolioModel();
          _reader.readMessage(
            _instance.portfolio,
            PortfolioModel.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.portfolioId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    PortfolioCompositionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PortfolioCompositionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.color) {
      _writer.writeString(1, _instance.color);
    }
    if (_instance.fundingId) {
      _writer.writeInt32(2, _instance.fundingId);
    }
    if (_instance.id) {
      _writer.writeInt32(3, _instance.id);
    }
    if (_instance.isPrimarySubcategory) {
      _writer.writeBool(4, _instance.isPrimarySubcategory);
    }
    if (_instance.percentage) {
      _writer.writeDouble(5, _instance.percentage);
    }
    if (_instance.subCategoryName) {
      _writer.writeString(6, _instance.subCategoryName);
    }
    if (_instance.subcategory) {
      _writer.writeMessage(
        7,
        _instance.subcategory as any,
        CompositionSubcategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.subCategoryId) {
      _writer.writeInt32(8, _instance.subCategoryId);
    }
    if (_instance.isBox) {
      _writer.writeBool(9, _instance.isBox);
    }
    if (_instance.created) {
      _writer.writeMessage(
        10,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        11,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.portfolio) {
      _writer.writeMessage(
        12,
        _instance.portfolio as any,
        PortfolioModel.serializeBinaryToWriter
      );
    }
    if (_instance.portfolioId) {
      _writer.writeInt32(13, _instance.portfolioId);
    }
  }

  private _color: string;
  private _fundingId: number;
  private _id: number;
  private _isPrimarySubcategory: boolean;
  private _percentage: number;
  private _subCategoryName: string;
  private _subcategory?: CompositionSubcategoryModel;
  private _subCategoryId: number;
  private _isBox: boolean;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _portfolio?: PortfolioModel;
  private _portfolioId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PortfolioCompositionModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<PortfolioCompositionModel.AsObject>) {
    _value = _value || {};
    this.color = _value.color;
    this.fundingId = _value.fundingId;
    this.id = _value.id;
    this.isPrimarySubcategory = _value.isPrimarySubcategory;
    this.percentage = _value.percentage;
    this.subCategoryName = _value.subCategoryName;
    this.subcategory = _value.subcategory
      ? new CompositionSubcategoryModel(_value.subcategory)
      : undefined;
    this.subCategoryId = _value.subCategoryId;
    this.isBox = _value.isBox;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.portfolio = _value.portfolio
      ? new PortfolioModel(_value.portfolio)
      : undefined;
    this.portfolioId = _value.portfolioId;
    PortfolioCompositionModel.refineValues(this);
  }
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._color = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get isPrimarySubcategory(): boolean {
    return this._isPrimarySubcategory;
  }
  set isPrimarySubcategory(value: boolean) {
    this._isPrimarySubcategory = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get subCategoryName(): string {
    return this._subCategoryName;
  }
  set subCategoryName(value: string) {
    this._subCategoryName = value;
  }
  get subcategory(): CompositionSubcategoryModel | undefined {
    return this._subcategory;
  }
  set subcategory(value: CompositionSubcategoryModel | undefined) {
    this._subcategory = value;
  }
  get subCategoryId(): number {
    return this._subCategoryId;
  }
  set subCategoryId(value: number) {
    this._subCategoryId = value;
  }
  get isBox(): boolean {
    return this._isBox;
  }
  set isBox(value: boolean) {
    this._isBox = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get portfolio(): PortfolioModel | undefined {
    return this._portfolio;
  }
  set portfolio(value: PortfolioModel | undefined) {
    this._portfolio = value;
  }
  get portfolioId(): number {
    return this._portfolioId;
  }
  set portfolioId(value: number) {
    this._portfolioId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PortfolioCompositionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PortfolioCompositionModel.AsObject {
    return {
      color: this.color,
      fundingId: this.fundingId,
      id: this.id,
      isPrimarySubcategory: this.isPrimarySubcategory,
      percentage: this.percentage,
      subCategoryName: this.subCategoryName,
      subcategory: this.subcategory ? this.subcategory.toObject() : undefined,
      subCategoryId: this.subCategoryId,
      isBox: this.isBox,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      portfolio: this.portfolio ? this.portfolio.toObject() : undefined,
      portfolioId: this.portfolioId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PortfolioCompositionModel.AsProtobufJSON {
    return {
      color: this.color,
      fundingId: this.fundingId,
      id: this.id,
      isPrimarySubcategory: this.isPrimarySubcategory,
      percentage: this.percentage,
      subCategoryName: this.subCategoryName,
      subcategory: this.subcategory
        ? this.subcategory.toProtobufJSON(options)
        : null,
      subCategoryId: this.subCategoryId,
      isBox: this.isBox,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      portfolio: this.portfolio ? this.portfolio.toProtobufJSON(options) : null,
      portfolioId: this.portfolioId
    };
  }
}
export module PortfolioCompositionModel {
  /**
   * Standard JavaScript object representation for PortfolioCompositionModel
   */
  export interface AsObject {
    color: string;
    fundingId: number;
    id: number;
    isPrimarySubcategory: boolean;
    percentage: number;
    subCategoryName: string;
    subcategory?: CompositionSubcategoryModel.AsObject;
    subCategoryId: number;
    isBox: boolean;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    portfolio?: PortfolioModel.AsObject;
    portfolioId: number;
  }

  /**
   * Protobuf JSON representation for PortfolioCompositionModel
   */
  export interface AsProtobufJSON {
    color: string;
    fundingId: number;
    id: number;
    isPrimarySubcategory: boolean;
    percentage: number;
    subCategoryName: string;
    subcategory: CompositionSubcategoryModel.AsProtobufJSON | null;
    subCategoryId: number;
    isBox: boolean;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    portfolio: PortfolioModel.AsProtobufJSON | null;
    portfolioId: number;
  }
}

/**
 * Message implementation for common_message.PortfolioFundingModel
 */
export class PortfolioFundingModel implements GrpcMessage {
  static id = 'common_message.PortfolioFundingModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PortfolioFundingModel();
    PortfolioFundingModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PortfolioFundingModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.percentage = _instance.percentage || 0;
    _instance.fundingId = _instance.fundingId || 0;
    _instance.portfolioId = _instance.portfolioId || 0;
    _instance.funding = _instance.funding || undefined;
    _instance.portfolio = _instance.portfolio || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PortfolioFundingModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.percentage = _reader.readDouble();
          break;
        case 5:
          _instance.fundingId = _reader.readInt32();
          break;
        case 6:
          _instance.portfolioId = _reader.readInt32();
          break;
        case 7:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.portfolio = new PortfolioModel();
          _reader.readMessage(
            _instance.portfolio,
            PortfolioModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PortfolioFundingModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PortfolioFundingModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.percentage) {
      _writer.writeDouble(4, _instance.percentage);
    }
    if (_instance.fundingId) {
      _writer.writeInt32(5, _instance.fundingId);
    }
    if (_instance.portfolioId) {
      _writer.writeInt32(6, _instance.portfolioId);
    }
    if (_instance.funding) {
      _writer.writeMessage(
        7,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.portfolio) {
      _writer.writeMessage(
        8,
        _instance.portfolio as any,
        PortfolioModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _percentage: number;
  private _fundingId: number;
  private _portfolioId: number;
  private _funding?: FundingModel;
  private _portfolio?: PortfolioModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PortfolioFundingModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<PortfolioFundingModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.percentage = _value.percentage;
    this.fundingId = _value.fundingId;
    this.portfolioId = _value.portfolioId;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.portfolio = _value.portfolio
      ? new PortfolioModel(_value.portfolio)
      : undefined;
    PortfolioFundingModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get portfolioId(): number {
    return this._portfolioId;
  }
  set portfolioId(value: number) {
    this._portfolioId = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get portfolio(): PortfolioModel | undefined {
    return this._portfolio;
  }
  set portfolio(value: PortfolioModel | undefined) {
    this._portfolio = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PortfolioFundingModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PortfolioFundingModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      percentage: this.percentage,
      fundingId: this.fundingId,
      portfolioId: this.portfolioId,
      funding: this.funding ? this.funding.toObject() : undefined,
      portfolio: this.portfolio ? this.portfolio.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PortfolioFundingModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      percentage: this.percentage,
      fundingId: this.fundingId,
      portfolioId: this.portfolioId,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      portfolio: this.portfolio ? this.portfolio.toProtobufJSON(options) : null
    };
  }
}
export module PortfolioFundingModel {
  /**
   * Standard JavaScript object representation for PortfolioFundingModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    percentage: number;
    fundingId: number;
    portfolioId: number;
    funding?: FundingModel.AsObject;
    portfolio?: PortfolioModel.AsObject;
  }

  /**
   * Protobuf JSON representation for PortfolioFundingModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    percentage: number;
    fundingId: number;
    portfolioId: number;
    funding: FundingModel.AsProtobufJSON | null;
    portfolio: PortfolioModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.PortfolioCurrencyConfigModel
 */
export class PortfolioCurrencyConfigModel implements GrpcMessage {
  static id = 'common_message.PortfolioCurrencyConfigModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PortfolioCurrencyConfigModel();
    PortfolioCurrencyConfigModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PortfolioCurrencyConfigModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.minAmount = _instance.minAmount || 0;
    _instance.maxAmount = _instance.maxAmount || 0;
    _instance.portfolioId = _instance.portfolioId || 0;
    _instance.currencyId = _instance.currencyId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PortfolioCurrencyConfigModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.minAmount = _reader.readDouble();
          break;
        case 5:
          _instance.maxAmount = _reader.readDouble();
          break;
        case 6:
          _instance.portfolioId = _reader.readInt32();
          break;
        case 7:
          _instance.currencyId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    PortfolioCurrencyConfigModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PortfolioCurrencyConfigModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.minAmount) {
      _writer.writeDouble(4, _instance.minAmount);
    }
    if (_instance.maxAmount) {
      _writer.writeDouble(5, _instance.maxAmount);
    }
    if (_instance.portfolioId) {
      _writer.writeInt32(6, _instance.portfolioId);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(7, _instance.currencyId);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _minAmount: number;
  private _maxAmount: number;
  private _portfolioId: number;
  private _currencyId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PortfolioCurrencyConfigModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<PortfolioCurrencyConfigModel.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.minAmount = _value.minAmount;
    this.maxAmount = _value.maxAmount;
    this.portfolioId = _value.portfolioId;
    this.currencyId = _value.currencyId;
    PortfolioCurrencyConfigModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get minAmount(): number {
    return this._minAmount;
  }
  set minAmount(value: number) {
    this._minAmount = value;
  }
  get maxAmount(): number {
    return this._maxAmount;
  }
  set maxAmount(value: number) {
    this._maxAmount = value;
  }
  get portfolioId(): number {
    return this._portfolioId;
  }
  set portfolioId(value: number) {
    this._portfolioId = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PortfolioCurrencyConfigModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PortfolioCurrencyConfigModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      minAmount: this.minAmount,
      maxAmount: this.maxAmount,
      portfolioId: this.portfolioId,
      currencyId: this.currencyId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PortfolioCurrencyConfigModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      minAmount: this.minAmount,
      maxAmount: this.maxAmount,
      portfolioId: this.portfolioId,
      currencyId: this.currencyId
    };
  }
}
export module PortfolioCurrencyConfigModel {
  /**
   * Standard JavaScript object representation for PortfolioCurrencyConfigModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    minAmount: number;
    maxAmount: number;
    portfolioId: number;
    currencyId: number;
  }

  /**
   * Protobuf JSON representation for PortfolioCurrencyConfigModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    minAmount: number;
    maxAmount: number;
    portfolioId: number;
    currencyId: number;
  }
}

/**
 * Message implementation for common_message.PortfolioProfitabilityModel
 */
export class PortfolioProfitabilityModel implements GrpcMessage {
  static id = 'common_message.PortfolioProfitabilityModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PortfolioProfitabilityModel();
    PortfolioProfitabilityModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PortfolioProfitabilityModel) {
    _instance.minimum = _instance.minimum || 0;
    _instance.expected = _instance.expected || 0;
    _instance.maximum = _instance.maximum || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PortfolioProfitabilityModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.minimum = _reader.readDouble();
          break;
        case 2:
          _instance.expected = _reader.readDouble();
          break;
        case 3:
          _instance.maximum = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    PortfolioProfitabilityModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PortfolioProfitabilityModel,
    _writer: BinaryWriter
  ) {
    if (_instance.minimum) {
      _writer.writeDouble(1, _instance.minimum);
    }
    if (_instance.expected) {
      _writer.writeDouble(2, _instance.expected);
    }
    if (_instance.maximum) {
      _writer.writeDouble(3, _instance.maximum);
    }
  }

  private _minimum: number;
  private _expected: number;
  private _maximum: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PortfolioProfitabilityModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<PortfolioProfitabilityModel.AsObject>) {
    _value = _value || {};
    this.minimum = _value.minimum;
    this.expected = _value.expected;
    this.maximum = _value.maximum;
    PortfolioProfitabilityModel.refineValues(this);
  }
  get minimum(): number {
    return this._minimum;
  }
  set minimum(value: number) {
    this._minimum = value;
  }
  get expected(): number {
    return this._expected;
  }
  set expected(value: number) {
    this._expected = value;
  }
  get maximum(): number {
    return this._maximum;
  }
  set maximum(value: number) {
    this._maximum = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PortfolioProfitabilityModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PortfolioProfitabilityModel.AsObject {
    return {
      minimum: this.minimum,
      expected: this.expected,
      maximum: this.maximum
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PortfolioProfitabilityModel.AsProtobufJSON {
    return {
      minimum: this.minimum,
      expected: this.expected,
      maximum: this.maximum
    };
  }
}
export module PortfolioProfitabilityModel {
  /**
   * Standard JavaScript object representation for PortfolioProfitabilityModel
   */
  export interface AsObject {
    minimum: number;
    expected: number;
    maximum: number;
  }

  /**
   * Protobuf JSON representation for PortfolioProfitabilityModel
   */
  export interface AsProtobufJSON {
    minimum: number;
    expected: number;
    maximum: number;
  }
}

/**
 * Message implementation for common_message.GoalModel
 */
export class GoalModel implements GrpcMessage {
  static id = 'common_message.GoalModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalModel();
    GoalModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalModel) {
    _instance.title = _instance.title || '';
    _instance.years = _instance.years || 0;
    _instance.initialInvestment = _instance.initialInvestment || 0;
    _instance.monthlyContribution = _instance.monthlyContribution || 0;
    _instance.currentCapital = _instance.currentCapital || 0;
    _instance.amountsTransactionsPending =
      _instance.amountsTransactionsPending || 0;
    _instance.currentContribution = _instance.currentContribution || 0;
    _instance.dateOfCompletion = _instance.dateOfCompletion || '';
    _instance.progress = _instance.progress || 0;
    _instance.state = _instance.state || '';
    _instance.icon = _instance.icon || undefined;
    _instance.targetAmount = _instance.targetAmount || 0;
    _instance.signedContract = _instance.signedContract || false;
    _instance.haveDeposited = _instance.haveDeposited || false;
    _instance.haveRequestDeposited = _instance.haveRequestDeposited || false;
    _instance.waitingContractApproval =
      _instance.waitingContractApproval || false;
    _instance.starred = _instance.starred || false;
    _instance.id = _instance.id || 0;
    _instance.userId = _instance.userId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.goalCategoryId = _instance.goalCategoryId || 0;
    _instance.riskLevelId = _instance.riskLevelId || undefined;
    _instance.financialEntityId = _instance.financialEntityId || undefined;
    _instance.portfolioId = _instance.portfolioId || undefined;
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.goalNotifications = _instance.goalNotifications || '';
    _instance.portfolio = _instance.portfolio || undefined;
    _instance.currency = _instance.currency || undefined;
    _instance.goalCategory = _instance.goalCategory || undefined;
    _instance.archived = _instance.archived || false;
    _instance.currencyId = _instance.currencyId || undefined;
    _instance.lastDepositDate = _instance.lastDepositDate || undefined;
    _instance.haveTransactionsProcessed =
      _instance.haveTransactionsProcessed || false;
    _instance.displayCurrencyId = _instance.displayCurrencyId || undefined;
    _instance.riskLevel = _instance.riskLevel || undefined;
    _instance.displayCurrency = _instance.displayCurrency || undefined;
    _instance.investmentStrategy = _instance.investmentStrategy || undefined;
    _instance.enabledEdit = _instance.enabledEdit || false;
    _instance.firstTransactionDate =
      _instance.firstTransactionDate || undefined;
    _instance.imageLarge = _instance.imageLarge || '';
    _instance.imageSmall = _instance.imageSmall || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 14:
          _instance.title = _reader.readString();
          break;
        case 1:
          _instance.years = _reader.readInt32();
          break;
        case 2:
          _instance.initialInvestment = _reader.readInt32();
          break;
        case 3:
          _instance.monthlyContribution = _reader.readInt32();
          break;
        case 4:
          _instance.currentCapital = _reader.readDouble();
          break;
        case 5:
          _instance.amountsTransactionsPending = _reader.readDouble();
          break;
        case 6:
          _instance.currentContribution = _reader.readDouble();
          break;
        case 7:
          _instance.dateOfCompletion = _reader.readString();
          break;
        case 8:
          _instance.progress = _reader.readDouble();
          break;
        case 9:
          _instance.state = _reader.readString();
          break;
        case 10:
          _instance.icon = new GoalModel.Icon();
          _reader.readMessage(
            _instance.icon,
            GoalModel.Icon.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.targetAmount = _reader.readInt32();
          break;
        case 12:
          _instance.signedContract = _reader.readBool();
          break;
        case 13:
          _instance.haveDeposited = _reader.readBool();
          break;
        case 15:
          _instance.haveRequestDeposited = _reader.readBool();
          break;
        case 16:
          _instance.waitingContractApproval = _reader.readBool();
          break;
        case 17:
          _instance.starred = _reader.readBool();
          break;
        case 18:
          _instance.id = _reader.readInt32();
          break;
        case 19:
          _instance.userId = _reader.readInt32();
          break;
        case 20:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.goalCategoryId = _reader.readInt32();
          break;
        case 23:
          _instance.riskLevelId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.riskLevelId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 24:
          _instance.financialEntityId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.financialEntityId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 25:
          _instance.portfolioId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.portfolioId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 28:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 29:
          _instance.goalNotifications = _reader.readString();
          break;
        case 30:
          _instance.portfolio = new PortfolioModel();
          _reader.readMessage(
            _instance.portfolio,
            PortfolioModel.deserializeBinaryFromReader
          );
          break;
        case 31:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 32:
          _instance.goalCategory = new GoalCategoryModel();
          _reader.readMessage(
            _instance.goalCategory,
            GoalCategoryModel.deserializeBinaryFromReader
          );
          break;
        case 33:
          _instance.archived = _reader.readBool();
          break;
        case 34:
          _instance.currencyId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.currencyId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 35:
          _instance.lastDepositDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastDepositDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 36:
          _instance.haveTransactionsProcessed = _reader.readBool();
          break;
        case 37:
          _instance.displayCurrencyId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.displayCurrencyId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 38:
          _instance.riskLevel = new RiskLevelModel();
          _reader.readMessage(
            _instance.riskLevel,
            RiskLevelModel.deserializeBinaryFromReader
          );
          break;
        case 39:
          _instance.displayCurrency = new CurrencyModel();
          _reader.readMessage(
            _instance.displayCurrency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 40:
          _instance.investmentStrategy = new InvestmentStrategyModel();
          _reader.readMessage(
            _instance.investmentStrategy,
            InvestmentStrategyModel.deserializeBinaryFromReader
          );
          break;
        case 41:
          _instance.enabledEdit = _reader.readBool();
          break;
        case 42:
          _instance.firstTransactionDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.firstTransactionDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 43:
          _instance.imageLarge = _reader.readString();
          break;
        case 44:
          _instance.imageSmall = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GoalModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GoalModel, _writer: BinaryWriter) {
    if (_instance.title) {
      _writer.writeString(14, _instance.title);
    }
    if (_instance.years) {
      _writer.writeInt32(1, _instance.years);
    }
    if (_instance.initialInvestment) {
      _writer.writeInt32(2, _instance.initialInvestment);
    }
    if (_instance.monthlyContribution) {
      _writer.writeInt32(3, _instance.monthlyContribution);
    }
    if (_instance.currentCapital) {
      _writer.writeDouble(4, _instance.currentCapital);
    }
    if (_instance.amountsTransactionsPending) {
      _writer.writeDouble(5, _instance.amountsTransactionsPending);
    }
    if (_instance.currentContribution) {
      _writer.writeDouble(6, _instance.currentContribution);
    }
    if (_instance.dateOfCompletion) {
      _writer.writeString(7, _instance.dateOfCompletion);
    }
    if (_instance.progress) {
      _writer.writeDouble(8, _instance.progress);
    }
    if (_instance.state) {
      _writer.writeString(9, _instance.state);
    }
    if (_instance.icon) {
      _writer.writeMessage(
        10,
        _instance.icon as any,
        GoalModel.Icon.serializeBinaryToWriter
      );
    }
    if (_instance.targetAmount) {
      _writer.writeInt32(11, _instance.targetAmount);
    }
    if (_instance.signedContract) {
      _writer.writeBool(12, _instance.signedContract);
    }
    if (_instance.haveDeposited) {
      _writer.writeBool(13, _instance.haveDeposited);
    }
    if (_instance.haveRequestDeposited) {
      _writer.writeBool(15, _instance.haveRequestDeposited);
    }
    if (_instance.waitingContractApproval) {
      _writer.writeBool(16, _instance.waitingContractApproval);
    }
    if (_instance.starred) {
      _writer.writeBool(17, _instance.starred);
    }
    if (_instance.id) {
      _writer.writeInt32(18, _instance.id);
    }
    if (_instance.userId) {
      _writer.writeInt32(19, _instance.userId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        20,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        21,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.goalCategoryId) {
      _writer.writeInt32(22, _instance.goalCategoryId);
    }
    if (_instance.riskLevelId) {
      _writer.writeMessage(
        23,
        _instance.riskLevelId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeMessage(
        24,
        _instance.financialEntityId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.portfolioId) {
      _writer.writeMessage(
        25,
        _instance.portfolioId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        28,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.goalNotifications) {
      _writer.writeString(29, _instance.goalNotifications);
    }
    if (_instance.portfolio) {
      _writer.writeMessage(
        30,
        _instance.portfolio as any,
        PortfolioModel.serializeBinaryToWriter
      );
    }
    if (_instance.currency) {
      _writer.writeMessage(
        31,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.goalCategory) {
      _writer.writeMessage(
        32,
        _instance.goalCategory as any,
        GoalCategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(33, _instance.archived);
    }
    if (_instance.currencyId) {
      _writer.writeMessage(
        34,
        _instance.currencyId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.lastDepositDate) {
      _writer.writeMessage(
        35,
        _instance.lastDepositDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.haveTransactionsProcessed) {
      _writer.writeBool(36, _instance.haveTransactionsProcessed);
    }
    if (_instance.displayCurrencyId) {
      _writer.writeMessage(
        37,
        _instance.displayCurrencyId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.riskLevel) {
      _writer.writeMessage(
        38,
        _instance.riskLevel as any,
        RiskLevelModel.serializeBinaryToWriter
      );
    }
    if (_instance.displayCurrency) {
      _writer.writeMessage(
        39,
        _instance.displayCurrency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.investmentStrategy) {
      _writer.writeMessage(
        40,
        _instance.investmentStrategy as any,
        InvestmentStrategyModel.serializeBinaryToWriter
      );
    }
    if (_instance.enabledEdit) {
      _writer.writeBool(41, _instance.enabledEdit);
    }
    if (_instance.firstTransactionDate) {
      _writer.writeMessage(
        42,
        _instance.firstTransactionDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.imageLarge) {
      _writer.writeString(43, _instance.imageLarge);
    }
    if (_instance.imageSmall) {
      _writer.writeString(44, _instance.imageSmall);
    }
  }

  private _title: string;
  private _years: number;
  private _initialInvestment: number;
  private _monthlyContribution: number;
  private _currentCapital: number;
  private _amountsTransactionsPending: number;
  private _currentContribution: number;
  private _dateOfCompletion: string;
  private _progress: number;
  private _state: string;
  private _icon?: GoalModel.Icon;
  private _targetAmount: number;
  private _signedContract: boolean;
  private _haveDeposited: boolean;
  private _haveRequestDeposited: boolean;
  private _waitingContractApproval: boolean;
  private _starred: boolean;
  private _id: number;
  private _userId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _goalCategoryId: number;
  private _riskLevelId?: googleProtobuf000.Int32Value;
  private _financialEntityId?: googleProtobuf000.Int32Value;
  private _portfolioId?: googleProtobuf000.Int32Value;
  private _financialEntity?: FinancialEntityModel;
  private _goalNotifications: string;
  private _portfolio?: PortfolioModel;
  private _currency?: CurrencyModel;
  private _goalCategory?: GoalCategoryModel;
  private _archived: boolean;
  private _currencyId?: googleProtobuf000.Int32Value;
  private _lastDepositDate?: googleProtobuf001.Timestamp;
  private _haveTransactionsProcessed: boolean;
  private _displayCurrencyId?: googleProtobuf000.Int32Value;
  private _riskLevel?: RiskLevelModel;
  private _displayCurrency?: CurrencyModel;
  private _investmentStrategy?: InvestmentStrategyModel;
  private _enabledEdit: boolean;
  private _firstTransactionDate?: googleProtobuf001.Timestamp;
  private _imageLarge: string;
  private _imageSmall: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalModel.AsObject>) {
    _value = _value || {};
    this.title = _value.title;
    this.years = _value.years;
    this.initialInvestment = _value.initialInvestment;
    this.monthlyContribution = _value.monthlyContribution;
    this.currentCapital = _value.currentCapital;
    this.amountsTransactionsPending = _value.amountsTransactionsPending;
    this.currentContribution = _value.currentContribution;
    this.dateOfCompletion = _value.dateOfCompletion;
    this.progress = _value.progress;
    this.state = _value.state;
    this.icon = _value.icon ? new GoalModel.Icon(_value.icon) : undefined;
    this.targetAmount = _value.targetAmount;
    this.signedContract = _value.signedContract;
    this.haveDeposited = _value.haveDeposited;
    this.haveRequestDeposited = _value.haveRequestDeposited;
    this.waitingContractApproval = _value.waitingContractApproval;
    this.starred = _value.starred;
    this.id = _value.id;
    this.userId = _value.userId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.goalCategoryId = _value.goalCategoryId;
    this.riskLevelId = _value.riskLevelId
      ? new googleProtobuf000.Int32Value(_value.riskLevelId)
      : undefined;
    this.financialEntityId = _value.financialEntityId
      ? new googleProtobuf000.Int32Value(_value.financialEntityId)
      : undefined;
    this.portfolioId = _value.portfolioId
      ? new googleProtobuf000.Int32Value(_value.portfolioId)
      : undefined;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.goalNotifications = _value.goalNotifications;
    this.portfolio = _value.portfolio
      ? new PortfolioModel(_value.portfolio)
      : undefined;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.goalCategory = _value.goalCategory
      ? new GoalCategoryModel(_value.goalCategory)
      : undefined;
    this.archived = _value.archived;
    this.currencyId = _value.currencyId
      ? new googleProtobuf000.Int32Value(_value.currencyId)
      : undefined;
    this.lastDepositDate = _value.lastDepositDate
      ? new googleProtobuf001.Timestamp(_value.lastDepositDate)
      : undefined;
    this.haveTransactionsProcessed = _value.haveTransactionsProcessed;
    this.displayCurrencyId = _value.displayCurrencyId
      ? new googleProtobuf000.Int32Value(_value.displayCurrencyId)
      : undefined;
    this.riskLevel = _value.riskLevel
      ? new RiskLevelModel(_value.riskLevel)
      : undefined;
    this.displayCurrency = _value.displayCurrency
      ? new CurrencyModel(_value.displayCurrency)
      : undefined;
    this.investmentStrategy = _value.investmentStrategy
      ? new InvestmentStrategyModel(_value.investmentStrategy)
      : undefined;
    this.enabledEdit = _value.enabledEdit;
    this.firstTransactionDate = _value.firstTransactionDate
      ? new googleProtobuf001.Timestamp(_value.firstTransactionDate)
      : undefined;
    this.imageLarge = _value.imageLarge;
    this.imageSmall = _value.imageSmall;
    GoalModel.refineValues(this);
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get years(): number {
    return this._years;
  }
  set years(value: number) {
    this._years = value;
  }
  get initialInvestment(): number {
    return this._initialInvestment;
  }
  set initialInvestment(value: number) {
    this._initialInvestment = value;
  }
  get monthlyContribution(): number {
    return this._monthlyContribution;
  }
  set monthlyContribution(value: number) {
    this._monthlyContribution = value;
  }
  get currentCapital(): number {
    return this._currentCapital;
  }
  set currentCapital(value: number) {
    this._currentCapital = value;
  }
  get amountsTransactionsPending(): number {
    return this._amountsTransactionsPending;
  }
  set amountsTransactionsPending(value: number) {
    this._amountsTransactionsPending = value;
  }
  get currentContribution(): number {
    return this._currentContribution;
  }
  set currentContribution(value: number) {
    this._currentContribution = value;
  }
  get dateOfCompletion(): string {
    return this._dateOfCompletion;
  }
  set dateOfCompletion(value: string) {
    this._dateOfCompletion = value;
  }
  get progress(): number {
    return this._progress;
  }
  set progress(value: number) {
    this._progress = value;
  }
  get state(): string {
    return this._state;
  }
  set state(value: string) {
    this._state = value;
  }
  get icon(): GoalModel.Icon | undefined {
    return this._icon;
  }
  set icon(value: GoalModel.Icon | undefined) {
    this._icon = value;
  }
  get targetAmount(): number {
    return this._targetAmount;
  }
  set targetAmount(value: number) {
    this._targetAmount = value;
  }
  get signedContract(): boolean {
    return this._signedContract;
  }
  set signedContract(value: boolean) {
    this._signedContract = value;
  }
  get haveDeposited(): boolean {
    return this._haveDeposited;
  }
  set haveDeposited(value: boolean) {
    this._haveDeposited = value;
  }
  get haveRequestDeposited(): boolean {
    return this._haveRequestDeposited;
  }
  set haveRequestDeposited(value: boolean) {
    this._haveRequestDeposited = value;
  }
  get waitingContractApproval(): boolean {
    return this._waitingContractApproval;
  }
  set waitingContractApproval(value: boolean) {
    this._waitingContractApproval = value;
  }
  get starred(): boolean {
    return this._starred;
  }
  set starred(value: boolean) {
    this._starred = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get goalCategoryId(): number {
    return this._goalCategoryId;
  }
  set goalCategoryId(value: number) {
    this._goalCategoryId = value;
  }
  get riskLevelId(): googleProtobuf000.Int32Value | undefined {
    return this._riskLevelId;
  }
  set riskLevelId(value: googleProtobuf000.Int32Value | undefined) {
    this._riskLevelId = value;
  }
  get financialEntityId(): googleProtobuf000.Int32Value | undefined {
    return this._financialEntityId;
  }
  set financialEntityId(value: googleProtobuf000.Int32Value | undefined) {
    this._financialEntityId = value;
  }
  get portfolioId(): googleProtobuf000.Int32Value | undefined {
    return this._portfolioId;
  }
  set portfolioId(value: googleProtobuf000.Int32Value | undefined) {
    this._portfolioId = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }
  get goalNotifications(): string {
    return this._goalNotifications;
  }
  set goalNotifications(value: string) {
    this._goalNotifications = value;
  }
  get portfolio(): PortfolioModel | undefined {
    return this._portfolio;
  }
  set portfolio(value: PortfolioModel | undefined) {
    this._portfolio = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get goalCategory(): GoalCategoryModel | undefined {
    return this._goalCategory;
  }
  set goalCategory(value: GoalCategoryModel | undefined) {
    this._goalCategory = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get currencyId(): googleProtobuf000.Int32Value | undefined {
    return this._currencyId;
  }
  set currencyId(value: googleProtobuf000.Int32Value | undefined) {
    this._currencyId = value;
  }
  get lastDepositDate(): googleProtobuf001.Timestamp | undefined {
    return this._lastDepositDate;
  }
  set lastDepositDate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastDepositDate = value;
  }
  get haveTransactionsProcessed(): boolean {
    return this._haveTransactionsProcessed;
  }
  set haveTransactionsProcessed(value: boolean) {
    this._haveTransactionsProcessed = value;
  }
  get displayCurrencyId(): googleProtobuf000.Int32Value | undefined {
    return this._displayCurrencyId;
  }
  set displayCurrencyId(value: googleProtobuf000.Int32Value | undefined) {
    this._displayCurrencyId = value;
  }
  get riskLevel(): RiskLevelModel | undefined {
    return this._riskLevel;
  }
  set riskLevel(value: RiskLevelModel | undefined) {
    this._riskLevel = value;
  }
  get displayCurrency(): CurrencyModel | undefined {
    return this._displayCurrency;
  }
  set displayCurrency(value: CurrencyModel | undefined) {
    this._displayCurrency = value;
  }
  get investmentStrategy(): InvestmentStrategyModel | undefined {
    return this._investmentStrategy;
  }
  set investmentStrategy(value: InvestmentStrategyModel | undefined) {
    this._investmentStrategy = value;
  }
  get enabledEdit(): boolean {
    return this._enabledEdit;
  }
  set enabledEdit(value: boolean) {
    this._enabledEdit = value;
  }
  get firstTransactionDate(): googleProtobuf001.Timestamp | undefined {
    return this._firstTransactionDate;
  }
  set firstTransactionDate(value: googleProtobuf001.Timestamp | undefined) {
    this._firstTransactionDate = value;
  }
  get imageLarge(): string {
    return this._imageLarge;
  }
  set imageLarge(value: string) {
    this._imageLarge = value;
  }
  get imageSmall(): string {
    return this._imageSmall;
  }
  set imageSmall(value: string) {
    this._imageSmall = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalModel.AsObject {
    return {
      title: this.title,
      years: this.years,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution,
      currentCapital: this.currentCapital,
      amountsTransactionsPending: this.amountsTransactionsPending,
      currentContribution: this.currentContribution,
      dateOfCompletion: this.dateOfCompletion,
      progress: this.progress,
      state: this.state,
      icon: this.icon ? this.icon.toObject() : undefined,
      targetAmount: this.targetAmount,
      signedContract: this.signedContract,
      haveDeposited: this.haveDeposited,
      haveRequestDeposited: this.haveRequestDeposited,
      waitingContractApproval: this.waitingContractApproval,
      starred: this.starred,
      id: this.id,
      userId: this.userId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId ? this.riskLevelId.toObject() : undefined,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toObject()
        : undefined,
      portfolioId: this.portfolioId ? this.portfolioId.toObject() : undefined,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      goalNotifications: this.goalNotifications,
      portfolio: this.portfolio ? this.portfolio.toObject() : undefined,
      currency: this.currency ? this.currency.toObject() : undefined,
      goalCategory: this.goalCategory
        ? this.goalCategory.toObject()
        : undefined,
      archived: this.archived,
      currencyId: this.currencyId ? this.currencyId.toObject() : undefined,
      lastDepositDate: this.lastDepositDate
        ? this.lastDepositDate.toObject()
        : undefined,
      haveTransactionsProcessed: this.haveTransactionsProcessed,
      displayCurrencyId: this.displayCurrencyId
        ? this.displayCurrencyId.toObject()
        : undefined,
      riskLevel: this.riskLevel ? this.riskLevel.toObject() : undefined,
      displayCurrency: this.displayCurrency
        ? this.displayCurrency.toObject()
        : undefined,
      investmentStrategy: this.investmentStrategy
        ? this.investmentStrategy.toObject()
        : undefined,
      enabledEdit: this.enabledEdit,
      firstTransactionDate: this.firstTransactionDate
        ? this.firstTransactionDate.toObject()
        : undefined,
      imageLarge: this.imageLarge,
      imageSmall: this.imageSmall
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalModel.AsProtobufJSON {
    return {
      title: this.title,
      years: this.years,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution,
      currentCapital: this.currentCapital,
      amountsTransactionsPending: this.amountsTransactionsPending,
      currentContribution: this.currentContribution,
      dateOfCompletion: this.dateOfCompletion,
      progress: this.progress,
      state: this.state,
      icon: this.icon ? this.icon.toProtobufJSON(options) : null,
      targetAmount: this.targetAmount,
      signedContract: this.signedContract,
      haveDeposited: this.haveDeposited,
      haveRequestDeposited: this.haveRequestDeposited,
      waitingContractApproval: this.waitingContractApproval,
      starred: this.starred,
      id: this.id,
      userId: this.userId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId
        ? this.riskLevelId.toProtobufJSON(options)
        : null,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toProtobufJSON(options)
        : null,
      portfolioId: this.portfolioId
        ? this.portfolioId.toProtobufJSON(options)
        : null,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      goalNotifications: this.goalNotifications,
      portfolio: this.portfolio ? this.portfolio.toProtobufJSON(options) : null,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      goalCategory: this.goalCategory
        ? this.goalCategory.toProtobufJSON(options)
        : null,
      archived: this.archived,
      currencyId: this.currencyId
        ? this.currencyId.toProtobufJSON(options)
        : null,
      lastDepositDate: this.lastDepositDate
        ? this.lastDepositDate.toProtobufJSON(options)
        : null,
      haveTransactionsProcessed: this.haveTransactionsProcessed,
      displayCurrencyId: this.displayCurrencyId
        ? this.displayCurrencyId.toProtobufJSON(options)
        : null,
      riskLevel: this.riskLevel ? this.riskLevel.toProtobufJSON(options) : null,
      displayCurrency: this.displayCurrency
        ? this.displayCurrency.toProtobufJSON(options)
        : null,
      investmentStrategy: this.investmentStrategy
        ? this.investmentStrategy.toProtobufJSON(options)
        : null,
      enabledEdit: this.enabledEdit,
      firstTransactionDate: this.firstTransactionDate
        ? this.firstTransactionDate.toProtobufJSON(options)
        : null,
      imageLarge: this.imageLarge,
      imageSmall: this.imageSmall
    };
  }
}
export module GoalModel {
  /**
   * Standard JavaScript object representation for GoalModel
   */
  export interface AsObject {
    title: string;
    years: number;
    initialInvestment: number;
    monthlyContribution: number;
    currentCapital: number;
    amountsTransactionsPending: number;
    currentContribution: number;
    dateOfCompletion: string;
    progress: number;
    state: string;
    icon?: GoalModel.Icon.AsObject;
    targetAmount: number;
    signedContract: boolean;
    haveDeposited: boolean;
    haveRequestDeposited: boolean;
    waitingContractApproval: boolean;
    starred: boolean;
    id: number;
    userId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    goalCategoryId: number;
    riskLevelId?: googleProtobuf000.Int32Value.AsObject;
    financialEntityId?: googleProtobuf000.Int32Value.AsObject;
    portfolioId?: googleProtobuf000.Int32Value.AsObject;
    financialEntity?: FinancialEntityModel.AsObject;
    goalNotifications: string;
    portfolio?: PortfolioModel.AsObject;
    currency?: CurrencyModel.AsObject;
    goalCategory?: GoalCategoryModel.AsObject;
    archived: boolean;
    currencyId?: googleProtobuf000.Int32Value.AsObject;
    lastDepositDate?: googleProtobuf001.Timestamp.AsObject;
    haveTransactionsProcessed: boolean;
    displayCurrencyId?: googleProtobuf000.Int32Value.AsObject;
    riskLevel?: RiskLevelModel.AsObject;
    displayCurrency?: CurrencyModel.AsObject;
    investmentStrategy?: InvestmentStrategyModel.AsObject;
    enabledEdit: boolean;
    firstTransactionDate?: googleProtobuf001.Timestamp.AsObject;
    imageLarge: string;
    imageSmall: string;
  }

  /**
   * Protobuf JSON representation for GoalModel
   */
  export interface AsProtobufJSON {
    title: string;
    years: number;
    initialInvestment: number;
    monthlyContribution: number;
    currentCapital: number;
    amountsTransactionsPending: number;
    currentContribution: number;
    dateOfCompletion: string;
    progress: number;
    state: string;
    icon: GoalModel.Icon.AsProtobufJSON | null;
    targetAmount: number;
    signedContract: boolean;
    haveDeposited: boolean;
    haveRequestDeposited: boolean;
    waitingContractApproval: boolean;
    starred: boolean;
    id: number;
    userId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    goalCategoryId: number;
    riskLevelId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    financialEntityId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    portfolioId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
    goalNotifications: string;
    portfolio: PortfolioModel.AsProtobufJSON | null;
    currency: CurrencyModel.AsProtobufJSON | null;
    goalCategory: GoalCategoryModel.AsProtobufJSON | null;
    archived: boolean;
    currencyId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    lastDepositDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    haveTransactionsProcessed: boolean;
    displayCurrencyId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    riskLevel: RiskLevelModel.AsProtobufJSON | null;
    displayCurrency: CurrencyModel.AsProtobufJSON | null;
    investmentStrategy: InvestmentStrategyModel.AsProtobufJSON | null;
    enabledEdit: boolean;
    firstTransactionDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    imageLarge: string;
    imageSmall: string;
  }

  /**
   * Message implementation for common_message.GoalModel.Icon
   */
  export class Icon implements GrpcMessage {
    static id = 'common_message.GoalModel.Icon';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Icon();
      Icon.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Icon) {
      _instance.code = _instance.code || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Icon, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.code = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Icon.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Icon, _writer: BinaryWriter) {
      if (_instance.code) {
        _writer.writeString(1, _instance.code);
      }
    }

    private _code: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Icon to deeply clone from
     */
    constructor(_value?: RecursivePartial<Icon.AsObject>) {
      _value = _value || {};
      this.code = _value.code;
      Icon.refineValues(this);
    }
    get code(): string {
      return this._code;
    }
    set code(value: string) {
      this._code = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Icon.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Icon.AsObject {
      return {
        code: this.code
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Icon.AsProtobufJSON {
      return {
        code: this.code
      };
    }
  }
  export module Icon {
    /**
     * Standard JavaScript object representation for Icon
     */
    export interface AsObject {
      code: string;
    }

    /**
     * Protobuf JSON representation for Icon
     */
    export interface AsProtobufJSON {
      code: string;
    }
  }
}

/**
 * Message implementation for common_message.GoalCategoryModel
 */
export class GoalCategoryModel implements GrpcMessage {
  static id = 'common_message.GoalCategoryModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalCategoryModel();
    GoalCategoryModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalCategoryModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.code = _instance.code || '';
    _instance.description = _instance.description || '';
    _instance.icon = _instance.icon || undefined;
    _instance.image = _instance.image || undefined;
    _instance.initialInvestment = _instance.initialInvestment || 0;
    _instance.isVisible = _instance.isVisible || false;
    _instance.monthlyContribution = _instance.monthlyContribution || 0;
    _instance.targetAmount = _instance.targetAmount || 0;
    _instance.title = _instance.title || '';
    _instance.uuid = _instance.uuid || '';
    _instance.year = _instance.year || 0;
    _instance.goalCategoryConfigCurrencies =
      _instance.goalCategoryConfigCurrencies || [];
    _instance.recommendfor = _instance.recommendfor || '';
    _instance.enabledmilestoneemails =
      _instance.enabledmilestoneemails || false;
    _instance.imageLarge = _instance.imageLarge || '';
    _instance.imageSmall = _instance.imageSmall || '';
    _instance.displayOrder = _instance.displayOrder || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalCategoryModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.code = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.icon = new GoalCategoryModel.Icon();
          _reader.readMessage(
            _instance.icon,
            GoalCategoryModel.Icon.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.image = new GoalCategoryModel.Image();
          _reader.readMessage(
            _instance.image,
            GoalCategoryModel.Image.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.initialInvestment = _reader.readInt32();
          break;
        case 9:
          _instance.isVisible = _reader.readBool();
          break;
        case 10:
          _instance.monthlyContribution = _reader.readInt32();
          break;
        case 11:
          _instance.targetAmount = _reader.readInt32();
          break;
        case 12:
          _instance.title = _reader.readString();
          break;
        case 13:
          _instance.uuid = _reader.readString();
          break;
        case 14:
          _instance.year = _reader.readInt32();
          break;
        case 15:
          const messageInitializer15 = new GoalCategoryConfigCurrencyModel();
          _reader.readMessage(
            messageInitializer15,
            GoalCategoryConfigCurrencyModel.deserializeBinaryFromReader
          );
          (_instance.goalCategoryConfigCurrencies =
            _instance.goalCategoryConfigCurrencies || []).push(
            messageInitializer15
          );
          break;
        case 16:
          _instance.recommendfor = _reader.readString();
          break;
        case 17:
          _instance.enabledmilestoneemails = _reader.readBool();
          break;
        case 18:
          _instance.imageLarge = _reader.readString();
          break;
        case 19:
          _instance.imageSmall = _reader.readString();
          break;
        case 20:
          _instance.displayOrder = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GoalCategoryModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalCategoryModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.code) {
      _writer.writeString(4, _instance.code);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.icon) {
      _writer.writeMessage(
        6,
        _instance.icon as any,
        GoalCategoryModel.Icon.serializeBinaryToWriter
      );
    }
    if (_instance.image) {
      _writer.writeMessage(
        7,
        _instance.image as any,
        GoalCategoryModel.Image.serializeBinaryToWriter
      );
    }
    if (_instance.initialInvestment) {
      _writer.writeInt32(8, _instance.initialInvestment);
    }
    if (_instance.isVisible) {
      _writer.writeBool(9, _instance.isVisible);
    }
    if (_instance.monthlyContribution) {
      _writer.writeInt32(10, _instance.monthlyContribution);
    }
    if (_instance.targetAmount) {
      _writer.writeInt32(11, _instance.targetAmount);
    }
    if (_instance.title) {
      _writer.writeString(12, _instance.title);
    }
    if (_instance.uuid) {
      _writer.writeString(13, _instance.uuid);
    }
    if (_instance.year) {
      _writer.writeInt32(14, _instance.year);
    }
    if (
      _instance.goalCategoryConfigCurrencies &&
      _instance.goalCategoryConfigCurrencies.length
    ) {
      _writer.writeRepeatedMessage(
        15,
        _instance.goalCategoryConfigCurrencies as any,
        GoalCategoryConfigCurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.recommendfor) {
      _writer.writeString(16, _instance.recommendfor);
    }
    if (_instance.enabledmilestoneemails) {
      _writer.writeBool(17, _instance.enabledmilestoneemails);
    }
    if (_instance.imageLarge) {
      _writer.writeString(18, _instance.imageLarge);
    }
    if (_instance.imageSmall) {
      _writer.writeString(19, _instance.imageSmall);
    }
    if (_instance.displayOrder) {
      _writer.writeInt32(20, _instance.displayOrder);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _code: string;
  private _description: string;
  private _icon?: GoalCategoryModel.Icon;
  private _image?: GoalCategoryModel.Image;
  private _initialInvestment: number;
  private _isVisible: boolean;
  private _monthlyContribution: number;
  private _targetAmount: number;
  private _title: string;
  private _uuid: string;
  private _year: number;
  private _goalCategoryConfigCurrencies?: GoalCategoryConfigCurrencyModel[];
  private _recommendfor: string;
  private _enabledmilestoneemails: boolean;
  private _imageLarge: string;
  private _imageSmall: string;
  private _displayOrder: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalCategoryModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalCategoryModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.code = _value.code;
    this.description = _value.description;
    this.icon = _value.icon
      ? new GoalCategoryModel.Icon(_value.icon)
      : undefined;
    this.image = _value.image
      ? new GoalCategoryModel.Image(_value.image)
      : undefined;
    this.initialInvestment = _value.initialInvestment;
    this.isVisible = _value.isVisible;
    this.monthlyContribution = _value.monthlyContribution;
    this.targetAmount = _value.targetAmount;
    this.title = _value.title;
    this.uuid = _value.uuid;
    this.year = _value.year;
    this.goalCategoryConfigCurrencies = (
      _value.goalCategoryConfigCurrencies || []
    ).map(m => new GoalCategoryConfigCurrencyModel(m));
    this.recommendfor = _value.recommendfor;
    this.enabledmilestoneemails = _value.enabledmilestoneemails;
    this.imageLarge = _value.imageLarge;
    this.imageSmall = _value.imageSmall;
    this.displayOrder = _value.displayOrder;
    GoalCategoryModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get icon(): GoalCategoryModel.Icon | undefined {
    return this._icon;
  }
  set icon(value: GoalCategoryModel.Icon | undefined) {
    this._icon = value;
  }
  get image(): GoalCategoryModel.Image | undefined {
    return this._image;
  }
  set image(value: GoalCategoryModel.Image | undefined) {
    this._image = value;
  }
  get initialInvestment(): number {
    return this._initialInvestment;
  }
  set initialInvestment(value: number) {
    this._initialInvestment = value;
  }
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
  }
  get monthlyContribution(): number {
    return this._monthlyContribution;
  }
  set monthlyContribution(value: number) {
    this._monthlyContribution = value;
  }
  get targetAmount(): number {
    return this._targetAmount;
  }
  set targetAmount(value: number) {
    this._targetAmount = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get year(): number {
    return this._year;
  }
  set year(value: number) {
    this._year = value;
  }
  get goalCategoryConfigCurrencies():
    | GoalCategoryConfigCurrencyModel[]
    | undefined {
    return this._goalCategoryConfigCurrencies;
  }
  set goalCategoryConfigCurrencies(
    value: GoalCategoryConfigCurrencyModel[] | undefined
  ) {
    this._goalCategoryConfigCurrencies = value;
  }
  get recommendfor(): string {
    return this._recommendfor;
  }
  set recommendfor(value: string) {
    this._recommendfor = value;
  }
  get enabledmilestoneemails(): boolean {
    return this._enabledmilestoneemails;
  }
  set enabledmilestoneemails(value: boolean) {
    this._enabledmilestoneemails = value;
  }
  get imageLarge(): string {
    return this._imageLarge;
  }
  set imageLarge(value: string) {
    this._imageLarge = value;
  }
  get imageSmall(): string {
    return this._imageSmall;
  }
  set imageSmall(value: string) {
    this._imageSmall = value;
  }
  get displayOrder(): number {
    return this._displayOrder;
  }
  set displayOrder(value: number) {
    this._displayOrder = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalCategoryModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalCategoryModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      code: this.code,
      description: this.description,
      icon: this.icon ? this.icon.toObject() : undefined,
      image: this.image ? this.image.toObject() : undefined,
      initialInvestment: this.initialInvestment,
      isVisible: this.isVisible,
      monthlyContribution: this.monthlyContribution,
      targetAmount: this.targetAmount,
      title: this.title,
      uuid: this.uuid,
      year: this.year,
      goalCategoryConfigCurrencies: (
        this.goalCategoryConfigCurrencies || []
      ).map(m => m.toObject()),
      recommendfor: this.recommendfor,
      enabledmilestoneemails: this.enabledmilestoneemails,
      imageLarge: this.imageLarge,
      imageSmall: this.imageSmall,
      displayOrder: this.displayOrder
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalCategoryModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      code: this.code,
      description: this.description,
      icon: this.icon ? this.icon.toProtobufJSON(options) : null,
      image: this.image ? this.image.toProtobufJSON(options) : null,
      initialInvestment: this.initialInvestment,
      isVisible: this.isVisible,
      monthlyContribution: this.monthlyContribution,
      targetAmount: this.targetAmount,
      title: this.title,
      uuid: this.uuid,
      year: this.year,
      goalCategoryConfigCurrencies: (
        this.goalCategoryConfigCurrencies || []
      ).map(m => m.toProtobufJSON(options)),
      recommendfor: this.recommendfor,
      enabledmilestoneemails: this.enabledmilestoneemails,
      imageLarge: this.imageLarge,
      imageSmall: this.imageSmall,
      displayOrder: this.displayOrder
    };
  }
}
export module GoalCategoryModel {
  /**
   * Standard JavaScript object representation for GoalCategoryModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    code: string;
    description: string;
    icon?: GoalCategoryModel.Icon.AsObject;
    image?: GoalCategoryModel.Image.AsObject;
    initialInvestment: number;
    isVisible: boolean;
    monthlyContribution: number;
    targetAmount: number;
    title: string;
    uuid: string;
    year: number;
    goalCategoryConfigCurrencies?: GoalCategoryConfigCurrencyModel.AsObject[];
    recommendfor: string;
    enabledmilestoneemails: boolean;
    imageLarge: string;
    imageSmall: string;
    displayOrder: number;
  }

  /**
   * Protobuf JSON representation for GoalCategoryModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    code: string;
    description: string;
    icon: GoalCategoryModel.Icon.AsProtobufJSON | null;
    image: GoalCategoryModel.Image.AsProtobufJSON | null;
    initialInvestment: number;
    isVisible: boolean;
    monthlyContribution: number;
    targetAmount: number;
    title: string;
    uuid: string;
    year: number;
    goalCategoryConfigCurrencies:
      | GoalCategoryConfigCurrencyModel.AsProtobufJSON[]
      | null;
    recommendfor: string;
    enabledmilestoneemails: boolean;
    imageLarge: string;
    imageSmall: string;
    displayOrder: number;
  }

  /**
   * Message implementation for common_message.GoalCategoryModel.Icon
   */
  export class Icon implements GrpcMessage {
    static id = 'common_message.GoalCategoryModel.Icon';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Icon();
      Icon.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Icon) {
      _instance.code = _instance.code || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Icon, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.code = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Icon.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Icon, _writer: BinaryWriter) {
      if (_instance.code) {
        _writer.writeString(1, _instance.code);
      }
    }

    private _code: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Icon to deeply clone from
     */
    constructor(_value?: RecursivePartial<Icon.AsObject>) {
      _value = _value || {};
      this.code = _value.code;
      Icon.refineValues(this);
    }
    get code(): string {
      return this._code;
    }
    set code(value: string) {
      this._code = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Icon.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Icon.AsObject {
      return {
        code: this.code
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Icon.AsProtobufJSON {
      return {
        code: this.code
      };
    }
  }
  export module Icon {
    /**
     * Standard JavaScript object representation for Icon
     */
    export interface AsObject {
      code: string;
    }

    /**
     * Protobuf JSON representation for Icon
     */
    export interface AsProtobufJSON {
      code: string;
    }
  }

  /**
   * Message implementation for common_message.GoalCategoryModel.Image
   */
  export class Image implements GrpcMessage {
    static id = 'common_message.GoalCategoryModel.Image';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Image();
      Image.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Image) {
      _instance.src = _instance.src || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Image,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.src = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Image.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Image, _writer: BinaryWriter) {
      if (_instance.src) {
        _writer.writeString(1, _instance.src);
      }
    }

    private _src: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Image to deeply clone from
     */
    constructor(_value?: RecursivePartial<Image.AsObject>) {
      _value = _value || {};
      this.src = _value.src;
      Image.refineValues(this);
    }
    get src(): string {
      return this._src;
    }
    set src(value: string) {
      this._src = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Image.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Image.AsObject {
      return {
        src: this.src
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Image.AsProtobufJSON {
      return {
        src: this.src
      };
    }
  }
  export module Image {
    /**
     * Standard JavaScript object representation for Image
     */
    export interface AsObject {
      src: string;
    }

    /**
     * Protobuf JSON representation for Image
     */
    export interface AsProtobufJSON {
      src: string;
    }
  }
}

/**
 * Message implementation for common_message.GoalCategoryConfigCurrencyModel
 */
export class GoalCategoryConfigCurrencyModel implements GrpcMessage {
  static id = 'common_message.GoalCategoryConfigCurrencyModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalCategoryConfigCurrencyModel();
    GoalCategoryConfigCurrencyModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalCategoryConfigCurrencyModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.goalCategoryId = _instance.goalCategoryId || 0;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.targetAmount = _instance.targetAmount || 0;
    _instance.minTargetAmount = _instance.minTargetAmount || 0;
    _instance.maxTargetAmount = _instance.maxTargetAmount || 0;
    _instance.initialInvestment = _instance.initialInvestment || 0;
    _instance.minInitialInvestment = _instance.minInitialInvestment || 0;
    _instance.maxInitialInvestment = _instance.maxInitialInvestment || 0;
    _instance.monthlyContribution = _instance.monthlyContribution || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalCategoryConfigCurrencyModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.goalCategoryId = _reader.readInt32();
          break;
        case 5:
          _instance.currencyId = _reader.readInt32();
          break;
        case 6:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.targetAmount = _reader.readDouble();
          break;
        case 8:
          _instance.minTargetAmount = _reader.readDouble();
          break;
        case 9:
          _instance.maxTargetAmount = _reader.readDouble();
          break;
        case 10:
          _instance.initialInvestment = _reader.readDouble();
          break;
        case 11:
          _instance.minInitialInvestment = _reader.readDouble();
          break;
        case 12:
          _instance.maxInitialInvestment = _reader.readDouble();
          break;
        case 13:
          _instance.monthlyContribution = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    GoalCategoryConfigCurrencyModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalCategoryConfigCurrencyModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.goalCategoryId) {
      _writer.writeInt32(4, _instance.goalCategoryId);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(5, _instance.currencyId);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        6,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.targetAmount) {
      _writer.writeDouble(7, _instance.targetAmount);
    }
    if (_instance.minTargetAmount) {
      _writer.writeDouble(8, _instance.minTargetAmount);
    }
    if (_instance.maxTargetAmount) {
      _writer.writeDouble(9, _instance.maxTargetAmount);
    }
    if (_instance.initialInvestment) {
      _writer.writeDouble(10, _instance.initialInvestment);
    }
    if (_instance.minInitialInvestment) {
      _writer.writeDouble(11, _instance.minInitialInvestment);
    }
    if (_instance.maxInitialInvestment) {
      _writer.writeDouble(12, _instance.maxInitialInvestment);
    }
    if (_instance.monthlyContribution) {
      _writer.writeDouble(13, _instance.monthlyContribution);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _goalCategoryId: number;
  private _currencyId: number;
  private _currency?: CurrencyModel;
  private _targetAmount: number;
  private _minTargetAmount: number;
  private _maxTargetAmount: number;
  private _initialInvestment: number;
  private _minInitialInvestment: number;
  private _maxInitialInvestment: number;
  private _monthlyContribution: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalCategoryConfigCurrencyModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GoalCategoryConfigCurrencyModel.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.goalCategoryId = _value.goalCategoryId;
    this.currencyId = _value.currencyId;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.targetAmount = _value.targetAmount;
    this.minTargetAmount = _value.minTargetAmount;
    this.maxTargetAmount = _value.maxTargetAmount;
    this.initialInvestment = _value.initialInvestment;
    this.minInitialInvestment = _value.minInitialInvestment;
    this.maxInitialInvestment = _value.maxInitialInvestment;
    this.monthlyContribution = _value.monthlyContribution;
    GoalCategoryConfigCurrencyModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get goalCategoryId(): number {
    return this._goalCategoryId;
  }
  set goalCategoryId(value: number) {
    this._goalCategoryId = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get targetAmount(): number {
    return this._targetAmount;
  }
  set targetAmount(value: number) {
    this._targetAmount = value;
  }
  get minTargetAmount(): number {
    return this._minTargetAmount;
  }
  set minTargetAmount(value: number) {
    this._minTargetAmount = value;
  }
  get maxTargetAmount(): number {
    return this._maxTargetAmount;
  }
  set maxTargetAmount(value: number) {
    this._maxTargetAmount = value;
  }
  get initialInvestment(): number {
    return this._initialInvestment;
  }
  set initialInvestment(value: number) {
    this._initialInvestment = value;
  }
  get minInitialInvestment(): number {
    return this._minInitialInvestment;
  }
  set minInitialInvestment(value: number) {
    this._minInitialInvestment = value;
  }
  get maxInitialInvestment(): number {
    return this._maxInitialInvestment;
  }
  set maxInitialInvestment(value: number) {
    this._maxInitialInvestment = value;
  }
  get monthlyContribution(): number {
    return this._monthlyContribution;
  }
  set monthlyContribution(value: number) {
    this._monthlyContribution = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalCategoryConfigCurrencyModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalCategoryConfigCurrencyModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      goalCategoryId: this.goalCategoryId,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toObject() : undefined,
      targetAmount: this.targetAmount,
      minTargetAmount: this.minTargetAmount,
      maxTargetAmount: this.maxTargetAmount,
      initialInvestment: this.initialInvestment,
      minInitialInvestment: this.minInitialInvestment,
      maxInitialInvestment: this.maxInitialInvestment,
      monthlyContribution: this.monthlyContribution
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalCategoryConfigCurrencyModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      goalCategoryId: this.goalCategoryId,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      targetAmount: this.targetAmount,
      minTargetAmount: this.minTargetAmount,
      maxTargetAmount: this.maxTargetAmount,
      initialInvestment: this.initialInvestment,
      minInitialInvestment: this.minInitialInvestment,
      maxInitialInvestment: this.maxInitialInvestment,
      monthlyContribution: this.monthlyContribution
    };
  }
}
export module GoalCategoryConfigCurrencyModel {
  /**
   * Standard JavaScript object representation for GoalCategoryConfigCurrencyModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    goalCategoryId: number;
    currencyId: number;
    currency?: CurrencyModel.AsObject;
    targetAmount: number;
    minTargetAmount: number;
    maxTargetAmount: number;
    initialInvestment: number;
    minInitialInvestment: number;
    maxInitialInvestment: number;
    monthlyContribution: number;
  }

  /**
   * Protobuf JSON representation for GoalCategoryConfigCurrencyModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    goalCategoryId: number;
    currencyId: number;
    currency: CurrencyModel.AsProtobufJSON | null;
    targetAmount: number;
    minTargetAmount: number;
    maxTargetAmount: number;
    initialInvestment: number;
    minInitialInvestment: number;
    maxInitialInvestment: number;
    monthlyContribution: number;
  }
}

/**
 * Message implementation for common_message.RiskProfileModel
 */
export class RiskProfileModel implements GrpcMessage {
  static id = 'common_message.RiskProfileModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RiskProfileModel();
    RiskProfileModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RiskProfileModel) {
    _instance.assetAllocation = _instance.assetAllocation || 0;
    _instance.code = _instance.code || '';
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.expectedProfitabilityPercentage =
      _instance.expectedProfitabilityPercentage || 0;
    _instance.id = _instance.id || 0;
    _instance.maximumProfitabilityPercentage =
      _instance.maximumProfitabilityPercentage || 0;
    _instance.minimumProfitabilityPercentage =
      _instance.minimumProfitabilityPercentage || 0;
    _instance.title = _instance.title || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RiskProfileModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.assetAllocation = _reader.readDouble();
          break;
        case 2:
          _instance.code = _reader.readString();
          break;
        case 3:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.expectedProfitabilityPercentage = _reader.readDouble();
          break;
        case 6:
          _instance.id = _reader.readInt32();
          break;
        case 7:
          _instance.maximumProfitabilityPercentage = _reader.readDouble();
          break;
        case 8:
          _instance.minimumProfitabilityPercentage = _reader.readDouble();
          break;
        case 9:
          _instance.title = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RiskProfileModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RiskProfileModel,
    _writer: BinaryWriter
  ) {
    if (_instance.assetAllocation) {
      _writer.writeDouble(1, _instance.assetAllocation);
    }
    if (_instance.code) {
      _writer.writeString(2, _instance.code);
    }
    if (_instance.created) {
      _writer.writeMessage(
        3,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        4,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.expectedProfitabilityPercentage) {
      _writer.writeDouble(5, _instance.expectedProfitabilityPercentage);
    }
    if (_instance.id) {
      _writer.writeInt32(6, _instance.id);
    }
    if (_instance.maximumProfitabilityPercentage) {
      _writer.writeDouble(7, _instance.maximumProfitabilityPercentage);
    }
    if (_instance.minimumProfitabilityPercentage) {
      _writer.writeDouble(8, _instance.minimumProfitabilityPercentage);
    }
    if (_instance.title) {
      _writer.writeString(9, _instance.title);
    }
  }

  private _assetAllocation: number;
  private _code: string;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _expectedProfitabilityPercentage: number;
  private _id: number;
  private _maximumProfitabilityPercentage: number;
  private _minimumProfitabilityPercentage: number;
  private _title: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RiskProfileModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<RiskProfileModel.AsObject>) {
    _value = _value || {};
    this.assetAllocation = _value.assetAllocation;
    this.code = _value.code;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.expectedProfitabilityPercentage =
      _value.expectedProfitabilityPercentage;
    this.id = _value.id;
    this.maximumProfitabilityPercentage = _value.maximumProfitabilityPercentage;
    this.minimumProfitabilityPercentage = _value.minimumProfitabilityPercentage;
    this.title = _value.title;
    RiskProfileModel.refineValues(this);
  }
  get assetAllocation(): number {
    return this._assetAllocation;
  }
  set assetAllocation(value: number) {
    this._assetAllocation = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get expectedProfitabilityPercentage(): number {
    return this._expectedProfitabilityPercentage;
  }
  set expectedProfitabilityPercentage(value: number) {
    this._expectedProfitabilityPercentage = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get maximumProfitabilityPercentage(): number {
    return this._maximumProfitabilityPercentage;
  }
  set maximumProfitabilityPercentage(value: number) {
    this._maximumProfitabilityPercentage = value;
  }
  get minimumProfitabilityPercentage(): number {
    return this._minimumProfitabilityPercentage;
  }
  set minimumProfitabilityPercentage(value: number) {
    this._minimumProfitabilityPercentage = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RiskProfileModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RiskProfileModel.AsObject {
    return {
      assetAllocation: this.assetAllocation,
      code: this.code,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      expectedProfitabilityPercentage: this.expectedProfitabilityPercentage,
      id: this.id,
      maximumProfitabilityPercentage: this.maximumProfitabilityPercentage,
      minimumProfitabilityPercentage: this.minimumProfitabilityPercentage,
      title: this.title
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RiskProfileModel.AsProtobufJSON {
    return {
      assetAllocation: this.assetAllocation,
      code: this.code,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      expectedProfitabilityPercentage: this.expectedProfitabilityPercentage,
      id: this.id,
      maximumProfitabilityPercentage: this.maximumProfitabilityPercentage,
      minimumProfitabilityPercentage: this.minimumProfitabilityPercentage,
      title: this.title
    };
  }
}
export module RiskProfileModel {
  /**
   * Standard JavaScript object representation for RiskProfileModel
   */
  export interface AsObject {
    assetAllocation: number;
    code: string;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    expectedProfitabilityPercentage: number;
    id: number;
    maximumProfitabilityPercentage: number;
    minimumProfitabilityPercentage: number;
    title: string;
  }

  /**
   * Protobuf JSON representation for RiskProfileModel
   */
  export interface AsProtobufJSON {
    assetAllocation: number;
    code: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    expectedProfitabilityPercentage: number;
    id: number;
    maximumProfitabilityPercentage: number;
    minimumProfitabilityPercentage: number;
    title: string;
  }
}

/**
 * Message implementation for common_message.RiskLevelModel
 */
export class RiskLevelModel implements GrpcMessage {
  static id = 'common_message.RiskLevelModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RiskLevelModel();
    RiskLevelModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RiskLevelModel) {
    _instance.assetAllocation = _instance.assetAllocation || 0;
    _instance.code = _instance.code || '';
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.expectedProfitabilityPercentage =
      _instance.expectedProfitabilityPercentage || 0;
    _instance.id = _instance.id || 0;
    _instance.maximumProfitabilityPercentage =
      _instance.maximumProfitabilityPercentage || 0;
    _instance.minimumProfitabilityPercentage =
      _instance.minimumProfitabilityPercentage || 0;
    _instance.title = _instance.title || '';
    _instance.position = _instance.position || 0;
    _instance.version = _instance.version || '';
    _instance.description = _instance.description || '';
    _instance.shortDescription = _instance.shortDescription || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RiskLevelModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.assetAllocation = _reader.readDouble();
          break;
        case 2:
          _instance.code = _reader.readString();
          break;
        case 3:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.expectedProfitabilityPercentage = _reader.readDouble();
          break;
        case 6:
          _instance.id = _reader.readInt32();
          break;
        case 7:
          _instance.maximumProfitabilityPercentage = _reader.readDouble();
          break;
        case 8:
          _instance.minimumProfitabilityPercentage = _reader.readDouble();
          break;
        case 9:
          _instance.title = _reader.readString();
          break;
        case 10:
          _instance.position = _reader.readInt32();
          break;
        case 11:
          _instance.version = _reader.readString();
          break;
        case 12:
          _instance.description = _reader.readString();
          break;
        case 13:
          _instance.shortDescription = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RiskLevelModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RiskLevelModel,
    _writer: BinaryWriter
  ) {
    if (_instance.assetAllocation) {
      _writer.writeDouble(1, _instance.assetAllocation);
    }
    if (_instance.code) {
      _writer.writeString(2, _instance.code);
    }
    if (_instance.created) {
      _writer.writeMessage(
        3,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        4,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.expectedProfitabilityPercentage) {
      _writer.writeDouble(5, _instance.expectedProfitabilityPercentage);
    }
    if (_instance.id) {
      _writer.writeInt32(6, _instance.id);
    }
    if (_instance.maximumProfitabilityPercentage) {
      _writer.writeDouble(7, _instance.maximumProfitabilityPercentage);
    }
    if (_instance.minimumProfitabilityPercentage) {
      _writer.writeDouble(8, _instance.minimumProfitabilityPercentage);
    }
    if (_instance.title) {
      _writer.writeString(9, _instance.title);
    }
    if (_instance.position) {
      _writer.writeInt32(10, _instance.position);
    }
    if (_instance.version) {
      _writer.writeString(11, _instance.version);
    }
    if (_instance.description) {
      _writer.writeString(12, _instance.description);
    }
    if (_instance.shortDescription) {
      _writer.writeString(13, _instance.shortDescription);
    }
  }

  private _assetAllocation: number;
  private _code: string;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _expectedProfitabilityPercentage: number;
  private _id: number;
  private _maximumProfitabilityPercentage: number;
  private _minimumProfitabilityPercentage: number;
  private _title: string;
  private _position: number;
  private _version: string;
  private _description: string;
  private _shortDescription: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RiskLevelModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<RiskLevelModel.AsObject>) {
    _value = _value || {};
    this.assetAllocation = _value.assetAllocation;
    this.code = _value.code;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.expectedProfitabilityPercentage =
      _value.expectedProfitabilityPercentage;
    this.id = _value.id;
    this.maximumProfitabilityPercentage = _value.maximumProfitabilityPercentage;
    this.minimumProfitabilityPercentage = _value.minimumProfitabilityPercentage;
    this.title = _value.title;
    this.position = _value.position;
    this.version = _value.version;
    this.description = _value.description;
    this.shortDescription = _value.shortDescription;
    RiskLevelModel.refineValues(this);
  }
  get assetAllocation(): number {
    return this._assetAllocation;
  }
  set assetAllocation(value: number) {
    this._assetAllocation = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get expectedProfitabilityPercentage(): number {
    return this._expectedProfitabilityPercentage;
  }
  set expectedProfitabilityPercentage(value: number) {
    this._expectedProfitabilityPercentage = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get maximumProfitabilityPercentage(): number {
    return this._maximumProfitabilityPercentage;
  }
  set maximumProfitabilityPercentage(value: number) {
    this._maximumProfitabilityPercentage = value;
  }
  get minimumProfitabilityPercentage(): number {
    return this._minimumProfitabilityPercentage;
  }
  set minimumProfitabilityPercentage(value: number) {
    this._minimumProfitabilityPercentage = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get position(): number {
    return this._position;
  }
  set position(value: number) {
    this._position = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get shortDescription(): string {
    return this._shortDescription;
  }
  set shortDescription(value: string) {
    this._shortDescription = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RiskLevelModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RiskLevelModel.AsObject {
    return {
      assetAllocation: this.assetAllocation,
      code: this.code,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      expectedProfitabilityPercentage: this.expectedProfitabilityPercentage,
      id: this.id,
      maximumProfitabilityPercentage: this.maximumProfitabilityPercentage,
      minimumProfitabilityPercentage: this.minimumProfitabilityPercentage,
      title: this.title,
      position: this.position,
      version: this.version,
      description: this.description,
      shortDescription: this.shortDescription
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RiskLevelModel.AsProtobufJSON {
    return {
      assetAllocation: this.assetAllocation,
      code: this.code,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      expectedProfitabilityPercentage: this.expectedProfitabilityPercentage,
      id: this.id,
      maximumProfitabilityPercentage: this.maximumProfitabilityPercentage,
      minimumProfitabilityPercentage: this.minimumProfitabilityPercentage,
      title: this.title,
      position: this.position,
      version: this.version,
      description: this.description,
      shortDescription: this.shortDescription
    };
  }
}
export module RiskLevelModel {
  /**
   * Standard JavaScript object representation for RiskLevelModel
   */
  export interface AsObject {
    assetAllocation: number;
    code: string;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    expectedProfitabilityPercentage: number;
    id: number;
    maximumProfitabilityPercentage: number;
    minimumProfitabilityPercentage: number;
    title: string;
    position: number;
    version: string;
    description: string;
    shortDescription: string;
  }

  /**
   * Protobuf JSON representation for RiskLevelModel
   */
  export interface AsProtobufJSON {
    assetAllocation: number;
    code: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    expectedProfitabilityPercentage: number;
    id: number;
    maximumProfitabilityPercentage: number;
    minimumProfitabilityPercentage: number;
    title: string;
    position: number;
    version: string;
    description: string;
    shortDescription: string;
  }
}

/**
 * Message implementation for common_message.InvestmentStrategyModel
 */
export class InvestmentStrategyModel implements GrpcMessage {
  static id = 'common_message.InvestmentStrategyModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new InvestmentStrategyModel();
    InvestmentStrategyModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: InvestmentStrategyModel) {
    _instance.id = _instance.id || 0;
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.code = _instance.code || '';
    _instance.features = _instance.features || [];
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.isVisible = _instance.isVisible || false;
    _instance.isDefault = _instance.isDefault || false;
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.shortTitle = _instance.shortTitle || '';
    _instance.investmentStrategyTypeId =
      _instance.investmentStrategyTypeId || 0;
    _instance.investmentStrategyType =
      _instance.investmentStrategyType || undefined;
    _instance.isRecommended = _instance.isRecommended || false;
    _instance.iconUrl = _instance.iconUrl || '';
    _instance.recommendedFor = _instance.recommendedFor || '';
    _instance.displayOrder = _instance.displayOrder || 0;
    _instance.investmentTypeId = _instance.investmentTypeId || 0;
    _instance.investmentType = _instance.investmentType || undefined;
    _instance.isSemiLiquid = _instance.isSemiLiquid || false;
    _instance.manager = _instance.manager || '';
    _instance.minimumAmountToInvest = _instance.minimumAmountToInvest || '';
    _instance.historicalAnnualProfitability =
      _instance.historicalAnnualProfitability || '';
    _instance.targetProfitability = _instance.targetProfitability || '';
    _instance.frequencyForWithdrawals = _instance.frequencyForWithdrawals || '';
    _instance.extraInformationFrequencyForWithdrawals =
      _instance.extraInformationFrequencyForWithdrawals || '';
    _instance.extraInformationManager = _instance.extraInformationManager || '';
    _instance.videoExplicationUrl = _instance.videoExplicationUrl || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: InvestmentStrategyModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.code = _reader.readString();
          break;
        case 5:
          (_instance.features = _instance.features || []).push(
            _reader.readString()
          );
          break;
        case 6:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 9:
          _instance.isVisible = _reader.readBool();
          break;
        case 10:
          _instance.isDefault = _reader.readBool();
          break;
        case 11:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.shortTitle = _reader.readString();
          break;
        case 13:
          _instance.investmentStrategyTypeId = _reader.readInt32();
          break;
        case 14:
          _instance.investmentStrategyType = new InvestmentStrategyTypeModel();
          _reader.readMessage(
            _instance.investmentStrategyType,
            InvestmentStrategyTypeModel.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.isRecommended = _reader.readBool();
          break;
        case 16:
          _instance.iconUrl = _reader.readString();
          break;
        case 17:
          _instance.recommendedFor = _reader.readString();
          break;
        case 18:
          _instance.displayOrder = _reader.readInt32();
          break;
        case 19:
          _instance.investmentTypeId = _reader.readInt32();
          break;
        case 20:
          _instance.investmentType = new InvestmentTypeModel();
          _reader.readMessage(
            _instance.investmentType,
            InvestmentTypeModel.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.isSemiLiquid = _reader.readBool();
          break;
        case 22:
          _instance.manager = _reader.readString();
          break;
        case 23:
          _instance.minimumAmountToInvest = _reader.readString();
          break;
        case 24:
          _instance.historicalAnnualProfitability = _reader.readString();
          break;
        case 25:
          _instance.targetProfitability = _reader.readString();
          break;
        case 26:
          _instance.frequencyForWithdrawals = _reader.readString();
          break;
        case 27:
          _instance.extraInformationFrequencyForWithdrawals = _reader.readString();
          break;
        case 28:
          _instance.extraInformationManager = _reader.readString();
          break;
        case 29:
          _instance.videoExplicationUrl = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    InvestmentStrategyModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: InvestmentStrategyModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.code) {
      _writer.writeString(4, _instance.code);
    }
    if (_instance.features && _instance.features.length) {
      _writer.writeRepeatedString(5, _instance.features);
    }
    if (_instance.created) {
      _writer.writeMessage(
        6,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        7,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(8, _instance.financialEntityId);
    }
    if (_instance.isVisible) {
      _writer.writeBool(9, _instance.isVisible);
    }
    if (_instance.isDefault) {
      _writer.writeBool(10, _instance.isDefault);
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        11,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.shortTitle) {
      _writer.writeString(12, _instance.shortTitle);
    }
    if (_instance.investmentStrategyTypeId) {
      _writer.writeInt32(13, _instance.investmentStrategyTypeId);
    }
    if (_instance.investmentStrategyType) {
      _writer.writeMessage(
        14,
        _instance.investmentStrategyType as any,
        InvestmentStrategyTypeModel.serializeBinaryToWriter
      );
    }
    if (_instance.isRecommended) {
      _writer.writeBool(15, _instance.isRecommended);
    }
    if (_instance.iconUrl) {
      _writer.writeString(16, _instance.iconUrl);
    }
    if (_instance.recommendedFor) {
      _writer.writeString(17, _instance.recommendedFor);
    }
    if (_instance.displayOrder) {
      _writer.writeInt32(18, _instance.displayOrder);
    }
    if (_instance.investmentTypeId) {
      _writer.writeInt32(19, _instance.investmentTypeId);
    }
    if (_instance.investmentType) {
      _writer.writeMessage(
        20,
        _instance.investmentType as any,
        InvestmentTypeModel.serializeBinaryToWriter
      );
    }
    if (_instance.isSemiLiquid) {
      _writer.writeBool(21, _instance.isSemiLiquid);
    }
    if (_instance.manager) {
      _writer.writeString(22, _instance.manager);
    }
    if (_instance.minimumAmountToInvest) {
      _writer.writeString(23, _instance.minimumAmountToInvest);
    }
    if (_instance.historicalAnnualProfitability) {
      _writer.writeString(24, _instance.historicalAnnualProfitability);
    }
    if (_instance.targetProfitability) {
      _writer.writeString(25, _instance.targetProfitability);
    }
    if (_instance.frequencyForWithdrawals) {
      _writer.writeString(26, _instance.frequencyForWithdrawals);
    }
    if (_instance.extraInformationFrequencyForWithdrawals) {
      _writer.writeString(
        27,
        _instance.extraInformationFrequencyForWithdrawals
      );
    }
    if (_instance.extraInformationManager) {
      _writer.writeString(28, _instance.extraInformationManager);
    }
    if (_instance.videoExplicationUrl) {
      _writer.writeString(29, _instance.videoExplicationUrl);
    }
  }

  private _id: number;
  private _title: string;
  private _description: string;
  private _code: string;
  private _features: string[];
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _financialEntityId: number;
  private _isVisible: boolean;
  private _isDefault: boolean;
  private _financialEntity?: FinancialEntityModel;
  private _shortTitle: string;
  private _investmentStrategyTypeId: number;
  private _investmentStrategyType?: InvestmentStrategyTypeModel;
  private _isRecommended: boolean;
  private _iconUrl: string;
  private _recommendedFor: string;
  private _displayOrder: number;
  private _investmentTypeId: number;
  private _investmentType?: InvestmentTypeModel;
  private _isSemiLiquid: boolean;
  private _manager: string;
  private _minimumAmountToInvest: string;
  private _historicalAnnualProfitability: string;
  private _targetProfitability: string;
  private _frequencyForWithdrawals: string;
  private _extraInformationFrequencyForWithdrawals: string;
  private _extraInformationManager: string;
  private _videoExplicationUrl: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of InvestmentStrategyModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<InvestmentStrategyModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.title = _value.title;
    this.description = _value.description;
    this.code = _value.code;
    this.features = (_value.features || []).slice();
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.financialEntityId = _value.financialEntityId;
    this.isVisible = _value.isVisible;
    this.isDefault = _value.isDefault;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.shortTitle = _value.shortTitle;
    this.investmentStrategyTypeId = _value.investmentStrategyTypeId;
    this.investmentStrategyType = _value.investmentStrategyType
      ? new InvestmentStrategyTypeModel(_value.investmentStrategyType)
      : undefined;
    this.isRecommended = _value.isRecommended;
    this.iconUrl = _value.iconUrl;
    this.recommendedFor = _value.recommendedFor;
    this.displayOrder = _value.displayOrder;
    this.investmentTypeId = _value.investmentTypeId;
    this.investmentType = _value.investmentType
      ? new InvestmentTypeModel(_value.investmentType)
      : undefined;
    this.isSemiLiquid = _value.isSemiLiquid;
    this.manager = _value.manager;
    this.minimumAmountToInvest = _value.minimumAmountToInvest;
    this.historicalAnnualProfitability = _value.historicalAnnualProfitability;
    this.targetProfitability = _value.targetProfitability;
    this.frequencyForWithdrawals = _value.frequencyForWithdrawals;
    this.extraInformationFrequencyForWithdrawals =
      _value.extraInformationFrequencyForWithdrawals;
    this.extraInformationManager = _value.extraInformationManager;
    this.videoExplicationUrl = _value.videoExplicationUrl;
    InvestmentStrategyModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get features(): string[] {
    return this._features;
  }
  set features(value: string[]) {
    this._features = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
  }
  get isDefault(): boolean {
    return this._isDefault;
  }
  set isDefault(value: boolean) {
    this._isDefault = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }
  get shortTitle(): string {
    return this._shortTitle;
  }
  set shortTitle(value: string) {
    this._shortTitle = value;
  }
  get investmentStrategyTypeId(): number {
    return this._investmentStrategyTypeId;
  }
  set investmentStrategyTypeId(value: number) {
    this._investmentStrategyTypeId = value;
  }
  get investmentStrategyType(): InvestmentStrategyTypeModel | undefined {
    return this._investmentStrategyType;
  }
  set investmentStrategyType(value: InvestmentStrategyTypeModel | undefined) {
    this._investmentStrategyType = value;
  }
  get isRecommended(): boolean {
    return this._isRecommended;
  }
  set isRecommended(value: boolean) {
    this._isRecommended = value;
  }
  get iconUrl(): string {
    return this._iconUrl;
  }
  set iconUrl(value: string) {
    this._iconUrl = value;
  }
  get recommendedFor(): string {
    return this._recommendedFor;
  }
  set recommendedFor(value: string) {
    this._recommendedFor = value;
  }
  get displayOrder(): number {
    return this._displayOrder;
  }
  set displayOrder(value: number) {
    this._displayOrder = value;
  }
  get investmentTypeId(): number {
    return this._investmentTypeId;
  }
  set investmentTypeId(value: number) {
    this._investmentTypeId = value;
  }
  get investmentType(): InvestmentTypeModel | undefined {
    return this._investmentType;
  }
  set investmentType(value: InvestmentTypeModel | undefined) {
    this._investmentType = value;
  }
  get isSemiLiquid(): boolean {
    return this._isSemiLiquid;
  }
  set isSemiLiquid(value: boolean) {
    this._isSemiLiquid = value;
  }
  get manager(): string {
    return this._manager;
  }
  set manager(value: string) {
    this._manager = value;
  }
  get minimumAmountToInvest(): string {
    return this._minimumAmountToInvest;
  }
  set minimumAmountToInvest(value: string) {
    this._minimumAmountToInvest = value;
  }
  get historicalAnnualProfitability(): string {
    return this._historicalAnnualProfitability;
  }
  set historicalAnnualProfitability(value: string) {
    this._historicalAnnualProfitability = value;
  }
  get targetProfitability(): string {
    return this._targetProfitability;
  }
  set targetProfitability(value: string) {
    this._targetProfitability = value;
  }
  get frequencyForWithdrawals(): string {
    return this._frequencyForWithdrawals;
  }
  set frequencyForWithdrawals(value: string) {
    this._frequencyForWithdrawals = value;
  }
  get extraInformationFrequencyForWithdrawals(): string {
    return this._extraInformationFrequencyForWithdrawals;
  }
  set extraInformationFrequencyForWithdrawals(value: string) {
    this._extraInformationFrequencyForWithdrawals = value;
  }
  get extraInformationManager(): string {
    return this._extraInformationManager;
  }
  set extraInformationManager(value: string) {
    this._extraInformationManager = value;
  }
  get videoExplicationUrl(): string {
    return this._videoExplicationUrl;
  }
  set videoExplicationUrl(value: string) {
    this._videoExplicationUrl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    InvestmentStrategyModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): InvestmentStrategyModel.AsObject {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      code: this.code,
      features: (this.features || []).slice(),
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      financialEntityId: this.financialEntityId,
      isVisible: this.isVisible,
      isDefault: this.isDefault,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      shortTitle: this.shortTitle,
      investmentStrategyTypeId: this.investmentStrategyTypeId,
      investmentStrategyType: this.investmentStrategyType
        ? this.investmentStrategyType.toObject()
        : undefined,
      isRecommended: this.isRecommended,
      iconUrl: this.iconUrl,
      recommendedFor: this.recommendedFor,
      displayOrder: this.displayOrder,
      investmentTypeId: this.investmentTypeId,
      investmentType: this.investmentType
        ? this.investmentType.toObject()
        : undefined,
      isSemiLiquid: this.isSemiLiquid,
      manager: this.manager,
      minimumAmountToInvest: this.minimumAmountToInvest,
      historicalAnnualProfitability: this.historicalAnnualProfitability,
      targetProfitability: this.targetProfitability,
      frequencyForWithdrawals: this.frequencyForWithdrawals,
      extraInformationFrequencyForWithdrawals: this
        .extraInformationFrequencyForWithdrawals,
      extraInformationManager: this.extraInformationManager,
      videoExplicationUrl: this.videoExplicationUrl
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): InvestmentStrategyModel.AsProtobufJSON {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      code: this.code,
      features: (this.features || []).slice(),
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      financialEntityId: this.financialEntityId,
      isVisible: this.isVisible,
      isDefault: this.isDefault,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      shortTitle: this.shortTitle,
      investmentStrategyTypeId: this.investmentStrategyTypeId,
      investmentStrategyType: this.investmentStrategyType
        ? this.investmentStrategyType.toProtobufJSON(options)
        : null,
      isRecommended: this.isRecommended,
      iconUrl: this.iconUrl,
      recommendedFor: this.recommendedFor,
      displayOrder: this.displayOrder,
      investmentTypeId: this.investmentTypeId,
      investmentType: this.investmentType
        ? this.investmentType.toProtobufJSON(options)
        : null,
      isSemiLiquid: this.isSemiLiquid,
      manager: this.manager,
      minimumAmountToInvest: this.minimumAmountToInvest,
      historicalAnnualProfitability: this.historicalAnnualProfitability,
      targetProfitability: this.targetProfitability,
      frequencyForWithdrawals: this.frequencyForWithdrawals,
      extraInformationFrequencyForWithdrawals: this
        .extraInformationFrequencyForWithdrawals,
      extraInformationManager: this.extraInformationManager,
      videoExplicationUrl: this.videoExplicationUrl
    };
  }
}
export module InvestmentStrategyModel {
  /**
   * Standard JavaScript object representation for InvestmentStrategyModel
   */
  export interface AsObject {
    id: number;
    title: string;
    description: string;
    code: string;
    features: string[];
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    financialEntityId: number;
    isVisible: boolean;
    isDefault: boolean;
    financialEntity?: FinancialEntityModel.AsObject;
    shortTitle: string;
    investmentStrategyTypeId: number;
    investmentStrategyType?: InvestmentStrategyTypeModel.AsObject;
    isRecommended: boolean;
    iconUrl: string;
    recommendedFor: string;
    displayOrder: number;
    investmentTypeId: number;
    investmentType?: InvestmentTypeModel.AsObject;
    isSemiLiquid: boolean;
    manager: string;
    minimumAmountToInvest: string;
    historicalAnnualProfitability: string;
    targetProfitability: string;
    frequencyForWithdrawals: string;
    extraInformationFrequencyForWithdrawals: string;
    extraInformationManager: string;
    videoExplicationUrl: string;
  }

  /**
   * Protobuf JSON representation for InvestmentStrategyModel
   */
  export interface AsProtobufJSON {
    id: number;
    title: string;
    description: string;
    code: string;
    features: string[];
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    financialEntityId: number;
    isVisible: boolean;
    isDefault: boolean;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
    shortTitle: string;
    investmentStrategyTypeId: number;
    investmentStrategyType: InvestmentStrategyTypeModel.AsProtobufJSON | null;
    isRecommended: boolean;
    iconUrl: string;
    recommendedFor: string;
    displayOrder: number;
    investmentTypeId: number;
    investmentType: InvestmentTypeModel.AsProtobufJSON | null;
    isSemiLiquid: boolean;
    manager: string;
    minimumAmountToInvest: string;
    historicalAnnualProfitability: string;
    targetProfitability: string;
    frequencyForWithdrawals: string;
    extraInformationFrequencyForWithdrawals: string;
    extraInformationManager: string;
    videoExplicationUrl: string;
  }
}

/**
 * Message implementation for common_message.InvestmentTypeModel
 */
export class InvestmentTypeModel implements GrpcMessage {
  static id = 'common_message.InvestmentTypeModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new InvestmentTypeModel();
    InvestmentTypeModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: InvestmentTypeModel) {
    _instance.id = _instance.id || 0;
    _instance.imageUrl = _instance.imageUrl || '';
    _instance.iconUrl = _instance.iconUrl || '';
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.shortDescription = _instance.shortDescription || '';
    _instance.isVisible = _instance.isVisible || false;
    _instance.isDefault = _instance.isDefault || false;
    _instance.displayOrder = _instance.displayOrder || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.investmentStrategies = _instance.investmentStrategies || [];
    _instance.code = _instance.code || '';
    _instance.uuid = _instance.uuid || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: InvestmentTypeModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.imageUrl = _reader.readString();
          break;
        case 3:
          _instance.iconUrl = _reader.readString();
          break;
        case 4:
          _instance.title = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.shortDescription = _reader.readString();
          break;
        case 7:
          _instance.isVisible = _reader.readBool();
          break;
        case 9:
          _instance.isDefault = _reader.readBool();
          break;
        case 10:
          _instance.displayOrder = _reader.readInt32();
          break;
        case 11:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 13:
          const messageInitializer13 = new InvestmentStrategyModel();
          _reader.readMessage(
            messageInitializer13,
            InvestmentStrategyModel.deserializeBinaryFromReader
          );
          (_instance.investmentStrategies =
            _instance.investmentStrategies || []).push(messageInitializer13);
          break;
        case 14:
          _instance.code = _reader.readString();
          break;
        case 15:
          _instance.uuid = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    InvestmentTypeModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: InvestmentTypeModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.imageUrl) {
      _writer.writeString(2, _instance.imageUrl);
    }
    if (_instance.iconUrl) {
      _writer.writeString(3, _instance.iconUrl);
    }
    if (_instance.title) {
      _writer.writeString(4, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.shortDescription) {
      _writer.writeString(6, _instance.shortDescription);
    }
    if (_instance.isVisible) {
      _writer.writeBool(7, _instance.isVisible);
    }
    if (_instance.isDefault) {
      _writer.writeBool(9, _instance.isDefault);
    }
    if (_instance.displayOrder) {
      _writer.writeInt32(10, _instance.displayOrder);
    }
    if (_instance.created) {
      _writer.writeMessage(
        11,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        12,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (
      _instance.investmentStrategies &&
      _instance.investmentStrategies.length
    ) {
      _writer.writeRepeatedMessage(
        13,
        _instance.investmentStrategies as any,
        InvestmentStrategyModel.serializeBinaryToWriter
      );
    }
    if (_instance.code) {
      _writer.writeString(14, _instance.code);
    }
    if (_instance.uuid) {
      _writer.writeString(15, _instance.uuid);
    }
  }

  private _id: number;
  private _imageUrl: string;
  private _iconUrl: string;
  private _title: string;
  private _description: string;
  private _shortDescription: string;
  private _isVisible: boolean;
  private _isDefault: boolean;
  private _displayOrder: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _investmentStrategies?: InvestmentStrategyModel[];
  private _code: string;
  private _uuid: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of InvestmentTypeModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<InvestmentTypeModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.imageUrl = _value.imageUrl;
    this.iconUrl = _value.iconUrl;
    this.title = _value.title;
    this.description = _value.description;
    this.shortDescription = _value.shortDescription;
    this.isVisible = _value.isVisible;
    this.isDefault = _value.isDefault;
    this.displayOrder = _value.displayOrder;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.investmentStrategies = (_value.investmentStrategies || []).map(
      m => new InvestmentStrategyModel(m)
    );
    this.code = _value.code;
    this.uuid = _value.uuid;
    InvestmentTypeModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get imageUrl(): string {
    return this._imageUrl;
  }
  set imageUrl(value: string) {
    this._imageUrl = value;
  }
  get iconUrl(): string {
    return this._iconUrl;
  }
  set iconUrl(value: string) {
    this._iconUrl = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get shortDescription(): string {
    return this._shortDescription;
  }
  set shortDescription(value: string) {
    this._shortDescription = value;
  }
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
  }
  get isDefault(): boolean {
    return this._isDefault;
  }
  set isDefault(value: boolean) {
    this._isDefault = value;
  }
  get displayOrder(): number {
    return this._displayOrder;
  }
  set displayOrder(value: number) {
    this._displayOrder = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get investmentStrategies(): InvestmentStrategyModel[] | undefined {
    return this._investmentStrategies;
  }
  set investmentStrategies(value: InvestmentStrategyModel[] | undefined) {
    this._investmentStrategies = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    InvestmentTypeModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): InvestmentTypeModel.AsObject {
    return {
      id: this.id,
      imageUrl: this.imageUrl,
      iconUrl: this.iconUrl,
      title: this.title,
      description: this.description,
      shortDescription: this.shortDescription,
      isVisible: this.isVisible,
      isDefault: this.isDefault,
      displayOrder: this.displayOrder,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      investmentStrategies: (this.investmentStrategies || []).map(m =>
        m.toObject()
      ),
      code: this.code,
      uuid: this.uuid
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): InvestmentTypeModel.AsProtobufJSON {
    return {
      id: this.id,
      imageUrl: this.imageUrl,
      iconUrl: this.iconUrl,
      title: this.title,
      description: this.description,
      shortDescription: this.shortDescription,
      isVisible: this.isVisible,
      isDefault: this.isDefault,
      displayOrder: this.displayOrder,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      investmentStrategies: (this.investmentStrategies || []).map(m =>
        m.toProtobufJSON(options)
      ),
      code: this.code,
      uuid: this.uuid
    };
  }
}
export module InvestmentTypeModel {
  /**
   * Standard JavaScript object representation for InvestmentTypeModel
   */
  export interface AsObject {
    id: number;
    imageUrl: string;
    iconUrl: string;
    title: string;
    description: string;
    shortDescription: string;
    isVisible: boolean;
    isDefault: boolean;
    displayOrder: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    investmentStrategies?: InvestmentStrategyModel.AsObject[];
    code: string;
    uuid: string;
  }

  /**
   * Protobuf JSON representation for InvestmentTypeModel
   */
  export interface AsProtobufJSON {
    id: number;
    imageUrl: string;
    iconUrl: string;
    title: string;
    description: string;
    shortDescription: string;
    isVisible: boolean;
    isDefault: boolean;
    displayOrder: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    investmentStrategies: InvestmentStrategyModel.AsProtobufJSON[] | null;
    code: string;
    uuid: string;
  }
}

/**
 * Message implementation for common_message.InvestmentStrategyTypeModel
 */
export class InvestmentStrategyTypeModel implements GrpcMessage {
  static id = 'common_message.InvestmentStrategyTypeModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new InvestmentStrategyTypeModel();
    InvestmentStrategyTypeModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: InvestmentStrategyTypeModel) {
    _instance.id = _instance.id || 0;
    _instance.iconUrl = _instance.iconUrl || '';
    _instance.title = _instance.title || '';
    _instance.shortTitle = _instance.shortTitle || '';
    _instance.description = _instance.description || '';
    _instance.recommendedFor = _instance.recommendedFor || '';
    _instance.isVisible = _instance.isVisible || false;
    _instance.isDefault = _instance.isDefault || false;
    _instance.displayOrder = _instance.displayOrder || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.investmentStrategies = _instance.investmentStrategies || [];
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.financialEntity = _instance.financialEntity || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: InvestmentStrategyTypeModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.iconUrl = _reader.readString();
          break;
        case 3:
          _instance.title = _reader.readString();
          break;
        case 4:
          _instance.shortTitle = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.recommendedFor = _reader.readString();
          break;
        case 7:
          _instance.isVisible = _reader.readBool();
          break;
        case 8:
          _instance.isDefault = _reader.readBool();
          break;
        case 9:
          _instance.displayOrder = _reader.readInt32();
          break;
        case 10:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 12:
          const messageInitializer12 = new InvestmentStrategyModel();
          _reader.readMessage(
            messageInitializer12,
            InvestmentStrategyModel.deserializeBinaryFromReader
          );
          (_instance.investmentStrategies =
            _instance.investmentStrategies || []).push(messageInitializer12);
          break;
        case 13:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 14:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    InvestmentStrategyTypeModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: InvestmentStrategyTypeModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.iconUrl) {
      _writer.writeString(2, _instance.iconUrl);
    }
    if (_instance.title) {
      _writer.writeString(3, _instance.title);
    }
    if (_instance.shortTitle) {
      _writer.writeString(4, _instance.shortTitle);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.recommendedFor) {
      _writer.writeString(6, _instance.recommendedFor);
    }
    if (_instance.isVisible) {
      _writer.writeBool(7, _instance.isVisible);
    }
    if (_instance.isDefault) {
      _writer.writeBool(8, _instance.isDefault);
    }
    if (_instance.displayOrder) {
      _writer.writeInt32(9, _instance.displayOrder);
    }
    if (_instance.created) {
      _writer.writeMessage(
        10,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        11,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (
      _instance.investmentStrategies &&
      _instance.investmentStrategies.length
    ) {
      _writer.writeRepeatedMessage(
        12,
        _instance.investmentStrategies as any,
        InvestmentStrategyModel.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(13, _instance.financialEntityId);
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        14,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _iconUrl: string;
  private _title: string;
  private _shortTitle: string;
  private _description: string;
  private _recommendedFor: string;
  private _isVisible: boolean;
  private _isDefault: boolean;
  private _displayOrder: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _investmentStrategies?: InvestmentStrategyModel[];
  private _financialEntityId: number;
  private _financialEntity?: FinancialEntityModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of InvestmentStrategyTypeModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<InvestmentStrategyTypeModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.iconUrl = _value.iconUrl;
    this.title = _value.title;
    this.shortTitle = _value.shortTitle;
    this.description = _value.description;
    this.recommendedFor = _value.recommendedFor;
    this.isVisible = _value.isVisible;
    this.isDefault = _value.isDefault;
    this.displayOrder = _value.displayOrder;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.investmentStrategies = (_value.investmentStrategies || []).map(
      m => new InvestmentStrategyModel(m)
    );
    this.financialEntityId = _value.financialEntityId;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    InvestmentStrategyTypeModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get iconUrl(): string {
    return this._iconUrl;
  }
  set iconUrl(value: string) {
    this._iconUrl = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get shortTitle(): string {
    return this._shortTitle;
  }
  set shortTitle(value: string) {
    this._shortTitle = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get recommendedFor(): string {
    return this._recommendedFor;
  }
  set recommendedFor(value: string) {
    this._recommendedFor = value;
  }
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
  }
  get isDefault(): boolean {
    return this._isDefault;
  }
  set isDefault(value: boolean) {
    this._isDefault = value;
  }
  get displayOrder(): number {
    return this._displayOrder;
  }
  set displayOrder(value: number) {
    this._displayOrder = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get investmentStrategies(): InvestmentStrategyModel[] | undefined {
    return this._investmentStrategies;
  }
  set investmentStrategies(value: InvestmentStrategyModel[] | undefined) {
    this._investmentStrategies = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    InvestmentStrategyTypeModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): InvestmentStrategyTypeModel.AsObject {
    return {
      id: this.id,
      iconUrl: this.iconUrl,
      title: this.title,
      shortTitle: this.shortTitle,
      description: this.description,
      recommendedFor: this.recommendedFor,
      isVisible: this.isVisible,
      isDefault: this.isDefault,
      displayOrder: this.displayOrder,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      investmentStrategies: (this.investmentStrategies || []).map(m =>
        m.toObject()
      ),
      financialEntityId: this.financialEntityId,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): InvestmentStrategyTypeModel.AsProtobufJSON {
    return {
      id: this.id,
      iconUrl: this.iconUrl,
      title: this.title,
      shortTitle: this.shortTitle,
      description: this.description,
      recommendedFor: this.recommendedFor,
      isVisible: this.isVisible,
      isDefault: this.isDefault,
      displayOrder: this.displayOrder,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      investmentStrategies: (this.investmentStrategies || []).map(m =>
        m.toProtobufJSON(options)
      ),
      financialEntityId: this.financialEntityId,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null
    };
  }
}
export module InvestmentStrategyTypeModel {
  /**
   * Standard JavaScript object representation for InvestmentStrategyTypeModel
   */
  export interface AsObject {
    id: number;
    iconUrl: string;
    title: string;
    shortTitle: string;
    description: string;
    recommendedFor: string;
    isVisible: boolean;
    isDefault: boolean;
    displayOrder: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    investmentStrategies?: InvestmentStrategyModel.AsObject[];
    financialEntityId: number;
    financialEntity?: FinancialEntityModel.AsObject;
  }

  /**
   * Protobuf JSON representation for InvestmentStrategyTypeModel
   */
  export interface AsProtobufJSON {
    id: number;
    iconUrl: string;
    title: string;
    shortTitle: string;
    description: string;
    recommendedFor: string;
    isVisible: boolean;
    isDefault: boolean;
    displayOrder: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    investmentStrategies: InvestmentStrategyModel.AsProtobufJSON[] | null;
    financialEntityId: number;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.BalanceNetDepositGraphDetailModel
 */
export class BalanceNetDepositGraphDetailModel implements GrpcMessage {
  static id = 'common_message.BalanceNetDepositGraphDetailModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BalanceNetDepositGraphDetailModel();
    BalanceNetDepositGraphDetailModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BalanceNetDepositGraphDetailModel) {
    _instance.initialBalance = _instance.initialBalance || undefined;
    _instance.finalBalance = _instance.finalBalance || 0;
    _instance.netDeposits = _instance.netDeposits || 0;
    _instance.deposits = _instance.deposits || 0;
    _instance.withdrawals = _instance.withdrawals || 0;
    _instance.values = _instance.values || [];
    _instance.dateInitialBalance = _instance.dateInitialBalance || undefined;
    _instance.dateFinalBalance = _instance.dateFinalBalance || undefined;
    _instance.fromDateFilter = _instance.fromDateFilter || undefined;
    _instance.toDateFilter = _instance.toDateFilter || undefined;
    _instance.currency = _instance.currency || undefined;
    _instance.depositsLength = _instance.depositsLength || 0;
    _instance.withdrawalsLength = _instance.withdrawalsLength || 0;
    _instance.variation = _instance.variation || 0;
    _instance.dividensByCurrency = _instance.dividensByCurrency || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BalanceNetDepositGraphDetailModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.initialBalance = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.initialBalance,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.finalBalance = _reader.readInt32();
          break;
        case 3:
          _instance.netDeposits = _reader.readInt32();
          break;
        case 4:
          _instance.deposits = _reader.readInt32();
          break;
        case 5:
          _instance.withdrawals = _reader.readInt32();
          break;
        case 6:
          const messageInitializer6 = new BalanceNetDepositGraphModel();
          _reader.readMessage(
            messageInitializer6,
            BalanceNetDepositGraphModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer6);
          break;
        case 7:
          _instance.dateInitialBalance = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateInitialBalance,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.dateFinalBalance = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateFinalBalance,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.fromDateFilter = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.fromDateFilter,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.toDateFilter = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.toDateFilter,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.depositsLength = _reader.readInt32();
          break;
        case 13:
          _instance.withdrawalsLength = _reader.readInt32();
          break;
        case 14:
          _instance.variation = _reader.readInt32();
          break;
        case 15:
          const messageInitializer15 = new GoalTransactionModel();
          _reader.readMessage(
            messageInitializer15,
            GoalTransactionModel.deserializeBinaryFromReader
          );
          (_instance.dividensByCurrency =
            _instance.dividensByCurrency || []).push(messageInitializer15);
          break;
        default:
          _reader.skipField();
      }
    }

    BalanceNetDepositGraphDetailModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BalanceNetDepositGraphDetailModel,
    _writer: BinaryWriter
  ) {
    if (_instance.initialBalance) {
      _writer.writeMessage(
        1,
        _instance.initialBalance as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.finalBalance) {
      _writer.writeInt32(2, _instance.finalBalance);
    }
    if (_instance.netDeposits) {
      _writer.writeInt32(3, _instance.netDeposits);
    }
    if (_instance.deposits) {
      _writer.writeInt32(4, _instance.deposits);
    }
    if (_instance.withdrawals) {
      _writer.writeInt32(5, _instance.withdrawals);
    }
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.values as any,
        BalanceNetDepositGraphModel.serializeBinaryToWriter
      );
    }
    if (_instance.dateInitialBalance) {
      _writer.writeMessage(
        7,
        _instance.dateInitialBalance as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.dateFinalBalance) {
      _writer.writeMessage(
        8,
        _instance.dateFinalBalance as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.fromDateFilter) {
      _writer.writeMessage(
        9,
        _instance.fromDateFilter as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.toDateFilter) {
      _writer.writeMessage(
        10,
        _instance.toDateFilter as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.currency) {
      _writer.writeMessage(
        11,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.depositsLength) {
      _writer.writeInt32(12, _instance.depositsLength);
    }
    if (_instance.withdrawalsLength) {
      _writer.writeInt32(13, _instance.withdrawalsLength);
    }
    if (_instance.variation) {
      _writer.writeInt32(14, _instance.variation);
    }
    if (_instance.dividensByCurrency && _instance.dividensByCurrency.length) {
      _writer.writeRepeatedMessage(
        15,
        _instance.dividensByCurrency as any,
        GoalTransactionModel.serializeBinaryToWriter
      );
    }
  }

  private _initialBalance?: googleProtobuf000.Int32Value;
  private _finalBalance: number;
  private _netDeposits: number;
  private _deposits: number;
  private _withdrawals: number;
  private _values?: BalanceNetDepositGraphModel[];
  private _dateInitialBalance?: googleProtobuf001.Timestamp;
  private _dateFinalBalance?: googleProtobuf001.Timestamp;
  private _fromDateFilter?: googleProtobuf001.Timestamp;
  private _toDateFilter?: googleProtobuf001.Timestamp;
  private _currency?: CurrencyModel;
  private _depositsLength: number;
  private _withdrawalsLength: number;
  private _variation: number;
  private _dividensByCurrency?: GoalTransactionModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BalanceNetDepositGraphDetailModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<BalanceNetDepositGraphDetailModel.AsObject>
  ) {
    _value = _value || {};
    this.initialBalance = _value.initialBalance
      ? new googleProtobuf000.Int32Value(_value.initialBalance)
      : undefined;
    this.finalBalance = _value.finalBalance;
    this.netDeposits = _value.netDeposits;
    this.deposits = _value.deposits;
    this.withdrawals = _value.withdrawals;
    this.values = (_value.values || []).map(
      m => new BalanceNetDepositGraphModel(m)
    );
    this.dateInitialBalance = _value.dateInitialBalance
      ? new googleProtobuf001.Timestamp(_value.dateInitialBalance)
      : undefined;
    this.dateFinalBalance = _value.dateFinalBalance
      ? new googleProtobuf001.Timestamp(_value.dateFinalBalance)
      : undefined;
    this.fromDateFilter = _value.fromDateFilter
      ? new googleProtobuf001.Timestamp(_value.fromDateFilter)
      : undefined;
    this.toDateFilter = _value.toDateFilter
      ? new googleProtobuf001.Timestamp(_value.toDateFilter)
      : undefined;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.depositsLength = _value.depositsLength;
    this.withdrawalsLength = _value.withdrawalsLength;
    this.variation = _value.variation;
    this.dividensByCurrency = (_value.dividensByCurrency || []).map(
      m => new GoalTransactionModel(m)
    );
    BalanceNetDepositGraphDetailModel.refineValues(this);
  }
  get initialBalance(): googleProtobuf000.Int32Value | undefined {
    return this._initialBalance;
  }
  set initialBalance(value: googleProtobuf000.Int32Value | undefined) {
    this._initialBalance = value;
  }
  get finalBalance(): number {
    return this._finalBalance;
  }
  set finalBalance(value: number) {
    this._finalBalance = value;
  }
  get netDeposits(): number {
    return this._netDeposits;
  }
  set netDeposits(value: number) {
    this._netDeposits = value;
  }
  get deposits(): number {
    return this._deposits;
  }
  set deposits(value: number) {
    this._deposits = value;
  }
  get withdrawals(): number {
    return this._withdrawals;
  }
  set withdrawals(value: number) {
    this._withdrawals = value;
  }
  get values(): BalanceNetDepositGraphModel[] | undefined {
    return this._values;
  }
  set values(value: BalanceNetDepositGraphModel[] | undefined) {
    this._values = value;
  }
  get dateInitialBalance(): googleProtobuf001.Timestamp | undefined {
    return this._dateInitialBalance;
  }
  set dateInitialBalance(value: googleProtobuf001.Timestamp | undefined) {
    this._dateInitialBalance = value;
  }
  get dateFinalBalance(): googleProtobuf001.Timestamp | undefined {
    return this._dateFinalBalance;
  }
  set dateFinalBalance(value: googleProtobuf001.Timestamp | undefined) {
    this._dateFinalBalance = value;
  }
  get fromDateFilter(): googleProtobuf001.Timestamp | undefined {
    return this._fromDateFilter;
  }
  set fromDateFilter(value: googleProtobuf001.Timestamp | undefined) {
    this._fromDateFilter = value;
  }
  get toDateFilter(): googleProtobuf001.Timestamp | undefined {
    return this._toDateFilter;
  }
  set toDateFilter(value: googleProtobuf001.Timestamp | undefined) {
    this._toDateFilter = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get depositsLength(): number {
    return this._depositsLength;
  }
  set depositsLength(value: number) {
    this._depositsLength = value;
  }
  get withdrawalsLength(): number {
    return this._withdrawalsLength;
  }
  set withdrawalsLength(value: number) {
    this._withdrawalsLength = value;
  }
  get variation(): number {
    return this._variation;
  }
  set variation(value: number) {
    this._variation = value;
  }
  get dividensByCurrency(): GoalTransactionModel[] | undefined {
    return this._dividensByCurrency;
  }
  set dividensByCurrency(value: GoalTransactionModel[] | undefined) {
    this._dividensByCurrency = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BalanceNetDepositGraphDetailModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BalanceNetDepositGraphDetailModel.AsObject {
    return {
      initialBalance: this.initialBalance
        ? this.initialBalance.toObject()
        : undefined,
      finalBalance: this.finalBalance,
      netDeposits: this.netDeposits,
      deposits: this.deposits,
      withdrawals: this.withdrawals,
      values: (this.values || []).map(m => m.toObject()),
      dateInitialBalance: this.dateInitialBalance
        ? this.dateInitialBalance.toObject()
        : undefined,
      dateFinalBalance: this.dateFinalBalance
        ? this.dateFinalBalance.toObject()
        : undefined,
      fromDateFilter: this.fromDateFilter
        ? this.fromDateFilter.toObject()
        : undefined,
      toDateFilter: this.toDateFilter
        ? this.toDateFilter.toObject()
        : undefined,
      currency: this.currency ? this.currency.toObject() : undefined,
      depositsLength: this.depositsLength,
      withdrawalsLength: this.withdrawalsLength,
      variation: this.variation,
      dividensByCurrency: (this.dividensByCurrency || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BalanceNetDepositGraphDetailModel.AsProtobufJSON {
    return {
      initialBalance: this.initialBalance
        ? this.initialBalance.toProtobufJSON(options)
        : null,
      finalBalance: this.finalBalance,
      netDeposits: this.netDeposits,
      deposits: this.deposits,
      withdrawals: this.withdrawals,
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      dateInitialBalance: this.dateInitialBalance
        ? this.dateInitialBalance.toProtobufJSON(options)
        : null,
      dateFinalBalance: this.dateFinalBalance
        ? this.dateFinalBalance.toProtobufJSON(options)
        : null,
      fromDateFilter: this.fromDateFilter
        ? this.fromDateFilter.toProtobufJSON(options)
        : null,
      toDateFilter: this.toDateFilter
        ? this.toDateFilter.toProtobufJSON(options)
        : null,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      depositsLength: this.depositsLength,
      withdrawalsLength: this.withdrawalsLength,
      variation: this.variation,
      dividensByCurrency: (this.dividensByCurrency || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module BalanceNetDepositGraphDetailModel {
  /**
   * Standard JavaScript object representation for BalanceNetDepositGraphDetailModel
   */
  export interface AsObject {
    initialBalance?: googleProtobuf000.Int32Value.AsObject;
    finalBalance: number;
    netDeposits: number;
    deposits: number;
    withdrawals: number;
    values?: BalanceNetDepositGraphModel.AsObject[];
    dateInitialBalance?: googleProtobuf001.Timestamp.AsObject;
    dateFinalBalance?: googleProtobuf001.Timestamp.AsObject;
    fromDateFilter?: googleProtobuf001.Timestamp.AsObject;
    toDateFilter?: googleProtobuf001.Timestamp.AsObject;
    currency?: CurrencyModel.AsObject;
    depositsLength: number;
    withdrawalsLength: number;
    variation: number;
    dividensByCurrency?: GoalTransactionModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for BalanceNetDepositGraphDetailModel
   */
  export interface AsProtobufJSON {
    initialBalance: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    finalBalance: number;
    netDeposits: number;
    deposits: number;
    withdrawals: number;
    values: BalanceNetDepositGraphModel.AsProtobufJSON[] | null;
    dateInitialBalance: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    dateFinalBalance: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    fromDateFilter: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    toDateFilter: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    currency: CurrencyModel.AsProtobufJSON | null;
    depositsLength: number;
    withdrawalsLength: number;
    variation: number;
    dividensByCurrency: GoalTransactionModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for common_message.BalanceNetDepositGraphModel
 */
export class BalanceNetDepositGraphModel implements GrpcMessage {
  static id = 'common_message.BalanceNetDepositGraphModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BalanceNetDepositGraphModel();
    BalanceNetDepositGraphModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BalanceNetDepositGraphModel) {
    _instance.date = _instance.date || undefined;
    _instance.balance = _instance.balance || 0;
    _instance.netDeposit = _instance.netDeposit || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BalanceNetDepositGraphModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.balance = _reader.readInt32();
          break;
        case 3:
          _instance.netDeposit = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    BalanceNetDepositGraphModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BalanceNetDepositGraphModel,
    _writer: BinaryWriter
  ) {
    if (_instance.date) {
      _writer.writeMessage(
        1,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.balance) {
      _writer.writeInt32(2, _instance.balance);
    }
    if (_instance.netDeposit) {
      _writer.writeInt32(3, _instance.netDeposit);
    }
  }

  private _date?: googleProtobuf001.Timestamp;
  private _balance: number;
  private _netDeposit: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BalanceNetDepositGraphModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BalanceNetDepositGraphModel.AsObject>) {
    _value = _value || {};
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.balance = _value.balance;
    this.netDeposit = _value.netDeposit;
    BalanceNetDepositGraphModel.refineValues(this);
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get balance(): number {
    return this._balance;
  }
  set balance(value: number) {
    this._balance = value;
  }
  get netDeposit(): number {
    return this._netDeposit;
  }
  set netDeposit(value: number) {
    this._netDeposit = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BalanceNetDepositGraphModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BalanceNetDepositGraphModel.AsObject {
    return {
      date: this.date ? this.date.toObject() : undefined,
      balance: this.balance,
      netDeposit: this.netDeposit
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BalanceNetDepositGraphModel.AsProtobufJSON {
    return {
      date: this.date ? this.date.toProtobufJSON(options) : null,
      balance: this.balance,
      netDeposit: this.netDeposit
    };
  }
}
export module BalanceNetDepositGraphModel {
  /**
   * Standard JavaScript object representation for BalanceNetDepositGraphModel
   */
  export interface AsObject {
    date?: googleProtobuf001.Timestamp.AsObject;
    balance: number;
    netDeposit: number;
  }

  /**
   * Protobuf JSON representation for BalanceNetDepositGraphModel
   */
  export interface AsProtobufJSON {
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    balance: number;
    netDeposit: number;
  }
}

/**
 * Message implementation for common_message.TransactionGoalRequestModel
 */
export class TransactionGoalRequestModel implements GrpcMessage {
  static id = 'common_message.TransactionGoalRequestModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TransactionGoalRequestModel();
    TransactionGoalRequestModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TransactionGoalRequestModel) {
    _instance.amount = _instance.amount || 0;
    _instance.percentage = _instance.percentage || 0;
    _instance.state = _instance.state || 0;
    _instance.goalId = _instance.goalId || 0;
    _instance.goalTransactionId = _instance.goalTransactionId || undefined;
    _instance.transactionRequestId = _instance.transactionRequestId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.id = _instance.id || 0;
    _instance.goal = _instance.goal || undefined;
    _instance.goalTransaction = _instance.goalTransaction || undefined;
    _instance.type = _instance.type || 0;
    _instance.portfolioId = _instance.portfolioId || 0;
    _instance.portfolio = _instance.portfolio || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TransactionGoalRequestModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readDouble();
          break;
        case 2:
          _instance.percentage = _reader.readInt32();
          break;
        case 3:
          _instance.state = _reader.readEnum();
          break;
        case 4:
          _instance.goalId = _reader.readInt32();
          break;
        case 5:
          _instance.goalTransactionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalTransactionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.transactionRequestId = _reader.readInt32();
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.id = _reader.readInt32();
          break;
        case 10:
          _instance.goal = new GoalModel();
          _reader.readMessage(
            _instance.goal,
            GoalModel.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.goalTransaction = new GoalTransactionModel();
          _reader.readMessage(
            _instance.goalTransaction,
            GoalTransactionModel.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.type = _reader.readEnum();
          break;
        case 13:
          _instance.portfolioId = _reader.readInt32();
          break;
        case 14:
          _instance.portfolio = new PortfolioModel();
          _reader.readMessage(
            _instance.portfolio,
            PortfolioModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TransactionGoalRequestModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TransactionGoalRequestModel,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeDouble(1, _instance.amount);
    }
    if (_instance.percentage) {
      _writer.writeInt32(2, _instance.percentage);
    }
    if (_instance.state) {
      _writer.writeEnum(3, _instance.state);
    }
    if (_instance.goalId) {
      _writer.writeInt32(4, _instance.goalId);
    }
    if (_instance.goalTransactionId) {
      _writer.writeMessage(
        5,
        _instance.goalTransactionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.transactionRequestId) {
      _writer.writeInt32(6, _instance.transactionRequestId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(9, _instance.id);
    }
    if (_instance.goal) {
      _writer.writeMessage(
        10,
        _instance.goal as any,
        GoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.goalTransaction) {
      _writer.writeMessage(
        11,
        _instance.goalTransaction as any,
        GoalTransactionModel.serializeBinaryToWriter
      );
    }
    if (_instance.type) {
      _writer.writeEnum(12, _instance.type);
    }
    if (_instance.portfolioId) {
      _writer.writeInt32(13, _instance.portfolioId);
    }
    if (_instance.portfolio) {
      _writer.writeMessage(
        14,
        _instance.portfolio as any,
        PortfolioModel.serializeBinaryToWriter
      );
    }
  }

  private _amount: number;
  private _percentage: number;
  private _state: goal_transaction_enums002.GoalTransactionState;
  private _goalId: number;
  private _goalTransactionId?: googleProtobuf000.Int32Value;
  private _transactionRequestId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _id: number;
  private _goal?: GoalModel;
  private _goalTransaction?: GoalTransactionModel;
  private _type: goal_transaction_enums002.TransactionGoalRequestType;
  private _portfolioId: number;
  private _portfolio?: PortfolioModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TransactionGoalRequestModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<TransactionGoalRequestModel.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.percentage = _value.percentage;
    this.state = _value.state;
    this.goalId = _value.goalId;
    this.goalTransactionId = _value.goalTransactionId
      ? new googleProtobuf000.Int32Value(_value.goalTransactionId)
      : undefined;
    this.transactionRequestId = _value.transactionRequestId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.id = _value.id;
    this.goal = _value.goal ? new GoalModel(_value.goal) : undefined;
    this.goalTransaction = _value.goalTransaction
      ? new GoalTransactionModel(_value.goalTransaction)
      : undefined;
    this.type = _value.type;
    this.portfolioId = _value.portfolioId;
    this.portfolio = _value.portfolio
      ? new PortfolioModel(_value.portfolio)
      : undefined;
    TransactionGoalRequestModel.refineValues(this);
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }
  get goalTransactionId(): googleProtobuf000.Int32Value | undefined {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalTransactionId = value;
  }
  get transactionRequestId(): number {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: number) {
    this._transactionRequestId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get goal(): GoalModel | undefined {
    return this._goal;
  }
  set goal(value: GoalModel | undefined) {
    this._goal = value;
  }
  get goalTransaction(): GoalTransactionModel | undefined {
    return this._goalTransaction;
  }
  set goalTransaction(value: GoalTransactionModel | undefined) {
    this._goalTransaction = value;
  }
  get type(): goal_transaction_enums002.TransactionGoalRequestType {
    return this._type;
  }
  set type(value: goal_transaction_enums002.TransactionGoalRequestType) {
    this._type = value;
  }
  get portfolioId(): number {
    return this._portfolioId;
  }
  set portfolioId(value: number) {
    this._portfolioId = value;
  }
  get portfolio(): PortfolioModel | undefined {
    return this._portfolio;
  }
  set portfolio(value: PortfolioModel | undefined) {
    this._portfolio = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TransactionGoalRequestModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TransactionGoalRequestModel.AsObject {
    return {
      amount: this.amount,
      percentage: this.percentage,
      state: this.state,
      goalId: this.goalId,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toObject()
        : undefined,
      transactionRequestId: this.transactionRequestId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      id: this.id,
      goal: this.goal ? this.goal.toObject() : undefined,
      goalTransaction: this.goalTransaction
        ? this.goalTransaction.toObject()
        : undefined,
      type: this.type,
      portfolioId: this.portfolioId,
      portfolio: this.portfolio ? this.portfolio.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TransactionGoalRequestModel.AsProtobufJSON {
    return {
      amount: this.amount,
      percentage: this.percentage,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      goalId: this.goalId,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toProtobufJSON(options)
        : null,
      transactionRequestId: this.transactionRequestId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      id: this.id,
      goal: this.goal ? this.goal.toProtobufJSON(options) : null,
      goalTransaction: this.goalTransaction
        ? this.goalTransaction.toProtobufJSON(options)
        : null,
      type:
        goal_transaction_enums002.TransactionGoalRequestType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      portfolioId: this.portfolioId,
      portfolio: this.portfolio ? this.portfolio.toProtobufJSON(options) : null
    };
  }
}
export module TransactionGoalRequestModel {
  /**
   * Standard JavaScript object representation for TransactionGoalRequestModel
   */
  export interface AsObject {
    amount: number;
    percentage: number;
    state: goal_transaction_enums002.GoalTransactionState;
    goalId: number;
    goalTransactionId?: googleProtobuf000.Int32Value.AsObject;
    transactionRequestId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    id: number;
    goal?: GoalModel.AsObject;
    goalTransaction?: GoalTransactionModel.AsObject;
    type: goal_transaction_enums002.TransactionGoalRequestType;
    portfolioId: number;
    portfolio?: PortfolioModel.AsObject;
  }

  /**
   * Protobuf JSON representation for TransactionGoalRequestModel
   */
  export interface AsProtobufJSON {
    amount: number;
    percentage: number;
    state: string;
    goalId: number;
    goalTransactionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    transactionRequestId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    id: number;
    goal: GoalModel.AsProtobufJSON | null;
    goalTransaction: GoalTransactionModel.AsProtobufJSON | null;
    type: string;
    portfolioId: number;
    portfolio: PortfolioModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.TransactionRequestModel
 */
export class TransactionRequestModel implements GrpcMessage {
  static id = 'common_message.TransactionRequestModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TransactionRequestModel();
    TransactionRequestModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TransactionRequestModel) {
    _instance.type = _instance.type || 0;
    _instance.amount = _instance.amount || 0;
    _instance.state = _instance.state || 0;
    _instance.date = _instance.date || undefined;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.userId = _instance.userId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.id = _instance.id || 0;
    _instance.bankTransactionId = _instance.bankTransactionId || undefined;
    _instance.user = _instance.user || undefined;
    _instance.transactionGoalRequests = _instance.transactionGoalRequests || [];
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.identifier = _instance.identifier || '';
    _instance.bankTransaction = _instance.bankTransaction || undefined;
    _instance.userBankAccount = _instance.userBankAccount || undefined;
    _instance.originType = _instance.originType || 0;
    _instance.transactionState = _instance.transactionState || 0;
    _instance.notifyUser = _instance.notifyUser || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TransactionRequestModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.amount = _reader.readDouble();
          break;
        case 3:
          _instance.state = _reader.readEnum();
          break;
        case 4:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 6:
          _instance.userId = _reader.readInt32();
          break;
        case 9:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.id = _reader.readInt32();
          break;
        case 12:
          _instance.bankTransactionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.bankTransactionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.user = new UserModel();
          _reader.readMessage(
            _instance.user,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 16:
          const messageInitializer16 = new TransactionGoalRequestModel();
          _reader.readMessage(
            messageInitializer16,
            TransactionGoalRequestModel.deserializeBinaryFromReader
          );
          (_instance.transactionGoalRequests =
            _instance.transactionGoalRequests || []).push(messageInitializer16);
          break;
        case 17:
          _instance.currencyId = _reader.readInt32();
          break;
        case 18:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.identifier = _reader.readString();
          break;
        case 20:
          _instance.bankTransaction = new BankTransactionModel();
          _reader.readMessage(
            _instance.bankTransaction,
            BankTransactionModel.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.userBankAccount = new UserBankAccountModel();
          _reader.readMessage(
            _instance.userBankAccount,
            UserBankAccountModel.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.originType = _reader.readEnum();
          break;
        case 23:
          _instance.transactionState = _reader.readEnum();
          break;
        case 24:
          _instance.notifyUser = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    TransactionRequestModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TransactionRequestModel,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.amount) {
      _writer.writeDouble(2, _instance.amount);
    }
    if (_instance.state) {
      _writer.writeEnum(3, _instance.state);
    }
    if (_instance.date) {
      _writer.writeMessage(
        4,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(5, _instance.financialEntityId);
    }
    if (_instance.userId) {
      _writer.writeInt32(6, _instance.userId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        9,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        10,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(11, _instance.id);
    }
    if (_instance.bankTransactionId) {
      _writer.writeMessage(
        12,
        _instance.bankTransactionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.user) {
      _writer.writeMessage(
        15,
        _instance.user as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (
      _instance.transactionGoalRequests &&
      _instance.transactionGoalRequests.length
    ) {
      _writer.writeRepeatedMessage(
        16,
        _instance.transactionGoalRequests as any,
        TransactionGoalRequestModel.serializeBinaryToWriter
      );
    }
    if (_instance.currencyId) {
      _writer.writeInt32(17, _instance.currencyId);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        18,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.identifier) {
      _writer.writeString(19, _instance.identifier);
    }
    if (_instance.bankTransaction) {
      _writer.writeMessage(
        20,
        _instance.bankTransaction as any,
        BankTransactionModel.serializeBinaryToWriter
      );
    }
    if (_instance.userBankAccount) {
      _writer.writeMessage(
        21,
        _instance.userBankAccount as any,
        UserBankAccountModel.serializeBinaryToWriter
      );
    }
    if (_instance.originType) {
      _writer.writeEnum(22, _instance.originType);
    }
    if (_instance.transactionState) {
      _writer.writeEnum(23, _instance.transactionState);
    }
    if (_instance.notifyUser) {
      _writer.writeBool(24, _instance.notifyUser);
    }
  }

  private _type: goal_transaction_enums002.TransactionRequestType;
  private _amount: number;
  private _state: goal_transaction_enums002.GoalTransactionState;
  private _date?: googleProtobuf001.Timestamp;
  private _financialEntityId: number;
  private _userId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _id: number;
  private _bankTransactionId?: googleProtobuf000.Int32Value;
  private _user?: UserModel;
  private _transactionGoalRequests?: TransactionGoalRequestModel[];
  private _currencyId: number;
  private _currency?: CurrencyModel;
  private _identifier: string;
  private _bankTransaction?: BankTransactionModel;
  private _userBankAccount?: UserBankAccountModel;
  private _originType: goal_transaction_origin_type_enum005.OriginType;
  private _transactionState: goal_transaction_enums002.TransactionRequestState;
  private _notifyUser: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TransactionRequestModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<TransactionRequestModel.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.amount = _value.amount;
    this.state = _value.state;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.financialEntityId = _value.financialEntityId;
    this.userId = _value.userId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.id = _value.id;
    this.bankTransactionId = _value.bankTransactionId
      ? new googleProtobuf000.Int32Value(_value.bankTransactionId)
      : undefined;
    this.user = _value.user ? new UserModel(_value.user) : undefined;
    this.transactionGoalRequests = (_value.transactionGoalRequests || []).map(
      m => new TransactionGoalRequestModel(m)
    );
    this.currencyId = _value.currencyId;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.identifier = _value.identifier;
    this.bankTransaction = _value.bankTransaction
      ? new BankTransactionModel(_value.bankTransaction)
      : undefined;
    this.userBankAccount = _value.userBankAccount
      ? new UserBankAccountModel(_value.userBankAccount)
      : undefined;
    this.originType = _value.originType;
    this.transactionState = _value.transactionState;
    this.notifyUser = _value.notifyUser;
    TransactionRequestModel.refineValues(this);
  }
  get type(): goal_transaction_enums002.TransactionRequestType {
    return this._type;
  }
  set type(value: goal_transaction_enums002.TransactionRequestType) {
    this._type = value;
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get bankTransactionId(): googleProtobuf000.Int32Value | undefined {
    return this._bankTransactionId;
  }
  set bankTransactionId(value: googleProtobuf000.Int32Value | undefined) {
    this._bankTransactionId = value;
  }
  get user(): UserModel | undefined {
    return this._user;
  }
  set user(value: UserModel | undefined) {
    this._user = value;
  }
  get transactionGoalRequests(): TransactionGoalRequestModel[] | undefined {
    return this._transactionGoalRequests;
  }
  set transactionGoalRequests(
    value: TransactionGoalRequestModel[] | undefined
  ) {
    this._transactionGoalRequests = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get identifier(): string {
    return this._identifier;
  }
  set identifier(value: string) {
    this._identifier = value;
  }
  get bankTransaction(): BankTransactionModel | undefined {
    return this._bankTransaction;
  }
  set bankTransaction(value: BankTransactionModel | undefined) {
    this._bankTransaction = value;
  }
  get userBankAccount(): UserBankAccountModel | undefined {
    return this._userBankAccount;
  }
  set userBankAccount(value: UserBankAccountModel | undefined) {
    this._userBankAccount = value;
  }
  get originType(): goal_transaction_origin_type_enum005.OriginType {
    return this._originType;
  }
  set originType(value: goal_transaction_origin_type_enum005.OriginType) {
    this._originType = value;
  }
  get transactionState(): goal_transaction_enums002.TransactionRequestState {
    return this._transactionState;
  }
  set transactionState(
    value: goal_transaction_enums002.TransactionRequestState
  ) {
    this._transactionState = value;
  }
  get notifyUser(): boolean {
    return this._notifyUser;
  }
  set notifyUser(value: boolean) {
    this._notifyUser = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TransactionRequestModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TransactionRequestModel.AsObject {
    return {
      type: this.type,
      amount: this.amount,
      state: this.state,
      date: this.date ? this.date.toObject() : undefined,
      financialEntityId: this.financialEntityId,
      userId: this.userId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      id: this.id,
      bankTransactionId: this.bankTransactionId
        ? this.bankTransactionId.toObject()
        : undefined,
      user: this.user ? this.user.toObject() : undefined,
      transactionGoalRequests: (this.transactionGoalRequests || []).map(m =>
        m.toObject()
      ),
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toObject() : undefined,
      identifier: this.identifier,
      bankTransaction: this.bankTransaction
        ? this.bankTransaction.toObject()
        : undefined,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toObject()
        : undefined,
      originType: this.originType,
      transactionState: this.transactionState,
      notifyUser: this.notifyUser
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TransactionRequestModel.AsProtobufJSON {
    return {
      type:
        goal_transaction_enums002.TransactionRequestType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      amount: this.amount,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      date: this.date ? this.date.toProtobufJSON(options) : null,
      financialEntityId: this.financialEntityId,
      userId: this.userId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      id: this.id,
      bankTransactionId: this.bankTransactionId
        ? this.bankTransactionId.toProtobufJSON(options)
        : null,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      transactionGoalRequests: (this.transactionGoalRequests || []).map(m =>
        m.toProtobufJSON(options)
      ),
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      identifier: this.identifier,
      bankTransaction: this.bankTransaction
        ? this.bankTransaction.toProtobufJSON(options)
        : null,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toProtobufJSON(options)
        : null,
      originType:
        goal_transaction_origin_type_enum005.OriginType[
          this.originType === null || this.originType === undefined
            ? 0
            : this.originType
        ],
      transactionState:
        goal_transaction_enums002.TransactionRequestState[
          this.transactionState === null || this.transactionState === undefined
            ? 0
            : this.transactionState
        ],
      notifyUser: this.notifyUser
    };
  }
}
export module TransactionRequestModel {
  /**
   * Standard JavaScript object representation for TransactionRequestModel
   */
  export interface AsObject {
    type: goal_transaction_enums002.TransactionRequestType;
    amount: number;
    state: goal_transaction_enums002.GoalTransactionState;
    date?: googleProtobuf001.Timestamp.AsObject;
    financialEntityId: number;
    userId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    id: number;
    bankTransactionId?: googleProtobuf000.Int32Value.AsObject;
    user?: UserModel.AsObject;
    transactionGoalRequests?: TransactionGoalRequestModel.AsObject[];
    currencyId: number;
    currency?: CurrencyModel.AsObject;
    identifier: string;
    bankTransaction?: BankTransactionModel.AsObject;
    userBankAccount?: UserBankAccountModel.AsObject;
    originType: goal_transaction_origin_type_enum005.OriginType;
    transactionState: goal_transaction_enums002.TransactionRequestState;
    notifyUser: boolean;
  }

  /**
   * Protobuf JSON representation for TransactionRequestModel
   */
  export interface AsProtobufJSON {
    type: string;
    amount: number;
    state: string;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    financialEntityId: number;
    userId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    id: number;
    bankTransactionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    user: UserModel.AsProtobufJSON | null;
    transactionGoalRequests:
      | TransactionGoalRequestModel.AsProtobufJSON[]
      | null;
    currencyId: number;
    currency: CurrencyModel.AsProtobufJSON | null;
    identifier: string;
    bankTransaction: BankTransactionModel.AsProtobufJSON | null;
    userBankAccount: UserBankAccountModel.AsProtobufJSON | null;
    originType: string;
    transactionState: string;
    notifyUser: boolean;
  }
}

/**
 * Message implementation for common_message.BankAccount
 */
export class BankAccount implements GrpcMessage {
  static id = 'common_message.BankAccount';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BankAccount();
    BankAccount.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BankAccount) {
    _instance.bank = _instance.bank || 0;
    _instance.type = _instance.type || 0;
    _instance.number = _instance.number || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BankAccount,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.bank = _reader.readEnum();
          break;
        case 2:
          _instance.type = _reader.readEnum();
          break;
        case 3:
          _instance.number = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BankAccount.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BankAccount,
    _writer: BinaryWriter
  ) {
    if (_instance.bank) {
      _writer.writeEnum(1, _instance.bank);
    }
    if (_instance.type) {
      _writer.writeEnum(2, _instance.type);
    }
    if (_instance.number) {
      _writer.writeString(3, _instance.number);
    }
  }

  private _bank: BankNameEnum;
  private _type: AccountTypeBankEnum;
  private _number: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BankAccount to deeply clone from
   */
  constructor(_value?: RecursivePartial<BankAccount.AsObject>) {
    _value = _value || {};
    this.bank = _value.bank;
    this.type = _value.type;
    this.number = _value.number;
    BankAccount.refineValues(this);
  }
  get bank(): BankNameEnum {
    return this._bank;
  }
  set bank(value: BankNameEnum) {
    this._bank = value;
  }
  get type(): AccountTypeBankEnum {
    return this._type;
  }
  set type(value: AccountTypeBankEnum) {
    this._type = value;
  }
  get number(): string {
    return this._number;
  }
  set number(value: string) {
    this._number = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BankAccount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BankAccount.AsObject {
    return {
      bank: this.bank,
      type: this.type,
      number: this.number
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BankAccount.AsProtobufJSON {
    return {
      bank:
        BankNameEnum[
          this.bank === null || this.bank === undefined ? 0 : this.bank
        ],
      type:
        AccountTypeBankEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      number: this.number
    };
  }
}
export module BankAccount {
  /**
   * Standard JavaScript object representation for BankAccount
   */
  export interface AsObject {
    bank: BankNameEnum;
    type: AccountTypeBankEnum;
    number: string;
  }

  /**
   * Protobuf JSON representation for BankAccount
   */
  export interface AsProtobufJSON {
    bank: string;
    type: string;
    number: string;
  }
}

/**
 * Message implementation for common_message.AdvisorAssignmentRuleModel
 */
export class AdvisorAssignmentRuleModel implements GrpcMessage {
  static id = 'common_message.AdvisorAssignmentRuleModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AdvisorAssignmentRuleModel();
    AdvisorAssignmentRuleModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AdvisorAssignmentRuleModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.advisorId = _instance.advisorId || 0;
    _instance.advisor = _instance.advisor || undefined;
    _instance.advisorAssignmentRuleArticles =
      _instance.advisorAssignmentRuleArticles || [];
    _instance.position = _instance.position || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AdvisorAssignmentRuleModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.advisorId = _reader.readInt32();
          break;
        case 7:
          _instance.advisor = new UserModel();
          _reader.readMessage(
            _instance.advisor,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          const messageInitializer8 = new AdvisorAssignmentRuleArticleModel();
          _reader.readMessage(
            messageInitializer8,
            AdvisorAssignmentRuleArticleModel.deserializeBinaryFromReader
          );
          (_instance.advisorAssignmentRuleArticles =
            _instance.advisorAssignmentRuleArticles || []).push(
            messageInitializer8
          );
          break;
        case 9:
          _instance.position = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AdvisorAssignmentRuleModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AdvisorAssignmentRuleModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.advisorId) {
      _writer.writeInt32(6, _instance.advisorId);
    }
    if (_instance.advisor) {
      _writer.writeMessage(
        7,
        _instance.advisor as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (
      _instance.advisorAssignmentRuleArticles &&
      _instance.advisorAssignmentRuleArticles.length
    ) {
      _writer.writeRepeatedMessage(
        8,
        _instance.advisorAssignmentRuleArticles as any,
        AdvisorAssignmentRuleArticleModel.serializeBinaryToWriter
      );
    }
    if (_instance.position) {
      _writer.writeInt32(9, _instance.position);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _name: string;
  private _description: string;
  private _advisorId: number;
  private _advisor?: UserModel;
  private _advisorAssignmentRuleArticles?: AdvisorAssignmentRuleArticleModel[];
  private _position: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AdvisorAssignmentRuleModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<AdvisorAssignmentRuleModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.name = _value.name;
    this.description = _value.description;
    this.advisorId = _value.advisorId;
    this.advisor = _value.advisor ? new UserModel(_value.advisor) : undefined;
    this.advisorAssignmentRuleArticles = (
      _value.advisorAssignmentRuleArticles || []
    ).map(m => new AdvisorAssignmentRuleArticleModel(m));
    this.position = _value.position;
    AdvisorAssignmentRuleModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get advisorId(): number {
    return this._advisorId;
  }
  set advisorId(value: number) {
    this._advisorId = value;
  }
  get advisor(): UserModel | undefined {
    return this._advisor;
  }
  set advisor(value: UserModel | undefined) {
    this._advisor = value;
  }
  get advisorAssignmentRuleArticles():
    | AdvisorAssignmentRuleArticleModel[]
    | undefined {
    return this._advisorAssignmentRuleArticles;
  }
  set advisorAssignmentRuleArticles(
    value: AdvisorAssignmentRuleArticleModel[] | undefined
  ) {
    this._advisorAssignmentRuleArticles = value;
  }
  get position(): number {
    return this._position;
  }
  set position(value: number) {
    this._position = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AdvisorAssignmentRuleModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AdvisorAssignmentRuleModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      name: this.name,
      description: this.description,
      advisorId: this.advisorId,
      advisor: this.advisor ? this.advisor.toObject() : undefined,
      advisorAssignmentRuleArticles: (
        this.advisorAssignmentRuleArticles || []
      ).map(m => m.toObject()),
      position: this.position
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AdvisorAssignmentRuleModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      name: this.name,
      description: this.description,
      advisorId: this.advisorId,
      advisor: this.advisor ? this.advisor.toProtobufJSON(options) : null,
      advisorAssignmentRuleArticles: (
        this.advisorAssignmentRuleArticles || []
      ).map(m => m.toProtobufJSON(options)),
      position: this.position
    };
  }
}
export module AdvisorAssignmentRuleModel {
  /**
   * Standard JavaScript object representation for AdvisorAssignmentRuleModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    name: string;
    description: string;
    advisorId: number;
    advisor?: UserModel.AsObject;
    advisorAssignmentRuleArticles?: AdvisorAssignmentRuleArticleModel.AsObject[];
    position: number;
  }

  /**
   * Protobuf JSON representation for AdvisorAssignmentRuleModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    name: string;
    description: string;
    advisorId: number;
    advisor: UserModel.AsProtobufJSON | null;
    advisorAssignmentRuleArticles:
      | AdvisorAssignmentRuleArticleModel.AsProtobufJSON[]
      | null;
    position: number;
  }
}

/**
 * Message implementation for common_message.AdvisorAssignmentRuleArticleModel
 */
export class AdvisorAssignmentRuleArticleModel implements GrpcMessage {
  static id = 'common_message.AdvisorAssignmentRuleArticleModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AdvisorAssignmentRuleArticleModel();
    AdvisorAssignmentRuleArticleModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AdvisorAssignmentRuleArticleModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.ruleId = _instance.ruleId || 0;
    _instance.operation = _instance.operation || 0;
    _instance.unionOperation = _instance.unionOperation || 0;
    _instance.position = _instance.position || 0;
    _instance.response = _instance.response || '';
    _instance.questionId = _instance.questionId || 0;
    _instance.responseType = _instance.responseType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AdvisorAssignmentRuleArticleModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.ruleId = _reader.readInt32();
          break;
        case 7:
          _instance.operation = _reader.readEnum();
          break;
        case 8:
          _instance.unionOperation = _reader.readEnum();
          break;
        case 9:
          _instance.position = _reader.readInt32();
          break;
        case 10:
          _instance.response = _reader.readString();
          break;
        case 11:
          _instance.questionId = _reader.readInt32();
          break;
        case 12:
          _instance.responseType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    AdvisorAssignmentRuleArticleModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AdvisorAssignmentRuleArticleModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.ruleId) {
      _writer.writeInt32(6, _instance.ruleId);
    }
    if (_instance.operation) {
      _writer.writeEnum(7, _instance.operation);
    }
    if (_instance.unionOperation) {
      _writer.writeEnum(8, _instance.unionOperation);
    }
    if (_instance.position) {
      _writer.writeInt32(9, _instance.position);
    }
    if (_instance.response) {
      _writer.writeString(10, _instance.response);
    }
    if (_instance.questionId) {
      _writer.writeInt32(11, _instance.questionId);
    }
    if (_instance.responseType) {
      _writer.writeEnum(12, _instance.responseType);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _name: string;
  private _description: string;
  private _ruleId: number;
  private _operation: ArticleCompareOperationEnum;
  private _unionOperation: ArticleUnionOperationEnum;
  private _position: number;
  private _response: string;
  private _questionId: number;
  private _responseType: ArticleResponseTypeEnum;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AdvisorAssignmentRuleArticleModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AdvisorAssignmentRuleArticleModel.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.name = _value.name;
    this.description = _value.description;
    this.ruleId = _value.ruleId;
    this.operation = _value.operation;
    this.unionOperation = _value.unionOperation;
    this.position = _value.position;
    this.response = _value.response;
    this.questionId = _value.questionId;
    this.responseType = _value.responseType;
    AdvisorAssignmentRuleArticleModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get ruleId(): number {
    return this._ruleId;
  }
  set ruleId(value: number) {
    this._ruleId = value;
  }
  get operation(): ArticleCompareOperationEnum {
    return this._operation;
  }
  set operation(value: ArticleCompareOperationEnum) {
    this._operation = value;
  }
  get unionOperation(): ArticleUnionOperationEnum {
    return this._unionOperation;
  }
  set unionOperation(value: ArticleUnionOperationEnum) {
    this._unionOperation = value;
  }
  get position(): number {
    return this._position;
  }
  set position(value: number) {
    this._position = value;
  }
  get response(): string {
    return this._response;
  }
  set response(value: string) {
    this._response = value;
  }
  get questionId(): number {
    return this._questionId;
  }
  set questionId(value: number) {
    this._questionId = value;
  }
  get responseType(): ArticleResponseTypeEnum {
    return this._responseType;
  }
  set responseType(value: ArticleResponseTypeEnum) {
    this._responseType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AdvisorAssignmentRuleArticleModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AdvisorAssignmentRuleArticleModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      name: this.name,
      description: this.description,
      ruleId: this.ruleId,
      operation: this.operation,
      unionOperation: this.unionOperation,
      position: this.position,
      response: this.response,
      questionId: this.questionId,
      responseType: this.responseType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AdvisorAssignmentRuleArticleModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      name: this.name,
      description: this.description,
      ruleId: this.ruleId,
      operation:
        ArticleCompareOperationEnum[
          this.operation === null || this.operation === undefined
            ? 0
            : this.operation
        ],
      unionOperation:
        ArticleUnionOperationEnum[
          this.unionOperation === null || this.unionOperation === undefined
            ? 0
            : this.unionOperation
        ],
      position: this.position,
      response: this.response,
      questionId: this.questionId,
      responseType:
        ArticleResponseTypeEnum[
          this.responseType === null || this.responseType === undefined
            ? 0
            : this.responseType
        ]
    };
  }
}
export module AdvisorAssignmentRuleArticleModel {
  /**
   * Standard JavaScript object representation for AdvisorAssignmentRuleArticleModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    name: string;
    description: string;
    ruleId: number;
    operation: ArticleCompareOperationEnum;
    unionOperation: ArticleUnionOperationEnum;
    position: number;
    response: string;
    questionId: number;
    responseType: ArticleResponseTypeEnum;
  }

  /**
   * Protobuf JSON representation for AdvisorAssignmentRuleArticleModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    name: string;
    description: string;
    ruleId: number;
    operation: string;
    unionOperation: string;
    position: number;
    response: string;
    questionId: number;
    responseType: string;
  }
}

/**
 * Message implementation for common_message.UserModel
 */
export class UserModel implements GrpcMessage {
  static id = 'common_message.UserModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserModel();
    UserModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserModel) {
    _instance.phone = _instance.phone || '';
    _instance.userFinancialEntities = _instance.userFinancialEntities || [];
    _instance.firstname = _instance.firstname || '';
    _instance.middlename = _instance.middlename || '';
    _instance.surname = _instance.surname || '';
    _instance.maidenname = _instance.maidenname || '';
    _instance.rut = _instance.rut || '';
    _instance.status = _instance.status || '';
    _instance.ref = _instance.ref || undefined;
    _instance.description = _instance.description || '';
    _instance.isAdmin = _instance.isAdmin || false;
    _instance.isAdvisor = _instance.isAdvisor || false;
    _instance.advisingUsers = _instance.advisingUsers || undefined;
    _instance.urlCalendar = _instance.urlCalendar || undefined;
    _instance.calendlyUuid = _instance.calendlyUuid || undefined;
    _instance.firstSteps = _instance.firstSteps || undefined;
    _instance.identityId = _instance.identityId || '';
    _instance.rutWithoutCheckDigit = _instance.rutWithoutCheckDigit || '';
    _instance.username = _instance.username || '';
    _instance.email = _instance.email || '';
    _instance.id = _instance.id || 0;
    _instance.advisorId = _instance.advisorId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.informationId = _instance.informationId || 0;
    _instance.riskProfileId = _instance.riskProfileId || 0;
    _instance.icBackFileId = _instance.icBackFileId || undefined;
    _instance.icFrontFileId = _instance.icFrontFileId || undefined;
    _instance.photoProfileId = _instance.photoProfileId || undefined;
    _instance.icValidated = _instance.icValidated || false;
    _instance.fullName = _instance.fullName || '';
    _instance.currency = _instance.currency || undefined;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.goals = _instance.goals || [];
    _instance.manager = _instance.manager || undefined;
    _instance.riskProfile = _instance.riskProfile || undefined;
    _instance.typesGoalsCreated = _instance.typesGoalsCreated || {};
    _instance.userType = _instance.userType || '';
    _instance.companies = _instance.companies || [];
    _instance.information = _instance.information || undefined;
    _instance.showAdvisorPopUp = _instance.showAdvisorPopUp || false;
    _instance.urlLinkedIn = _instance.urlLinkedIn || '';
    _instance.segmentationPlan = _instance.segmentationPlan || undefined;
    _instance.segmentationPlanId = _instance.segmentationPlanId || undefined;
    _instance.advisingCount = _instance.advisingCount || 0;
    _instance.password = _instance.password || '';
    _instance.preferedName = _instance.preferedName || '';
    _instance.userResultCalculate = _instance.userResultCalculate || undefined;
    _instance.emailVerified = _instance.emailVerified || false;
    _instance.holidayAdvisor = _instance.holidayAdvisor || undefined;
    _instance.archived = _instance.archived || false;
    _instance.hasCustomSegmentation = _instance.hasCustomSegmentation || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.phone = _reader.readString();
          break;
        case 2:
          const messageInitializer2 = new UserFinancialEntityModel();
          _reader.readMessage(
            messageInitializer2,
            UserFinancialEntityModel.deserializeBinaryFromReader
          );
          (_instance.userFinancialEntities =
            _instance.userFinancialEntities || []).push(messageInitializer2);
          break;
        case 3:
          _instance.firstname = _reader.readString();
          break;
        case 4:
          _instance.middlename = _reader.readString();
          break;
        case 5:
          _instance.surname = _reader.readString();
          break;
        case 6:
          _instance.maidenname = _reader.readString();
          break;
        case 7:
          _instance.rut = _reader.readString();
          break;
        case 8:
          _instance.status = _reader.readString();
          break;
        case 9:
          _instance.ref = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.ref,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.description = _reader.readString();
          break;
        case 11:
          _instance.isAdmin = _reader.readBool();
          break;
        case 12:
          _instance.isAdvisor = _reader.readBool();
          break;
        case 13:
          _instance.advisingUsers = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.advisingUsers,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.urlCalendar = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.urlCalendar,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.calendlyUuid = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.calendlyUuid,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.firstSteps = new UserFirstStepsModel();
          _reader.readMessage(
            _instance.firstSteps,
            UserFirstStepsModel.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.identityId = _reader.readString();
          break;
        case 18:
          _instance.rutWithoutCheckDigit = _reader.readString();
          break;
        case 20:
          _instance.username = _reader.readString();
          break;
        case 21:
          _instance.email = _reader.readString();
          break;
        case 23:
          _instance.id = _reader.readInt32();
          break;
        case 24:
          _instance.advisorId = _reader.readInt32();
          break;
        case 25:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 27:
          _instance.informationId = _reader.readDouble();
          break;
        case 28:
          _instance.riskProfileId = _reader.readDouble();
          break;
        case 29:
          _instance.icBackFileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.icBackFileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 30:
          _instance.icFrontFileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.icFrontFileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 31:
          _instance.photoProfileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.photoProfileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 32:
          _instance.icValidated = _reader.readBool();
          break;
        case 33:
          _instance.fullName = _reader.readString();
          break;
        case 34:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 35:
          _instance.currencyId = _reader.readInt32();
          break;
        case 36:
          const messageInitializer36 = new GoalModel();
          _reader.readMessage(
            messageInitializer36,
            GoalModel.deserializeBinaryFromReader
          );
          (_instance.goals = _instance.goals || []).push(messageInitializer36);
          break;
        case 37:
          _instance.manager = new UserModel();
          _reader.readMessage(
            _instance.manager,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 38:
          _instance.riskProfile = new RiskProfileModel();
          _reader.readMessage(
            _instance.riskProfile,
            RiskProfileModel.deserializeBinaryFromReader
          );
          break;
        case 39:
          const msg_39 = {} as any;
          _reader.readMessage(
            msg_39,
            UserModel.TypesGoalsCreatedEntry.deserializeBinaryFromReader
          );
          _instance.typesGoalsCreated = _instance.typesGoalsCreated || {};
          _instance.typesGoalsCreated[msg_39.key] = msg_39.value;
          break;
        case 40:
          _instance.userType = _reader.readString();
          break;
        case 41:
          const messageInitializer41 = new CompanyManagersModel();
          _reader.readMessage(
            messageInitializer41,
            CompanyManagersModel.deserializeBinaryFromReader
          );
          (_instance.companies = _instance.companies || []).push(
            messageInitializer41
          );
          break;
        case 42:
          _instance.information = new UserInformationModel();
          _reader.readMessage(
            _instance.information,
            UserInformationModel.deserializeBinaryFromReader
          );
          break;
        case 43:
          _instance.showAdvisorPopUp = _reader.readBool();
          break;
        case 44:
          _instance.urlLinkedIn = _reader.readString();
          break;
        case 45:
          _instance.segmentationPlan = new SegmentationPlanModel();
          _reader.readMessage(
            _instance.segmentationPlan,
            SegmentationPlanModel.deserializeBinaryFromReader
          );
          break;
        case 46:
          _instance.segmentationPlanId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.segmentationPlanId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 47:
          _instance.advisingCount = _reader.readInt32();
          break;
        case 48:
          _instance.password = _reader.readString();
          break;
        case 49:
          _instance.preferedName = _reader.readString();
          break;
        case 50:
          _instance.userResultCalculate = new UserResultCalculateModel();
          _reader.readMessage(
            _instance.userResultCalculate,
            UserResultCalculateModel.deserializeBinaryFromReader
          );
          break;
        case 51:
          _instance.emailVerified = _reader.readBool();
          break;
        case 52:
          _instance.holidayAdvisor = new HolidayAdvisorModel();
          _reader.readMessage(
            _instance.holidayAdvisor,
            HolidayAdvisorModel.deserializeBinaryFromReader
          );
          break;
        case 53:
          _instance.archived = _reader.readBool();
          break;
        case 54:
          _instance.hasCustomSegmentation = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UserModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: UserModel, _writer: BinaryWriter) {
    if (_instance.phone) {
      _writer.writeString(1, _instance.phone);
    }
    if (
      _instance.userFinancialEntities &&
      _instance.userFinancialEntities.length
    ) {
      _writer.writeRepeatedMessage(
        2,
        _instance.userFinancialEntities as any,
        UserFinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.firstname) {
      _writer.writeString(3, _instance.firstname);
    }
    if (_instance.middlename) {
      _writer.writeString(4, _instance.middlename);
    }
    if (_instance.surname) {
      _writer.writeString(5, _instance.surname);
    }
    if (_instance.maidenname) {
      _writer.writeString(6, _instance.maidenname);
    }
    if (_instance.rut) {
      _writer.writeString(7, _instance.rut);
    }
    if (_instance.status) {
      _writer.writeString(8, _instance.status);
    }
    if (_instance.ref) {
      _writer.writeMessage(
        9,
        _instance.ref as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(10, _instance.description);
    }
    if (_instance.isAdmin) {
      _writer.writeBool(11, _instance.isAdmin);
    }
    if (_instance.isAdvisor) {
      _writer.writeBool(12, _instance.isAdvisor);
    }
    if (_instance.advisingUsers) {
      _writer.writeMessage(
        13,
        _instance.advisingUsers as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.urlCalendar) {
      _writer.writeMessage(
        14,
        _instance.urlCalendar as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.calendlyUuid) {
      _writer.writeMessage(
        15,
        _instance.calendlyUuid as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.firstSteps) {
      _writer.writeMessage(
        16,
        _instance.firstSteps as any,
        UserFirstStepsModel.serializeBinaryToWriter
      );
    }
    if (_instance.identityId) {
      _writer.writeString(17, _instance.identityId);
    }
    if (_instance.rutWithoutCheckDigit) {
      _writer.writeString(18, _instance.rutWithoutCheckDigit);
    }
    if (_instance.username) {
      _writer.writeString(20, _instance.username);
    }
    if (_instance.email) {
      _writer.writeString(21, _instance.email);
    }
    if (_instance.id) {
      _writer.writeInt32(23, _instance.id);
    }
    if (_instance.advisorId) {
      _writer.writeInt32(24, _instance.advisorId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        25,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        26,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.informationId) {
      _writer.writeDouble(27, _instance.informationId);
    }
    if (_instance.riskProfileId) {
      _writer.writeDouble(28, _instance.riskProfileId);
    }
    if (_instance.icBackFileId) {
      _writer.writeMessage(
        29,
        _instance.icBackFileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.icFrontFileId) {
      _writer.writeMessage(
        30,
        _instance.icFrontFileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.photoProfileId) {
      _writer.writeMessage(
        31,
        _instance.photoProfileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.icValidated) {
      _writer.writeBool(32, _instance.icValidated);
    }
    if (_instance.fullName) {
      _writer.writeString(33, _instance.fullName);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        34,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.currencyId) {
      _writer.writeInt32(35, _instance.currencyId);
    }
    if (_instance.goals && _instance.goals.length) {
      _writer.writeRepeatedMessage(
        36,
        _instance.goals as any,
        GoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.manager) {
      _writer.writeMessage(
        37,
        _instance.manager as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.riskProfile) {
      _writer.writeMessage(
        38,
        _instance.riskProfile as any,
        RiskProfileModel.serializeBinaryToWriter
      );
    }
    if (!!_instance.typesGoalsCreated) {
      const keys_39 = Object.keys(_instance.typesGoalsCreated as any);

      if (keys_39.length) {
        const repeated_39 = keys_39
          .map(key => ({
            key: key,
            value: (_instance.typesGoalsCreated as any)[key]
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          39,
          repeated_39,
          UserModel.TypesGoalsCreatedEntry.serializeBinaryToWriter
        );
      }
    }
    if (_instance.userType) {
      _writer.writeString(40, _instance.userType);
    }
    if (_instance.companies && _instance.companies.length) {
      _writer.writeRepeatedMessage(
        41,
        _instance.companies as any,
        CompanyManagersModel.serializeBinaryToWriter
      );
    }
    if (_instance.information) {
      _writer.writeMessage(
        42,
        _instance.information as any,
        UserInformationModel.serializeBinaryToWriter
      );
    }
    if (_instance.showAdvisorPopUp) {
      _writer.writeBool(43, _instance.showAdvisorPopUp);
    }
    if (_instance.urlLinkedIn) {
      _writer.writeString(44, _instance.urlLinkedIn);
    }
    if (_instance.segmentationPlan) {
      _writer.writeMessage(
        45,
        _instance.segmentationPlan as any,
        SegmentationPlanModel.serializeBinaryToWriter
      );
    }
    if (_instance.segmentationPlanId) {
      _writer.writeMessage(
        46,
        _instance.segmentationPlanId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.advisingCount) {
      _writer.writeInt32(47, _instance.advisingCount);
    }
    if (_instance.password) {
      _writer.writeString(48, _instance.password);
    }
    if (_instance.preferedName) {
      _writer.writeString(49, _instance.preferedName);
    }
    if (_instance.userResultCalculate) {
      _writer.writeMessage(
        50,
        _instance.userResultCalculate as any,
        UserResultCalculateModel.serializeBinaryToWriter
      );
    }
    if (_instance.emailVerified) {
      _writer.writeBool(51, _instance.emailVerified);
    }
    if (_instance.holidayAdvisor) {
      _writer.writeMessage(
        52,
        _instance.holidayAdvisor as any,
        HolidayAdvisorModel.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(53, _instance.archived);
    }
    if (_instance.hasCustomSegmentation) {
      _writer.writeBool(54, _instance.hasCustomSegmentation);
    }
  }

  private _phone: string;
  private _userFinancialEntities?: UserFinancialEntityModel[];
  private _firstname: string;
  private _middlename: string;
  private _surname: string;
  private _maidenname: string;
  private _rut: string;
  private _status: string;
  private _ref?: googleProtobuf000.StringValue;
  private _description: string;
  private _isAdmin: boolean;
  private _isAdvisor: boolean;
  private _advisingUsers?: googleProtobuf000.Int32Value;
  private _urlCalendar?: googleProtobuf000.StringValue;
  private _calendlyUuid?: googleProtobuf000.StringValue;
  private _firstSteps?: UserFirstStepsModel;
  private _identityId: string;
  private _rutWithoutCheckDigit: string;
  private _username: string;
  private _email: string;
  private _id: number;
  private _advisorId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _informationId: number;
  private _riskProfileId: number;
  private _icBackFileId?: googleProtobuf000.Int32Value;
  private _icFrontFileId?: googleProtobuf000.Int32Value;
  private _photoProfileId?: googleProtobuf000.Int32Value;
  private _icValidated: boolean;
  private _fullName: string;
  private _currency?: CurrencyModel;
  private _currencyId: number;
  private _goals?: GoalModel[];
  private _manager?: UserModel;
  private _riskProfile?: RiskProfileModel;
  private _typesGoalsCreated: { [prop: string]: string };
  private _userType: string;
  private _companies?: CompanyManagersModel[];
  private _information?: UserInformationModel;
  private _showAdvisorPopUp: boolean;
  private _urlLinkedIn: string;
  private _segmentationPlan?: SegmentationPlanModel;
  private _segmentationPlanId?: googleProtobuf000.Int32Value;
  private _advisingCount: number;
  private _password: string;
  private _preferedName: string;
  private _userResultCalculate?: UserResultCalculateModel;
  private _emailVerified: boolean;
  private _holidayAdvisor?: HolidayAdvisorModel;
  private _archived: boolean;
  private _hasCustomSegmentation: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserModel.AsObject>) {
    _value = _value || {};
    this.phone = _value.phone;
    this.userFinancialEntities = (_value.userFinancialEntities || []).map(
      m => new UserFinancialEntityModel(m)
    );
    this.firstname = _value.firstname;
    this.middlename = _value.middlename;
    this.surname = _value.surname;
    this.maidenname = _value.maidenname;
    this.rut = _value.rut;
    this.status = _value.status;
    this.ref = _value.ref
      ? new googleProtobuf000.StringValue(_value.ref)
      : undefined;
    this.description = _value.description;
    this.isAdmin = _value.isAdmin;
    this.isAdvisor = _value.isAdvisor;
    this.advisingUsers = _value.advisingUsers
      ? new googleProtobuf000.Int32Value(_value.advisingUsers)
      : undefined;
    this.urlCalendar = _value.urlCalendar
      ? new googleProtobuf000.StringValue(_value.urlCalendar)
      : undefined;
    this.calendlyUuid = _value.calendlyUuid
      ? new googleProtobuf000.StringValue(_value.calendlyUuid)
      : undefined;
    this.firstSteps = _value.firstSteps
      ? new UserFirstStepsModel(_value.firstSteps)
      : undefined;
    this.identityId = _value.identityId;
    this.rutWithoutCheckDigit = _value.rutWithoutCheckDigit;
    this.username = _value.username;
    this.email = _value.email;
    this.id = _value.id;
    this.advisorId = _value.advisorId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.informationId = _value.informationId;
    this.riskProfileId = _value.riskProfileId;
    this.icBackFileId = _value.icBackFileId
      ? new googleProtobuf000.Int32Value(_value.icBackFileId)
      : undefined;
    this.icFrontFileId = _value.icFrontFileId
      ? new googleProtobuf000.Int32Value(_value.icFrontFileId)
      : undefined;
    this.photoProfileId = _value.photoProfileId
      ? new googleProtobuf000.Int32Value(_value.photoProfileId)
      : undefined;
    this.icValidated = _value.icValidated;
    this.fullName = _value.fullName;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.currencyId = _value.currencyId;
    this.goals = (_value.goals || []).map(m => new GoalModel(m));
    this.manager = _value.manager ? new UserModel(_value.manager) : undefined;
    this.riskProfile = _value.riskProfile
      ? new RiskProfileModel(_value.riskProfile)
      : undefined;
    (this.typesGoalsCreated = _value!.typesGoalsCreated
      ? Object.keys(_value!.typesGoalsCreated).reduce(
          (r, k) => ({ ...r, [k]: _value!.typesGoalsCreated![k] }),
          {}
        )
      : {}),
      (this.userType = _value.userType);
    this.companies = (_value.companies || []).map(
      m => new CompanyManagersModel(m)
    );
    this.information = _value.information
      ? new UserInformationModel(_value.information)
      : undefined;
    this.showAdvisorPopUp = _value.showAdvisorPopUp;
    this.urlLinkedIn = _value.urlLinkedIn;
    this.segmentationPlan = _value.segmentationPlan
      ? new SegmentationPlanModel(_value.segmentationPlan)
      : undefined;
    this.segmentationPlanId = _value.segmentationPlanId
      ? new googleProtobuf000.Int32Value(_value.segmentationPlanId)
      : undefined;
    this.advisingCount = _value.advisingCount;
    this.password = _value.password;
    this.preferedName = _value.preferedName;
    this.userResultCalculate = _value.userResultCalculate
      ? new UserResultCalculateModel(_value.userResultCalculate)
      : undefined;
    this.emailVerified = _value.emailVerified;
    this.holidayAdvisor = _value.holidayAdvisor
      ? new HolidayAdvisorModel(_value.holidayAdvisor)
      : undefined;
    this.archived = _value.archived;
    this.hasCustomSegmentation = _value.hasCustomSegmentation;
    UserModel.refineValues(this);
  }
  get phone(): string {
    return this._phone;
  }
  set phone(value: string) {
    this._phone = value;
  }
  get userFinancialEntities(): UserFinancialEntityModel[] | undefined {
    return this._userFinancialEntities;
  }
  set userFinancialEntities(value: UserFinancialEntityModel[] | undefined) {
    this._userFinancialEntities = value;
  }
  get firstname(): string {
    return this._firstname;
  }
  set firstname(value: string) {
    this._firstname = value;
  }
  get middlename(): string {
    return this._middlename;
  }
  set middlename(value: string) {
    this._middlename = value;
  }
  get surname(): string {
    return this._surname;
  }
  set surname(value: string) {
    this._surname = value;
  }
  get maidenname(): string {
    return this._maidenname;
  }
  set maidenname(value: string) {
    this._maidenname = value;
  }
  get rut(): string {
    return this._rut;
  }
  set rut(value: string) {
    this._rut = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get ref(): googleProtobuf000.StringValue | undefined {
    return this._ref;
  }
  set ref(value: googleProtobuf000.StringValue | undefined) {
    this._ref = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get isAdmin(): boolean {
    return this._isAdmin;
  }
  set isAdmin(value: boolean) {
    this._isAdmin = value;
  }
  get isAdvisor(): boolean {
    return this._isAdvisor;
  }
  set isAdvisor(value: boolean) {
    this._isAdvisor = value;
  }
  get advisingUsers(): googleProtobuf000.Int32Value | undefined {
    return this._advisingUsers;
  }
  set advisingUsers(value: googleProtobuf000.Int32Value | undefined) {
    this._advisingUsers = value;
  }
  get urlCalendar(): googleProtobuf000.StringValue | undefined {
    return this._urlCalendar;
  }
  set urlCalendar(value: googleProtobuf000.StringValue | undefined) {
    this._urlCalendar = value;
  }
  get calendlyUuid(): googleProtobuf000.StringValue | undefined {
    return this._calendlyUuid;
  }
  set calendlyUuid(value: googleProtobuf000.StringValue | undefined) {
    this._calendlyUuid = value;
  }
  get firstSteps(): UserFirstStepsModel | undefined {
    return this._firstSteps;
  }
  set firstSteps(value: UserFirstStepsModel | undefined) {
    this._firstSteps = value;
  }
  get identityId(): string {
    return this._identityId;
  }
  set identityId(value: string) {
    this._identityId = value;
  }
  get rutWithoutCheckDigit(): string {
    return this._rutWithoutCheckDigit;
  }
  set rutWithoutCheckDigit(value: string) {
    this._rutWithoutCheckDigit = value;
  }
  get username(): string {
    return this._username;
  }
  set username(value: string) {
    this._username = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get advisorId(): number {
    return this._advisorId;
  }
  set advisorId(value: number) {
    this._advisorId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get informationId(): number {
    return this._informationId;
  }
  set informationId(value: number) {
    this._informationId = value;
  }
  get riskProfileId(): number {
    return this._riskProfileId;
  }
  set riskProfileId(value: number) {
    this._riskProfileId = value;
  }
  get icBackFileId(): googleProtobuf000.Int32Value | undefined {
    return this._icBackFileId;
  }
  set icBackFileId(value: googleProtobuf000.Int32Value | undefined) {
    this._icBackFileId = value;
  }
  get icFrontFileId(): googleProtobuf000.Int32Value | undefined {
    return this._icFrontFileId;
  }
  set icFrontFileId(value: googleProtobuf000.Int32Value | undefined) {
    this._icFrontFileId = value;
  }
  get photoProfileId(): googleProtobuf000.Int32Value | undefined {
    return this._photoProfileId;
  }
  set photoProfileId(value: googleProtobuf000.Int32Value | undefined) {
    this._photoProfileId = value;
  }
  get icValidated(): boolean {
    return this._icValidated;
  }
  set icValidated(value: boolean) {
    this._icValidated = value;
  }
  get fullName(): string {
    return this._fullName;
  }
  set fullName(value: string) {
    this._fullName = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get goals(): GoalModel[] | undefined {
    return this._goals;
  }
  set goals(value: GoalModel[] | undefined) {
    this._goals = value;
  }
  get manager(): UserModel | undefined {
    return this._manager;
  }
  set manager(value: UserModel | undefined) {
    this._manager = value;
  }
  get riskProfile(): RiskProfileModel | undefined {
    return this._riskProfile;
  }
  set riskProfile(value: RiskProfileModel | undefined) {
    this._riskProfile = value;
  }
  get typesGoalsCreated(): { [prop: string]: string } {
    return this._typesGoalsCreated;
  }
  set typesGoalsCreated(value: { [prop: string]: string }) {
    this._typesGoalsCreated = value;
  }
  get userType(): string {
    return this._userType;
  }
  set userType(value: string) {
    this._userType = value;
  }
  get companies(): CompanyManagersModel[] | undefined {
    return this._companies;
  }
  set companies(value: CompanyManagersModel[] | undefined) {
    this._companies = value;
  }
  get information(): UserInformationModel | undefined {
    return this._information;
  }
  set information(value: UserInformationModel | undefined) {
    this._information = value;
  }
  get showAdvisorPopUp(): boolean {
    return this._showAdvisorPopUp;
  }
  set showAdvisorPopUp(value: boolean) {
    this._showAdvisorPopUp = value;
  }
  get urlLinkedIn(): string {
    return this._urlLinkedIn;
  }
  set urlLinkedIn(value: string) {
    this._urlLinkedIn = value;
  }
  get segmentationPlan(): SegmentationPlanModel | undefined {
    return this._segmentationPlan;
  }
  set segmentationPlan(value: SegmentationPlanModel | undefined) {
    this._segmentationPlan = value;
  }
  get segmentationPlanId(): googleProtobuf000.Int32Value | undefined {
    return this._segmentationPlanId;
  }
  set segmentationPlanId(value: googleProtobuf000.Int32Value | undefined) {
    this._segmentationPlanId = value;
  }
  get advisingCount(): number {
    return this._advisingCount;
  }
  set advisingCount(value: number) {
    this._advisingCount = value;
  }
  get password(): string {
    return this._password;
  }
  set password(value: string) {
    this._password = value;
  }
  get preferedName(): string {
    return this._preferedName;
  }
  set preferedName(value: string) {
    this._preferedName = value;
  }
  get userResultCalculate(): UserResultCalculateModel | undefined {
    return this._userResultCalculate;
  }
  set userResultCalculate(value: UserResultCalculateModel | undefined) {
    this._userResultCalculate = value;
  }
  get emailVerified(): boolean {
    return this._emailVerified;
  }
  set emailVerified(value: boolean) {
    this._emailVerified = value;
  }
  get holidayAdvisor(): HolidayAdvisorModel | undefined {
    return this._holidayAdvisor;
  }
  set holidayAdvisor(value: HolidayAdvisorModel | undefined) {
    this._holidayAdvisor = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get hasCustomSegmentation(): boolean {
    return this._hasCustomSegmentation;
  }
  set hasCustomSegmentation(value: boolean) {
    this._hasCustomSegmentation = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserModel.AsObject {
    return {
      phone: this.phone,
      userFinancialEntities: (this.userFinancialEntities || []).map(m =>
        m.toObject()
      ),
      firstname: this.firstname,
      middlename: this.middlename,
      surname: this.surname,
      maidenname: this.maidenname,
      rut: this.rut,
      status: this.status,
      ref: this.ref ? this.ref.toObject() : undefined,
      description: this.description,
      isAdmin: this.isAdmin,
      isAdvisor: this.isAdvisor,
      advisingUsers: this.advisingUsers
        ? this.advisingUsers.toObject()
        : undefined,
      urlCalendar: this.urlCalendar ? this.urlCalendar.toObject() : undefined,
      calendlyUuid: this.calendlyUuid
        ? this.calendlyUuid.toObject()
        : undefined,
      firstSteps: this.firstSteps ? this.firstSteps.toObject() : undefined,
      identityId: this.identityId,
      rutWithoutCheckDigit: this.rutWithoutCheckDigit,
      username: this.username,
      email: this.email,
      id: this.id,
      advisorId: this.advisorId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      informationId: this.informationId,
      riskProfileId: this.riskProfileId,
      icBackFileId: this.icBackFileId
        ? this.icBackFileId.toObject()
        : undefined,
      icFrontFileId: this.icFrontFileId
        ? this.icFrontFileId.toObject()
        : undefined,
      photoProfileId: this.photoProfileId
        ? this.photoProfileId.toObject()
        : undefined,
      icValidated: this.icValidated,
      fullName: this.fullName,
      currency: this.currency ? this.currency.toObject() : undefined,
      currencyId: this.currencyId,
      goals: (this.goals || []).map(m => m.toObject()),
      manager: this.manager ? this.manager.toObject() : undefined,
      riskProfile: this.riskProfile ? this.riskProfile.toObject() : undefined,
      typesGoalsCreated: this.typesGoalsCreated
        ? Object.keys(this.typesGoalsCreated).reduce(
            (r, k) => ({ ...r, [k]: this.typesGoalsCreated![k] }),
            {}
          )
        : {},
      userType: this.userType,
      companies: (this.companies || []).map(m => m.toObject()),
      information: this.information ? this.information.toObject() : undefined,
      showAdvisorPopUp: this.showAdvisorPopUp,
      urlLinkedIn: this.urlLinkedIn,
      segmentationPlan: this.segmentationPlan
        ? this.segmentationPlan.toObject()
        : undefined,
      segmentationPlanId: this.segmentationPlanId
        ? this.segmentationPlanId.toObject()
        : undefined,
      advisingCount: this.advisingCount,
      password: this.password,
      preferedName: this.preferedName,
      userResultCalculate: this.userResultCalculate
        ? this.userResultCalculate.toObject()
        : undefined,
      emailVerified: this.emailVerified,
      holidayAdvisor: this.holidayAdvisor
        ? this.holidayAdvisor.toObject()
        : undefined,
      archived: this.archived,
      hasCustomSegmentation: this.hasCustomSegmentation
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserModel.AsProtobufJSON {
    return {
      phone: this.phone,
      userFinancialEntities: (this.userFinancialEntities || []).map(m =>
        m.toProtobufJSON(options)
      ),
      firstname: this.firstname,
      middlename: this.middlename,
      surname: this.surname,
      maidenname: this.maidenname,
      rut: this.rut,
      status: this.status,
      ref: this.ref ? this.ref.toProtobufJSON(options) : null,
      description: this.description,
      isAdmin: this.isAdmin,
      isAdvisor: this.isAdvisor,
      advisingUsers: this.advisingUsers
        ? this.advisingUsers.toProtobufJSON(options)
        : null,
      urlCalendar: this.urlCalendar
        ? this.urlCalendar.toProtobufJSON(options)
        : null,
      calendlyUuid: this.calendlyUuid
        ? this.calendlyUuid.toProtobufJSON(options)
        : null,
      firstSteps: this.firstSteps
        ? this.firstSteps.toProtobufJSON(options)
        : null,
      identityId: this.identityId,
      rutWithoutCheckDigit: this.rutWithoutCheckDigit,
      username: this.username,
      email: this.email,
      id: this.id,
      advisorId: this.advisorId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      informationId: this.informationId,
      riskProfileId: this.riskProfileId,
      icBackFileId: this.icBackFileId
        ? this.icBackFileId.toProtobufJSON(options)
        : null,
      icFrontFileId: this.icFrontFileId
        ? this.icFrontFileId.toProtobufJSON(options)
        : null,
      photoProfileId: this.photoProfileId
        ? this.photoProfileId.toProtobufJSON(options)
        : null,
      icValidated: this.icValidated,
      fullName: this.fullName,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      currencyId: this.currencyId,
      goals: (this.goals || []).map(m => m.toProtobufJSON(options)),
      manager: this.manager ? this.manager.toProtobufJSON(options) : null,
      riskProfile: this.riskProfile
        ? this.riskProfile.toProtobufJSON(options)
        : null,
      typesGoalsCreated: this.typesGoalsCreated
        ? Object.keys(this.typesGoalsCreated).reduce(
            (r, k) => ({ ...r, [k]: this.typesGoalsCreated![k] }),
            {}
          )
        : {},
      userType: this.userType,
      companies: (this.companies || []).map(m => m.toProtobufJSON(options)),
      information: this.information
        ? this.information.toProtobufJSON(options)
        : null,
      showAdvisorPopUp: this.showAdvisorPopUp,
      urlLinkedIn: this.urlLinkedIn,
      segmentationPlan: this.segmentationPlan
        ? this.segmentationPlan.toProtobufJSON(options)
        : null,
      segmentationPlanId: this.segmentationPlanId
        ? this.segmentationPlanId.toProtobufJSON(options)
        : null,
      advisingCount: this.advisingCount,
      password: this.password,
      preferedName: this.preferedName,
      userResultCalculate: this.userResultCalculate
        ? this.userResultCalculate.toProtobufJSON(options)
        : null,
      emailVerified: this.emailVerified,
      holidayAdvisor: this.holidayAdvisor
        ? this.holidayAdvisor.toProtobufJSON(options)
        : null,
      archived: this.archived,
      hasCustomSegmentation: this.hasCustomSegmentation
    };
  }
}
export module UserModel {
  /**
   * Standard JavaScript object representation for UserModel
   */
  export interface AsObject {
    phone: string;
    userFinancialEntities?: UserFinancialEntityModel.AsObject[];
    firstname: string;
    middlename: string;
    surname: string;
    maidenname: string;
    rut: string;
    status: string;
    ref?: googleProtobuf000.StringValue.AsObject;
    description: string;
    isAdmin: boolean;
    isAdvisor: boolean;
    advisingUsers?: googleProtobuf000.Int32Value.AsObject;
    urlCalendar?: googleProtobuf000.StringValue.AsObject;
    calendlyUuid?: googleProtobuf000.StringValue.AsObject;
    firstSteps?: UserFirstStepsModel.AsObject;
    identityId: string;
    rutWithoutCheckDigit: string;
    username: string;
    email: string;
    id: number;
    advisorId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    informationId: number;
    riskProfileId: number;
    icBackFileId?: googleProtobuf000.Int32Value.AsObject;
    icFrontFileId?: googleProtobuf000.Int32Value.AsObject;
    photoProfileId?: googleProtobuf000.Int32Value.AsObject;
    icValidated: boolean;
    fullName: string;
    currency?: CurrencyModel.AsObject;
    currencyId: number;
    goals?: GoalModel.AsObject[];
    manager?: UserModel.AsObject;
    riskProfile?: RiskProfileModel.AsObject;
    typesGoalsCreated: { [prop: string]: string };
    userType: string;
    companies?: CompanyManagersModel.AsObject[];
    information?: UserInformationModel.AsObject;
    showAdvisorPopUp: boolean;
    urlLinkedIn: string;
    segmentationPlan?: SegmentationPlanModel.AsObject;
    segmentationPlanId?: googleProtobuf000.Int32Value.AsObject;
    advisingCount: number;
    password: string;
    preferedName: string;
    userResultCalculate?: UserResultCalculateModel.AsObject;
    emailVerified: boolean;
    holidayAdvisor?: HolidayAdvisorModel.AsObject;
    archived: boolean;
    hasCustomSegmentation: boolean;
  }

  /**
   * Protobuf JSON representation for UserModel
   */
  export interface AsProtobufJSON {
    phone: string;
    userFinancialEntities: UserFinancialEntityModel.AsProtobufJSON[] | null;
    firstname: string;
    middlename: string;
    surname: string;
    maidenname: string;
    rut: string;
    status: string;
    ref: googleProtobuf000.StringValue.AsProtobufJSON | null;
    description: string;
    isAdmin: boolean;
    isAdvisor: boolean;
    advisingUsers: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    urlCalendar: googleProtobuf000.StringValue.AsProtobufJSON | null;
    calendlyUuid: googleProtobuf000.StringValue.AsProtobufJSON | null;
    firstSteps: UserFirstStepsModel.AsProtobufJSON | null;
    identityId: string;
    rutWithoutCheckDigit: string;
    username: string;
    email: string;
    id: number;
    advisorId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    informationId: number;
    riskProfileId: number;
    icBackFileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    icFrontFileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    photoProfileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    icValidated: boolean;
    fullName: string;
    currency: CurrencyModel.AsProtobufJSON | null;
    currencyId: number;
    goals: GoalModel.AsProtobufJSON[] | null;
    manager: UserModel.AsProtobufJSON | null;
    riskProfile: RiskProfileModel.AsProtobufJSON | null;
    typesGoalsCreated: { [prop: string]: string };
    userType: string;
    companies: CompanyManagersModel.AsProtobufJSON[] | null;
    information: UserInformationModel.AsProtobufJSON | null;
    showAdvisorPopUp: boolean;
    urlLinkedIn: string;
    segmentationPlan: SegmentationPlanModel.AsProtobufJSON | null;
    segmentationPlanId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    advisingCount: number;
    password: string;
    preferedName: string;
    userResultCalculate: UserResultCalculateModel.AsProtobufJSON | null;
    emailVerified: boolean;
    holidayAdvisor: HolidayAdvisorModel.AsProtobufJSON | null;
    archived: boolean;
    hasCustomSegmentation: boolean;
  }

  /**
   * Message implementation for common_message.UserModel.TypesGoalsCreatedEntry
   */
  export class TypesGoalsCreatedEntry implements GrpcMessage {
    static id = 'common_message.UserModel.TypesGoalsCreatedEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new TypesGoalsCreatedEntry();
      TypesGoalsCreatedEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: TypesGoalsCreatedEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: TypesGoalsCreatedEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      TypesGoalsCreatedEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: TypesGoalsCreatedEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeString(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of TypesGoalsCreatedEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<TypesGoalsCreatedEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      TypesGoalsCreatedEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      TypesGoalsCreatedEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): TypesGoalsCreatedEntry.AsObject {
      return {
        key: this.key,
        value: this.value
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): TypesGoalsCreatedEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value
      };
    }
  }
  export module TypesGoalsCreatedEntry {
    /**
     * Standard JavaScript object representation for TypesGoalsCreatedEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for TypesGoalsCreatedEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}

/**
 * Message implementation for common_message.CompanyManagersModel
 */
export class CompanyManagersModel implements GrpcMessage {
  static id = 'common_message.CompanyManagersModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompanyManagersModel();
    CompanyManagersModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompanyManagersModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.companyUserId = _instance.companyUserId || 0;
    _instance.company = _instance.company || undefined;
    _instance.managerUserId = _instance.managerUserId || 0;
    _instance.manager = _instance.manager || undefined;
    _instance.isEnabled = _instance.isEnabled || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompanyManagersModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.companyUserId = _reader.readInt32();
          break;
        case 5:
          _instance.company = new UserModel();
          _reader.readMessage(
            _instance.company,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.managerUserId = _reader.readInt32();
          break;
        case 7:
          _instance.manager = new UserModel();
          _reader.readMessage(
            _instance.manager,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.isEnabled = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.isEnabled,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CompanyManagersModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompanyManagersModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.companyUserId) {
      _writer.writeInt32(4, _instance.companyUserId);
    }
    if (_instance.company) {
      _writer.writeMessage(
        5,
        _instance.company as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.managerUserId) {
      _writer.writeInt32(6, _instance.managerUserId);
    }
    if (_instance.manager) {
      _writer.writeMessage(
        7,
        _instance.manager as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.isEnabled) {
      _writer.writeMessage(
        8,
        _instance.isEnabled as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _companyUserId: number;
  private _company?: UserModel;
  private _managerUserId: number;
  private _manager?: UserModel;
  private _isEnabled?: googleProtobuf000.BoolValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompanyManagersModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<CompanyManagersModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.companyUserId = _value.companyUserId;
    this.company = _value.company ? new UserModel(_value.company) : undefined;
    this.managerUserId = _value.managerUserId;
    this.manager = _value.manager ? new UserModel(_value.manager) : undefined;
    this.isEnabled = _value.isEnabled
      ? new googleProtobuf000.BoolValue(_value.isEnabled)
      : undefined;
    CompanyManagersModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get companyUserId(): number {
    return this._companyUserId;
  }
  set companyUserId(value: number) {
    this._companyUserId = value;
  }
  get company(): UserModel | undefined {
    return this._company;
  }
  set company(value: UserModel | undefined) {
    this._company = value;
  }
  get managerUserId(): number {
    return this._managerUserId;
  }
  set managerUserId(value: number) {
    this._managerUserId = value;
  }
  get manager(): UserModel | undefined {
    return this._manager;
  }
  set manager(value: UserModel | undefined) {
    this._manager = value;
  }
  get isEnabled(): googleProtobuf000.BoolValue | undefined {
    return this._isEnabled;
  }
  set isEnabled(value: googleProtobuf000.BoolValue | undefined) {
    this._isEnabled = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompanyManagersModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompanyManagersModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      companyUserId: this.companyUserId,
      company: this.company ? this.company.toObject() : undefined,
      managerUserId: this.managerUserId,
      manager: this.manager ? this.manager.toObject() : undefined,
      isEnabled: this.isEnabled ? this.isEnabled.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompanyManagersModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      companyUserId: this.companyUserId,
      company: this.company ? this.company.toProtobufJSON(options) : null,
      managerUserId: this.managerUserId,
      manager: this.manager ? this.manager.toProtobufJSON(options) : null,
      isEnabled: this.isEnabled ? this.isEnabled.toProtobufJSON(options) : null
    };
  }
}
export module CompanyManagersModel {
  /**
   * Standard JavaScript object representation for CompanyManagersModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    companyUserId: number;
    company?: UserModel.AsObject;
    managerUserId: number;
    manager?: UserModel.AsObject;
    isEnabled?: googleProtobuf000.BoolValue.AsObject;
  }

  /**
   * Protobuf JSON representation for CompanyManagersModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    companyUserId: number;
    company: UserModel.AsProtobufJSON | null;
    managerUserId: number;
    manager: UserModel.AsProtobufJSON | null;
    isEnabled: googleProtobuf000.BoolValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.UserFinancialEntityModel
 */
export class UserFinancialEntityModel implements GrpcMessage {
  static id = 'common_message.UserFinancialEntityModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserFinancialEntityModel();
    UserFinancialEntityModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserFinancialEntityModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.userId = _instance.userId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.status = _instance.status || '';
    _instance.financialEntity = _instance.financialEntity || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserFinancialEntityModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.userId = _reader.readInt32();
          break;
        case 5:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 6:
          _instance.status = _reader.readString();
          break;
        case 7:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserFinancialEntityModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserFinancialEntityModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.userId) {
      _writer.writeInt32(4, _instance.userId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(5, _instance.financialEntityId);
    }
    if (_instance.status) {
      _writer.writeString(6, _instance.status);
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        7,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _userId: number;
  private _financialEntityId: number;
  private _status: string;
  private _financialEntity?: FinancialEntityModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserFinancialEntityModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserFinancialEntityModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.userId = _value.userId;
    this.financialEntityId = _value.financialEntityId;
    this.status = _value.status;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    UserFinancialEntityModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserFinancialEntityModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserFinancialEntityModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      status: this.status,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserFinancialEntityModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      userId: this.userId,
      financialEntityId: this.financialEntityId,
      status: this.status,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null
    };
  }
}
export module UserFinancialEntityModel {
  /**
   * Standard JavaScript object representation for UserFinancialEntityModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    userId: number;
    financialEntityId: number;
    status: string;
    financialEntity?: FinancialEntityModel.AsObject;
  }

  /**
   * Protobuf JSON representation for UserFinancialEntityModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    userId: number;
    financialEntityId: number;
    status: string;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.UserInformationModel
 */
export class UserInformationModel implements GrpcMessage {
  static id = 'common_message.UserInformationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserInformationModel();
    UserInformationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserInformationModel) {
    _instance.profession = _instance.profession || undefined;
    _instance.whatProfession = _instance.whatProfession || undefined;
    _instance.gender = _instance.gender || undefined;
    _instance.civilStatus = _instance.civilStatus || undefined;
    _instance.activity = _instance.activity || undefined;
    _instance.address = _instance.address || undefined;
    _instance.cityOfBirth = _instance.cityOfBirth || undefined;
    _instance.countryOfBirth = _instance.countryOfBirth || undefined;
    _instance.nationality = _instance.nationality || undefined;
    _instance.usPerson = _instance.usPerson || undefined;
    _instance.isPEP = _instance.isPEP || undefined;
    _instance.whoIsPEP = _instance.whoIsPEP || undefined;
    _instance.pepMePosition = _instance.pepMePosition || undefined;
    _instance.pepMeInstitution = _instance.pepMeInstitution || undefined;
    _instance.pepName = _instance.pepName || undefined;
    _instance.pepPosition = _instance.pepPosition || undefined;
    _instance.pepInstitution = _instance.pepInstitution || undefined;
    _instance.pepRelationship = _instance.pepRelationship || undefined;
    _instance.pepNameSecond = _instance.pepNameSecond || undefined;
    _instance.pepPositionSecond = _instance.pepPositionSecond || undefined;
    _instance.pepInstitutionSecond =
      _instance.pepInstitutionSecond || undefined;
    _instance.pepRelationshipSecond =
      _instance.pepRelationshipSecond || undefined;
    _instance.pepFromDate = _instance.pepFromDate || undefined;
    _instance.pepToDate = _instance.pepToDate || undefined;
    _instance.birthday = _instance.birthday || undefined;
    _instance.hasOtherNationality = _instance.hasOtherNationality || undefined;
    _instance.otherNationality = _instance.otherNationality || undefined;
    _instance.passportNumber = _instance.passportNumber || undefined;
    _instance.laborOld = _instance.laborOld || undefined;
    _instance.stakeCompany = _instance.stakeCompany || undefined;
    _instance.hasStakeCompany = _instance.hasStakeCompany || undefined;
    _instance.hasAnotherTaxResidence =
      _instance.hasAnotherTaxResidence || undefined;
    _instance.otherCountryTaxDomicile =
      _instance.otherCountryTaxDomicile || undefined;
    _instance.taxpayerNumber = _instance.taxpayerNumber || undefined;
    _instance.averageIncome = _instance.averageIncome || undefined;
    _instance.equityFinancialSystem =
      _instance.equityFinancialSystem || undefined;
    _instance.nonfinancialAssets = _instance.nonfinancialAssets || undefined;
    _instance.accountTypeTranfer = _instance.accountTypeTranfer || undefined;
    _instance.bankTranfer = _instance.bankTranfer || undefined;
    _instance.accountNumberTranfer =
      _instance.accountNumberTranfer || undefined;
    _instance.sourceFunds = _instance.sourceFunds || undefined;
    _instance.workCompanyName = _instance.workCompanyName || undefined;
    _instance.workRutCompany = _instance.workRutCompany || undefined;
    _instance.workJob = _instance.workJob || undefined;
    _instance.workAddress = _instance.workAddress || undefined;
    _instance.workProfession = _instance.workProfession || undefined;
    _instance.spouseNames = _instance.spouseNames || undefined;
    _instance.spouseRUT = _instance.spouseRUT || undefined;
    _instance.spouseBirthDate = _instance.spouseBirthDate || undefined;
    _instance.spouseActivity = _instance.spouseActivity || undefined;
    _instance.spouseNationality = _instance.spouseNationality || undefined;
    _instance.spouseAddress = _instance.spouseAddress || undefined;
    _instance.spousePhone = _instance.spousePhone || undefined;
    _instance.spousenEmail = _instance.spousenEmail || undefined;
    _instance.spouceWorkCompanyName =
      _instance.spouceWorkCompanyName || undefined;
    _instance.spouceWorkRutCompany =
      _instance.spouceWorkRutCompany || undefined;
    _instance.spouceWorkJob = _instance.spouceWorkJob || undefined;
    _instance.spouceWorkAddress = _instance.spouceWorkAddress || undefined;
    _instance.spouceWorkProfession =
      _instance.spouceWorkProfession || undefined;
    _instance.maritalRegime = _instance.maritalRegime || undefined;
    _instance.hasRelationshipWithStockbroker =
      _instance.hasRelationshipWithStockbroker || undefined;
    _instance.entityBusiness = _instance.entityBusiness || undefined;
    _instance.entityType = _instance.entityType || undefined;
    _instance.usNumberOfPeopleInCharge =
      _instance.usNumberOfPeopleInCharge || undefined;
    _instance.usEmploymentSituation =
      _instance.usEmploymentSituation || undefined;
    _instance.usEmploymentSituationDetail =
      _instance.usEmploymentSituationDetail || undefined;
    _instance.usEmployerName = _instance.usEmployerName || undefined;
    _instance.usEmploymentAddress = _instance.usEmploymentAddress || undefined;
    _instance.usEmploymentCountry = _instance.usEmploymentCountry || undefined;
    _instance.usEmploymentStateProvince =
      _instance.usEmploymentStateProvince || undefined;
    _instance.usEmploymentCommune = _instance.usEmploymentCommune || undefined;
    _instance.usEmploymentProfession =
      _instance.usEmploymentProfession || undefined;
    _instance.usEmploymentProfessionDetail =
      _instance.usEmploymentProfessionDetail || undefined;
    _instance.usCompanyActivity = _instance.usCompanyActivity || undefined;
    _instance.usCompanyActivityDetail =
      _instance.usCompanyActivityDetail || undefined;
    _instance.usTotalAnnualIncome = _instance.usTotalAnnualIncome || undefined;
    _instance.usNetPatrimony = _instance.usNetPatrimony || undefined;
    _instance.usLiquidPatrimony = _instance.usLiquidPatrimony || undefined;
    _instance.usComplianceEmail = _instance.usComplianceEmail || undefined;
    _instance.usCompliancePhone = _instance.usCompliancePhone || undefined;
    _instance.usSourceFunds = _instance.usSourceFunds || undefined;
    _instance.usAddressZipCode = _instance.usAddressZipCode || undefined;
    _instance.usWorkAddressZipCode =
      _instance.usWorkAddressZipCode || undefined;
    _instance.iBKRId = _instance.iBKRId || undefined;
    _instance.iBKRUser = _instance.iBKRUser || undefined;
    _instance.id = _instance.id || 0;
    _instance.ownerId = _instance.ownerId || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.regionId = _instance.regionId || undefined;
    _instance.spousenRegionId = _instance.spousenRegionId || undefined;
    _instance.workRegionId = _instance.workRegionId || undefined;
    _instance.communeId = _instance.communeId || undefined;
    _instance.spousenCommuneId = _instance.spousenCommuneId || undefined;
    _instance.workCommuneId = _instance.workCommuneId || undefined;
    _instance.region = _instance.region || undefined;
    _instance.commune = _instance.commune || undefined;
    _instance.fatcaUsTaxpayerIdentificationNumber =
      _instance.fatcaUsTaxpayerIdentificationNumber || undefined;
    _instance.fatcaReportAuthorization =
      _instance.fatcaReportAuthorization || undefined;
    _instance.otherCountryTaxDate = _instance.otherCountryTaxDate || undefined;
    _instance.potencialEquity = _instance.potencialEquity || undefined;
    _instance.incomeCode = _instance.incomeCode || undefined;
    _instance.patrimonyCode = _instance.patrimonyCode || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserInformationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.profession = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.profession,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.whatProfession = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.whatProfession,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.gender = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.gender,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.civilStatus = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.civilStatus,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.activity = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.activity,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.address = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.address,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.cityOfBirth = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.cityOfBirth,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.countryOfBirth = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.countryOfBirth,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.nationality = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.nationality,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.usPerson = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.usPerson,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.isPEP = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.isPEP,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.whoIsPEP = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.whoIsPEP,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.pepMePosition = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepMePosition,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.pepMeInstitution = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepMeInstitution,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.pepName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.pepPosition = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepPosition,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.pepInstitution = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepInstitution,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.pepRelationship = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepRelationship,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.pepNameSecond = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepNameSecond,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 20:
          _instance.pepPositionSecond = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepPositionSecond,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.pepInstitutionSecond = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepInstitutionSecond,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.pepRelationshipSecond = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.pepRelationshipSecond,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 23:
          _instance.pepFromDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.pepFromDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 24:
          _instance.pepToDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.pepToDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 25:
          _instance.birthday = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.birthday,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.hasOtherNationality = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.hasOtherNationality,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 27:
          _instance.otherNationality = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.otherNationality,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 28:
          _instance.passportNumber = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.passportNumber,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 29:
          _instance.laborOld = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.laborOld,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 30:
          _instance.stakeCompany = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.stakeCompany,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 31:
          _instance.hasStakeCompany = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.hasStakeCompany,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 32:
          _instance.hasAnotherTaxResidence = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.hasAnotherTaxResidence,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 33:
          _instance.otherCountryTaxDomicile = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.otherCountryTaxDomicile,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 34:
          _instance.taxpayerNumber = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.taxpayerNumber,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 35:
          _instance.averageIncome = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.averageIncome,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 36:
          _instance.equityFinancialSystem = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.equityFinancialSystem,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 37:
          _instance.nonfinancialAssets = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.nonfinancialAssets,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 38:
          _instance.accountTypeTranfer = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.accountTypeTranfer,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 39:
          _instance.bankTranfer = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.bankTranfer,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 40:
          _instance.accountNumberTranfer = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.accountNumberTranfer,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 41:
          _instance.sourceFunds = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.sourceFunds,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 42:
          _instance.workCompanyName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workCompanyName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 43:
          _instance.workRutCompany = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workRutCompany,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 44:
          _instance.workJob = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workJob,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 45:
          _instance.workAddress = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workAddress,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 46:
          _instance.workProfession = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workProfession,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 47:
          _instance.spouseNames = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouseNames,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 48:
          _instance.spouseRUT = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouseRUT,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 49:
          _instance.spouseBirthDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.spouseBirthDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 50:
          _instance.spouseActivity = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouseActivity,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 51:
          _instance.spouseNationality = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouseNationality,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 52:
          _instance.spouseAddress = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouseAddress,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 53:
          _instance.spousePhone = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spousePhone,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 54:
          _instance.spousenEmail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spousenEmail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 55:
          _instance.spouceWorkCompanyName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouceWorkCompanyName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 56:
          _instance.spouceWorkRutCompany = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouceWorkRutCompany,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 57:
          _instance.spouceWorkJob = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouceWorkJob,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 58:
          _instance.spouceWorkAddress = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouceWorkAddress,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 59:
          _instance.spouceWorkProfession = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.spouceWorkProfession,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 60:
          _instance.maritalRegime = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.maritalRegime,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 61:
          _instance.hasRelationshipWithStockbroker = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.hasRelationshipWithStockbroker,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 62:
          _instance.entityBusiness = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.entityBusiness,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 63:
          _instance.entityType = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.entityType,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 64:
          _instance.usNumberOfPeopleInCharge = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.usNumberOfPeopleInCharge,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 65:
          _instance.usEmploymentSituation = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentSituation,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 66:
          _instance.usEmploymentSituationDetail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentSituationDetail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 67:
          _instance.usEmployerName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmployerName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 68:
          _instance.usEmploymentAddress = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentAddress,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 69:
          _instance.usEmploymentCountry = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentCountry,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 70:
          _instance.usEmploymentStateProvince = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentStateProvince,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 71:
          _instance.usEmploymentCommune = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentCommune,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 72:
          _instance.usEmploymentProfession = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentProfession,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 73:
          _instance.usEmploymentProfessionDetail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentProfessionDetail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 74:
          _instance.usCompanyActivity = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usCompanyActivity,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 75:
          _instance.usCompanyActivityDetail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usCompanyActivityDetail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 76:
          _instance.usTotalAnnualIncome = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usTotalAnnualIncome,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 77:
          _instance.usNetPatrimony = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usNetPatrimony,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 78:
          _instance.usLiquidPatrimony = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usLiquidPatrimony,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 79:
          _instance.usComplianceEmail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usComplianceEmail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 80:
          _instance.usCompliancePhone = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usCompliancePhone,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 81:
          _instance.usSourceFunds = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usSourceFunds,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 82:
          _instance.usAddressZipCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usAddressZipCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 83:
          _instance.usWorkAddressZipCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usWorkAddressZipCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 84:
          _instance.iBKRId = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.iBKRId,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 85:
          _instance.iBKRUser = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.iBKRUser,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 86:
          _instance.id = _reader.readInt32();
          break;
        case 87:
          _instance.ownerId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.ownerId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 88:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 89:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 90:
          _instance.regionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.regionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 91:
          _instance.spousenRegionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.spousenRegionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 92:
          _instance.workRegionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.workRegionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 93:
          _instance.communeId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.communeId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 94:
          _instance.spousenCommuneId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.spousenCommuneId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 95:
          _instance.workCommuneId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.workCommuneId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 96:
          _instance.region = new RegionModel();
          _reader.readMessage(
            _instance.region,
            RegionModel.deserializeBinaryFromReader
          );
          break;
        case 97:
          _instance.commune = new CommuneModel();
          _reader.readMessage(
            _instance.commune,
            CommuneModel.deserializeBinaryFromReader
          );
          break;
        case 98:
          _instance.fatcaUsTaxpayerIdentificationNumber = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.fatcaUsTaxpayerIdentificationNumber,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 99:
          _instance.fatcaReportAuthorization = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.fatcaReportAuthorization,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 100:
          _instance.otherCountryTaxDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.otherCountryTaxDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 101:
          _instance.potencialEquity = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.potencialEquity,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 102:
          _instance.incomeCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.incomeCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 103:
          _instance.patrimonyCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.patrimonyCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserInformationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserInformationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.profession) {
      _writer.writeMessage(
        1,
        _instance.profession as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.whatProfession) {
      _writer.writeMessage(
        2,
        _instance.whatProfession as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.gender) {
      _writer.writeMessage(
        3,
        _instance.gender as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.civilStatus) {
      _writer.writeMessage(
        4,
        _instance.civilStatus as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.activity) {
      _writer.writeMessage(
        5,
        _instance.activity as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.address) {
      _writer.writeMessage(
        6,
        _instance.address as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.cityOfBirth) {
      _writer.writeMessage(
        7,
        _instance.cityOfBirth as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.countryOfBirth) {
      _writer.writeMessage(
        8,
        _instance.countryOfBirth as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.nationality) {
      _writer.writeMessage(
        9,
        _instance.nationality as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usPerson) {
      _writer.writeMessage(
        10,
        _instance.usPerson as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.isPEP) {
      _writer.writeMessage(
        11,
        _instance.isPEP as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.whoIsPEP) {
      _writer.writeMessage(
        12,
        _instance.whoIsPEP as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepMePosition) {
      _writer.writeMessage(
        13,
        _instance.pepMePosition as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepMeInstitution) {
      _writer.writeMessage(
        14,
        _instance.pepMeInstitution as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepName) {
      _writer.writeMessage(
        15,
        _instance.pepName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepPosition) {
      _writer.writeMessage(
        16,
        _instance.pepPosition as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepInstitution) {
      _writer.writeMessage(
        17,
        _instance.pepInstitution as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepRelationship) {
      _writer.writeMessage(
        18,
        _instance.pepRelationship as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepNameSecond) {
      _writer.writeMessage(
        19,
        _instance.pepNameSecond as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepPositionSecond) {
      _writer.writeMessage(
        20,
        _instance.pepPositionSecond as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepInstitutionSecond) {
      _writer.writeMessage(
        21,
        _instance.pepInstitutionSecond as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepRelationshipSecond) {
      _writer.writeMessage(
        22,
        _instance.pepRelationshipSecond as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.pepFromDate) {
      _writer.writeMessage(
        23,
        _instance.pepFromDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.pepToDate) {
      _writer.writeMessage(
        24,
        _instance.pepToDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.birthday) {
      _writer.writeMessage(
        25,
        _instance.birthday as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.hasOtherNationality) {
      _writer.writeMessage(
        26,
        _instance.hasOtherNationality as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.otherNationality) {
      _writer.writeMessage(
        27,
        _instance.otherNationality as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.passportNumber) {
      _writer.writeMessage(
        28,
        _instance.passportNumber as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.laborOld) {
      _writer.writeMessage(
        29,
        _instance.laborOld as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.stakeCompany) {
      _writer.writeMessage(
        30,
        _instance.stakeCompany as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.hasStakeCompany) {
      _writer.writeMessage(
        31,
        _instance.hasStakeCompany as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.hasAnotherTaxResidence) {
      _writer.writeMessage(
        32,
        _instance.hasAnotherTaxResidence as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.otherCountryTaxDomicile) {
      _writer.writeMessage(
        33,
        _instance.otherCountryTaxDomicile as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.taxpayerNumber) {
      _writer.writeMessage(
        34,
        _instance.taxpayerNumber as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.averageIncome) {
      _writer.writeMessage(
        35,
        _instance.averageIncome as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.equityFinancialSystem) {
      _writer.writeMessage(
        36,
        _instance.equityFinancialSystem as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.nonfinancialAssets) {
      _writer.writeMessage(
        37,
        _instance.nonfinancialAssets as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.accountTypeTranfer) {
      _writer.writeMessage(
        38,
        _instance.accountTypeTranfer as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.bankTranfer) {
      _writer.writeMessage(
        39,
        _instance.bankTranfer as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.accountNumberTranfer) {
      _writer.writeMessage(
        40,
        _instance.accountNumberTranfer as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.sourceFunds) {
      _writer.writeMessage(
        41,
        _instance.sourceFunds as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workCompanyName) {
      _writer.writeMessage(
        42,
        _instance.workCompanyName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workRutCompany) {
      _writer.writeMessage(
        43,
        _instance.workRutCompany as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workJob) {
      _writer.writeMessage(
        44,
        _instance.workJob as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workAddress) {
      _writer.writeMessage(
        45,
        _instance.workAddress as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workProfession) {
      _writer.writeMessage(
        46,
        _instance.workProfession as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouseNames) {
      _writer.writeMessage(
        47,
        _instance.spouseNames as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouseRUT) {
      _writer.writeMessage(
        48,
        _instance.spouseRUT as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouseBirthDate) {
      _writer.writeMessage(
        49,
        _instance.spouseBirthDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.spouseActivity) {
      _writer.writeMessage(
        50,
        _instance.spouseActivity as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouseNationality) {
      _writer.writeMessage(
        51,
        _instance.spouseNationality as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouseAddress) {
      _writer.writeMessage(
        52,
        _instance.spouseAddress as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spousePhone) {
      _writer.writeMessage(
        53,
        _instance.spousePhone as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spousenEmail) {
      _writer.writeMessage(
        54,
        _instance.spousenEmail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouceWorkCompanyName) {
      _writer.writeMessage(
        55,
        _instance.spouceWorkCompanyName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouceWorkRutCompany) {
      _writer.writeMessage(
        56,
        _instance.spouceWorkRutCompany as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouceWorkJob) {
      _writer.writeMessage(
        57,
        _instance.spouceWorkJob as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouceWorkAddress) {
      _writer.writeMessage(
        58,
        _instance.spouceWorkAddress as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.spouceWorkProfession) {
      _writer.writeMessage(
        59,
        _instance.spouceWorkProfession as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.maritalRegime) {
      _writer.writeMessage(
        60,
        _instance.maritalRegime as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.hasRelationshipWithStockbroker) {
      _writer.writeMessage(
        61,
        _instance.hasRelationshipWithStockbroker as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.entityBusiness) {
      _writer.writeMessage(
        62,
        _instance.entityBusiness as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.entityType) {
      _writer.writeMessage(
        63,
        _instance.entityType as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usNumberOfPeopleInCharge) {
      _writer.writeMessage(
        64,
        _instance.usNumberOfPeopleInCharge as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentSituation) {
      _writer.writeMessage(
        65,
        _instance.usEmploymentSituation as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentSituationDetail) {
      _writer.writeMessage(
        66,
        _instance.usEmploymentSituationDetail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmployerName) {
      _writer.writeMessage(
        67,
        _instance.usEmployerName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentAddress) {
      _writer.writeMessage(
        68,
        _instance.usEmploymentAddress as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentCountry) {
      _writer.writeMessage(
        69,
        _instance.usEmploymentCountry as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentStateProvince) {
      _writer.writeMessage(
        70,
        _instance.usEmploymentStateProvince as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentCommune) {
      _writer.writeMessage(
        71,
        _instance.usEmploymentCommune as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentProfession) {
      _writer.writeMessage(
        72,
        _instance.usEmploymentProfession as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentProfessionDetail) {
      _writer.writeMessage(
        73,
        _instance.usEmploymentProfessionDetail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usCompanyActivity) {
      _writer.writeMessage(
        74,
        _instance.usCompanyActivity as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usCompanyActivityDetail) {
      _writer.writeMessage(
        75,
        _instance.usCompanyActivityDetail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usTotalAnnualIncome) {
      _writer.writeMessage(
        76,
        _instance.usTotalAnnualIncome as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usNetPatrimony) {
      _writer.writeMessage(
        77,
        _instance.usNetPatrimony as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usLiquidPatrimony) {
      _writer.writeMessage(
        78,
        _instance.usLiquidPatrimony as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usComplianceEmail) {
      _writer.writeMessage(
        79,
        _instance.usComplianceEmail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usCompliancePhone) {
      _writer.writeMessage(
        80,
        _instance.usCompliancePhone as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usSourceFunds) {
      _writer.writeMessage(
        81,
        _instance.usSourceFunds as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usAddressZipCode) {
      _writer.writeMessage(
        82,
        _instance.usAddressZipCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usWorkAddressZipCode) {
      _writer.writeMessage(
        83,
        _instance.usWorkAddressZipCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.iBKRId) {
      _writer.writeMessage(
        84,
        _instance.iBKRId as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.iBKRUser) {
      _writer.writeMessage(
        85,
        _instance.iBKRUser as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(86, _instance.id);
    }
    if (_instance.ownerId) {
      _writer.writeMessage(
        87,
        _instance.ownerId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        88,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        89,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.regionId) {
      _writer.writeMessage(
        90,
        _instance.regionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.spousenRegionId) {
      _writer.writeMessage(
        91,
        _instance.spousenRegionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.workRegionId) {
      _writer.writeMessage(
        92,
        _instance.workRegionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.communeId) {
      _writer.writeMessage(
        93,
        _instance.communeId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.spousenCommuneId) {
      _writer.writeMessage(
        94,
        _instance.spousenCommuneId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.workCommuneId) {
      _writer.writeMessage(
        95,
        _instance.workCommuneId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.region) {
      _writer.writeMessage(
        96,
        _instance.region as any,
        RegionModel.serializeBinaryToWriter
      );
    }
    if (_instance.commune) {
      _writer.writeMessage(
        97,
        _instance.commune as any,
        CommuneModel.serializeBinaryToWriter
      );
    }
    if (_instance.fatcaUsTaxpayerIdentificationNumber) {
      _writer.writeMessage(
        98,
        _instance.fatcaUsTaxpayerIdentificationNumber as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.fatcaReportAuthorization) {
      _writer.writeMessage(
        99,
        _instance.fatcaReportAuthorization as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.otherCountryTaxDate) {
      _writer.writeMessage(
        100,
        _instance.otherCountryTaxDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.potencialEquity) {
      _writer.writeMessage(
        101,
        _instance.potencialEquity as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.incomeCode) {
      _writer.writeMessage(
        102,
        _instance.incomeCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.patrimonyCode) {
      _writer.writeMessage(
        103,
        _instance.patrimonyCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _profession?: googleProtobuf000.BoolValue;
  private _whatProfession?: googleProtobuf000.StringValue;
  private _gender?: googleProtobuf000.StringValue;
  private _civilStatus?: googleProtobuf000.StringValue;
  private _activity?: googleProtobuf000.StringValue;
  private _address?: googleProtobuf000.StringValue;
  private _cityOfBirth?: googleProtobuf000.StringValue;
  private _countryOfBirth?: googleProtobuf000.StringValue;
  private _nationality?: googleProtobuf000.StringValue;
  private _usPerson?: googleProtobuf000.BoolValue;
  private _isPEP?: googleProtobuf000.BoolValue;
  private _whoIsPEP?: googleProtobuf000.StringValue;
  private _pepMePosition?: googleProtobuf000.StringValue;
  private _pepMeInstitution?: googleProtobuf000.StringValue;
  private _pepName?: googleProtobuf000.StringValue;
  private _pepPosition?: googleProtobuf000.StringValue;
  private _pepInstitution?: googleProtobuf000.StringValue;
  private _pepRelationship?: googleProtobuf000.StringValue;
  private _pepNameSecond?: googleProtobuf000.StringValue;
  private _pepPositionSecond?: googleProtobuf000.StringValue;
  private _pepInstitutionSecond?: googleProtobuf000.StringValue;
  private _pepRelationshipSecond?: googleProtobuf000.StringValue;
  private _pepFromDate?: googleProtobuf001.Timestamp;
  private _pepToDate?: googleProtobuf001.Timestamp;
  private _birthday?: googleProtobuf001.Timestamp;
  private _hasOtherNationality?: googleProtobuf000.BoolValue;
  private _otherNationality?: googleProtobuf000.StringValue;
  private _passportNumber?: googleProtobuf000.StringValue;
  private _laborOld?: googleProtobuf000.StringValue;
  private _stakeCompany?: googleProtobuf000.StringValue;
  private _hasStakeCompany?: googleProtobuf000.BoolValue;
  private _hasAnotherTaxResidence?: googleProtobuf000.BoolValue;
  private _otherCountryTaxDomicile?: googleProtobuf000.StringValue;
  private _taxpayerNumber?: googleProtobuf000.StringValue;
  private _averageIncome?: googleProtobuf000.StringValue;
  private _equityFinancialSystem?: googleProtobuf000.StringValue;
  private _nonfinancialAssets?: googleProtobuf000.StringValue;
  private _accountTypeTranfer?: googleProtobuf000.StringValue;
  private _bankTranfer?: googleProtobuf000.StringValue;
  private _accountNumberTranfer?: googleProtobuf000.StringValue;
  private _sourceFunds?: googleProtobuf000.StringValue;
  private _workCompanyName?: googleProtobuf000.StringValue;
  private _workRutCompany?: googleProtobuf000.StringValue;
  private _workJob?: googleProtobuf000.StringValue;
  private _workAddress?: googleProtobuf000.StringValue;
  private _workProfession?: googleProtobuf000.StringValue;
  private _spouseNames?: googleProtobuf000.StringValue;
  private _spouseRUT?: googleProtobuf000.StringValue;
  private _spouseBirthDate?: googleProtobuf001.Timestamp;
  private _spouseActivity?: googleProtobuf000.StringValue;
  private _spouseNationality?: googleProtobuf000.StringValue;
  private _spouseAddress?: googleProtobuf000.StringValue;
  private _spousePhone?: googleProtobuf000.StringValue;
  private _spousenEmail?: googleProtobuf000.StringValue;
  private _spouceWorkCompanyName?: googleProtobuf000.StringValue;
  private _spouceWorkRutCompany?: googleProtobuf000.StringValue;
  private _spouceWorkJob?: googleProtobuf000.StringValue;
  private _spouceWorkAddress?: googleProtobuf000.StringValue;
  private _spouceWorkProfession?: googleProtobuf000.StringValue;
  private _maritalRegime?: googleProtobuf000.StringValue;
  private _hasRelationshipWithStockbroker?: googleProtobuf000.BoolValue;
  private _entityBusiness?: googleProtobuf000.StringValue;
  private _entityType?: googleProtobuf000.StringValue;
  private _usNumberOfPeopleInCharge?: googleProtobuf000.Int32Value;
  private _usEmploymentSituation?: googleProtobuf000.StringValue;
  private _usEmploymentSituationDetail?: googleProtobuf000.StringValue;
  private _usEmployerName?: googleProtobuf000.StringValue;
  private _usEmploymentAddress?: googleProtobuf000.StringValue;
  private _usEmploymentCountry?: googleProtobuf000.StringValue;
  private _usEmploymentStateProvince?: googleProtobuf000.StringValue;
  private _usEmploymentCommune?: googleProtobuf000.StringValue;
  private _usEmploymentProfession?: googleProtobuf000.StringValue;
  private _usEmploymentProfessionDetail?: googleProtobuf000.StringValue;
  private _usCompanyActivity?: googleProtobuf000.StringValue;
  private _usCompanyActivityDetail?: googleProtobuf000.StringValue;
  private _usTotalAnnualIncome?: googleProtobuf000.StringValue;
  private _usNetPatrimony?: googleProtobuf000.StringValue;
  private _usLiquidPatrimony?: googleProtobuf000.StringValue;
  private _usComplianceEmail?: googleProtobuf000.StringValue;
  private _usCompliancePhone?: googleProtobuf000.StringValue;
  private _usSourceFunds?: googleProtobuf000.StringValue;
  private _usAddressZipCode?: googleProtobuf000.StringValue;
  private _usWorkAddressZipCode?: googleProtobuf000.StringValue;
  private _iBKRId?: googleProtobuf000.StringValue;
  private _iBKRUser?: googleProtobuf000.StringValue;
  private _id: number;
  private _ownerId?: googleProtobuf000.Int32Value;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _regionId?: googleProtobuf000.Int32Value;
  private _spousenRegionId?: googleProtobuf000.Int32Value;
  private _workRegionId?: googleProtobuf000.Int32Value;
  private _communeId?: googleProtobuf000.Int32Value;
  private _spousenCommuneId?: googleProtobuf000.Int32Value;
  private _workCommuneId?: googleProtobuf000.Int32Value;
  private _region?: RegionModel;
  private _commune?: CommuneModel;
  private _fatcaUsTaxpayerIdentificationNumber?: googleProtobuf000.StringValue;
  private _fatcaReportAuthorization?: googleProtobuf000.BoolValue;
  private _otherCountryTaxDate?: googleProtobuf001.Timestamp;
  private _potencialEquity?: googleProtobuf000.StringValue;
  private _incomeCode?: googleProtobuf000.StringValue;
  private _patrimonyCode?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserInformationModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserInformationModel.AsObject>) {
    _value = _value || {};
    this.profession = _value.profession
      ? new googleProtobuf000.BoolValue(_value.profession)
      : undefined;
    this.whatProfession = _value.whatProfession
      ? new googleProtobuf000.StringValue(_value.whatProfession)
      : undefined;
    this.gender = _value.gender
      ? new googleProtobuf000.StringValue(_value.gender)
      : undefined;
    this.civilStatus = _value.civilStatus
      ? new googleProtobuf000.StringValue(_value.civilStatus)
      : undefined;
    this.activity = _value.activity
      ? new googleProtobuf000.StringValue(_value.activity)
      : undefined;
    this.address = _value.address
      ? new googleProtobuf000.StringValue(_value.address)
      : undefined;
    this.cityOfBirth = _value.cityOfBirth
      ? new googleProtobuf000.StringValue(_value.cityOfBirth)
      : undefined;
    this.countryOfBirth = _value.countryOfBirth
      ? new googleProtobuf000.StringValue(_value.countryOfBirth)
      : undefined;
    this.nationality = _value.nationality
      ? new googleProtobuf000.StringValue(_value.nationality)
      : undefined;
    this.usPerson = _value.usPerson
      ? new googleProtobuf000.BoolValue(_value.usPerson)
      : undefined;
    this.isPEP = _value.isPEP
      ? new googleProtobuf000.BoolValue(_value.isPEP)
      : undefined;
    this.whoIsPEP = _value.whoIsPEP
      ? new googleProtobuf000.StringValue(_value.whoIsPEP)
      : undefined;
    this.pepMePosition = _value.pepMePosition
      ? new googleProtobuf000.StringValue(_value.pepMePosition)
      : undefined;
    this.pepMeInstitution = _value.pepMeInstitution
      ? new googleProtobuf000.StringValue(_value.pepMeInstitution)
      : undefined;
    this.pepName = _value.pepName
      ? new googleProtobuf000.StringValue(_value.pepName)
      : undefined;
    this.pepPosition = _value.pepPosition
      ? new googleProtobuf000.StringValue(_value.pepPosition)
      : undefined;
    this.pepInstitution = _value.pepInstitution
      ? new googleProtobuf000.StringValue(_value.pepInstitution)
      : undefined;
    this.pepRelationship = _value.pepRelationship
      ? new googleProtobuf000.StringValue(_value.pepRelationship)
      : undefined;
    this.pepNameSecond = _value.pepNameSecond
      ? new googleProtobuf000.StringValue(_value.pepNameSecond)
      : undefined;
    this.pepPositionSecond = _value.pepPositionSecond
      ? new googleProtobuf000.StringValue(_value.pepPositionSecond)
      : undefined;
    this.pepInstitutionSecond = _value.pepInstitutionSecond
      ? new googleProtobuf000.StringValue(_value.pepInstitutionSecond)
      : undefined;
    this.pepRelationshipSecond = _value.pepRelationshipSecond
      ? new googleProtobuf000.StringValue(_value.pepRelationshipSecond)
      : undefined;
    this.pepFromDate = _value.pepFromDate
      ? new googleProtobuf001.Timestamp(_value.pepFromDate)
      : undefined;
    this.pepToDate = _value.pepToDate
      ? new googleProtobuf001.Timestamp(_value.pepToDate)
      : undefined;
    this.birthday = _value.birthday
      ? new googleProtobuf001.Timestamp(_value.birthday)
      : undefined;
    this.hasOtherNationality = _value.hasOtherNationality
      ? new googleProtobuf000.BoolValue(_value.hasOtherNationality)
      : undefined;
    this.otherNationality = _value.otherNationality
      ? new googleProtobuf000.StringValue(_value.otherNationality)
      : undefined;
    this.passportNumber = _value.passportNumber
      ? new googleProtobuf000.StringValue(_value.passportNumber)
      : undefined;
    this.laborOld = _value.laborOld
      ? new googleProtobuf000.StringValue(_value.laborOld)
      : undefined;
    this.stakeCompany = _value.stakeCompany
      ? new googleProtobuf000.StringValue(_value.stakeCompany)
      : undefined;
    this.hasStakeCompany = _value.hasStakeCompany
      ? new googleProtobuf000.BoolValue(_value.hasStakeCompany)
      : undefined;
    this.hasAnotherTaxResidence = _value.hasAnotherTaxResidence
      ? new googleProtobuf000.BoolValue(_value.hasAnotherTaxResidence)
      : undefined;
    this.otherCountryTaxDomicile = _value.otherCountryTaxDomicile
      ? new googleProtobuf000.StringValue(_value.otherCountryTaxDomicile)
      : undefined;
    this.taxpayerNumber = _value.taxpayerNumber
      ? new googleProtobuf000.StringValue(_value.taxpayerNumber)
      : undefined;
    this.averageIncome = _value.averageIncome
      ? new googleProtobuf000.StringValue(_value.averageIncome)
      : undefined;
    this.equityFinancialSystem = _value.equityFinancialSystem
      ? new googleProtobuf000.StringValue(_value.equityFinancialSystem)
      : undefined;
    this.nonfinancialAssets = _value.nonfinancialAssets
      ? new googleProtobuf000.StringValue(_value.nonfinancialAssets)
      : undefined;
    this.accountTypeTranfer = _value.accountTypeTranfer
      ? new googleProtobuf000.StringValue(_value.accountTypeTranfer)
      : undefined;
    this.bankTranfer = _value.bankTranfer
      ? new googleProtobuf000.StringValue(_value.bankTranfer)
      : undefined;
    this.accountNumberTranfer = _value.accountNumberTranfer
      ? new googleProtobuf000.StringValue(_value.accountNumberTranfer)
      : undefined;
    this.sourceFunds = _value.sourceFunds
      ? new googleProtobuf000.StringValue(_value.sourceFunds)
      : undefined;
    this.workCompanyName = _value.workCompanyName
      ? new googleProtobuf000.StringValue(_value.workCompanyName)
      : undefined;
    this.workRutCompany = _value.workRutCompany
      ? new googleProtobuf000.StringValue(_value.workRutCompany)
      : undefined;
    this.workJob = _value.workJob
      ? new googleProtobuf000.StringValue(_value.workJob)
      : undefined;
    this.workAddress = _value.workAddress
      ? new googleProtobuf000.StringValue(_value.workAddress)
      : undefined;
    this.workProfession = _value.workProfession
      ? new googleProtobuf000.StringValue(_value.workProfession)
      : undefined;
    this.spouseNames = _value.spouseNames
      ? new googleProtobuf000.StringValue(_value.spouseNames)
      : undefined;
    this.spouseRUT = _value.spouseRUT
      ? new googleProtobuf000.StringValue(_value.spouseRUT)
      : undefined;
    this.spouseBirthDate = _value.spouseBirthDate
      ? new googleProtobuf001.Timestamp(_value.spouseBirthDate)
      : undefined;
    this.spouseActivity = _value.spouseActivity
      ? new googleProtobuf000.StringValue(_value.spouseActivity)
      : undefined;
    this.spouseNationality = _value.spouseNationality
      ? new googleProtobuf000.StringValue(_value.spouseNationality)
      : undefined;
    this.spouseAddress = _value.spouseAddress
      ? new googleProtobuf000.StringValue(_value.spouseAddress)
      : undefined;
    this.spousePhone = _value.spousePhone
      ? new googleProtobuf000.StringValue(_value.spousePhone)
      : undefined;
    this.spousenEmail = _value.spousenEmail
      ? new googleProtobuf000.StringValue(_value.spousenEmail)
      : undefined;
    this.spouceWorkCompanyName = _value.spouceWorkCompanyName
      ? new googleProtobuf000.StringValue(_value.spouceWorkCompanyName)
      : undefined;
    this.spouceWorkRutCompany = _value.spouceWorkRutCompany
      ? new googleProtobuf000.StringValue(_value.spouceWorkRutCompany)
      : undefined;
    this.spouceWorkJob = _value.spouceWorkJob
      ? new googleProtobuf000.StringValue(_value.spouceWorkJob)
      : undefined;
    this.spouceWorkAddress = _value.spouceWorkAddress
      ? new googleProtobuf000.StringValue(_value.spouceWorkAddress)
      : undefined;
    this.spouceWorkProfession = _value.spouceWorkProfession
      ? new googleProtobuf000.StringValue(_value.spouceWorkProfession)
      : undefined;
    this.maritalRegime = _value.maritalRegime
      ? new googleProtobuf000.StringValue(_value.maritalRegime)
      : undefined;
    this.hasRelationshipWithStockbroker = _value.hasRelationshipWithStockbroker
      ? new googleProtobuf000.BoolValue(_value.hasRelationshipWithStockbroker)
      : undefined;
    this.entityBusiness = _value.entityBusiness
      ? new googleProtobuf000.StringValue(_value.entityBusiness)
      : undefined;
    this.entityType = _value.entityType
      ? new googleProtobuf000.StringValue(_value.entityType)
      : undefined;
    this.usNumberOfPeopleInCharge = _value.usNumberOfPeopleInCharge
      ? new googleProtobuf000.Int32Value(_value.usNumberOfPeopleInCharge)
      : undefined;
    this.usEmploymentSituation = _value.usEmploymentSituation
      ? new googleProtobuf000.StringValue(_value.usEmploymentSituation)
      : undefined;
    this.usEmploymentSituationDetail = _value.usEmploymentSituationDetail
      ? new googleProtobuf000.StringValue(_value.usEmploymentSituationDetail)
      : undefined;
    this.usEmployerName = _value.usEmployerName
      ? new googleProtobuf000.StringValue(_value.usEmployerName)
      : undefined;
    this.usEmploymentAddress = _value.usEmploymentAddress
      ? new googleProtobuf000.StringValue(_value.usEmploymentAddress)
      : undefined;
    this.usEmploymentCountry = _value.usEmploymentCountry
      ? new googleProtobuf000.StringValue(_value.usEmploymentCountry)
      : undefined;
    this.usEmploymentStateProvince = _value.usEmploymentStateProvince
      ? new googleProtobuf000.StringValue(_value.usEmploymentStateProvince)
      : undefined;
    this.usEmploymentCommune = _value.usEmploymentCommune
      ? new googleProtobuf000.StringValue(_value.usEmploymentCommune)
      : undefined;
    this.usEmploymentProfession = _value.usEmploymentProfession
      ? new googleProtobuf000.StringValue(_value.usEmploymentProfession)
      : undefined;
    this.usEmploymentProfessionDetail = _value.usEmploymentProfessionDetail
      ? new googleProtobuf000.StringValue(_value.usEmploymentProfessionDetail)
      : undefined;
    this.usCompanyActivity = _value.usCompanyActivity
      ? new googleProtobuf000.StringValue(_value.usCompanyActivity)
      : undefined;
    this.usCompanyActivityDetail = _value.usCompanyActivityDetail
      ? new googleProtobuf000.StringValue(_value.usCompanyActivityDetail)
      : undefined;
    this.usTotalAnnualIncome = _value.usTotalAnnualIncome
      ? new googleProtobuf000.StringValue(_value.usTotalAnnualIncome)
      : undefined;
    this.usNetPatrimony = _value.usNetPatrimony
      ? new googleProtobuf000.StringValue(_value.usNetPatrimony)
      : undefined;
    this.usLiquidPatrimony = _value.usLiquidPatrimony
      ? new googleProtobuf000.StringValue(_value.usLiquidPatrimony)
      : undefined;
    this.usComplianceEmail = _value.usComplianceEmail
      ? new googleProtobuf000.StringValue(_value.usComplianceEmail)
      : undefined;
    this.usCompliancePhone = _value.usCompliancePhone
      ? new googleProtobuf000.StringValue(_value.usCompliancePhone)
      : undefined;
    this.usSourceFunds = _value.usSourceFunds
      ? new googleProtobuf000.StringValue(_value.usSourceFunds)
      : undefined;
    this.usAddressZipCode = _value.usAddressZipCode
      ? new googleProtobuf000.StringValue(_value.usAddressZipCode)
      : undefined;
    this.usWorkAddressZipCode = _value.usWorkAddressZipCode
      ? new googleProtobuf000.StringValue(_value.usWorkAddressZipCode)
      : undefined;
    this.iBKRId = _value.iBKRId
      ? new googleProtobuf000.StringValue(_value.iBKRId)
      : undefined;
    this.iBKRUser = _value.iBKRUser
      ? new googleProtobuf000.StringValue(_value.iBKRUser)
      : undefined;
    this.id = _value.id;
    this.ownerId = _value.ownerId
      ? new googleProtobuf000.Int32Value(_value.ownerId)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.regionId = _value.regionId
      ? new googleProtobuf000.Int32Value(_value.regionId)
      : undefined;
    this.spousenRegionId = _value.spousenRegionId
      ? new googleProtobuf000.Int32Value(_value.spousenRegionId)
      : undefined;
    this.workRegionId = _value.workRegionId
      ? new googleProtobuf000.Int32Value(_value.workRegionId)
      : undefined;
    this.communeId = _value.communeId
      ? new googleProtobuf000.Int32Value(_value.communeId)
      : undefined;
    this.spousenCommuneId = _value.spousenCommuneId
      ? new googleProtobuf000.Int32Value(_value.spousenCommuneId)
      : undefined;
    this.workCommuneId = _value.workCommuneId
      ? new googleProtobuf000.Int32Value(_value.workCommuneId)
      : undefined;
    this.region = _value.region ? new RegionModel(_value.region) : undefined;
    this.commune = _value.commune
      ? new CommuneModel(_value.commune)
      : undefined;
    this.fatcaUsTaxpayerIdentificationNumber = _value.fatcaUsTaxpayerIdentificationNumber
      ? new googleProtobuf000.StringValue(
          _value.fatcaUsTaxpayerIdentificationNumber
        )
      : undefined;
    this.fatcaReportAuthorization = _value.fatcaReportAuthorization
      ? new googleProtobuf000.BoolValue(_value.fatcaReportAuthorization)
      : undefined;
    this.otherCountryTaxDate = _value.otherCountryTaxDate
      ? new googleProtobuf001.Timestamp(_value.otherCountryTaxDate)
      : undefined;
    this.potencialEquity = _value.potencialEquity
      ? new googleProtobuf000.StringValue(_value.potencialEquity)
      : undefined;
    this.incomeCode = _value.incomeCode
      ? new googleProtobuf000.StringValue(_value.incomeCode)
      : undefined;
    this.patrimonyCode = _value.patrimonyCode
      ? new googleProtobuf000.StringValue(_value.patrimonyCode)
      : undefined;
    UserInformationModel.refineValues(this);
  }
  get profession(): googleProtobuf000.BoolValue | undefined {
    return this._profession;
  }
  set profession(value: googleProtobuf000.BoolValue | undefined) {
    this._profession = value;
  }
  get whatProfession(): googleProtobuf000.StringValue | undefined {
    return this._whatProfession;
  }
  set whatProfession(value: googleProtobuf000.StringValue | undefined) {
    this._whatProfession = value;
  }
  get gender(): googleProtobuf000.StringValue | undefined {
    return this._gender;
  }
  set gender(value: googleProtobuf000.StringValue | undefined) {
    this._gender = value;
  }
  get civilStatus(): googleProtobuf000.StringValue | undefined {
    return this._civilStatus;
  }
  set civilStatus(value: googleProtobuf000.StringValue | undefined) {
    this._civilStatus = value;
  }
  get activity(): googleProtobuf000.StringValue | undefined {
    return this._activity;
  }
  set activity(value: googleProtobuf000.StringValue | undefined) {
    this._activity = value;
  }
  get address(): googleProtobuf000.StringValue | undefined {
    return this._address;
  }
  set address(value: googleProtobuf000.StringValue | undefined) {
    this._address = value;
  }
  get cityOfBirth(): googleProtobuf000.StringValue | undefined {
    return this._cityOfBirth;
  }
  set cityOfBirth(value: googleProtobuf000.StringValue | undefined) {
    this._cityOfBirth = value;
  }
  get countryOfBirth(): googleProtobuf000.StringValue | undefined {
    return this._countryOfBirth;
  }
  set countryOfBirth(value: googleProtobuf000.StringValue | undefined) {
    this._countryOfBirth = value;
  }
  get nationality(): googleProtobuf000.StringValue | undefined {
    return this._nationality;
  }
  set nationality(value: googleProtobuf000.StringValue | undefined) {
    this._nationality = value;
  }
  get usPerson(): googleProtobuf000.BoolValue | undefined {
    return this._usPerson;
  }
  set usPerson(value: googleProtobuf000.BoolValue | undefined) {
    this._usPerson = value;
  }
  get isPEP(): googleProtobuf000.BoolValue | undefined {
    return this._isPEP;
  }
  set isPEP(value: googleProtobuf000.BoolValue | undefined) {
    this._isPEP = value;
  }
  get whoIsPEP(): googleProtobuf000.StringValue | undefined {
    return this._whoIsPEP;
  }
  set whoIsPEP(value: googleProtobuf000.StringValue | undefined) {
    this._whoIsPEP = value;
  }
  get pepMePosition(): googleProtobuf000.StringValue | undefined {
    return this._pepMePosition;
  }
  set pepMePosition(value: googleProtobuf000.StringValue | undefined) {
    this._pepMePosition = value;
  }
  get pepMeInstitution(): googleProtobuf000.StringValue | undefined {
    return this._pepMeInstitution;
  }
  set pepMeInstitution(value: googleProtobuf000.StringValue | undefined) {
    this._pepMeInstitution = value;
  }
  get pepName(): googleProtobuf000.StringValue | undefined {
    return this._pepName;
  }
  set pepName(value: googleProtobuf000.StringValue | undefined) {
    this._pepName = value;
  }
  get pepPosition(): googleProtobuf000.StringValue | undefined {
    return this._pepPosition;
  }
  set pepPosition(value: googleProtobuf000.StringValue | undefined) {
    this._pepPosition = value;
  }
  get pepInstitution(): googleProtobuf000.StringValue | undefined {
    return this._pepInstitution;
  }
  set pepInstitution(value: googleProtobuf000.StringValue | undefined) {
    this._pepInstitution = value;
  }
  get pepRelationship(): googleProtobuf000.StringValue | undefined {
    return this._pepRelationship;
  }
  set pepRelationship(value: googleProtobuf000.StringValue | undefined) {
    this._pepRelationship = value;
  }
  get pepNameSecond(): googleProtobuf000.StringValue | undefined {
    return this._pepNameSecond;
  }
  set pepNameSecond(value: googleProtobuf000.StringValue | undefined) {
    this._pepNameSecond = value;
  }
  get pepPositionSecond(): googleProtobuf000.StringValue | undefined {
    return this._pepPositionSecond;
  }
  set pepPositionSecond(value: googleProtobuf000.StringValue | undefined) {
    this._pepPositionSecond = value;
  }
  get pepInstitutionSecond(): googleProtobuf000.StringValue | undefined {
    return this._pepInstitutionSecond;
  }
  set pepInstitutionSecond(value: googleProtobuf000.StringValue | undefined) {
    this._pepInstitutionSecond = value;
  }
  get pepRelationshipSecond(): googleProtobuf000.StringValue | undefined {
    return this._pepRelationshipSecond;
  }
  set pepRelationshipSecond(value: googleProtobuf000.StringValue | undefined) {
    this._pepRelationshipSecond = value;
  }
  get pepFromDate(): googleProtobuf001.Timestamp | undefined {
    return this._pepFromDate;
  }
  set pepFromDate(value: googleProtobuf001.Timestamp | undefined) {
    this._pepFromDate = value;
  }
  get pepToDate(): googleProtobuf001.Timestamp | undefined {
    return this._pepToDate;
  }
  set pepToDate(value: googleProtobuf001.Timestamp | undefined) {
    this._pepToDate = value;
  }
  get birthday(): googleProtobuf001.Timestamp | undefined {
    return this._birthday;
  }
  set birthday(value: googleProtobuf001.Timestamp | undefined) {
    this._birthday = value;
  }
  get hasOtherNationality(): googleProtobuf000.BoolValue | undefined {
    return this._hasOtherNationality;
  }
  set hasOtherNationality(value: googleProtobuf000.BoolValue | undefined) {
    this._hasOtherNationality = value;
  }
  get otherNationality(): googleProtobuf000.StringValue | undefined {
    return this._otherNationality;
  }
  set otherNationality(value: googleProtobuf000.StringValue | undefined) {
    this._otherNationality = value;
  }
  get passportNumber(): googleProtobuf000.StringValue | undefined {
    return this._passportNumber;
  }
  set passportNumber(value: googleProtobuf000.StringValue | undefined) {
    this._passportNumber = value;
  }
  get laborOld(): googleProtobuf000.StringValue | undefined {
    return this._laborOld;
  }
  set laborOld(value: googleProtobuf000.StringValue | undefined) {
    this._laborOld = value;
  }
  get stakeCompany(): googleProtobuf000.StringValue | undefined {
    return this._stakeCompany;
  }
  set stakeCompany(value: googleProtobuf000.StringValue | undefined) {
    this._stakeCompany = value;
  }
  get hasStakeCompany(): googleProtobuf000.BoolValue | undefined {
    return this._hasStakeCompany;
  }
  set hasStakeCompany(value: googleProtobuf000.BoolValue | undefined) {
    this._hasStakeCompany = value;
  }
  get hasAnotherTaxResidence(): googleProtobuf000.BoolValue | undefined {
    return this._hasAnotherTaxResidence;
  }
  set hasAnotherTaxResidence(value: googleProtobuf000.BoolValue | undefined) {
    this._hasAnotherTaxResidence = value;
  }
  get otherCountryTaxDomicile(): googleProtobuf000.StringValue | undefined {
    return this._otherCountryTaxDomicile;
  }
  set otherCountryTaxDomicile(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._otherCountryTaxDomicile = value;
  }
  get taxpayerNumber(): googleProtobuf000.StringValue | undefined {
    return this._taxpayerNumber;
  }
  set taxpayerNumber(value: googleProtobuf000.StringValue | undefined) {
    this._taxpayerNumber = value;
  }
  get averageIncome(): googleProtobuf000.StringValue | undefined {
    return this._averageIncome;
  }
  set averageIncome(value: googleProtobuf000.StringValue | undefined) {
    this._averageIncome = value;
  }
  get equityFinancialSystem(): googleProtobuf000.StringValue | undefined {
    return this._equityFinancialSystem;
  }
  set equityFinancialSystem(value: googleProtobuf000.StringValue | undefined) {
    this._equityFinancialSystem = value;
  }
  get nonfinancialAssets(): googleProtobuf000.StringValue | undefined {
    return this._nonfinancialAssets;
  }
  set nonfinancialAssets(value: googleProtobuf000.StringValue | undefined) {
    this._nonfinancialAssets = value;
  }
  get accountTypeTranfer(): googleProtobuf000.StringValue | undefined {
    return this._accountTypeTranfer;
  }
  set accountTypeTranfer(value: googleProtobuf000.StringValue | undefined) {
    this._accountTypeTranfer = value;
  }
  get bankTranfer(): googleProtobuf000.StringValue | undefined {
    return this._bankTranfer;
  }
  set bankTranfer(value: googleProtobuf000.StringValue | undefined) {
    this._bankTranfer = value;
  }
  get accountNumberTranfer(): googleProtobuf000.StringValue | undefined {
    return this._accountNumberTranfer;
  }
  set accountNumberTranfer(value: googleProtobuf000.StringValue | undefined) {
    this._accountNumberTranfer = value;
  }
  get sourceFunds(): googleProtobuf000.StringValue | undefined {
    return this._sourceFunds;
  }
  set sourceFunds(value: googleProtobuf000.StringValue | undefined) {
    this._sourceFunds = value;
  }
  get workCompanyName(): googleProtobuf000.StringValue | undefined {
    return this._workCompanyName;
  }
  set workCompanyName(value: googleProtobuf000.StringValue | undefined) {
    this._workCompanyName = value;
  }
  get workRutCompany(): googleProtobuf000.StringValue | undefined {
    return this._workRutCompany;
  }
  set workRutCompany(value: googleProtobuf000.StringValue | undefined) {
    this._workRutCompany = value;
  }
  get workJob(): googleProtobuf000.StringValue | undefined {
    return this._workJob;
  }
  set workJob(value: googleProtobuf000.StringValue | undefined) {
    this._workJob = value;
  }
  get workAddress(): googleProtobuf000.StringValue | undefined {
    return this._workAddress;
  }
  set workAddress(value: googleProtobuf000.StringValue | undefined) {
    this._workAddress = value;
  }
  get workProfession(): googleProtobuf000.StringValue | undefined {
    return this._workProfession;
  }
  set workProfession(value: googleProtobuf000.StringValue | undefined) {
    this._workProfession = value;
  }
  get spouseNames(): googleProtobuf000.StringValue | undefined {
    return this._spouseNames;
  }
  set spouseNames(value: googleProtobuf000.StringValue | undefined) {
    this._spouseNames = value;
  }
  get spouseRUT(): googleProtobuf000.StringValue | undefined {
    return this._spouseRUT;
  }
  set spouseRUT(value: googleProtobuf000.StringValue | undefined) {
    this._spouseRUT = value;
  }
  get spouseBirthDate(): googleProtobuf001.Timestamp | undefined {
    return this._spouseBirthDate;
  }
  set spouseBirthDate(value: googleProtobuf001.Timestamp | undefined) {
    this._spouseBirthDate = value;
  }
  get spouseActivity(): googleProtobuf000.StringValue | undefined {
    return this._spouseActivity;
  }
  set spouseActivity(value: googleProtobuf000.StringValue | undefined) {
    this._spouseActivity = value;
  }
  get spouseNationality(): googleProtobuf000.StringValue | undefined {
    return this._spouseNationality;
  }
  set spouseNationality(value: googleProtobuf000.StringValue | undefined) {
    this._spouseNationality = value;
  }
  get spouseAddress(): googleProtobuf000.StringValue | undefined {
    return this._spouseAddress;
  }
  set spouseAddress(value: googleProtobuf000.StringValue | undefined) {
    this._spouseAddress = value;
  }
  get spousePhone(): googleProtobuf000.StringValue | undefined {
    return this._spousePhone;
  }
  set spousePhone(value: googleProtobuf000.StringValue | undefined) {
    this._spousePhone = value;
  }
  get spousenEmail(): googleProtobuf000.StringValue | undefined {
    return this._spousenEmail;
  }
  set spousenEmail(value: googleProtobuf000.StringValue | undefined) {
    this._spousenEmail = value;
  }
  get spouceWorkCompanyName(): googleProtobuf000.StringValue | undefined {
    return this._spouceWorkCompanyName;
  }
  set spouceWorkCompanyName(value: googleProtobuf000.StringValue | undefined) {
    this._spouceWorkCompanyName = value;
  }
  get spouceWorkRutCompany(): googleProtobuf000.StringValue | undefined {
    return this._spouceWorkRutCompany;
  }
  set spouceWorkRutCompany(value: googleProtobuf000.StringValue | undefined) {
    this._spouceWorkRutCompany = value;
  }
  get spouceWorkJob(): googleProtobuf000.StringValue | undefined {
    return this._spouceWorkJob;
  }
  set spouceWorkJob(value: googleProtobuf000.StringValue | undefined) {
    this._spouceWorkJob = value;
  }
  get spouceWorkAddress(): googleProtobuf000.StringValue | undefined {
    return this._spouceWorkAddress;
  }
  set spouceWorkAddress(value: googleProtobuf000.StringValue | undefined) {
    this._spouceWorkAddress = value;
  }
  get spouceWorkProfession(): googleProtobuf000.StringValue | undefined {
    return this._spouceWorkProfession;
  }
  set spouceWorkProfession(value: googleProtobuf000.StringValue | undefined) {
    this._spouceWorkProfession = value;
  }
  get maritalRegime(): googleProtobuf000.StringValue | undefined {
    return this._maritalRegime;
  }
  set maritalRegime(value: googleProtobuf000.StringValue | undefined) {
    this._maritalRegime = value;
  }
  get hasRelationshipWithStockbroker():
    | googleProtobuf000.BoolValue
    | undefined {
    return this._hasRelationshipWithStockbroker;
  }
  set hasRelationshipWithStockbroker(
    value: googleProtobuf000.BoolValue | undefined
  ) {
    this._hasRelationshipWithStockbroker = value;
  }
  get entityBusiness(): googleProtobuf000.StringValue | undefined {
    return this._entityBusiness;
  }
  set entityBusiness(value: googleProtobuf000.StringValue | undefined) {
    this._entityBusiness = value;
  }
  get entityType(): googleProtobuf000.StringValue | undefined {
    return this._entityType;
  }
  set entityType(value: googleProtobuf000.StringValue | undefined) {
    this._entityType = value;
  }
  get usNumberOfPeopleInCharge(): googleProtobuf000.Int32Value | undefined {
    return this._usNumberOfPeopleInCharge;
  }
  set usNumberOfPeopleInCharge(
    value: googleProtobuf000.Int32Value | undefined
  ) {
    this._usNumberOfPeopleInCharge = value;
  }
  get usEmploymentSituation(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentSituation;
  }
  set usEmploymentSituation(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentSituation = value;
  }
  get usEmploymentSituationDetail(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentSituationDetail;
  }
  set usEmploymentSituationDetail(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usEmploymentSituationDetail = value;
  }
  get usEmployerName(): googleProtobuf000.StringValue | undefined {
    return this._usEmployerName;
  }
  set usEmployerName(value: googleProtobuf000.StringValue | undefined) {
    this._usEmployerName = value;
  }
  get usEmploymentAddress(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentAddress;
  }
  set usEmploymentAddress(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentAddress = value;
  }
  get usEmploymentCountry(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentCountry;
  }
  set usEmploymentCountry(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentCountry = value;
  }
  get usEmploymentStateProvince(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentStateProvince;
  }
  set usEmploymentStateProvince(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usEmploymentStateProvince = value;
  }
  get usEmploymentCommune(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentCommune;
  }
  set usEmploymentCommune(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentCommune = value;
  }
  get usEmploymentProfession(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentProfession;
  }
  set usEmploymentProfession(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentProfession = value;
  }
  get usEmploymentProfessionDetail():
    | googleProtobuf000.StringValue
    | undefined {
    return this._usEmploymentProfessionDetail;
  }
  set usEmploymentProfessionDetail(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usEmploymentProfessionDetail = value;
  }
  get usCompanyActivity(): googleProtobuf000.StringValue | undefined {
    return this._usCompanyActivity;
  }
  set usCompanyActivity(value: googleProtobuf000.StringValue | undefined) {
    this._usCompanyActivity = value;
  }
  get usCompanyActivityDetail(): googleProtobuf000.StringValue | undefined {
    return this._usCompanyActivityDetail;
  }
  set usCompanyActivityDetail(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usCompanyActivityDetail = value;
  }
  get usTotalAnnualIncome(): googleProtobuf000.StringValue | undefined {
    return this._usTotalAnnualIncome;
  }
  set usTotalAnnualIncome(value: googleProtobuf000.StringValue | undefined) {
    this._usTotalAnnualIncome = value;
  }
  get usNetPatrimony(): googleProtobuf000.StringValue | undefined {
    return this._usNetPatrimony;
  }
  set usNetPatrimony(value: googleProtobuf000.StringValue | undefined) {
    this._usNetPatrimony = value;
  }
  get usLiquidPatrimony(): googleProtobuf000.StringValue | undefined {
    return this._usLiquidPatrimony;
  }
  set usLiquidPatrimony(value: googleProtobuf000.StringValue | undefined) {
    this._usLiquidPatrimony = value;
  }
  get usComplianceEmail(): googleProtobuf000.StringValue | undefined {
    return this._usComplianceEmail;
  }
  set usComplianceEmail(value: googleProtobuf000.StringValue | undefined) {
    this._usComplianceEmail = value;
  }
  get usCompliancePhone(): googleProtobuf000.StringValue | undefined {
    return this._usCompliancePhone;
  }
  set usCompliancePhone(value: googleProtobuf000.StringValue | undefined) {
    this._usCompliancePhone = value;
  }
  get usSourceFunds(): googleProtobuf000.StringValue | undefined {
    return this._usSourceFunds;
  }
  set usSourceFunds(value: googleProtobuf000.StringValue | undefined) {
    this._usSourceFunds = value;
  }
  get usAddressZipCode(): googleProtobuf000.StringValue | undefined {
    return this._usAddressZipCode;
  }
  set usAddressZipCode(value: googleProtobuf000.StringValue | undefined) {
    this._usAddressZipCode = value;
  }
  get usWorkAddressZipCode(): googleProtobuf000.StringValue | undefined {
    return this._usWorkAddressZipCode;
  }
  set usWorkAddressZipCode(value: googleProtobuf000.StringValue | undefined) {
    this._usWorkAddressZipCode = value;
  }
  get iBKRId(): googleProtobuf000.StringValue | undefined {
    return this._iBKRId;
  }
  set iBKRId(value: googleProtobuf000.StringValue | undefined) {
    this._iBKRId = value;
  }
  get iBKRUser(): googleProtobuf000.StringValue | undefined {
    return this._iBKRUser;
  }
  set iBKRUser(value: googleProtobuf000.StringValue | undefined) {
    this._iBKRUser = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get ownerId(): googleProtobuf000.Int32Value | undefined {
    return this._ownerId;
  }
  set ownerId(value: googleProtobuf000.Int32Value | undefined) {
    this._ownerId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get regionId(): googleProtobuf000.Int32Value | undefined {
    return this._regionId;
  }
  set regionId(value: googleProtobuf000.Int32Value | undefined) {
    this._regionId = value;
  }
  get spousenRegionId(): googleProtobuf000.Int32Value | undefined {
    return this._spousenRegionId;
  }
  set spousenRegionId(value: googleProtobuf000.Int32Value | undefined) {
    this._spousenRegionId = value;
  }
  get workRegionId(): googleProtobuf000.Int32Value | undefined {
    return this._workRegionId;
  }
  set workRegionId(value: googleProtobuf000.Int32Value | undefined) {
    this._workRegionId = value;
  }
  get communeId(): googleProtobuf000.Int32Value | undefined {
    return this._communeId;
  }
  set communeId(value: googleProtobuf000.Int32Value | undefined) {
    this._communeId = value;
  }
  get spousenCommuneId(): googleProtobuf000.Int32Value | undefined {
    return this._spousenCommuneId;
  }
  set spousenCommuneId(value: googleProtobuf000.Int32Value | undefined) {
    this._spousenCommuneId = value;
  }
  get workCommuneId(): googleProtobuf000.Int32Value | undefined {
    return this._workCommuneId;
  }
  set workCommuneId(value: googleProtobuf000.Int32Value | undefined) {
    this._workCommuneId = value;
  }
  get region(): RegionModel | undefined {
    return this._region;
  }
  set region(value: RegionModel | undefined) {
    this._region = value;
  }
  get commune(): CommuneModel | undefined {
    return this._commune;
  }
  set commune(value: CommuneModel | undefined) {
    this._commune = value;
  }
  get fatcaUsTaxpayerIdentificationNumber():
    | googleProtobuf000.StringValue
    | undefined {
    return this._fatcaUsTaxpayerIdentificationNumber;
  }
  set fatcaUsTaxpayerIdentificationNumber(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._fatcaUsTaxpayerIdentificationNumber = value;
  }
  get fatcaReportAuthorization(): googleProtobuf000.BoolValue | undefined {
    return this._fatcaReportAuthorization;
  }
  set fatcaReportAuthorization(value: googleProtobuf000.BoolValue | undefined) {
    this._fatcaReportAuthorization = value;
  }
  get otherCountryTaxDate(): googleProtobuf001.Timestamp | undefined {
    return this._otherCountryTaxDate;
  }
  set otherCountryTaxDate(value: googleProtobuf001.Timestamp | undefined) {
    this._otherCountryTaxDate = value;
  }
  get potencialEquity(): googleProtobuf000.StringValue | undefined {
    return this._potencialEquity;
  }
  set potencialEquity(value: googleProtobuf000.StringValue | undefined) {
    this._potencialEquity = value;
  }
  get incomeCode(): googleProtobuf000.StringValue | undefined {
    return this._incomeCode;
  }
  set incomeCode(value: googleProtobuf000.StringValue | undefined) {
    this._incomeCode = value;
  }
  get patrimonyCode(): googleProtobuf000.StringValue | undefined {
    return this._patrimonyCode;
  }
  set patrimonyCode(value: googleProtobuf000.StringValue | undefined) {
    this._patrimonyCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserInformationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserInformationModel.AsObject {
    return {
      profession: this.profession ? this.profession.toObject() : undefined,
      whatProfession: this.whatProfession
        ? this.whatProfession.toObject()
        : undefined,
      gender: this.gender ? this.gender.toObject() : undefined,
      civilStatus: this.civilStatus ? this.civilStatus.toObject() : undefined,
      activity: this.activity ? this.activity.toObject() : undefined,
      address: this.address ? this.address.toObject() : undefined,
      cityOfBirth: this.cityOfBirth ? this.cityOfBirth.toObject() : undefined,
      countryOfBirth: this.countryOfBirth
        ? this.countryOfBirth.toObject()
        : undefined,
      nationality: this.nationality ? this.nationality.toObject() : undefined,
      usPerson: this.usPerson ? this.usPerson.toObject() : undefined,
      isPEP: this.isPEP ? this.isPEP.toObject() : undefined,
      whoIsPEP: this.whoIsPEP ? this.whoIsPEP.toObject() : undefined,
      pepMePosition: this.pepMePosition
        ? this.pepMePosition.toObject()
        : undefined,
      pepMeInstitution: this.pepMeInstitution
        ? this.pepMeInstitution.toObject()
        : undefined,
      pepName: this.pepName ? this.pepName.toObject() : undefined,
      pepPosition: this.pepPosition ? this.pepPosition.toObject() : undefined,
      pepInstitution: this.pepInstitution
        ? this.pepInstitution.toObject()
        : undefined,
      pepRelationship: this.pepRelationship
        ? this.pepRelationship.toObject()
        : undefined,
      pepNameSecond: this.pepNameSecond
        ? this.pepNameSecond.toObject()
        : undefined,
      pepPositionSecond: this.pepPositionSecond
        ? this.pepPositionSecond.toObject()
        : undefined,
      pepInstitutionSecond: this.pepInstitutionSecond
        ? this.pepInstitutionSecond.toObject()
        : undefined,
      pepRelationshipSecond: this.pepRelationshipSecond
        ? this.pepRelationshipSecond.toObject()
        : undefined,
      pepFromDate: this.pepFromDate ? this.pepFromDate.toObject() : undefined,
      pepToDate: this.pepToDate ? this.pepToDate.toObject() : undefined,
      birthday: this.birthday ? this.birthday.toObject() : undefined,
      hasOtherNationality: this.hasOtherNationality
        ? this.hasOtherNationality.toObject()
        : undefined,
      otherNationality: this.otherNationality
        ? this.otherNationality.toObject()
        : undefined,
      passportNumber: this.passportNumber
        ? this.passportNumber.toObject()
        : undefined,
      laborOld: this.laborOld ? this.laborOld.toObject() : undefined,
      stakeCompany: this.stakeCompany
        ? this.stakeCompany.toObject()
        : undefined,
      hasStakeCompany: this.hasStakeCompany
        ? this.hasStakeCompany.toObject()
        : undefined,
      hasAnotherTaxResidence: this.hasAnotherTaxResidence
        ? this.hasAnotherTaxResidence.toObject()
        : undefined,
      otherCountryTaxDomicile: this.otherCountryTaxDomicile
        ? this.otherCountryTaxDomicile.toObject()
        : undefined,
      taxpayerNumber: this.taxpayerNumber
        ? this.taxpayerNumber.toObject()
        : undefined,
      averageIncome: this.averageIncome
        ? this.averageIncome.toObject()
        : undefined,
      equityFinancialSystem: this.equityFinancialSystem
        ? this.equityFinancialSystem.toObject()
        : undefined,
      nonfinancialAssets: this.nonfinancialAssets
        ? this.nonfinancialAssets.toObject()
        : undefined,
      accountTypeTranfer: this.accountTypeTranfer
        ? this.accountTypeTranfer.toObject()
        : undefined,
      bankTranfer: this.bankTranfer ? this.bankTranfer.toObject() : undefined,
      accountNumberTranfer: this.accountNumberTranfer
        ? this.accountNumberTranfer.toObject()
        : undefined,
      sourceFunds: this.sourceFunds ? this.sourceFunds.toObject() : undefined,
      workCompanyName: this.workCompanyName
        ? this.workCompanyName.toObject()
        : undefined,
      workRutCompany: this.workRutCompany
        ? this.workRutCompany.toObject()
        : undefined,
      workJob: this.workJob ? this.workJob.toObject() : undefined,
      workAddress: this.workAddress ? this.workAddress.toObject() : undefined,
      workProfession: this.workProfession
        ? this.workProfession.toObject()
        : undefined,
      spouseNames: this.spouseNames ? this.spouseNames.toObject() : undefined,
      spouseRUT: this.spouseRUT ? this.spouseRUT.toObject() : undefined,
      spouseBirthDate: this.spouseBirthDate
        ? this.spouseBirthDate.toObject()
        : undefined,
      spouseActivity: this.spouseActivity
        ? this.spouseActivity.toObject()
        : undefined,
      spouseNationality: this.spouseNationality
        ? this.spouseNationality.toObject()
        : undefined,
      spouseAddress: this.spouseAddress
        ? this.spouseAddress.toObject()
        : undefined,
      spousePhone: this.spousePhone ? this.spousePhone.toObject() : undefined,
      spousenEmail: this.spousenEmail
        ? this.spousenEmail.toObject()
        : undefined,
      spouceWorkCompanyName: this.spouceWorkCompanyName
        ? this.spouceWorkCompanyName.toObject()
        : undefined,
      spouceWorkRutCompany: this.spouceWorkRutCompany
        ? this.spouceWorkRutCompany.toObject()
        : undefined,
      spouceWorkJob: this.spouceWorkJob
        ? this.spouceWorkJob.toObject()
        : undefined,
      spouceWorkAddress: this.spouceWorkAddress
        ? this.spouceWorkAddress.toObject()
        : undefined,
      spouceWorkProfession: this.spouceWorkProfession
        ? this.spouceWorkProfession.toObject()
        : undefined,
      maritalRegime: this.maritalRegime
        ? this.maritalRegime.toObject()
        : undefined,
      hasRelationshipWithStockbroker: this.hasRelationshipWithStockbroker
        ? this.hasRelationshipWithStockbroker.toObject()
        : undefined,
      entityBusiness: this.entityBusiness
        ? this.entityBusiness.toObject()
        : undefined,
      entityType: this.entityType ? this.entityType.toObject() : undefined,
      usNumberOfPeopleInCharge: this.usNumberOfPeopleInCharge
        ? this.usNumberOfPeopleInCharge.toObject()
        : undefined,
      usEmploymentSituation: this.usEmploymentSituation
        ? this.usEmploymentSituation.toObject()
        : undefined,
      usEmploymentSituationDetail: this.usEmploymentSituationDetail
        ? this.usEmploymentSituationDetail.toObject()
        : undefined,
      usEmployerName: this.usEmployerName
        ? this.usEmployerName.toObject()
        : undefined,
      usEmploymentAddress: this.usEmploymentAddress
        ? this.usEmploymentAddress.toObject()
        : undefined,
      usEmploymentCountry: this.usEmploymentCountry
        ? this.usEmploymentCountry.toObject()
        : undefined,
      usEmploymentStateProvince: this.usEmploymentStateProvince
        ? this.usEmploymentStateProvince.toObject()
        : undefined,
      usEmploymentCommune: this.usEmploymentCommune
        ? this.usEmploymentCommune.toObject()
        : undefined,
      usEmploymentProfession: this.usEmploymentProfession
        ? this.usEmploymentProfession.toObject()
        : undefined,
      usEmploymentProfessionDetail: this.usEmploymentProfessionDetail
        ? this.usEmploymentProfessionDetail.toObject()
        : undefined,
      usCompanyActivity: this.usCompanyActivity
        ? this.usCompanyActivity.toObject()
        : undefined,
      usCompanyActivityDetail: this.usCompanyActivityDetail
        ? this.usCompanyActivityDetail.toObject()
        : undefined,
      usTotalAnnualIncome: this.usTotalAnnualIncome
        ? this.usTotalAnnualIncome.toObject()
        : undefined,
      usNetPatrimony: this.usNetPatrimony
        ? this.usNetPatrimony.toObject()
        : undefined,
      usLiquidPatrimony: this.usLiquidPatrimony
        ? this.usLiquidPatrimony.toObject()
        : undefined,
      usComplianceEmail: this.usComplianceEmail
        ? this.usComplianceEmail.toObject()
        : undefined,
      usCompliancePhone: this.usCompliancePhone
        ? this.usCompliancePhone.toObject()
        : undefined,
      usSourceFunds: this.usSourceFunds
        ? this.usSourceFunds.toObject()
        : undefined,
      usAddressZipCode: this.usAddressZipCode
        ? this.usAddressZipCode.toObject()
        : undefined,
      usWorkAddressZipCode: this.usWorkAddressZipCode
        ? this.usWorkAddressZipCode.toObject()
        : undefined,
      iBKRId: this.iBKRId ? this.iBKRId.toObject() : undefined,
      iBKRUser: this.iBKRUser ? this.iBKRUser.toObject() : undefined,
      id: this.id,
      ownerId: this.ownerId ? this.ownerId.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      regionId: this.regionId ? this.regionId.toObject() : undefined,
      spousenRegionId: this.spousenRegionId
        ? this.spousenRegionId.toObject()
        : undefined,
      workRegionId: this.workRegionId
        ? this.workRegionId.toObject()
        : undefined,
      communeId: this.communeId ? this.communeId.toObject() : undefined,
      spousenCommuneId: this.spousenCommuneId
        ? this.spousenCommuneId.toObject()
        : undefined,
      workCommuneId: this.workCommuneId
        ? this.workCommuneId.toObject()
        : undefined,
      region: this.region ? this.region.toObject() : undefined,
      commune: this.commune ? this.commune.toObject() : undefined,
      fatcaUsTaxpayerIdentificationNumber: this
        .fatcaUsTaxpayerIdentificationNumber
        ? this.fatcaUsTaxpayerIdentificationNumber.toObject()
        : undefined,
      fatcaReportAuthorization: this.fatcaReportAuthorization
        ? this.fatcaReportAuthorization.toObject()
        : undefined,
      otherCountryTaxDate: this.otherCountryTaxDate
        ? this.otherCountryTaxDate.toObject()
        : undefined,
      potencialEquity: this.potencialEquity
        ? this.potencialEquity.toObject()
        : undefined,
      incomeCode: this.incomeCode ? this.incomeCode.toObject() : undefined,
      patrimonyCode: this.patrimonyCode
        ? this.patrimonyCode.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserInformationModel.AsProtobufJSON {
    return {
      profession: this.profession
        ? this.profession.toProtobufJSON(options)
        : null,
      whatProfession: this.whatProfession
        ? this.whatProfession.toProtobufJSON(options)
        : null,
      gender: this.gender ? this.gender.toProtobufJSON(options) : null,
      civilStatus: this.civilStatus
        ? this.civilStatus.toProtobufJSON(options)
        : null,
      activity: this.activity ? this.activity.toProtobufJSON(options) : null,
      address: this.address ? this.address.toProtobufJSON(options) : null,
      cityOfBirth: this.cityOfBirth
        ? this.cityOfBirth.toProtobufJSON(options)
        : null,
      countryOfBirth: this.countryOfBirth
        ? this.countryOfBirth.toProtobufJSON(options)
        : null,
      nationality: this.nationality
        ? this.nationality.toProtobufJSON(options)
        : null,
      usPerson: this.usPerson ? this.usPerson.toProtobufJSON(options) : null,
      isPEP: this.isPEP ? this.isPEP.toProtobufJSON(options) : null,
      whoIsPEP: this.whoIsPEP ? this.whoIsPEP.toProtobufJSON(options) : null,
      pepMePosition: this.pepMePosition
        ? this.pepMePosition.toProtobufJSON(options)
        : null,
      pepMeInstitution: this.pepMeInstitution
        ? this.pepMeInstitution.toProtobufJSON(options)
        : null,
      pepName: this.pepName ? this.pepName.toProtobufJSON(options) : null,
      pepPosition: this.pepPosition
        ? this.pepPosition.toProtobufJSON(options)
        : null,
      pepInstitution: this.pepInstitution
        ? this.pepInstitution.toProtobufJSON(options)
        : null,
      pepRelationship: this.pepRelationship
        ? this.pepRelationship.toProtobufJSON(options)
        : null,
      pepNameSecond: this.pepNameSecond
        ? this.pepNameSecond.toProtobufJSON(options)
        : null,
      pepPositionSecond: this.pepPositionSecond
        ? this.pepPositionSecond.toProtobufJSON(options)
        : null,
      pepInstitutionSecond: this.pepInstitutionSecond
        ? this.pepInstitutionSecond.toProtobufJSON(options)
        : null,
      pepRelationshipSecond: this.pepRelationshipSecond
        ? this.pepRelationshipSecond.toProtobufJSON(options)
        : null,
      pepFromDate: this.pepFromDate
        ? this.pepFromDate.toProtobufJSON(options)
        : null,
      pepToDate: this.pepToDate ? this.pepToDate.toProtobufJSON(options) : null,
      birthday: this.birthday ? this.birthday.toProtobufJSON(options) : null,
      hasOtherNationality: this.hasOtherNationality
        ? this.hasOtherNationality.toProtobufJSON(options)
        : null,
      otherNationality: this.otherNationality
        ? this.otherNationality.toProtobufJSON(options)
        : null,
      passportNumber: this.passportNumber
        ? this.passportNumber.toProtobufJSON(options)
        : null,
      laborOld: this.laborOld ? this.laborOld.toProtobufJSON(options) : null,
      stakeCompany: this.stakeCompany
        ? this.stakeCompany.toProtobufJSON(options)
        : null,
      hasStakeCompany: this.hasStakeCompany
        ? this.hasStakeCompany.toProtobufJSON(options)
        : null,
      hasAnotherTaxResidence: this.hasAnotherTaxResidence
        ? this.hasAnotherTaxResidence.toProtobufJSON(options)
        : null,
      otherCountryTaxDomicile: this.otherCountryTaxDomicile
        ? this.otherCountryTaxDomicile.toProtobufJSON(options)
        : null,
      taxpayerNumber: this.taxpayerNumber
        ? this.taxpayerNumber.toProtobufJSON(options)
        : null,
      averageIncome: this.averageIncome
        ? this.averageIncome.toProtobufJSON(options)
        : null,
      equityFinancialSystem: this.equityFinancialSystem
        ? this.equityFinancialSystem.toProtobufJSON(options)
        : null,
      nonfinancialAssets: this.nonfinancialAssets
        ? this.nonfinancialAssets.toProtobufJSON(options)
        : null,
      accountTypeTranfer: this.accountTypeTranfer
        ? this.accountTypeTranfer.toProtobufJSON(options)
        : null,
      bankTranfer: this.bankTranfer
        ? this.bankTranfer.toProtobufJSON(options)
        : null,
      accountNumberTranfer: this.accountNumberTranfer
        ? this.accountNumberTranfer.toProtobufJSON(options)
        : null,
      sourceFunds: this.sourceFunds
        ? this.sourceFunds.toProtobufJSON(options)
        : null,
      workCompanyName: this.workCompanyName
        ? this.workCompanyName.toProtobufJSON(options)
        : null,
      workRutCompany: this.workRutCompany
        ? this.workRutCompany.toProtobufJSON(options)
        : null,
      workJob: this.workJob ? this.workJob.toProtobufJSON(options) : null,
      workAddress: this.workAddress
        ? this.workAddress.toProtobufJSON(options)
        : null,
      workProfession: this.workProfession
        ? this.workProfession.toProtobufJSON(options)
        : null,
      spouseNames: this.spouseNames
        ? this.spouseNames.toProtobufJSON(options)
        : null,
      spouseRUT: this.spouseRUT ? this.spouseRUT.toProtobufJSON(options) : null,
      spouseBirthDate: this.spouseBirthDate
        ? this.spouseBirthDate.toProtobufJSON(options)
        : null,
      spouseActivity: this.spouseActivity
        ? this.spouseActivity.toProtobufJSON(options)
        : null,
      spouseNationality: this.spouseNationality
        ? this.spouseNationality.toProtobufJSON(options)
        : null,
      spouseAddress: this.spouseAddress
        ? this.spouseAddress.toProtobufJSON(options)
        : null,
      spousePhone: this.spousePhone
        ? this.spousePhone.toProtobufJSON(options)
        : null,
      spousenEmail: this.spousenEmail
        ? this.spousenEmail.toProtobufJSON(options)
        : null,
      spouceWorkCompanyName: this.spouceWorkCompanyName
        ? this.spouceWorkCompanyName.toProtobufJSON(options)
        : null,
      spouceWorkRutCompany: this.spouceWorkRutCompany
        ? this.spouceWorkRutCompany.toProtobufJSON(options)
        : null,
      spouceWorkJob: this.spouceWorkJob
        ? this.spouceWorkJob.toProtobufJSON(options)
        : null,
      spouceWorkAddress: this.spouceWorkAddress
        ? this.spouceWorkAddress.toProtobufJSON(options)
        : null,
      spouceWorkProfession: this.spouceWorkProfession
        ? this.spouceWorkProfession.toProtobufJSON(options)
        : null,
      maritalRegime: this.maritalRegime
        ? this.maritalRegime.toProtobufJSON(options)
        : null,
      hasRelationshipWithStockbroker: this.hasRelationshipWithStockbroker
        ? this.hasRelationshipWithStockbroker.toProtobufJSON(options)
        : null,
      entityBusiness: this.entityBusiness
        ? this.entityBusiness.toProtobufJSON(options)
        : null,
      entityType: this.entityType
        ? this.entityType.toProtobufJSON(options)
        : null,
      usNumberOfPeopleInCharge: this.usNumberOfPeopleInCharge
        ? this.usNumberOfPeopleInCharge.toProtobufJSON(options)
        : null,
      usEmploymentSituation: this.usEmploymentSituation
        ? this.usEmploymentSituation.toProtobufJSON(options)
        : null,
      usEmploymentSituationDetail: this.usEmploymentSituationDetail
        ? this.usEmploymentSituationDetail.toProtobufJSON(options)
        : null,
      usEmployerName: this.usEmployerName
        ? this.usEmployerName.toProtobufJSON(options)
        : null,
      usEmploymentAddress: this.usEmploymentAddress
        ? this.usEmploymentAddress.toProtobufJSON(options)
        : null,
      usEmploymentCountry: this.usEmploymentCountry
        ? this.usEmploymentCountry.toProtobufJSON(options)
        : null,
      usEmploymentStateProvince: this.usEmploymentStateProvince
        ? this.usEmploymentStateProvince.toProtobufJSON(options)
        : null,
      usEmploymentCommune: this.usEmploymentCommune
        ? this.usEmploymentCommune.toProtobufJSON(options)
        : null,
      usEmploymentProfession: this.usEmploymentProfession
        ? this.usEmploymentProfession.toProtobufJSON(options)
        : null,
      usEmploymentProfessionDetail: this.usEmploymentProfessionDetail
        ? this.usEmploymentProfessionDetail.toProtobufJSON(options)
        : null,
      usCompanyActivity: this.usCompanyActivity
        ? this.usCompanyActivity.toProtobufJSON(options)
        : null,
      usCompanyActivityDetail: this.usCompanyActivityDetail
        ? this.usCompanyActivityDetail.toProtobufJSON(options)
        : null,
      usTotalAnnualIncome: this.usTotalAnnualIncome
        ? this.usTotalAnnualIncome.toProtobufJSON(options)
        : null,
      usNetPatrimony: this.usNetPatrimony
        ? this.usNetPatrimony.toProtobufJSON(options)
        : null,
      usLiquidPatrimony: this.usLiquidPatrimony
        ? this.usLiquidPatrimony.toProtobufJSON(options)
        : null,
      usComplianceEmail: this.usComplianceEmail
        ? this.usComplianceEmail.toProtobufJSON(options)
        : null,
      usCompliancePhone: this.usCompliancePhone
        ? this.usCompliancePhone.toProtobufJSON(options)
        : null,
      usSourceFunds: this.usSourceFunds
        ? this.usSourceFunds.toProtobufJSON(options)
        : null,
      usAddressZipCode: this.usAddressZipCode
        ? this.usAddressZipCode.toProtobufJSON(options)
        : null,
      usWorkAddressZipCode: this.usWorkAddressZipCode
        ? this.usWorkAddressZipCode.toProtobufJSON(options)
        : null,
      iBKRId: this.iBKRId ? this.iBKRId.toProtobufJSON(options) : null,
      iBKRUser: this.iBKRUser ? this.iBKRUser.toProtobufJSON(options) : null,
      id: this.id,
      ownerId: this.ownerId ? this.ownerId.toProtobufJSON(options) : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      regionId: this.regionId ? this.regionId.toProtobufJSON(options) : null,
      spousenRegionId: this.spousenRegionId
        ? this.spousenRegionId.toProtobufJSON(options)
        : null,
      workRegionId: this.workRegionId
        ? this.workRegionId.toProtobufJSON(options)
        : null,
      communeId: this.communeId ? this.communeId.toProtobufJSON(options) : null,
      spousenCommuneId: this.spousenCommuneId
        ? this.spousenCommuneId.toProtobufJSON(options)
        : null,
      workCommuneId: this.workCommuneId
        ? this.workCommuneId.toProtobufJSON(options)
        : null,
      region: this.region ? this.region.toProtobufJSON(options) : null,
      commune: this.commune ? this.commune.toProtobufJSON(options) : null,
      fatcaUsTaxpayerIdentificationNumber: this
        .fatcaUsTaxpayerIdentificationNumber
        ? this.fatcaUsTaxpayerIdentificationNumber.toProtobufJSON(options)
        : null,
      fatcaReportAuthorization: this.fatcaReportAuthorization
        ? this.fatcaReportAuthorization.toProtobufJSON(options)
        : null,
      otherCountryTaxDate: this.otherCountryTaxDate
        ? this.otherCountryTaxDate.toProtobufJSON(options)
        : null,
      potencialEquity: this.potencialEquity
        ? this.potencialEquity.toProtobufJSON(options)
        : null,
      incomeCode: this.incomeCode
        ? this.incomeCode.toProtobufJSON(options)
        : null,
      patrimonyCode: this.patrimonyCode
        ? this.patrimonyCode.toProtobufJSON(options)
        : null
    };
  }
}
export module UserInformationModel {
  /**
   * Standard JavaScript object representation for UserInformationModel
   */
  export interface AsObject {
    profession?: googleProtobuf000.BoolValue.AsObject;
    whatProfession?: googleProtobuf000.StringValue.AsObject;
    gender?: googleProtobuf000.StringValue.AsObject;
    civilStatus?: googleProtobuf000.StringValue.AsObject;
    activity?: googleProtobuf000.StringValue.AsObject;
    address?: googleProtobuf000.StringValue.AsObject;
    cityOfBirth?: googleProtobuf000.StringValue.AsObject;
    countryOfBirth?: googleProtobuf000.StringValue.AsObject;
    nationality?: googleProtobuf000.StringValue.AsObject;
    usPerson?: googleProtobuf000.BoolValue.AsObject;
    isPEP?: googleProtobuf000.BoolValue.AsObject;
    whoIsPEP?: googleProtobuf000.StringValue.AsObject;
    pepMePosition?: googleProtobuf000.StringValue.AsObject;
    pepMeInstitution?: googleProtobuf000.StringValue.AsObject;
    pepName?: googleProtobuf000.StringValue.AsObject;
    pepPosition?: googleProtobuf000.StringValue.AsObject;
    pepInstitution?: googleProtobuf000.StringValue.AsObject;
    pepRelationship?: googleProtobuf000.StringValue.AsObject;
    pepNameSecond?: googleProtobuf000.StringValue.AsObject;
    pepPositionSecond?: googleProtobuf000.StringValue.AsObject;
    pepInstitutionSecond?: googleProtobuf000.StringValue.AsObject;
    pepRelationshipSecond?: googleProtobuf000.StringValue.AsObject;
    pepFromDate?: googleProtobuf001.Timestamp.AsObject;
    pepToDate?: googleProtobuf001.Timestamp.AsObject;
    birthday?: googleProtobuf001.Timestamp.AsObject;
    hasOtherNationality?: googleProtobuf000.BoolValue.AsObject;
    otherNationality?: googleProtobuf000.StringValue.AsObject;
    passportNumber?: googleProtobuf000.StringValue.AsObject;
    laborOld?: googleProtobuf000.StringValue.AsObject;
    stakeCompany?: googleProtobuf000.StringValue.AsObject;
    hasStakeCompany?: googleProtobuf000.BoolValue.AsObject;
    hasAnotherTaxResidence?: googleProtobuf000.BoolValue.AsObject;
    otherCountryTaxDomicile?: googleProtobuf000.StringValue.AsObject;
    taxpayerNumber?: googleProtobuf000.StringValue.AsObject;
    averageIncome?: googleProtobuf000.StringValue.AsObject;
    equityFinancialSystem?: googleProtobuf000.StringValue.AsObject;
    nonfinancialAssets?: googleProtobuf000.StringValue.AsObject;
    accountTypeTranfer?: googleProtobuf000.StringValue.AsObject;
    bankTranfer?: googleProtobuf000.StringValue.AsObject;
    accountNumberTranfer?: googleProtobuf000.StringValue.AsObject;
    sourceFunds?: googleProtobuf000.StringValue.AsObject;
    workCompanyName?: googleProtobuf000.StringValue.AsObject;
    workRutCompany?: googleProtobuf000.StringValue.AsObject;
    workJob?: googleProtobuf000.StringValue.AsObject;
    workAddress?: googleProtobuf000.StringValue.AsObject;
    workProfession?: googleProtobuf000.StringValue.AsObject;
    spouseNames?: googleProtobuf000.StringValue.AsObject;
    spouseRUT?: googleProtobuf000.StringValue.AsObject;
    spouseBirthDate?: googleProtobuf001.Timestamp.AsObject;
    spouseActivity?: googleProtobuf000.StringValue.AsObject;
    spouseNationality?: googleProtobuf000.StringValue.AsObject;
    spouseAddress?: googleProtobuf000.StringValue.AsObject;
    spousePhone?: googleProtobuf000.StringValue.AsObject;
    spousenEmail?: googleProtobuf000.StringValue.AsObject;
    spouceWorkCompanyName?: googleProtobuf000.StringValue.AsObject;
    spouceWorkRutCompany?: googleProtobuf000.StringValue.AsObject;
    spouceWorkJob?: googleProtobuf000.StringValue.AsObject;
    spouceWorkAddress?: googleProtobuf000.StringValue.AsObject;
    spouceWorkProfession?: googleProtobuf000.StringValue.AsObject;
    maritalRegime?: googleProtobuf000.StringValue.AsObject;
    hasRelationshipWithStockbroker?: googleProtobuf000.BoolValue.AsObject;
    entityBusiness?: googleProtobuf000.StringValue.AsObject;
    entityType?: googleProtobuf000.StringValue.AsObject;
    usNumberOfPeopleInCharge?: googleProtobuf000.Int32Value.AsObject;
    usEmploymentSituation?: googleProtobuf000.StringValue.AsObject;
    usEmploymentSituationDetail?: googleProtobuf000.StringValue.AsObject;
    usEmployerName?: googleProtobuf000.StringValue.AsObject;
    usEmploymentAddress?: googleProtobuf000.StringValue.AsObject;
    usEmploymentCountry?: googleProtobuf000.StringValue.AsObject;
    usEmploymentStateProvince?: googleProtobuf000.StringValue.AsObject;
    usEmploymentCommune?: googleProtobuf000.StringValue.AsObject;
    usEmploymentProfession?: googleProtobuf000.StringValue.AsObject;
    usEmploymentProfessionDetail?: googleProtobuf000.StringValue.AsObject;
    usCompanyActivity?: googleProtobuf000.StringValue.AsObject;
    usCompanyActivityDetail?: googleProtobuf000.StringValue.AsObject;
    usTotalAnnualIncome?: googleProtobuf000.StringValue.AsObject;
    usNetPatrimony?: googleProtobuf000.StringValue.AsObject;
    usLiquidPatrimony?: googleProtobuf000.StringValue.AsObject;
    usComplianceEmail?: googleProtobuf000.StringValue.AsObject;
    usCompliancePhone?: googleProtobuf000.StringValue.AsObject;
    usSourceFunds?: googleProtobuf000.StringValue.AsObject;
    usAddressZipCode?: googleProtobuf000.StringValue.AsObject;
    usWorkAddressZipCode?: googleProtobuf000.StringValue.AsObject;
    iBKRId?: googleProtobuf000.StringValue.AsObject;
    iBKRUser?: googleProtobuf000.StringValue.AsObject;
    id: number;
    ownerId?: googleProtobuf000.Int32Value.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    regionId?: googleProtobuf000.Int32Value.AsObject;
    spousenRegionId?: googleProtobuf000.Int32Value.AsObject;
    workRegionId?: googleProtobuf000.Int32Value.AsObject;
    communeId?: googleProtobuf000.Int32Value.AsObject;
    spousenCommuneId?: googleProtobuf000.Int32Value.AsObject;
    workCommuneId?: googleProtobuf000.Int32Value.AsObject;
    region?: RegionModel.AsObject;
    commune?: CommuneModel.AsObject;
    fatcaUsTaxpayerIdentificationNumber?: googleProtobuf000.StringValue.AsObject;
    fatcaReportAuthorization?: googleProtobuf000.BoolValue.AsObject;
    otherCountryTaxDate?: googleProtobuf001.Timestamp.AsObject;
    potencialEquity?: googleProtobuf000.StringValue.AsObject;
    incomeCode?: googleProtobuf000.StringValue.AsObject;
    patrimonyCode?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for UserInformationModel
   */
  export interface AsProtobufJSON {
    profession: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    whatProfession: googleProtobuf000.StringValue.AsProtobufJSON | null;
    gender: googleProtobuf000.StringValue.AsProtobufJSON | null;
    civilStatus: googleProtobuf000.StringValue.AsProtobufJSON | null;
    activity: googleProtobuf000.StringValue.AsProtobufJSON | null;
    address: googleProtobuf000.StringValue.AsProtobufJSON | null;
    cityOfBirth: googleProtobuf000.StringValue.AsProtobufJSON | null;
    countryOfBirth: googleProtobuf000.StringValue.AsProtobufJSON | null;
    nationality: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usPerson: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    isPEP: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    whoIsPEP: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepMePosition: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepMeInstitution: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepPosition: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepInstitution: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepRelationship: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepNameSecond: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepPositionSecond: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepInstitutionSecond: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepRelationshipSecond: googleProtobuf000.StringValue.AsProtobufJSON | null;
    pepFromDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    pepToDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    birthday: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    hasOtherNationality: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    otherNationality: googleProtobuf000.StringValue.AsProtobufJSON | null;
    passportNumber: googleProtobuf000.StringValue.AsProtobufJSON | null;
    laborOld: googleProtobuf000.StringValue.AsProtobufJSON | null;
    stakeCompany: googleProtobuf000.StringValue.AsProtobufJSON | null;
    hasStakeCompany: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    hasAnotherTaxResidence: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    otherCountryTaxDomicile: googleProtobuf000.StringValue.AsProtobufJSON | null;
    taxpayerNumber: googleProtobuf000.StringValue.AsProtobufJSON | null;
    averageIncome: googleProtobuf000.StringValue.AsProtobufJSON | null;
    equityFinancialSystem: googleProtobuf000.StringValue.AsProtobufJSON | null;
    nonfinancialAssets: googleProtobuf000.StringValue.AsProtobufJSON | null;
    accountTypeTranfer: googleProtobuf000.StringValue.AsProtobufJSON | null;
    bankTranfer: googleProtobuf000.StringValue.AsProtobufJSON | null;
    accountNumberTranfer: googleProtobuf000.StringValue.AsProtobufJSON | null;
    sourceFunds: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workCompanyName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workRutCompany: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workJob: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workAddress: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workProfession: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouseNames: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouseRUT: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouseBirthDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    spouseActivity: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouseNationality: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouseAddress: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spousePhone: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spousenEmail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouceWorkCompanyName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouceWorkRutCompany: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouceWorkJob: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouceWorkAddress: googleProtobuf000.StringValue.AsProtobufJSON | null;
    spouceWorkProfession: googleProtobuf000.StringValue.AsProtobufJSON | null;
    maritalRegime: googleProtobuf000.StringValue.AsProtobufJSON | null;
    hasRelationshipWithStockbroker: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    entityBusiness: googleProtobuf000.StringValue.AsProtobufJSON | null;
    entityType: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usNumberOfPeopleInCharge: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    usEmploymentSituation: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentSituationDetail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmployerName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentAddress: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentCountry: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentStateProvince: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentCommune: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentProfession: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentProfessionDetail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usCompanyActivity: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usCompanyActivityDetail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usTotalAnnualIncome: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usNetPatrimony: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usLiquidPatrimony: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usComplianceEmail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usCompliancePhone: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usSourceFunds: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usAddressZipCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usWorkAddressZipCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    iBKRId: googleProtobuf000.StringValue.AsProtobufJSON | null;
    iBKRUser: googleProtobuf000.StringValue.AsProtobufJSON | null;
    id: number;
    ownerId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    regionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    spousenRegionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    workRegionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    communeId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    spousenCommuneId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    workCommuneId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    region: RegionModel.AsProtobufJSON | null;
    commune: CommuneModel.AsProtobufJSON | null;
    fatcaUsTaxpayerIdentificationNumber: googleProtobuf000.StringValue.AsProtobufJSON | null;
    fatcaReportAuthorization: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    otherCountryTaxDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    potencialEquity: googleProtobuf000.StringValue.AsProtobufJSON | null;
    incomeCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    patrimonyCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.UserCodeModel
 */
export class UserCodeModel implements GrpcMessage {
  static id = 'common_message.UserCodeModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserCodeModel();
    UserCodeModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserCodeModel) {
    _instance.id = _instance.id || 0;
    _instance.code = _instance.code || '';
    _instance.type = _instance.type || '';
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.users = _instance.users || [];
    _instance.status = _instance.status || '';
    _instance.userId = _instance.userId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserCodeModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readDouble();
          break;
        case 2:
          _instance.code = _reader.readString();
          break;
        case 3:
          _instance.type = _reader.readString();
          break;
        case 4:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          const messageInitializer6 = new UserModel();
          _reader.readMessage(
            messageInitializer6,
            UserModel.deserializeBinaryFromReader
          );
          (_instance.users = _instance.users || []).push(messageInitializer6);
          break;
        case 7:
          _instance.status = _reader.readString();
          break;
        case 8:
          _instance.userId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    UserCodeModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserCodeModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeDouble(1, _instance.id);
    }
    if (_instance.code) {
      _writer.writeString(2, _instance.code);
    }
    if (_instance.type) {
      _writer.writeString(3, _instance.type);
    }
    if (_instance.created) {
      _writer.writeMessage(
        4,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        5,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.users && _instance.users.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.users as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeString(7, _instance.status);
    }
    if (_instance.userId) {
      _writer.writeInt32(8, _instance.userId);
    }
  }

  private _id: number;
  private _code: string;
  private _type: string;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _users?: UserModel[];
  private _status: string;
  private _userId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserCodeModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserCodeModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.code = _value.code;
    this.type = _value.type;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.users = (_value.users || []).map(m => new UserModel(m));
    this.status = _value.status;
    this.userId = _value.userId;
    UserCodeModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get users(): UserModel[] | undefined {
    return this._users;
  }
  set users(value: UserModel[] | undefined) {
    this._users = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserCodeModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserCodeModel.AsObject {
    return {
      id: this.id,
      code: this.code,
      type: this.type,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      users: (this.users || []).map(m => m.toObject()),
      status: this.status,
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserCodeModel.AsProtobufJSON {
    return {
      id: this.id,
      code: this.code,
      type: this.type,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      users: (this.users || []).map(m => m.toProtobufJSON(options)),
      status: this.status,
      userId: this.userId
    };
  }
}
export module UserCodeModel {
  /**
   * Standard JavaScript object representation for UserCodeModel
   */
  export interface AsObject {
    id: number;
    code: string;
    type: string;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    users?: UserModel.AsObject[];
    status: string;
    userId: number;
  }

  /**
   * Protobuf JSON representation for UserCodeModel
   */
  export interface AsProtobufJSON {
    id: number;
    code: string;
    type: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    users: UserModel.AsProtobufJSON[] | null;
    status: string;
    userId: number;
  }
}

/**
 * Message implementation for common_message.UserReferralInfoModel
 */
export class UserReferralInfoModel implements GrpcMessage {
  static id = 'common_message.UserReferralInfoModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserReferralInfoModel();
    UserReferralInfoModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserReferralInfoModel) {
    _instance.id = _instance.id || 0;
    _instance.code = _instance.code || '';
    _instance.type = _instance.type || '';
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.referralPromoEnd = _instance.referralPromoEnd || undefined;
    _instance.user = _instance.user || undefined;
    _instance.hasInvested = _instance.hasInvested || false;
    _instance.client = _instance.client || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserReferralInfoModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readDouble();
          break;
        case 2:
          _instance.code = _reader.readString();
          break;
        case 3:
          _instance.type = _reader.readString();
          break;
        case 4:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.referralPromoEnd = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.referralPromoEnd,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.user = new UserModel();
          _reader.readMessage(
            _instance.user,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.hasInvested = _reader.readBool();
          break;
        case 9:
          _instance.client = new UserModel();
          _reader.readMessage(
            _instance.client,
            UserModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserReferralInfoModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserReferralInfoModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeDouble(1, _instance.id);
    }
    if (_instance.code) {
      _writer.writeString(2, _instance.code);
    }
    if (_instance.type) {
      _writer.writeString(3, _instance.type);
    }
    if (_instance.created) {
      _writer.writeMessage(
        4,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        5,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.referralPromoEnd) {
      _writer.writeMessage(
        6,
        _instance.referralPromoEnd as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.user) {
      _writer.writeMessage(
        7,
        _instance.user as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.hasInvested) {
      _writer.writeBool(8, _instance.hasInvested);
    }
    if (_instance.client) {
      _writer.writeMessage(
        9,
        _instance.client as any,
        UserModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _code: string;
  private _type: string;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _referralPromoEnd?: googleProtobuf001.Timestamp;
  private _user?: UserModel;
  private _hasInvested: boolean;
  private _client?: UserModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserReferralInfoModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserReferralInfoModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.code = _value.code;
    this.type = _value.type;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.referralPromoEnd = _value.referralPromoEnd
      ? new googleProtobuf001.Timestamp(_value.referralPromoEnd)
      : undefined;
    this.user = _value.user ? new UserModel(_value.user) : undefined;
    this.hasInvested = _value.hasInvested;
    this.client = _value.client ? new UserModel(_value.client) : undefined;
    UserReferralInfoModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get referralPromoEnd(): googleProtobuf001.Timestamp | undefined {
    return this._referralPromoEnd;
  }
  set referralPromoEnd(value: googleProtobuf001.Timestamp | undefined) {
    this._referralPromoEnd = value;
  }
  get user(): UserModel | undefined {
    return this._user;
  }
  set user(value: UserModel | undefined) {
    this._user = value;
  }
  get hasInvested(): boolean {
    return this._hasInvested;
  }
  set hasInvested(value: boolean) {
    this._hasInvested = value;
  }
  get client(): UserModel | undefined {
    return this._client;
  }
  set client(value: UserModel | undefined) {
    this._client = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserReferralInfoModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserReferralInfoModel.AsObject {
    return {
      id: this.id,
      code: this.code,
      type: this.type,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      referralPromoEnd: this.referralPromoEnd
        ? this.referralPromoEnd.toObject()
        : undefined,
      user: this.user ? this.user.toObject() : undefined,
      hasInvested: this.hasInvested,
      client: this.client ? this.client.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserReferralInfoModel.AsProtobufJSON {
    return {
      id: this.id,
      code: this.code,
      type: this.type,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      referralPromoEnd: this.referralPromoEnd
        ? this.referralPromoEnd.toProtobufJSON(options)
        : null,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      hasInvested: this.hasInvested,
      client: this.client ? this.client.toProtobufJSON(options) : null
    };
  }
}
export module UserReferralInfoModel {
  /**
   * Standard JavaScript object representation for UserReferralInfoModel
   */
  export interface AsObject {
    id: number;
    code: string;
    type: string;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    referralPromoEnd?: googleProtobuf001.Timestamp.AsObject;
    user?: UserModel.AsObject;
    hasInvested: boolean;
    client?: UserModel.AsObject;
  }

  /**
   * Protobuf JSON representation for UserReferralInfoModel
   */
  export interface AsProtobufJSON {
    id: number;
    code: string;
    type: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    referralPromoEnd: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    user: UserModel.AsProtobufJSON | null;
    hasInvested: boolean;
    client: UserModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.CurrencyModel
 */
export class CurrencyModel implements GrpcMessage {
  static id = 'common_message.CurrencyModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CurrencyModel();
    CurrencyModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CurrencyModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.name = _instance.name || '';
    _instance.uuid = _instance.uuid || '';
    _instance.yahoomnemonic = _instance.yahoomnemonic || undefined;
    _instance.sourceIndicators = _instance.sourceIndicators || [];
    _instance.destinationIndicators = _instance.destinationIndicators || [];
    _instance.currencyCode = _instance.currencyCode || '';
    _instance.display = _instance.display || '';
    _instance.digitsInfo = _instance.digitsInfo || '';
    _instance.locale = _instance.locale || '';
    _instance.chartTooltipFormatNumber =
      _instance.chartTooltipFormatNumber || '';
    _instance.chartAxisFormatNumber = _instance.chartAxisFormatNumber || '';
    _instance.vectorCode = _instance.vectorCode || '';
    _instance.isUserBankAccountEnabled =
      _instance.isUserBankAccountEnabled || false;
    _instance.serverFormatNumber = _instance.serverFormatNumber || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CurrencyModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.uuid = _reader.readString();
          break;
        case 6:
          _instance.yahoomnemonic = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.yahoomnemonic,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 7:
          const messageInitializer7 = new CurrencyIndicatorModel();
          _reader.readMessage(
            messageInitializer7,
            CurrencyIndicatorModel.deserializeBinaryFromReader
          );
          (_instance.sourceIndicators = _instance.sourceIndicators || []).push(
            messageInitializer7
          );
          break;
        case 8:
          const messageInitializer8 = new CurrencyIndicatorModel();
          _reader.readMessage(
            messageInitializer8,
            CurrencyIndicatorModel.deserializeBinaryFromReader
          );
          (_instance.destinationIndicators =
            _instance.destinationIndicators || []).push(messageInitializer8);
          break;
        case 9:
          _instance.currencyCode = _reader.readString();
          break;
        case 10:
          _instance.display = _reader.readString();
          break;
        case 11:
          _instance.digitsInfo = _reader.readString();
          break;
        case 12:
          _instance.locale = _reader.readString();
          break;
        case 13:
          _instance.chartTooltipFormatNumber = _reader.readString();
          break;
        case 14:
          _instance.chartAxisFormatNumber = _reader.readString();
          break;
        case 15:
          _instance.vectorCode = _reader.readString();
          break;
        case 16:
          _instance.isUserBankAccountEnabled = _reader.readBool();
          break;
        case 17:
          _instance.serverFormatNumber = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CurrencyModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CurrencyModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.uuid) {
      _writer.writeString(5, _instance.uuid);
    }
    if (_instance.yahoomnemonic) {
      _writer.writeMessage(
        6,
        _instance.yahoomnemonic as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.sourceIndicators && _instance.sourceIndicators.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.sourceIndicators as any,
        CurrencyIndicatorModel.serializeBinaryToWriter
      );
    }
    if (
      _instance.destinationIndicators &&
      _instance.destinationIndicators.length
    ) {
      _writer.writeRepeatedMessage(
        8,
        _instance.destinationIndicators as any,
        CurrencyIndicatorModel.serializeBinaryToWriter
      );
    }
    if (_instance.currencyCode) {
      _writer.writeString(9, _instance.currencyCode);
    }
    if (_instance.display) {
      _writer.writeString(10, _instance.display);
    }
    if (_instance.digitsInfo) {
      _writer.writeString(11, _instance.digitsInfo);
    }
    if (_instance.locale) {
      _writer.writeString(12, _instance.locale);
    }
    if (_instance.chartTooltipFormatNumber) {
      _writer.writeString(13, _instance.chartTooltipFormatNumber);
    }
    if (_instance.chartAxisFormatNumber) {
      _writer.writeString(14, _instance.chartAxisFormatNumber);
    }
    if (_instance.vectorCode) {
      _writer.writeString(15, _instance.vectorCode);
    }
    if (_instance.isUserBankAccountEnabled) {
      _writer.writeBool(16, _instance.isUserBankAccountEnabled);
    }
    if (_instance.serverFormatNumber) {
      _writer.writeString(17, _instance.serverFormatNumber);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _name: string;
  private _uuid: string;
  private _yahoomnemonic?: googleProtobuf000.StringValue;
  private _sourceIndicators?: CurrencyIndicatorModel[];
  private _destinationIndicators?: CurrencyIndicatorModel[];
  private _currencyCode: string;
  private _display: string;
  private _digitsInfo: string;
  private _locale: string;
  private _chartTooltipFormatNumber: string;
  private _chartAxisFormatNumber: string;
  private _vectorCode: string;
  private _isUserBankAccountEnabled: boolean;
  private _serverFormatNumber: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CurrencyModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<CurrencyModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.name = _value.name;
    this.uuid = _value.uuid;
    this.yahoomnemonic = _value.yahoomnemonic
      ? new googleProtobuf000.StringValue(_value.yahoomnemonic)
      : undefined;
    this.sourceIndicators = (_value.sourceIndicators || []).map(
      m => new CurrencyIndicatorModel(m)
    );
    this.destinationIndicators = (_value.destinationIndicators || []).map(
      m => new CurrencyIndicatorModel(m)
    );
    this.currencyCode = _value.currencyCode;
    this.display = _value.display;
    this.digitsInfo = _value.digitsInfo;
    this.locale = _value.locale;
    this.chartTooltipFormatNumber = _value.chartTooltipFormatNumber;
    this.chartAxisFormatNumber = _value.chartAxisFormatNumber;
    this.vectorCode = _value.vectorCode;
    this.isUserBankAccountEnabled = _value.isUserBankAccountEnabled;
    this.serverFormatNumber = _value.serverFormatNumber;
    CurrencyModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get yahoomnemonic(): googleProtobuf000.StringValue | undefined {
    return this._yahoomnemonic;
  }
  set yahoomnemonic(value: googleProtobuf000.StringValue | undefined) {
    this._yahoomnemonic = value;
  }
  get sourceIndicators(): CurrencyIndicatorModel[] | undefined {
    return this._sourceIndicators;
  }
  set sourceIndicators(value: CurrencyIndicatorModel[] | undefined) {
    this._sourceIndicators = value;
  }
  get destinationIndicators(): CurrencyIndicatorModel[] | undefined {
    return this._destinationIndicators;
  }
  set destinationIndicators(value: CurrencyIndicatorModel[] | undefined) {
    this._destinationIndicators = value;
  }
  get currencyCode(): string {
    return this._currencyCode;
  }
  set currencyCode(value: string) {
    this._currencyCode = value;
  }
  get display(): string {
    return this._display;
  }
  set display(value: string) {
    this._display = value;
  }
  get digitsInfo(): string {
    return this._digitsInfo;
  }
  set digitsInfo(value: string) {
    this._digitsInfo = value;
  }
  get locale(): string {
    return this._locale;
  }
  set locale(value: string) {
    this._locale = value;
  }
  get chartTooltipFormatNumber(): string {
    return this._chartTooltipFormatNumber;
  }
  set chartTooltipFormatNumber(value: string) {
    this._chartTooltipFormatNumber = value;
  }
  get chartAxisFormatNumber(): string {
    return this._chartAxisFormatNumber;
  }
  set chartAxisFormatNumber(value: string) {
    this._chartAxisFormatNumber = value;
  }
  get vectorCode(): string {
    return this._vectorCode;
  }
  set vectorCode(value: string) {
    this._vectorCode = value;
  }
  get isUserBankAccountEnabled(): boolean {
    return this._isUserBankAccountEnabled;
  }
  set isUserBankAccountEnabled(value: boolean) {
    this._isUserBankAccountEnabled = value;
  }
  get serverFormatNumber(): string {
    return this._serverFormatNumber;
  }
  set serverFormatNumber(value: string) {
    this._serverFormatNumber = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CurrencyModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CurrencyModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      name: this.name,
      uuid: this.uuid,
      yahoomnemonic: this.yahoomnemonic
        ? this.yahoomnemonic.toObject()
        : undefined,
      sourceIndicators: (this.sourceIndicators || []).map(m => m.toObject()),
      destinationIndicators: (this.destinationIndicators || []).map(m =>
        m.toObject()
      ),
      currencyCode: this.currencyCode,
      display: this.display,
      digitsInfo: this.digitsInfo,
      locale: this.locale,
      chartTooltipFormatNumber: this.chartTooltipFormatNumber,
      chartAxisFormatNumber: this.chartAxisFormatNumber,
      vectorCode: this.vectorCode,
      isUserBankAccountEnabled: this.isUserBankAccountEnabled,
      serverFormatNumber: this.serverFormatNumber
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CurrencyModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      name: this.name,
      uuid: this.uuid,
      yahoomnemonic: this.yahoomnemonic
        ? this.yahoomnemonic.toProtobufJSON(options)
        : null,
      sourceIndicators: (this.sourceIndicators || []).map(m =>
        m.toProtobufJSON(options)
      ),
      destinationIndicators: (this.destinationIndicators || []).map(m =>
        m.toProtobufJSON(options)
      ),
      currencyCode: this.currencyCode,
      display: this.display,
      digitsInfo: this.digitsInfo,
      locale: this.locale,
      chartTooltipFormatNumber: this.chartTooltipFormatNumber,
      chartAxisFormatNumber: this.chartAxisFormatNumber,
      vectorCode: this.vectorCode,
      isUserBankAccountEnabled: this.isUserBankAccountEnabled,
      serverFormatNumber: this.serverFormatNumber
    };
  }
}
export module CurrencyModel {
  /**
   * Standard JavaScript object representation for CurrencyModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    name: string;
    uuid: string;
    yahoomnemonic?: googleProtobuf000.StringValue.AsObject;
    sourceIndicators?: CurrencyIndicatorModel.AsObject[];
    destinationIndicators?: CurrencyIndicatorModel.AsObject[];
    currencyCode: string;
    display: string;
    digitsInfo: string;
    locale: string;
    chartTooltipFormatNumber: string;
    chartAxisFormatNumber: string;
    vectorCode: string;
    isUserBankAccountEnabled: boolean;
    serverFormatNumber: string;
  }

  /**
   * Protobuf JSON representation for CurrencyModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    name: string;
    uuid: string;
    yahoomnemonic: googleProtobuf000.StringValue.AsProtobufJSON | null;
    sourceIndicators: CurrencyIndicatorModel.AsProtobufJSON[] | null;
    destinationIndicators: CurrencyIndicatorModel.AsProtobufJSON[] | null;
    currencyCode: string;
    display: string;
    digitsInfo: string;
    locale: string;
    chartTooltipFormatNumber: string;
    chartAxisFormatNumber: string;
    vectorCode: string;
    isUserBankAccountEnabled: boolean;
    serverFormatNumber: string;
  }
}

/**
 * Message implementation for common_message.CurrencyIndicatorModel
 */
export class CurrencyIndicatorModel implements GrpcMessage {
  static id = 'common_message.CurrencyIndicatorModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CurrencyIndicatorModel();
    CurrencyIndicatorModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CurrencyIndicatorModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.value = _instance.value || 0;
    _instance.date = _instance.date || undefined;
    _instance.sourceCurrencyId = _instance.sourceCurrencyId || 0;
    _instance.destinationCurrencyId = _instance.destinationCurrencyId || 0;
    _instance.destinationCurrency = _instance.destinationCurrency || undefined;
    _instance.sourceCurrency = _instance.sourceCurrency || undefined;
    _instance.ask = _instance.ask || 0;
    _instance.askBetterplan = _instance.askBetterplan || 0;
    _instance.bid = _instance.bid || 0;
    _instance.bidBetterplan = _instance.bidBetterplan || 0;
    _instance.requestDate = _instance.requestDate || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CurrencyIndicatorModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.value = _reader.readDouble();
          break;
        case 5:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.sourceCurrencyId = _reader.readInt32();
          break;
        case 7:
          _instance.destinationCurrencyId = _reader.readInt32();
          break;
        case 8:
          _instance.destinationCurrency = new CurrencyModel();
          _reader.readMessage(
            _instance.destinationCurrency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.sourceCurrency = new CurrencyModel();
          _reader.readMessage(
            _instance.sourceCurrency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.ask = _reader.readDouble();
          break;
        case 11:
          _instance.askBetterplan = _reader.readDouble();
          break;
        case 12:
          _instance.bid = _reader.readDouble();
          break;
        case 13:
          _instance.bidBetterplan = _reader.readDouble();
          break;
        case 14:
          _instance.requestDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.requestDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CurrencyIndicatorModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CurrencyIndicatorModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.value) {
      _writer.writeDouble(4, _instance.value);
    }
    if (_instance.date) {
      _writer.writeMessage(
        5,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.sourceCurrencyId) {
      _writer.writeInt32(6, _instance.sourceCurrencyId);
    }
    if (_instance.destinationCurrencyId) {
      _writer.writeInt32(7, _instance.destinationCurrencyId);
    }
    if (_instance.destinationCurrency) {
      _writer.writeMessage(
        8,
        _instance.destinationCurrency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.sourceCurrency) {
      _writer.writeMessage(
        9,
        _instance.sourceCurrency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.ask) {
      _writer.writeDouble(10, _instance.ask);
    }
    if (_instance.askBetterplan) {
      _writer.writeDouble(11, _instance.askBetterplan);
    }
    if (_instance.bid) {
      _writer.writeDouble(12, _instance.bid);
    }
    if (_instance.bidBetterplan) {
      _writer.writeDouble(13, _instance.bidBetterplan);
    }
    if (_instance.requestDate) {
      _writer.writeMessage(
        14,
        _instance.requestDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _value: number;
  private _date?: googleProtobuf001.Timestamp;
  private _sourceCurrencyId: number;
  private _destinationCurrencyId: number;
  private _destinationCurrency?: CurrencyModel;
  private _sourceCurrency?: CurrencyModel;
  private _ask: number;
  private _askBetterplan: number;
  private _bid: number;
  private _bidBetterplan: number;
  private _requestDate?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CurrencyIndicatorModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<CurrencyIndicatorModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.value = _value.value;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.sourceCurrencyId = _value.sourceCurrencyId;
    this.destinationCurrencyId = _value.destinationCurrencyId;
    this.destinationCurrency = _value.destinationCurrency
      ? new CurrencyModel(_value.destinationCurrency)
      : undefined;
    this.sourceCurrency = _value.sourceCurrency
      ? new CurrencyModel(_value.sourceCurrency)
      : undefined;
    this.ask = _value.ask;
    this.askBetterplan = _value.askBetterplan;
    this.bid = _value.bid;
    this.bidBetterplan = _value.bidBetterplan;
    this.requestDate = _value.requestDate
      ? new googleProtobuf001.Timestamp(_value.requestDate)
      : undefined;
    CurrencyIndicatorModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get sourceCurrencyId(): number {
    return this._sourceCurrencyId;
  }
  set sourceCurrencyId(value: number) {
    this._sourceCurrencyId = value;
  }
  get destinationCurrencyId(): number {
    return this._destinationCurrencyId;
  }
  set destinationCurrencyId(value: number) {
    this._destinationCurrencyId = value;
  }
  get destinationCurrency(): CurrencyModel | undefined {
    return this._destinationCurrency;
  }
  set destinationCurrency(value: CurrencyModel | undefined) {
    this._destinationCurrency = value;
  }
  get sourceCurrency(): CurrencyModel | undefined {
    return this._sourceCurrency;
  }
  set sourceCurrency(value: CurrencyModel | undefined) {
    this._sourceCurrency = value;
  }
  get ask(): number {
    return this._ask;
  }
  set ask(value: number) {
    this._ask = value;
  }
  get askBetterplan(): number {
    return this._askBetterplan;
  }
  set askBetterplan(value: number) {
    this._askBetterplan = value;
  }
  get bid(): number {
    return this._bid;
  }
  set bid(value: number) {
    this._bid = value;
  }
  get bidBetterplan(): number {
    return this._bidBetterplan;
  }
  set bidBetterplan(value: number) {
    this._bidBetterplan = value;
  }
  get requestDate(): googleProtobuf001.Timestamp | undefined {
    return this._requestDate;
  }
  set requestDate(value: googleProtobuf001.Timestamp | undefined) {
    this._requestDate = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CurrencyIndicatorModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CurrencyIndicatorModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      value: this.value,
      date: this.date ? this.date.toObject() : undefined,
      sourceCurrencyId: this.sourceCurrencyId,
      destinationCurrencyId: this.destinationCurrencyId,
      destinationCurrency: this.destinationCurrency
        ? this.destinationCurrency.toObject()
        : undefined,
      sourceCurrency: this.sourceCurrency
        ? this.sourceCurrency.toObject()
        : undefined,
      ask: this.ask,
      askBetterplan: this.askBetterplan,
      bid: this.bid,
      bidBetterplan: this.bidBetterplan,
      requestDate: this.requestDate ? this.requestDate.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CurrencyIndicatorModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      value: this.value,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      sourceCurrencyId: this.sourceCurrencyId,
      destinationCurrencyId: this.destinationCurrencyId,
      destinationCurrency: this.destinationCurrency
        ? this.destinationCurrency.toProtobufJSON(options)
        : null,
      sourceCurrency: this.sourceCurrency
        ? this.sourceCurrency.toProtobufJSON(options)
        : null,
      ask: this.ask,
      askBetterplan: this.askBetterplan,
      bid: this.bid,
      bidBetterplan: this.bidBetterplan,
      requestDate: this.requestDate
        ? this.requestDate.toProtobufJSON(options)
        : null
    };
  }
}
export module CurrencyIndicatorModel {
  /**
   * Standard JavaScript object representation for CurrencyIndicatorModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    value: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    sourceCurrencyId: number;
    destinationCurrencyId: number;
    destinationCurrency?: CurrencyModel.AsObject;
    sourceCurrency?: CurrencyModel.AsObject;
    ask: number;
    askBetterplan: number;
    bid: number;
    bidBetterplan: number;
    requestDate?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for CurrencyIndicatorModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    value: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    sourceCurrencyId: number;
    destinationCurrencyId: number;
    destinationCurrency: CurrencyModel.AsProtobufJSON | null;
    sourceCurrency: CurrencyModel.AsProtobufJSON | null;
    ask: number;
    askBetterplan: number;
    bid: number;
    bidBetterplan: number;
    requestDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.UserBankAccountModel
 */
export class UserBankAccountModel implements GrpcMessage {
  static id = 'common_message.UserBankAccountModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserBankAccountModel();
    UserBankAccountModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserBankAccountModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.accountNumber = _instance.accountNumber || '';
    _instance.type = _instance.type || '';
    _instance.bank = _instance.bank || '';
    _instance.state = _instance.state || '';
    _instance.currencyId = _instance.currencyId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.userId = _instance.userId || 0;
    _instance.user = _instance.user || undefined;
    _instance.currency = _instance.currency || undefined;
    _instance.financialEntity = _instance.financialEntity || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserBankAccountModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.accountNumber = _reader.readString();
          break;
        case 5:
          _instance.type = _reader.readString();
          break;
        case 6:
          _instance.bank = _reader.readString();
          break;
        case 7:
          _instance.state = _reader.readString();
          break;
        case 8:
          _instance.currencyId = _reader.readInt32();
          break;
        case 9:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 10:
          _instance.userId = _reader.readInt32();
          break;
        case 11:
          _instance.user = new UserModel();
          _reader.readMessage(
            _instance.user,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserBankAccountModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserBankAccountModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.accountNumber) {
      _writer.writeString(4, _instance.accountNumber);
    }
    if (_instance.type) {
      _writer.writeString(5, _instance.type);
    }
    if (_instance.bank) {
      _writer.writeString(6, _instance.bank);
    }
    if (_instance.state) {
      _writer.writeString(7, _instance.state);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(8, _instance.currencyId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(9, _instance.financialEntityId);
    }
    if (_instance.userId) {
      _writer.writeInt32(10, _instance.userId);
    }
    if (_instance.user) {
      _writer.writeMessage(
        11,
        _instance.user as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.currency) {
      _writer.writeMessage(
        12,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        13,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _accountNumber: string;
  private _type: string;
  private _bank: string;
  private _state: string;
  private _currencyId: number;
  private _financialEntityId: number;
  private _userId: number;
  private _user?: UserModel;
  private _currency?: CurrencyModel;
  private _financialEntity?: FinancialEntityModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserBankAccountModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserBankAccountModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.accountNumber = _value.accountNumber;
    this.type = _value.type;
    this.bank = _value.bank;
    this.state = _value.state;
    this.currencyId = _value.currencyId;
    this.financialEntityId = _value.financialEntityId;
    this.userId = _value.userId;
    this.user = _value.user ? new UserModel(_value.user) : undefined;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    UserBankAccountModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get accountNumber(): string {
    return this._accountNumber;
  }
  set accountNumber(value: string) {
    this._accountNumber = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get bank(): string {
    return this._bank;
  }
  set bank(value: string) {
    this._bank = value;
  }
  get state(): string {
    return this._state;
  }
  set state(value: string) {
    this._state = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get user(): UserModel | undefined {
    return this._user;
  }
  set user(value: UserModel | undefined) {
    this._user = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserBankAccountModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserBankAccountModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      accountNumber: this.accountNumber,
      type: this.type,
      bank: this.bank,
      state: this.state,
      currencyId: this.currencyId,
      financialEntityId: this.financialEntityId,
      userId: this.userId,
      user: this.user ? this.user.toObject() : undefined,
      currency: this.currency ? this.currency.toObject() : undefined,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserBankAccountModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      accountNumber: this.accountNumber,
      type: this.type,
      bank: this.bank,
      state: this.state,
      currencyId: this.currencyId,
      financialEntityId: this.financialEntityId,
      userId: this.userId,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null
    };
  }
}
export module UserBankAccountModel {
  /**
   * Standard JavaScript object representation for UserBankAccountModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    accountNumber: string;
    type: string;
    bank: string;
    state: string;
    currencyId: number;
    financialEntityId: number;
    userId: number;
    user?: UserModel.AsObject;
    currency?: CurrencyModel.AsObject;
    financialEntity?: FinancialEntityModel.AsObject;
  }

  /**
   * Protobuf JSON representation for UserBankAccountModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    accountNumber: string;
    type: string;
    bank: string;
    state: string;
    currencyId: number;
    financialEntityId: number;
    userId: number;
    user: UserModel.AsProtobufJSON | null;
    currency: CurrencyModel.AsProtobufJSON | null;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.FinancialEntityModel
 */
export class FinancialEntityModel implements GrpcMessage {
  static id = 'common_message.FinancialEntityModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FinancialEntityModel();
    FinancialEntityModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FinancialEntityModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.currentVersionGoalRiskLevelCalculation =
      _instance.currentVersionGoalRiskLevelCalculation || '';
    _instance.currentVersionPortfolio = _instance.currentVersionPortfolio || '';
    _instance.currentVersionRiskLevel = _instance.currentVersionRiskLevel || '';
    _instance.currentVersionTimeHorizon =
      _instance.currentVersionTimeHorizon || '';
    _instance.logo = _instance.logo || '';
    _instance.title = _instance.title || '';
    _instance.shortTitle = _instance.shortTitle || '';
    _instance.description = _instance.description || '';
    _instance.uuid = _instance.uuid || '';
    _instance.hasPaymentInformation = _instance.hasPaymentInformation || false;
    _instance.paymentInformation = _instance.paymentInformation || '';
    _instance.defaultCurrencyId = _instance.defaultCurrencyId || 0;
    _instance.defaultCurrency = _instance.defaultCurrency || undefined;
    _instance.hasStarredGoal = _instance.hasStarredGoal || false;
    _instance.countries = _instance.countries || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FinancialEntityModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.currentVersionGoalRiskLevelCalculation = _reader.readString();
          break;
        case 5:
          _instance.currentVersionPortfolio = _reader.readString();
          break;
        case 6:
          _instance.currentVersionRiskLevel = _reader.readString();
          break;
        case 7:
          _instance.currentVersionTimeHorizon = _reader.readString();
          break;
        case 8:
          _instance.logo = _reader.readString();
          break;
        case 9:
          _instance.title = _reader.readString();
          break;
        case 10:
          _instance.shortTitle = _reader.readString();
          break;
        case 11:
          _instance.description = _reader.readString();
          break;
        case 12:
          _instance.uuid = _reader.readString();
          break;
        case 13:
          _instance.hasPaymentInformation = _reader.readBool();
          break;
        case 14:
          _instance.paymentInformation = _reader.readString();
          break;
        case 15:
          _instance.defaultCurrencyId = _reader.readInt32();
          break;
        case 16:
          _instance.defaultCurrency = new CurrencyModel();
          _reader.readMessage(
            _instance.defaultCurrency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.hasStarredGoal = _reader.readBool();
          break;
        case 18:
          const messageInitializer18 = new CountryModel();
          _reader.readMessage(
            messageInitializer18,
            CountryModel.deserializeBinaryFromReader
          );
          (_instance.countries = _instance.countries || []).push(
            messageInitializer18
          );
          break;
        default:
          _reader.skipField();
      }
    }

    FinancialEntityModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FinancialEntityModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.currentVersionGoalRiskLevelCalculation) {
      _writer.writeString(4, _instance.currentVersionGoalRiskLevelCalculation);
    }
    if (_instance.currentVersionPortfolio) {
      _writer.writeString(5, _instance.currentVersionPortfolio);
    }
    if (_instance.currentVersionRiskLevel) {
      _writer.writeString(6, _instance.currentVersionRiskLevel);
    }
    if (_instance.currentVersionTimeHorizon) {
      _writer.writeString(7, _instance.currentVersionTimeHorizon);
    }
    if (_instance.logo) {
      _writer.writeString(8, _instance.logo);
    }
    if (_instance.title) {
      _writer.writeString(9, _instance.title);
    }
    if (_instance.shortTitle) {
      _writer.writeString(10, _instance.shortTitle);
    }
    if (_instance.description) {
      _writer.writeString(11, _instance.description);
    }
    if (_instance.uuid) {
      _writer.writeString(12, _instance.uuid);
    }
    if (_instance.hasPaymentInformation) {
      _writer.writeBool(13, _instance.hasPaymentInformation);
    }
    if (_instance.paymentInformation) {
      _writer.writeString(14, _instance.paymentInformation);
    }
    if (_instance.defaultCurrencyId) {
      _writer.writeInt32(15, _instance.defaultCurrencyId);
    }
    if (_instance.defaultCurrency) {
      _writer.writeMessage(
        16,
        _instance.defaultCurrency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.hasStarredGoal) {
      _writer.writeBool(17, _instance.hasStarredGoal);
    }
    if (_instance.countries && _instance.countries.length) {
      _writer.writeRepeatedMessage(
        18,
        _instance.countries as any,
        CountryModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _currentVersionGoalRiskLevelCalculation: string;
  private _currentVersionPortfolio: string;
  private _currentVersionRiskLevel: string;
  private _currentVersionTimeHorizon: string;
  private _logo: string;
  private _title: string;
  private _shortTitle: string;
  private _description: string;
  private _uuid: string;
  private _hasPaymentInformation: boolean;
  private _paymentInformation: string;
  private _defaultCurrencyId: number;
  private _defaultCurrency?: CurrencyModel;
  private _hasStarredGoal: boolean;
  private _countries?: CountryModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FinancialEntityModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FinancialEntityModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.currentVersionGoalRiskLevelCalculation =
      _value.currentVersionGoalRiskLevelCalculation;
    this.currentVersionPortfolio = _value.currentVersionPortfolio;
    this.currentVersionRiskLevel = _value.currentVersionRiskLevel;
    this.currentVersionTimeHorizon = _value.currentVersionTimeHorizon;
    this.logo = _value.logo;
    this.title = _value.title;
    this.shortTitle = _value.shortTitle;
    this.description = _value.description;
    this.uuid = _value.uuid;
    this.hasPaymentInformation = _value.hasPaymentInformation;
    this.paymentInformation = _value.paymentInformation;
    this.defaultCurrencyId = _value.defaultCurrencyId;
    this.defaultCurrency = _value.defaultCurrency
      ? new CurrencyModel(_value.defaultCurrency)
      : undefined;
    this.hasStarredGoal = _value.hasStarredGoal;
    this.countries = (_value.countries || []).map(m => new CountryModel(m));
    FinancialEntityModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get currentVersionGoalRiskLevelCalculation(): string {
    return this._currentVersionGoalRiskLevelCalculation;
  }
  set currentVersionGoalRiskLevelCalculation(value: string) {
    this._currentVersionGoalRiskLevelCalculation = value;
  }
  get currentVersionPortfolio(): string {
    return this._currentVersionPortfolio;
  }
  set currentVersionPortfolio(value: string) {
    this._currentVersionPortfolio = value;
  }
  get currentVersionRiskLevel(): string {
    return this._currentVersionRiskLevel;
  }
  set currentVersionRiskLevel(value: string) {
    this._currentVersionRiskLevel = value;
  }
  get currentVersionTimeHorizon(): string {
    return this._currentVersionTimeHorizon;
  }
  set currentVersionTimeHorizon(value: string) {
    this._currentVersionTimeHorizon = value;
  }
  get logo(): string {
    return this._logo;
  }
  set logo(value: string) {
    this._logo = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get shortTitle(): string {
    return this._shortTitle;
  }
  set shortTitle(value: string) {
    this._shortTitle = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get hasPaymentInformation(): boolean {
    return this._hasPaymentInformation;
  }
  set hasPaymentInformation(value: boolean) {
    this._hasPaymentInformation = value;
  }
  get paymentInformation(): string {
    return this._paymentInformation;
  }
  set paymentInformation(value: string) {
    this._paymentInformation = value;
  }
  get defaultCurrencyId(): number {
    return this._defaultCurrencyId;
  }
  set defaultCurrencyId(value: number) {
    this._defaultCurrencyId = value;
  }
  get defaultCurrency(): CurrencyModel | undefined {
    return this._defaultCurrency;
  }
  set defaultCurrency(value: CurrencyModel | undefined) {
    this._defaultCurrency = value;
  }
  get hasStarredGoal(): boolean {
    return this._hasStarredGoal;
  }
  set hasStarredGoal(value: boolean) {
    this._hasStarredGoal = value;
  }
  get countries(): CountryModel[] | undefined {
    return this._countries;
  }
  set countries(value: CountryModel[] | undefined) {
    this._countries = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FinancialEntityModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FinancialEntityModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      currentVersionGoalRiskLevelCalculation: this
        .currentVersionGoalRiskLevelCalculation,
      currentVersionPortfolio: this.currentVersionPortfolio,
      currentVersionRiskLevel: this.currentVersionRiskLevel,
      currentVersionTimeHorizon: this.currentVersionTimeHorizon,
      logo: this.logo,
      title: this.title,
      shortTitle: this.shortTitle,
      description: this.description,
      uuid: this.uuid,
      hasPaymentInformation: this.hasPaymentInformation,
      paymentInformation: this.paymentInformation,
      defaultCurrencyId: this.defaultCurrencyId,
      defaultCurrency: this.defaultCurrency
        ? this.defaultCurrency.toObject()
        : undefined,
      hasStarredGoal: this.hasStarredGoal,
      countries: (this.countries || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FinancialEntityModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      currentVersionGoalRiskLevelCalculation: this
        .currentVersionGoalRiskLevelCalculation,
      currentVersionPortfolio: this.currentVersionPortfolio,
      currentVersionRiskLevel: this.currentVersionRiskLevel,
      currentVersionTimeHorizon: this.currentVersionTimeHorizon,
      logo: this.logo,
      title: this.title,
      shortTitle: this.shortTitle,
      description: this.description,
      uuid: this.uuid,
      hasPaymentInformation: this.hasPaymentInformation,
      paymentInformation: this.paymentInformation,
      defaultCurrencyId: this.defaultCurrencyId,
      defaultCurrency: this.defaultCurrency
        ? this.defaultCurrency.toProtobufJSON(options)
        : null,
      hasStarredGoal: this.hasStarredGoal,
      countries: (this.countries || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module FinancialEntityModel {
  /**
   * Standard JavaScript object representation for FinancialEntityModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    currentVersionGoalRiskLevelCalculation: string;
    currentVersionPortfolio: string;
    currentVersionRiskLevel: string;
    currentVersionTimeHorizon: string;
    logo: string;
    title: string;
    shortTitle: string;
    description: string;
    uuid: string;
    hasPaymentInformation: boolean;
    paymentInformation: string;
    defaultCurrencyId: number;
    defaultCurrency?: CurrencyModel.AsObject;
    hasStarredGoal: boolean;
    countries?: CountryModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for FinancialEntityModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    currentVersionGoalRiskLevelCalculation: string;
    currentVersionPortfolio: string;
    currentVersionRiskLevel: string;
    currentVersionTimeHorizon: string;
    logo: string;
    title: string;
    shortTitle: string;
    description: string;
    uuid: string;
    hasPaymentInformation: boolean;
    paymentInformation: string;
    defaultCurrencyId: number;
    defaultCurrency: CurrencyModel.AsProtobufJSON | null;
    hasStarredGoal: boolean;
    countries: CountryModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for common_message.FundingLiteModel
 */
export class FundingLiteModel implements GrpcMessage {
  static id = 'common_message.FundingLiteModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FundingLiteModel();
    FundingLiteModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FundingLiteModel) {
    _instance.id = _instance.id || 0;
    _instance.mnemonic = _instance.mnemonic || '';
    _instance.yahoomnemonic = _instance.yahoomnemonic || '';
    _instance.title = _instance.title || '';
    _instance.hasSharedValue = _instance.hasSharedValue || false;
    _instance.isBox = _instance.isBox || false;
    _instance.cost = _instance.cost || 0;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.isSemiLiquid = _instance.isSemiLiquid || false;
    _instance.financialEntityIds = _instance.financialEntityIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FundingLiteModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.mnemonic = _reader.readString();
          break;
        case 3:
          _instance.yahoomnemonic = _reader.readString();
          break;
        case 4:
          _instance.title = _reader.readString();
          break;
        case 5:
          _instance.hasSharedValue = _reader.readBool();
          break;
        case 6:
          _instance.isBox = _reader.readBool();
          break;
        case 7:
          _instance.cost = _reader.readDouble();
          break;
        case 8:
          _instance.currencyId = _reader.readInt32();
          break;
        case 9:
          _instance.isSemiLiquid = _reader.readBool();
          break;
        case 11:
          (_instance.financialEntityIds =
            _instance.financialEntityIds || []).push(
            ...(_reader.readPackedInt32() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    FundingLiteModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FundingLiteModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.mnemonic) {
      _writer.writeString(2, _instance.mnemonic);
    }
    if (_instance.yahoomnemonic) {
      _writer.writeString(3, _instance.yahoomnemonic);
    }
    if (_instance.title) {
      _writer.writeString(4, _instance.title);
    }
    if (_instance.hasSharedValue) {
      _writer.writeBool(5, _instance.hasSharedValue);
    }
    if (_instance.isBox) {
      _writer.writeBool(6, _instance.isBox);
    }
    if (_instance.cost) {
      _writer.writeDouble(7, _instance.cost);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(8, _instance.currencyId);
    }
    if (_instance.isSemiLiquid) {
      _writer.writeBool(9, _instance.isSemiLiquid);
    }
    if (_instance.financialEntityIds && _instance.financialEntityIds.length) {
      _writer.writePackedInt32(11, _instance.financialEntityIds);
    }
  }

  private _id: number;
  private _mnemonic: string;
  private _yahoomnemonic: string;
  private _title: string;
  private _hasSharedValue: boolean;
  private _isBox: boolean;
  private _cost: number;
  private _currencyId: number;
  private _isSemiLiquid: boolean;
  private _financialEntityIds: number[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FundingLiteModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FundingLiteModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.mnemonic = _value.mnemonic;
    this.yahoomnemonic = _value.yahoomnemonic;
    this.title = _value.title;
    this.hasSharedValue = _value.hasSharedValue;
    this.isBox = _value.isBox;
    this.cost = _value.cost;
    this.currencyId = _value.currencyId;
    this.isSemiLiquid = _value.isSemiLiquid;
    this.financialEntityIds = (_value.financialEntityIds || []).slice();
    FundingLiteModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get mnemonic(): string {
    return this._mnemonic;
  }
  set mnemonic(value: string) {
    this._mnemonic = value;
  }
  get yahoomnemonic(): string {
    return this._yahoomnemonic;
  }
  set yahoomnemonic(value: string) {
    this._yahoomnemonic = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get hasSharedValue(): boolean {
    return this._hasSharedValue;
  }
  set hasSharedValue(value: boolean) {
    this._hasSharedValue = value;
  }
  get isBox(): boolean {
    return this._isBox;
  }
  set isBox(value: boolean) {
    this._isBox = value;
  }
  get cost(): number {
    return this._cost;
  }
  set cost(value: number) {
    this._cost = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get isSemiLiquid(): boolean {
    return this._isSemiLiquid;
  }
  set isSemiLiquid(value: boolean) {
    this._isSemiLiquid = value;
  }
  get financialEntityIds(): number[] {
    return this._financialEntityIds;
  }
  set financialEntityIds(value: number[]) {
    this._financialEntityIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FundingLiteModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FundingLiteModel.AsObject {
    return {
      id: this.id,
      mnemonic: this.mnemonic,
      yahoomnemonic: this.yahoomnemonic,
      title: this.title,
      hasSharedValue: this.hasSharedValue,
      isBox: this.isBox,
      cost: this.cost,
      currencyId: this.currencyId,
      isSemiLiquid: this.isSemiLiquid,
      financialEntityIds: (this.financialEntityIds || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FundingLiteModel.AsProtobufJSON {
    return {
      id: this.id,
      mnemonic: this.mnemonic,
      yahoomnemonic: this.yahoomnemonic,
      title: this.title,
      hasSharedValue: this.hasSharedValue,
      isBox: this.isBox,
      cost: this.cost,
      currencyId: this.currencyId,
      isSemiLiquid: this.isSemiLiquid,
      financialEntityIds: (this.financialEntityIds || []).slice()
    };
  }
}
export module FundingLiteModel {
  /**
   * Standard JavaScript object representation for FundingLiteModel
   */
  export interface AsObject {
    id: number;
    mnemonic: string;
    yahoomnemonic: string;
    title: string;
    hasSharedValue: boolean;
    isBox: boolean;
    cost: number;
    currencyId: number;
    isSemiLiquid: boolean;
    financialEntityIds: number[];
  }

  /**
   * Protobuf JSON representation for FundingLiteModel
   */
  export interface AsProtobufJSON {
    id: number;
    mnemonic: string;
    yahoomnemonic: string;
    title: string;
    hasSharedValue: boolean;
    isBox: boolean;
    cost: number;
    currencyId: number;
    isSemiLiquid: boolean;
    financialEntityIds: number[];
  }
}

/**
 * Message implementation for common_message.FundingModel
 */
export class FundingModel implements GrpcMessage {
  static id = 'common_message.FundingModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FundingModel();
    FundingModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FundingModel) {
    _instance.description = _instance.description || '';
    _instance.hasPaymentInformation = _instance.hasPaymentInformation || false;

    _instance.uuid = _instance.uuid || '';
    _instance.mnemonic = _instance.mnemonic || '';
    _instance.isBox = _instance.isBox || false;
    _instance.cost = _instance.cost || 0;
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.informativeBrochureId =
      _instance.informativeBrochureId || undefined;
    _instance.title = _instance.title || '';
    _instance.shareValues = _instance.shareValues || [];
    _instance.mname = _instance.mname || '';
    _instance.features = _instance.features || [];
    _instance.hasSharedValue = _instance.hasSharedValue || false;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.fundingCompositions = _instance.fundingCompositions || [];
    _instance.customPortfolioEnabled =
      _instance.customPortfolioEnabled || false;
    _instance.shortDescription = _instance.shortDescription || '';
    _instance.featuresjson = _instance.featuresjson || [];
    _instance.yahoomnemonic = _instance.yahoomnemonic || '';
    _instance.cmfurl = _instance.cmfurl || '';
    _instance.showInComparator = _instance.showInComparator || false;
    _instance.brokerexchange = _instance.brokerexchange || '';
    _instance.showInEconomicIndicator =
      _instance.showInEconomicIndicator || false;
    _instance.showInEconomicIndicatorCurrencyId =
      _instance.showInEconomicIndicatorCurrencyId || 0;
    _instance.showInEconomicIndicatorOrder =
      _instance.showInEconomicIndicatorOrder || 0;
    _instance.showInEconomicIndicatorCurrency =
      _instance.showInEconomicIndicatorCurrency || undefined;
    _instance.settlementForm = _instance.settlementForm || '';
    _instance.bpComission = _instance.bpComission || 0;
    _instance.hasBenefit107 = _instance.hasBenefit107 || false;
    _instance.additionalDescription = _instance.additionalDescription || '';
    _instance.isDefaultCustomFund = _instance.isDefaultCustomFund || false;
    _instance.paymentInformation = _instance.paymentInformation || undefined;
    _instance.withHoldingTax100 = _instance.withHoldingTax100 || false;
    _instance.informativeBrochureUrl = _instance.informativeBrochureUrl || '';
    _instance.cmfSerie = _instance.cmfSerie || '';
    _instance.isSemiLiquid = _instance.isSemiLiquid || false;
    _instance.priceMargin = _instance.priceMargin || 0;
    _instance.hasCashback = _instance.hasCashback || false;
    _instance.fundingTags = _instance.fundingTags || [];
    _instance.urlImage = _instance.urlImage || undefined;
    _instance.iconClass = _instance.iconClass || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FundingModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.description = _reader.readString();
          break;
        case 2:
          _instance.hasPaymentInformation = _reader.readBool();
          break;
        case 3:
          _instance.paymentInformations = _reader.readString();
          break;
        case 14:
          _instance.lastShareValue = new FundingShareValueModel();
          _reader.readMessage(
            _instance.lastShareValue,
            FundingShareValueModel.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.uuid = _reader.readString();
          break;
        case 5:
          _instance.mnemonic = _reader.readString();
          break;
        case 6:
          _instance.isBox = _reader.readBool();
          break;
        case 7:
          _instance.cost = _reader.readDouble();
          break;
        case 8:
          _instance.id = _reader.readInt32();
          break;
        case 10:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.informativeBrochureId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.informativeBrochureId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.title = _reader.readString();
          break;
        case 15:
          const messageInitializer15 = new FundingShareValueModel();
          _reader.readMessage(
            messageInitializer15,
            FundingShareValueModel.deserializeBinaryFromReader
          );
          (_instance.shareValues = _instance.shareValues || []).push(
            messageInitializer15
          );
          break;
        case 16:
          _instance.mname = _reader.readString();
          break;
        case 17:
          (_instance.features = _instance.features || []).push(
            _reader.readString()
          );
          break;
        case 18:
          _instance.hasSharedValue = _reader.readBool();
          break;
        case 19:
          _instance.currencyId = _reader.readInt32();
          break;
        case 20:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 21:
          const messageInitializer21 = new FundingCompositionModel();
          _reader.readMessage(
            messageInitializer21,
            FundingCompositionModel.deserializeBinaryFromReader
          );
          (_instance.fundingCompositions =
            _instance.fundingCompositions || []).push(messageInitializer21);
          break;
        case 22:
          _instance.customPortfolioEnabled = _reader.readBool();
          break;
        case 23:
          _instance.shortDescription = _reader.readString();
          break;
        case 24:
          (_instance.featuresjson = _instance.featuresjson || []).push(
            _reader.readString()
          );
          break;
        case 25:
          _instance.yahoomnemonic = _reader.readString();
          break;
        case 26:
          _instance.cmfurl = _reader.readString();
          break;
        case 27:
          _instance.showInComparator = _reader.readBool();
          break;
        case 28:
          _instance.brokerexchange = _reader.readString();
          break;
        case 29:
          _instance.showInEconomicIndicator = _reader.readBool();
          break;
        case 30:
          _instance.showInEconomicIndicatorCurrencyId = _reader.readInt32();
          break;
        case 31:
          _instance.showInEconomicIndicatorOrder = _reader.readInt32();
          break;
        case 32:
          _instance.showInEconomicIndicatorCurrency = new CurrencyModel();
          _reader.readMessage(
            _instance.showInEconomicIndicatorCurrency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 33:
          _instance.settlementForm = _reader.readString();
          break;
        case 34:
          _instance.bpComission = _reader.readDouble();
          break;
        case 35:
          _instance.hasBenefit107 = _reader.readBool();
          break;
        case 36:
          _instance.additionalDescription = _reader.readString();
          break;
        case 37:
          _instance.isDefaultCustomFund = _reader.readBool();
          break;
        case 38:
          _instance.paymentInformation = new FundingModel.PaymentInformation();
          _reader.readMessage(
            _instance.paymentInformation,
            FundingModel.PaymentInformation.deserializeBinaryFromReader
          );
          break;
        case 39:
          _instance.withHoldingTax100 = _reader.readBool();
          break;
        case 40:
          _instance.informativeBrochureUrl = _reader.readString();
          break;
        case 41:
          _instance.cmfSerie = _reader.readString();
          break;
        case 42:
          _instance.isSemiLiquid = _reader.readBool();
          break;
        case 43:
          _instance.priceMargin = _reader.readDouble();
          break;
        case 44:
          _instance.hasCashback = _reader.readBool();
          break;
        case 45:
          const messageInitializer45 = new FundingTagModel();
          _reader.readMessage(
            messageInitializer45,
            FundingTagModel.deserializeBinaryFromReader
          );
          (_instance.fundingTags = _instance.fundingTags || []).push(
            messageInitializer45
          );
          break;
        case 46:
          _instance.urlImage = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.urlImage,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 47:
          _instance.iconClass = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.iconClass,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    FundingModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FundingModel,
    _writer: BinaryWriter
  ) {
    if (_instance.description) {
      _writer.writeString(1, _instance.description);
    }
    if (_instance.hasPaymentInformation) {
      _writer.writeBool(2, _instance.hasPaymentInformation);
    }
    if (_instance.paymentInformations || _instance.paymentInformations === '') {
      _writer.writeString(3, _instance.paymentInformations);
    }
    if (_instance.lastShareValue) {
      _writer.writeMessage(
        14,
        _instance.lastShareValue as any,
        FundingShareValueModel.serializeBinaryToWriter
      );
    }
    if (_instance.uuid) {
      _writer.writeString(4, _instance.uuid);
    }
    if (_instance.mnemonic) {
      _writer.writeString(5, _instance.mnemonic);
    }
    if (_instance.isBox) {
      _writer.writeBool(6, _instance.isBox);
    }
    if (_instance.cost) {
      _writer.writeDouble(7, _instance.cost);
    }
    if (_instance.id) {
      _writer.writeInt32(8, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        10,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        11,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.informativeBrochureId) {
      _writer.writeMessage(
        12,
        _instance.informativeBrochureId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.title) {
      _writer.writeString(13, _instance.title);
    }
    if (_instance.shareValues && _instance.shareValues.length) {
      _writer.writeRepeatedMessage(
        15,
        _instance.shareValues as any,
        FundingShareValueModel.serializeBinaryToWriter
      );
    }
    if (_instance.mname) {
      _writer.writeString(16, _instance.mname);
    }
    if (_instance.features && _instance.features.length) {
      _writer.writeRepeatedString(17, _instance.features);
    }
    if (_instance.hasSharedValue) {
      _writer.writeBool(18, _instance.hasSharedValue);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(19, _instance.currencyId);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        20,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.fundingCompositions && _instance.fundingCompositions.length) {
      _writer.writeRepeatedMessage(
        21,
        _instance.fundingCompositions as any,
        FundingCompositionModel.serializeBinaryToWriter
      );
    }
    if (_instance.customPortfolioEnabled) {
      _writer.writeBool(22, _instance.customPortfolioEnabled);
    }
    if (_instance.shortDescription) {
      _writer.writeString(23, _instance.shortDescription);
    }
    if (_instance.featuresjson && _instance.featuresjson.length) {
      _writer.writeRepeatedString(24, _instance.featuresjson);
    }
    if (_instance.yahoomnemonic) {
      _writer.writeString(25, _instance.yahoomnemonic);
    }
    if (_instance.cmfurl) {
      _writer.writeString(26, _instance.cmfurl);
    }
    if (_instance.showInComparator) {
      _writer.writeBool(27, _instance.showInComparator);
    }
    if (_instance.brokerexchange) {
      _writer.writeString(28, _instance.brokerexchange);
    }
    if (_instance.showInEconomicIndicator) {
      _writer.writeBool(29, _instance.showInEconomicIndicator);
    }
    if (_instance.showInEconomicIndicatorCurrencyId) {
      _writer.writeInt32(30, _instance.showInEconomicIndicatorCurrencyId);
    }
    if (_instance.showInEconomicIndicatorOrder) {
      _writer.writeInt32(31, _instance.showInEconomicIndicatorOrder);
    }
    if (_instance.showInEconomicIndicatorCurrency) {
      _writer.writeMessage(
        32,
        _instance.showInEconomicIndicatorCurrency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.settlementForm) {
      _writer.writeString(33, _instance.settlementForm);
    }
    if (_instance.bpComission) {
      _writer.writeDouble(34, _instance.bpComission);
    }
    if (_instance.hasBenefit107) {
      _writer.writeBool(35, _instance.hasBenefit107);
    }
    if (_instance.additionalDescription) {
      _writer.writeString(36, _instance.additionalDescription);
    }
    if (_instance.isDefaultCustomFund) {
      _writer.writeBool(37, _instance.isDefaultCustomFund);
    }
    if (_instance.paymentInformation) {
      _writer.writeMessage(
        38,
        _instance.paymentInformation as any,
        FundingModel.PaymentInformation.serializeBinaryToWriter
      );
    }
    if (_instance.withHoldingTax100) {
      _writer.writeBool(39, _instance.withHoldingTax100);
    }
    if (_instance.informativeBrochureUrl) {
      _writer.writeString(40, _instance.informativeBrochureUrl);
    }
    if (_instance.cmfSerie) {
      _writer.writeString(41, _instance.cmfSerie);
    }
    if (_instance.isSemiLiquid) {
      _writer.writeBool(42, _instance.isSemiLiquid);
    }
    if (_instance.priceMargin) {
      _writer.writeDouble(43, _instance.priceMargin);
    }
    if (_instance.hasCashback) {
      _writer.writeBool(44, _instance.hasCashback);
    }
    if (_instance.fundingTags && _instance.fundingTags.length) {
      _writer.writeRepeatedMessage(
        45,
        _instance.fundingTags as any,
        FundingTagModel.serializeBinaryToWriter
      );
    }
    if (_instance.urlImage) {
      _writer.writeMessage(
        46,
        _instance.urlImage as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.iconClass) {
      _writer.writeMessage(
        47,
        _instance.iconClass as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _description: string;
  private _hasPaymentInformation: boolean;
  private _paymentInformations: string;
  private _lastShareValue?: FundingShareValueModel;
  private _uuid: string;
  private _mnemonic: string;
  private _isBox: boolean;
  private _cost: number;
  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _informativeBrochureId?: googleProtobuf000.Int32Value;
  private _title: string;
  private _shareValues?: FundingShareValueModel[];
  private _mname: string;
  private _features: string[];
  private _hasSharedValue: boolean;
  private _currencyId: number;
  private _currency?: CurrencyModel;
  private _fundingCompositions?: FundingCompositionModel[];
  private _customPortfolioEnabled: boolean;
  private _shortDescription: string;
  private _featuresjson: string[];
  private _yahoomnemonic: string;
  private _cmfurl: string;
  private _showInComparator: boolean;
  private _brokerexchange: string;
  private _showInEconomicIndicator: boolean;
  private _showInEconomicIndicatorCurrencyId: number;
  private _showInEconomicIndicatorOrder: number;
  private _showInEconomicIndicatorCurrency?: CurrencyModel;
  private _settlementForm: string;
  private _bpComission: number;
  private _hasBenefit107: boolean;
  private _additionalDescription: string;
  private _isDefaultCustomFund: boolean;
  private _paymentInformation?: FundingModel.PaymentInformation;
  private _withHoldingTax100: boolean;
  private _informativeBrochureUrl: string;
  private _cmfSerie: string;
  private _isSemiLiquid: boolean;
  private _priceMargin: number;
  private _hasCashback: boolean;
  private _fundingTags?: FundingTagModel[];
  private _urlImage?: googleProtobuf000.StringValue;
  private _iconClass?: googleProtobuf000.StringValue;

  private _optional: FundingModel.OptionalCase = FundingModel.OptionalCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FundingModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FundingModel.AsObject>) {
    _value = _value || {};
    this.description = _value.description;
    this.hasPaymentInformation = _value.hasPaymentInformation;
    this.paymentInformations = _value.paymentInformations;
    this.lastShareValue = _value.lastShareValue
      ? new FundingShareValueModel(_value.lastShareValue)
      : undefined;
    this.uuid = _value.uuid;
    this.mnemonic = _value.mnemonic;
    this.isBox = _value.isBox;
    this.cost = _value.cost;
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.informativeBrochureId = _value.informativeBrochureId
      ? new googleProtobuf000.Int32Value(_value.informativeBrochureId)
      : undefined;
    this.title = _value.title;
    this.shareValues = (_value.shareValues || []).map(
      m => new FundingShareValueModel(m)
    );
    this.mname = _value.mname;
    this.features = (_value.features || []).slice();
    this.hasSharedValue = _value.hasSharedValue;
    this.currencyId = _value.currencyId;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.fundingCompositions = (_value.fundingCompositions || []).map(
      m => new FundingCompositionModel(m)
    );
    this.customPortfolioEnabled = _value.customPortfolioEnabled;
    this.shortDescription = _value.shortDescription;
    this.featuresjson = (_value.featuresjson || []).slice();
    this.yahoomnemonic = _value.yahoomnemonic;
    this.cmfurl = _value.cmfurl;
    this.showInComparator = _value.showInComparator;
    this.brokerexchange = _value.brokerexchange;
    this.showInEconomicIndicator = _value.showInEconomicIndicator;
    this.showInEconomicIndicatorCurrencyId =
      _value.showInEconomicIndicatorCurrencyId;
    this.showInEconomicIndicatorOrder = _value.showInEconomicIndicatorOrder;
    this.showInEconomicIndicatorCurrency = _value.showInEconomicIndicatorCurrency
      ? new CurrencyModel(_value.showInEconomicIndicatorCurrency)
      : undefined;
    this.settlementForm = _value.settlementForm;
    this.bpComission = _value.bpComission;
    this.hasBenefit107 = _value.hasBenefit107;
    this.additionalDescription = _value.additionalDescription;
    this.isDefaultCustomFund = _value.isDefaultCustomFund;
    this.paymentInformation = _value.paymentInformation
      ? new FundingModel.PaymentInformation(_value.paymentInformation)
      : undefined;
    this.withHoldingTax100 = _value.withHoldingTax100;
    this.informativeBrochureUrl = _value.informativeBrochureUrl;
    this.cmfSerie = _value.cmfSerie;
    this.isSemiLiquid = _value.isSemiLiquid;
    this.priceMargin = _value.priceMargin;
    this.hasCashback = _value.hasCashback;
    this.fundingTags = (_value.fundingTags || []).map(
      m => new FundingTagModel(m)
    );
    this.urlImage = _value.urlImage
      ? new googleProtobuf000.StringValue(_value.urlImage)
      : undefined;
    this.iconClass = _value.iconClass
      ? new googleProtobuf000.StringValue(_value.iconClass)
      : undefined;
    FundingModel.refineValues(this);
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get hasPaymentInformation(): boolean {
    return this._hasPaymentInformation;
  }
  set hasPaymentInformation(value: boolean) {
    this._hasPaymentInformation = value;
  }
  get paymentInformations(): string {
    return this._paymentInformations;
  }
  set paymentInformations(value: string) {
    if (value !== undefined && value !== null) {
      this._lastShareValue = undefined;
      this._optional = FundingModel.OptionalCase.paymentInformations;
    }
    this._paymentInformations = value;
  }
  get lastShareValue(): FundingShareValueModel | undefined {
    return this._lastShareValue;
  }
  set lastShareValue(value: FundingShareValueModel | undefined) {
    if (value !== undefined && value !== null) {
      this._paymentInformations = undefined;
      this._optional = FundingModel.OptionalCase.lastShareValue;
    }
    this._lastShareValue = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get mnemonic(): string {
    return this._mnemonic;
  }
  set mnemonic(value: string) {
    this._mnemonic = value;
  }
  get isBox(): boolean {
    return this._isBox;
  }
  set isBox(value: boolean) {
    this._isBox = value;
  }
  get cost(): number {
    return this._cost;
  }
  set cost(value: number) {
    this._cost = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get informativeBrochureId(): googleProtobuf000.Int32Value | undefined {
    return this._informativeBrochureId;
  }
  set informativeBrochureId(value: googleProtobuf000.Int32Value | undefined) {
    this._informativeBrochureId = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get shareValues(): FundingShareValueModel[] | undefined {
    return this._shareValues;
  }
  set shareValues(value: FundingShareValueModel[] | undefined) {
    this._shareValues = value;
  }
  get mname(): string {
    return this._mname;
  }
  set mname(value: string) {
    this._mname = value;
  }
  get features(): string[] {
    return this._features;
  }
  set features(value: string[]) {
    this._features = value;
  }
  get hasSharedValue(): boolean {
    return this._hasSharedValue;
  }
  set hasSharedValue(value: boolean) {
    this._hasSharedValue = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get fundingCompositions(): FundingCompositionModel[] | undefined {
    return this._fundingCompositions;
  }
  set fundingCompositions(value: FundingCompositionModel[] | undefined) {
    this._fundingCompositions = value;
  }
  get customPortfolioEnabled(): boolean {
    return this._customPortfolioEnabled;
  }
  set customPortfolioEnabled(value: boolean) {
    this._customPortfolioEnabled = value;
  }
  get shortDescription(): string {
    return this._shortDescription;
  }
  set shortDescription(value: string) {
    this._shortDescription = value;
  }
  get featuresjson(): string[] {
    return this._featuresjson;
  }
  set featuresjson(value: string[]) {
    this._featuresjson = value;
  }
  get yahoomnemonic(): string {
    return this._yahoomnemonic;
  }
  set yahoomnemonic(value: string) {
    this._yahoomnemonic = value;
  }
  get cmfurl(): string {
    return this._cmfurl;
  }
  set cmfurl(value: string) {
    this._cmfurl = value;
  }
  get showInComparator(): boolean {
    return this._showInComparator;
  }
  set showInComparator(value: boolean) {
    this._showInComparator = value;
  }
  get brokerexchange(): string {
    return this._brokerexchange;
  }
  set brokerexchange(value: string) {
    this._brokerexchange = value;
  }
  get showInEconomicIndicator(): boolean {
    return this._showInEconomicIndicator;
  }
  set showInEconomicIndicator(value: boolean) {
    this._showInEconomicIndicator = value;
  }
  get showInEconomicIndicatorCurrencyId(): number {
    return this._showInEconomicIndicatorCurrencyId;
  }
  set showInEconomicIndicatorCurrencyId(value: number) {
    this._showInEconomicIndicatorCurrencyId = value;
  }
  get showInEconomicIndicatorOrder(): number {
    return this._showInEconomicIndicatorOrder;
  }
  set showInEconomicIndicatorOrder(value: number) {
    this._showInEconomicIndicatorOrder = value;
  }
  get showInEconomicIndicatorCurrency(): CurrencyModel | undefined {
    return this._showInEconomicIndicatorCurrency;
  }
  set showInEconomicIndicatorCurrency(value: CurrencyModel | undefined) {
    this._showInEconomicIndicatorCurrency = value;
  }
  get settlementForm(): string {
    return this._settlementForm;
  }
  set settlementForm(value: string) {
    this._settlementForm = value;
  }
  get bpComission(): number {
    return this._bpComission;
  }
  set bpComission(value: number) {
    this._bpComission = value;
  }
  get hasBenefit107(): boolean {
    return this._hasBenefit107;
  }
  set hasBenefit107(value: boolean) {
    this._hasBenefit107 = value;
  }
  get additionalDescription(): string {
    return this._additionalDescription;
  }
  set additionalDescription(value: string) {
    this._additionalDescription = value;
  }
  get isDefaultCustomFund(): boolean {
    return this._isDefaultCustomFund;
  }
  set isDefaultCustomFund(value: boolean) {
    this._isDefaultCustomFund = value;
  }
  get paymentInformation(): FundingModel.PaymentInformation | undefined {
    return this._paymentInformation;
  }
  set paymentInformation(value: FundingModel.PaymentInformation | undefined) {
    this._paymentInformation = value;
  }
  get withHoldingTax100(): boolean {
    return this._withHoldingTax100;
  }
  set withHoldingTax100(value: boolean) {
    this._withHoldingTax100 = value;
  }
  get informativeBrochureUrl(): string {
    return this._informativeBrochureUrl;
  }
  set informativeBrochureUrl(value: string) {
    this._informativeBrochureUrl = value;
  }
  get cmfSerie(): string {
    return this._cmfSerie;
  }
  set cmfSerie(value: string) {
    this._cmfSerie = value;
  }
  get isSemiLiquid(): boolean {
    return this._isSemiLiquid;
  }
  set isSemiLiquid(value: boolean) {
    this._isSemiLiquid = value;
  }
  get priceMargin(): number {
    return this._priceMargin;
  }
  set priceMargin(value: number) {
    this._priceMargin = value;
  }
  get hasCashback(): boolean {
    return this._hasCashback;
  }
  set hasCashback(value: boolean) {
    this._hasCashback = value;
  }
  get fundingTags(): FundingTagModel[] | undefined {
    return this._fundingTags;
  }
  set fundingTags(value: FundingTagModel[] | undefined) {
    this._fundingTags = value;
  }
  get urlImage(): googleProtobuf000.StringValue | undefined {
    return this._urlImage;
  }
  set urlImage(value: googleProtobuf000.StringValue | undefined) {
    this._urlImage = value;
  }
  get iconClass(): googleProtobuf000.StringValue | undefined {
    return this._iconClass;
  }
  set iconClass(value: googleProtobuf000.StringValue | undefined) {
    this._iconClass = value;
  }
  get optional() {
    return this._optional;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FundingModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FundingModel.AsObject {
    return {
      description: this.description,
      hasPaymentInformation: this.hasPaymentInformation,
      paymentInformations: this.paymentInformations,
      lastShareValue: this.lastShareValue
        ? this.lastShareValue.toObject()
        : undefined,
      uuid: this.uuid,
      mnemonic: this.mnemonic,
      isBox: this.isBox,
      cost: this.cost,
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      informativeBrochureId: this.informativeBrochureId
        ? this.informativeBrochureId.toObject()
        : undefined,
      title: this.title,
      shareValues: (this.shareValues || []).map(m => m.toObject()),
      mname: this.mname,
      features: (this.features || []).slice(),
      hasSharedValue: this.hasSharedValue,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toObject() : undefined,
      fundingCompositions: (this.fundingCompositions || []).map(m =>
        m.toObject()
      ),
      customPortfolioEnabled: this.customPortfolioEnabled,
      shortDescription: this.shortDescription,
      featuresjson: (this.featuresjson || []).slice(),
      yahoomnemonic: this.yahoomnemonic,
      cmfurl: this.cmfurl,
      showInComparator: this.showInComparator,
      brokerexchange: this.brokerexchange,
      showInEconomicIndicator: this.showInEconomicIndicator,
      showInEconomicIndicatorCurrencyId: this.showInEconomicIndicatorCurrencyId,
      showInEconomicIndicatorOrder: this.showInEconomicIndicatorOrder,
      showInEconomicIndicatorCurrency: this.showInEconomicIndicatorCurrency
        ? this.showInEconomicIndicatorCurrency.toObject()
        : undefined,
      settlementForm: this.settlementForm,
      bpComission: this.bpComission,
      hasBenefit107: this.hasBenefit107,
      additionalDescription: this.additionalDescription,
      isDefaultCustomFund: this.isDefaultCustomFund,
      paymentInformation: this.paymentInformation
        ? this.paymentInformation.toObject()
        : undefined,
      withHoldingTax100: this.withHoldingTax100,
      informativeBrochureUrl: this.informativeBrochureUrl,
      cmfSerie: this.cmfSerie,
      isSemiLiquid: this.isSemiLiquid,
      priceMargin: this.priceMargin,
      hasCashback: this.hasCashback,
      fundingTags: (this.fundingTags || []).map(m => m.toObject()),
      urlImage: this.urlImage ? this.urlImage.toObject() : undefined,
      iconClass: this.iconClass ? this.iconClass.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FundingModel.AsProtobufJSON {
    return {
      description: this.description,
      hasPaymentInformation: this.hasPaymentInformation,
      paymentInformations:
        this.paymentInformations === null ||
        this.paymentInformations === undefined
          ? null
          : this.paymentInformations,
      lastShareValue: this.lastShareValue
        ? this.lastShareValue.toProtobufJSON(options)
        : null,
      uuid: this.uuid,
      mnemonic: this.mnemonic,
      isBox: this.isBox,
      cost: this.cost,
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      informativeBrochureId: this.informativeBrochureId
        ? this.informativeBrochureId.toProtobufJSON(options)
        : null,
      title: this.title,
      shareValues: (this.shareValues || []).map(m => m.toProtobufJSON(options)),
      mname: this.mname,
      features: (this.features || []).slice(),
      hasSharedValue: this.hasSharedValue,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      fundingCompositions: (this.fundingCompositions || []).map(m =>
        m.toProtobufJSON(options)
      ),
      customPortfolioEnabled: this.customPortfolioEnabled,
      shortDescription: this.shortDescription,
      featuresjson: (this.featuresjson || []).slice(),
      yahoomnemonic: this.yahoomnemonic,
      cmfurl: this.cmfurl,
      showInComparator: this.showInComparator,
      brokerexchange: this.brokerexchange,
      showInEconomicIndicator: this.showInEconomicIndicator,
      showInEconomicIndicatorCurrencyId: this.showInEconomicIndicatorCurrencyId,
      showInEconomicIndicatorOrder: this.showInEconomicIndicatorOrder,
      showInEconomicIndicatorCurrency: this.showInEconomicIndicatorCurrency
        ? this.showInEconomicIndicatorCurrency.toProtobufJSON(options)
        : null,
      settlementForm: this.settlementForm,
      bpComission: this.bpComission,
      hasBenefit107: this.hasBenefit107,
      additionalDescription: this.additionalDescription,
      isDefaultCustomFund: this.isDefaultCustomFund,
      paymentInformation: this.paymentInformation
        ? this.paymentInformation.toProtobufJSON(options)
        : null,
      withHoldingTax100: this.withHoldingTax100,
      informativeBrochureUrl: this.informativeBrochureUrl,
      cmfSerie: this.cmfSerie,
      isSemiLiquid: this.isSemiLiquid,
      priceMargin: this.priceMargin,
      hasCashback: this.hasCashback,
      fundingTags: (this.fundingTags || []).map(m => m.toProtobufJSON(options)),
      urlImage: this.urlImage ? this.urlImage.toProtobufJSON(options) : null,
      iconClass: this.iconClass ? this.iconClass.toProtobufJSON(options) : null
    };
  }
}
export module FundingModel {
  /**
   * Standard JavaScript object representation for FundingModel
   */
  export interface AsObject {
    description: string;
    hasPaymentInformation: boolean;
    paymentInformations: string;
    lastShareValue?: FundingShareValueModel.AsObject;
    uuid: string;
    mnemonic: string;
    isBox: boolean;
    cost: number;
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    informativeBrochureId?: googleProtobuf000.Int32Value.AsObject;
    title: string;
    shareValues?: FundingShareValueModel.AsObject[];
    mname: string;
    features: string[];
    hasSharedValue: boolean;
    currencyId: number;
    currency?: CurrencyModel.AsObject;
    fundingCompositions?: FundingCompositionModel.AsObject[];
    customPortfolioEnabled: boolean;
    shortDescription: string;
    featuresjson: string[];
    yahoomnemonic: string;
    cmfurl: string;
    showInComparator: boolean;
    brokerexchange: string;
    showInEconomicIndicator: boolean;
    showInEconomicIndicatorCurrencyId: number;
    showInEconomicIndicatorOrder: number;
    showInEconomicIndicatorCurrency?: CurrencyModel.AsObject;
    settlementForm: string;
    bpComission: number;
    hasBenefit107: boolean;
    additionalDescription: string;
    isDefaultCustomFund: boolean;
    paymentInformation?: FundingModel.PaymentInformation.AsObject;
    withHoldingTax100: boolean;
    informativeBrochureUrl: string;
    cmfSerie: string;
    isSemiLiquid: boolean;
    priceMargin: number;
    hasCashback: boolean;
    fundingTags?: FundingTagModel.AsObject[];
    urlImage?: googleProtobuf000.StringValue.AsObject;
    iconClass?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for FundingModel
   */
  export interface AsProtobufJSON {
    description: string;
    hasPaymentInformation: boolean;
    paymentInformations: string | null;
    lastShareValue: FundingShareValueModel.AsProtobufJSON | null;
    uuid: string;
    mnemonic: string;
    isBox: boolean;
    cost: number;
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    informativeBrochureId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    title: string;
    shareValues: FundingShareValueModel.AsProtobufJSON[] | null;
    mname: string;
    features: string[];
    hasSharedValue: boolean;
    currencyId: number;
    currency: CurrencyModel.AsProtobufJSON | null;
    fundingCompositions: FundingCompositionModel.AsProtobufJSON[] | null;
    customPortfolioEnabled: boolean;
    shortDescription: string;
    featuresjson: string[];
    yahoomnemonic: string;
    cmfurl: string;
    showInComparator: boolean;
    brokerexchange: string;
    showInEconomicIndicator: boolean;
    showInEconomicIndicatorCurrencyId: number;
    showInEconomicIndicatorOrder: number;
    showInEconomicIndicatorCurrency: CurrencyModel.AsProtobufJSON | null;
    settlementForm: string;
    bpComission: number;
    hasBenefit107: boolean;
    additionalDescription: string;
    isDefaultCustomFund: boolean;
    paymentInformation: FundingModel.PaymentInformation.AsProtobufJSON | null;
    withHoldingTax100: boolean;
    informativeBrochureUrl: string;
    cmfSerie: string;
    isSemiLiquid: boolean;
    priceMargin: number;
    hasCashback: boolean;
    fundingTags: FundingTagModel.AsProtobufJSON[] | null;
    urlImage: googleProtobuf000.StringValue.AsProtobufJSON | null;
    iconClass: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
  export enum OptionalCase {
    none = 0,
    paymentInformations = 1,
    lastShareValue = 2
  }
  /**
   * Message implementation for common_message.FundingModel.PaymentInformation
   */
  export class PaymentInformation implements GrpcMessage {
    static id = 'common_message.FundingModel.PaymentInformation';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new PaymentInformation();
      PaymentInformation.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: PaymentInformation) {
      _instance.rut = _instance.rut || '';
      _instance.bank = _instance.bank || '';
      _instance.type = _instance.type || '';
      _instance.number = _instance.number || '';
      _instance.email = _instance.email || '';
      _instance.name = _instance.name || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: PaymentInformation,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.rut = _reader.readString();
            break;
          case 2:
            _instance.bank = _reader.readString();
            break;
          case 3:
            _instance.type = _reader.readString();
            break;
          case 4:
            _instance.number = _reader.readString();
            break;
          case 5:
            _instance.email = _reader.readString();
            break;
          case 6:
            _instance.name = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      PaymentInformation.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: PaymentInformation,
      _writer: BinaryWriter
    ) {
      if (_instance.rut) {
        _writer.writeString(1, _instance.rut);
      }
      if (_instance.bank) {
        _writer.writeString(2, _instance.bank);
      }
      if (_instance.type) {
        _writer.writeString(3, _instance.type);
      }
      if (_instance.number) {
        _writer.writeString(4, _instance.number);
      }
      if (_instance.email) {
        _writer.writeString(5, _instance.email);
      }
      if (_instance.name) {
        _writer.writeString(6, _instance.name);
      }
    }

    private _rut: string;
    private _bank: string;
    private _type: string;
    private _number: string;
    private _email: string;
    private _name: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of PaymentInformation to deeply clone from
     */
    constructor(_value?: RecursivePartial<PaymentInformation.AsObject>) {
      _value = _value || {};
      this.rut = _value.rut;
      this.bank = _value.bank;
      this.type = _value.type;
      this.number = _value.number;
      this.email = _value.email;
      this.name = _value.name;
      PaymentInformation.refineValues(this);
    }
    get rut(): string {
      return this._rut;
    }
    set rut(value: string) {
      this._rut = value;
    }
    get bank(): string {
      return this._bank;
    }
    set bank(value: string) {
      this._bank = value;
    }
    get type(): string {
      return this._type;
    }
    set type(value: string) {
      this._type = value;
    }
    get number(): string {
      return this._number;
    }
    set number(value: string) {
      this._number = value;
    }
    get email(): string {
      return this._email;
    }
    set email(value: string) {
      this._email = value;
    }
    get name(): string {
      return this._name;
    }
    set name(value: string) {
      this._name = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      PaymentInformation.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): PaymentInformation.AsObject {
      return {
        rut: this.rut,
        bank: this.bank,
        type: this.type,
        number: this.number,
        email: this.email,
        name: this.name
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): PaymentInformation.AsProtobufJSON {
      return {
        rut: this.rut,
        bank: this.bank,
        type: this.type,
        number: this.number,
        email: this.email,
        name: this.name
      };
    }
  }
  export module PaymentInformation {
    /**
     * Standard JavaScript object representation for PaymentInformation
     */
    export interface AsObject {
      rut: string;
      bank: string;
      type: string;
      number: string;
      email: string;
      name: string;
    }

    /**
     * Protobuf JSON representation for PaymentInformation
     */
    export interface AsProtobufJSON {
      rut: string;
      bank: string;
      type: string;
      number: string;
      email: string;
      name: string;
    }
  }
}

/**
 * Message implementation for common_message.TagModel
 */
export class TagModel implements GrpcMessage {
  static id = 'common_message.TagModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TagModel();
    TagModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TagModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.name = _instance.name || undefined;
    _instance.shortName = _instance.shortName || undefined;
    _instance.description = _instance.description || undefined;
    _instance.shortDescription = _instance.shortDescription || undefined;
    _instance.urlImage = _instance.urlImage || undefined;
    _instance.iconClass = _instance.iconClass || undefined;
    _instance.color = _instance.color || undefined;
    _instance.tooltipTitle = _instance.tooltipTitle || undefined;
    _instance.tooltipText = _instance.tooltipText || undefined;
    _instance.isActive = _instance.isActive || false;
    _instance.code = _instance.code || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TagModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.name = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.name,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.shortName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.shortName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.description = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.description,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.shortDescription = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.shortDescription,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.urlImage = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.urlImage,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.iconClass = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.iconClass,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.color = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.color,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.tooltipTitle = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.tooltipTitle,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.tooltipText = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.tooltipText,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.isActive = _reader.readBool();
          break;
        case 14:
          _instance.code = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.code,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TagModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TagModel, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeMessage(
        4,
        _instance.name as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.shortName) {
      _writer.writeMessage(
        5,
        _instance.shortName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeMessage(
        6,
        _instance.description as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.shortDescription) {
      _writer.writeMessage(
        7,
        _instance.shortDescription as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.urlImage) {
      _writer.writeMessage(
        8,
        _instance.urlImage as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.iconClass) {
      _writer.writeMessage(
        9,
        _instance.iconClass as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.color) {
      _writer.writeMessage(
        10,
        _instance.color as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.tooltipTitle) {
      _writer.writeMessage(
        11,
        _instance.tooltipTitle as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.tooltipText) {
      _writer.writeMessage(
        12,
        _instance.tooltipText as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.isActive) {
      _writer.writeBool(13, _instance.isActive);
    }
    if (_instance.code) {
      _writer.writeMessage(
        14,
        _instance.code as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _name?: googleProtobuf000.StringValue;
  private _shortName?: googleProtobuf000.StringValue;
  private _description?: googleProtobuf000.StringValue;
  private _shortDescription?: googleProtobuf000.StringValue;
  private _urlImage?: googleProtobuf000.StringValue;
  private _iconClass?: googleProtobuf000.StringValue;
  private _color?: googleProtobuf000.StringValue;
  private _tooltipTitle?: googleProtobuf000.StringValue;
  private _tooltipText?: googleProtobuf000.StringValue;
  private _isActive: boolean;
  private _code?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TagModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<TagModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.name = _value.name
      ? new googleProtobuf000.StringValue(_value.name)
      : undefined;
    this.shortName = _value.shortName
      ? new googleProtobuf000.StringValue(_value.shortName)
      : undefined;
    this.description = _value.description
      ? new googleProtobuf000.StringValue(_value.description)
      : undefined;
    this.shortDescription = _value.shortDescription
      ? new googleProtobuf000.StringValue(_value.shortDescription)
      : undefined;
    this.urlImage = _value.urlImage
      ? new googleProtobuf000.StringValue(_value.urlImage)
      : undefined;
    this.iconClass = _value.iconClass
      ? new googleProtobuf000.StringValue(_value.iconClass)
      : undefined;
    this.color = _value.color
      ? new googleProtobuf000.StringValue(_value.color)
      : undefined;
    this.tooltipTitle = _value.tooltipTitle
      ? new googleProtobuf000.StringValue(_value.tooltipTitle)
      : undefined;
    this.tooltipText = _value.tooltipText
      ? new googleProtobuf000.StringValue(_value.tooltipText)
      : undefined;
    this.isActive = _value.isActive;
    this.code = _value.code
      ? new googleProtobuf000.StringValue(_value.code)
      : undefined;
    TagModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get name(): googleProtobuf000.StringValue | undefined {
    return this._name;
  }
  set name(value: googleProtobuf000.StringValue | undefined) {
    this._name = value;
  }
  get shortName(): googleProtobuf000.StringValue | undefined {
    return this._shortName;
  }
  set shortName(value: googleProtobuf000.StringValue | undefined) {
    this._shortName = value;
  }
  get description(): googleProtobuf000.StringValue | undefined {
    return this._description;
  }
  set description(value: googleProtobuf000.StringValue | undefined) {
    this._description = value;
  }
  get shortDescription(): googleProtobuf000.StringValue | undefined {
    return this._shortDescription;
  }
  set shortDescription(value: googleProtobuf000.StringValue | undefined) {
    this._shortDescription = value;
  }
  get urlImage(): googleProtobuf000.StringValue | undefined {
    return this._urlImage;
  }
  set urlImage(value: googleProtobuf000.StringValue | undefined) {
    this._urlImage = value;
  }
  get iconClass(): googleProtobuf000.StringValue | undefined {
    return this._iconClass;
  }
  set iconClass(value: googleProtobuf000.StringValue | undefined) {
    this._iconClass = value;
  }
  get color(): googleProtobuf000.StringValue | undefined {
    return this._color;
  }
  set color(value: googleProtobuf000.StringValue | undefined) {
    this._color = value;
  }
  get tooltipTitle(): googleProtobuf000.StringValue | undefined {
    return this._tooltipTitle;
  }
  set tooltipTitle(value: googleProtobuf000.StringValue | undefined) {
    this._tooltipTitle = value;
  }
  get tooltipText(): googleProtobuf000.StringValue | undefined {
    return this._tooltipText;
  }
  set tooltipText(value: googleProtobuf000.StringValue | undefined) {
    this._tooltipText = value;
  }
  get isActive(): boolean {
    return this._isActive;
  }
  set isActive(value: boolean) {
    this._isActive = value;
  }
  get code(): googleProtobuf000.StringValue | undefined {
    return this._code;
  }
  set code(value: googleProtobuf000.StringValue | undefined) {
    this._code = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TagModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TagModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      name: this.name ? this.name.toObject() : undefined,
      shortName: this.shortName ? this.shortName.toObject() : undefined,
      description: this.description ? this.description.toObject() : undefined,
      shortDescription: this.shortDescription
        ? this.shortDescription.toObject()
        : undefined,
      urlImage: this.urlImage ? this.urlImage.toObject() : undefined,
      iconClass: this.iconClass ? this.iconClass.toObject() : undefined,
      color: this.color ? this.color.toObject() : undefined,
      tooltipTitle: this.tooltipTitle
        ? this.tooltipTitle.toObject()
        : undefined,
      tooltipText: this.tooltipText ? this.tooltipText.toObject() : undefined,
      isActive: this.isActive,
      code: this.code ? this.code.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TagModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      name: this.name ? this.name.toProtobufJSON(options) : null,
      shortName: this.shortName ? this.shortName.toProtobufJSON(options) : null,
      description: this.description
        ? this.description.toProtobufJSON(options)
        : null,
      shortDescription: this.shortDescription
        ? this.shortDescription.toProtobufJSON(options)
        : null,
      urlImage: this.urlImage ? this.urlImage.toProtobufJSON(options) : null,
      iconClass: this.iconClass ? this.iconClass.toProtobufJSON(options) : null,
      color: this.color ? this.color.toProtobufJSON(options) : null,
      tooltipTitle: this.tooltipTitle
        ? this.tooltipTitle.toProtobufJSON(options)
        : null,
      tooltipText: this.tooltipText
        ? this.tooltipText.toProtobufJSON(options)
        : null,
      isActive: this.isActive,
      code: this.code ? this.code.toProtobufJSON(options) : null
    };
  }
}
export module TagModel {
  /**
   * Standard JavaScript object representation for TagModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    name?: googleProtobuf000.StringValue.AsObject;
    shortName?: googleProtobuf000.StringValue.AsObject;
    description?: googleProtobuf000.StringValue.AsObject;
    shortDescription?: googleProtobuf000.StringValue.AsObject;
    urlImage?: googleProtobuf000.StringValue.AsObject;
    iconClass?: googleProtobuf000.StringValue.AsObject;
    color?: googleProtobuf000.StringValue.AsObject;
    tooltipTitle?: googleProtobuf000.StringValue.AsObject;
    tooltipText?: googleProtobuf000.StringValue.AsObject;
    isActive: boolean;
    code?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for TagModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    name: googleProtobuf000.StringValue.AsProtobufJSON | null;
    shortName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    description: googleProtobuf000.StringValue.AsProtobufJSON | null;
    shortDescription: googleProtobuf000.StringValue.AsProtobufJSON | null;
    urlImage: googleProtobuf000.StringValue.AsProtobufJSON | null;
    iconClass: googleProtobuf000.StringValue.AsProtobufJSON | null;
    color: googleProtobuf000.StringValue.AsProtobufJSON | null;
    tooltipTitle: googleProtobuf000.StringValue.AsProtobufJSON | null;
    tooltipText: googleProtobuf000.StringValue.AsProtobufJSON | null;
    isActive: boolean;
    code: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.FundingTagModel
 */
export class FundingTagModel implements GrpcMessage {
  static id = 'common_message.FundingTagModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FundingTagModel();
    FundingTagModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FundingTagModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.fundingId = _instance.fundingId || undefined;
    _instance.tagId = _instance.tagId || undefined;
    _instance.funding = _instance.funding || undefined;
    _instance.tag = _instance.tag || undefined;
    _instance.order = _instance.order || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FundingTagModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.fundingId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.fundingId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.tagId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.tagId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.tag = new TagModel();
          _reader.readMessage(
            _instance.tag,
            TagModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.order = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    FundingTagModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FundingTagModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.fundingId) {
      _writer.writeMessage(
        4,
        _instance.fundingId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.tagId) {
      _writer.writeMessage(
        5,
        _instance.tagId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.funding) {
      _writer.writeMessage(
        6,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.tag) {
      _writer.writeMessage(
        7,
        _instance.tag as any,
        TagModel.serializeBinaryToWriter
      );
    }
    if (_instance.order) {
      _writer.writeInt32(8, _instance.order);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _fundingId?: googleProtobuf000.Int32Value;
  private _tagId?: googleProtobuf000.Int32Value;
  private _funding?: FundingModel;
  private _tag?: TagModel;
  private _order: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FundingTagModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FundingTagModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.fundingId = _value.fundingId
      ? new googleProtobuf000.Int32Value(_value.fundingId)
      : undefined;
    this.tagId = _value.tagId
      ? new googleProtobuf000.Int32Value(_value.tagId)
      : undefined;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.tag = _value.tag ? new TagModel(_value.tag) : undefined;
    this.order = _value.order;
    FundingTagModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get fundingId(): googleProtobuf000.Int32Value | undefined {
    return this._fundingId;
  }
  set fundingId(value: googleProtobuf000.Int32Value | undefined) {
    this._fundingId = value;
  }
  get tagId(): googleProtobuf000.Int32Value | undefined {
    return this._tagId;
  }
  set tagId(value: googleProtobuf000.Int32Value | undefined) {
    this._tagId = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get tag(): TagModel | undefined {
    return this._tag;
  }
  set tag(value: TagModel | undefined) {
    this._tag = value;
  }
  get order(): number {
    return this._order;
  }
  set order(value: number) {
    this._order = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FundingTagModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FundingTagModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      fundingId: this.fundingId ? this.fundingId.toObject() : undefined,
      tagId: this.tagId ? this.tagId.toObject() : undefined,
      funding: this.funding ? this.funding.toObject() : undefined,
      tag: this.tag ? this.tag.toObject() : undefined,
      order: this.order
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FundingTagModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      fundingId: this.fundingId ? this.fundingId.toProtobufJSON(options) : null,
      tagId: this.tagId ? this.tagId.toProtobufJSON(options) : null,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      tag: this.tag ? this.tag.toProtobufJSON(options) : null,
      order: this.order
    };
  }
}
export module FundingTagModel {
  /**
   * Standard JavaScript object representation for FundingTagModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    fundingId?: googleProtobuf000.Int32Value.AsObject;
    tagId?: googleProtobuf000.Int32Value.AsObject;
    funding?: FundingModel.AsObject;
    tag?: TagModel.AsObject;
    order: number;
  }

  /**
   * Protobuf JSON representation for FundingTagModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    fundingId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    tagId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    funding: FundingModel.AsProtobufJSON | null;
    tag: TagModel.AsProtobufJSON | null;
    order: number;
  }
}

/**
 * Message implementation for common_message.CompositionCategoryModel
 */
export class CompositionCategoryModel implements GrpcMessage {
  static id = 'common_message.CompositionCategoryModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompositionCategoryModel();
    CompositionCategoryModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompositionCategoryModel) {
    _instance.name = _instance.name || '';
    _instance.uuid = _instance.uuid || '';
    _instance.description = _instance.description || undefined;
    _instance.groupDisplayUuid = _instance.groupDisplayUuid || '';
    _instance.isDisplayedInGroupFunding =
      _instance.isDisplayedInGroupFunding || false;
    _instance.id = _instance.id || 0;
    _instance.groupCategory = _instance.groupCategory || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompositionCategoryModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.uuid = _reader.readString();
          break;
        case 3:
          _instance.description = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.description,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.groupDisplayUuid = _reader.readString();
          break;
        case 5:
          _instance.isDisplayedInGroupFunding = _reader.readBool();
          break;
        case 6:
          _instance.id = _reader.readInt32();
          break;
        case 7:
          _instance.groupCategory = new GroupCategoryModel();
          _reader.readMessage(
            _instance.groupCategory,
            GroupCategoryModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CompositionCategoryModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompositionCategoryModel,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.uuid) {
      _writer.writeString(2, _instance.uuid);
    }
    if (_instance.description) {
      _writer.writeMessage(
        3,
        _instance.description as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.groupDisplayUuid) {
      _writer.writeString(4, _instance.groupDisplayUuid);
    }
    if (_instance.isDisplayedInGroupFunding) {
      _writer.writeBool(5, _instance.isDisplayedInGroupFunding);
    }
    if (_instance.id) {
      _writer.writeInt32(6, _instance.id);
    }
    if (_instance.groupCategory) {
      _writer.writeMessage(
        7,
        _instance.groupCategory as any,
        GroupCategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        8,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        9,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _uuid: string;
  private _description?: googleProtobuf000.StringValue;
  private _groupDisplayUuid: string;
  private _isDisplayedInGroupFunding: boolean;
  private _id: number;
  private _groupCategory?: GroupCategoryModel;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompositionCategoryModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<CompositionCategoryModel.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.uuid = _value.uuid;
    this.description = _value.description
      ? new googleProtobuf000.StringValue(_value.description)
      : undefined;
    this.groupDisplayUuid = _value.groupDisplayUuid;
    this.isDisplayedInGroupFunding = _value.isDisplayedInGroupFunding;
    this.id = _value.id;
    this.groupCategory = _value.groupCategory
      ? new GroupCategoryModel(_value.groupCategory)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    CompositionCategoryModel.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get description(): googleProtobuf000.StringValue | undefined {
    return this._description;
  }
  set description(value: googleProtobuf000.StringValue | undefined) {
    this._description = value;
  }
  get groupDisplayUuid(): string {
    return this._groupDisplayUuid;
  }
  set groupDisplayUuid(value: string) {
    this._groupDisplayUuid = value;
  }
  get isDisplayedInGroupFunding(): boolean {
    return this._isDisplayedInGroupFunding;
  }
  set isDisplayedInGroupFunding(value: boolean) {
    this._isDisplayedInGroupFunding = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get groupCategory(): GroupCategoryModel | undefined {
    return this._groupCategory;
  }
  set groupCategory(value: GroupCategoryModel | undefined) {
    this._groupCategory = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompositionCategoryModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompositionCategoryModel.AsObject {
    return {
      name: this.name,
      uuid: this.uuid,
      description: this.description ? this.description.toObject() : undefined,
      groupDisplayUuid: this.groupDisplayUuid,
      isDisplayedInGroupFunding: this.isDisplayedInGroupFunding,
      id: this.id,
      groupCategory: this.groupCategory
        ? this.groupCategory.toObject()
        : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompositionCategoryModel.AsProtobufJSON {
    return {
      name: this.name,
      uuid: this.uuid,
      description: this.description
        ? this.description.toProtobufJSON(options)
        : null,
      groupDisplayUuid: this.groupDisplayUuid,
      isDisplayedInGroupFunding: this.isDisplayedInGroupFunding,
      id: this.id,
      groupCategory: this.groupCategory
        ? this.groupCategory.toProtobufJSON(options)
        : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module CompositionCategoryModel {
  /**
   * Standard JavaScript object representation for CompositionCategoryModel
   */
  export interface AsObject {
    name: string;
    uuid: string;
    description?: googleProtobuf000.StringValue.AsObject;
    groupDisplayUuid: string;
    isDisplayedInGroupFunding: boolean;
    id: number;
    groupCategory?: GroupCategoryModel.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for CompositionCategoryModel
   */
  export interface AsProtobufJSON {
    name: string;
    uuid: string;
    description: googleProtobuf000.StringValue.AsProtobufJSON | null;
    groupDisplayUuid: string;
    isDisplayedInGroupFunding: boolean;
    id: number;
    groupCategory: GroupCategoryModel.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.GroupCategoryModel
 */
export class GroupCategoryModel implements GrpcMessage {
  static id = 'common_message.GroupCategoryModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GroupCategoryModel();
    GroupCategoryModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GroupCategoryModel) {
    _instance.id = _instance.id || 0;
    _instance.title = _instance.title || '';
    _instance.uuid = _instance.uuid || '';
    _instance.description = _instance.description || undefined;
    _instance.color = _instance.color || '';
    _instance.displayOrder = _instance.displayOrder || 0;
    _instance.categories = _instance.categories || [];
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GroupCategoryModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        case 3:
          _instance.uuid = _reader.readString();
          break;
        case 4:
          _instance.description = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.description,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.color = _reader.readString();
          break;
        case 6:
          _instance.displayOrder = _reader.readInt32();
          break;
        case 7:
          const messageInitializer7 = new CompositionCategoryModel();
          _reader.readMessage(
            messageInitializer7,
            CompositionCategoryModel.deserializeBinaryFromReader
          );
          (_instance.categories = _instance.categories || []).push(
            messageInitializer7
          );
          break;
        case 8:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GroupCategoryModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GroupCategoryModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.uuid) {
      _writer.writeString(3, _instance.uuid);
    }
    if (_instance.description) {
      _writer.writeMessage(
        4,
        _instance.description as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.color) {
      _writer.writeString(5, _instance.color);
    }
    if (_instance.displayOrder) {
      _writer.writeInt32(6, _instance.displayOrder);
    }
    if (_instance.categories && _instance.categories.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.categories as any,
        CompositionCategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        8,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        9,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _title: string;
  private _uuid: string;
  private _description?: googleProtobuf000.StringValue;
  private _color: string;
  private _displayOrder: number;
  private _categories?: CompositionCategoryModel[];
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GroupCategoryModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GroupCategoryModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.title = _value.title;
    this.uuid = _value.uuid;
    this.description = _value.description
      ? new googleProtobuf000.StringValue(_value.description)
      : undefined;
    this.color = _value.color;
    this.displayOrder = _value.displayOrder;
    this.categories = (_value.categories || []).map(
      m => new CompositionCategoryModel(m)
    );
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    GroupCategoryModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get description(): googleProtobuf000.StringValue | undefined {
    return this._description;
  }
  set description(value: googleProtobuf000.StringValue | undefined) {
    this._description = value;
  }
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._color = value;
  }
  get displayOrder(): number {
    return this._displayOrder;
  }
  set displayOrder(value: number) {
    this._displayOrder = value;
  }
  get categories(): CompositionCategoryModel[] | undefined {
    return this._categories;
  }
  set categories(value: CompositionCategoryModel[] | undefined) {
    this._categories = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GroupCategoryModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GroupCategoryModel.AsObject {
    return {
      id: this.id,
      title: this.title,
      uuid: this.uuid,
      description: this.description ? this.description.toObject() : undefined,
      color: this.color,
      displayOrder: this.displayOrder,
      categories: (this.categories || []).map(m => m.toObject()),
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GroupCategoryModel.AsProtobufJSON {
    return {
      id: this.id,
      title: this.title,
      uuid: this.uuid,
      description: this.description
        ? this.description.toProtobufJSON(options)
        : null,
      color: this.color,
      displayOrder: this.displayOrder,
      categories: (this.categories || []).map(m => m.toProtobufJSON(options)),
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module GroupCategoryModel {
  /**
   * Standard JavaScript object representation for GroupCategoryModel
   */
  export interface AsObject {
    id: number;
    title: string;
    uuid: string;
    description?: googleProtobuf000.StringValue.AsObject;
    color: string;
    displayOrder: number;
    categories?: CompositionCategoryModel.AsObject[];
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GroupCategoryModel
   */
  export interface AsProtobufJSON {
    id: number;
    title: string;
    uuid: string;
    description: googleProtobuf000.StringValue.AsProtobufJSON | null;
    color: string;
    displayOrder: number;
    categories: CompositionCategoryModel.AsProtobufJSON[] | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.CompositionSubcategoryModel
 */
export class CompositionSubcategoryModel implements GrpcMessage {
  static id = 'common_message.CompositionSubcategoryModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CompositionSubcategoryModel();
    CompositionSubcategoryModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CompositionSubcategoryModel) {
    _instance.name = _instance.name || '';
    _instance.uuid = _instance.uuid || '';
    _instance.description = _instance.description || undefined;
    _instance.id = _instance.id || 0;
    _instance.categoryId = _instance.categoryId || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.category = _instance.category || undefined;
    _instance.country = _instance.country || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CompositionSubcategoryModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.uuid = _reader.readString();
          break;
        case 3:
          _instance.description = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.description,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.id = _reader.readInt32();
          break;
        case 5:
          _instance.categoryId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.categoryId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.category = new CompositionCategoryModel();
          _reader.readMessage(
            _instance.category,
            CompositionCategoryModel.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.country = new CountryModel();
          _reader.readMessage(
            _instance.country,
            CountryModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CompositionSubcategoryModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CompositionSubcategoryModel,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.uuid) {
      _writer.writeString(2, _instance.uuid);
    }
    if (_instance.description) {
      _writer.writeMessage(
        3,
        _instance.description as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(4, _instance.id);
    }
    if (_instance.categoryId) {
      _writer.writeMessage(
        5,
        _instance.categoryId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        6,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        7,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.category) {
      _writer.writeMessage(
        8,
        _instance.category as any,
        CompositionCategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.country) {
      _writer.writeMessage(
        9,
        _instance.country as any,
        CountryModel.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _uuid: string;
  private _description?: googleProtobuf000.StringValue;
  private _id: number;
  private _categoryId?: googleProtobuf000.Int32Value;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _category?: CompositionCategoryModel;
  private _country?: CountryModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CompositionSubcategoryModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<CompositionSubcategoryModel.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.uuid = _value.uuid;
    this.description = _value.description
      ? new googleProtobuf000.StringValue(_value.description)
      : undefined;
    this.id = _value.id;
    this.categoryId = _value.categoryId
      ? new googleProtobuf000.Int32Value(_value.categoryId)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.category = _value.category
      ? new CompositionCategoryModel(_value.category)
      : undefined;
    this.country = _value.country
      ? new CountryModel(_value.country)
      : undefined;
    CompositionSubcategoryModel.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get description(): googleProtobuf000.StringValue | undefined {
    return this._description;
  }
  set description(value: googleProtobuf000.StringValue | undefined) {
    this._description = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get categoryId(): googleProtobuf000.Int32Value | undefined {
    return this._categoryId;
  }
  set categoryId(value: googleProtobuf000.Int32Value | undefined) {
    this._categoryId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get category(): CompositionCategoryModel | undefined {
    return this._category;
  }
  set category(value: CompositionCategoryModel | undefined) {
    this._category = value;
  }
  get country(): CountryModel | undefined {
    return this._country;
  }
  set country(value: CountryModel | undefined) {
    this._country = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CompositionSubcategoryModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CompositionSubcategoryModel.AsObject {
    return {
      name: this.name,
      uuid: this.uuid,
      description: this.description ? this.description.toObject() : undefined,
      id: this.id,
      categoryId: this.categoryId ? this.categoryId.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      category: this.category ? this.category.toObject() : undefined,
      country: this.country ? this.country.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CompositionSubcategoryModel.AsProtobufJSON {
    return {
      name: this.name,
      uuid: this.uuid,
      description: this.description
        ? this.description.toProtobufJSON(options)
        : null,
      id: this.id,
      categoryId: this.categoryId
        ? this.categoryId.toProtobufJSON(options)
        : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      category: this.category ? this.category.toProtobufJSON(options) : null,
      country: this.country ? this.country.toProtobufJSON(options) : null
    };
  }
}
export module CompositionSubcategoryModel {
  /**
   * Standard JavaScript object representation for CompositionSubcategoryModel
   */
  export interface AsObject {
    name: string;
    uuid: string;
    description?: googleProtobuf000.StringValue.AsObject;
    id: number;
    categoryId?: googleProtobuf000.Int32Value.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    category?: CompositionCategoryModel.AsObject;
    country?: CountryModel.AsObject;
  }

  /**
   * Protobuf JSON representation for CompositionSubcategoryModel
   */
  export interface AsProtobufJSON {
    name: string;
    uuid: string;
    description: googleProtobuf000.StringValue.AsProtobufJSON | null;
    id: number;
    categoryId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    category: CompositionCategoryModel.AsProtobufJSON | null;
    country: CountryModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.FundingCompositionModel
 */
export class FundingCompositionModel implements GrpcMessage {
  static id = 'common_message.FundingCompositionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FundingCompositionModel();
    FundingCompositionModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FundingCompositionModel) {
    _instance.percentage = _instance.percentage || 0;
    _instance.id = _instance.id || 0;
    _instance.subcategoryId = _instance.subcategoryId || 0;
    _instance.fundingId = _instance.fundingId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.subcategory = _instance.subcategory || undefined;
    _instance.funding = _instance.funding || undefined;
    _instance.isPrimarySubcategory = _instance.isPrimarySubcategory || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FundingCompositionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.percentage = _reader.readDouble();
          break;
        case 2:
          _instance.id = _reader.readInt32();
          break;
        case 3:
          _instance.subcategoryId = _reader.readInt32();
          break;
        case 4:
          _instance.fundingId = _reader.readInt32();
          break;
        case 5:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.subcategory = new CompositionSubcategoryModel();
          _reader.readMessage(
            _instance.subcategory,
            CompositionSubcategoryModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.isPrimarySubcategory = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    FundingCompositionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FundingCompositionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.percentage) {
      _writer.writeDouble(1, _instance.percentage);
    }
    if (_instance.id) {
      _writer.writeInt32(2, _instance.id);
    }
    if (_instance.subcategoryId) {
      _writer.writeInt32(3, _instance.subcategoryId);
    }
    if (_instance.fundingId) {
      _writer.writeInt32(4, _instance.fundingId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        5,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        6,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.subcategory) {
      _writer.writeMessage(
        7,
        _instance.subcategory as any,
        CompositionSubcategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.funding) {
      _writer.writeMessage(
        8,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.isPrimarySubcategory) {
      _writer.writeBool(9, _instance.isPrimarySubcategory);
    }
  }

  private _percentage: number;
  private _id: number;
  private _subcategoryId: number;
  private _fundingId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _subcategory?: CompositionSubcategoryModel;
  private _funding?: FundingModel;
  private _isPrimarySubcategory: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FundingCompositionModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FundingCompositionModel.AsObject>) {
    _value = _value || {};
    this.percentage = _value.percentage;
    this.id = _value.id;
    this.subcategoryId = _value.subcategoryId;
    this.fundingId = _value.fundingId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.subcategory = _value.subcategory
      ? new CompositionSubcategoryModel(_value.subcategory)
      : undefined;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.isPrimarySubcategory = _value.isPrimarySubcategory;
    FundingCompositionModel.refineValues(this);
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get subcategoryId(): number {
    return this._subcategoryId;
  }
  set subcategoryId(value: number) {
    this._subcategoryId = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get subcategory(): CompositionSubcategoryModel | undefined {
    return this._subcategory;
  }
  set subcategory(value: CompositionSubcategoryModel | undefined) {
    this._subcategory = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get isPrimarySubcategory(): boolean {
    return this._isPrimarySubcategory;
  }
  set isPrimarySubcategory(value: boolean) {
    this._isPrimarySubcategory = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FundingCompositionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FundingCompositionModel.AsObject {
    return {
      percentage: this.percentage,
      id: this.id,
      subcategoryId: this.subcategoryId,
      fundingId: this.fundingId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      subcategory: this.subcategory ? this.subcategory.toObject() : undefined,
      funding: this.funding ? this.funding.toObject() : undefined,
      isPrimarySubcategory: this.isPrimarySubcategory
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FundingCompositionModel.AsProtobufJSON {
    return {
      percentage: this.percentage,
      id: this.id,
      subcategoryId: this.subcategoryId,
      fundingId: this.fundingId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      subcategory: this.subcategory
        ? this.subcategory.toProtobufJSON(options)
        : null,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      isPrimarySubcategory: this.isPrimarySubcategory
    };
  }
}
export module FundingCompositionModel {
  /**
   * Standard JavaScript object representation for FundingCompositionModel
   */
  export interface AsObject {
    percentage: number;
    id: number;
    subcategoryId: number;
    fundingId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    subcategory?: CompositionSubcategoryModel.AsObject;
    funding?: FundingModel.AsObject;
    isPrimarySubcategory: boolean;
  }

  /**
   * Protobuf JSON representation for FundingCompositionModel
   */
  export interface AsProtobufJSON {
    percentage: number;
    id: number;
    subcategoryId: number;
    fundingId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    subcategory: CompositionSubcategoryModel.AsProtobufJSON | null;
    funding: FundingModel.AsProtobufJSON | null;
    isPrimarySubcategory: boolean;
  }
}

/**
 * Message implementation for common_message.FundingShareValueModel
 */
export class FundingShareValueModel implements GrpcMessage {
  static id = 'common_message.FundingShareValueModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FundingShareValueModel();
    FundingShareValueModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FundingShareValueModel) {
    _instance.id = _instance.id || 0;
    _instance.date = _instance.date || undefined;
    _instance.value = _instance.value || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.fundingId = _instance.fundingId || 0;
    _instance.funding = _instance.funding || undefined;
    _instance.delayedDate = _instance.delayedDate || undefined;
    _instance.isDelayedValue = _instance.isDelayedValue || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FundingShareValueModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.value = _reader.readDouble();
          break;
        case 4:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.fundingId = _reader.readInt32();
          break;
        case 7:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.delayedDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.delayedDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.isDelayedValue = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    FundingShareValueModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FundingShareValueModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.value) {
      _writer.writeDouble(3, _instance.value);
    }
    if (_instance.created) {
      _writer.writeMessage(
        4,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        5,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.fundingId) {
      _writer.writeInt32(6, _instance.fundingId);
    }
    if (_instance.funding) {
      _writer.writeMessage(
        7,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.delayedDate) {
      _writer.writeMessage(
        8,
        _instance.delayedDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.isDelayedValue) {
      _writer.writeBool(9, _instance.isDelayedValue);
    }
  }

  private _id: number;
  private _date?: googleProtobuf001.Timestamp;
  private _value: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _fundingId: number;
  private _funding?: FundingModel;
  private _delayedDate?: googleProtobuf001.Timestamp;
  private _isDelayedValue: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FundingShareValueModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FundingShareValueModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.value = _value.value;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.fundingId = _value.fundingId;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.delayedDate = _value.delayedDate
      ? new googleProtobuf001.Timestamp(_value.delayedDate)
      : undefined;
    this.isDelayedValue = _value.isDelayedValue;
    FundingShareValueModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get delayedDate(): googleProtobuf001.Timestamp | undefined {
    return this._delayedDate;
  }
  set delayedDate(value: googleProtobuf001.Timestamp | undefined) {
    this._delayedDate = value;
  }
  get isDelayedValue(): boolean {
    return this._isDelayedValue;
  }
  set isDelayedValue(value: boolean) {
    this._isDelayedValue = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FundingShareValueModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FundingShareValueModel.AsObject {
    return {
      id: this.id,
      date: this.date ? this.date.toObject() : undefined,
      value: this.value,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      fundingId: this.fundingId,
      funding: this.funding ? this.funding.toObject() : undefined,
      delayedDate: this.delayedDate ? this.delayedDate.toObject() : undefined,
      isDelayedValue: this.isDelayedValue
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FundingShareValueModel.AsProtobufJSON {
    return {
      id: this.id,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      value: this.value,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      fundingId: this.fundingId,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      delayedDate: this.delayedDate
        ? this.delayedDate.toProtobufJSON(options)
        : null,
      isDelayedValue: this.isDelayedValue
    };
  }
}
export module FundingShareValueModel {
  /**
   * Standard JavaScript object representation for FundingShareValueModel
   */
  export interface AsObject {
    id: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    value: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    fundingId: number;
    funding?: FundingModel.AsObject;
    delayedDate?: googleProtobuf001.Timestamp.AsObject;
    isDelayedValue: boolean;
  }

  /**
   * Protobuf JSON representation for FundingShareValueModel
   */
  export interface AsProtobufJSON {
    id: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    value: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    fundingId: number;
    funding: FundingModel.AsProtobufJSON | null;
    delayedDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    isDelayedValue: boolean;
  }
}

/**
 * Message implementation for common_message.GoalTransactionFundingModel
 */
export class GoalTransactionFundingModel implements GrpcMessage {
  static id = 'common_message.GoalTransactionFundingModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalTransactionFundingModel();
    GoalTransactionFundingModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalTransactionFundingModel) {
    _instance.amount = _instance.amount || 0;
    _instance.amountRequested = _instance.amountRequested || 0;
    _instance.amountToProcess = _instance.amountToProcess || 0;
    _instance.quotas = _instance.quotas || 0;
    _instance.date = _instance.date || undefined;
    _instance.state = _instance.state || 0;
    _instance.type = _instance.type || 0;
    _instance.price = _instance.price || 0;
    _instance.invoice = _instance.invoice || '';
    _instance.id = _instance.id || 0;
    _instance.fundingId = _instance.fundingId || 0;
    _instance.goalId = _instance.goalId || 0;
    _instance.ownerId = _instance.ownerId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.goalTransactionId = _instance.goalTransactionId || 0;
    _instance.transactionId = _instance.transactionId || 0;
    _instance.shareValueId = _instance.shareValueId || undefined;
    _instance.requestFileId = _instance.requestFileId || undefined;
    _instance.voucherFileId = _instance.voucherFileId || undefined;
    _instance.funding = _instance.funding || undefined;
    _instance.percentage = _instance.percentage || 0;
    _instance.generatedBy = _instance.generatedBy || '';
    _instance.cost = _instance.cost || 0;
    _instance.priceDateStamp = _instance.priceDateStamp || undefined;
    _instance.transaction = _instance.transaction || undefined;
    _instance.shareValue = _instance.shareValue || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalTransactionFundingModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readDouble();
          break;
        case 2:
          _instance.amountRequested = _reader.readDouble();
          break;
        case 3:
          _instance.amountToProcess = _reader.readDouble();
          break;
        case 4:
          _instance.quotas = _reader.readDouble();
          break;
        case 5:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.state = _reader.readEnum();
          break;
        case 7:
          _instance.type = _reader.readEnum();
          break;
        case 8:
          _instance.price = _reader.readDouble();
          break;
        case 9:
          _instance.invoice = _reader.readString();
          break;
        case 10:
          _instance.id = _reader.readInt32();
          break;
        case 11:
          _instance.fundingId = _reader.readInt32();
          break;
        case 12:
          _instance.goalId = _reader.readInt32();
          break;
        case 13:
          _instance.ownerId = _reader.readInt32();
          break;
        case 14:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.goalTransactionId = _reader.readInt32();
          break;
        case 17:
          _instance.transactionId = _reader.readInt32();
          break;
        case 18:
          _instance.shareValueId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.shareValueId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.requestFileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.requestFileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 20:
          _instance.voucherFileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.voucherFileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.percentage = _reader.readDouble();
          break;
        case 23:
          _instance.generatedBy = _reader.readString();
          break;
        case 24:
          _instance.cost = _reader.readDouble();
          break;
        case 25:
          _instance.priceDateStamp = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.priceDateStamp,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.transaction = new GoalTransactionModel();
          _reader.readMessage(
            _instance.transaction,
            GoalTransactionModel.deserializeBinaryFromReader
          );
          break;
        case 27:
          _instance.shareValue = new FundingShareValueModel();
          _reader.readMessage(
            _instance.shareValue,
            FundingShareValueModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GoalTransactionFundingModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalTransactionFundingModel,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeDouble(1, _instance.amount);
    }
    if (_instance.amountRequested) {
      _writer.writeDouble(2, _instance.amountRequested);
    }
    if (_instance.amountToProcess) {
      _writer.writeDouble(3, _instance.amountToProcess);
    }
    if (_instance.quotas) {
      _writer.writeDouble(4, _instance.quotas);
    }
    if (_instance.date) {
      _writer.writeMessage(
        5,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.state) {
      _writer.writeEnum(6, _instance.state);
    }
    if (_instance.type) {
      _writer.writeEnum(7, _instance.type);
    }
    if (_instance.price) {
      _writer.writeDouble(8, _instance.price);
    }
    if (_instance.invoice) {
      _writer.writeString(9, _instance.invoice);
    }
    if (_instance.id) {
      _writer.writeInt32(10, _instance.id);
    }
    if (_instance.fundingId) {
      _writer.writeInt32(11, _instance.fundingId);
    }
    if (_instance.goalId) {
      _writer.writeInt32(12, _instance.goalId);
    }
    if (_instance.ownerId) {
      _writer.writeInt32(13, _instance.ownerId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        14,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        15,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.goalTransactionId) {
      _writer.writeInt32(16, _instance.goalTransactionId);
    }
    if (_instance.transactionId) {
      _writer.writeInt32(17, _instance.transactionId);
    }
    if (_instance.shareValueId) {
      _writer.writeMessage(
        18,
        _instance.shareValueId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.requestFileId) {
      _writer.writeMessage(
        19,
        _instance.requestFileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.voucherFileId) {
      _writer.writeMessage(
        20,
        _instance.voucherFileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.funding) {
      _writer.writeMessage(
        21,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.percentage) {
      _writer.writeDouble(22, _instance.percentage);
    }
    if (_instance.generatedBy) {
      _writer.writeString(23, _instance.generatedBy);
    }
    if (_instance.cost) {
      _writer.writeDouble(24, _instance.cost);
    }
    if (_instance.priceDateStamp) {
      _writer.writeMessage(
        25,
        _instance.priceDateStamp as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.transaction) {
      _writer.writeMessage(
        26,
        _instance.transaction as any,
        GoalTransactionModel.serializeBinaryToWriter
      );
    }
    if (_instance.shareValue) {
      _writer.writeMessage(
        27,
        _instance.shareValue as any,
        FundingShareValueModel.serializeBinaryToWriter
      );
    }
  }

  private _amount: number;
  private _amountRequested: number;
  private _amountToProcess: number;
  private _quotas: number;
  private _date?: googleProtobuf001.Timestamp;
  private _state: goal_transaction_enums002.GoalTransactionState;
  private _type: goal_transaction_type_enums003.GoalTransactionType;
  private _price: number;
  private _invoice: string;
  private _id: number;
  private _fundingId: number;
  private _goalId: number;
  private _ownerId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _goalTransactionId: number;
  private _transactionId: number;
  private _shareValueId?: googleProtobuf000.Int32Value;
  private _requestFileId?: googleProtobuf000.Int32Value;
  private _voucherFileId?: googleProtobuf000.Int32Value;
  private _funding?: FundingModel;
  private _percentage: number;
  private _generatedBy: string;
  private _cost: number;
  private _priceDateStamp?: googleProtobuf001.Timestamp;
  private _transaction?: GoalTransactionModel;
  private _shareValue?: FundingShareValueModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalTransactionFundingModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalTransactionFundingModel.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.amountRequested = _value.amountRequested;
    this.amountToProcess = _value.amountToProcess;
    this.quotas = _value.quotas;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.state = _value.state;
    this.type = _value.type;
    this.price = _value.price;
    this.invoice = _value.invoice;
    this.id = _value.id;
    this.fundingId = _value.fundingId;
    this.goalId = _value.goalId;
    this.ownerId = _value.ownerId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.goalTransactionId = _value.goalTransactionId;
    this.transactionId = _value.transactionId;
    this.shareValueId = _value.shareValueId
      ? new googleProtobuf000.Int32Value(_value.shareValueId)
      : undefined;
    this.requestFileId = _value.requestFileId
      ? new googleProtobuf000.Int32Value(_value.requestFileId)
      : undefined;
    this.voucherFileId = _value.voucherFileId
      ? new googleProtobuf000.Int32Value(_value.voucherFileId)
      : undefined;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.percentage = _value.percentage;
    this.generatedBy = _value.generatedBy;
    this.cost = _value.cost;
    this.priceDateStamp = _value.priceDateStamp
      ? new googleProtobuf001.Timestamp(_value.priceDateStamp)
      : undefined;
    this.transaction = _value.transaction
      ? new GoalTransactionModel(_value.transaction)
      : undefined;
    this.shareValue = _value.shareValue
      ? new FundingShareValueModel(_value.shareValue)
      : undefined;
    GoalTransactionFundingModel.refineValues(this);
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get amountRequested(): number {
    return this._amountRequested;
  }
  set amountRequested(value: number) {
    this._amountRequested = value;
  }
  get amountToProcess(): number {
    return this._amountToProcess;
  }
  set amountToProcess(value: number) {
    this._amountToProcess = value;
  }
  get quotas(): number {
    return this._quotas;
  }
  set quotas(value: number) {
    this._quotas = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }
  get type(): goal_transaction_type_enums003.GoalTransactionType {
    return this._type;
  }
  set type(value: goal_transaction_type_enums003.GoalTransactionType) {
    this._type = value;
  }
  get price(): number {
    return this._price;
  }
  set price(value: number) {
    this._price = value;
  }
  get invoice(): string {
    return this._invoice;
  }
  set invoice(value: string) {
    this._invoice = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }
  get ownerId(): number {
    return this._ownerId;
  }
  set ownerId(value: number) {
    this._ownerId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get goalTransactionId(): number {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: number) {
    this._goalTransactionId = value;
  }
  get transactionId(): number {
    return this._transactionId;
  }
  set transactionId(value: number) {
    this._transactionId = value;
  }
  get shareValueId(): googleProtobuf000.Int32Value | undefined {
    return this._shareValueId;
  }
  set shareValueId(value: googleProtobuf000.Int32Value | undefined) {
    this._shareValueId = value;
  }
  get requestFileId(): googleProtobuf000.Int32Value | undefined {
    return this._requestFileId;
  }
  set requestFileId(value: googleProtobuf000.Int32Value | undefined) {
    this._requestFileId = value;
  }
  get voucherFileId(): googleProtobuf000.Int32Value | undefined {
    return this._voucherFileId;
  }
  set voucherFileId(value: googleProtobuf000.Int32Value | undefined) {
    this._voucherFileId = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get generatedBy(): string {
    return this._generatedBy;
  }
  set generatedBy(value: string) {
    this._generatedBy = value;
  }
  get cost(): number {
    return this._cost;
  }
  set cost(value: number) {
    this._cost = value;
  }
  get priceDateStamp(): googleProtobuf001.Timestamp | undefined {
    return this._priceDateStamp;
  }
  set priceDateStamp(value: googleProtobuf001.Timestamp | undefined) {
    this._priceDateStamp = value;
  }
  get transaction(): GoalTransactionModel | undefined {
    return this._transaction;
  }
  set transaction(value: GoalTransactionModel | undefined) {
    this._transaction = value;
  }
  get shareValue(): FundingShareValueModel | undefined {
    return this._shareValue;
  }
  set shareValue(value: FundingShareValueModel | undefined) {
    this._shareValue = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalTransactionFundingModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalTransactionFundingModel.AsObject {
    return {
      amount: this.amount,
      amountRequested: this.amountRequested,
      amountToProcess: this.amountToProcess,
      quotas: this.quotas,
      date: this.date ? this.date.toObject() : undefined,
      state: this.state,
      type: this.type,
      price: this.price,
      invoice: this.invoice,
      id: this.id,
      fundingId: this.fundingId,
      goalId: this.goalId,
      ownerId: this.ownerId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      goalTransactionId: this.goalTransactionId,
      transactionId: this.transactionId,
      shareValueId: this.shareValueId
        ? this.shareValueId.toObject()
        : undefined,
      requestFileId: this.requestFileId
        ? this.requestFileId.toObject()
        : undefined,
      voucherFileId: this.voucherFileId
        ? this.voucherFileId.toObject()
        : undefined,
      funding: this.funding ? this.funding.toObject() : undefined,
      percentage: this.percentage,
      generatedBy: this.generatedBy,
      cost: this.cost,
      priceDateStamp: this.priceDateStamp
        ? this.priceDateStamp.toObject()
        : undefined,
      transaction: this.transaction ? this.transaction.toObject() : undefined,
      shareValue: this.shareValue ? this.shareValue.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalTransactionFundingModel.AsProtobufJSON {
    return {
      amount: this.amount,
      amountRequested: this.amountRequested,
      amountToProcess: this.amountToProcess,
      quotas: this.quotas,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      type:
        goal_transaction_type_enums003.GoalTransactionType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      price: this.price,
      invoice: this.invoice,
      id: this.id,
      fundingId: this.fundingId,
      goalId: this.goalId,
      ownerId: this.ownerId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      goalTransactionId: this.goalTransactionId,
      transactionId: this.transactionId,
      shareValueId: this.shareValueId
        ? this.shareValueId.toProtobufJSON(options)
        : null,
      requestFileId: this.requestFileId
        ? this.requestFileId.toProtobufJSON(options)
        : null,
      voucherFileId: this.voucherFileId
        ? this.voucherFileId.toProtobufJSON(options)
        : null,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      percentage: this.percentage,
      generatedBy: this.generatedBy,
      cost: this.cost,
      priceDateStamp: this.priceDateStamp
        ? this.priceDateStamp.toProtobufJSON(options)
        : null,
      transaction: this.transaction
        ? this.transaction.toProtobufJSON(options)
        : null,
      shareValue: this.shareValue
        ? this.shareValue.toProtobufJSON(options)
        : null
    };
  }
}
export module GoalTransactionFundingModel {
  /**
   * Standard JavaScript object representation for GoalTransactionFundingModel
   */
  export interface AsObject {
    amount: number;
    amountRequested: number;
    amountToProcess: number;
    quotas: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    state: goal_transaction_enums002.GoalTransactionState;
    type: goal_transaction_type_enums003.GoalTransactionType;
    price: number;
    invoice: string;
    id: number;
    fundingId: number;
    goalId: number;
    ownerId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    goalTransactionId: number;
    transactionId: number;
    shareValueId?: googleProtobuf000.Int32Value.AsObject;
    requestFileId?: googleProtobuf000.Int32Value.AsObject;
    voucherFileId?: googleProtobuf000.Int32Value.AsObject;
    funding?: FundingModel.AsObject;
    percentage: number;
    generatedBy: string;
    cost: number;
    priceDateStamp?: googleProtobuf001.Timestamp.AsObject;
    transaction?: GoalTransactionModel.AsObject;
    shareValue?: FundingShareValueModel.AsObject;
  }

  /**
   * Protobuf JSON representation for GoalTransactionFundingModel
   */
  export interface AsProtobufJSON {
    amount: number;
    amountRequested: number;
    amountToProcess: number;
    quotas: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    state: string;
    type: string;
    price: number;
    invoice: string;
    id: number;
    fundingId: number;
    goalId: number;
    ownerId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    goalTransactionId: number;
    transactionId: number;
    shareValueId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    requestFileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    voucherFileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    funding: FundingModel.AsProtobufJSON | null;
    percentage: number;
    generatedBy: string;
    cost: number;
    priceDateStamp: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    transaction: GoalTransactionModel.AsProtobufJSON | null;
    shareValue: FundingShareValueModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.BpUsInscriptionModel
 */
export class BpUsInscriptionModel implements GrpcMessage {
  static id = 'common_message.BpUsInscriptionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BpUsInscriptionModel();
    BpUsInscriptionModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BpUsInscriptionModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.status = _instance.status || '';
    _instance.type = _instance.type || 0;
    _instance.usUserInformation = _instance.usUserInformation || undefined;
    _instance.userId = _instance.userId || 0;
    _instance.user = _instance.user || undefined;
    _instance.files = _instance.files || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BpUsInscriptionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.status = _reader.readString();
          break;
        case 5:
          _instance.type = _reader.readEnum();
          break;
        case 6:
          _instance.usUserInformation = new BpUsInscriptionInformationModel();
          _reader.readMessage(
            _instance.usUserInformation,
            BpUsInscriptionInformationModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.userId = _reader.readInt32();
          break;
        case 8:
          _instance.user = new UserModel();
          _reader.readMessage(
            _instance.user,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 9:
          const messageInitializer9 = new UserInscriptionFileModel();
          _reader.readMessage(
            messageInitializer9,
            UserInscriptionFileModel.deserializeBinaryFromReader
          );
          (_instance.files = _instance.files || []).push(messageInitializer9);
          break;
        default:
          _reader.skipField();
      }
    }

    BpUsInscriptionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BpUsInscriptionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeString(4, _instance.status);
    }
    if (_instance.type) {
      _writer.writeEnum(5, _instance.type);
    }
    if (_instance.usUserInformation) {
      _writer.writeMessage(
        6,
        _instance.usUserInformation as any,
        BpUsInscriptionInformationModel.serializeBinaryToWriter
      );
    }
    if (_instance.userId) {
      _writer.writeInt32(7, _instance.userId);
    }
    if (_instance.user) {
      _writer.writeMessage(
        8,
        _instance.user as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.files && _instance.files.length) {
      _writer.writeRepeatedMessage(
        9,
        _instance.files as any,
        UserInscriptionFileModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _status: string;
  private _type: UserInscriptionTypeEnum;
  private _usUserInformation?: BpUsInscriptionInformationModel;
  private _userId: number;
  private _user?: UserModel;
  private _files?: UserInscriptionFileModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BpUsInscriptionModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BpUsInscriptionModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.status = _value.status;
    this.type = _value.type;
    this.usUserInformation = _value.usUserInformation
      ? new BpUsInscriptionInformationModel(_value.usUserInformation)
      : undefined;
    this.userId = _value.userId;
    this.user = _value.user ? new UserModel(_value.user) : undefined;
    this.files = (_value.files || []).map(m => new UserInscriptionFileModel(m));
    BpUsInscriptionModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get type(): UserInscriptionTypeEnum {
    return this._type;
  }
  set type(value: UserInscriptionTypeEnum) {
    this._type = value;
  }
  get usUserInformation(): BpUsInscriptionInformationModel | undefined {
    return this._usUserInformation;
  }
  set usUserInformation(value: BpUsInscriptionInformationModel | undefined) {
    this._usUserInformation = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get user(): UserModel | undefined {
    return this._user;
  }
  set user(value: UserModel | undefined) {
    this._user = value;
  }
  get files(): UserInscriptionFileModel[] | undefined {
    return this._files;
  }
  set files(value: UserInscriptionFileModel[] | undefined) {
    this._files = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BpUsInscriptionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BpUsInscriptionModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      status: this.status,
      type: this.type,
      usUserInformation: this.usUserInformation
        ? this.usUserInformation.toObject()
        : undefined,
      userId: this.userId,
      user: this.user ? this.user.toObject() : undefined,
      files: (this.files || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BpUsInscriptionModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      status: this.status,
      type:
        UserInscriptionTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      usUserInformation: this.usUserInformation
        ? this.usUserInformation.toProtobufJSON(options)
        : null,
      userId: this.userId,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      files: (this.files || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module BpUsInscriptionModel {
  /**
   * Standard JavaScript object representation for BpUsInscriptionModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    status: string;
    type: UserInscriptionTypeEnum;
    usUserInformation?: BpUsInscriptionInformationModel.AsObject;
    userId: number;
    user?: UserModel.AsObject;
    files?: UserInscriptionFileModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for BpUsInscriptionModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    status: string;
    type: string;
    usUserInformation: BpUsInscriptionInformationModel.AsProtobufJSON | null;
    userId: number;
    user: UserModel.AsProtobufJSON | null;
    files: UserInscriptionFileModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for common_message.BpUsInscriptionInformationModel
 */
export class BpUsInscriptionInformationModel implements GrpcMessage {
  static id = 'common_message.BpUsInscriptionInformationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BpUsInscriptionInformationModel();
    BpUsInscriptionInformationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BpUsInscriptionInformationModel) {
    _instance.inscriptionId = _instance.inscriptionId || 0;
    _instance.userInformationId = _instance.userInformationId || 0;
    _instance.communeId = _instance.communeId || 0;
    _instance.regionId = _instance.regionId || 0;
    _instance.address = _instance.address || '';
    _instance.hasOtherNationality = _instance.hasOtherNationality || false;
    _instance.otherNationality = _instance.otherNationality || '';
    _instance.usNumberOfPeopleInCharge =
      _instance.usNumberOfPeopleInCharge || undefined;
    _instance.usEmploymentSituation =
      _instance.usEmploymentSituation || undefined;
    _instance.usEmployerName = _instance.usEmployerName || undefined;
    _instance.usEmploymentAddress = _instance.usEmploymentAddress || undefined;
    _instance.usTotalAnnualIncome = _instance.usTotalAnnualIncome || undefined;
    _instance.usNetPatrimony = _instance.usNetPatrimony || undefined;
    _instance.usLiquidPatrimony = _instance.usLiquidPatrimony || undefined;
    _instance.usSourceFunds = _instance.usSourceFunds || undefined;
    _instance.nationality = _instance.nationality || undefined;
    _instance.gender = _instance.gender || undefined;
    _instance.communeName = _instance.communeName || undefined;
    _instance.regionName = _instance.regionName || undefined;
    _instance.postalCode = _instance.postalCode || undefined;
    _instance.dateOfBirth = _instance.dateOfBirth || undefined;
    _instance.countryOfBirth = _instance.countryOfBirth || undefined;
    _instance.civilStatus = _instance.civilStatus || undefined;
    _instance.workCommuneId = _instance.workCommuneId || undefined;
    _instance.workCommuneName = _instance.workCommuneName || undefined;
    _instance.workPostalCode = _instance.workPostalCode || undefined;
    _instance.workRegionName = _instance.workRegionName || undefined;
    _instance.ibkrId = _instance.ibkrId || undefined;
    _instance.ibkrUser = _instance.ibkrUser || undefined;
    _instance.usEmploymentCountry = _instance.usEmploymentCountry || undefined;
    _instance.usEmploymentCommune = _instance.usEmploymentCommune || undefined;
    _instance.usEmploymentProfession =
      _instance.usEmploymentProfession || undefined;
    _instance.usCompanyActivity = _instance.usCompanyActivity || undefined;
    _instance.usEmploymentStateProvince =
      _instance.usEmploymentStateProvince || undefined;
    _instance.usAddressZipCode = _instance.usAddressZipCode || undefined;
    _instance.usWorkAddressZipCode =
      _instance.usWorkAddressZipCode || undefined;
    _instance.usCompanyActivityDetail =
      _instance.usCompanyActivityDetail || undefined;
    _instance.usEmploymentProfessionDetail =
      _instance.usEmploymentProfessionDetail || undefined;
    _instance.usEmploymentSituationDetail =
      _instance.usEmploymentSituationDetail || undefined;
    _instance.usCompliancePhone = _instance.usCompliancePhone || undefined;
    _instance.usComplianceEmail = _instance.usComplianceEmail || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BpUsInscriptionInformationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.inscriptionId = _reader.readInt32();
          break;
        case 2:
          _instance.userInformationId = _reader.readInt32();
          break;
        case 3:
          _instance.communeId = _reader.readInt32();
          break;
        case 4:
          _instance.regionId = _reader.readInt32();
          break;
        case 5:
          _instance.address = _reader.readString();
          break;
        case 6:
          _instance.hasOtherNationality = _reader.readBool();
          break;
        case 7:
          _instance.otherNationality = _reader.readString();
          break;
        case 8:
          _instance.usNumberOfPeopleInCharge = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.usNumberOfPeopleInCharge,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.usEmploymentSituation = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentSituation,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.usEmployerName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmployerName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.usEmploymentAddress = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentAddress,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.usTotalAnnualIncome = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usTotalAnnualIncome,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.usNetPatrimony = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usNetPatrimony,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.usLiquidPatrimony = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usLiquidPatrimony,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.usSourceFunds = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usSourceFunds,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.nationality = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.nationality,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.gender = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.gender,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.communeName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.communeName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 20:
          _instance.regionName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.regionName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.postalCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.postalCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.dateOfBirth = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateOfBirth,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 23:
          _instance.countryOfBirth = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.countryOfBirth,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 24:
          _instance.civilStatus = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.civilStatus,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 25:
          _instance.workCommuneId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.workCommuneId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.workCommuneName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workCommuneName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 27:
          _instance.workPostalCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workPostalCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 28:
          _instance.workRegionName = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.workRegionName,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 29:
          _instance.ibkrId = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.ibkrId,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 30:
          _instance.ibkrUser = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.ibkrUser,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 31:
          _instance.usEmploymentCountry = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentCountry,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 32:
          _instance.usEmploymentCommune = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentCommune,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 33:
          _instance.usEmploymentProfession = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentProfession,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 34:
          _instance.usCompanyActivity = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usCompanyActivity,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 35:
          _instance.usEmploymentStateProvince = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentStateProvince,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 36:
          _instance.usAddressZipCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usAddressZipCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 37:
          _instance.usWorkAddressZipCode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usWorkAddressZipCode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 38:
          _instance.usCompanyActivityDetail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usCompanyActivityDetail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 39:
          _instance.usEmploymentProfessionDetail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentProfessionDetail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 40:
          _instance.usEmploymentSituationDetail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usEmploymentSituationDetail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 41:
          _instance.usCompliancePhone = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usCompliancePhone,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 42:
          _instance.usComplianceEmail = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.usComplianceEmail,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BpUsInscriptionInformationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BpUsInscriptionInformationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.inscriptionId) {
      _writer.writeInt32(1, _instance.inscriptionId);
    }
    if (_instance.userInformationId) {
      _writer.writeInt32(2, _instance.userInformationId);
    }
    if (_instance.communeId) {
      _writer.writeInt32(3, _instance.communeId);
    }
    if (_instance.regionId) {
      _writer.writeInt32(4, _instance.regionId);
    }
    if (_instance.address) {
      _writer.writeString(5, _instance.address);
    }
    if (_instance.hasOtherNationality) {
      _writer.writeBool(6, _instance.hasOtherNationality);
    }
    if (_instance.otherNationality) {
      _writer.writeString(7, _instance.otherNationality);
    }
    if (_instance.usNumberOfPeopleInCharge) {
      _writer.writeMessage(
        8,
        _instance.usNumberOfPeopleInCharge as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentSituation) {
      _writer.writeMessage(
        9,
        _instance.usEmploymentSituation as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmployerName) {
      _writer.writeMessage(
        10,
        _instance.usEmployerName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentAddress) {
      _writer.writeMessage(
        12,
        _instance.usEmploymentAddress as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usTotalAnnualIncome) {
      _writer.writeMessage(
        13,
        _instance.usTotalAnnualIncome as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usNetPatrimony) {
      _writer.writeMessage(
        14,
        _instance.usNetPatrimony as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usLiquidPatrimony) {
      _writer.writeMessage(
        15,
        _instance.usLiquidPatrimony as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usSourceFunds) {
      _writer.writeMessage(
        16,
        _instance.usSourceFunds as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.nationality) {
      _writer.writeMessage(
        17,
        _instance.nationality as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.gender) {
      _writer.writeMessage(
        18,
        _instance.gender as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.communeName) {
      _writer.writeMessage(
        19,
        _instance.communeName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.regionName) {
      _writer.writeMessage(
        20,
        _instance.regionName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.postalCode) {
      _writer.writeMessage(
        21,
        _instance.postalCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.dateOfBirth) {
      _writer.writeMessage(
        22,
        _instance.dateOfBirth as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.countryOfBirth) {
      _writer.writeMessage(
        23,
        _instance.countryOfBirth as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.civilStatus) {
      _writer.writeMessage(
        24,
        _instance.civilStatus as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workCommuneId) {
      _writer.writeMessage(
        25,
        _instance.workCommuneId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.workCommuneName) {
      _writer.writeMessage(
        26,
        _instance.workCommuneName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workPostalCode) {
      _writer.writeMessage(
        27,
        _instance.workPostalCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.workRegionName) {
      _writer.writeMessage(
        28,
        _instance.workRegionName as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.ibkrId) {
      _writer.writeMessage(
        29,
        _instance.ibkrId as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.ibkrUser) {
      _writer.writeMessage(
        30,
        _instance.ibkrUser as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentCountry) {
      _writer.writeMessage(
        31,
        _instance.usEmploymentCountry as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentCommune) {
      _writer.writeMessage(
        32,
        _instance.usEmploymentCommune as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentProfession) {
      _writer.writeMessage(
        33,
        _instance.usEmploymentProfession as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usCompanyActivity) {
      _writer.writeMessage(
        34,
        _instance.usCompanyActivity as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentStateProvince) {
      _writer.writeMessage(
        35,
        _instance.usEmploymentStateProvince as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usAddressZipCode) {
      _writer.writeMessage(
        36,
        _instance.usAddressZipCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usWorkAddressZipCode) {
      _writer.writeMessage(
        37,
        _instance.usWorkAddressZipCode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usCompanyActivityDetail) {
      _writer.writeMessage(
        38,
        _instance.usCompanyActivityDetail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentProfessionDetail) {
      _writer.writeMessage(
        39,
        _instance.usEmploymentProfessionDetail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usEmploymentSituationDetail) {
      _writer.writeMessage(
        40,
        _instance.usEmploymentSituationDetail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usCompliancePhone) {
      _writer.writeMessage(
        41,
        _instance.usCompliancePhone as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.usComplianceEmail) {
      _writer.writeMessage(
        42,
        _instance.usComplianceEmail as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _inscriptionId: number;
  private _userInformationId: number;
  private _communeId: number;
  private _regionId: number;
  private _address: string;
  private _hasOtherNationality: boolean;
  private _otherNationality: string;
  private _usNumberOfPeopleInCharge?: googleProtobuf000.Int32Value;
  private _usEmploymentSituation?: googleProtobuf000.StringValue;
  private _usEmployerName?: googleProtobuf000.StringValue;
  private _usEmploymentAddress?: googleProtobuf000.StringValue;
  private _usTotalAnnualIncome?: googleProtobuf000.StringValue;
  private _usNetPatrimony?: googleProtobuf000.StringValue;
  private _usLiquidPatrimony?: googleProtobuf000.StringValue;
  private _usSourceFunds?: googleProtobuf000.StringValue;
  private _nationality?: googleProtobuf000.StringValue;
  private _gender?: googleProtobuf000.StringValue;
  private _communeName?: googleProtobuf000.StringValue;
  private _regionName?: googleProtobuf000.StringValue;
  private _postalCode?: googleProtobuf000.StringValue;
  private _dateOfBirth?: googleProtobuf001.Timestamp;
  private _countryOfBirth?: googleProtobuf000.StringValue;
  private _civilStatus?: googleProtobuf000.StringValue;
  private _workCommuneId?: googleProtobuf000.Int32Value;
  private _workCommuneName?: googleProtobuf000.StringValue;
  private _workPostalCode?: googleProtobuf000.StringValue;
  private _workRegionName?: googleProtobuf000.StringValue;
  private _ibkrId?: googleProtobuf000.StringValue;
  private _ibkrUser?: googleProtobuf000.StringValue;
  private _usEmploymentCountry?: googleProtobuf000.StringValue;
  private _usEmploymentCommune?: googleProtobuf000.StringValue;
  private _usEmploymentProfession?: googleProtobuf000.StringValue;
  private _usCompanyActivity?: googleProtobuf000.StringValue;
  private _usEmploymentStateProvince?: googleProtobuf000.StringValue;
  private _usAddressZipCode?: googleProtobuf000.StringValue;
  private _usWorkAddressZipCode?: googleProtobuf000.StringValue;
  private _usCompanyActivityDetail?: googleProtobuf000.StringValue;
  private _usEmploymentProfessionDetail?: googleProtobuf000.StringValue;
  private _usEmploymentSituationDetail?: googleProtobuf000.StringValue;
  private _usCompliancePhone?: googleProtobuf000.StringValue;
  private _usComplianceEmail?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BpUsInscriptionInformationModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<BpUsInscriptionInformationModel.AsObject>
  ) {
    _value = _value || {};
    this.inscriptionId = _value.inscriptionId;
    this.userInformationId = _value.userInformationId;
    this.communeId = _value.communeId;
    this.regionId = _value.regionId;
    this.address = _value.address;
    this.hasOtherNationality = _value.hasOtherNationality;
    this.otherNationality = _value.otherNationality;
    this.usNumberOfPeopleInCharge = _value.usNumberOfPeopleInCharge
      ? new googleProtobuf000.Int32Value(_value.usNumberOfPeopleInCharge)
      : undefined;
    this.usEmploymentSituation = _value.usEmploymentSituation
      ? new googleProtobuf000.StringValue(_value.usEmploymentSituation)
      : undefined;
    this.usEmployerName = _value.usEmployerName
      ? new googleProtobuf000.StringValue(_value.usEmployerName)
      : undefined;
    this.usEmploymentAddress = _value.usEmploymentAddress
      ? new googleProtobuf000.StringValue(_value.usEmploymentAddress)
      : undefined;
    this.usTotalAnnualIncome = _value.usTotalAnnualIncome
      ? new googleProtobuf000.StringValue(_value.usTotalAnnualIncome)
      : undefined;
    this.usNetPatrimony = _value.usNetPatrimony
      ? new googleProtobuf000.StringValue(_value.usNetPatrimony)
      : undefined;
    this.usLiquidPatrimony = _value.usLiquidPatrimony
      ? new googleProtobuf000.StringValue(_value.usLiquidPatrimony)
      : undefined;
    this.usSourceFunds = _value.usSourceFunds
      ? new googleProtobuf000.StringValue(_value.usSourceFunds)
      : undefined;
    this.nationality = _value.nationality
      ? new googleProtobuf000.StringValue(_value.nationality)
      : undefined;
    this.gender = _value.gender
      ? new googleProtobuf000.StringValue(_value.gender)
      : undefined;
    this.communeName = _value.communeName
      ? new googleProtobuf000.StringValue(_value.communeName)
      : undefined;
    this.regionName = _value.regionName
      ? new googleProtobuf000.StringValue(_value.regionName)
      : undefined;
    this.postalCode = _value.postalCode
      ? new googleProtobuf000.StringValue(_value.postalCode)
      : undefined;
    this.dateOfBirth = _value.dateOfBirth
      ? new googleProtobuf001.Timestamp(_value.dateOfBirth)
      : undefined;
    this.countryOfBirth = _value.countryOfBirth
      ? new googleProtobuf000.StringValue(_value.countryOfBirth)
      : undefined;
    this.civilStatus = _value.civilStatus
      ? new googleProtobuf000.StringValue(_value.civilStatus)
      : undefined;
    this.workCommuneId = _value.workCommuneId
      ? new googleProtobuf000.Int32Value(_value.workCommuneId)
      : undefined;
    this.workCommuneName = _value.workCommuneName
      ? new googleProtobuf000.StringValue(_value.workCommuneName)
      : undefined;
    this.workPostalCode = _value.workPostalCode
      ? new googleProtobuf000.StringValue(_value.workPostalCode)
      : undefined;
    this.workRegionName = _value.workRegionName
      ? new googleProtobuf000.StringValue(_value.workRegionName)
      : undefined;
    this.ibkrId = _value.ibkrId
      ? new googleProtobuf000.StringValue(_value.ibkrId)
      : undefined;
    this.ibkrUser = _value.ibkrUser
      ? new googleProtobuf000.StringValue(_value.ibkrUser)
      : undefined;
    this.usEmploymentCountry = _value.usEmploymentCountry
      ? new googleProtobuf000.StringValue(_value.usEmploymentCountry)
      : undefined;
    this.usEmploymentCommune = _value.usEmploymentCommune
      ? new googleProtobuf000.StringValue(_value.usEmploymentCommune)
      : undefined;
    this.usEmploymentProfession = _value.usEmploymentProfession
      ? new googleProtobuf000.StringValue(_value.usEmploymentProfession)
      : undefined;
    this.usCompanyActivity = _value.usCompanyActivity
      ? new googleProtobuf000.StringValue(_value.usCompanyActivity)
      : undefined;
    this.usEmploymentStateProvince = _value.usEmploymentStateProvince
      ? new googleProtobuf000.StringValue(_value.usEmploymentStateProvince)
      : undefined;
    this.usAddressZipCode = _value.usAddressZipCode
      ? new googleProtobuf000.StringValue(_value.usAddressZipCode)
      : undefined;
    this.usWorkAddressZipCode = _value.usWorkAddressZipCode
      ? new googleProtobuf000.StringValue(_value.usWorkAddressZipCode)
      : undefined;
    this.usCompanyActivityDetail = _value.usCompanyActivityDetail
      ? new googleProtobuf000.StringValue(_value.usCompanyActivityDetail)
      : undefined;
    this.usEmploymentProfessionDetail = _value.usEmploymentProfessionDetail
      ? new googleProtobuf000.StringValue(_value.usEmploymentProfessionDetail)
      : undefined;
    this.usEmploymentSituationDetail = _value.usEmploymentSituationDetail
      ? new googleProtobuf000.StringValue(_value.usEmploymentSituationDetail)
      : undefined;
    this.usCompliancePhone = _value.usCompliancePhone
      ? new googleProtobuf000.StringValue(_value.usCompliancePhone)
      : undefined;
    this.usComplianceEmail = _value.usComplianceEmail
      ? new googleProtobuf000.StringValue(_value.usComplianceEmail)
      : undefined;
    BpUsInscriptionInformationModel.refineValues(this);
  }
  get inscriptionId(): number {
    return this._inscriptionId;
  }
  set inscriptionId(value: number) {
    this._inscriptionId = value;
  }
  get userInformationId(): number {
    return this._userInformationId;
  }
  set userInformationId(value: number) {
    this._userInformationId = value;
  }
  get communeId(): number {
    return this._communeId;
  }
  set communeId(value: number) {
    this._communeId = value;
  }
  get regionId(): number {
    return this._regionId;
  }
  set regionId(value: number) {
    this._regionId = value;
  }
  get address(): string {
    return this._address;
  }
  set address(value: string) {
    this._address = value;
  }
  get hasOtherNationality(): boolean {
    return this._hasOtherNationality;
  }
  set hasOtherNationality(value: boolean) {
    this._hasOtherNationality = value;
  }
  get otherNationality(): string {
    return this._otherNationality;
  }
  set otherNationality(value: string) {
    this._otherNationality = value;
  }
  get usNumberOfPeopleInCharge(): googleProtobuf000.Int32Value | undefined {
    return this._usNumberOfPeopleInCharge;
  }
  set usNumberOfPeopleInCharge(
    value: googleProtobuf000.Int32Value | undefined
  ) {
    this._usNumberOfPeopleInCharge = value;
  }
  get usEmploymentSituation(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentSituation;
  }
  set usEmploymentSituation(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentSituation = value;
  }
  get usEmployerName(): googleProtobuf000.StringValue | undefined {
    return this._usEmployerName;
  }
  set usEmployerName(value: googleProtobuf000.StringValue | undefined) {
    this._usEmployerName = value;
  }
  get usEmploymentAddress(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentAddress;
  }
  set usEmploymentAddress(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentAddress = value;
  }
  get usTotalAnnualIncome(): googleProtobuf000.StringValue | undefined {
    return this._usTotalAnnualIncome;
  }
  set usTotalAnnualIncome(value: googleProtobuf000.StringValue | undefined) {
    this._usTotalAnnualIncome = value;
  }
  get usNetPatrimony(): googleProtobuf000.StringValue | undefined {
    return this._usNetPatrimony;
  }
  set usNetPatrimony(value: googleProtobuf000.StringValue | undefined) {
    this._usNetPatrimony = value;
  }
  get usLiquidPatrimony(): googleProtobuf000.StringValue | undefined {
    return this._usLiquidPatrimony;
  }
  set usLiquidPatrimony(value: googleProtobuf000.StringValue | undefined) {
    this._usLiquidPatrimony = value;
  }
  get usSourceFunds(): googleProtobuf000.StringValue | undefined {
    return this._usSourceFunds;
  }
  set usSourceFunds(value: googleProtobuf000.StringValue | undefined) {
    this._usSourceFunds = value;
  }
  get nationality(): googleProtobuf000.StringValue | undefined {
    return this._nationality;
  }
  set nationality(value: googleProtobuf000.StringValue | undefined) {
    this._nationality = value;
  }
  get gender(): googleProtobuf000.StringValue | undefined {
    return this._gender;
  }
  set gender(value: googleProtobuf000.StringValue | undefined) {
    this._gender = value;
  }
  get communeName(): googleProtobuf000.StringValue | undefined {
    return this._communeName;
  }
  set communeName(value: googleProtobuf000.StringValue | undefined) {
    this._communeName = value;
  }
  get regionName(): googleProtobuf000.StringValue | undefined {
    return this._regionName;
  }
  set regionName(value: googleProtobuf000.StringValue | undefined) {
    this._regionName = value;
  }
  get postalCode(): googleProtobuf000.StringValue | undefined {
    return this._postalCode;
  }
  set postalCode(value: googleProtobuf000.StringValue | undefined) {
    this._postalCode = value;
  }
  get dateOfBirth(): googleProtobuf001.Timestamp | undefined {
    return this._dateOfBirth;
  }
  set dateOfBirth(value: googleProtobuf001.Timestamp | undefined) {
    this._dateOfBirth = value;
  }
  get countryOfBirth(): googleProtobuf000.StringValue | undefined {
    return this._countryOfBirth;
  }
  set countryOfBirth(value: googleProtobuf000.StringValue | undefined) {
    this._countryOfBirth = value;
  }
  get civilStatus(): googleProtobuf000.StringValue | undefined {
    return this._civilStatus;
  }
  set civilStatus(value: googleProtobuf000.StringValue | undefined) {
    this._civilStatus = value;
  }
  get workCommuneId(): googleProtobuf000.Int32Value | undefined {
    return this._workCommuneId;
  }
  set workCommuneId(value: googleProtobuf000.Int32Value | undefined) {
    this._workCommuneId = value;
  }
  get workCommuneName(): googleProtobuf000.StringValue | undefined {
    return this._workCommuneName;
  }
  set workCommuneName(value: googleProtobuf000.StringValue | undefined) {
    this._workCommuneName = value;
  }
  get workPostalCode(): googleProtobuf000.StringValue | undefined {
    return this._workPostalCode;
  }
  set workPostalCode(value: googleProtobuf000.StringValue | undefined) {
    this._workPostalCode = value;
  }
  get workRegionName(): googleProtobuf000.StringValue | undefined {
    return this._workRegionName;
  }
  set workRegionName(value: googleProtobuf000.StringValue | undefined) {
    this._workRegionName = value;
  }
  get ibkrId(): googleProtobuf000.StringValue | undefined {
    return this._ibkrId;
  }
  set ibkrId(value: googleProtobuf000.StringValue | undefined) {
    this._ibkrId = value;
  }
  get ibkrUser(): googleProtobuf000.StringValue | undefined {
    return this._ibkrUser;
  }
  set ibkrUser(value: googleProtobuf000.StringValue | undefined) {
    this._ibkrUser = value;
  }
  get usEmploymentCountry(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentCountry;
  }
  set usEmploymentCountry(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentCountry = value;
  }
  get usEmploymentCommune(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentCommune;
  }
  set usEmploymentCommune(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentCommune = value;
  }
  get usEmploymentProfession(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentProfession;
  }
  set usEmploymentProfession(value: googleProtobuf000.StringValue | undefined) {
    this._usEmploymentProfession = value;
  }
  get usCompanyActivity(): googleProtobuf000.StringValue | undefined {
    return this._usCompanyActivity;
  }
  set usCompanyActivity(value: googleProtobuf000.StringValue | undefined) {
    this._usCompanyActivity = value;
  }
  get usEmploymentStateProvince(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentStateProvince;
  }
  set usEmploymentStateProvince(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usEmploymentStateProvince = value;
  }
  get usAddressZipCode(): googleProtobuf000.StringValue | undefined {
    return this._usAddressZipCode;
  }
  set usAddressZipCode(value: googleProtobuf000.StringValue | undefined) {
    this._usAddressZipCode = value;
  }
  get usWorkAddressZipCode(): googleProtobuf000.StringValue | undefined {
    return this._usWorkAddressZipCode;
  }
  set usWorkAddressZipCode(value: googleProtobuf000.StringValue | undefined) {
    this._usWorkAddressZipCode = value;
  }
  get usCompanyActivityDetail(): googleProtobuf000.StringValue | undefined {
    return this._usCompanyActivityDetail;
  }
  set usCompanyActivityDetail(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usCompanyActivityDetail = value;
  }
  get usEmploymentProfessionDetail():
    | googleProtobuf000.StringValue
    | undefined {
    return this._usEmploymentProfessionDetail;
  }
  set usEmploymentProfessionDetail(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usEmploymentProfessionDetail = value;
  }
  get usEmploymentSituationDetail(): googleProtobuf000.StringValue | undefined {
    return this._usEmploymentSituationDetail;
  }
  set usEmploymentSituationDetail(
    value: googleProtobuf000.StringValue | undefined
  ) {
    this._usEmploymentSituationDetail = value;
  }
  get usCompliancePhone(): googleProtobuf000.StringValue | undefined {
    return this._usCompliancePhone;
  }
  set usCompliancePhone(value: googleProtobuf000.StringValue | undefined) {
    this._usCompliancePhone = value;
  }
  get usComplianceEmail(): googleProtobuf000.StringValue | undefined {
    return this._usComplianceEmail;
  }
  set usComplianceEmail(value: googleProtobuf000.StringValue | undefined) {
    this._usComplianceEmail = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BpUsInscriptionInformationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BpUsInscriptionInformationModel.AsObject {
    return {
      inscriptionId: this.inscriptionId,
      userInformationId: this.userInformationId,
      communeId: this.communeId,
      regionId: this.regionId,
      address: this.address,
      hasOtherNationality: this.hasOtherNationality,
      otherNationality: this.otherNationality,
      usNumberOfPeopleInCharge: this.usNumberOfPeopleInCharge
        ? this.usNumberOfPeopleInCharge.toObject()
        : undefined,
      usEmploymentSituation: this.usEmploymentSituation
        ? this.usEmploymentSituation.toObject()
        : undefined,
      usEmployerName: this.usEmployerName
        ? this.usEmployerName.toObject()
        : undefined,
      usEmploymentAddress: this.usEmploymentAddress
        ? this.usEmploymentAddress.toObject()
        : undefined,
      usTotalAnnualIncome: this.usTotalAnnualIncome
        ? this.usTotalAnnualIncome.toObject()
        : undefined,
      usNetPatrimony: this.usNetPatrimony
        ? this.usNetPatrimony.toObject()
        : undefined,
      usLiquidPatrimony: this.usLiquidPatrimony
        ? this.usLiquidPatrimony.toObject()
        : undefined,
      usSourceFunds: this.usSourceFunds
        ? this.usSourceFunds.toObject()
        : undefined,
      nationality: this.nationality ? this.nationality.toObject() : undefined,
      gender: this.gender ? this.gender.toObject() : undefined,
      communeName: this.communeName ? this.communeName.toObject() : undefined,
      regionName: this.regionName ? this.regionName.toObject() : undefined,
      postalCode: this.postalCode ? this.postalCode.toObject() : undefined,
      dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toObject() : undefined,
      countryOfBirth: this.countryOfBirth
        ? this.countryOfBirth.toObject()
        : undefined,
      civilStatus: this.civilStatus ? this.civilStatus.toObject() : undefined,
      workCommuneId: this.workCommuneId
        ? this.workCommuneId.toObject()
        : undefined,
      workCommuneName: this.workCommuneName
        ? this.workCommuneName.toObject()
        : undefined,
      workPostalCode: this.workPostalCode
        ? this.workPostalCode.toObject()
        : undefined,
      workRegionName: this.workRegionName
        ? this.workRegionName.toObject()
        : undefined,
      ibkrId: this.ibkrId ? this.ibkrId.toObject() : undefined,
      ibkrUser: this.ibkrUser ? this.ibkrUser.toObject() : undefined,
      usEmploymentCountry: this.usEmploymentCountry
        ? this.usEmploymentCountry.toObject()
        : undefined,
      usEmploymentCommune: this.usEmploymentCommune
        ? this.usEmploymentCommune.toObject()
        : undefined,
      usEmploymentProfession: this.usEmploymentProfession
        ? this.usEmploymentProfession.toObject()
        : undefined,
      usCompanyActivity: this.usCompanyActivity
        ? this.usCompanyActivity.toObject()
        : undefined,
      usEmploymentStateProvince: this.usEmploymentStateProvince
        ? this.usEmploymentStateProvince.toObject()
        : undefined,
      usAddressZipCode: this.usAddressZipCode
        ? this.usAddressZipCode.toObject()
        : undefined,
      usWorkAddressZipCode: this.usWorkAddressZipCode
        ? this.usWorkAddressZipCode.toObject()
        : undefined,
      usCompanyActivityDetail: this.usCompanyActivityDetail
        ? this.usCompanyActivityDetail.toObject()
        : undefined,
      usEmploymentProfessionDetail: this.usEmploymentProfessionDetail
        ? this.usEmploymentProfessionDetail.toObject()
        : undefined,
      usEmploymentSituationDetail: this.usEmploymentSituationDetail
        ? this.usEmploymentSituationDetail.toObject()
        : undefined,
      usCompliancePhone: this.usCompliancePhone
        ? this.usCompliancePhone.toObject()
        : undefined,
      usComplianceEmail: this.usComplianceEmail
        ? this.usComplianceEmail.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BpUsInscriptionInformationModel.AsProtobufJSON {
    return {
      inscriptionId: this.inscriptionId,
      userInformationId: this.userInformationId,
      communeId: this.communeId,
      regionId: this.regionId,
      address: this.address,
      hasOtherNationality: this.hasOtherNationality,
      otherNationality: this.otherNationality,
      usNumberOfPeopleInCharge: this.usNumberOfPeopleInCharge
        ? this.usNumberOfPeopleInCharge.toProtobufJSON(options)
        : null,
      usEmploymentSituation: this.usEmploymentSituation
        ? this.usEmploymentSituation.toProtobufJSON(options)
        : null,
      usEmployerName: this.usEmployerName
        ? this.usEmployerName.toProtobufJSON(options)
        : null,
      usEmploymentAddress: this.usEmploymentAddress
        ? this.usEmploymentAddress.toProtobufJSON(options)
        : null,
      usTotalAnnualIncome: this.usTotalAnnualIncome
        ? this.usTotalAnnualIncome.toProtobufJSON(options)
        : null,
      usNetPatrimony: this.usNetPatrimony
        ? this.usNetPatrimony.toProtobufJSON(options)
        : null,
      usLiquidPatrimony: this.usLiquidPatrimony
        ? this.usLiquidPatrimony.toProtobufJSON(options)
        : null,
      usSourceFunds: this.usSourceFunds
        ? this.usSourceFunds.toProtobufJSON(options)
        : null,
      nationality: this.nationality
        ? this.nationality.toProtobufJSON(options)
        : null,
      gender: this.gender ? this.gender.toProtobufJSON(options) : null,
      communeName: this.communeName
        ? this.communeName.toProtobufJSON(options)
        : null,
      regionName: this.regionName
        ? this.regionName.toProtobufJSON(options)
        : null,
      postalCode: this.postalCode
        ? this.postalCode.toProtobufJSON(options)
        : null,
      dateOfBirth: this.dateOfBirth
        ? this.dateOfBirth.toProtobufJSON(options)
        : null,
      countryOfBirth: this.countryOfBirth
        ? this.countryOfBirth.toProtobufJSON(options)
        : null,
      civilStatus: this.civilStatus
        ? this.civilStatus.toProtobufJSON(options)
        : null,
      workCommuneId: this.workCommuneId
        ? this.workCommuneId.toProtobufJSON(options)
        : null,
      workCommuneName: this.workCommuneName
        ? this.workCommuneName.toProtobufJSON(options)
        : null,
      workPostalCode: this.workPostalCode
        ? this.workPostalCode.toProtobufJSON(options)
        : null,
      workRegionName: this.workRegionName
        ? this.workRegionName.toProtobufJSON(options)
        : null,
      ibkrId: this.ibkrId ? this.ibkrId.toProtobufJSON(options) : null,
      ibkrUser: this.ibkrUser ? this.ibkrUser.toProtobufJSON(options) : null,
      usEmploymentCountry: this.usEmploymentCountry
        ? this.usEmploymentCountry.toProtobufJSON(options)
        : null,
      usEmploymentCommune: this.usEmploymentCommune
        ? this.usEmploymentCommune.toProtobufJSON(options)
        : null,
      usEmploymentProfession: this.usEmploymentProfession
        ? this.usEmploymentProfession.toProtobufJSON(options)
        : null,
      usCompanyActivity: this.usCompanyActivity
        ? this.usCompanyActivity.toProtobufJSON(options)
        : null,
      usEmploymentStateProvince: this.usEmploymentStateProvince
        ? this.usEmploymentStateProvince.toProtobufJSON(options)
        : null,
      usAddressZipCode: this.usAddressZipCode
        ? this.usAddressZipCode.toProtobufJSON(options)
        : null,
      usWorkAddressZipCode: this.usWorkAddressZipCode
        ? this.usWorkAddressZipCode.toProtobufJSON(options)
        : null,
      usCompanyActivityDetail: this.usCompanyActivityDetail
        ? this.usCompanyActivityDetail.toProtobufJSON(options)
        : null,
      usEmploymentProfessionDetail: this.usEmploymentProfessionDetail
        ? this.usEmploymentProfessionDetail.toProtobufJSON(options)
        : null,
      usEmploymentSituationDetail: this.usEmploymentSituationDetail
        ? this.usEmploymentSituationDetail.toProtobufJSON(options)
        : null,
      usCompliancePhone: this.usCompliancePhone
        ? this.usCompliancePhone.toProtobufJSON(options)
        : null,
      usComplianceEmail: this.usComplianceEmail
        ? this.usComplianceEmail.toProtobufJSON(options)
        : null
    };
  }
}
export module BpUsInscriptionInformationModel {
  /**
   * Standard JavaScript object representation for BpUsInscriptionInformationModel
   */
  export interface AsObject {
    inscriptionId: number;
    userInformationId: number;
    communeId: number;
    regionId: number;
    address: string;
    hasOtherNationality: boolean;
    otherNationality: string;
    usNumberOfPeopleInCharge?: googleProtobuf000.Int32Value.AsObject;
    usEmploymentSituation?: googleProtobuf000.StringValue.AsObject;
    usEmployerName?: googleProtobuf000.StringValue.AsObject;
    usEmploymentAddress?: googleProtobuf000.StringValue.AsObject;
    usTotalAnnualIncome?: googleProtobuf000.StringValue.AsObject;
    usNetPatrimony?: googleProtobuf000.StringValue.AsObject;
    usLiquidPatrimony?: googleProtobuf000.StringValue.AsObject;
    usSourceFunds?: googleProtobuf000.StringValue.AsObject;
    nationality?: googleProtobuf000.StringValue.AsObject;
    gender?: googleProtobuf000.StringValue.AsObject;
    communeName?: googleProtobuf000.StringValue.AsObject;
    regionName?: googleProtobuf000.StringValue.AsObject;
    postalCode?: googleProtobuf000.StringValue.AsObject;
    dateOfBirth?: googleProtobuf001.Timestamp.AsObject;
    countryOfBirth?: googleProtobuf000.StringValue.AsObject;
    civilStatus?: googleProtobuf000.StringValue.AsObject;
    workCommuneId?: googleProtobuf000.Int32Value.AsObject;
    workCommuneName?: googleProtobuf000.StringValue.AsObject;
    workPostalCode?: googleProtobuf000.StringValue.AsObject;
    workRegionName?: googleProtobuf000.StringValue.AsObject;
    ibkrId?: googleProtobuf000.StringValue.AsObject;
    ibkrUser?: googleProtobuf000.StringValue.AsObject;
    usEmploymentCountry?: googleProtobuf000.StringValue.AsObject;
    usEmploymentCommune?: googleProtobuf000.StringValue.AsObject;
    usEmploymentProfession?: googleProtobuf000.StringValue.AsObject;
    usCompanyActivity?: googleProtobuf000.StringValue.AsObject;
    usEmploymentStateProvince?: googleProtobuf000.StringValue.AsObject;
    usAddressZipCode?: googleProtobuf000.StringValue.AsObject;
    usWorkAddressZipCode?: googleProtobuf000.StringValue.AsObject;
    usCompanyActivityDetail?: googleProtobuf000.StringValue.AsObject;
    usEmploymentProfessionDetail?: googleProtobuf000.StringValue.AsObject;
    usEmploymentSituationDetail?: googleProtobuf000.StringValue.AsObject;
    usCompliancePhone?: googleProtobuf000.StringValue.AsObject;
    usComplianceEmail?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for BpUsInscriptionInformationModel
   */
  export interface AsProtobufJSON {
    inscriptionId: number;
    userInformationId: number;
    communeId: number;
    regionId: number;
    address: string;
    hasOtherNationality: boolean;
    otherNationality: string;
    usNumberOfPeopleInCharge: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    usEmploymentSituation: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmployerName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentAddress: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usTotalAnnualIncome: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usNetPatrimony: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usLiquidPatrimony: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usSourceFunds: googleProtobuf000.StringValue.AsProtobufJSON | null;
    nationality: googleProtobuf000.StringValue.AsProtobufJSON | null;
    gender: googleProtobuf000.StringValue.AsProtobufJSON | null;
    communeName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    regionName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    postalCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    dateOfBirth: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    countryOfBirth: googleProtobuf000.StringValue.AsProtobufJSON | null;
    civilStatus: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workCommuneId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    workCommuneName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workPostalCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    workRegionName: googleProtobuf000.StringValue.AsProtobufJSON | null;
    ibkrId: googleProtobuf000.StringValue.AsProtobufJSON | null;
    ibkrUser: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentCountry: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentCommune: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentProfession: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usCompanyActivity: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentStateProvince: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usAddressZipCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usWorkAddressZipCode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usCompanyActivityDetail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentProfessionDetail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usEmploymentSituationDetail: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usCompliancePhone: googleProtobuf000.StringValue.AsProtobufJSON | null;
    usComplianceEmail: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.UserInscriptionFileModel
 */
export class UserInscriptionFileModel implements GrpcMessage {
  static id = 'common_message.UserInscriptionFileModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserInscriptionFileModel();
    UserInscriptionFileModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserInscriptionFileModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.inscriptionId = _instance.inscriptionId || 0;
    _instance.fileStorageId = _instance.fileStorageId || 0;
    _instance.file = _instance.file || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserInscriptionFileModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.inscriptionId = _reader.readInt32();
          break;
        case 5:
          _instance.fileStorageId = _reader.readInt32();
          break;
        case 6:
          _instance.file = new FileStorageModel();
          _reader.readMessage(
            _instance.file,
            FileStorageModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserInscriptionFileModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserInscriptionFileModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.inscriptionId) {
      _writer.writeInt32(4, _instance.inscriptionId);
    }
    if (_instance.fileStorageId) {
      _writer.writeInt32(5, _instance.fileStorageId);
    }
    if (_instance.file) {
      _writer.writeMessage(
        6,
        _instance.file as any,
        FileStorageModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _inscriptionId: number;
  private _fileStorageId: number;
  private _file?: FileStorageModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserInscriptionFileModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserInscriptionFileModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.inscriptionId = _value.inscriptionId;
    this.fileStorageId = _value.fileStorageId;
    this.file = _value.file ? new FileStorageModel(_value.file) : undefined;
    UserInscriptionFileModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get inscriptionId(): number {
    return this._inscriptionId;
  }
  set inscriptionId(value: number) {
    this._inscriptionId = value;
  }
  get fileStorageId(): number {
    return this._fileStorageId;
  }
  set fileStorageId(value: number) {
    this._fileStorageId = value;
  }
  get file(): FileStorageModel | undefined {
    return this._file;
  }
  set file(value: FileStorageModel | undefined) {
    this._file = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserInscriptionFileModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserInscriptionFileModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      inscriptionId: this.inscriptionId,
      fileStorageId: this.fileStorageId,
      file: this.file ? this.file.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserInscriptionFileModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      inscriptionId: this.inscriptionId,
      fileStorageId: this.fileStorageId,
      file: this.file ? this.file.toProtobufJSON(options) : null
    };
  }
}
export module UserInscriptionFileModel {
  /**
   * Standard JavaScript object representation for UserInscriptionFileModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    inscriptionId: number;
    fileStorageId: number;
    file?: FileStorageModel.AsObject;
  }

  /**
   * Protobuf JSON representation for UserInscriptionFileModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    inscriptionId: number;
    fileStorageId: number;
    file: FileStorageModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.FileStorageModel
 */
export class FileStorageModel implements GrpcMessage {
  static id = 'common_message.FileStorageModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FileStorageModel();
    FileStorageModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FileStorageModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.type = _instance.type || '';
    _instance.name = _instance.name || '';
    _instance.fileName = _instance.fileName || '';
    _instance.extension = _instance.extension || '';
    _instance.description = _instance.description || '';
    _instance.size = _instance.size || '';
    _instance.date = _instance.date || undefined;
    _instance.listedAsDocument = _instance.listedAsDocument || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FileStorageModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.type = _reader.readString();
          break;
        case 5:
          _instance.name = _reader.readString();
          break;
        case 6:
          _instance.fileName = _reader.readString();
          break;
        case 7:
          _instance.extension = _reader.readString();
          break;
        case 8:
          _instance.description = _reader.readString();
          break;
        case 9:
          _instance.size = _reader.readString();
          break;
        case 10:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.listedAsDocument = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    FileStorageModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FileStorageModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.type) {
      _writer.writeString(4, _instance.type);
    }
    if (_instance.name) {
      _writer.writeString(5, _instance.name);
    }
    if (_instance.fileName) {
      _writer.writeString(6, _instance.fileName);
    }
    if (_instance.extension) {
      _writer.writeString(7, _instance.extension);
    }
    if (_instance.description) {
      _writer.writeString(8, _instance.description);
    }
    if (_instance.size) {
      _writer.writeString(9, _instance.size);
    }
    if (_instance.date) {
      _writer.writeMessage(
        10,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.listedAsDocument) {
      _writer.writeBool(11, _instance.listedAsDocument);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _type: string;
  private _name: string;
  private _fileName: string;
  private _extension: string;
  private _description: string;
  private _size: string;
  private _date?: googleProtobuf001.Timestamp;
  private _listedAsDocument: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FileStorageModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FileStorageModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.type = _value.type;
    this.name = _value.name;
    this.fileName = _value.fileName;
    this.extension = _value.extension;
    this.description = _value.description;
    this.size = _value.size;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.listedAsDocument = _value.listedAsDocument;
    FileStorageModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get fileName(): string {
    return this._fileName;
  }
  set fileName(value: string) {
    this._fileName = value;
  }
  get extension(): string {
    return this._extension;
  }
  set extension(value: string) {
    this._extension = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get size(): string {
    return this._size;
  }
  set size(value: string) {
    this._size = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get listedAsDocument(): boolean {
    return this._listedAsDocument;
  }
  set listedAsDocument(value: boolean) {
    this._listedAsDocument = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FileStorageModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FileStorageModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      type: this.type,
      name: this.name,
      fileName: this.fileName,
      extension: this.extension,
      description: this.description,
      size: this.size,
      date: this.date ? this.date.toObject() : undefined,
      listedAsDocument: this.listedAsDocument
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FileStorageModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      type: this.type,
      name: this.name,
      fileName: this.fileName,
      extension: this.extension,
      description: this.description,
      size: this.size,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      listedAsDocument: this.listedAsDocument
    };
  }
}
export module FileStorageModel {
  /**
   * Standard JavaScript object representation for FileStorageModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    type: string;
    name: string;
    fileName: string;
    extension: string;
    description: string;
    size: string;
    date?: googleProtobuf001.Timestamp.AsObject;
    listedAsDocument: boolean;
  }

  /**
   * Protobuf JSON representation for FileStorageModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    type: string;
    name: string;
    fileName: string;
    extension: string;
    description: string;
    size: string;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    listedAsDocument: boolean;
  }
}

/**
 * Message implementation for common_message.RoleModel
 */
export class RoleModel implements GrpcMessage {
  static id = 'common_message.RoleModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RoleModel();
    RoleModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RoleModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RoleModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RoleModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: RoleModel, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _name: string;
  private _description: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RoleModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<RoleModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.name = _value.name;
    this.description = _value.description;
    RoleModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RoleModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RoleModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      name: this.name,
      description: this.description
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RoleModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      name: this.name,
      description: this.description
    };
  }
}
export module RoleModel {
  /**
   * Standard JavaScript object representation for RoleModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    name: string;
    description: string;
  }

  /**
   * Protobuf JSON representation for RoleModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    name: string;
    description: string;
  }
}

/**
 * Message implementation for common_message.TransactionProcessingModel
 */
export class TransactionProcessingModel implements GrpcMessage {
  static id = 'common_message.TransactionProcessingModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TransactionProcessingModel();
    TransactionProcessingModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TransactionProcessingModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.state = _instance.state || '';
    _instance.fileId = _instance.fileId || undefined;
    _instance.data = _instance.data || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TransactionProcessingModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.state = _reader.readString();
          break;
        case 5:
          _instance.fileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.fileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.data = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TransactionProcessingModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TransactionProcessingModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.state) {
      _writer.writeString(4, _instance.state);
    }
    if (_instance.fileId) {
      _writer.writeMessage(
        5,
        _instance.fileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.data) {
      _writer.writeString(6, _instance.data);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _state: string;
  private _fileId?: googleProtobuf000.Int32Value;
  private _data: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TransactionProcessingModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<TransactionProcessingModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.state = _value.state;
    this.fileId = _value.fileId
      ? new googleProtobuf000.Int32Value(_value.fileId)
      : undefined;
    this.data = _value.data;
    TransactionProcessingModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get state(): string {
    return this._state;
  }
  set state(value: string) {
    this._state = value;
  }
  get fileId(): googleProtobuf000.Int32Value | undefined {
    return this._fileId;
  }
  set fileId(value: googleProtobuf000.Int32Value | undefined) {
    this._fileId = value;
  }
  get data(): string {
    return this._data;
  }
  set data(value: string) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TransactionProcessingModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TransactionProcessingModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      state: this.state,
      fileId: this.fileId ? this.fileId.toObject() : undefined,
      data: this.data
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TransactionProcessingModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      state: this.state,
      fileId: this.fileId ? this.fileId.toProtobufJSON(options) : null,
      data: this.data
    };
  }
}
export module TransactionProcessingModel {
  /**
   * Standard JavaScript object representation for TransactionProcessingModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    state: string;
    fileId?: googleProtobuf000.Int32Value.AsObject;
    data: string;
  }

  /**
   * Protobuf JSON representation for TransactionProcessingModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    state: string;
    fileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    data: string;
  }
}

/**
 * Message implementation for common_message.BankTransactionModel
 */
export class BankTransactionModel implements GrpcMessage {
  static id = 'common_message.BankTransactionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BankTransactionModel();
    BankTransactionModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BankTransactionModel) {
    _instance.type = _instance.type || 0;
    _instance.amount = _instance.amount || 0;
    _instance.document = _instance.document || '';
    _instance.description = _instance.description || '';
    _instance.account = _instance.account || '';
    _instance.rut = _instance.rut || '';
    _instance.balance = _instance.balance || 0;
    _instance.uuid = _instance.uuid || '';
    _instance.id = _instance.id || 0;
    _instance.date = _instance.date || undefined;
    _instance.userId = _instance.userId || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.financialEntityId = _instance.financialEntityId || undefined;
    _instance.goalTransactionId = _instance.goalTransactionId || undefined;
    _instance.goalId = _instance.goalId || undefined;
    _instance.status = _instance.status || '';
    _instance.goal = _instance.goal || undefined;
    _instance.errorDescription = _instance.errorDescription || '';
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.transactionRequestId =
      _instance.transactionRequestId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BankTransactionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.amount = _reader.readInt32();
          break;
        case 3:
          _instance.document = _reader.readString();
          break;
        case 4:
          _instance.description = _reader.readString();
          break;
        case 5:
          _instance.account = _reader.readString();
          break;
        case 6:
          _instance.rut = _reader.readString();
          break;
        case 7:
          _instance.balance = _reader.readInt32();
          break;
        case 8:
          _instance.uuid = _reader.readString();
          break;
        case 9:
          _instance.id = _reader.readInt32();
          break;
        case 10:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.userId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.financialEntityId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.financialEntityId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.goalTransactionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalTransactionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.goalId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.status = _reader.readString();
          break;
        case 18:
          _instance.goal = new GoalModel();
          _reader.readMessage(
            _instance.goal,
            GoalModel.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.errorDescription = _reader.readString();
          break;
        case 20:
          _instance.currencyId = _reader.readInt32();
          break;
        case 21:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 22:
          _instance.transactionRequestId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.transactionRequestId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BankTransactionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BankTransactionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.amount) {
      _writer.writeInt32(2, _instance.amount);
    }
    if (_instance.document) {
      _writer.writeString(3, _instance.document);
    }
    if (_instance.description) {
      _writer.writeString(4, _instance.description);
    }
    if (_instance.account) {
      _writer.writeString(5, _instance.account);
    }
    if (_instance.rut) {
      _writer.writeString(6, _instance.rut);
    }
    if (_instance.balance) {
      _writer.writeInt32(7, _instance.balance);
    }
    if (_instance.uuid) {
      _writer.writeString(8, _instance.uuid);
    }
    if (_instance.id) {
      _writer.writeInt32(9, _instance.id);
    }
    if (_instance.date) {
      _writer.writeMessage(
        10,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.userId) {
      _writer.writeMessage(
        11,
        _instance.userId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        12,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        13,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeMessage(
        14,
        _instance.financialEntityId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.goalTransactionId) {
      _writer.writeMessage(
        15,
        _instance.goalTransactionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.goalId) {
      _writer.writeMessage(
        16,
        _instance.goalId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeString(17, _instance.status);
    }
    if (_instance.goal) {
      _writer.writeMessage(
        18,
        _instance.goal as any,
        GoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.errorDescription) {
      _writer.writeString(19, _instance.errorDescription);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(20, _instance.currencyId);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        21,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.transactionRequestId) {
      _writer.writeMessage(
        22,
        _instance.transactionRequestId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _type: goal_transaction_type_enums003.GoalTransactionType;
  private _amount: number;
  private _document: string;
  private _description: string;
  private _account: string;
  private _rut: string;
  private _balance: number;
  private _uuid: string;
  private _id: number;
  private _date?: googleProtobuf001.Timestamp;
  private _userId?: googleProtobuf000.Int32Value;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _financialEntityId?: googleProtobuf000.Int32Value;
  private _goalTransactionId?: googleProtobuf000.Int32Value;
  private _goalId?: googleProtobuf000.Int32Value;
  private _status: string;
  private _goal?: GoalModel;
  private _errorDescription: string;
  private _currencyId: number;
  private _currency?: CurrencyModel;
  private _transactionRequestId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BankTransactionModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BankTransactionModel.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.amount = _value.amount;
    this.document = _value.document;
    this.description = _value.description;
    this.account = _value.account;
    this.rut = _value.rut;
    this.balance = _value.balance;
    this.uuid = _value.uuid;
    this.id = _value.id;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.userId = _value.userId
      ? new googleProtobuf000.Int32Value(_value.userId)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.financialEntityId = _value.financialEntityId
      ? new googleProtobuf000.Int32Value(_value.financialEntityId)
      : undefined;
    this.goalTransactionId = _value.goalTransactionId
      ? new googleProtobuf000.Int32Value(_value.goalTransactionId)
      : undefined;
    this.goalId = _value.goalId
      ? new googleProtobuf000.Int32Value(_value.goalId)
      : undefined;
    this.status = _value.status;
    this.goal = _value.goal ? new GoalModel(_value.goal) : undefined;
    this.errorDescription = _value.errorDescription;
    this.currencyId = _value.currencyId;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.transactionRequestId = _value.transactionRequestId
      ? new googleProtobuf000.Int32Value(_value.transactionRequestId)
      : undefined;
    BankTransactionModel.refineValues(this);
  }
  get type(): goal_transaction_type_enums003.GoalTransactionType {
    return this._type;
  }
  set type(value: goal_transaction_type_enums003.GoalTransactionType) {
    this._type = value;
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get document(): string {
    return this._document;
  }
  set document(value: string) {
    this._document = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get account(): string {
    return this._account;
  }
  set account(value: string) {
    this._account = value;
  }
  get rut(): string {
    return this._rut;
  }
  set rut(value: string) {
    this._rut = value;
  }
  get balance(): number {
    return this._balance;
  }
  set balance(value: number) {
    this._balance = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get userId(): googleProtobuf000.Int32Value | undefined {
    return this._userId;
  }
  set userId(value: googleProtobuf000.Int32Value | undefined) {
    this._userId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get financialEntityId(): googleProtobuf000.Int32Value | undefined {
    return this._financialEntityId;
  }
  set financialEntityId(value: googleProtobuf000.Int32Value | undefined) {
    this._financialEntityId = value;
  }
  get goalTransactionId(): googleProtobuf000.Int32Value | undefined {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalTransactionId = value;
  }
  get goalId(): googleProtobuf000.Int32Value | undefined {
    return this._goalId;
  }
  set goalId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalId = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get goal(): GoalModel | undefined {
    return this._goal;
  }
  set goal(value: GoalModel | undefined) {
    this._goal = value;
  }
  get errorDescription(): string {
    return this._errorDescription;
  }
  set errorDescription(value: string) {
    this._errorDescription = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get transactionRequestId(): googleProtobuf000.Int32Value | undefined {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: googleProtobuf000.Int32Value | undefined) {
    this._transactionRequestId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BankTransactionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BankTransactionModel.AsObject {
    return {
      type: this.type,
      amount: this.amount,
      document: this.document,
      description: this.description,
      account: this.account,
      rut: this.rut,
      balance: this.balance,
      uuid: this.uuid,
      id: this.id,
      date: this.date ? this.date.toObject() : undefined,
      userId: this.userId ? this.userId.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toObject()
        : undefined,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toObject()
        : undefined,
      goalId: this.goalId ? this.goalId.toObject() : undefined,
      status: this.status,
      goal: this.goal ? this.goal.toObject() : undefined,
      errorDescription: this.errorDescription,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toObject() : undefined,
      transactionRequestId: this.transactionRequestId
        ? this.transactionRequestId.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BankTransactionModel.AsProtobufJSON {
    return {
      type:
        goal_transaction_type_enums003.GoalTransactionType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      amount: this.amount,
      document: this.document,
      description: this.description,
      account: this.account,
      rut: this.rut,
      balance: this.balance,
      uuid: this.uuid,
      id: this.id,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      userId: this.userId ? this.userId.toProtobufJSON(options) : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      financialEntityId: this.financialEntityId
        ? this.financialEntityId.toProtobufJSON(options)
        : null,
      goalTransactionId: this.goalTransactionId
        ? this.goalTransactionId.toProtobufJSON(options)
        : null,
      goalId: this.goalId ? this.goalId.toProtobufJSON(options) : null,
      status: this.status,
      goal: this.goal ? this.goal.toProtobufJSON(options) : null,
      errorDescription: this.errorDescription,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      transactionRequestId: this.transactionRequestId
        ? this.transactionRequestId.toProtobufJSON(options)
        : null
    };
  }
}
export module BankTransactionModel {
  /**
   * Standard JavaScript object representation for BankTransactionModel
   */
  export interface AsObject {
    type: goal_transaction_type_enums003.GoalTransactionType;
    amount: number;
    document: string;
    description: string;
    account: string;
    rut: string;
    balance: number;
    uuid: string;
    id: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    userId?: googleProtobuf000.Int32Value.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    financialEntityId?: googleProtobuf000.Int32Value.AsObject;
    goalTransactionId?: googleProtobuf000.Int32Value.AsObject;
    goalId?: googleProtobuf000.Int32Value.AsObject;
    status: string;
    goal?: GoalModel.AsObject;
    errorDescription: string;
    currencyId: number;
    currency?: CurrencyModel.AsObject;
    transactionRequestId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for BankTransactionModel
   */
  export interface AsProtobufJSON {
    type: string;
    amount: number;
    document: string;
    description: string;
    account: string;
    rut: string;
    balance: number;
    uuid: string;
    id: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    userId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    financialEntityId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    goalTransactionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    goalId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    status: string;
    goal: GoalModel.AsProtobufJSON | null;
    errorDescription: string;
    currencyId: number;
    currency: CurrencyModel.AsProtobufJSON | null;
    transactionRequestId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.CountryModel
 */
export class CountryModel implements GrpcMessage {
  static id = 'common_message.CountryModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CountryModel();
    CountryModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CountryModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.description = _instance.description || '';
    _instance.isoCode = _instance.isoCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CountryModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.description = _reader.readString();
          break;
        case 5:
          _instance.isoCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CountryModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CountryModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(4, _instance.description);
    }
    if (_instance.isoCode) {
      _writer.writeString(5, _instance.isoCode);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _description: string;
  private _isoCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CountryModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<CountryModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.description = _value.description;
    this.isoCode = _value.isoCode;
    CountryModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get isoCode(): string {
    return this._isoCode;
  }
  set isoCode(value: string) {
    this._isoCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CountryModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CountryModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      description: this.description,
      isoCode: this.isoCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CountryModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      description: this.description,
      isoCode: this.isoCode
    };
  }
}
export module CountryModel {
  /**
   * Standard JavaScript object representation for CountryModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    description: string;
    isoCode: string;
  }

  /**
   * Protobuf JSON representation for CountryModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    description: string;
    isoCode: string;
  }
}

/**
 * Message implementation for common_message.ExternalTransactionFundingModel
 */
export class ExternalTransactionFundingModel implements GrpcMessage {
  static id = 'common_message.ExternalTransactionFundingModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExternalTransactionFundingModel();
    ExternalTransactionFundingModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExternalTransactionFundingModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.idOperation = _instance.idOperation || 0;
    _instance.idInvoice = _instance.idInvoice || '';
    _instance.idOrder = _instance.idOrder || '';
    _instance.price = _instance.price || 0;
    _instance.type = _instance.type || '';
    _instance.quotas = _instance.quotas || 0;
    _instance.assignedQuotas = _instance.assignedQuotas || 0;
    _instance.account = _instance.account || '';
    _instance.clientName = _instance.clientName || '';
    _instance.rut = _instance.rut || '';
    _instance.raw = _instance.raw || '';
    _instance.mnemonic = _instance.mnemonic || '';
    _instance.rutWithoutCheckDigit = _instance.rutWithoutCheckDigit || '';
    _instance.date = _instance.date || undefined;
    _instance.operationDate = _instance.operationDate || undefined;
    _instance.settlementDate = _instance.settlementDate || undefined;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.fundingId = _instance.fundingId || 0;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.userId = _instance.userId || 0;
    _instance.funding = _instance.funding || undefined;
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.user = _instance.user || undefined;
    _instance.externalTransactionFundingAssigns =
      _instance.externalTransactionFundingAssigns || [];
    _instance.amount = _instance.amount || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.descriptionOperation = _instance.descriptionOperation || '';
    _instance.typeOperation = _instance.typeOperation || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExternalTransactionFundingModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.idOperation = _reader.readInt32();
          break;
        case 5:
          _instance.idInvoice = _reader.readString();
          break;
        case 6:
          _instance.idOrder = _reader.readString();
          break;
        case 7:
          _instance.price = _reader.readDouble();
          break;
        case 8:
          _instance.type = _reader.readString();
          break;
        case 9:
          _instance.quotas = _reader.readDouble();
          break;
        case 10:
          _instance.assignedQuotas = _reader.readDouble();
          break;
        case 11:
          _instance.account = _reader.readString();
          break;
        case 12:
          _instance.clientName = _reader.readString();
          break;
        case 13:
          _instance.rut = _reader.readString();
          break;
        case 14:
          _instance.raw = _reader.readString();
          break;
        case 15:
          _instance.mnemonic = _reader.readString();
          break;
        case 16:
          _instance.rutWithoutCheckDigit = _reader.readString();
          break;
        case 17:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.operationDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.operationDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.settlementDate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.settlementDate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 20:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 21:
          _instance.fundingId = _reader.readInt32();
          break;
        case 22:
          _instance.currencyId = _reader.readInt32();
          break;
        case 23:
          _instance.userId = _reader.readInt32();
          break;
        case 24:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 25:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 26:
          _instance.user = new UserModel();
          _reader.readMessage(
            _instance.user,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 27:
          const messageInitializer27 = new ExternalTransactionFundingAssignModel();
          _reader.readMessage(
            messageInitializer27,
            ExternalTransactionFundingAssignModel.deserializeBinaryFromReader
          );
          (_instance.externalTransactionFundingAssigns =
            _instance.externalTransactionFundingAssigns || []).push(
            messageInitializer27
          );
          break;
        case 28:
          _instance.amount = _reader.readDouble();
          break;
        case 29:
          _instance.currency = new CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 30:
          _instance.descriptionOperation = _reader.readString();
          break;
        case 31:
          _instance.typeOperation = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ExternalTransactionFundingModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExternalTransactionFundingModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.idOperation) {
      _writer.writeInt32(4, _instance.idOperation);
    }
    if (_instance.idInvoice) {
      _writer.writeString(5, _instance.idInvoice);
    }
    if (_instance.idOrder) {
      _writer.writeString(6, _instance.idOrder);
    }
    if (_instance.price) {
      _writer.writeDouble(7, _instance.price);
    }
    if (_instance.type) {
      _writer.writeString(8, _instance.type);
    }
    if (_instance.quotas) {
      _writer.writeDouble(9, _instance.quotas);
    }
    if (_instance.assignedQuotas) {
      _writer.writeDouble(10, _instance.assignedQuotas);
    }
    if (_instance.account) {
      _writer.writeString(11, _instance.account);
    }
    if (_instance.clientName) {
      _writer.writeString(12, _instance.clientName);
    }
    if (_instance.rut) {
      _writer.writeString(13, _instance.rut);
    }
    if (_instance.raw) {
      _writer.writeString(14, _instance.raw);
    }
    if (_instance.mnemonic) {
      _writer.writeString(15, _instance.mnemonic);
    }
    if (_instance.rutWithoutCheckDigit) {
      _writer.writeString(16, _instance.rutWithoutCheckDigit);
    }
    if (_instance.date) {
      _writer.writeMessage(
        17,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.operationDate) {
      _writer.writeMessage(
        18,
        _instance.operationDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.settlementDate) {
      _writer.writeMessage(
        19,
        _instance.settlementDate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(20, _instance.financialEntityId);
    }
    if (_instance.fundingId) {
      _writer.writeInt32(21, _instance.fundingId);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(22, _instance.currencyId);
    }
    if (_instance.userId) {
      _writer.writeInt32(23, _instance.userId);
    }
    if (_instance.funding) {
      _writer.writeMessage(
        24,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        25,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.user) {
      _writer.writeMessage(
        26,
        _instance.user as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (
      _instance.externalTransactionFundingAssigns &&
      _instance.externalTransactionFundingAssigns.length
    ) {
      _writer.writeRepeatedMessage(
        27,
        _instance.externalTransactionFundingAssigns as any,
        ExternalTransactionFundingAssignModel.serializeBinaryToWriter
      );
    }
    if (_instance.amount) {
      _writer.writeDouble(28, _instance.amount);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        29,
        _instance.currency as any,
        CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.descriptionOperation) {
      _writer.writeString(30, _instance.descriptionOperation);
    }
    if (_instance.typeOperation) {
      _writer.writeString(31, _instance.typeOperation);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _idOperation: number;
  private _idInvoice: string;
  private _idOrder: string;
  private _price: number;
  private _type: string;
  private _quotas: number;
  private _assignedQuotas: number;
  private _account: string;
  private _clientName: string;
  private _rut: string;
  private _raw: string;
  private _mnemonic: string;
  private _rutWithoutCheckDigit: string;
  private _date?: googleProtobuf001.Timestamp;
  private _operationDate?: googleProtobuf001.Timestamp;
  private _settlementDate?: googleProtobuf001.Timestamp;
  private _financialEntityId: number;
  private _fundingId: number;
  private _currencyId: number;
  private _userId: number;
  private _funding?: FundingModel;
  private _financialEntity?: FinancialEntityModel;
  private _user?: UserModel;
  private _externalTransactionFundingAssigns?: ExternalTransactionFundingAssignModel[];
  private _amount: number;
  private _currency?: CurrencyModel;
  private _descriptionOperation: string;
  private _typeOperation: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExternalTransactionFundingModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ExternalTransactionFundingModel.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.idOperation = _value.idOperation;
    this.idInvoice = _value.idInvoice;
    this.idOrder = _value.idOrder;
    this.price = _value.price;
    this.type = _value.type;
    this.quotas = _value.quotas;
    this.assignedQuotas = _value.assignedQuotas;
    this.account = _value.account;
    this.clientName = _value.clientName;
    this.rut = _value.rut;
    this.raw = _value.raw;
    this.mnemonic = _value.mnemonic;
    this.rutWithoutCheckDigit = _value.rutWithoutCheckDigit;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.operationDate = _value.operationDate
      ? new googleProtobuf001.Timestamp(_value.operationDate)
      : undefined;
    this.settlementDate = _value.settlementDate
      ? new googleProtobuf001.Timestamp(_value.settlementDate)
      : undefined;
    this.financialEntityId = _value.financialEntityId;
    this.fundingId = _value.fundingId;
    this.currencyId = _value.currencyId;
    this.userId = _value.userId;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.user = _value.user ? new UserModel(_value.user) : undefined;
    this.externalTransactionFundingAssigns = (
      _value.externalTransactionFundingAssigns || []
    ).map(m => new ExternalTransactionFundingAssignModel(m));
    this.amount = _value.amount;
    this.currency = _value.currency
      ? new CurrencyModel(_value.currency)
      : undefined;
    this.descriptionOperation = _value.descriptionOperation;
    this.typeOperation = _value.typeOperation;
    ExternalTransactionFundingModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get idOperation(): number {
    return this._idOperation;
  }
  set idOperation(value: number) {
    this._idOperation = value;
  }
  get idInvoice(): string {
    return this._idInvoice;
  }
  set idInvoice(value: string) {
    this._idInvoice = value;
  }
  get idOrder(): string {
    return this._idOrder;
  }
  set idOrder(value: string) {
    this._idOrder = value;
  }
  get price(): number {
    return this._price;
  }
  set price(value: number) {
    this._price = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get quotas(): number {
    return this._quotas;
  }
  set quotas(value: number) {
    this._quotas = value;
  }
  get assignedQuotas(): number {
    return this._assignedQuotas;
  }
  set assignedQuotas(value: number) {
    this._assignedQuotas = value;
  }
  get account(): string {
    return this._account;
  }
  set account(value: string) {
    this._account = value;
  }
  get clientName(): string {
    return this._clientName;
  }
  set clientName(value: string) {
    this._clientName = value;
  }
  get rut(): string {
    return this._rut;
  }
  set rut(value: string) {
    this._rut = value;
  }
  get raw(): string {
    return this._raw;
  }
  set raw(value: string) {
    this._raw = value;
  }
  get mnemonic(): string {
    return this._mnemonic;
  }
  set mnemonic(value: string) {
    this._mnemonic = value;
  }
  get rutWithoutCheckDigit(): string {
    return this._rutWithoutCheckDigit;
  }
  set rutWithoutCheckDigit(value: string) {
    this._rutWithoutCheckDigit = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get operationDate(): googleProtobuf001.Timestamp | undefined {
    return this._operationDate;
  }
  set operationDate(value: googleProtobuf001.Timestamp | undefined) {
    this._operationDate = value;
  }
  get settlementDate(): googleProtobuf001.Timestamp | undefined {
    return this._settlementDate;
  }
  set settlementDate(value: googleProtobuf001.Timestamp | undefined) {
    this._settlementDate = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }
  get user(): UserModel | undefined {
    return this._user;
  }
  set user(value: UserModel | undefined) {
    this._user = value;
  }
  get externalTransactionFundingAssigns():
    | ExternalTransactionFundingAssignModel[]
    | undefined {
    return this._externalTransactionFundingAssigns;
  }
  set externalTransactionFundingAssigns(
    value: ExternalTransactionFundingAssignModel[] | undefined
  ) {
    this._externalTransactionFundingAssigns = value;
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get currency(): CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: CurrencyModel | undefined) {
    this._currency = value;
  }
  get descriptionOperation(): string {
    return this._descriptionOperation;
  }
  set descriptionOperation(value: string) {
    this._descriptionOperation = value;
  }
  get typeOperation(): string {
    return this._typeOperation;
  }
  set typeOperation(value: string) {
    this._typeOperation = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExternalTransactionFundingModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExternalTransactionFundingModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      idOperation: this.idOperation,
      idInvoice: this.idInvoice,
      idOrder: this.idOrder,
      price: this.price,
      type: this.type,
      quotas: this.quotas,
      assignedQuotas: this.assignedQuotas,
      account: this.account,
      clientName: this.clientName,
      rut: this.rut,
      raw: this.raw,
      mnemonic: this.mnemonic,
      rutWithoutCheckDigit: this.rutWithoutCheckDigit,
      date: this.date ? this.date.toObject() : undefined,
      operationDate: this.operationDate
        ? this.operationDate.toObject()
        : undefined,
      settlementDate: this.settlementDate
        ? this.settlementDate.toObject()
        : undefined,
      financialEntityId: this.financialEntityId,
      fundingId: this.fundingId,
      currencyId: this.currencyId,
      userId: this.userId,
      funding: this.funding ? this.funding.toObject() : undefined,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      user: this.user ? this.user.toObject() : undefined,
      externalTransactionFundingAssigns: (
        this.externalTransactionFundingAssigns || []
      ).map(m => m.toObject()),
      amount: this.amount,
      currency: this.currency ? this.currency.toObject() : undefined,
      descriptionOperation: this.descriptionOperation,
      typeOperation: this.typeOperation
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExternalTransactionFundingModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      idOperation: this.idOperation,
      idInvoice: this.idInvoice,
      idOrder: this.idOrder,
      price: this.price,
      type: this.type,
      quotas: this.quotas,
      assignedQuotas: this.assignedQuotas,
      account: this.account,
      clientName: this.clientName,
      rut: this.rut,
      raw: this.raw,
      mnemonic: this.mnemonic,
      rutWithoutCheckDigit: this.rutWithoutCheckDigit,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      operationDate: this.operationDate
        ? this.operationDate.toProtobufJSON(options)
        : null,
      settlementDate: this.settlementDate
        ? this.settlementDate.toProtobufJSON(options)
        : null,
      financialEntityId: this.financialEntityId,
      fundingId: this.fundingId,
      currencyId: this.currencyId,
      userId: this.userId,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      externalTransactionFundingAssigns: (
        this.externalTransactionFundingAssigns || []
      ).map(m => m.toProtobufJSON(options)),
      amount: this.amount,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      descriptionOperation: this.descriptionOperation,
      typeOperation: this.typeOperation
    };
  }
}
export module ExternalTransactionFundingModel {
  /**
   * Standard JavaScript object representation for ExternalTransactionFundingModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    idOperation: number;
    idInvoice: string;
    idOrder: string;
    price: number;
    type: string;
    quotas: number;
    assignedQuotas: number;
    account: string;
    clientName: string;
    rut: string;
    raw: string;
    mnemonic: string;
    rutWithoutCheckDigit: string;
    date?: googleProtobuf001.Timestamp.AsObject;
    operationDate?: googleProtobuf001.Timestamp.AsObject;
    settlementDate?: googleProtobuf001.Timestamp.AsObject;
    financialEntityId: number;
    fundingId: number;
    currencyId: number;
    userId: number;
    funding?: FundingModel.AsObject;
    financialEntity?: FinancialEntityModel.AsObject;
    user?: UserModel.AsObject;
    externalTransactionFundingAssigns?: ExternalTransactionFundingAssignModel.AsObject[];
    amount: number;
    currency?: CurrencyModel.AsObject;
    descriptionOperation: string;
    typeOperation: string;
  }

  /**
   * Protobuf JSON representation for ExternalTransactionFundingModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    idOperation: number;
    idInvoice: string;
    idOrder: string;
    price: number;
    type: string;
    quotas: number;
    assignedQuotas: number;
    account: string;
    clientName: string;
    rut: string;
    raw: string;
    mnemonic: string;
    rutWithoutCheckDigit: string;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    operationDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    settlementDate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    financialEntityId: number;
    fundingId: number;
    currencyId: number;
    userId: number;
    funding: FundingModel.AsProtobufJSON | null;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
    user: UserModel.AsProtobufJSON | null;
    externalTransactionFundingAssigns:
      | ExternalTransactionFundingAssignModel.AsProtobufJSON[]
      | null;
    amount: number;
    currency: CurrencyModel.AsProtobufJSON | null;
    descriptionOperation: string;
    typeOperation: string;
  }
}

/**
 * Message implementation for common_message.ExternalTransactionFundingAssignModel
 */
export class ExternalTransactionFundingAssignModel implements GrpcMessage {
  static id = 'common_message.ExternalTransactionFundingAssignModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExternalTransactionFundingAssignModel();
    ExternalTransactionFundingAssignModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExternalTransactionFundingAssignModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.externalTransactionFundingId =
      _instance.externalTransactionFundingId || 0;
    _instance.goalTransactionFundingId =
      _instance.goalTransactionFundingId || 0;
    _instance.goalTransactionFunding =
      _instance.goalTransactionFunding || undefined;
    _instance.externalTransactionFunding =
      _instance.externalTransactionFunding || undefined;
    _instance.quotas = _instance.quotas || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExternalTransactionFundingAssignModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.externalTransactionFundingId = _reader.readInt32();
          break;
        case 5:
          _instance.goalTransactionFundingId = _reader.readInt32();
          break;
        case 6:
          _instance.goalTransactionFunding = new GoalTransactionFundingModel();
          _reader.readMessage(
            _instance.goalTransactionFunding,
            GoalTransactionFundingModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.externalTransactionFunding = new ExternalTransactionFundingModel();
          _reader.readMessage(
            _instance.externalTransactionFunding,
            ExternalTransactionFundingModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.quotas = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    ExternalTransactionFundingAssignModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExternalTransactionFundingAssignModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.externalTransactionFundingId) {
      _writer.writeInt32(4, _instance.externalTransactionFundingId);
    }
    if (_instance.goalTransactionFundingId) {
      _writer.writeInt32(5, _instance.goalTransactionFundingId);
    }
    if (_instance.goalTransactionFunding) {
      _writer.writeMessage(
        6,
        _instance.goalTransactionFunding as any,
        GoalTransactionFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.externalTransactionFunding) {
      _writer.writeMessage(
        7,
        _instance.externalTransactionFunding as any,
        ExternalTransactionFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.quotas) {
      _writer.writeDouble(8, _instance.quotas);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _externalTransactionFundingId: number;
  private _goalTransactionFundingId: number;
  private _goalTransactionFunding?: GoalTransactionFundingModel;
  private _externalTransactionFunding?: ExternalTransactionFundingModel;
  private _quotas: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExternalTransactionFundingAssignModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ExternalTransactionFundingAssignModel.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.externalTransactionFundingId = _value.externalTransactionFundingId;
    this.goalTransactionFundingId = _value.goalTransactionFundingId;
    this.goalTransactionFunding = _value.goalTransactionFunding
      ? new GoalTransactionFundingModel(_value.goalTransactionFunding)
      : undefined;
    this.externalTransactionFunding = _value.externalTransactionFunding
      ? new ExternalTransactionFundingModel(_value.externalTransactionFunding)
      : undefined;
    this.quotas = _value.quotas;
    ExternalTransactionFundingAssignModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get externalTransactionFundingId(): number {
    return this._externalTransactionFundingId;
  }
  set externalTransactionFundingId(value: number) {
    this._externalTransactionFundingId = value;
  }
  get goalTransactionFundingId(): number {
    return this._goalTransactionFundingId;
  }
  set goalTransactionFundingId(value: number) {
    this._goalTransactionFundingId = value;
  }
  get goalTransactionFunding(): GoalTransactionFundingModel | undefined {
    return this._goalTransactionFunding;
  }
  set goalTransactionFunding(value: GoalTransactionFundingModel | undefined) {
    this._goalTransactionFunding = value;
  }
  get externalTransactionFunding():
    | ExternalTransactionFundingModel
    | undefined {
    return this._externalTransactionFunding;
  }
  set externalTransactionFunding(
    value: ExternalTransactionFundingModel | undefined
  ) {
    this._externalTransactionFunding = value;
  }
  get quotas(): number {
    return this._quotas;
  }
  set quotas(value: number) {
    this._quotas = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExternalTransactionFundingAssignModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExternalTransactionFundingAssignModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      externalTransactionFundingId: this.externalTransactionFundingId,
      goalTransactionFundingId: this.goalTransactionFundingId,
      goalTransactionFunding: this.goalTransactionFunding
        ? this.goalTransactionFunding.toObject()
        : undefined,
      externalTransactionFunding: this.externalTransactionFunding
        ? this.externalTransactionFunding.toObject()
        : undefined,
      quotas: this.quotas
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExternalTransactionFundingAssignModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      externalTransactionFundingId: this.externalTransactionFundingId,
      goalTransactionFundingId: this.goalTransactionFundingId,
      goalTransactionFunding: this.goalTransactionFunding
        ? this.goalTransactionFunding.toProtobufJSON(options)
        : null,
      externalTransactionFunding: this.externalTransactionFunding
        ? this.externalTransactionFunding.toProtobufJSON(options)
        : null,
      quotas: this.quotas
    };
  }
}
export module ExternalTransactionFundingAssignModel {
  /**
   * Standard JavaScript object representation for ExternalTransactionFundingAssignModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    externalTransactionFundingId: number;
    goalTransactionFundingId: number;
    goalTransactionFunding?: GoalTransactionFundingModel.AsObject;
    externalTransactionFunding?: ExternalTransactionFundingModel.AsObject;
    quotas: number;
  }

  /**
   * Protobuf JSON representation for ExternalTransactionFundingAssignModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    externalTransactionFundingId: number;
    goalTransactionFundingId: number;
    goalTransactionFunding: GoalTransactionFundingModel.AsProtobufJSON | null;
    externalTransactionFunding: ExternalTransactionFundingModel.AsProtobufJSON | null;
    quotas: number;
  }
}

/**
 * Message implementation for common_message.GoalProfitabilityValueModel
 */
export class GoalProfitabilityValueModel implements GrpcMessage {
  static id = 'common_message.GoalProfitabilityValueModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalProfitabilityValueModel();
    GoalProfitabilityValueModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalProfitabilityValueModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.value = _instance.value || 0;
    _instance.date = _instance.date || undefined;
    _instance.type = _instance.type || '';
    _instance.goalId = _instance.goalId || undefined;
    _instance.fundingId = _instance.fundingId || undefined;
    _instance.ownerId = _instance.ownerId || 0;
    _instance.funding = _instance.funding || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalProfitabilityValueModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.value = _reader.readDouble();
          break;
        case 5:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.type = _reader.readString();
          break;
        case 7:
          _instance.goalId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.fundingId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.fundingId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.ownerId = _reader.readInt32();
          break;
        case 10:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GoalProfitabilityValueModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalProfitabilityValueModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.value) {
      _writer.writeDouble(4, _instance.value);
    }
    if (_instance.date) {
      _writer.writeMessage(
        5,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.type) {
      _writer.writeString(6, _instance.type);
    }
    if (_instance.goalId) {
      _writer.writeMessage(
        7,
        _instance.goalId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.fundingId) {
      _writer.writeMessage(
        8,
        _instance.fundingId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.ownerId) {
      _writer.writeInt32(9, _instance.ownerId);
    }
    if (_instance.funding) {
      _writer.writeMessage(
        10,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _value: number;
  private _date?: googleProtobuf001.Timestamp;
  private _type: string;
  private _goalId?: googleProtobuf000.Int32Value;
  private _fundingId?: googleProtobuf000.Int32Value;
  private _ownerId: number;
  private _funding?: FundingModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalProfitabilityValueModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalProfitabilityValueModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.value = _value.value;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.type = _value.type;
    this.goalId = _value.goalId
      ? new googleProtobuf000.Int32Value(_value.goalId)
      : undefined;
    this.fundingId = _value.fundingId
      ? new googleProtobuf000.Int32Value(_value.fundingId)
      : undefined;
    this.ownerId = _value.ownerId;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    GoalProfitabilityValueModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get goalId(): googleProtobuf000.Int32Value | undefined {
    return this._goalId;
  }
  set goalId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalId = value;
  }
  get fundingId(): googleProtobuf000.Int32Value | undefined {
    return this._fundingId;
  }
  set fundingId(value: googleProtobuf000.Int32Value | undefined) {
    this._fundingId = value;
  }
  get ownerId(): number {
    return this._ownerId;
  }
  set ownerId(value: number) {
    this._ownerId = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalProfitabilityValueModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalProfitabilityValueModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      value: this.value,
      date: this.date ? this.date.toObject() : undefined,
      type: this.type,
      goalId: this.goalId ? this.goalId.toObject() : undefined,
      fundingId: this.fundingId ? this.fundingId.toObject() : undefined,
      ownerId: this.ownerId,
      funding: this.funding ? this.funding.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalProfitabilityValueModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      value: this.value,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      type: this.type,
      goalId: this.goalId ? this.goalId.toProtobufJSON(options) : null,
      fundingId: this.fundingId ? this.fundingId.toProtobufJSON(options) : null,
      ownerId: this.ownerId,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null
    };
  }
}
export module GoalProfitabilityValueModel {
  /**
   * Standard JavaScript object representation for GoalProfitabilityValueModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    value: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    type: string;
    goalId?: googleProtobuf000.Int32Value.AsObject;
    fundingId?: googleProtobuf000.Int32Value.AsObject;
    ownerId: number;
    funding?: FundingModel.AsObject;
  }

  /**
   * Protobuf JSON representation for GoalProfitabilityValueModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    value: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    type: string;
    goalId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    fundingId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    ownerId: number;
    funding: FundingModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.InternalNotificationEventConfigurationModel
 */
export class InternalNotificationEventConfigurationModel
  implements GrpcMessage {
  static id = 'common_message.InternalNotificationEventConfigurationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new InternalNotificationEventConfigurationModel();
    InternalNotificationEventConfigurationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: InternalNotificationEventConfigurationModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.notificationTitle = _instance.notificationTitle || '';
    _instance.notificationDescription = _instance.notificationDescription || '';
    _instance.type = _instance.type || 0;
    _instance.idChannel = _instance.idChannel || '';
    _instance.titleChannel = _instance.titleChannel || '';
    _instance.idTeam = _instance.idTeam || '';
    _instance.titleTeam = _instance.titleTeam || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: InternalNotificationEventConfigurationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.notificationTitle = _reader.readString();
          break;
        case 7:
          _instance.notificationDescription = _reader.readString();
          break;
        case 8:
          _instance.type = _reader.readEnum();
          break;
        case 9:
          _instance.idChannel = _reader.readString();
          break;
        case 10:
          _instance.titleChannel = _reader.readString();
          break;
        case 11:
          _instance.idTeam = _reader.readString();
          break;
        case 12:
          _instance.titleTeam = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    InternalNotificationEventConfigurationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: InternalNotificationEventConfigurationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.notificationTitle) {
      _writer.writeString(6, _instance.notificationTitle);
    }
    if (_instance.notificationDescription) {
      _writer.writeString(7, _instance.notificationDescription);
    }
    if (_instance.type) {
      _writer.writeEnum(8, _instance.type);
    }
    if (_instance.idChannel) {
      _writer.writeString(9, _instance.idChannel);
    }
    if (_instance.titleChannel) {
      _writer.writeString(10, _instance.titleChannel);
    }
    if (_instance.idTeam) {
      _writer.writeString(11, _instance.idTeam);
    }
    if (_instance.titleTeam) {
      _writer.writeString(12, _instance.titleTeam);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _name: string;
  private _description: string;
  private _notificationTitle: string;
  private _notificationDescription: string;
  private _type: InternalNotificationEventConfigurationType;
  private _idChannel: string;
  private _titleChannel: string;
  private _idTeam: string;
  private _titleTeam: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of InternalNotificationEventConfigurationModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      InternalNotificationEventConfigurationModel.AsObject
    >
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.name = _value.name;
    this.description = _value.description;
    this.notificationTitle = _value.notificationTitle;
    this.notificationDescription = _value.notificationDescription;
    this.type = _value.type;
    this.idChannel = _value.idChannel;
    this.titleChannel = _value.titleChannel;
    this.idTeam = _value.idTeam;
    this.titleTeam = _value.titleTeam;
    InternalNotificationEventConfigurationModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get notificationTitle(): string {
    return this._notificationTitle;
  }
  set notificationTitle(value: string) {
    this._notificationTitle = value;
  }
  get notificationDescription(): string {
    return this._notificationDescription;
  }
  set notificationDescription(value: string) {
    this._notificationDescription = value;
  }
  get type(): InternalNotificationEventConfigurationType {
    return this._type;
  }
  set type(value: InternalNotificationEventConfigurationType) {
    this._type = value;
  }
  get idChannel(): string {
    return this._idChannel;
  }
  set idChannel(value: string) {
    this._idChannel = value;
  }
  get titleChannel(): string {
    return this._titleChannel;
  }
  set titleChannel(value: string) {
    this._titleChannel = value;
  }
  get idTeam(): string {
    return this._idTeam;
  }
  set idTeam(value: string) {
    this._idTeam = value;
  }
  get titleTeam(): string {
    return this._titleTeam;
  }
  set titleTeam(value: string) {
    this._titleTeam = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    InternalNotificationEventConfigurationModel.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): InternalNotificationEventConfigurationModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      name: this.name,
      description: this.description,
      notificationTitle: this.notificationTitle,
      notificationDescription: this.notificationDescription,
      type: this.type,
      idChannel: this.idChannel,
      titleChannel: this.titleChannel,
      idTeam: this.idTeam,
      titleTeam: this.titleTeam
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): InternalNotificationEventConfigurationModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      name: this.name,
      description: this.description,
      notificationTitle: this.notificationTitle,
      notificationDescription: this.notificationDescription,
      type:
        InternalNotificationEventConfigurationType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      idChannel: this.idChannel,
      titleChannel: this.titleChannel,
      idTeam: this.idTeam,
      titleTeam: this.titleTeam
    };
  }
}
export module InternalNotificationEventConfigurationModel {
  /**
   * Standard JavaScript object representation for InternalNotificationEventConfigurationModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    name: string;
    description: string;
    notificationTitle: string;
    notificationDescription: string;
    type: InternalNotificationEventConfigurationType;
    idChannel: string;
    titleChannel: string;
    idTeam: string;
    titleTeam: string;
  }

  /**
   * Protobuf JSON representation for InternalNotificationEventConfigurationModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    name: string;
    description: string;
    notificationTitle: string;
    notificationDescription: string;
    type: string;
    idChannel: string;
    titleChannel: string;
    idTeam: string;
    titleTeam: string;
  }
}

/**
 * Message implementation for common_message.FundingProfitabilityValueModel
 */
export class FundingProfitabilityValueModel implements GrpcMessage {
  static id = 'common_message.FundingProfitabilityValueModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FundingProfitabilityValueModel();
    FundingProfitabilityValueModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FundingProfitabilityValueModel) {
    _instance.funding = _instance.funding || undefined;
    _instance.percentage = _instance.percentage || 0;
    _instance.quotas = _instance.quotas || 0;
    _instance.balance = _instance.balance || 0;
    _instance.netDeposit = _instance.netDeposit || 0;
    _instance.fundingId = _instance.fundingId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FundingProfitabilityValueModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.percentage = _reader.readDouble();
          break;
        case 3:
          _instance.quotas = _reader.readDouble();
          break;
        case 4:
          _instance.balance = _reader.readDouble();
          break;
        case 5:
          _instance.netDeposit = _reader.readDouble();
          break;
        case 6:
          _instance.fundingId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    FundingProfitabilityValueModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FundingProfitabilityValueModel,
    _writer: BinaryWriter
  ) {
    if (_instance.funding) {
      _writer.writeMessage(
        1,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.percentage) {
      _writer.writeDouble(2, _instance.percentage);
    }
    if (_instance.quotas) {
      _writer.writeDouble(3, _instance.quotas);
    }
    if (_instance.balance) {
      _writer.writeDouble(4, _instance.balance);
    }
    if (_instance.netDeposit) {
      _writer.writeDouble(5, _instance.netDeposit);
    }
    if (_instance.fundingId) {
      _writer.writeInt32(6, _instance.fundingId);
    }
  }

  private _funding?: FundingModel;
  private _percentage: number;
  private _quotas: number;
  private _balance: number;
  private _netDeposit: number;
  private _fundingId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FundingProfitabilityValueModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<FundingProfitabilityValueModel.AsObject>
  ) {
    _value = _value || {};
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.percentage = _value.percentage;
    this.quotas = _value.quotas;
    this.balance = _value.balance;
    this.netDeposit = _value.netDeposit;
    this.fundingId = _value.fundingId;
    FundingProfitabilityValueModel.refineValues(this);
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get quotas(): number {
    return this._quotas;
  }
  set quotas(value: number) {
    this._quotas = value;
  }
  get balance(): number {
    return this._balance;
  }
  set balance(value: number) {
    this._balance = value;
  }
  get netDeposit(): number {
    return this._netDeposit;
  }
  set netDeposit(value: number) {
    this._netDeposit = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FundingProfitabilityValueModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FundingProfitabilityValueModel.AsObject {
    return {
      funding: this.funding ? this.funding.toObject() : undefined,
      percentage: this.percentage,
      quotas: this.quotas,
      balance: this.balance,
      netDeposit: this.netDeposit,
      fundingId: this.fundingId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FundingProfitabilityValueModel.AsProtobufJSON {
    return {
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      percentage: this.percentage,
      quotas: this.quotas,
      balance: this.balance,
      netDeposit: this.netDeposit,
      fundingId: this.fundingId
    };
  }
}
export module FundingProfitabilityValueModel {
  /**
   * Standard JavaScript object representation for FundingProfitabilityValueModel
   */
  export interface AsObject {
    funding?: FundingModel.AsObject;
    percentage: number;
    quotas: number;
    balance: number;
    netDeposit: number;
    fundingId: number;
  }

  /**
   * Protobuf JSON representation for FundingProfitabilityValueModel
   */
  export interface AsProtobufJSON {
    funding: FundingModel.AsProtobufJSON | null;
    percentage: number;
    quotas: number;
    balance: number;
    netDeposit: number;
    fundingId: number;
  }
}

/**
 * Message implementation for common_message.UserFirstStepsModel
 */
export class UserFirstStepsModel implements GrpcMessage {
  static id = 'common_message.UserFirstStepsModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserFirstStepsModel();
    UserFirstStepsModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserFirstStepsModel) {
    _instance.calledAdvisor = _instance.calledAdvisor || undefined;
    _instance.invested = _instance.invested || undefined;
    _instance.scheduledAdvisor = _instance.scheduledAdvisor || undefined;
    _instance.contract = _instance.contract || undefined;
    _instance.skipedAdvisor = _instance.skipedAdvisor || undefined;
    _instance.filledFinancialData = _instance.filledFinancialData || undefined;
    _instance.createdGoal = _instance.createdGoal || undefined;
    _instance.ciIsValidIa = _instance.ciIsValidIa || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserFirstStepsModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.calledAdvisor = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.calledAdvisor,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.invested = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.invested,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.scheduledAdvisor = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.scheduledAdvisor,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.contract = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.contract,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.skipedAdvisor = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.skipedAdvisor,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.filledFinancialData = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.filledFinancialData,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.createdGoal = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.createdGoal,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.ciIsValidIa = new googleProtobuf000.BoolValue();
          _reader.readMessage(
            _instance.ciIsValidIa,
            googleProtobuf000.BoolValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserFirstStepsModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserFirstStepsModel,
    _writer: BinaryWriter
  ) {
    if (_instance.calledAdvisor) {
      _writer.writeMessage(
        1,
        _instance.calledAdvisor as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.invested) {
      _writer.writeMessage(
        3,
        _instance.invested as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.scheduledAdvisor) {
      _writer.writeMessage(
        4,
        _instance.scheduledAdvisor as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.contract) {
      _writer.writeMessage(
        5,
        _instance.contract as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.skipedAdvisor) {
      _writer.writeMessage(
        6,
        _instance.skipedAdvisor as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.filledFinancialData) {
      _writer.writeMessage(
        2,
        _instance.filledFinancialData as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.createdGoal) {
      _writer.writeMessage(
        7,
        _instance.createdGoal as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.ciIsValidIa) {
      _writer.writeMessage(
        8,
        _instance.ciIsValidIa as any,
        googleProtobuf000.BoolValue.serializeBinaryToWriter
      );
    }
  }

  private _calledAdvisor?: googleProtobuf000.BoolValue;
  private _invested?: googleProtobuf000.BoolValue;
  private _scheduledAdvisor?: googleProtobuf000.BoolValue;
  private _contract?: googleProtobuf000.BoolValue;
  private _skipedAdvisor?: googleProtobuf000.BoolValue;
  private _filledFinancialData?: googleProtobuf000.BoolValue;
  private _createdGoal?: googleProtobuf000.BoolValue;
  private _ciIsValidIa?: googleProtobuf000.BoolValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserFirstStepsModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserFirstStepsModel.AsObject>) {
    _value = _value || {};
    this.calledAdvisor = _value.calledAdvisor
      ? new googleProtobuf000.BoolValue(_value.calledAdvisor)
      : undefined;
    this.invested = _value.invested
      ? new googleProtobuf000.BoolValue(_value.invested)
      : undefined;
    this.scheduledAdvisor = _value.scheduledAdvisor
      ? new googleProtobuf000.BoolValue(_value.scheduledAdvisor)
      : undefined;
    this.contract = _value.contract
      ? new googleProtobuf000.BoolValue(_value.contract)
      : undefined;
    this.skipedAdvisor = _value.skipedAdvisor
      ? new googleProtobuf000.BoolValue(_value.skipedAdvisor)
      : undefined;
    this.filledFinancialData = _value.filledFinancialData
      ? new googleProtobuf000.BoolValue(_value.filledFinancialData)
      : undefined;
    this.createdGoal = _value.createdGoal
      ? new googleProtobuf000.BoolValue(_value.createdGoal)
      : undefined;
    this.ciIsValidIa = _value.ciIsValidIa
      ? new googleProtobuf000.BoolValue(_value.ciIsValidIa)
      : undefined;
    UserFirstStepsModel.refineValues(this);
  }
  get calledAdvisor(): googleProtobuf000.BoolValue | undefined {
    return this._calledAdvisor;
  }
  set calledAdvisor(value: googleProtobuf000.BoolValue | undefined) {
    this._calledAdvisor = value;
  }
  get invested(): googleProtobuf000.BoolValue | undefined {
    return this._invested;
  }
  set invested(value: googleProtobuf000.BoolValue | undefined) {
    this._invested = value;
  }
  get scheduledAdvisor(): googleProtobuf000.BoolValue | undefined {
    return this._scheduledAdvisor;
  }
  set scheduledAdvisor(value: googleProtobuf000.BoolValue | undefined) {
    this._scheduledAdvisor = value;
  }
  get contract(): googleProtobuf000.BoolValue | undefined {
    return this._contract;
  }
  set contract(value: googleProtobuf000.BoolValue | undefined) {
    this._contract = value;
  }
  get skipedAdvisor(): googleProtobuf000.BoolValue | undefined {
    return this._skipedAdvisor;
  }
  set skipedAdvisor(value: googleProtobuf000.BoolValue | undefined) {
    this._skipedAdvisor = value;
  }
  get filledFinancialData(): googleProtobuf000.BoolValue | undefined {
    return this._filledFinancialData;
  }
  set filledFinancialData(value: googleProtobuf000.BoolValue | undefined) {
    this._filledFinancialData = value;
  }
  get createdGoal(): googleProtobuf000.BoolValue | undefined {
    return this._createdGoal;
  }
  set createdGoal(value: googleProtobuf000.BoolValue | undefined) {
    this._createdGoal = value;
  }
  get ciIsValidIa(): googleProtobuf000.BoolValue | undefined {
    return this._ciIsValidIa;
  }
  set ciIsValidIa(value: googleProtobuf000.BoolValue | undefined) {
    this._ciIsValidIa = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserFirstStepsModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserFirstStepsModel.AsObject {
    return {
      calledAdvisor: this.calledAdvisor
        ? this.calledAdvisor.toObject()
        : undefined,
      invested: this.invested ? this.invested.toObject() : undefined,
      scheduledAdvisor: this.scheduledAdvisor
        ? this.scheduledAdvisor.toObject()
        : undefined,
      contract: this.contract ? this.contract.toObject() : undefined,
      skipedAdvisor: this.skipedAdvisor
        ? this.skipedAdvisor.toObject()
        : undefined,
      filledFinancialData: this.filledFinancialData
        ? this.filledFinancialData.toObject()
        : undefined,
      createdGoal: this.createdGoal ? this.createdGoal.toObject() : undefined,
      ciIsValidIa: this.ciIsValidIa ? this.ciIsValidIa.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserFirstStepsModel.AsProtobufJSON {
    return {
      calledAdvisor: this.calledAdvisor
        ? this.calledAdvisor.toProtobufJSON(options)
        : null,
      invested: this.invested ? this.invested.toProtobufJSON(options) : null,
      scheduledAdvisor: this.scheduledAdvisor
        ? this.scheduledAdvisor.toProtobufJSON(options)
        : null,
      contract: this.contract ? this.contract.toProtobufJSON(options) : null,
      skipedAdvisor: this.skipedAdvisor
        ? this.skipedAdvisor.toProtobufJSON(options)
        : null,
      filledFinancialData: this.filledFinancialData
        ? this.filledFinancialData.toProtobufJSON(options)
        : null,
      createdGoal: this.createdGoal
        ? this.createdGoal.toProtobufJSON(options)
        : null,
      ciIsValidIa: this.ciIsValidIa
        ? this.ciIsValidIa.toProtobufJSON(options)
        : null
    };
  }
}
export module UserFirstStepsModel {
  /**
   * Standard JavaScript object representation for UserFirstStepsModel
   */
  export interface AsObject {
    calledAdvisor?: googleProtobuf000.BoolValue.AsObject;
    invested?: googleProtobuf000.BoolValue.AsObject;
    scheduledAdvisor?: googleProtobuf000.BoolValue.AsObject;
    contract?: googleProtobuf000.BoolValue.AsObject;
    skipedAdvisor?: googleProtobuf000.BoolValue.AsObject;
    filledFinancialData?: googleProtobuf000.BoolValue.AsObject;
    createdGoal?: googleProtobuf000.BoolValue.AsObject;
    ciIsValidIa?: googleProtobuf000.BoolValue.AsObject;
  }

  /**
   * Protobuf JSON representation for UserFirstStepsModel
   */
  export interface AsProtobufJSON {
    calledAdvisor: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    invested: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    scheduledAdvisor: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    contract: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    skipedAdvisor: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    filledFinancialData: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    createdGoal: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    ciIsValidIa: googleProtobuf000.BoolValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.ListGoalResponse
 */
export class ListGoalResponse implements GrpcMessage {
  static id = 'common_message.ListGoalResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalResponse();
    ListGoalResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GoalModel();
          _reader.readMessage(
            messageInitializer1,
            GoalModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        GoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: GoalModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListGoalResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new GoalModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListGoalResponse.refineValues(this);
  }
  get values(): GoalModel[] | undefined {
    return this._values;
  }
  set values(value: GoalModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListGoalResponse {
  /**
   * Standard JavaScript object representation for ListGoalResponse
   */
  export interface AsObject {
    values?: GoalModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListGoalResponse
   */
  export interface AsProtobufJSON {
    values: GoalModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for common_message.MSTeamsValue
 */
export class MSTeamsValue implements GrpcMessage {
  static id = 'common_message.MSTeamsValue';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MSTeamsValue();
    MSTeamsValue.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MSTeamsValue) {
    _instance.id = _instance.id || '';
    _instance.displayName = _instance.displayName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MSTeamsValue,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.displayName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    MSTeamsValue.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: MSTeamsValue,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.displayName) {
      _writer.writeString(2, _instance.displayName);
    }
  }

  private _id: string;
  private _displayName: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MSTeamsValue to deeply clone from
   */
  constructor(_value?: RecursivePartial<MSTeamsValue.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.displayName = _value.displayName;
    MSTeamsValue.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get displayName(): string {
    return this._displayName;
  }
  set displayName(value: string) {
    this._displayName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MSTeamsValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MSTeamsValue.AsObject {
    return {
      id: this.id,
      displayName: this.displayName
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MSTeamsValue.AsProtobufJSON {
    return {
      id: this.id,
      displayName: this.displayName
    };
  }
}
export module MSTeamsValue {
  /**
   * Standard JavaScript object representation for MSTeamsValue
   */
  export interface AsObject {
    id: string;
    displayName: string;
  }

  /**
   * Protobuf JSON representation for MSTeamsValue
   */
  export interface AsProtobufJSON {
    id: string;
    displayName: string;
  }
}

/**
 * Message implementation for common_message.TemplateModel
 */
export class TemplateModel implements GrpcMessage {
  static id = 'common_message.TemplateModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TemplateModel();
    TemplateModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TemplateModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.type = _instance.type || 0;
    _instance.content = _instance.content || '';
    _instance.header = _instance.header || '';
    _instance.footer = _instance.footer || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TemplateModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.type = _reader.readEnum();
          break;
        case 7:
          _instance.content = _reader.readString();
          break;
        case 8:
          _instance.header = _reader.readString();
          break;
        case 9:
          _instance.footer = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TemplateModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TemplateModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.type) {
      _writer.writeEnum(6, _instance.type);
    }
    if (_instance.content) {
      _writer.writeString(7, _instance.content);
    }
    if (_instance.header) {
      _writer.writeString(8, _instance.header);
    }
    if (_instance.footer) {
      _writer.writeString(9, _instance.footer);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _name: string;
  private _description: string;
  private _type: TemplateType;
  private _content: string;
  private _header: string;
  private _footer: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TemplateModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<TemplateModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.name = _value.name;
    this.description = _value.description;
    this.type = _value.type;
    this.content = _value.content;
    this.header = _value.header;
    this.footer = _value.footer;
    TemplateModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get type(): TemplateType {
    return this._type;
  }
  set type(value: TemplateType) {
    this._type = value;
  }
  get content(): string {
    return this._content;
  }
  set content(value: string) {
    this._content = value;
  }
  get header(): string {
    return this._header;
  }
  set header(value: string) {
    this._header = value;
  }
  get footer(): string {
    return this._footer;
  }
  set footer(value: string) {
    this._footer = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TemplateModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TemplateModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      name: this.name,
      description: this.description,
      type: this.type,
      content: this.content,
      header: this.header,
      footer: this.footer
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TemplateModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      name: this.name,
      description: this.description,
      type:
        TemplateType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      content: this.content,
      header: this.header,
      footer: this.footer
    };
  }
}
export module TemplateModel {
  /**
   * Standard JavaScript object representation for TemplateModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    name: string;
    description: string;
    type: TemplateType;
    content: string;
    header: string;
    footer: string;
  }

  /**
   * Protobuf JSON representation for TemplateModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    name: string;
    description: string;
    type: string;
    content: string;
    header: string;
    footer: string;
  }
}

/**
 * Message implementation for common_message.ConfigParamModel
 */
export class ConfigParamModel implements GrpcMessage {
  static id = 'common_message.ConfigParamModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfigParamModel();
    ConfigParamModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfigParamModel) {
    _instance.id = _instance.id || 0;
    _instance.code = _instance.code || '';
    _instance.description = _instance.description || '';
    _instance.value = _instance.value || '';
    _instance.valueType = _instance.valueType || '';
    _instance.status = _instance.status || '';
    _instance.userId = _instance.userId || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfigParamModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.code = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.value = _reader.readString();
          break;
        case 5:
          _instance.valueType = _reader.readString();
          break;
        case 6:
          _instance.status = _reader.readString();
          break;
        case 7:
          _instance.userId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ConfigParamModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfigParamModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.code) {
      _writer.writeString(2, _instance.code);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.value) {
      _writer.writeString(4, _instance.value);
    }
    if (_instance.valueType) {
      _writer.writeString(5, _instance.valueType);
    }
    if (_instance.status) {
      _writer.writeString(6, _instance.status);
    }
    if (_instance.userId) {
      _writer.writeMessage(
        7,
        _instance.userId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        8,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        9,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _code: string;
  private _description: string;
  private _value: string;
  private _valueType: string;
  private _status: string;
  private _userId?: googleProtobuf000.Int32Value;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfigParamModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ConfigParamModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.code = _value.code;
    this.description = _value.description;
    this.value = _value.value;
    this.valueType = _value.valueType;
    this.status = _value.status;
    this.userId = _value.userId
      ? new googleProtobuf000.Int32Value(_value.userId)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    ConfigParamModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
  }
  get valueType(): string {
    return this._valueType;
  }
  set valueType(value: string) {
    this._valueType = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get userId(): googleProtobuf000.Int32Value | undefined {
    return this._userId;
  }
  set userId(value: googleProtobuf000.Int32Value | undefined) {
    this._userId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfigParamModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfigParamModel.AsObject {
    return {
      id: this.id,
      code: this.code,
      description: this.description,
      value: this.value,
      valueType: this.valueType,
      status: this.status,
      userId: this.userId ? this.userId.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfigParamModel.AsProtobufJSON {
    return {
      id: this.id,
      code: this.code,
      description: this.description,
      value: this.value,
      valueType: this.valueType,
      status: this.status,
      userId: this.userId ? this.userId.toProtobufJSON(options) : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module ConfigParamModel {
  /**
   * Standard JavaScript object representation for ConfigParamModel
   */
  export interface AsObject {
    id: number;
    code: string;
    description: string;
    value: string;
    valueType: string;
    status: string;
    userId?: googleProtobuf000.Int32Value.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for ConfigParamModel
   */
  export interface AsProtobufJSON {
    id: number;
    code: string;
    description: string;
    value: string;
    valueType: string;
    status: string;
    userId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.TimeHorizonModel
 */
export class TimeHorizonModel implements GrpcMessage {
  static id = 'common_message.TimeHorizonModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TimeHorizonModel();
    TimeHorizonModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TimeHorizonModel) {
    _instance.id = _instance.id || 0;
    _instance.name = _instance.name || '';
    _instance.uuid = _instance.uuid || '';
    _instance.fromYear = _instance.fromYear || 0;
    _instance.toYear = _instance.toYear || 0;
    _instance.version = _instance.version || '';
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TimeHorizonModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.uuid = _reader.readString();
          break;
        case 4:
          _instance.fromYear = _reader.readInt32();
          break;
        case 5:
          _instance.toYear = _reader.readInt32();
          break;
        case 6:
          _instance.version = _reader.readString();
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TimeHorizonModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TimeHorizonModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.uuid) {
      _writer.writeString(3, _instance.uuid);
    }
    if (_instance.fromYear) {
      _writer.writeInt32(4, _instance.fromYear);
    }
    if (_instance.toYear) {
      _writer.writeInt32(5, _instance.toYear);
    }
    if (_instance.version) {
      _writer.writeString(6, _instance.version);
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _name: string;
  private _uuid: string;
  private _fromYear: number;
  private _toYear: number;
  private _version: string;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TimeHorizonModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<TimeHorizonModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.uuid = _value.uuid;
    this.fromYear = _value.fromYear;
    this.toYear = _value.toYear;
    this.version = _value.version;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    TimeHorizonModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get fromYear(): number {
    return this._fromYear;
  }
  set fromYear(value: number) {
    this._fromYear = value;
  }
  get toYear(): number {
    return this._toYear;
  }
  set toYear(value: number) {
    this._toYear = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TimeHorizonModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TimeHorizonModel.AsObject {
    return {
      id: this.id,
      name: this.name,
      uuid: this.uuid,
      fromYear: this.fromYear,
      toYear: this.toYear,
      version: this.version,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TimeHorizonModel.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      uuid: this.uuid,
      fromYear: this.fromYear,
      toYear: this.toYear,
      version: this.version,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module TimeHorizonModel {
  /**
   * Standard JavaScript object representation for TimeHorizonModel
   */
  export interface AsObject {
    id: number;
    name: string;
    uuid: string;
    fromYear: number;
    toYear: number;
    version: string;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for TimeHorizonModel
   */
  export interface AsProtobufJSON {
    id: number;
    name: string;
    uuid: string;
    fromYear: number;
    toYear: number;
    version: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.QuestionModel
 */
export class QuestionModel implements GrpcMessage {
  static id = 'common_message.QuestionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new QuestionModel();
    QuestionModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: QuestionModel) {
    _instance.titleResponses = _instance.titleResponses || undefined;
    _instance.position = _instance.position || 0;
    _instance.uuid = _instance.uuid || '';
    _instance.uuidEconsult = _instance.uuidEconsult || undefined;
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.iconUrl = _instance.iconUrl || undefined;
    _instance.tutorialUrl = _instance.tutorialUrl || undefined;
    _instance.slider = _instance.slider || undefined;
    _instance.options = _instance.options || [];
    _instance.type = _instance.type || '';
    _instance.formatView = _instance.formatView || undefined;
    _instance.scoreType = _instance.scoreType || undefined;
    _instance.ranges = _instance.ranges || [];
    _instance.isSubQuestionGoalCustomization =
      _instance.isSubQuestionGoalCustomization || false;
    _instance.isBirthday18Old = _instance.isBirthday18Old || false;
    _instance.isBirthday = _instance.isBirthday || false;
    _instance.placeholder = _instance.placeholder || '';
    _instance.tooltip = _instance.tooltip || undefined;
    _instance.isOptional = _instance.isOptional || false;
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: QuestionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.titleResponses = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.titleResponses,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.position = _reader.readInt32();
          break;
        case 3:
          _instance.uuid = _reader.readString();
          break;
        case 4:
          _instance.uuidEconsult = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.uuidEconsult,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.title = _reader.readString();
          break;
        case 6:
          _instance.description = _reader.readString();
          break;
        case 7:
          _instance.iconUrl = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.iconUrl,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.tutorialUrl = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.tutorialUrl,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.slider = new QuestionModel.Slider();
          _reader.readMessage(
            _instance.slider,
            QuestionModel.Slider.deserializeBinaryFromReader
          );
          break;
        case 10:
          const messageInitializer10 = new QuestionModel.Options();
          _reader.readMessage(
            messageInitializer10,
            QuestionModel.Options.deserializeBinaryFromReader
          );
          (_instance.options = _instance.options || []).push(
            messageInitializer10
          );
          break;
        case 11:
          _instance.type = _reader.readString();
          break;
        case 12:
          _instance.formatView = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.formatView,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.scoreType = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.scoreType,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 14:
          const messageInitializer14 = new QuestionModel.Ranges();
          _reader.readMessage(
            messageInitializer14,
            QuestionModel.Ranges.deserializeBinaryFromReader
          );
          (_instance.ranges = _instance.ranges || []).push(
            messageInitializer14
          );
          break;
        case 15:
          _instance.isSubQuestionGoalCustomization = _reader.readBool();
          break;
        case 16:
          _instance.isBirthday18Old = _reader.readBool();
          break;
        case 17:
          _instance.isBirthday = _reader.readBool();
          break;
        case 18:
          _instance.placeholder = _reader.readString();
          break;
        case 19:
          _instance.tooltip = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.tooltip,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 20:
          _instance.isOptional = _reader.readBool();
          break;
        case 21:
          _instance.id = _reader.readInt32();
          break;
        case 22:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 23:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    QuestionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: QuestionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.titleResponses) {
      _writer.writeMessage(
        1,
        _instance.titleResponses as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.position) {
      _writer.writeInt32(2, _instance.position);
    }
    if (_instance.uuid) {
      _writer.writeString(3, _instance.uuid);
    }
    if (_instance.uuidEconsult) {
      _writer.writeMessage(
        4,
        _instance.uuidEconsult as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.title) {
      _writer.writeString(5, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(6, _instance.description);
    }
    if (_instance.iconUrl) {
      _writer.writeMessage(
        7,
        _instance.iconUrl as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.tutorialUrl) {
      _writer.writeMessage(
        8,
        _instance.tutorialUrl as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.slider) {
      _writer.writeMessage(
        9,
        _instance.slider as any,
        QuestionModel.Slider.serializeBinaryToWriter
      );
    }
    if (_instance.options && _instance.options.length) {
      _writer.writeRepeatedMessage(
        10,
        _instance.options as any,
        QuestionModel.Options.serializeBinaryToWriter
      );
    }
    if (_instance.type) {
      _writer.writeString(11, _instance.type);
    }
    if (_instance.formatView) {
      _writer.writeMessage(
        12,
        _instance.formatView as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.scoreType) {
      _writer.writeMessage(
        13,
        _instance.scoreType as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.ranges && _instance.ranges.length) {
      _writer.writeRepeatedMessage(
        14,
        _instance.ranges as any,
        QuestionModel.Ranges.serializeBinaryToWriter
      );
    }
    if (_instance.isSubQuestionGoalCustomization) {
      _writer.writeBool(15, _instance.isSubQuestionGoalCustomization);
    }
    if (_instance.isBirthday18Old) {
      _writer.writeBool(16, _instance.isBirthday18Old);
    }
    if (_instance.isBirthday) {
      _writer.writeBool(17, _instance.isBirthday);
    }
    if (_instance.placeholder) {
      _writer.writeString(18, _instance.placeholder);
    }
    if (_instance.tooltip) {
      _writer.writeMessage(
        19,
        _instance.tooltip as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.isOptional) {
      _writer.writeBool(20, _instance.isOptional);
    }
    if (_instance.id) {
      _writer.writeInt32(21, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        22,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        23,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _titleResponses?: googleProtobuf000.StringValue;
  private _position: number;
  private _uuid: string;
  private _uuidEconsult?: googleProtobuf000.StringValue;
  private _title: string;
  private _description: string;
  private _iconUrl?: googleProtobuf000.StringValue;
  private _tutorialUrl?: googleProtobuf000.StringValue;
  private _slider?: QuestionModel.Slider;
  private _options?: QuestionModel.Options[];
  private _type: string;
  private _formatView?: googleProtobuf000.StringValue;
  private _scoreType?: googleProtobuf000.StringValue;
  private _ranges?: QuestionModel.Ranges[];
  private _isSubQuestionGoalCustomization: boolean;
  private _isBirthday18Old: boolean;
  private _isBirthday: boolean;
  private _placeholder: string;
  private _tooltip?: googleProtobuf000.StringValue;
  private _isOptional: boolean;
  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of QuestionModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<QuestionModel.AsObject>) {
    _value = _value || {};
    this.titleResponses = _value.titleResponses
      ? new googleProtobuf000.StringValue(_value.titleResponses)
      : undefined;
    this.position = _value.position;
    this.uuid = _value.uuid;
    this.uuidEconsult = _value.uuidEconsult
      ? new googleProtobuf000.StringValue(_value.uuidEconsult)
      : undefined;
    this.title = _value.title;
    this.description = _value.description;
    this.iconUrl = _value.iconUrl
      ? new googleProtobuf000.StringValue(_value.iconUrl)
      : undefined;
    this.tutorialUrl = _value.tutorialUrl
      ? new googleProtobuf000.StringValue(_value.tutorialUrl)
      : undefined;
    this.slider = _value.slider
      ? new QuestionModel.Slider(_value.slider)
      : undefined;
    this.options = (_value.options || []).map(
      m => new QuestionModel.Options(m)
    );
    this.type = _value.type;
    this.formatView = _value.formatView
      ? new googleProtobuf000.StringValue(_value.formatView)
      : undefined;
    this.scoreType = _value.scoreType
      ? new googleProtobuf000.StringValue(_value.scoreType)
      : undefined;
    this.ranges = (_value.ranges || []).map(m => new QuestionModel.Ranges(m));
    this.isSubQuestionGoalCustomization = _value.isSubQuestionGoalCustomization;
    this.isBirthday18Old = _value.isBirthday18Old;
    this.isBirthday = _value.isBirthday;
    this.placeholder = _value.placeholder;
    this.tooltip = _value.tooltip
      ? new googleProtobuf000.StringValue(_value.tooltip)
      : undefined;
    this.isOptional = _value.isOptional;
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    QuestionModel.refineValues(this);
  }
  get titleResponses(): googleProtobuf000.StringValue | undefined {
    return this._titleResponses;
  }
  set titleResponses(value: googleProtobuf000.StringValue | undefined) {
    this._titleResponses = value;
  }
  get position(): number {
    return this._position;
  }
  set position(value: number) {
    this._position = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get uuidEconsult(): googleProtobuf000.StringValue | undefined {
    return this._uuidEconsult;
  }
  set uuidEconsult(value: googleProtobuf000.StringValue | undefined) {
    this._uuidEconsult = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get iconUrl(): googleProtobuf000.StringValue | undefined {
    return this._iconUrl;
  }
  set iconUrl(value: googleProtobuf000.StringValue | undefined) {
    this._iconUrl = value;
  }
  get tutorialUrl(): googleProtobuf000.StringValue | undefined {
    return this._tutorialUrl;
  }
  set tutorialUrl(value: googleProtobuf000.StringValue | undefined) {
    this._tutorialUrl = value;
  }
  get slider(): QuestionModel.Slider | undefined {
    return this._slider;
  }
  set slider(value: QuestionModel.Slider | undefined) {
    this._slider = value;
  }
  get options(): QuestionModel.Options[] | undefined {
    return this._options;
  }
  set options(value: QuestionModel.Options[] | undefined) {
    this._options = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get formatView(): googleProtobuf000.StringValue | undefined {
    return this._formatView;
  }
  set formatView(value: googleProtobuf000.StringValue | undefined) {
    this._formatView = value;
  }
  get scoreType(): googleProtobuf000.StringValue | undefined {
    return this._scoreType;
  }
  set scoreType(value: googleProtobuf000.StringValue | undefined) {
    this._scoreType = value;
  }
  get ranges(): QuestionModel.Ranges[] | undefined {
    return this._ranges;
  }
  set ranges(value: QuestionModel.Ranges[] | undefined) {
    this._ranges = value;
  }
  get isSubQuestionGoalCustomization(): boolean {
    return this._isSubQuestionGoalCustomization;
  }
  set isSubQuestionGoalCustomization(value: boolean) {
    this._isSubQuestionGoalCustomization = value;
  }
  get isBirthday18Old(): boolean {
    return this._isBirthday18Old;
  }
  set isBirthday18Old(value: boolean) {
    this._isBirthday18Old = value;
  }
  get isBirthday(): boolean {
    return this._isBirthday;
  }
  set isBirthday(value: boolean) {
    this._isBirthday = value;
  }
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
  }
  get tooltip(): googleProtobuf000.StringValue | undefined {
    return this._tooltip;
  }
  set tooltip(value: googleProtobuf000.StringValue | undefined) {
    this._tooltip = value;
  }
  get isOptional(): boolean {
    return this._isOptional;
  }
  set isOptional(value: boolean) {
    this._isOptional = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    QuestionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): QuestionModel.AsObject {
    return {
      titleResponses: this.titleResponses
        ? this.titleResponses.toObject()
        : undefined,
      position: this.position,
      uuid: this.uuid,
      uuidEconsult: this.uuidEconsult
        ? this.uuidEconsult.toObject()
        : undefined,
      title: this.title,
      description: this.description,
      iconUrl: this.iconUrl ? this.iconUrl.toObject() : undefined,
      tutorialUrl: this.tutorialUrl ? this.tutorialUrl.toObject() : undefined,
      slider: this.slider ? this.slider.toObject() : undefined,
      options: (this.options || []).map(m => m.toObject()),
      type: this.type,
      formatView: this.formatView ? this.formatView.toObject() : undefined,
      scoreType: this.scoreType ? this.scoreType.toObject() : undefined,
      ranges: (this.ranges || []).map(m => m.toObject()),
      isSubQuestionGoalCustomization: this.isSubQuestionGoalCustomization,
      isBirthday18Old: this.isBirthday18Old,
      isBirthday: this.isBirthday,
      placeholder: this.placeholder,
      tooltip: this.tooltip ? this.tooltip.toObject() : undefined,
      isOptional: this.isOptional,
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): QuestionModel.AsProtobufJSON {
    return {
      titleResponses: this.titleResponses
        ? this.titleResponses.toProtobufJSON(options)
        : null,
      position: this.position,
      uuid: this.uuid,
      uuidEconsult: this.uuidEconsult
        ? this.uuidEconsult.toProtobufJSON(options)
        : null,
      title: this.title,
      description: this.description,
      iconUrl: this.iconUrl ? this.iconUrl.toProtobufJSON(options) : null,
      tutorialUrl: this.tutorialUrl
        ? this.tutorialUrl.toProtobufJSON(options)
        : null,
      slider: this.slider ? this.slider.toProtobufJSON(options) : null,
      options: (this.options || []).map(m => m.toProtobufJSON(options)),
      type: this.type,
      formatView: this.formatView
        ? this.formatView.toProtobufJSON(options)
        : null,
      scoreType: this.scoreType ? this.scoreType.toProtobufJSON(options) : null,
      ranges: (this.ranges || []).map(m => m.toProtobufJSON(options)),
      isSubQuestionGoalCustomization: this.isSubQuestionGoalCustomization,
      isBirthday18Old: this.isBirthday18Old,
      isBirthday: this.isBirthday,
      placeholder: this.placeholder,
      tooltip: this.tooltip ? this.tooltip.toProtobufJSON(options) : null,
      isOptional: this.isOptional,
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module QuestionModel {
  /**
   * Standard JavaScript object representation for QuestionModel
   */
  export interface AsObject {
    titleResponses?: googleProtobuf000.StringValue.AsObject;
    position: number;
    uuid: string;
    uuidEconsult?: googleProtobuf000.StringValue.AsObject;
    title: string;
    description: string;
    iconUrl?: googleProtobuf000.StringValue.AsObject;
    tutorialUrl?: googleProtobuf000.StringValue.AsObject;
    slider?: QuestionModel.Slider.AsObject;
    options?: QuestionModel.Options.AsObject[];
    type: string;
    formatView?: googleProtobuf000.StringValue.AsObject;
    scoreType?: googleProtobuf000.StringValue.AsObject;
    ranges?: QuestionModel.Ranges.AsObject[];
    isSubQuestionGoalCustomization: boolean;
    isBirthday18Old: boolean;
    isBirthday: boolean;
    placeholder: string;
    tooltip?: googleProtobuf000.StringValue.AsObject;
    isOptional: boolean;
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for QuestionModel
   */
  export interface AsProtobufJSON {
    titleResponses: googleProtobuf000.StringValue.AsProtobufJSON | null;
    position: number;
    uuid: string;
    uuidEconsult: googleProtobuf000.StringValue.AsProtobufJSON | null;
    title: string;
    description: string;
    iconUrl: googleProtobuf000.StringValue.AsProtobufJSON | null;
    tutorialUrl: googleProtobuf000.StringValue.AsProtobufJSON | null;
    slider: QuestionModel.Slider.AsProtobufJSON | null;
    options: QuestionModel.Options.AsProtobufJSON[] | null;
    type: string;
    formatView: googleProtobuf000.StringValue.AsProtobufJSON | null;
    scoreType: googleProtobuf000.StringValue.AsProtobufJSON | null;
    ranges: QuestionModel.Ranges.AsProtobufJSON[] | null;
    isSubQuestionGoalCustomization: boolean;
    isBirthday18Old: boolean;
    isBirthday: boolean;
    placeholder: string;
    tooltip: googleProtobuf000.StringValue.AsProtobufJSON | null;
    isOptional: boolean;
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }

  /**
   * Message implementation for common_message.QuestionModel.Slider
   */
  export class Slider implements GrpcMessage {
    static id = 'common_message.QuestionModel.Slider';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Slider();
      Slider.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Slider) {
      _instance.floor = _instance.floor || 0;
      _instance.ceil = _instance.ceil || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Slider,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.floor = _reader.readInt32();
            break;
          case 2:
            _instance.ceil = _reader.readInt32();
            break;
          default:
            _reader.skipField();
        }
      }

      Slider.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Slider, _writer: BinaryWriter) {
      if (_instance.floor) {
        _writer.writeInt32(1, _instance.floor);
      }
      if (_instance.ceil) {
        _writer.writeInt32(2, _instance.ceil);
      }
    }

    private _floor: number;
    private _ceil: number;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Slider to deeply clone from
     */
    constructor(_value?: RecursivePartial<Slider.AsObject>) {
      _value = _value || {};
      this.floor = _value.floor;
      this.ceil = _value.ceil;
      Slider.refineValues(this);
    }
    get floor(): number {
      return this._floor;
    }
    set floor(value: number) {
      this._floor = value;
    }
    get ceil(): number {
      return this._ceil;
    }
    set ceil(value: number) {
      this._ceil = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Slider.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Slider.AsObject {
      return {
        floor: this.floor,
        ceil: this.ceil
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Slider.AsProtobufJSON {
      return {
        floor: this.floor,
        ceil: this.ceil
      };
    }
  }
  export module Slider {
    /**
     * Standard JavaScript object representation for Slider
     */
    export interface AsObject {
      floor: number;
      ceil: number;
    }

    /**
     * Protobuf JSON representation for Slider
     */
    export interface AsProtobufJSON {
      floor: number;
      ceil: number;
    }
  }

  /**
   * Message implementation for common_message.QuestionModel.Options
   */
  export class Options implements GrpcMessage {
    static id = 'common_message.QuestionModel.Options';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Options();
      Options.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Options) {
      _instance.text = _instance.text || '';
      _instance.value = _instance.value || '';
      _instance.score = _instance.score || 0;
      _instance.iconCode = _instance.iconCode || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Options,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.text = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readString();
            break;
          case 3:
            _instance.score = _reader.readInt32();
            break;
          case 4:
            _instance.iconCode = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Options.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Options, _writer: BinaryWriter) {
      if (_instance.text) {
        _writer.writeString(1, _instance.text);
      }
      if (_instance.value) {
        _writer.writeString(2, _instance.value);
      }
      if (_instance.score) {
        _writer.writeInt32(3, _instance.score);
      }
      if (_instance.iconCode) {
        _writer.writeString(4, _instance.iconCode);
      }
    }

    private _text: string;
    private _value: string;
    private _score: number;
    private _iconCode: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Options to deeply clone from
     */
    constructor(_value?: RecursivePartial<Options.AsObject>) {
      _value = _value || {};
      this.text = _value.text;
      this.value = _value.value;
      this.score = _value.score;
      this.iconCode = _value.iconCode;
      Options.refineValues(this);
    }
    get text(): string {
      return this._text;
    }
    set text(value: string) {
      this._text = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }
    get score(): number {
      return this._score;
    }
    set score(value: number) {
      this._score = value;
    }
    get iconCode(): string {
      return this._iconCode;
    }
    set iconCode(value: string) {
      this._iconCode = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Options.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Options.AsObject {
      return {
        text: this.text,
        value: this.value,
        score: this.score,
        iconCode: this.iconCode
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Options.AsProtobufJSON {
      return {
        text: this.text,
        value: this.value,
        score: this.score,
        iconCode: this.iconCode
      };
    }
  }
  export module Options {
    /**
     * Standard JavaScript object representation for Options
     */
    export interface AsObject {
      text: string;
      value: string;
      score: number;
      iconCode: string;
    }

    /**
     * Protobuf JSON representation for Options
     */
    export interface AsProtobufJSON {
      text: string;
      value: string;
      score: number;
      iconCode: string;
    }
  }

  /**
   * Message implementation for common_message.QuestionModel.Ranges
   */
  export class Ranges implements GrpcMessage {
    static id = 'common_message.QuestionModel.Ranges';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Ranges();
      Ranges.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Ranges) {
      _instance.position = _instance.position || 0;
      _instance.color = _instance.color || '';
      _instance.name = _instance.name || '';
      _instance.valueMax = _instance.valueMax || 0;
      _instance.valueMin = _instance.valueMin || 0;
      _instance.score = _instance.score || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Ranges,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.position = _reader.readInt32();
            break;
          case 2:
            _instance.color = _reader.readString();
            break;
          case 3:
            _instance.name = _reader.readString();
            break;
          case 4:
            _instance.valueMax = _reader.readDouble();
            break;
          case 5:
            _instance.valueMin = _reader.readDouble();
            break;
          case 6:
            _instance.score = _reader.readDouble();
            break;
          default:
            _reader.skipField();
        }
      }

      Ranges.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Ranges, _writer: BinaryWriter) {
      if (_instance.position) {
        _writer.writeInt32(1, _instance.position);
      }
      if (_instance.color) {
        _writer.writeString(2, _instance.color);
      }
      if (_instance.name) {
        _writer.writeString(3, _instance.name);
      }
      if (_instance.valueMax) {
        _writer.writeDouble(4, _instance.valueMax);
      }
      if (_instance.valueMin) {
        _writer.writeDouble(5, _instance.valueMin);
      }
      if (_instance.score) {
        _writer.writeDouble(6, _instance.score);
      }
    }

    private _position: number;
    private _color: string;
    private _name: string;
    private _valueMax: number;
    private _valueMin: number;
    private _score: number;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Ranges to deeply clone from
     */
    constructor(_value?: RecursivePartial<Ranges.AsObject>) {
      _value = _value || {};
      this.position = _value.position;
      this.color = _value.color;
      this.name = _value.name;
      this.valueMax = _value.valueMax;
      this.valueMin = _value.valueMin;
      this.score = _value.score;
      Ranges.refineValues(this);
    }
    get position(): number {
      return this._position;
    }
    set position(value: number) {
      this._position = value;
    }
    get color(): string {
      return this._color;
    }
    set color(value: string) {
      this._color = value;
    }
    get name(): string {
      return this._name;
    }
    set name(value: string) {
      this._name = value;
    }
    get valueMax(): number {
      return this._valueMax;
    }
    set valueMax(value: number) {
      this._valueMax = value;
    }
    get valueMin(): number {
      return this._valueMin;
    }
    set valueMin(value: number) {
      this._valueMin = value;
    }
    get score(): number {
      return this._score;
    }
    set score(value: number) {
      this._score = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Ranges.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Ranges.AsObject {
      return {
        position: this.position,
        color: this.color,
        name: this.name,
        valueMax: this.valueMax,
        valueMin: this.valueMin,
        score: this.score
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Ranges.AsProtobufJSON {
      return {
        position: this.position,
        color: this.color,
        name: this.name,
        valueMax: this.valueMax,
        valueMin: this.valueMin,
        score: this.score
      };
    }
  }
  export module Ranges {
    /**
     * Standard JavaScript object representation for Ranges
     */
    export interface AsObject {
      position: number;
      color: string;
      name: string;
      valueMax: number;
      valueMin: number;
      score: number;
    }

    /**
     * Protobuf JSON representation for Ranges
     */
    export interface AsProtobufJSON {
      position: number;
      color: string;
      name: string;
      valueMax: number;
      valueMin: number;
      score: number;
    }
  }
}

/**
 * Message implementation for common_message.PlanCalculationOperationModel
 */
export class PlanCalculationOperationModel implements GrpcMessage {
  static id = 'common_message.PlanCalculationOperationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PlanCalculationOperationModel();
    PlanCalculationOperationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PlanCalculationOperationModel) {
    _instance.uuid = _instance.uuid || '';
    _instance.operation = _instance.operation || '';
    _instance.value = _instance.value || undefined;
    _instance.position = _instance.position || 0;
    _instance.source = _instance.source || undefined;
    _instance.id = _instance.id || 0;
    _instance.planCalculationId = _instance.planCalculationId || undefined;
    _instance.questionId = _instance.questionId || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PlanCalculationOperationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.uuid = _reader.readString();
          break;
        case 2:
          _instance.operation = _reader.readString();
          break;
        case 3:
          _instance.value = new googleProtobuf000.DoubleValue();
          _reader.readMessage(
            _instance.value,
            googleProtobuf000.DoubleValue.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.position = _reader.readInt32();
          break;
        case 5:
          _instance.source = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.source,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.id = _reader.readInt32();
          break;
        case 7:
          _instance.planCalculationId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.planCalculationId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.questionId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.questionId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PlanCalculationOperationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PlanCalculationOperationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.uuid) {
      _writer.writeString(1, _instance.uuid);
    }
    if (_instance.operation) {
      _writer.writeString(2, _instance.operation);
    }
    if (_instance.value) {
      _writer.writeMessage(
        3,
        _instance.value as any,
        googleProtobuf000.DoubleValue.serializeBinaryToWriter
      );
    }
    if (_instance.position) {
      _writer.writeInt32(4, _instance.position);
    }
    if (_instance.source) {
      _writer.writeMessage(
        5,
        _instance.source as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(6, _instance.id);
    }
    if (_instance.planCalculationId) {
      _writer.writeMessage(
        7,
        _instance.planCalculationId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.questionId) {
      _writer.writeMessage(
        8,
        _instance.questionId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        9,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        10,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _uuid: string;
  private _operation: string;
  private _value?: googleProtobuf000.DoubleValue;
  private _position: number;
  private _source?: googleProtobuf000.StringValue;
  private _id: number;
  private _planCalculationId?: googleProtobuf000.Int32Value;
  private _questionId?: googleProtobuf000.Int32Value;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PlanCalculationOperationModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<PlanCalculationOperationModel.AsObject>
  ) {
    _value = _value || {};
    this.uuid = _value.uuid;
    this.operation = _value.operation;
    this.value = _value.value
      ? new googleProtobuf000.DoubleValue(_value.value)
      : undefined;
    this.position = _value.position;
    this.source = _value.source
      ? new googleProtobuf000.StringValue(_value.source)
      : undefined;
    this.id = _value.id;
    this.planCalculationId = _value.planCalculationId
      ? new googleProtobuf000.Int32Value(_value.planCalculationId)
      : undefined;
    this.questionId = _value.questionId
      ? new googleProtobuf000.Int32Value(_value.questionId)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    PlanCalculationOperationModel.refineValues(this);
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get operation(): string {
    return this._operation;
  }
  set operation(value: string) {
    this._operation = value;
  }
  get value(): googleProtobuf000.DoubleValue | undefined {
    return this._value;
  }
  set value(value: googleProtobuf000.DoubleValue | undefined) {
    this._value = value;
  }
  get position(): number {
    return this._position;
  }
  set position(value: number) {
    this._position = value;
  }
  get source(): googleProtobuf000.StringValue | undefined {
    return this._source;
  }
  set source(value: googleProtobuf000.StringValue | undefined) {
    this._source = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get planCalculationId(): googleProtobuf000.Int32Value | undefined {
    return this._planCalculationId;
  }
  set planCalculationId(value: googleProtobuf000.Int32Value | undefined) {
    this._planCalculationId = value;
  }
  get questionId(): googleProtobuf000.Int32Value | undefined {
    return this._questionId;
  }
  set questionId(value: googleProtobuf000.Int32Value | undefined) {
    this._questionId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PlanCalculationOperationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PlanCalculationOperationModel.AsObject {
    return {
      uuid: this.uuid,
      operation: this.operation,
      value: this.value ? this.value.toObject() : undefined,
      position: this.position,
      source: this.source ? this.source.toObject() : undefined,
      id: this.id,
      planCalculationId: this.planCalculationId
        ? this.planCalculationId.toObject()
        : undefined,
      questionId: this.questionId ? this.questionId.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PlanCalculationOperationModel.AsProtobufJSON {
    return {
      uuid: this.uuid,
      operation: this.operation,
      value: this.value ? this.value.toProtobufJSON(options) : null,
      position: this.position,
      source: this.source ? this.source.toProtobufJSON(options) : null,
      id: this.id,
      planCalculationId: this.planCalculationId
        ? this.planCalculationId.toProtobufJSON(options)
        : null,
      questionId: this.questionId
        ? this.questionId.toProtobufJSON(options)
        : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module PlanCalculationOperationModel {
  /**
   * Standard JavaScript object representation for PlanCalculationOperationModel
   */
  export interface AsObject {
    uuid: string;
    operation: string;
    value?: googleProtobuf000.DoubleValue.AsObject;
    position: number;
    source?: googleProtobuf000.StringValue.AsObject;
    id: number;
    planCalculationId?: googleProtobuf000.Int32Value.AsObject;
    questionId?: googleProtobuf000.Int32Value.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for PlanCalculationOperationModel
   */
  export interface AsProtobufJSON {
    uuid: string;
    operation: string;
    value: googleProtobuf000.DoubleValue.AsProtobufJSON | null;
    position: number;
    source: googleProtobuf000.StringValue.AsProtobufJSON | null;
    id: number;
    planCalculationId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    questionId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.PlanCalculationModel
 */
export class PlanCalculationModel implements GrpcMessage {
  static id = 'common_message.PlanCalculationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PlanCalculationModel();
    PlanCalculationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PlanCalculationModel) {
    _instance.uuid = _instance.uuid || '';
    _instance.type = _instance.type || '';
    _instance.name = _instance.name || '';
    _instance.result = _instance.result || '';
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.operations = _instance.operations || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PlanCalculationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.uuid = _reader.readString();
          break;
        case 2:
          _instance.type = _reader.readString();
          break;
        case 3:
          _instance.name = _reader.readString();
          break;
        case 4:
          _instance.result = _reader.readString();
          break;
        case 5:
          _instance.id = _reader.readInt32();
          break;
        case 6:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          const messageInitializer8 = new PlanCalculationOperationModel();
          _reader.readMessage(
            messageInitializer8,
            PlanCalculationOperationModel.deserializeBinaryFromReader
          );
          (_instance.operations = _instance.operations || []).push(
            messageInitializer8
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PlanCalculationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PlanCalculationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.uuid) {
      _writer.writeString(1, _instance.uuid);
    }
    if (_instance.type) {
      _writer.writeString(2, _instance.type);
    }
    if (_instance.name) {
      _writer.writeString(3, _instance.name);
    }
    if (_instance.result) {
      _writer.writeString(4, _instance.result);
    }
    if (_instance.id) {
      _writer.writeInt32(5, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        6,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        7,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.operations && _instance.operations.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.operations as any,
        PlanCalculationOperationModel.serializeBinaryToWriter
      );
    }
  }

  private _uuid: string;
  private _type: string;
  private _name: string;
  private _result: string;
  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _operations?: PlanCalculationOperationModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PlanCalculationModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<PlanCalculationModel.AsObject>) {
    _value = _value || {};
    this.uuid = _value.uuid;
    this.type = _value.type;
    this.name = _value.name;
    this.result = _value.result;
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.operations = (_value.operations || []).map(
      m => new PlanCalculationOperationModel(m)
    );
    PlanCalculationModel.refineValues(this);
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get result(): string {
    return this._result;
  }
  set result(value: string) {
    this._result = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get operations(): PlanCalculationOperationModel[] | undefined {
    return this._operations;
  }
  set operations(value: PlanCalculationOperationModel[] | undefined) {
    this._operations = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PlanCalculationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PlanCalculationModel.AsObject {
    return {
      uuid: this.uuid,
      type: this.type,
      name: this.name,
      result: this.result,
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      operations: (this.operations || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PlanCalculationModel.AsProtobufJSON {
    return {
      uuid: this.uuid,
      type: this.type,
      name: this.name,
      result: this.result,
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      operations: (this.operations || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module PlanCalculationModel {
  /**
   * Standard JavaScript object representation for PlanCalculationModel
   */
  export interface AsObject {
    uuid: string;
    type: string;
    name: string;
    result: string;
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    operations?: PlanCalculationOperationModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for PlanCalculationModel
   */
  export interface AsProtobufJSON {
    uuid: string;
    type: string;
    name: string;
    result: string;
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    operations: PlanCalculationOperationModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for common_message.GoalRiskLevelCalculationModel
 */
export class GoalRiskLevelCalculationModel implements GrpcMessage {
  static id = 'common_message.GoalRiskLevelCalculationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalRiskLevelCalculationModel();
    GoalRiskLevelCalculationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalRiskLevelCalculationModel) {
    _instance.version = _instance.version || '';
    _instance.id = _instance.id || 0;
    _instance.goalCategoryId = _instance.goalCategoryId || 0;
    _instance.riskLevelId = _instance.riskLevelId || 0;
    _instance.riskProfileId = _instance.riskProfileId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.timeHorizonId = _instance.timeHorizonId || 0;
    _instance.riskProfile = _instance.riskProfile || undefined;
    _instance.riskLevel = _instance.riskLevel || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalRiskLevelCalculationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.version = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readInt32();
          break;
        case 3:
          _instance.goalCategoryId = _reader.readInt32();
          break;
        case 4:
          _instance.riskLevelId = _reader.readInt32();
          break;
        case 5:
          _instance.riskProfileId = _reader.readInt32();
          break;
        case 6:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.timeHorizonId = _reader.readInt32();
          break;
        case 9:
          _instance.riskProfile = new RiskProfileModel();
          _reader.readMessage(
            _instance.riskProfile,
            RiskProfileModel.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.riskLevel = new RiskLevelModel();
          _reader.readMessage(
            _instance.riskLevel,
            RiskLevelModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GoalRiskLevelCalculationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalRiskLevelCalculationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.version) {
      _writer.writeString(1, _instance.version);
    }
    if (_instance.id) {
      _writer.writeInt32(2, _instance.id);
    }
    if (_instance.goalCategoryId) {
      _writer.writeInt32(3, _instance.goalCategoryId);
    }
    if (_instance.riskLevelId) {
      _writer.writeInt32(4, _instance.riskLevelId);
    }
    if (_instance.riskProfileId) {
      _writer.writeInt32(5, _instance.riskProfileId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        6,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        7,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timeHorizonId) {
      _writer.writeInt32(8, _instance.timeHorizonId);
    }
    if (_instance.riskProfile) {
      _writer.writeMessage(
        9,
        _instance.riskProfile as any,
        RiskProfileModel.serializeBinaryToWriter
      );
    }
    if (_instance.riskLevel) {
      _writer.writeMessage(
        10,
        _instance.riskLevel as any,
        RiskLevelModel.serializeBinaryToWriter
      );
    }
  }

  private _version: string;
  private _id: number;
  private _goalCategoryId: number;
  private _riskLevelId: number;
  private _riskProfileId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _timeHorizonId: number;
  private _riskProfile?: RiskProfileModel;
  private _riskLevel?: RiskLevelModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalRiskLevelCalculationModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GoalRiskLevelCalculationModel.AsObject>
  ) {
    _value = _value || {};
    this.version = _value.version;
    this.id = _value.id;
    this.goalCategoryId = _value.goalCategoryId;
    this.riskLevelId = _value.riskLevelId;
    this.riskProfileId = _value.riskProfileId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.timeHorizonId = _value.timeHorizonId;
    this.riskProfile = _value.riskProfile
      ? new RiskProfileModel(_value.riskProfile)
      : undefined;
    this.riskLevel = _value.riskLevel
      ? new RiskLevelModel(_value.riskLevel)
      : undefined;
    GoalRiskLevelCalculationModel.refineValues(this);
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get goalCategoryId(): number {
    return this._goalCategoryId;
  }
  set goalCategoryId(value: number) {
    this._goalCategoryId = value;
  }
  get riskLevelId(): number {
    return this._riskLevelId;
  }
  set riskLevelId(value: number) {
    this._riskLevelId = value;
  }
  get riskProfileId(): number {
    return this._riskProfileId;
  }
  set riskProfileId(value: number) {
    this._riskProfileId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get timeHorizonId(): number {
    return this._timeHorizonId;
  }
  set timeHorizonId(value: number) {
    this._timeHorizonId = value;
  }
  get riskProfile(): RiskProfileModel | undefined {
    return this._riskProfile;
  }
  set riskProfile(value: RiskProfileModel | undefined) {
    this._riskProfile = value;
  }
  get riskLevel(): RiskLevelModel | undefined {
    return this._riskLevel;
  }
  set riskLevel(value: RiskLevelModel | undefined) {
    this._riskLevel = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalRiskLevelCalculationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalRiskLevelCalculationModel.AsObject {
    return {
      version: this.version,
      id: this.id,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId,
      riskProfileId: this.riskProfileId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      timeHorizonId: this.timeHorizonId,
      riskProfile: this.riskProfile ? this.riskProfile.toObject() : undefined,
      riskLevel: this.riskLevel ? this.riskLevel.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalRiskLevelCalculationModel.AsProtobufJSON {
    return {
      version: this.version,
      id: this.id,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId,
      riskProfileId: this.riskProfileId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      timeHorizonId: this.timeHorizonId,
      riskProfile: this.riskProfile
        ? this.riskProfile.toProtobufJSON(options)
        : null,
      riskLevel: this.riskLevel ? this.riskLevel.toProtobufJSON(options) : null
    };
  }
}
export module GoalRiskLevelCalculationModel {
  /**
   * Standard JavaScript object representation for GoalRiskLevelCalculationModel
   */
  export interface AsObject {
    version: string;
    id: number;
    goalCategoryId: number;
    riskLevelId: number;
    riskProfileId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    timeHorizonId: number;
    riskProfile?: RiskProfileModel.AsObject;
    riskLevel?: RiskLevelModel.AsObject;
  }

  /**
   * Protobuf JSON representation for GoalRiskLevelCalculationModel
   */
  export interface AsProtobufJSON {
    version: string;
    id: number;
    goalCategoryId: number;
    riskLevelId: number;
    riskProfileId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    timeHorizonId: number;
    riskProfile: RiskProfileModel.AsProtobufJSON | null;
    riskLevel: RiskLevelModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.UserResultCalculateModel
 */
export class UserResultCalculateModel implements GrpcMessage {
  static id = 'common_message.UserResultCalculateModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserResultCalculateModel();
    UserResultCalculateModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserResultCalculateModel) {
    _instance.plan = _instance.plan || undefined;
    _instance.goal = _instance.goal || undefined;
    _instance.user = _instance.user || undefined;
    _instance.econsult = _instance.econsult || undefined;
    _instance.investmentStrategy = _instance.investmentStrategy || undefined;
    _instance.portfolio = _instance.portfolio || undefined;
    _instance.answers = _instance.answers || [];
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserResultCalculateModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.plan = new UserResultCalculatePlanModel();
          _reader.readMessage(
            _instance.plan,
            UserResultCalculatePlanModel.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.goal = new UserResultCalculateGoalModel();
          _reader.readMessage(
            _instance.goal,
            UserResultCalculateGoalModel.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.user = new UserResultCalculateUserModel();
          _reader.readMessage(
            _instance.user,
            UserResultCalculateUserModel.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.econsult = new UserResultCalculateEconsultModel();
          _reader.readMessage(
            _instance.econsult,
            UserResultCalculateEconsultModel.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.investmentStrategy = new InvestmentStrategyModel();
          _reader.readMessage(
            _instance.investmentStrategy,
            InvestmentStrategyModel.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.portfolio = new PortfolioModel();
          _reader.readMessage(
            _instance.portfolio,
            PortfolioModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          const messageInitializer7 = new UserResultCalculateAnswerModel();
          _reader.readMessage(
            messageInitializer7,
            UserResultCalculateAnswerModel.deserializeBinaryFromReader
          );
          (_instance.answers = _instance.answers || []).push(
            messageInitializer7
          );
          break;
        case 8:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    UserResultCalculateModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserResultCalculateModel,
    _writer: BinaryWriter
  ) {
    if (_instance.plan) {
      _writer.writeMessage(
        1,
        _instance.plan as any,
        UserResultCalculatePlanModel.serializeBinaryToWriter
      );
    }
    if (_instance.goal) {
      _writer.writeMessage(
        2,
        _instance.goal as any,
        UserResultCalculateGoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.user) {
      _writer.writeMessage(
        3,
        _instance.user as any,
        UserResultCalculateUserModel.serializeBinaryToWriter
      );
    }
    if (_instance.econsult) {
      _writer.writeMessage(
        4,
        _instance.econsult as any,
        UserResultCalculateEconsultModel.serializeBinaryToWriter
      );
    }
    if (_instance.investmentStrategy) {
      _writer.writeMessage(
        5,
        _instance.investmentStrategy as any,
        InvestmentStrategyModel.serializeBinaryToWriter
      );
    }
    if (_instance.portfolio) {
      _writer.writeMessage(
        6,
        _instance.portfolio as any,
        PortfolioModel.serializeBinaryToWriter
      );
    }
    if (_instance.answers && _instance.answers.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.answers as any,
        UserResultCalculateAnswerModel.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(8, _instance.id);
    }
  }

  private _plan?: UserResultCalculatePlanModel;
  private _goal?: UserResultCalculateGoalModel;
  private _user?: UserResultCalculateUserModel;
  private _econsult?: UserResultCalculateEconsultModel;
  private _investmentStrategy?: InvestmentStrategyModel;
  private _portfolio?: PortfolioModel;
  private _answers?: UserResultCalculateAnswerModel[];
  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserResultCalculateModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserResultCalculateModel.AsObject>) {
    _value = _value || {};
    this.plan = _value.plan
      ? new UserResultCalculatePlanModel(_value.plan)
      : undefined;
    this.goal = _value.goal
      ? new UserResultCalculateGoalModel(_value.goal)
      : undefined;
    this.user = _value.user
      ? new UserResultCalculateUserModel(_value.user)
      : undefined;
    this.econsult = _value.econsult
      ? new UserResultCalculateEconsultModel(_value.econsult)
      : undefined;
    this.investmentStrategy = _value.investmentStrategy
      ? new InvestmentStrategyModel(_value.investmentStrategy)
      : undefined;
    this.portfolio = _value.portfolio
      ? new PortfolioModel(_value.portfolio)
      : undefined;
    this.answers = (_value.answers || []).map(
      m => new UserResultCalculateAnswerModel(m)
    );
    this.id = _value.id;
    UserResultCalculateModel.refineValues(this);
  }
  get plan(): UserResultCalculatePlanModel | undefined {
    return this._plan;
  }
  set plan(value: UserResultCalculatePlanModel | undefined) {
    this._plan = value;
  }
  get goal(): UserResultCalculateGoalModel | undefined {
    return this._goal;
  }
  set goal(value: UserResultCalculateGoalModel | undefined) {
    this._goal = value;
  }
  get user(): UserResultCalculateUserModel | undefined {
    return this._user;
  }
  set user(value: UserResultCalculateUserModel | undefined) {
    this._user = value;
  }
  get econsult(): UserResultCalculateEconsultModel | undefined {
    return this._econsult;
  }
  set econsult(value: UserResultCalculateEconsultModel | undefined) {
    this._econsult = value;
  }
  get investmentStrategy(): InvestmentStrategyModel | undefined {
    return this._investmentStrategy;
  }
  set investmentStrategy(value: InvestmentStrategyModel | undefined) {
    this._investmentStrategy = value;
  }
  get portfolio(): PortfolioModel | undefined {
    return this._portfolio;
  }
  set portfolio(value: PortfolioModel | undefined) {
    this._portfolio = value;
  }
  get answers(): UserResultCalculateAnswerModel[] | undefined {
    return this._answers;
  }
  set answers(value: UserResultCalculateAnswerModel[] | undefined) {
    this._answers = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserResultCalculateModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserResultCalculateModel.AsObject {
    return {
      plan: this.plan ? this.plan.toObject() : undefined,
      goal: this.goal ? this.goal.toObject() : undefined,
      user: this.user ? this.user.toObject() : undefined,
      econsult: this.econsult ? this.econsult.toObject() : undefined,
      investmentStrategy: this.investmentStrategy
        ? this.investmentStrategy.toObject()
        : undefined,
      portfolio: this.portfolio ? this.portfolio.toObject() : undefined,
      answers: (this.answers || []).map(m => m.toObject()),
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserResultCalculateModel.AsProtobufJSON {
    return {
      plan: this.plan ? this.plan.toProtobufJSON(options) : null,
      goal: this.goal ? this.goal.toProtobufJSON(options) : null,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      econsult: this.econsult ? this.econsult.toProtobufJSON(options) : null,
      investmentStrategy: this.investmentStrategy
        ? this.investmentStrategy.toProtobufJSON(options)
        : null,
      portfolio: this.portfolio ? this.portfolio.toProtobufJSON(options) : null,
      answers: (this.answers || []).map(m => m.toProtobufJSON(options)),
      id: this.id
    };
  }
}
export module UserResultCalculateModel {
  /**
   * Standard JavaScript object representation for UserResultCalculateModel
   */
  export interface AsObject {
    plan?: UserResultCalculatePlanModel.AsObject;
    goal?: UserResultCalculateGoalModel.AsObject;
    user?: UserResultCalculateUserModel.AsObject;
    econsult?: UserResultCalculateEconsultModel.AsObject;
    investmentStrategy?: InvestmentStrategyModel.AsObject;
    portfolio?: PortfolioModel.AsObject;
    answers?: UserResultCalculateAnswerModel.AsObject[];
    id: number;
  }

  /**
   * Protobuf JSON representation for UserResultCalculateModel
   */
  export interface AsProtobufJSON {
    plan: UserResultCalculatePlanModel.AsProtobufJSON | null;
    goal: UserResultCalculateGoalModel.AsProtobufJSON | null;
    user: UserResultCalculateUserModel.AsProtobufJSON | null;
    econsult: UserResultCalculateEconsultModel.AsProtobufJSON | null;
    investmentStrategy: InvestmentStrategyModel.AsProtobufJSON | null;
    portfolio: PortfolioModel.AsProtobufJSON | null;
    answers: UserResultCalculateAnswerModel.AsProtobufJSON[] | null;
    id: number;
  }
}

/**
 * Message implementation for common_message.UserResultCalculatePlanModel
 */
export class UserResultCalculatePlanModel implements GrpcMessage {
  static id = 'common_message.UserResultCalculatePlanModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserResultCalculatePlanModel();
    UserResultCalculatePlanModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserResultCalculatePlanModel) {
    _instance.year = _instance.year || 0;
    _instance.profile = _instance.profile || undefined;
    _instance.initialInvestment = _instance.initialInvestment || 0;
    _instance.monthlyContribution = _instance.monthlyContribution || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserResultCalculatePlanModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.year = _reader.readInt32();
          break;
        case 2:
          _instance.profile = new RiskProfileModel();
          _reader.readMessage(
            _instance.profile,
            RiskProfileModel.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.initialInvestment = _reader.readInt32();
          break;
        case 4:
          _instance.monthlyContribution = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    UserResultCalculatePlanModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserResultCalculatePlanModel,
    _writer: BinaryWriter
  ) {
    if (_instance.year) {
      _writer.writeInt32(1, _instance.year);
    }
    if (_instance.profile) {
      _writer.writeMessage(
        2,
        _instance.profile as any,
        RiskProfileModel.serializeBinaryToWriter
      );
    }
    if (_instance.initialInvestment) {
      _writer.writeInt32(3, _instance.initialInvestment);
    }
    if (_instance.monthlyContribution) {
      _writer.writeInt32(4, _instance.monthlyContribution);
    }
  }

  private _year: number;
  private _profile?: RiskProfileModel;
  private _initialInvestment: number;
  private _monthlyContribution: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserResultCalculatePlanModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UserResultCalculatePlanModel.AsObject>
  ) {
    _value = _value || {};
    this.year = _value.year;
    this.profile = _value.profile
      ? new RiskProfileModel(_value.profile)
      : undefined;
    this.initialInvestment = _value.initialInvestment;
    this.monthlyContribution = _value.monthlyContribution;
    UserResultCalculatePlanModel.refineValues(this);
  }
  get year(): number {
    return this._year;
  }
  set year(value: number) {
    this._year = value;
  }
  get profile(): RiskProfileModel | undefined {
    return this._profile;
  }
  set profile(value: RiskProfileModel | undefined) {
    this._profile = value;
  }
  get initialInvestment(): number {
    return this._initialInvestment;
  }
  set initialInvestment(value: number) {
    this._initialInvestment = value;
  }
  get monthlyContribution(): number {
    return this._monthlyContribution;
  }
  set monthlyContribution(value: number) {
    this._monthlyContribution = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserResultCalculatePlanModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserResultCalculatePlanModel.AsObject {
    return {
      year: this.year,
      profile: this.profile ? this.profile.toObject() : undefined,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserResultCalculatePlanModel.AsProtobufJSON {
    return {
      year: this.year,
      profile: this.profile ? this.profile.toProtobufJSON(options) : null,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution
    };
  }
}
export module UserResultCalculatePlanModel {
  /**
   * Standard JavaScript object representation for UserResultCalculatePlanModel
   */
  export interface AsObject {
    year: number;
    profile?: RiskProfileModel.AsObject;
    initialInvestment: number;
    monthlyContribution: number;
  }

  /**
   * Protobuf JSON representation for UserResultCalculatePlanModel
   */
  export interface AsProtobufJSON {
    year: number;
    profile: RiskProfileModel.AsProtobufJSON | null;
    initialInvestment: number;
    monthlyContribution: number;
  }
}

/**
 * Message implementation for common_message.UserResultCalculateGoalModel
 */
export class UserResultCalculateGoalModel implements GrpcMessage {
  static id = 'common_message.UserResultCalculateGoalModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserResultCalculateGoalModel();
    UserResultCalculateGoalModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserResultCalculateGoalModel) {
    _instance.initialInvestment = _instance.initialInvestment || 0;
    _instance.years = _instance.years || 0;
    _instance.targetAmount = _instance.targetAmount || 0;
    _instance.title = _instance.title || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserResultCalculateGoalModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.initialInvestment = _reader.readInt32();
          break;
        case 2:
          _instance.years = _reader.readInt32();
          break;
        case 3:
          _instance.targetAmount = _reader.readInt32();
          break;
        case 4:
          _instance.title = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserResultCalculateGoalModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserResultCalculateGoalModel,
    _writer: BinaryWriter
  ) {
    if (_instance.initialInvestment) {
      _writer.writeInt32(1, _instance.initialInvestment);
    }
    if (_instance.years) {
      _writer.writeInt32(2, _instance.years);
    }
    if (_instance.targetAmount) {
      _writer.writeInt32(3, _instance.targetAmount);
    }
    if (_instance.title) {
      _writer.writeString(4, _instance.title);
    }
  }

  private _initialInvestment: number;
  private _years: number;
  private _targetAmount: number;
  private _title: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserResultCalculateGoalModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UserResultCalculateGoalModel.AsObject>
  ) {
    _value = _value || {};
    this.initialInvestment = _value.initialInvestment;
    this.years = _value.years;
    this.targetAmount = _value.targetAmount;
    this.title = _value.title;
    UserResultCalculateGoalModel.refineValues(this);
  }
  get initialInvestment(): number {
    return this._initialInvestment;
  }
  set initialInvestment(value: number) {
    this._initialInvestment = value;
  }
  get years(): number {
    return this._years;
  }
  set years(value: number) {
    this._years = value;
  }
  get targetAmount(): number {
    return this._targetAmount;
  }
  set targetAmount(value: number) {
    this._targetAmount = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserResultCalculateGoalModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserResultCalculateGoalModel.AsObject {
    return {
      initialInvestment: this.initialInvestment,
      years: this.years,
      targetAmount: this.targetAmount,
      title: this.title
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserResultCalculateGoalModel.AsProtobufJSON {
    return {
      initialInvestment: this.initialInvestment,
      years: this.years,
      targetAmount: this.targetAmount,
      title: this.title
    };
  }
}
export module UserResultCalculateGoalModel {
  /**
   * Standard JavaScript object representation for UserResultCalculateGoalModel
   */
  export interface AsObject {
    initialInvestment: number;
    years: number;
    targetAmount: number;
    title: string;
  }

  /**
   * Protobuf JSON representation for UserResultCalculateGoalModel
   */
  export interface AsProtobufJSON {
    initialInvestment: number;
    years: number;
    targetAmount: number;
    title: string;
  }
}

/**
 * Message implementation for common_message.UserResultCalculateUserModel
 */
export class UserResultCalculateUserModel implements GrpcMessage {
  static id = 'common_message.UserResultCalculateUserModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserResultCalculateUserModel();
    UserResultCalculateUserModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserResultCalculateUserModel) {
    _instance.birthday = _instance.birthday || undefined;
    _instance.email = _instance.email || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserResultCalculateUserModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.birthday = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.birthday,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.email = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserResultCalculateUserModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserResultCalculateUserModel,
    _writer: BinaryWriter
  ) {
    if (_instance.birthday) {
      _writer.writeMessage(
        1,
        _instance.birthday as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.email) {
      _writer.writeString(2, _instance.email);
    }
  }

  private _birthday?: googleProtobuf001.Timestamp;
  private _email: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserResultCalculateUserModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UserResultCalculateUserModel.AsObject>
  ) {
    _value = _value || {};
    this.birthday = _value.birthday
      ? new googleProtobuf001.Timestamp(_value.birthday)
      : undefined;
    this.email = _value.email;
    UserResultCalculateUserModel.refineValues(this);
  }
  get birthday(): googleProtobuf001.Timestamp | undefined {
    return this._birthday;
  }
  set birthday(value: googleProtobuf001.Timestamp | undefined) {
    this._birthday = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserResultCalculateUserModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserResultCalculateUserModel.AsObject {
    return {
      birthday: this.birthday ? this.birthday.toObject() : undefined,
      email: this.email
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserResultCalculateUserModel.AsProtobufJSON {
    return {
      birthday: this.birthday ? this.birthday.toProtobufJSON(options) : null,
      email: this.email
    };
  }
}
export module UserResultCalculateUserModel {
  /**
   * Standard JavaScript object representation for UserResultCalculateUserModel
   */
  export interface AsObject {
    birthday?: googleProtobuf001.Timestamp.AsObject;
    email: string;
  }

  /**
   * Protobuf JSON representation for UserResultCalculateUserModel
   */
  export interface AsProtobufJSON {
    birthday: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    email: string;
  }
}

/**
 * Message implementation for common_message.UserResultCalculateEconsultModel
 */
export class UserResultCalculateEconsultModel implements GrpcMessage {
  static id = 'common_message.UserResultCalculateEconsultModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserResultCalculateEconsultModel();
    UserResultCalculateEconsultModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserResultCalculateEconsultModel) {
    _instance.question1 = _instance.question1 || '';
    _instance.question2 = _instance.question2 || '';
    _instance.question4 = _instance.question4 || '';
    _instance.question3 = _instance.question3 || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserResultCalculateEconsultModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.question1 = _reader.readString();
          break;
        case 2:
          _instance.question2 = _reader.readString();
          break;
        case 3:
          _instance.question4 = _reader.readString();
          break;
        case 4:
          _instance.question3 = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserResultCalculateEconsultModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserResultCalculateEconsultModel,
    _writer: BinaryWriter
  ) {
    if (_instance.question1) {
      _writer.writeString(1, _instance.question1);
    }
    if (_instance.question2) {
      _writer.writeString(2, _instance.question2);
    }
    if (_instance.question4) {
      _writer.writeString(3, _instance.question4);
    }
    if (_instance.question3) {
      _writer.writeString(4, _instance.question3);
    }
  }

  private _question1: string;
  private _question2: string;
  private _question4: string;
  private _question3: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserResultCalculateEconsultModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UserResultCalculateEconsultModel.AsObject>
  ) {
    _value = _value || {};
    this.question1 = _value.question1;
    this.question2 = _value.question2;
    this.question4 = _value.question4;
    this.question3 = _value.question3;
    UserResultCalculateEconsultModel.refineValues(this);
  }
  get question1(): string {
    return this._question1;
  }
  set question1(value: string) {
    this._question1 = value;
  }
  get question2(): string {
    return this._question2;
  }
  set question2(value: string) {
    this._question2 = value;
  }
  get question4(): string {
    return this._question4;
  }
  set question4(value: string) {
    this._question4 = value;
  }
  get question3(): string {
    return this._question3;
  }
  set question3(value: string) {
    this._question3 = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserResultCalculateEconsultModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserResultCalculateEconsultModel.AsObject {
    return {
      question1: this.question1,
      question2: this.question2,
      question4: this.question4,
      question3: this.question3
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserResultCalculateEconsultModel.AsProtobufJSON {
    return {
      question1: this.question1,
      question2: this.question2,
      question4: this.question4,
      question3: this.question3
    };
  }
}
export module UserResultCalculateEconsultModel {
  /**
   * Standard JavaScript object representation for UserResultCalculateEconsultModel
   */
  export interface AsObject {
    question1: string;
    question2: string;
    question4: string;
    question3: string;
  }

  /**
   * Protobuf JSON representation for UserResultCalculateEconsultModel
   */
  export interface AsProtobufJSON {
    question1: string;
    question2: string;
    question4: string;
    question3: string;
  }
}

/**
 * Message implementation for common_message.UserResultCalculateAnswerModel
 */
export class UserResultCalculateAnswerModel implements GrpcMessage {
  static id = 'common_message.UserResultCalculateAnswerModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserResultCalculateAnswerModel();
    UserResultCalculateAnswerModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserResultCalculateAnswerModel) {
    _instance.questionId = _instance.questionId || 0;
    _instance.value = _instance.value || '';
    _instance.answers = _instance.answers || [];
    _instance.question = _instance.question || undefined;
    _instance.answeredAt = _instance.answeredAt || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserResultCalculateAnswerModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.questionId = _reader.readInt32();
          break;
        case 2:
          _instance.value = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new UserResultCalculateAnswerModel();
          _reader.readMessage(
            messageInitializer3,
            UserResultCalculateAnswerModel.deserializeBinaryFromReader
          );
          (_instance.answers = _instance.answers || []).push(
            messageInitializer3
          );
          break;
        case 4:
          _instance.question = new QuestionModel();
          _reader.readMessage(
            _instance.question,
            QuestionModel.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.answeredAt = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.answeredAt,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UserResultCalculateAnswerModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UserResultCalculateAnswerModel,
    _writer: BinaryWriter
  ) {
    if (_instance.questionId) {
      _writer.writeInt32(1, _instance.questionId);
    }
    if (_instance.value) {
      _writer.writeString(2, _instance.value);
    }
    if (_instance.answers && _instance.answers.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.answers as any,
        UserResultCalculateAnswerModel.serializeBinaryToWriter
      );
    }
    if (_instance.question) {
      _writer.writeMessage(
        4,
        _instance.question as any,
        QuestionModel.serializeBinaryToWriter
      );
    }
    if (_instance.answeredAt) {
      _writer.writeMessage(
        5,
        _instance.answeredAt as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _questionId: number;
  private _value: string;
  private _answers?: UserResultCalculateAnswerModel[];
  private _question?: QuestionModel;
  private _answeredAt?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserResultCalculateAnswerModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UserResultCalculateAnswerModel.AsObject>
  ) {
    _value = _value || {};
    this.questionId = _value.questionId;
    this.value = _value.value;
    this.answers = (_value.answers || []).map(
      m => new UserResultCalculateAnswerModel(m)
    );
    this.question = _value.question
      ? new QuestionModel(_value.question)
      : undefined;
    this.answeredAt = _value.answeredAt
      ? new googleProtobuf001.Timestamp(_value.answeredAt)
      : undefined;
    UserResultCalculateAnswerModel.refineValues(this);
  }
  get questionId(): number {
    return this._questionId;
  }
  set questionId(value: number) {
    this._questionId = value;
  }
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
  }
  get answers(): UserResultCalculateAnswerModel[] | undefined {
    return this._answers;
  }
  set answers(value: UserResultCalculateAnswerModel[] | undefined) {
    this._answers = value;
  }
  get question(): QuestionModel | undefined {
    return this._question;
  }
  set question(value: QuestionModel | undefined) {
    this._question = value;
  }
  get answeredAt(): googleProtobuf001.Timestamp | undefined {
    return this._answeredAt;
  }
  set answeredAt(value: googleProtobuf001.Timestamp | undefined) {
    this._answeredAt = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserResultCalculateAnswerModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserResultCalculateAnswerModel.AsObject {
    return {
      questionId: this.questionId,
      value: this.value,
      answers: (this.answers || []).map(m => m.toObject()),
      question: this.question ? this.question.toObject() : undefined,
      answeredAt: this.answeredAt ? this.answeredAt.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserResultCalculateAnswerModel.AsProtobufJSON {
    return {
      questionId: this.questionId,
      value: this.value,
      answers: (this.answers || []).map(m => m.toProtobufJSON(options)),
      question: this.question ? this.question.toProtobufJSON(options) : null,
      answeredAt: this.answeredAt
        ? this.answeredAt.toProtobufJSON(options)
        : null
    };
  }
}
export module UserResultCalculateAnswerModel {
  /**
   * Standard JavaScript object representation for UserResultCalculateAnswerModel
   */
  export interface AsObject {
    questionId: number;
    value: string;
    answers?: UserResultCalculateAnswerModel.AsObject[];
    question?: QuestionModel.AsObject;
    answeredAt?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for UserResultCalculateAnswerModel
   */
  export interface AsProtobufJSON {
    questionId: number;
    value: string;
    answers: UserResultCalculateAnswerModel.AsProtobufJSON[] | null;
    question: QuestionModel.AsProtobufJSON | null;
    answeredAt: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.SegmentationPlanModel
 */
export class SegmentationPlanModel implements GrpcMessage {
  static id = 'common_message.SegmentationPlanModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SegmentationPlanModel();
    SegmentationPlanModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SegmentationPlanModel) {
    _instance.uuid = _instance.uuid || '';
    _instance.name = _instance.name || '';
    _instance.id = _instance.id || 0;
    _instance.maxAdvice = _instance.maxAdvice || 0;
    _instance.maxNetdeposit = _instance.maxNetdeposit || 0;
    _instance.minNetdeposit = _instance.minNetdeposit || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SegmentationPlanModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.uuid = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.id = _reader.readInt32();
          break;
        case 4:
          _instance.maxAdvice = _reader.readInt32();
          break;
        case 5:
          _instance.maxNetdeposit = _reader.readDouble();
          break;
        case 6:
          _instance.minNetdeposit = _reader.readDouble();
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SegmentationPlanModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SegmentationPlanModel,
    _writer: BinaryWriter
  ) {
    if (_instance.uuid) {
      _writer.writeString(1, _instance.uuid);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.id) {
      _writer.writeInt32(3, _instance.id);
    }
    if (_instance.maxAdvice) {
      _writer.writeInt32(4, _instance.maxAdvice);
    }
    if (_instance.maxNetdeposit) {
      _writer.writeDouble(5, _instance.maxNetdeposit);
    }
    if (_instance.minNetdeposit) {
      _writer.writeDouble(6, _instance.minNetdeposit);
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _uuid: string;
  private _name: string;
  private _id: number;
  private _maxAdvice: number;
  private _maxNetdeposit: number;
  private _minNetdeposit: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SegmentationPlanModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<SegmentationPlanModel.AsObject>) {
    _value = _value || {};
    this.uuid = _value.uuid;
    this.name = _value.name;
    this.id = _value.id;
    this.maxAdvice = _value.maxAdvice;
    this.maxNetdeposit = _value.maxNetdeposit;
    this.minNetdeposit = _value.minNetdeposit;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    SegmentationPlanModel.refineValues(this);
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get maxAdvice(): number {
    return this._maxAdvice;
  }
  set maxAdvice(value: number) {
    this._maxAdvice = value;
  }
  get maxNetdeposit(): number {
    return this._maxNetdeposit;
  }
  set maxNetdeposit(value: number) {
    this._maxNetdeposit = value;
  }
  get minNetdeposit(): number {
    return this._minNetdeposit;
  }
  set minNetdeposit(value: number) {
    this._minNetdeposit = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SegmentationPlanModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SegmentationPlanModel.AsObject {
    return {
      uuid: this.uuid,
      name: this.name,
      id: this.id,
      maxAdvice: this.maxAdvice,
      maxNetdeposit: this.maxNetdeposit,
      minNetdeposit: this.minNetdeposit,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SegmentationPlanModel.AsProtobufJSON {
    return {
      uuid: this.uuid,
      name: this.name,
      id: this.id,
      maxAdvice: this.maxAdvice,
      maxNetdeposit: this.maxNetdeposit,
      minNetdeposit: this.minNetdeposit,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module SegmentationPlanModel {
  /**
   * Standard JavaScript object representation for SegmentationPlanModel
   */
  export interface AsObject {
    uuid: string;
    name: string;
    id: number;
    maxAdvice: number;
    maxNetdeposit: number;
    minNetdeposit: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for SegmentationPlanModel
   */
  export interface AsProtobufJSON {
    uuid: string;
    name: string;
    id: number;
    maxAdvice: number;
    maxNetdeposit: number;
    minNetdeposit: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.ConfigModel
 */
export class ConfigModel implements GrpcMessage {
  static id = 'common_message.ConfigModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfigModel();
    ConfigModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfigModel) {
    _instance.forceSeed = _instance.forceSeed || false;
    _instance.enablePipedriveIntegration =
      _instance.enablePipedriveIntegration || false;
    _instance.id = _instance.id || 0;
    _instance.termsAndConditionsId = _instance.termsAndConditionsId || 0;
    _instance.commercialMandateId = _instance.commercialMandateId || 0;
    _instance.defaultAdvisorId = _instance.defaultAdvisorId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.termsAndConditions = _instance.termsAndConditions || undefined;
    _instance.commercialMandate = _instance.commercialMandate || undefined;
    _instance.codeOfConduct = _instance.codeOfConduct || undefined;
    _instance.codeOfConductId = _instance.codeOfConductId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfigModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.forceSeed = _reader.readBool();
          break;
        case 2:
          _instance.enablePipedriveIntegration = _reader.readBool();
          break;
        case 3:
          _instance.id = _reader.readInt32();
          break;
        case 4:
          _instance.termsAndConditionsId = _reader.readInt32();
          break;
        case 5:
          _instance.commercialMandateId = _reader.readInt32();
          break;
        case 6:
          _instance.defaultAdvisorId = _reader.readInt32();
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.termsAndConditions = new FileStorageModel();
          _reader.readMessage(
            _instance.termsAndConditions,
            FileStorageModel.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.commercialMandate = new FileStorageModel();
          _reader.readMessage(
            _instance.commercialMandate,
            FileStorageModel.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.codeOfConduct = new FileStorageModel();
          _reader.readMessage(
            _instance.codeOfConduct,
            FileStorageModel.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.codeOfConductId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.codeOfConductId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ConfigModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfigModel,
    _writer: BinaryWriter
  ) {
    if (_instance.forceSeed) {
      _writer.writeBool(1, _instance.forceSeed);
    }
    if (_instance.enablePipedriveIntegration) {
      _writer.writeBool(2, _instance.enablePipedriveIntegration);
    }
    if (_instance.id) {
      _writer.writeInt32(3, _instance.id);
    }
    if (_instance.termsAndConditionsId) {
      _writer.writeInt32(4, _instance.termsAndConditionsId);
    }
    if (_instance.commercialMandateId) {
      _writer.writeInt32(5, _instance.commercialMandateId);
    }
    if (_instance.defaultAdvisorId) {
      _writer.writeInt32(6, _instance.defaultAdvisorId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.termsAndConditions) {
      _writer.writeMessage(
        9,
        _instance.termsAndConditions as any,
        FileStorageModel.serializeBinaryToWriter
      );
    }
    if (_instance.commercialMandate) {
      _writer.writeMessage(
        10,
        _instance.commercialMandate as any,
        FileStorageModel.serializeBinaryToWriter
      );
    }
    if (_instance.codeOfConduct) {
      _writer.writeMessage(
        11,
        _instance.codeOfConduct as any,
        FileStorageModel.serializeBinaryToWriter
      );
    }
    if (_instance.codeOfConductId) {
      _writer.writeMessage(
        12,
        _instance.codeOfConductId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _forceSeed: boolean;
  private _enablePipedriveIntegration: boolean;
  private _id: number;
  private _termsAndConditionsId: number;
  private _commercialMandateId: number;
  private _defaultAdvisorId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _termsAndConditions?: FileStorageModel;
  private _commercialMandate?: FileStorageModel;
  private _codeOfConduct?: FileStorageModel;
  private _codeOfConductId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfigModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ConfigModel.AsObject>) {
    _value = _value || {};
    this.forceSeed = _value.forceSeed;
    this.enablePipedriveIntegration = _value.enablePipedriveIntegration;
    this.id = _value.id;
    this.termsAndConditionsId = _value.termsAndConditionsId;
    this.commercialMandateId = _value.commercialMandateId;
    this.defaultAdvisorId = _value.defaultAdvisorId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.termsAndConditions = _value.termsAndConditions
      ? new FileStorageModel(_value.termsAndConditions)
      : undefined;
    this.commercialMandate = _value.commercialMandate
      ? new FileStorageModel(_value.commercialMandate)
      : undefined;
    this.codeOfConduct = _value.codeOfConduct
      ? new FileStorageModel(_value.codeOfConduct)
      : undefined;
    this.codeOfConductId = _value.codeOfConductId
      ? new googleProtobuf000.Int32Value(_value.codeOfConductId)
      : undefined;
    ConfigModel.refineValues(this);
  }
  get forceSeed(): boolean {
    return this._forceSeed;
  }
  set forceSeed(value: boolean) {
    this._forceSeed = value;
  }
  get enablePipedriveIntegration(): boolean {
    return this._enablePipedriveIntegration;
  }
  set enablePipedriveIntegration(value: boolean) {
    this._enablePipedriveIntegration = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get termsAndConditionsId(): number {
    return this._termsAndConditionsId;
  }
  set termsAndConditionsId(value: number) {
    this._termsAndConditionsId = value;
  }
  get commercialMandateId(): number {
    return this._commercialMandateId;
  }
  set commercialMandateId(value: number) {
    this._commercialMandateId = value;
  }
  get defaultAdvisorId(): number {
    return this._defaultAdvisorId;
  }
  set defaultAdvisorId(value: number) {
    this._defaultAdvisorId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get termsAndConditions(): FileStorageModel | undefined {
    return this._termsAndConditions;
  }
  set termsAndConditions(value: FileStorageModel | undefined) {
    this._termsAndConditions = value;
  }
  get commercialMandate(): FileStorageModel | undefined {
    return this._commercialMandate;
  }
  set commercialMandate(value: FileStorageModel | undefined) {
    this._commercialMandate = value;
  }
  get codeOfConduct(): FileStorageModel | undefined {
    return this._codeOfConduct;
  }
  set codeOfConduct(value: FileStorageModel | undefined) {
    this._codeOfConduct = value;
  }
  get codeOfConductId(): googleProtobuf000.Int32Value | undefined {
    return this._codeOfConductId;
  }
  set codeOfConductId(value: googleProtobuf000.Int32Value | undefined) {
    this._codeOfConductId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfigModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfigModel.AsObject {
    return {
      forceSeed: this.forceSeed,
      enablePipedriveIntegration: this.enablePipedriveIntegration,
      id: this.id,
      termsAndConditionsId: this.termsAndConditionsId,
      commercialMandateId: this.commercialMandateId,
      defaultAdvisorId: this.defaultAdvisorId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      termsAndConditions: this.termsAndConditions
        ? this.termsAndConditions.toObject()
        : undefined,
      commercialMandate: this.commercialMandate
        ? this.commercialMandate.toObject()
        : undefined,
      codeOfConduct: this.codeOfConduct
        ? this.codeOfConduct.toObject()
        : undefined,
      codeOfConductId: this.codeOfConductId
        ? this.codeOfConductId.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfigModel.AsProtobufJSON {
    return {
      forceSeed: this.forceSeed,
      enablePipedriveIntegration: this.enablePipedriveIntegration,
      id: this.id,
      termsAndConditionsId: this.termsAndConditionsId,
      commercialMandateId: this.commercialMandateId,
      defaultAdvisorId: this.defaultAdvisorId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      termsAndConditions: this.termsAndConditions
        ? this.termsAndConditions.toProtobufJSON(options)
        : null,
      commercialMandate: this.commercialMandate
        ? this.commercialMandate.toProtobufJSON(options)
        : null,
      codeOfConduct: this.codeOfConduct
        ? this.codeOfConduct.toProtobufJSON(options)
        : null,
      codeOfConductId: this.codeOfConductId
        ? this.codeOfConductId.toProtobufJSON(options)
        : null
    };
  }
}
export module ConfigModel {
  /**
   * Standard JavaScript object representation for ConfigModel
   */
  export interface AsObject {
    forceSeed: boolean;
    enablePipedriveIntegration: boolean;
    id: number;
    termsAndConditionsId: number;
    commercialMandateId: number;
    defaultAdvisorId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    termsAndConditions?: FileStorageModel.AsObject;
    commercialMandate?: FileStorageModel.AsObject;
    codeOfConduct?: FileStorageModel.AsObject;
    codeOfConductId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for ConfigModel
   */
  export interface AsProtobufJSON {
    forceSeed: boolean;
    enablePipedriveIntegration: boolean;
    id: number;
    termsAndConditionsId: number;
    commercialMandateId: number;
    defaultAdvisorId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    termsAndConditions: FileStorageModel.AsProtobufJSON | null;
    commercialMandate: FileStorageModel.AsProtobufJSON | null;
    codeOfConduct: FileStorageModel.AsProtobufJSON | null;
    codeOfConductId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.RegionModel
 */
export class RegionModel implements GrpcMessage {
  static id = 'common_message.RegionModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RegionModel();
    RegionModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RegionModel) {
    _instance.name = _instance.name || '';
    _instance.uuid = _instance.uuid || undefined;
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.communes = _instance.communes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RegionModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.uuid = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.uuid,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.id = _reader.readInt32();
          break;
        case 4:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          const messageInitializer6 = new CommuneModel();
          _reader.readMessage(
            messageInitializer6,
            CommuneModel.deserializeBinaryFromReader
          );
          (_instance.communes = _instance.communes || []).push(
            messageInitializer6
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RegionModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RegionModel,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.uuid) {
      _writer.writeMessage(
        2,
        _instance.uuid as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(3, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        4,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        5,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.communes && _instance.communes.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.communes as any,
        CommuneModel.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _uuid?: googleProtobuf000.Int32Value;
  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _communes?: CommuneModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RegionModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<RegionModel.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.uuid = _value.uuid
      ? new googleProtobuf000.Int32Value(_value.uuid)
      : undefined;
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.communes = (_value.communes || []).map(m => new CommuneModel(m));
    RegionModel.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get uuid(): googleProtobuf000.Int32Value | undefined {
    return this._uuid;
  }
  set uuid(value: googleProtobuf000.Int32Value | undefined) {
    this._uuid = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get communes(): CommuneModel[] | undefined {
    return this._communes;
  }
  set communes(value: CommuneModel[] | undefined) {
    this._communes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RegionModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RegionModel.AsObject {
    return {
      name: this.name,
      uuid: this.uuid ? this.uuid.toObject() : undefined,
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      communes: (this.communes || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RegionModel.AsProtobufJSON {
    return {
      name: this.name,
      uuid: this.uuid ? this.uuid.toProtobufJSON(options) : null,
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      communes: (this.communes || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module RegionModel {
  /**
   * Standard JavaScript object representation for RegionModel
   */
  export interface AsObject {
    name: string;
    uuid?: googleProtobuf000.Int32Value.AsObject;
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    communes?: CommuneModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for RegionModel
   */
  export interface AsProtobufJSON {
    name: string;
    uuid: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    communes: CommuneModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for common_message.CommuneModel
 */
export class CommuneModel implements GrpcMessage {
  static id = 'common_message.CommuneModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommuneModel();
    CommuneModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommuneModel) {
    _instance.name = _instance.name || '';
    _instance.uuid = _instance.uuid || 0;
    _instance.city = _instance.city || '';
    _instance.zipcode = _instance.zipcode || undefined;
    _instance.id = _instance.id || 0;
    _instance.regionId = _instance.regionId || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.region = _instance.region || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommuneModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.uuid = _reader.readInt32();
          break;
        case 3:
          _instance.city = _reader.readString();
          break;
        case 4:
          _instance.zipcode = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.zipcode,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.id = _reader.readInt32();
          break;
        case 6:
          _instance.regionId = _reader.readInt32();
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.region = new RegionModel();
          _reader.readMessage(
            _instance.region,
            RegionModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CommuneModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommuneModel,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.uuid) {
      _writer.writeInt32(2, _instance.uuid);
    }
    if (_instance.city) {
      _writer.writeString(3, _instance.city);
    }
    if (_instance.zipcode) {
      _writer.writeMessage(
        4,
        _instance.zipcode as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(5, _instance.id);
    }
    if (_instance.regionId) {
      _writer.writeInt32(6, _instance.regionId);
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.region) {
      _writer.writeMessage(
        9,
        _instance.region as any,
        RegionModel.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _uuid: number;
  private _city: string;
  private _zipcode?: googleProtobuf000.StringValue;
  private _id: number;
  private _regionId: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _region?: RegionModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommuneModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommuneModel.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.uuid = _value.uuid;
    this.city = _value.city;
    this.zipcode = _value.zipcode
      ? new googleProtobuf000.StringValue(_value.zipcode)
      : undefined;
    this.id = _value.id;
    this.regionId = _value.regionId;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.region = _value.region ? new RegionModel(_value.region) : undefined;
    CommuneModel.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get uuid(): number {
    return this._uuid;
  }
  set uuid(value: number) {
    this._uuid = value;
  }
  get city(): string {
    return this._city;
  }
  set city(value: string) {
    this._city = value;
  }
  get zipcode(): googleProtobuf000.StringValue | undefined {
    return this._zipcode;
  }
  set zipcode(value: googleProtobuf000.StringValue | undefined) {
    this._zipcode = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get regionId(): number {
    return this._regionId;
  }
  set regionId(value: number) {
    this._regionId = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get region(): RegionModel | undefined {
    return this._region;
  }
  set region(value: RegionModel | undefined) {
    this._region = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommuneModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommuneModel.AsObject {
    return {
      name: this.name,
      uuid: this.uuid,
      city: this.city,
      zipcode: this.zipcode ? this.zipcode.toObject() : undefined,
      id: this.id,
      regionId: this.regionId,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      region: this.region ? this.region.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommuneModel.AsProtobufJSON {
    return {
      name: this.name,
      uuid: this.uuid,
      city: this.city,
      zipcode: this.zipcode ? this.zipcode.toProtobufJSON(options) : null,
      id: this.id,
      regionId: this.regionId,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      region: this.region ? this.region.toProtobufJSON(options) : null
    };
  }
}
export module CommuneModel {
  /**
   * Standard JavaScript object representation for CommuneModel
   */
  export interface AsObject {
    name: string;
    uuid: number;
    city: string;
    zipcode?: googleProtobuf000.StringValue.AsObject;
    id: number;
    regionId: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    region?: RegionModel.AsObject;
  }

  /**
   * Protobuf JSON representation for CommuneModel
   */
  export interface AsProtobufJSON {
    name: string;
    uuid: number;
    city: string;
    zipcode: googleProtobuf000.StringValue.AsProtobufJSON | null;
    id: number;
    regionId: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    region: RegionModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.HolidayAdvisorModel
 */
export class HolidayAdvisorModel implements GrpcMessage {
  static id = 'common_message.HolidayAdvisorModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new HolidayAdvisorModel();
    HolidayAdvisorModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: HolidayAdvisorModel) {
    _instance.dateFrom = _instance.dateFrom || undefined;
    _instance.dateTo = _instance.dateTo || undefined;
    _instance.description = _instance.description || '';
    _instance.advisorId = _instance.advisorId || 0;
    _instance.replacementAdvisorId = _instance.replacementAdvisorId || 0;
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.advisor = _instance.advisor || undefined;
    _instance.replacementAdvisor = _instance.replacementAdvisor || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: HolidayAdvisorModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.dateFrom = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateFrom,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.dateTo = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateTo,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.advisorId = _reader.readInt32();
          break;
        case 5:
          _instance.replacementAdvisorId = _reader.readInt32();
          break;
        case 6:
          _instance.id = _reader.readInt32();
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.advisor = new UserModel();
          _reader.readMessage(
            _instance.advisor,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.replacementAdvisor = new UserModel();
          _reader.readMessage(
            _instance.replacementAdvisor,
            UserModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    HolidayAdvisorModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: HolidayAdvisorModel,
    _writer: BinaryWriter
  ) {
    if (_instance.dateFrom) {
      _writer.writeMessage(
        1,
        _instance.dateFrom as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.dateTo) {
      _writer.writeMessage(
        2,
        _instance.dateTo as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.advisorId) {
      _writer.writeInt32(4, _instance.advisorId);
    }
    if (_instance.replacementAdvisorId) {
      _writer.writeInt32(5, _instance.replacementAdvisorId);
    }
    if (_instance.id) {
      _writer.writeInt32(6, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advisor) {
      _writer.writeMessage(
        9,
        _instance.advisor as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.replacementAdvisor) {
      _writer.writeMessage(
        10,
        _instance.replacementAdvisor as any,
        UserModel.serializeBinaryToWriter
      );
    }
  }

  private _dateFrom?: googleProtobuf001.Timestamp;
  private _dateTo?: googleProtobuf001.Timestamp;
  private _description: string;
  private _advisorId: number;
  private _replacementAdvisorId: number;
  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _advisor?: UserModel;
  private _replacementAdvisor?: UserModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of HolidayAdvisorModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<HolidayAdvisorModel.AsObject>) {
    _value = _value || {};
    this.dateFrom = _value.dateFrom
      ? new googleProtobuf001.Timestamp(_value.dateFrom)
      : undefined;
    this.dateTo = _value.dateTo
      ? new googleProtobuf001.Timestamp(_value.dateTo)
      : undefined;
    this.description = _value.description;
    this.advisorId = _value.advisorId;
    this.replacementAdvisorId = _value.replacementAdvisorId;
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.advisor = _value.advisor ? new UserModel(_value.advisor) : undefined;
    this.replacementAdvisor = _value.replacementAdvisor
      ? new UserModel(_value.replacementAdvisor)
      : undefined;
    HolidayAdvisorModel.refineValues(this);
  }
  get dateFrom(): googleProtobuf001.Timestamp | undefined {
    return this._dateFrom;
  }
  set dateFrom(value: googleProtobuf001.Timestamp | undefined) {
    this._dateFrom = value;
  }
  get dateTo(): googleProtobuf001.Timestamp | undefined {
    return this._dateTo;
  }
  set dateTo(value: googleProtobuf001.Timestamp | undefined) {
    this._dateTo = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get advisorId(): number {
    return this._advisorId;
  }
  set advisorId(value: number) {
    this._advisorId = value;
  }
  get replacementAdvisorId(): number {
    return this._replacementAdvisorId;
  }
  set replacementAdvisorId(value: number) {
    this._replacementAdvisorId = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get advisor(): UserModel | undefined {
    return this._advisor;
  }
  set advisor(value: UserModel | undefined) {
    this._advisor = value;
  }
  get replacementAdvisor(): UserModel | undefined {
    return this._replacementAdvisor;
  }
  set replacementAdvisor(value: UserModel | undefined) {
    this._replacementAdvisor = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    HolidayAdvisorModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): HolidayAdvisorModel.AsObject {
    return {
      dateFrom: this.dateFrom ? this.dateFrom.toObject() : undefined,
      dateTo: this.dateTo ? this.dateTo.toObject() : undefined,
      description: this.description,
      advisorId: this.advisorId,
      replacementAdvisorId: this.replacementAdvisorId,
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      advisor: this.advisor ? this.advisor.toObject() : undefined,
      replacementAdvisor: this.replacementAdvisor
        ? this.replacementAdvisor.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): HolidayAdvisorModel.AsProtobufJSON {
    return {
      dateFrom: this.dateFrom ? this.dateFrom.toProtobufJSON(options) : null,
      dateTo: this.dateTo ? this.dateTo.toProtobufJSON(options) : null,
      description: this.description,
      advisorId: this.advisorId,
      replacementAdvisorId: this.replacementAdvisorId,
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      advisor: this.advisor ? this.advisor.toProtobufJSON(options) : null,
      replacementAdvisor: this.replacementAdvisor
        ? this.replacementAdvisor.toProtobufJSON(options)
        : null
    };
  }
}
export module HolidayAdvisorModel {
  /**
   * Standard JavaScript object representation for HolidayAdvisorModel
   */
  export interface AsObject {
    dateFrom?: googleProtobuf001.Timestamp.AsObject;
    dateTo?: googleProtobuf001.Timestamp.AsObject;
    description: string;
    advisorId: number;
    replacementAdvisorId: number;
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    advisor?: UserModel.AsObject;
    replacementAdvisor?: UserModel.AsObject;
  }

  /**
   * Protobuf JSON representation for HolidayAdvisorModel
   */
  export interface AsProtobufJSON {
    dateFrom: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    dateTo: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    description: string;
    advisorId: number;
    replacementAdvisorId: number;
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    advisor: UserModel.AsProtobufJSON | null;
    replacementAdvisor: UserModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.ListFinancialEntityResponse
 */
export class ListFinancialEntityResponse implements GrpcMessage {
  static id = 'common_message.ListFinancialEntityResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListFinancialEntityResponse();
    ListFinancialEntityResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListFinancialEntityResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListFinancialEntityResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new FinancialEntityModel();
          _reader.readMessage(
            messageInitializer1,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListFinancialEntityResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListFinancialEntityResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: FinancialEntityModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListFinancialEntityResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListFinancialEntityResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new FinancialEntityModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListFinancialEntityResponse.refineValues(this);
  }
  get values(): FinancialEntityModel[] | undefined {
    return this._values;
  }
  set values(value: FinancialEntityModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListFinancialEntityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListFinancialEntityResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListFinancialEntityResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListFinancialEntityResponse {
  /**
   * Standard JavaScript object representation for ListFinancialEntityResponse
   */
  export interface AsObject {
    values?: FinancialEntityModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListFinancialEntityResponse
   */
  export interface AsProtobufJSON {
    values: FinancialEntityModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for common_message.ListGoalCategoryResponse
 */
export class ListGoalCategoryResponse implements GrpcMessage {
  static id = 'common_message.ListGoalCategoryResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalCategoryResponse();
    ListGoalCategoryResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalCategoryResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalCategoryResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GoalCategoryModel();
          _reader.readMessage(
            messageInitializer1,
            GoalCategoryModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalCategoryResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalCategoryResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        GoalCategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: GoalCategoryModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalCategoryResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListGoalCategoryResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new GoalCategoryModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListGoalCategoryResponse.refineValues(this);
  }
  get values(): GoalCategoryModel[] | undefined {
    return this._values;
  }
  set values(value: GoalCategoryModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalCategoryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalCategoryResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalCategoryResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListGoalCategoryResponse {
  /**
   * Standard JavaScript object representation for ListGoalCategoryResponse
   */
  export interface AsObject {
    values?: GoalCategoryModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListGoalCategoryResponse
   */
  export interface AsProtobufJSON {
    values: GoalCategoryModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for common_message.ListFundingResponse
 */
export class ListFundingResponse implements GrpcMessage {
  static id = 'common_message.ListFundingResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListFundingResponse();
    ListFundingResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListFundingResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListFundingResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new FundingModel();
          _reader.readMessage(
            messageInitializer1,
            FundingModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListFundingResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListFundingResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: FundingModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListFundingResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListFundingResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new FundingModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListFundingResponse.refineValues(this);
  }
  get values(): FundingModel[] | undefined {
    return this._values;
  }
  set values(value: FundingModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListFundingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListFundingResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListFundingResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListFundingResponse {
  /**
   * Standard JavaScript object representation for ListFundingResponse
   */
  export interface AsObject {
    values?: FundingModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListFundingResponse
   */
  export interface AsProtobufJSON {
    values: FundingModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for common_message.IncomeRangeModel
 */
export class IncomeRangeModel implements GrpcMessage {
  static id = 'common_message.IncomeRangeModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IncomeRangeModel();
    IncomeRangeModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IncomeRangeModel) {
    _instance.code = _instance.code || '';
    _instance.monthly = _instance.monthly || '';
    _instance.annual = _instance.annual || '';
    _instance.id = _instance.id || 0;
    _instance.order = _instance.order || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IncomeRangeModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.code = _reader.readString();
          break;
        case 2:
          _instance.monthly = _reader.readString();
          break;
        case 3:
          _instance.annual = _reader.readString();
          break;
        case 4:
          _instance.id = _reader.readInt32();
          break;
        case 5:
          _instance.order = _reader.readInt32();
          break;
        case 6:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    IncomeRangeModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IncomeRangeModel,
    _writer: BinaryWriter
  ) {
    if (_instance.code) {
      _writer.writeString(1, _instance.code);
    }
    if (_instance.monthly) {
      _writer.writeString(2, _instance.monthly);
    }
    if (_instance.annual) {
      _writer.writeString(3, _instance.annual);
    }
    if (_instance.id) {
      _writer.writeInt32(4, _instance.id);
    }
    if (_instance.order) {
      _writer.writeInt32(5, _instance.order);
    }
    if (_instance.created) {
      _writer.writeMessage(
        6,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        7,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _code: string;
  private _monthly: string;
  private _annual: string;
  private _id: number;
  private _order: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IncomeRangeModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<IncomeRangeModel.AsObject>) {
    _value = _value || {};
    this.code = _value.code;
    this.monthly = _value.monthly;
    this.annual = _value.annual;
    this.id = _value.id;
    this.order = _value.order;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    IncomeRangeModel.refineValues(this);
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get monthly(): string {
    return this._monthly;
  }
  set monthly(value: string) {
    this._monthly = value;
  }
  get annual(): string {
    return this._annual;
  }
  set annual(value: string) {
    this._annual = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get order(): number {
    return this._order;
  }
  set order(value: number) {
    this._order = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IncomeRangeModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IncomeRangeModel.AsObject {
    return {
      code: this.code,
      monthly: this.monthly,
      annual: this.annual,
      id: this.id,
      order: this.order,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IncomeRangeModel.AsProtobufJSON {
    return {
      code: this.code,
      monthly: this.monthly,
      annual: this.annual,
      id: this.id,
      order: this.order,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module IncomeRangeModel {
  /**
   * Standard JavaScript object representation for IncomeRangeModel
   */
  export interface AsObject {
    code: string;
    monthly: string;
    annual: string;
    id: number;
    order: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for IncomeRangeModel
   */
  export interface AsProtobufJSON {
    code: string;
    monthly: string;
    annual: string;
    id: number;
    order: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.PatrimonyRangeModel
 */
export class PatrimonyRangeModel implements GrpcMessage {
  static id = 'common_message.PatrimonyRangeModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PatrimonyRangeModel();
    PatrimonyRangeModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PatrimonyRangeModel) {
    _instance.code = _instance.code || '';
    _instance.description = _instance.description || '';
    _instance.id = _instance.id || 0;
    _instance.order = _instance.order || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PatrimonyRangeModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.code = _reader.readString();
          break;
        case 2:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.id = _reader.readInt32();
          break;
        case 5:
          _instance.order = _reader.readInt32();
          break;
        case 6:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PatrimonyRangeModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PatrimonyRangeModel,
    _writer: BinaryWriter
  ) {
    if (_instance.code) {
      _writer.writeString(1, _instance.code);
    }
    if (_instance.description) {
      _writer.writeString(2, _instance.description);
    }
    if (_instance.id) {
      _writer.writeInt32(4, _instance.id);
    }
    if (_instance.order) {
      _writer.writeInt32(5, _instance.order);
    }
    if (_instance.created) {
      _writer.writeMessage(
        6,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        7,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _code: string;
  private _description: string;
  private _id: number;
  private _order: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PatrimonyRangeModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<PatrimonyRangeModel.AsObject>) {
    _value = _value || {};
    this.code = _value.code;
    this.description = _value.description;
    this.id = _value.id;
    this.order = _value.order;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    PatrimonyRangeModel.refineValues(this);
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get order(): number {
    return this._order;
  }
  set order(value: number) {
    this._order = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PatrimonyRangeModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PatrimonyRangeModel.AsObject {
    return {
      code: this.code,
      description: this.description,
      id: this.id,
      order: this.order,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PatrimonyRangeModel.AsProtobufJSON {
    return {
      code: this.code,
      description: this.description,
      id: this.id,
      order: this.order,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module PatrimonyRangeModel {
  /**
   * Standard JavaScript object representation for PatrimonyRangeModel
   */
  export interface AsObject {
    code: string;
    description: string;
    id: number;
    order: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for PatrimonyRangeModel
   */
  export interface AsProtobufJSON {
    code: string;
    description: string;
    id: number;
    order: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.FundingFinancialEntityModel
 */
export class FundingFinancialEntityModel implements GrpcMessage {
  static id = 'common_message.FundingFinancialEntityModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FundingFinancialEntityModel();
    FundingFinancialEntityModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FundingFinancialEntityModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.fundingId = _instance.fundingId || 0;
    _instance.funding = _instance.funding || undefined;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.enabled = _instance.enabled || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FundingFinancialEntityModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.fundingId = _reader.readInt32();
          break;
        case 5:
          _instance.funding = new FundingModel();
          _reader.readMessage(
            _instance.funding,
            FundingModel.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 7:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.enabled = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    FundingFinancialEntityModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FundingFinancialEntityModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.fundingId) {
      _writer.writeInt32(4, _instance.fundingId);
    }
    if (_instance.funding) {
      _writer.writeMessage(
        5,
        _instance.funding as any,
        FundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(6, _instance.financialEntityId);
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        7,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.enabled) {
      _writer.writeBool(8, _instance.enabled);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _fundingId: number;
  private _funding?: FundingModel;
  private _financialEntityId: number;
  private _financialEntity?: FinancialEntityModel;
  private _enabled: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FundingFinancialEntityModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FundingFinancialEntityModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.fundingId = _value.fundingId;
    this.funding = _value.funding
      ? new FundingModel(_value.funding)
      : undefined;
    this.financialEntityId = _value.financialEntityId;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.enabled = _value.enabled;
    FundingFinancialEntityModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get funding(): FundingModel | undefined {
    return this._funding;
  }
  set funding(value: FundingModel | undefined) {
    this._funding = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FundingFinancialEntityModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FundingFinancialEntityModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      fundingId: this.fundingId,
      funding: this.funding ? this.funding.toObject() : undefined,
      financialEntityId: this.financialEntityId,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      enabled: this.enabled
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FundingFinancialEntityModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      fundingId: this.fundingId,
      funding: this.funding ? this.funding.toProtobufJSON(options) : null,
      financialEntityId: this.financialEntityId,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      enabled: this.enabled
    };
  }
}
export module FundingFinancialEntityModel {
  /**
   * Standard JavaScript object representation for FundingFinancialEntityModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    fundingId: number;
    funding?: FundingModel.AsObject;
    financialEntityId: number;
    financialEntity?: FinancialEntityModel.AsObject;
    enabled: boolean;
  }

  /**
   * Protobuf JSON representation for FundingFinancialEntityModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    fundingId: number;
    funding: FundingModel.AsProtobufJSON | null;
    financialEntityId: number;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
    enabled: boolean;
  }
}

/**
 * Message implementation for common_message.FeedbackModel
 */
export class FeedbackModel implements GrpcMessage {
  static id = 'common_message.FeedbackModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FeedbackModel();
    FeedbackModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FeedbackModel) {
    _instance.userId = _instance.userId || 0;
    _instance.message = _instance.message || '';
    _instance.date = _instance.date || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.extra = _instance.extra || '';
    _instance.type = _instance.type || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FeedbackModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.extra = _reader.readString();
          break;
        case 7:
          _instance.type = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    FeedbackModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FeedbackModel,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeInt32(1, _instance.userId);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.date) {
      _writer.writeMessage(
        3,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        4,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        5,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.extra) {
      _writer.writeString(6, _instance.extra);
    }
    if (_instance.type) {
      _writer.writeString(7, _instance.type);
    }
  }

  private _userId: number;
  private _message: string;
  private _date?: googleProtobuf001.Timestamp;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _extra: string;
  private _type: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FeedbackModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FeedbackModel.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    this.message = _value.message;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.extra = _value.extra;
    this.type = _value.type;
    FeedbackModel.refineValues(this);
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get extra(): string {
    return this._extra;
  }
  set extra(value: string) {
    this._extra = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FeedbackModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FeedbackModel.AsObject {
    return {
      userId: this.userId,
      message: this.message,
      date: this.date ? this.date.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      extra: this.extra,
      type: this.type
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FeedbackModel.AsProtobufJSON {
    return {
      userId: this.userId,
      message: this.message,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      extra: this.extra,
      type: this.type
    };
  }
}
export module FeedbackModel {
  /**
   * Standard JavaScript object representation for FeedbackModel
   */
  export interface AsObject {
    userId: number;
    message: string;
    date?: googleProtobuf001.Timestamp.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    extra: string;
    type: string;
  }

  /**
   * Protobuf JSON representation for FeedbackModel
   */
  export interface AsProtobufJSON {
    userId: number;
    message: string;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    extra: string;
    type: string;
  }
}

/**
 * Message implementation for common_message.ListGoalTransactionFundingResponse
 */
export class ListGoalTransactionFundingResponse implements GrpcMessage {
  static id = 'common_message.ListGoalTransactionFundingResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalTransactionFundingResponse();
    ListGoalTransactionFundingResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalTransactionFundingResponse) {
    _instance.value = _instance.value || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalTransactionFundingResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GoalTransactionFundingModel();
          _reader.readMessage(
            messageInitializer1,
            GoalTransactionFundingModel.deserializeBinaryFromReader
          );
          (_instance.value = _instance.value || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalTransactionFundingResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalTransactionFundingResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.value && _instance.value.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.value as any,
        GoalTransactionFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _value?: GoalTransactionFundingModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalTransactionFundingResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListGoalTransactionFundingResponse.AsObject>
  ) {
    _value = _value || {};
    this.value = (_value.value || []).map(
      m => new GoalTransactionFundingModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListGoalTransactionFundingResponse.refineValues(this);
  }
  get value(): GoalTransactionFundingModel[] | undefined {
    return this._value;
  }
  set value(value: GoalTransactionFundingModel[] | undefined) {
    this._value = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalTransactionFundingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalTransactionFundingResponse.AsObject {
    return {
      value: (this.value || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalTransactionFundingResponse.AsProtobufJSON {
    return {
      value: (this.value || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListGoalTransactionFundingResponse {
  /**
   * Standard JavaScript object representation for ListGoalTransactionFundingResponse
   */
  export interface AsObject {
    value?: GoalTransactionFundingModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListGoalTransactionFundingResponse
   */
  export interface AsProtobufJSON {
    value: GoalTransactionFundingModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for common_message.AdvisorInformationModel
 */
export class AdvisorInformationModel implements GrpcMessage {
  static id = 'common_message.AdvisorInformationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AdvisorInformationModel();
    AdvisorInformationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AdvisorInformationModel) {
    _instance.id = _instance.id || 0;
    _instance.isDefault = _instance.isDefault || false;
    _instance.userId = _instance.userId || 0;
    _instance.segmentationPlans = _instance.segmentationPlans || [];
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AdvisorInformationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.isDefault = _reader.readBool();
          break;
        case 3:
          _instance.userId = _reader.readInt32();
          break;
        case 4:
          const messageInitializer4 = new SegmentationPlanModel();
          _reader.readMessage(
            messageInitializer4,
            SegmentationPlanModel.deserializeBinaryFromReader
          );
          (_instance.segmentationPlans =
            _instance.segmentationPlans || []).push(messageInitializer4);
          break;
        case 5:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AdvisorInformationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AdvisorInformationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.isDefault) {
      _writer.writeBool(2, _instance.isDefault);
    }
    if (_instance.userId) {
      _writer.writeInt32(3, _instance.userId);
    }
    if (_instance.segmentationPlans && _instance.segmentationPlans.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.segmentationPlans as any,
        SegmentationPlanModel.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        5,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        6,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _isDefault: boolean;
  private _userId: number;
  private _segmentationPlans?: SegmentationPlanModel[];
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AdvisorInformationModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<AdvisorInformationModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.isDefault = _value.isDefault;
    this.userId = _value.userId;
    this.segmentationPlans = (_value.segmentationPlans || []).map(
      m => new SegmentationPlanModel(m)
    );
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    AdvisorInformationModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get isDefault(): boolean {
    return this._isDefault;
  }
  set isDefault(value: boolean) {
    this._isDefault = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get segmentationPlans(): SegmentationPlanModel[] | undefined {
    return this._segmentationPlans;
  }
  set segmentationPlans(value: SegmentationPlanModel[] | undefined) {
    this._segmentationPlans = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AdvisorInformationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AdvisorInformationModel.AsObject {
    return {
      id: this.id,
      isDefault: this.isDefault,
      userId: this.userId,
      segmentationPlans: (this.segmentationPlans || []).map(m => m.toObject()),
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AdvisorInformationModel.AsProtobufJSON {
    return {
      id: this.id,
      isDefault: this.isDefault,
      userId: this.userId,
      segmentationPlans: (this.segmentationPlans || []).map(m =>
        m.toProtobufJSON(options)
      ),
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module AdvisorInformationModel {
  /**
   * Standard JavaScript object representation for AdvisorInformationModel
   */
  export interface AsObject {
    id: number;
    isDefault: boolean;
    userId: number;
    segmentationPlans?: SegmentationPlanModel.AsObject[];
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for AdvisorInformationModel
   */
  export interface AsProtobufJSON {
    id: number;
    isDefault: boolean;
    userId: number;
    segmentationPlans: SegmentationPlanModel.AsProtobufJSON[] | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.DeviceInstallationModel
 */
export class DeviceInstallationModel implements GrpcMessage {
  static id = 'common_message.DeviceInstallationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeviceInstallationModel();
    DeviceInstallationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeviceInstallationModel) {
    _instance.id = _instance.id || 0;
    _instance.installationId = _instance.installationId || '';
    _instance.platform = _instance.platform || '';
    _instance.userId = _instance.userId || 0;
    _instance.token = _instance.token || '';
    _instance.user = _instance.user || undefined;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeviceInstallationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.installationId = _reader.readString();
          break;
        case 3:
          _instance.platform = _reader.readString();
          break;
        case 4:
          _instance.userId = _reader.readInt32();
          break;
        case 5:
          _instance.token = _reader.readString();
          break;
        case 6:
          _instance.user = new UserModel();
          _reader.readMessage(
            _instance.user,
            UserModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DeviceInstallationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeviceInstallationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.installationId) {
      _writer.writeString(2, _instance.installationId);
    }
    if (_instance.platform) {
      _writer.writeString(3, _instance.platform);
    }
    if (_instance.userId) {
      _writer.writeInt32(4, _instance.userId);
    }
    if (_instance.token) {
      _writer.writeString(5, _instance.token);
    }
    if (_instance.user) {
      _writer.writeMessage(
        6,
        _instance.user as any,
        UserModel.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _installationId: string;
  private _platform: string;
  private _userId: number;
  private _token: string;
  private _user?: UserModel;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeviceInstallationModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeviceInstallationModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.installationId = _value.installationId;
    this.platform = _value.platform;
    this.userId = _value.userId;
    this.token = _value.token;
    this.user = _value.user ? new UserModel(_value.user) : undefined;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    DeviceInstallationModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get installationId(): string {
    return this._installationId;
  }
  set installationId(value: string) {
    this._installationId = value;
  }
  get platform(): string {
    return this._platform;
  }
  set platform(value: string) {
    this._platform = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }
  get token(): string {
    return this._token;
  }
  set token(value: string) {
    this._token = value;
  }
  get user(): UserModel | undefined {
    return this._user;
  }
  set user(value: UserModel | undefined) {
    this._user = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeviceInstallationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeviceInstallationModel.AsObject {
    return {
      id: this.id,
      installationId: this.installationId,
      platform: this.platform,
      userId: this.userId,
      token: this.token,
      user: this.user ? this.user.toObject() : undefined,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeviceInstallationModel.AsProtobufJSON {
    return {
      id: this.id,
      installationId: this.installationId,
      platform: this.platform,
      userId: this.userId,
      token: this.token,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module DeviceInstallationModel {
  /**
   * Standard JavaScript object representation for DeviceInstallationModel
   */
  export interface AsObject {
    id: number;
    installationId: string;
    platform: string;
    userId: number;
    token: string;
    user?: UserModel.AsObject;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for DeviceInstallationModel
   */
  export interface AsProtobufJSON {
    id: number;
    installationId: string;
    platform: string;
    userId: number;
    token: string;
    user: UserModel.AsProtobufJSON | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.SpreadRangeModel
 */
export class SpreadRangeModel implements GrpcMessage {
  static id = 'common_message.SpreadRangeModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SpreadRangeModel();
    SpreadRangeModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SpreadRangeModel) {
    _instance.id = _instance.id || 0;
    _instance.description = _instance.description || '';
    _instance.maxValue = _instance.maxValue || 0;
    _instance.minValue = _instance.minValue || 0;
    _instance.spread = _instance.spread || 0;
    _instance.priority = _instance.priority || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SpreadRangeModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.description = _reader.readString();
          break;
        case 3:
          _instance.maxValue = _reader.readDouble();
          break;
        case 4:
          _instance.minValue = _reader.readDouble();
          break;
        case 6:
          _instance.spread = _reader.readInt32();
          break;
        case 7:
          _instance.priority = _reader.readInt32();
          break;
        case 8:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SpreadRangeModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SpreadRangeModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.description) {
      _writer.writeString(2, _instance.description);
    }
    if (_instance.maxValue) {
      _writer.writeDouble(3, _instance.maxValue);
    }
    if (_instance.minValue) {
      _writer.writeDouble(4, _instance.minValue);
    }
    if (_instance.spread) {
      _writer.writeInt32(6, _instance.spread);
    }
    if (_instance.priority) {
      _writer.writeInt32(7, _instance.priority);
    }
    if (_instance.created) {
      _writer.writeMessage(
        8,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        9,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _description: string;
  private _maxValue: number;
  private _minValue: number;
  private _spread: number;
  private _priority: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SpreadRangeModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<SpreadRangeModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.description = _value.description;
    this.maxValue = _value.maxValue;
    this.minValue = _value.minValue;
    this.spread = _value.spread;
    this.priority = _value.priority;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    SpreadRangeModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get maxValue(): number {
    return this._maxValue;
  }
  set maxValue(value: number) {
    this._maxValue = value;
  }
  get minValue(): number {
    return this._minValue;
  }
  set minValue(value: number) {
    this._minValue = value;
  }
  get spread(): number {
    return this._spread;
  }
  set spread(value: number) {
    this._spread = value;
  }
  get priority(): number {
    return this._priority;
  }
  set priority(value: number) {
    this._priority = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SpreadRangeModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SpreadRangeModel.AsObject {
    return {
      id: this.id,
      description: this.description,
      maxValue: this.maxValue,
      minValue: this.minValue,
      spread: this.spread,
      priority: this.priority,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SpreadRangeModel.AsProtobufJSON {
    return {
      id: this.id,
      description: this.description,
      maxValue: this.maxValue,
      minValue: this.minValue,
      spread: this.spread,
      priority: this.priority,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module SpreadRangeModel {
  /**
   * Standard JavaScript object representation for SpreadRangeModel
   */
  export interface AsObject {
    id: number;
    description: string;
    maxValue: number;
    minValue: number;
    spread: number;
    priority: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for SpreadRangeModel
   */
  export interface AsProtobufJSON {
    id: number;
    description: string;
    maxValue: number;
    minValue: number;
    spread: number;
    priority: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for common_message.WarningModel
 */
export class WarningModel implements GrpcMessage {
  static id = 'common_message.WarningModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WarningModel();
    WarningModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WarningModel) {
    _instance.message = _instance.message || '';
    _instance.severity = _instance.severity || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WarningModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.message = _reader.readString();
          break;
        case 2:
          _instance.severity = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WarningModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WarningModel,
    _writer: BinaryWriter
  ) {
    if (_instance.message) {
      _writer.writeString(1, _instance.message);
    }
    if (_instance.severity) {
      _writer.writeString(2, _instance.severity);
    }
  }

  private _message: string;
  private _severity: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WarningModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<WarningModel.AsObject>) {
    _value = _value || {};
    this.message = _value.message;
    this.severity = _value.severity;
    WarningModel.refineValues(this);
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }
  get severity(): string {
    return this._severity;
  }
  set severity(value: string) {
    this._severity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WarningModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WarningModel.AsObject {
    return {
      message: this.message,
      severity: this.severity
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WarningModel.AsProtobufJSON {
    return {
      message: this.message,
      severity: this.severity
    };
  }
}
export module WarningModel {
  /**
   * Standard JavaScript object representation for WarningModel
   */
  export interface AsObject {
    message: string;
    severity: string;
  }

  /**
   * Protobuf JSON representation for WarningModel
   */
  export interface AsProtobufJSON {
    message: string;
    severity: string;
  }
}

/**
 * Message implementation for common_message.ContractModel
 */
export class ContractModel implements GrpcMessage {
  static id = 'common_message.ContractModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ContractModel();
    ContractModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ContractModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.state = _instance.state || '';
    _instance.signed = _instance.signed || false;
    _instance.fileId = _instance.fileId || undefined;
    _instance.signatureId = _instance.signatureId || undefined;
    _instance.riskProfileId = _instance.riskProfileId || undefined;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.userId = _instance.userId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ContractModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.state = _reader.readString();
          break;
        case 5:
          _instance.signed = _reader.readBool();
          break;
        case 6:
          _instance.fileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.fileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.signatureId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.signatureId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.riskProfileId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.riskProfileId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 10:
          _instance.financialEntity = new FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.userId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ContractModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ContractModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.state) {
      _writer.writeString(4, _instance.state);
    }
    if (_instance.signed) {
      _writer.writeBool(5, _instance.signed);
    }
    if (_instance.fileId) {
      _writer.writeMessage(
        6,
        _instance.fileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.signatureId) {
      _writer.writeMessage(
        7,
        _instance.signatureId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.riskProfileId) {
      _writer.writeMessage(
        8,
        _instance.riskProfileId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(9, _instance.financialEntityId);
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        10,
        _instance.financialEntity as any,
        FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.userId) {
      _writer.writeInt32(11, _instance.userId);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _state: string;
  private _signed: boolean;
  private _fileId?: googleProtobuf000.Int32Value;
  private _signatureId?: googleProtobuf000.Int32Value;
  private _riskProfileId?: googleProtobuf000.Int32Value;
  private _financialEntityId: number;
  private _financialEntity?: FinancialEntityModel;
  private _userId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ContractModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ContractModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.state = _value.state;
    this.signed = _value.signed;
    this.fileId = _value.fileId
      ? new googleProtobuf000.Int32Value(_value.fileId)
      : undefined;
    this.signatureId = _value.signatureId
      ? new googleProtobuf000.Int32Value(_value.signatureId)
      : undefined;
    this.riskProfileId = _value.riskProfileId
      ? new googleProtobuf000.Int32Value(_value.riskProfileId)
      : undefined;
    this.financialEntityId = _value.financialEntityId;
    this.financialEntity = _value.financialEntity
      ? new FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.userId = _value.userId;
    ContractModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get state(): string {
    return this._state;
  }
  set state(value: string) {
    this._state = value;
  }
  get signed(): boolean {
    return this._signed;
  }
  set signed(value: boolean) {
    this._signed = value;
  }
  get fileId(): googleProtobuf000.Int32Value | undefined {
    return this._fileId;
  }
  set fileId(value: googleProtobuf000.Int32Value | undefined) {
    this._fileId = value;
  }
  get signatureId(): googleProtobuf000.Int32Value | undefined {
    return this._signatureId;
  }
  set signatureId(value: googleProtobuf000.Int32Value | undefined) {
    this._signatureId = value;
  }
  get riskProfileId(): googleProtobuf000.Int32Value | undefined {
    return this._riskProfileId;
  }
  set riskProfileId(value: googleProtobuf000.Int32Value | undefined) {
    this._riskProfileId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get financialEntity(): FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(value: FinancialEntityModel | undefined) {
    this._financialEntity = value;
  }
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ContractModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ContractModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      state: this.state,
      signed: this.signed,
      fileId: this.fileId ? this.fileId.toObject() : undefined,
      signatureId: this.signatureId ? this.signatureId.toObject() : undefined,
      riskProfileId: this.riskProfileId
        ? this.riskProfileId.toObject()
        : undefined,
      financialEntityId: this.financialEntityId,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      userId: this.userId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ContractModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      state: this.state,
      signed: this.signed,
      fileId: this.fileId ? this.fileId.toProtobufJSON(options) : null,
      signatureId: this.signatureId
        ? this.signatureId.toProtobufJSON(options)
        : null,
      riskProfileId: this.riskProfileId
        ? this.riskProfileId.toProtobufJSON(options)
        : null,
      financialEntityId: this.financialEntityId,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      userId: this.userId
    };
  }
}
export module ContractModel {
  /**
   * Standard JavaScript object representation for ContractModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    state: string;
    signed: boolean;
    fileId?: googleProtobuf000.Int32Value.AsObject;
    signatureId?: googleProtobuf000.Int32Value.AsObject;
    riskProfileId?: googleProtobuf000.Int32Value.AsObject;
    financialEntityId: number;
    financialEntity?: FinancialEntityModel.AsObject;
    userId: number;
  }

  /**
   * Protobuf JSON representation for ContractModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    state: string;
    signed: boolean;
    fileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    signatureId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    riskProfileId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    financialEntityId: number;
    financialEntity: FinancialEntityModel.AsProtobufJSON | null;
    userId: number;
  }
}

/**
 * Message implementation for common_message.ListTemplateResponse
 */
export class ListTemplateResponse implements GrpcMessage {
  static id = 'common_message.ListTemplateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListTemplateResponse();
    ListTemplateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListTemplateResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListTemplateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new TemplateModel();
          _reader.readMessage(
            messageInitializer1,
            TemplateModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListTemplateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListTemplateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        TemplateModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: TemplateModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListTemplateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListTemplateResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new TemplateModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListTemplateResponse.refineValues(this);
  }
  get values(): TemplateModel[] | undefined {
    return this._values;
  }
  set values(value: TemplateModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListTemplateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListTemplateResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListTemplateResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListTemplateResponse {
  /**
   * Standard JavaScript object representation for ListTemplateResponse
   */
  export interface AsObject {
    values?: TemplateModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListTemplateResponse
   */
  export interface AsProtobufJSON {
    values: TemplateModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for common_message.FinancialEntityEmailModel
 */
export class FinancialEntityEmailModel implements GrpcMessage {
  static id = 'common_message.FinancialEntityEmailModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FinancialEntityEmailModel();
    FinancialEntityEmailModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FinancialEntityEmailModel) {
    _instance.id = _instance.id || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.emailType = _instance.emailType || 0;
    _instance.emailAddress = _instance.emailAddress || '';
    _instance.contactName = _instance.contactName || '';
    _instance.isDestination = _instance.isDestination || false;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FinancialEntityEmailModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 3:
          _instance.emailType = _reader.readEnum();
          break;
        case 4:
          _instance.emailAddress = _reader.readString();
          break;
        case 5:
          _instance.contactName = _reader.readString();
          break;
        case 6:
          _instance.isDestination = _reader.readBool();
          break;
        case 7:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    FinancialEntityEmailModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FinancialEntityEmailModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(2, _instance.financialEntityId);
    }
    if (_instance.emailType) {
      _writer.writeEnum(3, _instance.emailType);
    }
    if (_instance.emailAddress) {
      _writer.writeString(4, _instance.emailAddress);
    }
    if (_instance.contactName) {
      _writer.writeString(5, _instance.contactName);
    }
    if (_instance.isDestination) {
      _writer.writeBool(6, _instance.isDestination);
    }
    if (_instance.created) {
      _writer.writeMessage(
        7,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        8,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _financialEntityId: number;
  private _emailType: FinancialEntityEmailType;
  private _emailAddress: string;
  private _contactName: string;
  private _isDestination: boolean;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FinancialEntityEmailModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<FinancialEntityEmailModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.financialEntityId = _value.financialEntityId;
    this.emailType = _value.emailType;
    this.emailAddress = _value.emailAddress;
    this.contactName = _value.contactName;
    this.isDestination = _value.isDestination;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    FinancialEntityEmailModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get emailType(): FinancialEntityEmailType {
    return this._emailType;
  }
  set emailType(value: FinancialEntityEmailType) {
    this._emailType = value;
  }
  get emailAddress(): string {
    return this._emailAddress;
  }
  set emailAddress(value: string) {
    this._emailAddress = value;
  }
  get contactName(): string {
    return this._contactName;
  }
  set contactName(value: string) {
    this._contactName = value;
  }
  get isDestination(): boolean {
    return this._isDestination;
  }
  set isDestination(value: boolean) {
    this._isDestination = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FinancialEntityEmailModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FinancialEntityEmailModel.AsObject {
    return {
      id: this.id,
      financialEntityId: this.financialEntityId,
      emailType: this.emailType,
      emailAddress: this.emailAddress,
      contactName: this.contactName,
      isDestination: this.isDestination,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FinancialEntityEmailModel.AsProtobufJSON {
    return {
      id: this.id,
      financialEntityId: this.financialEntityId,
      emailType:
        FinancialEntityEmailType[
          this.emailType === null || this.emailType === undefined
            ? 0
            : this.emailType
        ],
      emailAddress: this.emailAddress,
      contactName: this.contactName,
      isDestination: this.isDestination,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module FinancialEntityEmailModel {
  /**
   * Standard JavaScript object representation for FinancialEntityEmailModel
   */
  export interface AsObject {
    id: number;
    financialEntityId: number;
    emailType: FinancialEntityEmailType;
    emailAddress: string;
    contactName: string;
    isDestination: boolean;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for FinancialEntityEmailModel
   */
  export interface AsProtobufJSON {
    id: number;
    financialEntityId: number;
    emailType: string;
    emailAddress: string;
    contactName: string;
    isDestination: boolean;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}
