import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
//import { ApmBase } from '@elastic/apm-rum';
//import { ApmService } from '@elastic/apm-rum-angular';
import { Empty } from '@ngx-grpc/well-known-types';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import * as LogRocket from 'logrocket';
import { OAuthService } from 'projects/bp-core/src/lib/external/angular-oauth2-oidc-jwks/public_api';
import { IChangeAccountForm } from 'projects/bp-core/src/lib/models/changeAccount.form.model';
import { AmendRiskProfileModel } from 'projects/bp-core/src/lib/proto/amend-risk-profile.pb';
import { AmendRiskProfileGrpcServiceClient } from 'projects/bp-core/src/lib/proto/amend-risk-profile.pbsc';
import { UserModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
import { GetMeRequest } from 'projects/bp-core/src/lib/proto/user.pb';
import { UserGrpcServiceClient } from 'projects/bp-core/src/lib/proto/user.pbsc';
//@ts-ignore
import { environment } from '@environment/environment';
import Hotjar from '@hotjar/browser';
import Tracker from '@openreplay/tracker';
// import { H } from 'highlight.run';
import { datadogRum } from '@datadog/browser-rum';
import { WVCommunicationService } from 'projects/bp-core/src/lib/services/wvcommunication.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginForm } from '../../models/portal/login.form.model';
import { IOAuthToken } from '../../models/portal/oAuth.token.model';
import { IResetPasswordForm } from '../../models/portal/reset.password.form';
//import { AppInsightsService } from '../../modules/app-insights/app-insights.service';
import { GoalService } from './goal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userSubject$ = new BehaviorSubject<UserModel | undefined>(undefined);
  public AmendRiskProfileSubject$ = new BehaviorSubject<AmendRiskProfileModel | undefined>(
    undefined,
  );

  public hasDiscoveredSubject$ = new BehaviorSubject<boolean>(false);
  // apm: ApmBase;
  tracker: Tracker;
  constructor(
    private http: HttpClient,
    private goalService: GoalService,
    private oauthService: OAuthService,
    private wvCommunicationService: WVCommunicationService,
    //private apmService: ApmService,
    // private appInsightsService: AppInsightsService,

    @Inject(Injector) private readonly injector: Injector,
  ) {
    // if (environment?.apm) {
    //       this.apm = this.apmService.init(environment.apm);
    // }
    /*if (environment?.tracker) {
      this.tracker = new Tracker({
        projectKey: 'mbRxzNwIEbL2GfpiTMBq',
        verbose: true,
        __DISABLE_SECURE_MODE: true,
        __debug__: 5,
        captureIFrames: false,
        captureResourceTimings: false,
        capturePageLoadTimings: false,
        capturePageRenderTimings: false,
        //@ts-ignore
        network: {
          capturePayload: false,
          captureInIframes: false,
        },
        onStart: ({ sessionID }) =>
          console.log('OpenReplay tracker started with session: ', sessionID),
      });
      this.tracker.start();
    }*/
  }

  set bpEnterAsId(token: string) {
    localStorage.setItem('bp-enter-as-id', token);
  }

  get bpEnterAsId(): string {
    return localStorage.getItem('bp-enter-as-id') ?? '';
  }

  set accessToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  get accessToken(): string {
    return localStorage.getItem('access_token') ?? '';
  }

  /**
   * Need to get UserGrpcServiceClient from injector rather than constructor injection to avoid cyclic dependency error
   * @returns {}
   */
  private get userGrpcServiceClient() {
    return this.injector.get(UserGrpcServiceClient);
  }
  private get amendRiskProfileGrpcServiceClient() {
    return this.injector.get(AmendRiskProfileGrpcServiceClient);
  }
  login(login: LoginForm): Observable<IOAuthToken> {
    return new Observable(ob => {
      // Test
      this.oauthService.customQueryParams = login.customQueryParams;
      //@ts-ignore
      this.oauthService.clientId = environment.auth.client_id_pass;
      this.oauthService
        .fetchTokenUsingPasswordFlow(login.username, login.password)
        .then(resultToken => {
          //@ts-ignore
          this.oauthService.clientId = environment.auth.client_id_code;
          //   console.log(resultToken);
        })
        .catch(e => {
          //@ts-ignore
          this.oauthService.clientId = environment.auth.client_id_code;
          ob.error(e);
          ob.complete();
        });
    });

    // this.logout();
    // return this.http
    //   .post<IOAuthToken>(environment.urlApi + 'auth/oauth/token', login)
    //   .pipe(
    //     switchMap((resultLogin) => {
    //       localStorage.setItem('access_token', resultLogin.access_token);
    //       localStorage.setItem('refresh_token', resultLogin.refresh_token);
    //       return this.getCurrentUser().pipe(
    //         map((result) => {
    //           return resultLogin;
    //         })
    //       );
    //     })
    //   );
  }
  changeAccount(changeAccount: IChangeAccountForm): Observable<IOAuthToken> {
    return new Observable(ob => {
      this.http
        .post<IOAuthToken>(environment.auth.issuer + '/api/Account/ChangeAccount/', changeAccount)
        .subscribe(
          async result => {
            this.oauthService.setStorageAccessToken(
              result.access_token,
              result.refresh_token,
              result.expires_in,
              result.scope,
              result.id_token,
              result,
            );
            // localStorage.setItem('access_token', result.access_token);
            // localStorage.setItem('refresh_token', result.refresh_token);
            // if (result.id_token) {
            //   localStorage.setItem('id_token', result.id_token);
            // }
            // FIXME: corregir error de oidc en id server
            try {
              const userLogin = await this.oauthService.loadUserProfile();
              //console.log(userLogin);
              const res = await this.oauthService.silentRefresh();
              //console.log(res);
            } catch (error) {
              console.error(error);
            }

            ob.next(result);
            ob.complete();
          },
          error => {
            ob.error(error);
            ob.complete();
          },
        );
    });
  }
  // Current Betterplan client
  getCurrentUser(): Observable<UserModel | undefined> {
    if (!this.userSubject$.value) {
      // return this.http.get<IUser>(environment.urlApi + 'Users/me/')

      return this.userGrpcServiceClient.getMe(new GetMeRequest({ onlyManager: false })).pipe(
        map(result => {
          //console.log('user current', result);
          this.oauthService
            .loadUserProfile()
            .then(profile => {
              console.log('getCurrentUser');
              // console.log('profile current user', profile);
              this.wvCommunicationService.isLogin(true);
              console.log(this.timeConversion(this.oauthService.getAccessTokenExpiration()));
              // this.oauthService
              //   .refreshToken()
              //   .then((info) => console.debug('refresh ok', info))
              //   .catch((err) => console.error('refresh error', err));

              // (this.oauthService as any).initSessionCheck();
              // this.oauthService.setupAutomaticSilentRefresh();
              // this.oauthService
              //   .silentRefresh()
              //   .then((info) => console.debug('refresh ok', info))
              //   .catch((err) => console.error('refresh error', err));

              // this.oauthService.checkSession();
            })
            .catch(e => {
              console.error(e);
            });
          // try {
          //   const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
          //   //       console.log(_hsq);
          //   _hsq.push([
          //     'identify',
          //     {
          //       email: result.email,
          //       id: result.id
          //     }
          //   ]);
          //   _hsq.push(['trackPageView']);
          //   setTimeout(() => {
          //     (window as any).HubSpotConversations.widget.refresh();
          //   }, 1000);
          // } catch (e) {
          //   console.warn(e);
          // }

          // if (this.apm) {
          //   this.apm.setUserContext({
          //     id: result.id,
          //     email: result.email,
          //     username:
          //       result.firstname +
          //       ' ' +
          //       result.middlename +
          //       ' ' +
          //       result.surname +
          //       ' ' +
          //       result.maidenname,
          //   });
          //   this.apm.addLabels({
          //     isAdmin: this.getUserIsAdmin(),
          //   });
          // }
          datadogRum.setUser({
            id: result.id.toString(),
            email: result.email,
            name: result.firstname + ' ' + result.surname,
          });
          //   this.appInsightsService.setAuthenticatedUser(result.id.toString(), result.email);
          datadogRum.setUserProperty('isAdmin', this.getUserIsAdmin().toString());
          if (this.tracker) {
            this.tracker.setUserID(result.id?.toString() ?? '');
            this.tracker.setMetadata('email', result.email);
            this.tracker.setMetadata('name', result.firstname + ' ' + result.surname);
            this.tracker.setMetadata('isAdmin', this.getUserIsAdmin().toString());
          }
          if (Hotjar.isReady()) {
            Hotjar.identify(result.id?.toString() ?? '', {
              email: result.email,
              name: result.firstname + ' ' + result.surname,
              isAdmin: localStorage.getItem('bp_is_admin') === 'true' ? 'true' : 'false',
            });
          }
          if ((window as any).Intercom)
            (window as any).Intercom('update', {
              email: result.email,
              id: result.id,
              name:
                result.firstname +
                ' ' +
                result.middlename +
                ' ' +
                result.surname +
                ' ' +
                result.maidenname,
              isAdmin: this.getUserIsAdmin(),
            });
          if (environment.production) {
            // H.identify(result?.email ?? '', {
            //   id: result?.id ?? '',
            //   name: result.firstname + ' ' + result.surname,
            //   isAdmin: this.getUserIsAdmin(),
            // });
            LogRocket.identify(result?.id?.toString() ?? '', {
              email: result?.email ?? '',
              id: result?.id ?? '',
              name: result.firstname + ' ' + result.surname,
              isAdmin: this.getUserIsAdmin(),
            });
            Sentry.configureScope(function (scope) {
              scope.setUser({
                email: result.email,
                id: result?.id?.toString(),
                name: result.firstname + ' ' + result.surname,
                username: result.email,
                isAdmin: localStorage.getItem('bp_is_admin') === 'true' ? true : false,
              });
              scope.setTag(
                'isAdmin',
                localStorage.getItem('bp_is_admin') === 'true' ? 'true' : 'false',
              );
            });
          }
          this.userSubject$.next(result);
          this.goalService.Goals = result.goals ?? [];
          return result;
        }),
        catchError(err => {
          console.error('getCurrentUser', err);
          // LogRocket.captureException(err);
          // localStorage.removeItem('bp-enter-as-id');
          // localStorage.removeItem('bp_is_admin');
          // console.error(err);
          // this.wvCommunicationService.isLogin(false);
          // this.oauthService.logOut();
          // Sentry.captureException(err);
          return throwError(() => err);
        }),
      );
    }
    return this.userSubject$;
  }
  getUser(getOnlyManager: boolean = false): Observable<UserModel> {
    // console.log('getUser');
    // return this.http.get<IUser>(`${environment.urlApi}Users/me?onlyManager=${getOnlyManager}`);
    return this.userGrpcServiceClient.getMe(new GetMeRequest({ onlyManager: getOnlyManager }));
  }
  setUserIsAdmin(isAdmin: boolean): void {
    localStorage.setItem('bp_is_admin', isAdmin.toString());
  }
  getUserIsAdmin(): boolean {
    return localStorage.getItem('bp_is_admin') == 'true' ? true : false;
  }
  getStatus(userId: number) {
    this.amendRiskProfileGrpcServiceClient.checkRiskProfile(new Empty()).subscribe({
      next: value => {
        this.AmendRiskProfileSubject$.next(value);
      },
      error: err => {
        if (err.statusCode === 5) this.AmendRiskProfileSubject$.next(undefined);
        console.log(err);
      },
    });
  }
  refreshUser() {
    // this.http.get<IUser>(environment.urlApi + 'Users/me/').subscribe(result => {
    //   console.log('me', result);
    // });

    return this.userGrpcServiceClient.getMe(new GetMeRequest({ onlyManager: false })).pipe(
      catchError(err => {
        console.error('refreshError', err);
        // LogRocket.captureException(err);
        // localStorage.removeItem('bp-enter-as-id');
        // localStorage.removeItem('bp_is_admin');
        // console.error(err);
        // this.wvCommunicationService.isLogin(false);
        // this.oauthService.logOut();
        // Sentry.captureException(err);
        return throwError(() => err);
      }),
      map(result => {
        // const user = result.toJSON();
        // for (const key in user.firstSteps) {
        //   if (Object.prototype.hasOwnProperty.call(user.firstSteps, key)) {
        //     //@ts-ignore
        //     user.firstSteps[key] = user.firstSteps[key]?.value;
        //   }
        // }

        if (
          !this.AmendRiskProfileSubject$.value ||
          this.AmendRiskProfileSubject$.value?.user?.id != result.id
        )
          this.getStatus(result.id!);
        // console.log('refreshUser', user);
        // console.log('refreshUser firstSteps', result.firstSteps);
        this.userSubject$.next(result);
        this.goalService.Goals = result.goals ?? [];
        if (result) {
          if (this.tracker) {
            this.tracker.setUserID(result.id?.toString() ?? '');
            // this.tracker.setMetadata('email', result.email);
            // this.tracker.setMetadata('name', result.firstname + ' ' + result.surname);
            // this.tracker.setMetadata('isAdmin', this.getUserIsAdmin().toString());
          }
          if (Hotjar.isReady()) {
            Hotjar.identify(result.id?.toString() ?? '', {
              email: result.email,
              name: result.firstname + ' ' + result.surname,
              isAdmin: localStorage.getItem('bp_is_admin') === 'true' ? 'true' : 'false',
            });
          }
          // if (this.apm) {
          //   this.apm.setUserContext({
          //     id: result.id,
          //     email: result.email,
          //     username:
          //       result.firstname +
          //       ' ' +
          //       result.middlename +
          //       ' ' +
          //       result.surname +
          //       ' ' +
          //       result.maidenname,
          //   });
          //   this.apm.addLabels({
          //     isAdmin: this.getUserIsAdmin(),
          //   });
          // }
          datadogRum.setUser({
            id: result.id.toString(),
            email: result.email,
            name: result.firstname + ' ' + result.surname,
          });
          //this.appInsightsService.setAuthenticatedUser(result.id.toString(), result.email);

          datadogRum.setUserProperty('isAdmin', this.getUserIsAdmin().toString());
          if ((window as any).Intercom)
            (window as any).Intercom('update', {
              email: result.email,
              id: result.id,
              name:
                result.firstname +
                ' ' +
                result.middlename +
                ' ' +
                result.surname +
                ' ' +
                result.maidenname,
              isAdmin: this.getUserIsAdmin(),
            });
        }
        if (environment.production && result) {
          // H.identify(result?.email ?? '', {
          //   id: result?.id ?? '',
          //   name: result.firstname + ' ' + result.surname,
          //   isAdmin: this.getUserIsAdmin(),
          // });
          LogRocket.identify(result?.id?.toString() ?? '', {
            email: result?.email ?? '',
            id: result?.id ?? '',
            name: result.firstname + ' ' + result.surname,
            isAdmin: this.getUserIsAdmin(),
          });
          Sentry.configureScope(function (scope) {
            scope.setUser({
              email: result.email,
              id: result?.id?.toString(),
              name: result.firstname + ' ' + result.surname,
              username: result.email,
              isAdmin: localStorage.getItem('bp_is_admin') === 'true' ? true : false,
            });
            scope.setTag(
              'isAdmin',
              localStorage.getItem('bp_is_admin') === 'true' ? 'true' : 'false',
            );
          });
        }
        //@ts-ignore
        return result;
      }),
    );
  }
  activateUser(token: string, email: string): Observable<string> {
    localStorage.clear();
    return this.http.post<string>(environment.auth.issuer + '/api/Account/activate', {
      token,
      email,
    });
  }
  recoverPassword(email: string): Observable<boolean> {
    const url = environment.auth.issuer + '/api/Account/ForgotPassword';
    return this.http.post<boolean>(url, { email });
  }
  resetPassword(form: IResetPasswordForm): Observable<boolean> {
    localStorage.clear();
    return this.http.post<boolean>(environment.auth.issuer + '/api/Account/ResetPassword', form);
  }
  isAuthenticated(): Observable<boolean> {
    // @ts-ignore
    return new Observable(observer => {
      if (!localStorage.getItem('access_token')) {
        observer.next(false);
        observer.complete();
        return null;
      }
      if (this.sessionIsOverdue(this.oauthService.getAccessTokenExpiration())) {
        console.error('session vencida');
        observer.next(false);
        observer.complete();
        return null;
      }
      if (!this.userSubject$.value) {
        return this.getCurrentUser().subscribe(
          () => {
            observer.next(true);
            observer.complete();
            return true;
          },
          error => {
            console.error(error);
            observer.next(false);
            observer.complete();
            return null;
          },
        );
      }
      observer.next(true);
      observer.complete();
      return null;
    });
  }
  logout(sessionOverdue = false): void {
    this.wvCommunicationService.isLogin(false);
    console.log('logout');
    localStorage.removeItem('bp-enter-as-id');
    localStorage.removeItem('bp_is_admin');
    this.oauthService.revokeTokenAndLogout();
    this.userSubject$.next(undefined);

    // if (sessionOverdue && localStorage.getItem('access_token')) {
    // this.toastr.error(
    //   'Tu sesión ha expirado, por favor inicie sesion nuevamente'
    // );
    // Swal.fire({
    //   iconHtml: '<i class="icon icon-swal fas fa-info-circle"></i>',
    //   customClass: {
    //     container: 'swal2-fixe',
    //     confirmButton: 'btn',
    //     cancelButton: 'btn',
    //   },
    //   buttonsStyling: false,
    //   showClass: {
    //     icon: 'swal2-noanimation',
    //   },
    //   hideClass: {
    //     icon: 'swal2-noanimation',
    //   },
    //   footer:
    //     '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
    //   icon: 'info',
    //   title: 'Cierre de sesión',
    //   text:
    //     'Por motivos de seguridad tu sesión ha sido cerrada ya que lleva más de 12 horas activa. Por favor vuelve a ingresar.',
    // });
    // }

    // if (localStorage.getItem('access_token')) {
    //   this.oauthService.logOut(true);
    //   this.userSubject$.next(undefined);
    //   localStorage.clear();
    // }

    // window.location.replace('/auth/login');
    // this.router.navigate(['/auth/login']);

    /*this.router.navigate(['/auth/login']).then(() => {
      window.location.reload();
    });*/
  }
  timeConversion(millisec: number) {
    console.log(millisec, dayjs(millisec).toDate());
    return (
      'Vence el: ' +
      dayjs(millisec).toDate().toString() +
      ', Quedan: ' +
      dayjs(millisec).diff(dayjs(), 'm') +
      ' Minutos'
    );
  }
  sessionIsOverdue(millisec: number): boolean {
    return dayjs(millisec).diff(dayjs(), 's') < 0;
  }
}
